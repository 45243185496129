import { useState, useEffect, useContext } from 'react'
import Card from '../../ui/Card'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Spinner from 'react-bootstrap/Spinner'
import BaseService from '../../../services/BaseService';
import CommonService from '../../../services/CommonService';
import { UserContext } from '../../../UserContext';
import { toast } from 'react-toastify';
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const ReviewAdtr = (props) => {
  const loginUserId = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [adtrs, setAdtrs] = useState([]);
  const [selected, setSelected] = useState([]);
  //const [filteredAdtrs, setFilteredAdtrs] = useState([]);

  async function getSubmittedADTRs() {
    let obj = {
      "msgId": null,
      "sysId": null,
      "opr": "getSubmittedAdtrs",
      "hdrStruct": null,
      "queryParam": null,
      "reqData": {
      }
    };

    await BaseService.processRequest(obj)
      .then((res) => res)
      .then((result) => {
        if (result.data.responses.length > 0 && result.data.responses !== null) {
          setAdtrs(result.data.responses[0]);
          //setFilteredAdtrs(result.data.responses[0]);
        } else if(result.data.errors && result.data.errors.length > 0){
          toast.error(result.data.errors[0].message);                    
        } 
      });
      setIsLoading(false);
  }

  useEffect(() => {
    getSubmittedADTRs();
  }, []);

  const columns = [
    { dataField: 'cdiacNbr',text: 'CDIAC #',sort: true },
    { dataField: 'fiscalYrEndDate', text: 'Fiscal Yr End Date', sort: true, formatter: (row) => CommonService.dateFormatter(row['fiscalYrEndDate']),  },
    { dataField: 'issueStatus', text: 'Status', sort: true },
    { dataField: 'issueName', text: 'Issue Name', sort: true },
    { dataField: 'issuerName', text: 'Issuer Name', sort: true },
    { dataField: 'projectName', text: 'Project Name', sort: true },
    { dataField: 'actualSaleDate', text: 'Actual Sale Date', sort: true, formatter: (row) => CommonService.dateFormatter(row['actualSaleDate']) },
     { dataField: 'principalSaleAmt', text: 'Principal Amount', sort: true, formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt']) },
    { dataField: 'adtrBatchId', text: 'Batch #', sort: true },
  ]
 
  const submit = (e) => { 
    CommonService.showHideSpinner(true);     

    let obj = {       
    "msgId": null,        
    "sysId": null,        
    "opr": "reviewAdtr",        
    "hdrStruct": null,       
    "queryParam": null,       
    "reqData": {           
          "userId" : loginUserId,           
          "adtr" : selected,
          "env" : "I"     
      }      
    }    
    
    BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {          
          if (result.data.errors && result.data.errors.length > 0){              
            toast.error(result.data.errors[0].message);          
          }          
          else{              
             toast.success("Selected ADTR processed successfully!");
             setSelected([]);     
          }  
        getSubmittedADTRs();         
        CommonService.showHideSpinner(false);     
      });    
  }
  return (
  <Card>          
    <div>            
      <div className="panel panel-default" id="stepspanel" style={{ marginBottom: '0px' }}>              
        <div className="panel-heading">                  
          <div className="container">                      
            <div id="packet_id" className="panel-title">                          
              <h1 className="menu-title">Submitted Annual Debt Transparency Reports</h1>
            </div>                  
          </div>             
        </div>              
        <div className="panel-body">               
          <div className="form">  
          {isLoading && <Spinner animation="border" variant="primary" />} 
          <DataGrid
          dataSource={adtrs}
          sortField='createDatetime'
          emptyDataMessage='No data available to display'
          selectionMode='checkbox'
          selection={selected}
          onSelectionChange={(e)=>{ setSelected(e.value) }}
          addNew={true}
          addNewBtnText='Mark ADTR as Reviewed'
          onAddNew={(e)=>submit(e)}
          btnDisable={selected.length > 0 ? false : true}
          >
          <Column selectionMode='multiple' headerStyle={{"width": "3rem"}} />

          {columns.map((column, i) => {
              return (
              <Column 
              key={i}
              field={column.dataField}
              header={column.text}
              sortable={column.sort} 
              body={column.formatter}
              hidden={column.hidden}
              />)
              }) 
          } 
          </DataGrid>                 
          </div>              
        </div>            
      </div>          
    </div>                        
  </Card>)
}

export default ReviewAdtr
