import { useEffect, useState } from 'react'
import {Row, Form, Modal} from 'react-bootstrap'
import CodeMaintenanceService from '../../services/CodeMaintenanceService'
import { validateFormFields } from '../../utils/index'

const EditReasonModal = (props) => { 
    const {onModalClose, handleSave, cdiacReasonCode} = props;
    const [codes, setCodes] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [stateValues, setStateValues] = useState({
        editReasonCodeId: null,
        comment: ''
    })

    useEffect(()=>{
        async function fetchEditReasons() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: cdiacReasonCode, active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
                .then((res) => res)
                .then((result) => {
                    setCodes(result.data.responses[0]);
                });
        }
        fetchEditReasons();
    },[cdiacReasonCode]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        if(name === "comment") {
            setCount(e.target.value.length);
            const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
            setBgcolor(color);
        }
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const handleSubmit = (e) => {
        let form = e.target.closest('form#editReasonModal');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
           handleSave(stateValues);
        }
    }

    return (
            <Modal show={true} onHide={onModalClose} >
                <Modal.Body>
                <form style={{textAlign: 'left'}} id="editReasonModal">
                    <Row>
                    <div style={{whiteSpace:"nowrap"}}>
                        <label><span className="required">*</span>Reason for edit:</label>
                        <Form.Select name="editReasonCodeId"
                                onChange={(e)=>handleChange(e)}
                                data-validations={["required"]}
                                value={stateValues.editReasonCodeId}
                                >
                            <option value="">select</option>
                            {codes.map((item, i) => {
                                return <option key={i} value={item.cdiacCodeValueId}>{item.cdiacCodeValueDesc}</option>
                            })}
                        </Form.Select>
                        {formErrors['editReasonCodeId'] && <span className="error">{formErrors['editReasonCodeId']} </span>}
                    </div>
                    </Row>
                    <Row>
                    <div>
                        <label style={{float: 'left'}}><span className="required">*</span>Comment</label>
                        <textarea name="comment" id="comments-input"  data-validations={["required"]}
                            className="form-control" rows="5" maxLength="4000"  value = {stateValues.comment} onChange={(e)=>handleChange(e)} placeholder="Add a comment..."/>
                        <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                        {formErrors['comment'] && <span className="error">{formErrors['comment']} </span>}
                    </div>
                    </Row>
                    <Modal.Footer>
                        <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                        <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </Modal.Footer>
                    </form>
                </Modal.Body>
                
            </Modal>
    ) 
}

export default EditReasonModal
