import { useState, useEffect } from 'react'
import Card from '../ui/Card'
import { Modal, Table } from 'react-bootstrap'
import { AiFillQuestionCircle } from 'react-icons/ai'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UserContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import AdtrService from '../../services/AdtrService'

const Comments = (props) => {
    const {adtrDetails, adtrIssueComments, updateAdtrDependencies} = props;
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [cdiacBgColor, setCdiacBgcolor] = useState('yellowgreen');
    const [cdiacCommentValue, setCdiacCommentValue] = useState("");
    const [cdiacCommentCount, setCdiacCommentCount] = useState(0);
    const [issueComments, setIssueComments] = useState(adtrIssueComments);
    const [stateValues, setStateValues] = useState({
        issuerComment : adtrDetails.issuerComment
    });

    const [showInstructionsModal, setShowInstructionsModal] = useState(false);

    const user = useContext(UserContext);
    const toggleText = (e, i) => {
        const history = [...issueComments];
        history[i].expanded = !history[i].expanded;
        if(history[i].expanded) {
            history[i].displayComment = history[i].comment;
        } else {
            history[i].displayComment =  history[i].comment.substr(0, 32) + "...";
        }
        setIssueComments(history);
    }
    async function fetchIssueComments () {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueComments",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "issueComment":{
                    "adtrId" : adtrDetails.adtrId 
                }
            },
            };
        
        AdtrService.getIssueComments(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0 ) {
                toast.error(result.data.errors[0].message);
            }else {
                let reasonsArr = [];
                result.data.responses[0].forEach((item, i) => {
                    item.displayComment = item.comment;
                    if( item.comment.length > 32 ) {
                        item.displayComment = item.comment.substr(0, 32) + "...";
                        item.expanded = false;
                    }
                    
                    reasonsArr.push(item);
                })
                setIssueComments(reasonsArr);
                updateAdtrDependencies(result.data.responses[0], 'issueComments');
            }
        });
    }

    useEffect(()=>{
        if(adtrIssueComments){
            let reasonsArr = [];
            adtrIssueComments.forEach((item, i) => {
                item.displayComment = item.comment;
                if( item.comment.length > 32 ) {
                    item.displayComment = item.comment.substr(0, 32) + "...";
                    item.expanded = false;
                }
                
                reasonsArr.push(item);
            })
            setIssueComments(reasonsArr);
        }
    },[adtrIssueComments])

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
    }

    const handleCdiacCommentValue = (e) => {
        setCdiacCommentCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setCdiacBgcolor(color);
        setCdiacCommentValue(e.target.value);
    };
    const saveCdiacComment = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "saveComment",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueComment" : {
                    "adtrId" : adtrDetails.adtrId ,
                    "comment": cdiacCommentValue,
                    "createUser": CommonService.getUserName(user),
                    "contactId": adtrDetails.contactId
                }
            }
        };
    
       await FinalSaleReportService.saveComment(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message);
            }
            else{
                fetchIssueComments();
                setCdiacCommentValue("");
            }
        });
    }
    const updateAdtrDetails = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putAdtr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "adtrId" : adtrDetails.adtrId,
                "adtr" : {...adtrDetails,
                    'issuerComment': stateValues.issuerComment, 
                    'lastUpdateUser' : CommonService.getUserName(user)
                }
            }
        };
    
        FinalSaleReportService.updateAdtr(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            } else{
                updateAdtrDependencies(result.data.responses[0], 'adtrInfo');
                toast.success("Comments updated successfully!");
            }
        });
    }
    const validateForm = async(e) => {
        await updateAdtrDetails();
        props.openWidget('retiredIssues');
    }
    return (
        <Card>
            <div className="form">
                <h3>Comments <AiFillQuestionCircle onClick={() => setShowInstructionsModal(true)} className="help-icon heading-help-icon" />

                    {showInstructionsModal &&
                        <Modal show={true} backdrop="static" onHide={() => setShowInstructionsModal(false)}>
                            <Modal.Body>
                                <div>
                                Add any comments about this submission that were not otherwise captured in the report. Comments may be publicly viewable.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" className="custom-button custom-button-secondary" onClick={() => setShowInstructionsModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }</h3>
                <div className="comments-box">

                    <textarea name="issuerComment" value={stateValues.issuerComment}
                        id="issuerComment" onChange={(e)=>handleChange(e)}
                        className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..." />
                    <span style={{ position: 'relative', left: '45%', backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                    
                    <div>
                    <label>CDIAC Comments: </label>
                    {issueComments.length > 0 &&
                        <Table>
                        <thead>
                            <tr>
                                <th>Comment</th>
                                <th>Create User</th>
                                <th>Create Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {issueComments.map((comment,i)=>{
                                    return (
                                        <tr key={i}>
                                        <td>{comment.displayComment}&nbsp; 
                                        {comment.hasOwnProperty('expanded') ? 
                                            (comment.expanded ? 
                                            <button
                                            type="button"
                                            className="link-button dk-blue-color"
                                            onClick={(e) => toggleText(e, i)}>show less
                                            </button>
                                            : <button
                                            type="button"
                                            className="link-button dk-blue-color"
                                            onClick={(e) => toggleText(e, i)}>show more
                                            </button>
                                            ) : ""
                                        }
                                        </td>
                                        <td>{comment.createUser}</td>
                                        <td>{CommonService.dateFormatter(comment.createDatetime)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                    <textarea name="comment" value={cdiacCommentValue} 
                    id="comments-input" onChange={handleCdiacCommentValue}
                     className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                     <span style={{position: 'relative', left: '45%' , backgroundColor: cdiacBgColor }}>{cdiacCommentCount} out of 4000 max</span>
                     <span style={{position: 'relative', float: 'right', marginTop: '5px' }}><button type="button" disabled={(cdiacCommentValue !== "") ? false : true} onClick={()=>saveCdiacComment()}>Submit</button></span>
                    </div>
                </div>

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('useOfProceeds')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={() => validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default Comments
