import CommonService from "./CommonService";

const getReports = (obj) => {
    return CommonService.apicall(obj)
};

const cancelReport = (obj) => {
    return CommonService.apicall(obj)
};

const saveEditReason = (obj) => {
    return CommonService.apicall(obj)
};

const getEditReasonHistory = (obj) => {
    return CommonService.apicall(obj)
}

const isReviewedReportCanEdit = (obj) => {
    return CommonService.apicall(obj)
}

const ReportsService = {
    getReports,
    cancelReport,
    saveEditReason,
    getEditReasonHistory,
    isReviewedReportCanEdit
};


export default ReportsService;