import {useState, useContext} from 'react'
import Card from '../ui/Card'
import {Form, Row, Col, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {UserContext} from "../../UserContext";
import { runValidations, validateFormFields } from '../../utils'
import MaxCurrencyInput from '../formElements/MaxCurrencyInput'
import CommonService from '../../services/CommonService'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const AssessesValue = (props) => {
    const {melloRoosDetails} = props
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const loginUser = useContext(UserContext);

    const [stateValues, setStateValues] = useState({
        assessmentFlag : melloRoosDetails.assessmentFlag,
        assessmentDate: melloRoosDetails.assessmentDate? new Date(melloRoosDetails.assessmentDate) : null,
        assessmentAmt: melloRoosDetails.assessmentAmt
    })

    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateForm = (e) => {
        
        let form = e.target.closest('form#assesedValueForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            console.log(formErrors);
            setFormErrors(formErrors);
            //props.sectionValidated('issuer', false);
        } else {
            
            setFormErrors({});
            updateMelloRoosAssessment();
            // const melloRoos={};
            // melloRoos.issuance = issuanceInfo;
            // sessionStorage.setItem('MelloRoos', JSON.stringify(melloRoos));
            //props.sectionValidated('issuer', true);
            props.openWidget('taxCollection');
        }
    }

    const updateMelloRoosAssessment = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMelloRoosAssessment",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": melloRoosDetails.melloRoosId, 
                "env" : "I",
                "melloRoos":{
                    "assessmentDate" : stateValues.assessmentDate,
                    "assessmentFlag" : stateValues.assessmentFlag,                
                    "assessmentAmt" : stateValues.assessmentAmt,
                    "lastUpdateUser":  CommonService.getUserName(loginUser),
                    "emailId": loginUser
                }
            }
        };
    
       await FinalSaleReportService.updateMelloRoosAssessment(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                props.updateMelloRoosDetails(result.data.responses[0]);
                toast.success("Mello Roos Assessment updated Successfully!!");
            }

        });
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    const handleAssessmentDate = (e) => {
        setStateValues(prevState=>({
            ...stateValues, 
            'assessmentDate': e
        })
        )
    }

    return (
        <Card>
            <form className="form" id="assesedValueForm">
                <h4>Assessed Value  <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    {/* <Modal.Header closeButton><h3>Issuance Authorization</h3></Modal.Header> */}
                    <Modal.Body>
                        <div>
                            <ul>
                                <li>Issuers should report the total assessed value of parcels (land and improvements), which are subject to or may be subject to the special tax, shown on the most recent tax roll at the county assessor’s office. </li>
                                <li>Include the date of the tax roll used on the report.</li>
                                <li>Indicate either the Equalized Tax Roll or Appraisal of Property.</li>
                                <li>The appraisal value of the property should only be used in the first year of the bond issue or before annual tax billing commences.</li>
                                 <li>For issuers selling prior to June 30th of the year or those issuers that have 12 months of capitalized interest available and for which no annual tax roll billing will be required, the most recent appraisal value of the property may be reported as the assessed value.</li>
                                 </ul>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }</h4>      
                <Row>
                <Col md={6}>
                <div className="control">
                    <label><span className="required" >* </span>Assessed or Appraised Value Reported as of,</label>
                    <div>
                        <DatePicker 
                        selected={stateValues && stateValues.assessmentDate ? new Date(stateValues.assessmentDate) : null}
                        dateFormat="M/d/yyyy"
                        name="assessmentDate"
                        maxDate= {new Date(9999, 12, 31)}
                        onChange={(e) => handleAssessmentDate(e)} 
                        error={formErrors && formErrors['assessmentDate']}        
                        showIcon                   
                        />
                        {formErrors['assessmentDate'] && <p className="error">{formErrors['assessmentDate']}</p>}
                            <input type="hidden" data-validations={["required"]}  value={CommonService.dateFormatter(stateValues.assessmentDate)} name="assessmentDate" />
                    </div>
                    </div>
                    </Col>
                </Row>
                <div className="mt-10">
                    <label><span className="required" >* </span>Use Appraised Value only in first year or before annual tax roll billing commences</label>
                    <div >
                    
                    <Form.Check inline type="radio" id="assessmentFlag_E" name="assessmentFlag" checked={stateValues.assessmentFlag === "E"} value="E" label="From Equalized Tax Roll" data-validations="required" onChange={(e)=>handleChange(e)}/>
                    <Form.Check inline type="radio" id="assessmentFlag_A" name="assessmentFlag" checked={stateValues.assessmentFlag === "A"} value="A" label="From Appraisal of Property" data-validations="required" onChange={(e)=>handleChange(e)}/>
                    </div>
                    { formErrors['assessmentFlag'] && <p className="error">{ formErrors['assessmentFlag'] }</p>}
                
                </div>
                <div className="mt-10">
                    <Row>
                        <Col md={6}>
                        <div className="control">
                            <MaxCurrencyInput 
                            placeholder="0.00" 
                            label="Total Assessed Value of All Parcels" 
                            name="assessmentAmt" 
                            type="text"             
                            value={stateValues.assessmentAmt ? stateValues.assessmentAmt  : ""}
                            onChange={(e)=>handleChange(e)} 
                            onBlur={e=>validateField(e)}
                            error={formErrors.assessmentAmt} 
                            validations={["required", "max:9999999999999.99"]}
                            isrequired="true"
                            />
                            
                   </div>

                        {/* <label><span className="required" >* </span>Total Assessed Value of All Parcels</label>
                        <Form.Control type="text" name="totalAssessedValue" placeholder="0.00" data-validations="required" style={formErrors['totalAssessedValue'] && {border: '1px solid red'}}/>
                        { formErrors['totalAssessedValue'] && <p className="error">{ formErrors['totalAssessedValue'] }</p>} */}
                        </Col>
                    </Row>
                </div>

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('fundBalance')} >Back</button> */}
                    <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>

            </form>
        </Card>
    )
}

export default AssessesValue
