import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditDebtInstrumentForm from './editDebtInstrumentForm'
import AddDebtInstrumentForm from './addDebtInstrumentForm'
import DebtInstrumentMaintenanceService from "../../../services/DebtInstrumentMaintenanceService";
import Spinner from 'react-bootstrap/Spinner';
import CommonService from "../../../services/CommonService";
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const DebtDetails = (props) => {
    const [openEditCodeModal, setOpenModal] = useState(false);
    const [openAddCodeModal, setOpenAddCodeModal] = useState(false);
    const [debtGroups, setDebtGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editDebtInfo, setEditDebtInfo] = useState(null);
    useEffect(() => {
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getDebtInstrumentRefsByGroupId",
        hdrStruct: null,
        queryParam: null,
        reqData: { "id": props.selectedDebtGroup },
      };
  
      DebtInstrumentMaintenanceService.getAllDebtGroups(obj)
        .then((res) => res)
        .then((result) => {
          Object.keys(result.data.responses).forEach((key) => {
              setDebtGroups(result.data.responses[key]);
          });
          setIsLoading(false);
        });
    }, [props, openAddCodeModal, openEditCodeModal]);

    const viewFormatter = (row) => { 
        return ( 
              <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
           <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
         </div> 
        ); 
    }
    const onRowClick = (e, row) => {
       // e.preventDefault();
        setOpenModal(true);
        setEditDebtInfo(row);
    }

    const columns = [{
        dataField: 'debtInstrumentCode',
        text: 'Code',
        sort: true,
        headerStyle: () => {
          return { width: '10%'};
        },
      }, {
        dataField: 'debtInstrumentName',
        text: 'Name',
        sort: true,
      }, {
        dataField: 'createDatetime',
        text: 'Date Created',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['createDatetime'])
      },{
        dataField: 'lastUpdateDatetime',
        text: 'Last Updated',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['lastUpdateDatetime'])
      }, {
        dataField: 'createUser',
        text: 'Created By',
        sort: true,
      },{
        dataField: 'lastUpdateUser',
        text: 'Last Updated By',
        sort: true,
      },{
        dataField: 'activeInd',
        text: 'Active',
        sort: true,
        headerStyle: () => {
          return { width: '5%'};
        }
      },{ 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];
      
    return (
        <>
        {isLoading && <Spinner animation="border" variant="primary" />}

        <div className="form">
            <DataGrid
            dataSource={debtGroups}
            sortField='debtInstrumentCode'
            title={props.selectedDebtGroupVal}
            searchToolTip="Use the search feature to quickly retrieve the system code values" 
            addNew={true}
            addNewBtnText="Add New Debt Instrument"
            onAddNew={()=>setOpenAddCodeModal(true)}
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                />)
              }) 
            } 
            </DataGrid>
        </div>
        {openEditCodeModal && <EditDebtInstrumentForm onModalClose={()=>setOpenModal(false)} debtInfo={editDebtInfo} debtGroup={props.selectedDebtGroupVal}/>}
        {openAddCodeModal && <AddDebtInstrumentForm onModalClose={()=>setOpenAddCodeModal(false)} debtGroup={props.selectedDebtGroupVal} selectedDebtGroup ={props.selectedDebtGroup}/>}
        </>
    )
}

export default DebtDetails
