import CommonService from "./CommonService";

const getIssuerContactByIssuerId = (obj) => {
    return CommonService.apicall(obj)
};

const getIssuersByUserId = (obj) => {
    return CommonService.apicall(obj)
}

const createNewContact = (obj) => {
    return CommonService.apicall(obj)
}

const getIssuerAssocs = (obj) => {
    return CommonService.apicall(obj)
}
const getIssuerByIssuerId = (obj) => {
    return CommonService.apicall(obj)
}


const IssuerService = {
    getIssuerContactByIssuerId,
    getIssuersByUserId,
    createNewContact,
    getIssuerAssocs,
    getIssuerByIssuerId
};


export default IssuerService;
