import {useState, useEffect, useContext, Fragment} from 'react'
import Card from '../ui/Card'
import {Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import AddLocalObligor from './addLocalObligor'
import { toast } from 'react-toastify'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import {UserContext} from '../../UserContext'
import CommonService from '../../services/CommonService'
import swal from 'sweetalert'
import ReadOnlyRow from '../formElements/ReadOnlyRow'
import EditableRow from '../formElements/EditableRow'

const LocalObligor = (props) => {
    const {markRoosAuthorityDetails, markRoosAuthority} = props;
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [localObligors, setLocalObligors] = useState([]);
    const loginUser = useContext(UserContext);
    const [editLobInd, setEditLobInd] = useState(null);
    const [editLobData, setEditLobData] = useState(null);

    const handleChange = (e, i) => {
        const { name, value } = e.target;
        let newEditLobData = {...editLobData};
        newEditLobData[name] = value;
        setEditLobData(newEditLobData);
    }

    const removeMarkRoosLocalObligor = (localObligorId)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "deleteLocalObligorFee",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
              "id" : localObligorId,
            }
        };
  
        FinalSaleReportService.removeMarkRoosLocalObligor(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                toast.success("Mark Roos Local Obligor removed successfully");
                getLocalObligors();
            }
        });
    }

    const handleRemove  = (i,rowData) => {
        swal("Are you sure you want to remove this Local Obligor fees?", {
            buttons: ["No", "Yes"],
        }).then((value) => {
           if(value){
            const localObligorId = rowData.localObligorId;
            removeMarkRoosLocalObligor(localObligorId);
           }
        });
    }

    const addLocalObligor = () => {
        setOpenAddModal(true);
    }

    const handleModalClose = () => {
        setOpenAddModal(false);
    }

    const validateForm = () => {
        if(localObligors.length !== 0 ) {
            props.openWidget('investmentContracts');
        } else {
            alert('Please add at least one Local Obligor.');
        }
    }

    useEffect(() => {
        getLocalObligors();
        // eslint-disable-next-line
    }, [openAddModal]);

    const getLocalObligors = async () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getMarkRoosAuthorityLocalObligorFee",
            hdrStruct: null,
            queryParam: null,
            reqData: { id: markRoosAuthorityDetails.markRoosAuthorityId },
        };
    
        await FinalSaleReportService.getMarkRoosAuthorityLocalObligorFee(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                setLocalObligors(result.data.responses[0]);
                props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'localObligors');
            }

        })
    }

    const handleLobCancelClick = () => {
        setEditLobInd(null);
    }

    const handleLobEditClick = (event, i, item) => {
        event.preventDefault();
        setEditLobInd(i);
        setEditLobData(item);
    }

    const updateMarkRoosAuthorityLocalObligorFee = (e, rowData)  => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMarkRoosAuthorityLocalObligorFee",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { 
                "id" : rowData.localObligorId,
                "markRoosAuthorityLocalObligorFee":{
                    "adminFeeAmt" : rowData.adminFeeAmt,
                    "localObligorId": rowData.localObligorId,        
                    "lastUpdateUser":  CommonService.getUserName(loginUser),
                    "emailId": loginUser
                },
                "env": "I"
            }
        };

        FinalSaleReportService.updateMarkRoosAuthorityLocalObligorFee(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                toast.success("Mark Roos Local Obligor Fee updated successfully");
                getLocalObligors();
                setEditLobInd(null);;
            }
        });
    }
    return (
        <Card>
            <div className="form">
                <h3>Local Obligors <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                {showInstructionsModal && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                        <Modal.Body>
                            <div>
                            Select each LOB agency and provide the administration fee which is charged to this LOB within this Fiscal Year.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }
                </h3>

                <div>
                    <form>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                            <th>CDIAC Number</th>
                            <th>Issuer Name</th>
                            <th>Principal Amount</th>
                            <th>Obligor Type</th>
                            <th>Administration Fee</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {(localObligors && localObligors.length > 0) ? localObligors.map((item, i)=>(
                            <Fragment>
                                {editLobInd === i ? (
                                    <EditableRow 
                                    key={i}
                                    data={editLobData}
                                    handleChange={(e)=>handleChange(e, i)}
                                    handleCancelClick={()=>handleLobCancelClick()}
                                    handleUpdate={(e)=>updateMarkRoosAuthorityLocalObligorFee(e, editLobData )}
                                    columns={[
                                        {
                                            prop: "cdiacNbr",
                                            editable: false,
                                            fieldType: "text",
                                            
                                        },{
                                            name: "Issuer Name",
                                            prop: "issuerName",
                                            editable: false
                                        },{
                                            prop: "principalSaleAmt",
                                            editable: false,
                                            fieldType: "currency",
                                        },{
                                            prop: "obligorType",
                                            editable: false,
                                            fieldType: "text",
                                        },
                                        {
                                            prop: "adminFeeAmt",
                                            fieldType: "currency",
                                        }
                                        ]}
                                    />
                                ) : (
                                    <ReadOnlyRow 
                                    key={i}
                                    data={item} 
                                    handleEditClick={(e)=>handleLobEditClick(e, i, item) } 
                                    handleDeleteClick={(e)=>handleRemove(e, item)}
                                    isActionsRequired={true}
                                    columns={[
                                        {
                                            prop: "cdiacNbr",
                                            fieldType: "text"
                                        },{
                                            prop: "issuerName",
                                            fieldType: "text"
                                        },{
                                            prop: "principalSaleAmt",
                                            fieldType: "currency",
                                        },{
                                            prop: "obligorType",
                                            fieldType: "text"
                                        },
                                        {
                                            prop: "adminFeeAmt",
                                            fieldType: "currency",
                                        }
                                        ]}
                                    />
                                )}
                            </Fragment>
                        )):
                        <tr><td colSpan="5" style={{textAlign:'center'}}>No data available to display.</td></tr>
                        }
                        <tr>
                            <td><b>Total:</b></td>
                            <td></td>
                            <td>{CommonService.currencyFormatter(localObligors && localObligors.reduce((a, v) => a + (v.principalSaleAmt), 0))}</td>
                            <td></td>
                            <td>{CommonService.currencyFormatter(localObligors && localObligors.reduce((a, v) => a + (v.adminFeeAmt), 0))}</td>
                        </tr>
                        </tbody>
                    </table>
                    </form>
                </div>

                {openAddModal && <AddLocalObligor onModalClose={handleModalClose} markRoosAuthorityDetails={markRoosAuthorityDetails} markRoosAuthority={markRoosAuthority} /> }
                <button type="button" className="custom-button" onClick={() => addLocalObligor()}>Add Local Obligor</button>


                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('professionalFees')} >Back</button> */}
                    <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default LocalObligor
