import { Form } from 'react-bootstrap';
import {useState, useEffect} from 'react'
//import { useLocation, useNavigate } from 'react-router-dom'
import Card  from '../../ui/Card'
import ReportsDetails from './reportsDetails';
import ReportRefService from "../../../services/ReportRefService";
import Spinner from 'react-bootstrap/Spinner'

const AdminReports = () => {
    // const history = useNavigate();
    // const {pathname} = useLocation();
    const [selectedReportGroup, setSelectedReportGroup] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [reportRefs, setReportRefs] = useState([]);
    const [selectedReportGroupName, setSelectedReportGroupName] = useState(null);

    useEffect(() => {
        var obj = {
          msgId: "null",
          sysId: null,
          opr: "GetLookups",
          hdrStruct: null,
          queryParam: null,
          reqData: { cdiacCode: "RPTG", active: "Y" },
        };
    
        ReportRefService.getReportGroups(obj)
          .then((res) => res)
          .then((result) => {
            setReportRefs(result.data.responses);
            setIsLoading(false);
          });
      }, []);

    

    const handleChange = (e) => {
      
        let reportGroup = e.target.value;
        setSelectedReportGroup(reportGroup);
        setSelectedReportGroupName(e.target[e.target.selectedIndex].text);
    }

    return (

        <Card>
            {isLoading && <Spinner animation="border" variant="primary" />}
            <div className="form">
                <h1>Reports</h1>

                <div>
                    <label>Report Group: </label>
                    {Object.keys(reportRefs).map((key) => {
                    return (
                      <div key={key}>
                        <Form.Select
                          as="select"
                          onChange={(e) => handleChange(e)}
                          value={selectedReportGroup}
                        >
                          <option>Select</option>
                          {reportRefs[key].map((dataItem) => {
                            return (
                              <option
                                key={dataItem.cdiacCodeValueCode}
                                value={dataItem.cdiacCodeValueId}
                              >
                                {dataItem.cdiacCodeValueDesc}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    );
                  })}
                </div>
<br/>
                {selectedReportGroup && <ReportsDetails selectedReportGroup={selectedReportGroup} selectedReportGroupName={selectedReportGroupName} />}
                
            </div>
            
        </Card>
    )
}

export default AdminReports
