import { Modal, Table} from 'react-bootstrap'
import { useState, useEffect} from 'react'
import Spinner from 'react-bootstrap/Spinner';
import AuthorizationMaintenanceService from "../../../services/AuthorizationMaintenanceService";
import CommonService from "../../../services/CommonService";
import { AlertType } from '../../../utils';

const AuthorizationSummary = (props) => {
    const {onModalClose, authInfo } = props;
    const [authorizationSummaries, setAuthorizationSummary] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getAuthorizationSummary",
          hdrStruct: null,
          queryParam: null,
          reqData:{"authorizationId" : authInfo.authorizationId}
        };
    
        AuthorizationMaintenanceService.getIssuances(obj)
          .then((res) => res)
          .then((result) => {
            if(result.data.statusCode === 401 || result.data.statusCode === 500) {
                CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
            } else {
                setAuthorizationSummary(result.data.responses);
            }
            setIsLoading(false);
          });
      }, [props, authInfo]);
    return (
        <Modal show={true} backdrop="static" onHide={onModalClose} className="adtrSummaryModal">
            <Modal.Header closeButton><h3>ADTR Authorization Summary</h3></Modal.Header>
            <Modal.Body>
            {isLoading && <Spinner animation="border" variant="primary" />}
            {Object.keys(authorizationSummaries).map((key) => {
          return (
            <div key={key}>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>FY End</th>
                            <th>Beginning $</th>
                            <th>$ Replenished</th>
                            <th>$ Increased</th>
                            <th>$ Reduced</th>
                            <th>$ Lapsed</th>
                            <th>Ending $</th>
                        </tr>
                    </thead>
                    <tbody>
                    {authorizationSummaries[key].map((dataItem, index) => {
                        return (
                            <tr key={index}>
                                <td>{CommonService.dateFormatter(dataItem.fiscalYearEndDate)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.beginAmount)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.authorizedReplenishAmount)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.duringAmount)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.authorizedReducedAmount)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.lapsedAmount)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.endAmount)}</td>
                            </tr>
                        );
                    })}
                    </tbody>

                </Table>
                </div>
          );
        })}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default AuthorizationSummary
