import { useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./components/layout/Layout";
import Dashboard from "./components/dashboard/index";
import ProposedSaleReport from "./components/proposedSaleReport";
import FinalSaleReport from "./components/finalSaleReport";
import Reports from "./components/reports";
import CodeMaintenance from "./components/codeMaintenance";
import CodeDetails from "./components/codeMaintenance/codeDetails";
import DebtInsMaintenance from "./components/admin/debtInstrumentMaintenance";
import FinancingPurposeMaintenance from "./components/admin/financingPurposeMaintenance";
import CountyMaintenance from "./components/admin/countyMaintenance";
import StatuteMaintenance from "./components/admin/statuteMaintenance";
import OrganizationMaintenance from "./components/admin/organizationMaintenance";
import AuthorizationMaintenance from "./components/admin/authorizationMaintenance";
import FeeScheduleMaintenance from "./components/admin/feeScheduleMaintenance";
import StateElection from "./components/admin/stateElection";
import IssuerMaintenance from './components/admin/issuerMaintenance';
import OrgMaintenance from './components/admin/orgMaintenance';
import PendingAssociationRequests from './components/admin/pendingAssociationRequests';
import RemittanceAdviceTemplate from './components/admin/remittanceAdviceTemplate';
import Associations from "./components/admin/associations";
import OrgDetails from "./components/admin/organizationMaintenance/orgDetails";
import AdminReports from "./components/admin/reports";
import DebtDetails from "./components/admin/debtInstrumentMaintenance/debtDetails";
import Admin from "./components/admin";
import PrivateSaleReasonReport from "./components/privateSaleReasonReport";
import MelloRoos from "./components/melloRoos";
import MKRLocalObligors from "./components/mkrLocalObligors";
import MKRLocalObligorsYFS from "./components/mkrLocalObligors/mkrLocalObligorsYFS";
import MKRAuthorityIssue from "./components/mkrAuthorityIssue";
import MKRAuthorityIssueYFS from "./components/mkrAuthorityIssue/mkrAuthorityIssueYFS";
import MelloRoosYFS from "./components/melloRoos/melloRoosYFS";
import DrawOnReserve from "./components/drawOnReserve";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "./UserContext";
import { toast } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import IssuerFilerRequests from "./components/admin/issuerFilerRequests";
import StatusHistoryUploadDocuments from "./components/admin/statusHistoryUploadDocuments";
import AdtrYFS from "./components/adtr/adtrYFS";
import Adtr from "./components/adtr";
import { useMsal } from "@azure/msal-react";
import Invoice from "./components/invoice/InvoiceForm";
import InvoiceComment from "./components/invoice/InvoiceForm/Comment";
import InvoicePayment from "./components/invoice/InvoiceForm/Payment";
import FileNet from "./components/fileNet";
import AzureDocs from "./components/azureDocs";
import ADTRBatch from "./components/admin/adtrBatch";
import ReviewAdtr from "./components/admin/reviewAdtr";
import { hasGroupAccess } from "./graph";
import { useEffect } from "react";
import CommonService from "./services/CommonService";
//import NotAuthorizedView from "./common/NotAuthorizedView";
import DebtWatchConfiguration from "./components/admin/debtwatchConfiguration";

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const loginUsername = accounts[0] ? accounts[0].username : "";
  const [groupState, setGroupState] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  useEffect(()=>{
    CommonService.showHideSpinner(true);
   
    const getUser = async() => {
    if (accounts.length > 0) {
      const accessTokenRequest = {
          scopes: ["openid"],
          account: accounts[0]
      };
      
      async function checkForGroupAccess(accessToken) {
        let hasAccess = await hasGroupAccess(accessToken);
        setGroupState(true);
        console.log(`Has Group Access `+ hasAccess);
        if(hasAccess === false) {
          instance.logout().catch(e => {
            console.error(e);
          });        
        }
      }
      await instance.acquireTokenSilent(accessTokenRequest)
        .then(async (accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
          if(groupState === false) {
            localStorage.setItem("token", accessTokenResponse.idToken);
            checkForGroupAccess(accessToken)
          }
        }).catch(function(error) {
          console.log(error);
      }).finally(()=>{
          CommonService.showHideSpinner(false);
        });
    }
  }
  getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [accounts]);

  const onIdle = () => {
    if (isAuthenticated) {
      setModalIsOpen(true);
      sessionTimeoutRef.current = setTimeout(logOut, 300000);
    }
  };

  const logOut = () => {
    setModalIsOpen(false);
    instance
      .logout()
      .then((response) => {
        toast.info(
          "You have been logged-out of the system due to inactivity. Please login again.",
          { autoClose: false, closeOnClick: true }
        );
        localStorage.removeItem("token");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const stayActive = () => {
    console.log("User is active");
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  const idletimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 55 * 60000,
    onIdle: onIdle
  })

  return (
    <>
        {!isAuthenticated ? (
          <Routes><Route path="*" Component={Login} /></Routes>
        ) : (
          <Layout user={accounts[0]}>
            {/* <Routes><Route exact path="/notAuthorized" Component={NotAuthorizedView} /></Routes> */}
            <UserContext.Provider value={loginUsername}>
              <Routes>
              <>
              <Route path="/" exact Component={Dashboard} />
              <Route exact path="/:reportType/reports" Component={Reports} />
              <Route exact path="/admin/code-maintenance" Component={CodeMaintenance} />
              <Route exact path="/admin/code-maintenance/:code" Component={CodeDetails} />
              <Route exact path="/admin/debt-instrument-maintenance" Component={DebtInsMaintenance} />
              <Route exact path="/admin/financing-purpose-maintenance" Component={FinancingPurposeMaintenance}/>
              <Route exact path="/admin/reports" Component={AdminReports} />
              <Route exact path="/admin/county-maintenance" Component={CountyMaintenance} />
              <Route exact path="/admin/statute-maintenance" Component={StatuteMaintenance} />
              <Route exact path="/admin/organization-maintenance" Component={OrganizationMaintenance} />
              <Route exact path="/admin/authorization-maintenance" Component={AuthorizationMaintenance} />
              <Route exact path="/admin/fee-schedule-maintenance" Component={FeeScheduleMaintenance} />
              <Route exact path="/admin/state-election" Component={StateElection} />
              <Route exact path="/admin/organization-maintenance/:orgId" Component={OrgDetails} />
              <Route exact path="/admin/debt-instrument-maintenance/:code" Component={DebtDetails} />
              <Route exact path="/admin/associations" Component={Associations} />
              <Route exact path="/admin/issuer-filer-requests" Component={IssuerFilerRequests} />
              <Route exact path="/admin/issuer-maintenance" Component={IssuerMaintenance} />
              <Route exact path="/admin/org-maintenance" Component={OrgMaintenance} />
              <Route exact path="/admin/pending-association-requests" Component={PendingAssociationRequests} />
              <Route exact path="/admin/remittance-advice-template" Component={RemittanceAdviceTemplate} />
              <Route exact path="/admin/adtr-batch" Component={ADTRBatch} />
              <Route exact path="/admin/adtr" Component={ReviewAdtr} />
              <Route exact path="/admin/status-history-upload-documents" Component={StatusHistoryUploadDocuments} />
              <Route exact path="/admin/status-history-upload-documents/:cdiacNbr" Component={StatusHistoryUploadDocuments} />
              <Route exact path="/admin/debtwatch-configuration" Component={DebtWatchConfiguration} />
              <Route exact path="/admin" Component={Admin} />
              <Route exact path="/mello-roos/:cdiac" Component={MelloRoos} />
              <Route exact path="/mello-roos-yfs/:cdiac" Component={MelloRoosYFS} />
              <Route exact path="/mkr-local-obligors/:cdiac" Component={MKRLocalObligors} />
              <Route exact path="/mkr-local-obligors-yfs/:cdiac" Component={MKRLocalObligorsYFS} />
              <Route exact path="/mkr-authority-issue/:cdiac" Component={MKRAuthorityIssue} />
              <Route exact path="/mkr-authority-issue-yfs/:cdiac" Component={MKRAuthorityIssueYFS} />
              <Route exact path="/adtr/:cdiac" Component={Adtr} />
              <Route exact path="/adtr-yfs/:cdiac" Component={AdtrYFS} />
              <Route exact path="/dashboard" Component={Dashboard} />
              <Route exact path="/dashboard/report-proposed-sale/:issueId" Component={ProposedSaleReport}/>
              <Route exact path="/dashboard/report-final-sale/:finalSaleId" Component={FinalSaleReport} />
              <Route exact path="/report-private-sale-reason" Component={PrivateSaleReasonReport} />
              <Route exact path="/dashboard/draw-on-reserve/:drawId" Component={DrawOnReserve} />
              <Route exact path="/invoice/InvoiceForm" Component={Invoice} />
              <Route exact path="/invoice-payments/:invoiceId/:invoiceStatusFlag" Component={InvoicePayment}/>
              <Route exact path="/invoice/invoiceForm/comment/:invoiceId" Component={InvoiceComment} />
              <Route exact path="/invoice/invoiceForm/Payment" Component={InvoicePayment} />
              <Route exact path="/fileNet" Component={FileNet} />
              <Route exact path="/azureDocs" Component={AzureDocs} />
              </>
              </Routes>
            </UserContext.Provider>
          </Layout>
        )}
      <ToastContainer closeButton={false} position="top-right" />
      <Modal show={modalIsOpen} style={{ maxWidth: "500px", margin: "0 auto" }}>
        <Modal.Header>
          <h3>Session Timeout in five (5) minutes</h3>
        </Modal.Header>
        <Modal.Body>
          <p>You're being timed out due to inactivity.</p>
          <p>
            Please choose to stay signed in, or to logoff. Otherwise you will
            logged off automatically.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="fright">
            <Button variant="default" onClick={logOut}>
              {" "}
              Log Out
            </Button>{" "}
            <Button onClick={stayActive}> Keep signed in</Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div idletimer={idletimer} />
      {/* <IdleTimer
        ref={idleTimerRef}
        timeout={55 * 60000}
        onIdle={onIdle}
      ></IdleTimer> */}
    </>
  );
}

export default App;
