import {useState, useEffect, useContext} from 'react'
import { Form, Spinner, Row, Col } from 'react-bootstrap'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { validateFormFields } from '../../../utils/index'
import Card from '../../ui/Card'
import FinalSaleReportService from '../../../services/FinalSaleReportService'
import CodeMaintenanceService from '../../../services/CodeMaintenanceService'
import { UserContext } from '../../../UserContext'
import { toast } from 'react-toastify'
import CommonService from '../../../services/CommonService'
import { FormInput } from '../../formElements/FormInput'
import TooltipComponent from '../../../common/Tooltip'


const SourceOfRepayment = (props) => {
    const {finalSaleDetails} = props;
    const [finalSaleInfo, setFinalSaleInfo] = useState(finalSaleDetails ? finalSaleDetails : {});
    const {finalSaleId} = finalSaleDetails;
    const [formErrors, setFormErrors] = useState({});
    const [sources, setRepaymentSources] = useState([]);
    const [issueRepaymentSources, setIssueRepaymentSources] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showOtherTextField, setShowOtherTextField] = useState(false);
    const loginUser = useContext(UserContext);

    useEffect(() => {
        const fetchCodes = async () => {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: {cdiacCode: "SORP", active: "Y"},
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                setRepaymentSources(filteredList);
            });
        }
    
        const fetchIssueRepaymentResources = async() => {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueRepaymentSourcesByIssueId",
            hdrStruct: null,
            queryParam: null,
            reqData: { "id" : finalSaleId, "reportType" : "RFS"},
            };
        
           await FinalSaleReportService.getIssueRepaymentSourcesByFinalSaleId(obj)
            .then((res) => res)
            .then((result) => {
                setIssueRepaymentSources(result.data.responses[0]);
                if(result.data.responses[0].length > 0 && result.data.responses[0][0].repymtSrcCodeValueCode === "OTHS") {
                    setShowOtherTextField(true);
                }
            });

        }

        fetchCodes();
        fetchIssueRepaymentResources();
        setTimeout(()=>{
            setIsLoading(false);
        }, 1000)
    },[finalSaleId]);
    
    const handleRepaymentResourceChange = (e, source) => {
        const issRepaymentSources = [...issueRepaymentSources];
        const { value } = e.target;
        let obj = {};
        obj['proposeDebtId'] = null;
        obj['repymtSrcCodeId'] = parseInt(value);
        obj['repymtSourceId'] = issRepaymentSources.length > 0 ? issRepaymentSources[0].repymtSourceId : 0;
        obj['createUser'] = CommonService.getUserName(loginUser);
        obj['finalSaleId'] = finalSaleId;
        issRepaymentSources[0] = obj;
        setIssueRepaymentSources(issRepaymentSources);
        if(source.cdiacCodeValueCode === "OTHS") {
            setShowOtherTextField(true);
        } else {
            setShowOtherTextField(false);
        }
    }

    const handleOtherTextChange = (e) => {  
        const {value} = e.target;
        const issRepaymentSources = [...issueRepaymentSources];
        issRepaymentSources[0].otherText = value;
        setIssueRepaymentSources(issRepaymentSources);
    }
    const handleChange = (e) => {  
        const {name, value} = e.target;
        setFinalSaleInfo({
            ...finalSaleInfo,
            [name]: value
        });
    }

    const updateRepaymentSources = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "mergeIssueRepaymentSourcesByIssueId",
            hdrStruct: null,
            queryParam: null,
            reqData: { "id" : finalSaleId,
            "issueRepaymentSources": issueRepaymentSources,
            "reportType" : "RFS"
            },
        };
        
        await FinalSaleReportService.mergeIssueRepaymentSourcesByFinalSaleId(obj)
        .then((res) => res)
        .then((result) => {
            props.updateFinalSaleDependencies(result.data.responses[0], 'issueRepaymentSources');
        });
    }
    const updateFinalSale = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };
    
        await FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Source of Repayment updated successfully!")
                props.updateFinalSaleDetails(result.data.responses[0]);
                props.sectionValidated('sourceOfRepayment', true);
                props.openWidget('purposeOfFinancing');
            }
        });
    }
    const validateForm = (e) => {
        let form = e.target.closest('form#sourceOfRepaymentForm');
        let formErrors = validateFormFields(form.elements);
      
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('sourceOfRepayment', false);
        } else {
            setFormErrors({});
            if( issueRepaymentSources.length === 0) {
                alert("Please select source of repayment.");
            } else {
                updateRepaymentSources();
                updateFinalSale();
            }
        }
    }
    return (
        <Card>
            <form className="form" id="sourceOfRepaymentForm">
                <label><span className="required">*</span>Source of Repayment</label>

                <div>
                {isLoading && <Spinner animation="border" variant="primary" />}
                {(sources.length !== 0) && sources.map((source, i) =>{
                        return (<div><Form.Check key={i} inline name="repaymentSource" 
                        type="radio" 
                        id={`repaymentSource_${i}`}
                        data-validations="required" 
                        value={source.cdiacCodeValueId} label={source.cdiacCodeValueDesc} style={{marginTop: 0}} 
                        checked={issueRepaymentSources.some(el => el.repymtSrcCodeId === source.cdiacCodeValueId)} 
                        onChange={(e)=>handleRepaymentResourceChange(e, source)}/></div>);
                    }
                ) }
                {formErrors['repaymentSource'] && <p className="error">{formErrors['repaymentSource']}</p>}
                </div>
                {showOtherTextField && <FormInput label="Other Text" 
                    name="otherText" 
                    value={issueRepaymentSources[0].otherText} 
                    onChange={(e)=>handleOtherTextChange(e)}
                    isrequired="true"
                    validations={["required"]}
                    maxLength="120"/>}
                {formErrors['otherText'] && <span className="error">{formErrors['otherText']}</span>}
                <br/>

                <Row>
                <Col md={5}>
                <label htmlFor="debtRepayable"><span className="required">*</span>Is debt repayable in non-US currency:
                    <TooltipComponent
                    title="Is the debt repayable in non-US currency.  This is required to be provided per the statute."
                    id="mlr-reports"
                    placement="right">
                        <AiFillQuestionCircle className="help-icon heading-help-icon"/>
                    </TooltipComponent>
                </label>
                </Col>
                <Col>
                <Form.Check
                    inline
                    label="No"
                    name="debtRepayNonUsCurrFlag"
                    type="radio"
                    value="N"
                    id="debtRepayNonUsCurrFlag-1"
                    checked={finalSaleInfo.debtRepayNonUsCurrFlag === 'N'}
                    onChange={(e)=>handleChange(e)}
                />
                <Form.Check
                    inline
                    label="Yes"
                    name="debtRepayNonUsCurrFlag"
                    type="radio"
                    value="Y"
                    id="debtRepayNonUsCurrFlag-2"
                    checked={finalSaleInfo.debtRepayNonUsCurrFlag === 'Y'}
                    onChange={(e)=>handleChange(e)}
                />
                <Form.Check
                    inline
                    label="Unknown"
                    value="U"
                    name="debtRepayNonUsCurrFlag"
                    type="radio"
                    id="debtRepayNonUsCurrFlag-3"
                    checked={finalSaleInfo.debtRepayNonUsCurrFlag === 'U'}
                    onChange={(e)=>handleChange(e)}
                />
                </Col>
                {formErrors.debtRepayNonUsCurrFlag && <p className="error">{formErrors.debtRepayNonUsCurrFlag}</p>}
            </Row>
            
            <Row>
                <Col md={5}>
                <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Senior Structure:</label>
                </Col>
                <Col>
                <Form.Check
                        inline
                        label="No"
                        name="seniorInd"
                        value="N"
                        type="radio"
                        id="seniorind-2"
                        data-validations="required"
                        checked={finalSaleInfo.seniorInd === 'N'}
                        onChange={(e)=>handleChange(e)}
                />
                <Form.Check
                        inline
                        label="Yes"
                        name="seniorInd"
                        value="Y"
                        type="radio"
                        id="seniorind-1"
                        data-validations="required"
                        checked={finalSaleInfo.seniorInd === 'Y'}
                        onChange={(e)=>handleChange(e)}
                    />
                </Col>
                {formErrors['seniorInd'] && <p className="error">{formErrors['seniorInd']}</p>}
            </Row>  
        
            <Row>
                <Col md={5}>
                <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Subordinate Structure:</label>
                </Col>
                <Col>
                <Form.Check
                        inline
                        label="No"
                        name="subordinateInd"
                        value="N"
                        type="radio"
                        id="subordinateind-1"
                        data-validations="required"
                        checked={finalSaleInfo.subordinateInd === 'N'}
                        onChange={(e)=>handleChange(e)}
                    />
                <Form.Check
                        inline
                        label="Yes"
                        name="subordinateInd"
                        value="Y"
                        type="radio"
                        id="subordinateind-2"
                        data-validations="required"
                        checked={finalSaleInfo.subordinateInd === 'Y'}
                        onChange={(e)=>handleChange(e)}
                    />
                </Col>
                {formErrors['subordinateInd'] && <p className="error">{formErrors['subordinateInd']}</p>}
            </Row>
                   

                <div className="btn-div">
                    {/* <button className="custom-button">Back</button> */}
                    <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
                </div>
            </form>

        </Card>
    )
}

export default SourceOfRepayment
