import {useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye , faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import EditFinancePurposeForm from './editFinancePurposeForm'
import AddFinancePurposeForm from './addFinancePurposeForm'
import OrgDetails from './orgDetails'
import {useNavigate, useLocation} from 'react-router-dom'
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const SearchResultContainer = (props) => {
    const [openEditCodeModal, setOpenModal] = useState(false);
    const [openAddCodeModal, setOpenAddCodeModal] = useState(false);
    const [editFinancialPurposeInfo, setEditFinancialPurposeInfo] = useState([]);
    const [viewOrgDetails, setViewOrgDetails] = useState(false)

    const history = useNavigate();
    const {pathname} = useLocation();
    const viewFormatter = (row) => { 
        return ( 
              <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
           <FontAwesomeIcon icon={faEye} className="custom-icon" style={{ fontSize: 'medium'}} onClick={(e)=>onViewOrgClick(e, row)} />{"  "}
           <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" style={{ fontSize: 'medium'}} onClick={(e)=>onRowClick(e, row)} />
         </div> 
        ); 
    }

    const onRowClick = (e, row) => {
       // e.preventDefault();
        setOpenModal(true);
        setEditFinancialPurposeInfo(row);
    }
    const onViewOrgClick = (e, row) => {
       // e.preventDefault();
        history.push(pathname+"/"+row.id);
    }

    const columns = [{
        dataField: 'id',
        text: 'Org ID',
        sort: true
      },{
        dataField: 'name',
        text: 'Name',
        sort: true
      }, {
        dataField: 'shortName',
        text: 'Short Name',
        sort: true
      }, {
        dataField: 'createdDate',
        text: 'Date Created',
        sort: true
      },{
        dataField: 'lastUpdatedDate',
        text: 'Last Updated',
        sort: true
      }, {
        dataField: 'createdBy',
        text: 'Created By',
        sort: true
      },{
        dataField: 'updatedBy',
        text: 'Last Updated By',
        sort: true
      },{
        dataField: 'isActive',
        text: 'Active',
        sort: true
      },{ 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];
    
        //"GAN":"Grant anticipation note",
   const financingPurpose = [{
            'id': 28810,
            'shortName':'CMDV',
            'name':'Commercial Development',
            'createdDate':'06/05/2021',
            'lastUpdatedDate':'08/15/2021',
            'createdBy':'John',
            'updatedBy':'John',
            'isActive': 'Yes'
        },
        {
          'id': 28811,
            'shortName':'INDV',
            'name':'Industrial Development',
            'createdDate':'06/05/2021',
            'lastUpdatedDate':'08/15/2021',
            'createdBy':'Sam',
            'updatedBy':'Sam',
            'isActive': 'No'
        }
      ]
      
    return (
        <>
        <div className="form">
            <h1>Search Result: </h1>
            <DataGrid
            dataSource={financingPurpose}
            sortField='id'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            addNew={true}
            onAddNew={()=>setOpenAddCodeModal(true)}
            addNewBtnText='Add New Organization'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
        </div>
        {openEditCodeModal && <EditFinancePurposeForm onModalClose={()=>setOpenModal(false)} orgInfo={editFinancialPurposeInfo}/>}
        {openAddCodeModal && <AddFinancePurposeForm onModalClose={()=>setOpenAddCodeModal(false)}/>}
        {viewOrgDetails && <OrgDetails onModalClose={()=>setViewOrgDetails(false)}/>}
        </>
    )
}

export default SearchResultContainer
