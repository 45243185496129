import CommonService from "./CommonService";

const getAllCounties = (obj) => {
    return CommonService.apicall(obj)

};

const getCountyDetails = (obj) => {
    return CommonService.apicall(obj)

};

const addCounty = (obj) => {
    return CommonService.apicall(obj)

};

const updateCounty = (obj) => {
    return CommonService.apicall(obj)

};


const CountyMaintenanceService = {
    getAllCounties,
    getCountyDetails,
    addCounty,
    updateCounty
};

export default CountyMaintenanceService;