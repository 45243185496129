import {useState, useEffect, useContext} from 'react'
import { Form, Spinner, Row, Col } from 'react-bootstrap'
import { validateFormFields } from '../../../utils/index'
import Card from '../../ui/Card'
import CodeMaintenanceService from "../../../services/CodeMaintenanceService"
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import { UserContext } from '../../../UserContext'
import CommonService from '../../../services/CommonService'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { FormInput } from '../../formElements/FormInput'
import TooltipComponent from '../../../common/Tooltip'

const SourceOfRepayment = (props) => {
    const { issueId } = props;
    const {proposedDebtDetails} = props;
    const [proposeDebtInfo, setProposeDebtInfo] = useState(proposedDebtDetails ? proposedDebtDetails : {});
    const loginUser = useContext(UserContext);
    const [formErrors, setFormErrors] = useState({});
    const [sources, setRepaymentSources] = useState([]);
    const [issueRepaymentSources, setIssueRepaymentSources] = useState([]);
    const [showOtherTextField, setShowOtherTextField] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchCodes = async () => {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: {cdiacCode: "SORP", active: "Y"},
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                setRepaymentSources(filteredList);
                //setIsLoading(false);
            });
        }
    
        const fetchIssuerRepaymentResources = async() => {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueRepaymentSourcesByIssueId",
            hdrStruct: null,
            queryParam: null,
            reqData: { "id" : issueId, "reportType" : "RPD"},
            };
        
           await ReportOfProposedDebtService.getIssueRepaymentSourcesByProposeDebtId(obj)
            .then((res) => res)
            .then((result) => {
                setIssueRepaymentSources(result.data.responses[0]);
                if(result.data.responses[0].length > 0 && result.data.responses[0][0].repymtSrcCodeValueCode === "OTHS") {
                    setShowOtherTextField(true);
                }
                setIsLoading(false);
            });

        }

        fetchCodes();
        fetchIssuerRepaymentResources();
        //setIsLoading(false);
    },[issueId]);

    const handleRepaymentResourceChange = (e, source) => {
        const issRepaymentSources = [...issueRepaymentSources];
        const { value } = e.target;
        let obj = {};
        obj['proposeDebtId'] = issueId;
        obj['repymtSrcCodeId'] = parseInt(value);
        obj['repymtSourceId'] = issRepaymentSources.length > 0 ? issRepaymentSources[0].repymtSourceId : 0;
        obj['createUser'] = CommonService.getUserName(loginUser);
        obj['finalSaleId'] = null;
        issRepaymentSources[0] = obj;
       
        setIssueRepaymentSources(issRepaymentSources);
        if(source.cdiacCodeValueCode === "OTHS") {
            setShowOtherTextField(true);
        } else {
            setShowOtherTextField(false);
        }
    }
    const handleOtherTextChange = (e) => {  
        const {value} = e.target;
        const issRepaymentSources = [...issueRepaymentSources];
        issRepaymentSources[0].otherText = value;
        setIssueRepaymentSources(issRepaymentSources);
    }
    const handleChange = (e) => {  
        const {name, value} = e.target;
        setProposeDebtInfo({
            ...proposeDebtInfo,
            [name]: value
        });
    }

    const updateRepaymentSources = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "mergeIssueRepaymentSourcesByIssueId",
            hdrStruct: null,
            queryParam: null,
            reqData: { "id" : issueId,
            "issueRepaymentSources": issueRepaymentSources,
            "reportType" : "RPD"
            },
        };
        
        await ReportOfProposedDebtService.mergeIssueRepaymentSourcesByProposeDebtId(obj)
        .then((res) => res)
        .then((result) => {
            props.updateProposeDebtDependencies(result.data.responses[0], 'issueRepaymentSources');
        });
    }

    const updateProposeDebt = () => {
        
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putProposeDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issueId,
                "proposeDebt": {...proposeDebtInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };
    
        ReportOfProposedDebtService.updateProposedDebt(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Source of Repayment updated successfully");
                props.updateProposedDebtDetails(result.data.responses[0]);
                props.sectionValidated('sourceOfRepayment', true);
                props.openWidget('purposeOfFinancing');
            }
        });
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#sourceOfRepaymentForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('sourceOfRepayment', false);
        } else {
            setFormErrors({});
            if( issueRepaymentSources.length === 0) {
                alert("Please select source of repayment.");
            } else {
                updateRepaymentSources();
                updateProposeDebt();
            }
        }
    }

    return (
        <Card>
            <form className="form" id="sourceOfRepaymentForm">
                <div>
                {isLoading && <Spinner animation="border" variant="primary" />}
                {(sources.length !== 0) && sources.map((source, i) =>{
                        return (<div><Form.Check key={i} inline name="repaymentSource" 
                        type="radio" 
                        id={`repaymentSource_${i}`}
                        data-validations="required" 
                        value={source.cdiacCodeValueId} label={source.cdiacCodeValueDesc} style={{marginTop: 0}} 
                        checked={issueRepaymentSources.some(el => el.repymtSrcCodeId === source.cdiacCodeValueId)} 
                        onChange={(e)=>handleRepaymentResourceChange(e, source)}/></div>);
                    }
                ) }
                {formErrors['repaymentSource'] && <p className="error">{formErrors['repaymentSource']}</p>}
                </div>
                {showOtherTextField && <FormInput label="Other Text" name="otherText" 
                    value={issueRepaymentSources[0].otherText} 
                    onChange={(e)=>handleOtherTextChange(e)} 
                    isrequired="true"
                    validations={["required"]}
                    maxLength="120"/>}
                {formErrors['otherText'] && <span className="error">{formErrors['otherText']}</span>}
                <br/>
                <Row>
                <Col md={5}>
                <label htmlFor="debtRepayable"><span className="required">*</span>Is debt repayable in non-US currency?
                    <TooltipComponent
                        title="Is the debt repayable in non-US currency?"
                         id="actual-sale-date"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon heading-help-icon" />
                    </TooltipComponent>
                </label>
                </Col>
                <Col>
                <Form.Check
                    inline
                    label="No"
                    name="debtRepayNonUsCurrFlag"
                    value="N"
                    type="radio"
                    id="debtRepayNonUsCurrFlag-1"
                    checked={proposeDebtInfo.debtRepayNonUsCurrFlag === 'N'}
                    onChange={(e)=>handleChange(e)}
                    data-validations={["required"]}
                />
                <Form.Check
                    inline
                    label="Yes"
                    name="debtRepayNonUsCurrFlag"
                    value="Y"
                    type="radio"
                    id="debtRepayNonUsCurrFlag-2"
                    checked={proposeDebtInfo.debtRepayNonUsCurrFlag === 'Y'}
                    onChange={(e)=>handleChange(e)}
                    data-validations={["required"]}
                />
                <Form.Check
                    inline
                    label="Unknown"
                    name="debtRepayNonUsCurrFlag"
                    value="U"
                    type="radio"
                    id="debtRepayNonUsCurrFlag-3"
                    checked={proposeDebtInfo.debtRepayNonUsCurrFlag === 'U'}
                    onChange={(e)=>handleChange(e)}
                    data-validations={["required"]}
                />
                </Col>
                {formErrors.debtRepayNonUsCurrFlag && <p className="error">{formErrors.debtRepayNonUsCurrFlag}</p>}
            </Row>

                <div className="btn-div">
                    {/* <button className="custom-button">Back</button> */}
                    <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
                </div>
            </form>

        </Card>
    )
}

export default SourceOfRepayment
