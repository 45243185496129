import { Modal, Table} from 'react-bootstrap'
import { useState, useEffect} from 'react'
import AuthorizationMaintenanceService from "../../../services/AuthorizationMaintenanceService";
import CommonService from "../../../services/CommonService";
import { AlertType } from '../../../utils';

const ViewIssuances = (props) => {
    const {onModalClose, authInfo} = props;
    const [issuances, setIssuances] = useState([]);
    const [outstandingAmt, setOutstandingAmt] = useState(0);

    useEffect(() => {
        CommonService.showHideSpinner(true);

        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuances",
          hdrStruct: null,
          queryParam: null,
          reqData:{"authorizationId" : authInfo.authorizationId}
        };
    
        AuthorizationMaintenanceService.getIssuances(obj)
          .then((res) => res)
          .then((result) => {
            if(result.data.statusCode === 401 || result.data.statusCode === 500) {
              CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
            } else {
              setIssuances(result.data.responses);
              let data = result.data.responses[0];
              if(data.length > 0) {
                setOutstandingAmt(data[0].outstandingAmount);
              }
            }
            CommonService.showHideSpinner(false);
          });
      }, [props, authInfo]);

    return (
        <Modal show={true} backdrop="static" onHide={onModalClose} className="view-issuances">
            <Modal.Header closeButton>
              <div><h3>View Issuances - {authInfo.authorizationName} </h3> <p>(<b>Current Remaining Authorization:</b> {CommonService.currencyFormatter(outstandingAmt)})</p> </div>
            </Modal.Header>
            <Modal.Body>
            {Object.keys(issuances).map((key) => {
          return (
            <div key={key}>

                  <Table bordered>
                    <thead>
                        <tr>
                            <th>CDIAC #</th>
                            <th>Issuer Name</th>
                            <th>Issue Name</th>
                            <th>Principal Amount</th>
                            <th>Original Authorized Amount</th>
                            <th>Amount Authority Reduced</th>
                            <th>Amount Authority Replenished</th>
                            <th>Actual Sale Date</th>
                        </tr>
                    </thead>
                    <tbody>
                    {issuances[key].map((dataItem, index) => {
                        return (
                            <tr key={index}>
                                <td>{dataItem.cdiacNbr}</td>
                                <td>{dataItem.issuerName}</td>
                                <td>{dataItem.issueName}</td>
                                <td>{CommonService.currencyFormatter(dataItem.principalSaleAmt)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.originalAuthorizedAmount)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.authorizedReducedAmount)}</td>
                                <td>{CommonService.currencyFormatter(dataItem.authorizedReplenishAmount)}</td>
                                <td>{CommonService.dateFormatter(dataItem.actualSaleDate)}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                  </Table>

            </div>
          );
        })}
                
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ViewIssuances
