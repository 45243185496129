import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { Row, Col } from "react-bootstrap";
import Card from "../ui/Card";
import { useState, useEffect } from "react";
import CodeMaintenanceService from "../../services/CodeMaintenanceService";
import Select from 'react-select';
import CodeDetails from './codeDetails';

const CodeMaintenance = (props) => {
  const user = useContext(UserContext);
  console.log(user);
  const [codes, setCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCodeDetails, setShowCodeDetails] = useState(false);
  const [stateValues, setStateValues] = useState({
    cdiacCode: '',
    cdiacCodeVal: ''
  })
  useEffect(() => {
    var obj = {
      msgId: "null",
      sysId: null,
      opr: "getCdiacCodeRefs",
      hdrStruct: null,
      queryParam: null,
      reqData: {},
    };

    CodeMaintenanceService.getAllCodes(obj)
      .then((res) => res)
      .then((result) => {
        let codesArr = [];
        result.data.responses[0].forEach((item, i) => {
            let Obj = {};
            Obj['value'] = item.cdiacCode;
            Obj['label'] = item.cdiacCodeDesc;
            codesArr.push(Obj);
        });
        setCodes(codesArr);
        setIsLoading(false);
      });
  }, []);

  const showDetails = (e) => {
   setShowCodeDetails(true);
   setStateValues(prevState=>({
    ...stateValues, 
      cdiacCode: e.value,
      cdiacCodeVal: e.label
    })
   )
  };
  return (
    <Card>
        <div className="panel panel-default">
          <div className="panel-heading" role="tab"> 
              <div className="container">
                  <div className="panel-title">
                      <h1 className="main-title">Code Maintenance</h1>
                  </div>
              </div>
          </div>

          <div className="panel-body" style={{padding: '10px 0'}}>
              <div className="container">                       
                <Row>
                  <label><span className="required">*</span>Code Type:</label>
                  <Col md={6}>
                      <Select 
                    
                      options={codes} 
                      onChange={(e)=>showDetails(e)} 
                      isLoading={isLoading}
                      />
                  </Col>
                </Row>
              </div>
          </div>
        </div>
        {showCodeDetails && <CodeDetails cdiacValues={stateValues}/>}
    </Card>
  );
};

export default CodeMaintenance;
