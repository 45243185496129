import {useState, useEffect, useCallback} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Form, Modal, Row, Col } from 'react-bootstrap'
import SeniorSubordinateService from '../../../services/SeniorSubordinateService'
import Spinner from 'react-bootstrap/Spinner'
import CommonService from '../../../services/CommonService'
import swal from 'sweetalert'
import { toast } from 'react-toastify'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const EditModal = (props)=> {
    const {onModalClose} = props;
    const handleUpdate = () => {
        onModalClose();
    }
    return (
        <Modal show={true} onHide={onModalClose} size='lg'>
            <Modal.Header closeButton><h1>Edit</h1></Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={3}><label>Principal Amount</label></Col>
                    <Col md={5}><Form.Control type="text" /></Col>
                </Row>
                <Row>
                    <Col md={3}>
                    <label>Tax Exempt Status</label>
                    </Col>
                    <Col>
                        <Form.Check inline type="radio" name="taxExempt" label="Yes" />
                        <Form.Check inline type="radio" name="taxExempt" label="No" />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                    <label>Primary/Secondary</label>
                    </Col>
                    <Col>
                        <Form.Check inline type="radio" name="isPrimary" label="Yes" />
                        <Form.Check inline type="radio" name="isPrimary" label="No" />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={()=>handleUpdate()}>Update</button>
            </Modal.Footer>
        </Modal>
    )
}
const ChildAssociations = (props) => {
    const {handleAddAssociation, childTitle, associationType} = props;
    //const { SearchBar, ClearSearchButton } = Search;
    const [showEditModal, setShowEditModal] = useState(false);
    const [reports, setSeniorSubordinates]= useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const viewFormatter = (row) => { 
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
               {associationType==="series-association" && <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>handleEdit(e, row)}/> }{"  "} 
               <FontAwesomeIcon icon={faTrash} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
            </div> 
        ); 
    }

    const onRowClick = (e, row) => {
        swal("Are you sure you want to remove this association?", {
            buttons: ["No", "Yes"],
        }).then((value) => {
            if(value){
                var obj = {
                    msgId: "null",
                    sysId: null,
                    opr: "deleteSeniorSubordinateAssociation",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: { 
                            "seniorCdiacNbr" : props.selectedRow.cdiacNbr,
                            "subordinateCdiacNbr": row.subordinateCdiacNbr
                    },
                };
            
                SeniorSubordinateService.deleteSeniorSubordinateAssociation(obj)
                .then((res) => res)
                .then((result) => {
                    toast.success("Senior Subordinate Association Removed Successfully");
                    fetchSeniorSubordinates();
                })
        }
        });
    }
    
    const handleEdit = () => {
        setShowEditModal(true);
    }
    
    const fetchSeniorSubordinates = useCallback(() => { 
        var obj = {
            msgId: null,
            sysId: null,
            opr: "searchSeniorSubordinate",
            hdrStruct: null,
            queryParam: null,
            reqData: {
              "searchSeniorSubordinate" :{
                "issuerId": props.selectedRow.issuerId,
                "subordinateCdiacNbr": props.selectedRow.cdiacNbr,
                "associationType" : associationType
            }
            },
          };
      
          SeniorSubordinateService.searchSenior(obj)
            .then((res) => res)
            .then((result) => {
              Object.keys(result.data.responses).forEach((key) => {
                setSeniorSubordinates(result.data.responses[key]);
              });
              setIsLoading(false);
            });
    },[props.selectedRow, associationType]);

    useEffect(() => {
        fetchSeniorSubordinates();
    },[props.selectedRow, props.showAddAssociationModal, fetchSeniorSubordinates]);
      

    const columns = [{
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      },{
        dataField: 'subordinateCdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, {
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate']),
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      },
      { 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];


    return (
        <>

        <h3 className="menu-title">{childTitle} Associations</h3>
        {isLoading && <Spinner animation="border" variant="primary" />}
        <DataGrid
        dataSource={reports}
        sortField='issuerName'
        addNew={true}
        addNewBtnText='Add Association'
        onAddNew={()=>handleAddAssociation(true)}
        emptyDataMessage='No Associations to Display'
        >
        {columns.map((column, i) => {
            return (
            <Column 
            key={i}
            field={column.dataField}
            header={column.text}
            sortable={column.sort} 
            body={column.formatter}
            />)
            }) 
        } 
        </DataGrid>
                  
        {showEditModal && <EditModal onModalClose={()=>setShowEditModal(false)}/>}
        </>
    )
}

export default ChildAssociations
