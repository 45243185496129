import CommonService from "./CommonService";

const getDocuments = (obj) => {
  return CommonService.apicall(obj);
};

const getHistory = (obj) => {
  return CommonService.apicall(obj);
};

const AzureDocService = {
  getDocuments,
  getHistory,
};

export default AzureDocService;
