import React, { useState } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import InvoiceService from "../../../../services/InvoiceService";
import Alert from "react-bootstrap/Alert";
import CommonService from "../../../../services/CommonService";
import { toast } from "react-toastify";
import swal from "sweetalert";

const DeleteInvoicePayment = (props) => {
  const { onModalClose } = props;
  const stateValues = props.invoicePayments;
  const [errorMessage, setErrorMessage] = useState();

  const handleDeleteFile = (data) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        CommonService.showHideSpinner(true);
        var obj = {
          msgId: null,
          sysId: null,
          opr: "deleteInvoicePayment",
          hdrStruct: null,
          queryParam: null,
          reqData: {
            invoicePaymentId: data.invoicePaymentId,
          },
        };
        InvoiceService.deleteInvoicePayment(obj).then((result) => {
          if (result.data.errors && result.data.errors.length > 0) {
            setErrorMessage(result.data.errors[0].message);
          } else {
            toast.success("Invoice Payment Deleted successfully!");
            onModalClose();
          }
        });
        CommonService.showHideSpinner(false);
      }
    });
  };

  return (
    <Modal show={true} backdrop="static" onHide={onModalClose}>
      <Modal.Header closeButton>
        <h3>Delete Invoice Payment</h3>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert variant="danger">
            <p>{errorMessage}</p>
          </Alert>
        )}
        <form id="deleteInvoiceForm">
          <Row>
            <Col md={6}>
              <label>Invoice #:</label>
              <Form.Control
                type="text"
                name="invoiceId"
                value={stateValues.invoiceId}
                maxLength="16"
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label>Invoice Payment Amount:</label>
              <Form.Control
                type="text"
                name="invoicePaymentAmt"
                value={CommonService.currencyFormatter(stateValues.invoicePaymentAmt)}
                disabled
              />
            </Col>
            <Col md={6}>
              <label>Invoice Payment Date:</label>
              <span>
                <Form.Control
                  type="text"
                  name="invoicePaymentDate"
                  value={CommonService.dateFormatter(stateValues.invoicePaymentDate)}
                  disabled
                />
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label>Payment Detail (Wire/Check #)</label>
              <Form.Control
                type="text"
                name="checkNbr"
                value={stateValues.checkNbr}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Created On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="createDateTime"
                      value={CommonService.dateFormatter(
                        stateValues.createDatetime
                      )}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Created By:</label>
              <Form.Control
                type="text"
                name="createUser"
                value={stateValues.createUser}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Last Updated On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="lastUpdateDateTime"
                      value={CommonService.dateFormatter(
                        stateValues.lastUpdateDatetime
                      )}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Last Updated By</label>
              <Form.Control
                type="text"
                name="lastUpdateUserId"
                value={stateValues.lastUpdateUser}
                disabled
              />
            </Col>
          </Row>
          <div className="btn-div">
            <div className="fright">
              <button
                type="button"
                className="custom-button-secondary  btn-secondary"
                onClick={onModalClose}
              >
                Cancel
              </button>{" "}
              <button
                className="custom-button"
                type="button"
                onClick={(e) => handleDeleteFile(stateValues)}
              >
                Delete
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteInvoicePayment;
