import { useState, useEffect } from 'react'
import ViewIssuances from './viewIssuances'
import AuthorizationSummary from './authorizationSummary'
import AddAuthorization from './addAuthorization'
import EditAuthorization from './editAuthorization'
import AuthorizationMaintenanceService from "../../../services/AuthorizationMaintenanceService";
import CommonService from "../../../services/CommonService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { AlertType } from '../../../utils'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const SearchResultContainer = (props) => {
  const [openAddAuthModal, setOpenAddAuthModal] = useState(false);
  const [openViewIssuances, setOpenViewIssuances] = useState(false);
  const [openAuthSummary, setOpenAuthSummary] = useState(false);
  const [editAuthorization, setEditAuthorization] = useState(false);
  const [selectedAuthInfo, setSelectedAuthInfo] = useState([]);
  const { searchReq } = props;
  const [authorizationRefs, setAuthorizationRef] = useState([])

  const handleViewIssuancesClick = (e, row) => {
    e.stopPropagation();
    setSelectedAuthInfo(row)
    setOpenViewIssuances(true);

  }
  const handleAuthSummaryClick = (e, row) => {
    e.stopPropagation();
    setSelectedAuthInfo(row)
    setOpenAuthSummary(true);

  }

  const viewFormatter = (row) => {
    return (
      <div style={{
        textAlign: "center",
        lineHeight: "normal"
      }}>
        <button
          type="button"
          className="link-button dk-blue-color"
          onClick={(e) => handleViewIssuancesClick(e, row)}>View Issuances
        </button>
        <button
          type="button"
          className="link-button dk-blue-color"
          onClick={(e) => handleAuthSummaryClick(e, row)}>ADTR Summary
        </button>
      </div>
    );
  }

  const viewEditFormatter = (row) => {
    return (
      <div style={{
        textAlign: "center",
        lineHeight: "normal"
      }}>
        <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
      </div>
    );
  }
  const authorizationAmtTemplate = (row) => {
    return CommonService.currencyFormatter(row.authorizationAmt)
  }
  const electionDateTemplate = (row) => {
    return CommonService.dateFormatter(row.electionDate)
  }
  const onRowClick = (e, row) => {
    // e.preventDefault();
    setSelectedAuthInfo(row);
    setEditAuthorization(row);
 }
  useEffect(() => {
    CommonService.showHideSpinner(true);
    var obj = {
      msgId: null,
      sysId: null,
      opr: "searchAuthorizationRef",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        "searchAuthorizationRef": {
          "authorizationId": searchReq.authorizationId,
          "issuerId": searchReq.issuerId,
          "authorityOriginCode": searchReq.authorityOriginCode
        }
      },
    };

    AuthorizationMaintenanceService.searchAuthorizationRef(obj)
      .then((res) => res)
      .then((result) => {
        CommonService.showHideSpinner(false);

        if(result.data.statusCode === 401 || result.data.statusCode === 500) {
          CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
        } else {
          setAuthorizationRef(result.data.responses[0]);
        };
      });
  }, [props, searchReq, editAuthorization, openAddAuthModal]);

 
  return (
    <>
      <div className="form">
        <DataGrid
        dataSource={authorizationRefs}
        sortField='authorizationName'
        title={"Search Result:"}
        searchToolTip="Use the search feature to quickly retrieve the system code values"
        addNew={true}
        addNewBtnText="Add New Authorization"
        onAddNew={()=>setOpenAddAuthModal(true)}
        >
          <Column field='issuerName' header='Issuer Name' sortable />
          <Column field='authorizationName' header='Authorization Name' sortable />
          <Column field='electionDate' header='Original Authorization Date' sortable body={electionDateTemplate} />
          <Column field='authorizationAmt' header='Original Authorization Amount' sortable body={authorizationAmtTemplate} />
          <Column field='authorityOriginCode' header='Origin' sortable/>
          <Column field='' header='Edit' body={viewEditFormatter} />
          <Column field='' header='Issues/ADTR Summary' body={viewFormatter} />
        </DataGrid>
      </div>
      {openAddAuthModal && <AddAuthorization onModalClose={() => setOpenAddAuthModal(false)} />}
      {editAuthorization && <EditAuthorization onModalClose={() => setEditAuthorization(false)} authInfo={selectedAuthInfo} />}
      {openViewIssuances && <ViewIssuances onModalClose={() => setOpenViewIssuances(false)} authInfo={selectedAuthInfo} />}
      {openAuthSummary && <AuthorizationSummary onModalClose={() => setOpenAuthSummary(false)} authInfo={selectedAuthInfo} />}

    </>
  )
}

export default SearchResultContainer
