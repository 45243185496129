import { useState, useEffect, useContext } from 'react';
import Card from '../ui/Card';
import {Row, Col, Table} from 'react-bootstrap';
import { toast } from 'react-toastify';
import FinalSaleReportService from '../../services/FinalSaleReportService';
import CountyMaintenanceService from '../../services/CountyMaintenanceService';
import Select from 'react-select';
import CommonService from '../../services/CommonService';
import { UserContext } from "../../UserContext";

const Comments = (props) => {
    const {markRoosAuthority, markRoosAuthorityDetails} = props;
    const [count, setCount] = useState(0);
    const [cdiacCommentCount, setCdiacCommentCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [cdiacBgColor, setCdiacBgcolor] = useState('yellowgreen');
    const [cdiacCommentValue, setCdiacCommentValue] = useState("");
    const [issueComments, setIssueComments] = useState([]);
    const [commentTypes, setCommentTypes] = useState([]);
    const [commentTypeCodeId, setCommentTypeCodeId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const loginUserId = useContext(UserContext);

    const [stateValues, setStateValues] = useState({
        issuerComment : markRoosAuthorityDetails !== undefined? markRoosAuthorityDetails.issuerComment : ''
    });
    async function fetchIssueComments () {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueComments",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "issueComment":{
                    "mkrAuthId" : markRoosAuthorityDetails.markRoosAuthorityId 
                }
            },
            };
        
        FinalSaleReportService.getIssueComments(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0 ) {
                toast.error(result.data.errors[0].message);
            }else {
                let reasonsArr = [];
                result.data.responses[0].forEach((item, i) => {
                    item.displayComment = item.comment;
                    if( item.comment.length > 32 ) {
                        item.displayComment = item.comment.substr(0, 32) + "...";
                        item.expanded = false;
                    }
                    
                    reasonsArr.push(item);
                })
                setIssueComments(reasonsArr);
                props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'issueComments');
            }
        });
    }
    useEffect(() => { 
        async function fetchCommentTypes () {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: { cdiacCode: "MRCMT", active: "Y" },
            };
        
            CountyMaintenanceService.getAllCounties(obj)
            .then((res) => res)
            .then((result) => {
                let codesArr = [];
                result.data.responses[0].forEach((item, i) => {
                    let Obj = {};
                    Obj['value'] = item.cdiacCodeValueId;
                    Obj['label'] = item.cdiacCodeValueDesc;
                    codesArr.push(Obj);
                });
                setCommentTypes(codesArr);
                setIsLoading(false);
            });
        }
        
        fetchCommentTypes();
        fetchIssueComments();
        // eslint-disable-next-line
      }, []);
      const handleComment = (e) => {
        const {name, value} = e.target;
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
    };
    const handleCdiacCommentValue = (e) => {
        setCdiacCommentCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setCdiacBgcolor(color);
        setCdiacCommentValue(e.target.value);
    };
    const toggleText = (e, i) => {
        const history = [...issueComments];
        history[i].expanded = !history[i].expanded;
        if(history[i].expanded) {
            history[i].displayComment = history[i].comment;
        } else {
            history[i].displayComment =  history[i].comment.substr(0, 32) + "...";
        }
        setIssueComments(history);
    }
    const saveComment = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "saveComment",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueComment" : {
                    "mkrAuthId" : markRoosAuthorityDetails.markRoosAuthorityId ,
                    "commentTypeCodeId": commentTypeCodeId,
                    "comment": cdiacCommentValue,
                    "createUser": CommonService.getUserName(loginUserId)
                }
            }
        };
    
       await FinalSaleReportService.saveComment(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message);
            }
            else{
                fetchIssueComments();
                setCommentTypeCodeId(null);
                setCdiacCommentValue("");
            }
        });
    }
    const updateIssuerComment = () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateMarkRoosAuthorityComment",
            hdrStruct: null,
            queryParam: null,
            reqData: {  "id":markRoosAuthority.mkrAuthId,
                "env" : "I",
                "markRoosAuthority":{
                    "issuerComment" : stateValues.issuerComment,
                    "lastUpdateUser": CommonService.getUserName(loginUserId),
                    "emailId": loginUserId,
                    "contactId": markRoosAuthority.contactId
                }
            },
          };
      
          FinalSaleReportService.updatemarkRoosAuthorityComment(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                }
                else{
                    props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'mkrAuthorityData');
                    toast.success("Comment updated successfully!");
                }
                
            });
    }
    const validateForm = async(e) => {
        await updateIssuerComment();
        props.openWidget('review');
    }
    return (
        <Card>
            <div className="form">
                <div className="comments-box">

                    <textarea name="issuerComment" value={stateValues.issuerComment} 
                    id="comments-input" onChange={handleComment}
                     className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                     <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                <div>
                <label>CDIAC Comments: </label>
                {issueComments.length > 0 &&
                <Table>
                <thead>
                    <tr>
                        <th>Comment Type</th>
                        <th>Comment</th>
                        <th>Create User</th>
                        <th>Create Date</th>
                    </tr>
                </thead>
                <tbody>
                    {issueComments.map((comment,i)=>{
                            return (
                                <tr key={i}>
                                <td>{comment.cdiacCodeValueDesc}</td>
                                <td>{comment.displayComment}&nbsp; 
                                {comment.hasOwnProperty('expanded') ? 
                                    (comment.expanded ? 
                                    <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show less
                                    </button>
                                    : <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show more
                                    </button>
                                    ) : ""
                                }
                                </td>
                                <td>{comment.createUser}</td>
                                <td>{CommonService.dateFormatter(comment.createDatetime)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </Table>
                }
                </div>
                <div>
                    <Row>
                        <Col md={6}>
                        <label>Comment Type</label>
                        <Select 
                        options={commentTypes} 
                        isLoading={isLoading}
                        onChange={(e)=>setCommentTypeCodeId(e.value)}
                        />
                        </Col>
                    </Row>
                    <textarea name="comment" value={cdiacCommentValue} 
                    id="comments-input" onChange={handleCdiacCommentValue}
                     className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                     <span style={{position: 'relative', left: '45%' , backgroundColor: cdiacBgColor }}>{cdiacCommentCount} out of 4000 max</span>
                     <span style={{position: 'relative', float: 'right', marginTop: '5px' }}><button type="button" disabled={(commentTypeCodeId !== null && cdiacCommentValue !== "") ? false : true}onClick={()=>saveComment()}>Submit</button></span>
                    </div>
                </div>
                    
                <div className="btn-div">
                {/* <button className="custom-button" type="button">Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>
            </div>  
        </Card>
    )
}

export default Comments
