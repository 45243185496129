import {useState, useRef, useEffect, useContext} from 'react';
import CollapsibleWidgets from'./collapsibleWidgets';
import MKRAuthorityIssueProgressBar from './mkrAuthorityIssueProgressBar';
import {VscExpandAll, VscCollapseAll} from 'react-icons/vsc';
import ReviewComponent from './reviewComponent';
import { toast } from 'react-toastify';
import FinalSaleReportService from "../../services/FinalSaleReportService";
import { UserContext, IssuanceDetailContext } from '../../UserContext'
import { Spinner } from 'react-bootstrap';
import History from '../../common/History';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CommonService from '../../services/CommonService';
import EditReasonHistory from '../../common/EditReasonHistory';
import { renderToString } from 'react-dom/server';
import HtmlPdfService from '../../services/HtmlPdfService';
import { configManager } from '../../configManager';

const MKRAuthorityIssueYFS = (props) => {
    const componentRef = useRef();
    const location = useLocation();
    const params = useParams();
    const cdiacNo = params.cdiac;
    const [expandAll, setExpandAll] = useState(false);
    const [issuanceDetail, setIssuanceDetails] = useState([]);
    const [contact, setContactDetails] = useState([]);
    const userEmail = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [showHistory, setShowHistory] = useState(false);
    const [showEditReasonHistory, setShowEditReasonHistory] = useState(false);

    let markRoosAuthority = location.state.reportData;

    if(!location.state.reportData){
        markRoosAuthority = location.state.markRoosAuthority
    }
   
    const [markRoosAuthorityDetails, setMarkRoosAuthorityDetails] =  useState([]);
    const [markRoosAuthorityDependencies, setMarkRoosAuthorityDependencies] =  useState([]);

    const [openSection, setOpenSection] = useState({
        issuance: true,
        fundBalance: false,
        professionalFees: false,
        localObligor: false,
        retiredIssues: false,
        filingContact: false,
        comments: false,
        review: false
    });
    const toggleAllWidgets = () => {
        setExpandAll( !(expandAll) );
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: !(expandAll) })));
        setOpenSection(openSecObj);
    }

    const toggleWidget = (sectionName) => {
        setOpenSection({...openSection, [sectionName]: !(openSection[sectionName])})
    }

    const openWidget = (sectionName) => {
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: false })));
        openSecObj[sectionName] = !(openSecObj[sectionName]);
        setOpenSection(openSecObj);
    }
    const handlePrint = () =>{
        const html = renderToString(<ReviewComponent ref={componentRef} issuanceDetail={issuanceDetail} markRoosAuthority={markRoosAuthority} markRoosAuthorityDependencies={markRoosAuthorityDependencies} contact={contact} />)
        
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getPdf",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "html": html,
                "id" : markRoosAuthority.mkrAuthId,
                "cdiacNbr" :  cdiacNo,
                "status": 'D',
                "reportType" : "MKR-A",
                "createUser":  CommonService.getUserName(userEmail), 
            },
          };

        HtmlPdfService.generatePdf(obj)
        .then((res) => { 
             var base64EncodedPDF = res.data;
             var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
             var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
             var url = window.URL.createObjectURL(blob);
             window.open(url);

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
        
    }

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }

    const getRetireFlagDesc = (finalSaleDetail)  => {
        switch(finalSaleDetail.retireFlag){
            case "R" :
              return "Redeemed Entirely";
            case "M" :
              return "Matured";
            case "N" :
              return "Not Retired";
            case "O" :
              return "Other";
            default:
                return "";
            } 
    }

    useEffect(() => {

        async function getIssuanceDetailsByFinalSaleId() {
            if(markRoosAuthority.finalSaleId !== null){
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "getIssuanceDetailsByFinalSaleId",
                    hdrStruct: null,
                    queryParam: null,
                    reqData:{"finalSaleId" : markRoosAuthority.finalSaleId}
                  };
              
                await FinalSaleReportService.getIssuanceDetailsByFinalSaleId(obj)
                    .then((result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        }
                        else{
                            let retireFlagDesc = getRetireFlagDesc(result.data.responses[0]);
                            result.data.responses[0].retireFlagDesc = retireFlagDesc;
                            setIssuanceDetails(result.data.responses[0]);
                        }
            
                     });
            }
        }

        async function getMarkRoosAuthority() {
            
            if(markRoosAuthority !== undefined){

                    var obj = {
                        msgId: null,
                        sysId: null,
                        opr: "getMarkRoosAuthorityDependencies",
                        hdrStruct: null,
                        queryParam: null,
                        reqData:{"id" : markRoosAuthority.mkrAuthId}
                      };
                    await FinalSaleReportService.getMarkRoosAuthority(obj)
                        .then((result) => {
                            if (result.data.errors && result.data.errors.length > 0){
                                toast.error(result.data.errors[0].message);
                                return;
                            }
                            else{
                                setMarkRoosAuthorityDetails(result.data.responses[0].mkrAuthorityData);
                                setMarkRoosAuthorityDependencies(result.data.responses[0]);
                                getFilingContact(result.data.responses[0].mkrAuthorityData.contactId);
                            }
                            
                        });
            }
            else{
                    var anotherobj = {
                        msgId: null,
                        sysId: null,
                        opr: "getMarkRoosAuthorityDependencies",
                        hdrStruct: null,
                        queryParam: null,
                        reqData:{"id" : markRoosAuthorityDetails.markRoosAuthorityId}
                      };
                    await FinalSaleReportService.getMarkRoosAuthority(anotherobj)
                        .then((result) => {
                            if (result.data.errors && result.data.errors.length > 0){
                                toast.error(result.data.errors[0].message);
                                return;
                            }
                            else{
                                setMarkRoosAuthorityDetails(result.data.responses[0].mkrAuthorityData);
                                setMarkRoosAuthorityDependencies(result.data.responses[0]);
                                getFilingContact(result.data.responses[0].mkrAuthorityData.contactId);

                            }
                            
                        });
            }


        }

        async function getFilingContact(contactId) {
            if(contactId) {
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "getContactById",
                    hdrStruct: null,
                    queryParam: null,
                    reqData:{"id" : contactId}
                  };
                await FinalSaleReportService.getContactDetails(obj)
                    .then((result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        }
                        else{
                            setContactDetails(result.data.responses[0]);
                        }
                        
                    });
            }
        }

        getIssuanceDetailsByFinalSaleId();
        getMarkRoosAuthority();        

        setTimeout(() => {
            setIsLoading(false);
          }, 5000);
        // eslint-disable-next-line
      }, []);

      const updateIssuanceDetails = (finalSaleInfo) => {
        let retireFlagDesc = getRetireFlagDesc(finalSaleInfo);
        finalSaleInfo.retireFlagDesc = retireFlagDesc;
        setIssuanceDetails(finalSaleInfo);
      }
      const updateMarkRoosAuthorityDependencies = (data, type) => {
        setMarkRoosAuthorityDependencies(prevState => ({
            ...markRoosAuthorityDependencies, [type] : data
        }));
      }

    return (
        <div>
            <div>
                <div className="panel panel-default" id="stepspanel" style={{marginBottom: '0px'}}>
    
                    <div className="panel-heading">
                        <div className="container">
                            <div className="panel-title">
                                <div className="fright" style={{margin: '5px'}}>
                                <button className="custom-button me-2" onClick={() => window.open(`${configManager.debtWatchUrl}/issue-level-detail/${cdiacNo}`)}>View in DebtWatch</button>
                                {markRoosAuthority.issueStatusFlag === 'D' &&   <button className="custom-button" onClick={handlePrint}>Print PDF (Draft)</button>}</div>
                                <h1 className="menu-title">Marks Roos Yearly Fiscal Status Report For Authority Issue</h1>                                
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="container">
                            <MKRAuthorityIssueProgressBar openSection={openWidget} />
                            <div className="center-align">
                                <div style={{float: 'left'}}>
                                    <button
                                    type="button"
                                    className="dk-blue-color link-button" 
                                    onClick={()=>backToDashboard()}>Back to Dashboard</button>
                                </div>
                                <button
                                type="button"
                                style={{textDecoration: 'none', fontWeight: '500'}}
                                className="dk-blue-color link-button"
                                onClick={()=>toggleAllWidgets()}>{expandAll ? <VscCollapseAll /> : <VscExpandAll /> } {expandAll ? "Collapse All" : "Expand All" }</button>
                            
                                <div style={{ float: 'right' }}>
                                    <button
                                    type="button"
                                    className="dk-blue-color link-button"
                                    onClick={() => setShowHistory(true)}>Show History</button>

                                    {(markRoosAuthority && (markRoosAuthority.issueStatusFlag === "E" || markRoosAuthority.issueStatusFlag === "R")) &&
                                    <><br />
                                    <button
                                    type="button"
                                    className="dk-blue-color link-button" 
                                    onClick={()=>setShowEditReasonHistory(true)}>Show Edit Reason History</button>
                                    </>}
                                </div>
                            </div>
                            <br/>
                            <div style={{color: 'green'}}>
                            <em>CDIAC # {cdiacNo}</em>
                            <br/>
                            <em>Balances Reported as of {CommonService.dateFormatterMonthDayYear(markRoosAuthority.fiscalYrEndDate)}</em>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>
            {isLoading ? <Spinner animation="border" variant="primary"/> :
                <><IssuanceDetailContext.Provider value={issuanceDetail}>
                <CollapsibleWidgets openSectionObj={openSection} toggleWidget={toggleWidget} openSection={openWidget} 
                markRoosAuthority={markRoosAuthority} 
                markRoosAuthorityDetails={markRoosAuthorityDependencies.mkrAuthorityData} 
                contact={contact} 
                updateMarkRoosAuthorityDependencies = {updateMarkRoosAuthorityDependencies}
                updateIssuanceDetails={updateIssuanceDetails}
                markRoosAuthorityDependencies={markRoosAuthorityDependencies}
                />
                </IssuanceDetailContext.Provider>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <div className="container">
                            <div className="panel-body" style={{ padding: '10px 0 0' }}>
                                <button className="custom-button" onClick={() => backToDashboard()}>Back to Dashboard</button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            }
            {showHistory && <History onModalClose={()=>setShowHistory(false)} issueId={markRoosAuthority.mkrAuthId} reportTypeId="marks_roos_authority_id"/>}
            {showEditReasonHistory && <EditReasonHistory onModalClose={()=>setShowEditReasonHistory(false)} issueId={markRoosAuthority.mkrAuthId} cdiacCodeValueCode="MKAER"/>}

        </div>
    )
}

export default MKRAuthorityIssueYFS
