import {useState, useEffect} from 'react'
import { Form, Spinner } from 'react-bootstrap'
import Card from '../ui/Card'
import { validateFormFields } from '../../utils/index'
import DebtInstrumentMaintenanceService from "../../services/DebtInstrumentMaintenanceService";
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'

const TypeOfDebt = (props) => {
    const { issueId, proposedDebtDetails } = props;
    //const [selectedDebtGroup, setSelectedDebtGroup] = useState('');
    const [showOtherTextField, setShowOtherTextField] = useState(false);
    const [formErrors, setFormErrors]= useState({});
    const [debtGroups, setDebtGroups]= useState([]);
    const [debtInstruments, setDebtInstruments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDebtInstrumentsLoading, setIsDebtInstrumentsLoading] = useState(false);
    const [proposeDebtInfo, setProposeDebtInfo] = useState(proposedDebtDetails);
  
    useEffect(() => {
        setProposeDebtInfo(proposedDebtDetails);
        if(proposedDebtDetails.debtInstrumentGroupCodeId) {
            getDebtInstruments(proposedDebtDetails.debtInstrumentGroupCodeId);
        }

        if(['OTH', 'OTHB', 'OTHN'].includes(proposedDebtDetails.debtInstrumentCode) ){
            setShowOtherTextField(true);
        }
    },[proposedDebtDetails]);

    useEffect(() => {
        var obj = {
          msgId: "null",
          sysId: null,
          opr: "GetLookups",
          hdrStruct: null,
          queryParam: null,
          reqData: { cdiacCode: "DEBTG", active: "Y" },
        };
    
        DebtInstrumentMaintenanceService.getAllDebtGroups(obj)
          .then((res) => res)
          .then((result) => {
            setDebtGroups(result.data.responses[0]);
            setIsLoading(false);
          });
      }, []);
    
    const updateDebtInstrument = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putProposeDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issueId,
                "proposeDebt": {...proposeDebtInfo}}
        };
       
        ReportOfProposedDebtService.updateProposedDebt(obj)
          .then((res) => res)
          .then((result) => {
            if(result.data.responses[0]) {
                props.updateProposedDebtDetails(result.data.responses[0]);
                props.sectionValidated('typeOfDebt', true);
                props.openWidget('sourceOfRepayment');
            }
          });
    }
    
    const validateForm = (e) => {
        let form = e.target.closest('form#typeOfDebtForm');
        let formErrors = validateFormFields(form.elements);
      
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('typeOfDebt', false);
        } else {
            setFormErrors({});
            updateDebtInstrument();
        }
    }

    const getDebtInstruments = async (groupId) => {
            var obj = {
              msgId: null,
              sysId: null,
              opr: "getDebtInstrumentRefsByGroupId",
              hdrStruct: null,
              queryParam: null,
              reqData: { "id": groupId },
            };
        
            await DebtInstrumentMaintenanceService.getAllDebtGroups(obj)
              .then((res) => res)
              .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                setDebtInstruments(filteredList);
                setIsDebtInstrumentsLoading(false);
              });
    }
    const handleChange = (e) => {
        const {name, value} = e.target;
        setProposeDebtInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
        if(value !== "") {
            let debtGroupId = value;           
            setIsDebtInstrumentsLoading(true);
            getDebtInstruments(debtGroupId);
        } else {
            setDebtInstruments([]);
        }
    }

    const handleDebtTypeChange = (e) => {
        const { name, value } = e.target;
        if(['OTH', 'OTHB', 'OTHN'].includes(value) ){
            setShowOtherTextField(true);
        } else {
            setShowOtherTextField(false);
        }

        setProposeDebtInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleOtherTextChange = (e) => {
        const { name, value } = e.target;

        setProposeDebtInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    return (
        <Card>
            <form className="form" id="typeOfDebtForm">

                <label style={{fontSize: '16px', marginBottom: '0.5rem'}}><span className="required">*</span>Debt Group: </label>
                <br />
                {isLoading && <Spinner animation="border" variant="primary"/>}
                <Form.Select as="select" onChange={(e)=>handleChange(e)} value={proposeDebtInfo.debtInstrumentGroupCodeId} data-validations="required" name="debtInstrumentGroupCodeId">
                        <option value="">select</option>
                        
                        {debtGroups.length !== 0 && debtGroups.map((debtGroup, i) =>{
                            return (<option key={i} value={debtGroup.cdiacCodeValueId} >{debtGroup.cdiacCodeValueDesc}</option>);
                        }
                        ) }
                </Form.Select>
                {formErrors['debtGroup'] && <p className="error">{formErrors['debtGroup']}</p>}
                <br />
                    
                {isDebtInstrumentsLoading && <Spinner animation="border" variant="primary"/>}
                <div style={{marginTop: '10px'}}>
                {debtInstruments.length !== 0  &&  <label> Type: </label>}
                {debtInstruments && debtInstruments.map((debtInstrument, i) => {
                        return(<Form.Check key={Math.random()} name="debtInstrumentCode" id={`debtInstrument_${i}`} type="radio"  value={debtInstrument.debtInstrumentCode} label={debtInstrument.debtInstrumentName} data-validations="required" checked={debtInstrument.debtInstrumentCode===proposeDebtInfo.debtInstrumentCode ? true : false} onChange={(e)=>handleDebtTypeChange(e)}/>);
                    })
                }
                {formErrors['debtInstrumentCode'] && <p className="error">{formErrors['debtInstrumentCode']}</p>}
                </div>
                <br/>

                {showOtherTextField && <div form="control" style={{width: '50%'}}>
                    <label>Please specify if "Other" was checked above: </label><Form.Control type="text" name="debtInstrumentOtherText" value={proposeDebtInfo.debtInstrumentOtherText? proposeDebtInfo.debtInstrumentOtherText : "" } onChange={e=>handleOtherTextChange(e)}/> </div>}
                
                <div className="btn-div">
                    {/* <button className="custom-button">Back</button> */}
                    <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
                </div>    
            </form>
        </Card>
    )
}

export default TypeOfDebt