import { Form, Collapse } from 'react-bootstrap';
import Card from '../../ui/Card';
import {useState, useCallback, useEffect,useContext } from 'react';
import {FaPlus, FaMinus} from 'react-icons/fa';
import ReasonForPrivateSale from './reasonForPrivateSale';
import { validateFormFields } from '../../../utils/index';
import swal from 'sweetalert';
import FinalSaleReportService from '../../../services/FinalSaleReportService';
import IssuePrivateSaleReasonService from '../../../services/IssuePrivateSaleReasonService';
import { UserContext } from '../../../UserContext';
import CommonService from '../../../services/CommonService';
import { toast } from 'react-toastify';
import { AiFillQuestionCircle } from 'react-icons/ai';
import TooltipComponent from '../../../common/Tooltip';

const TypeOfSale = (props) => {
    const {finalSaleDetails, updateFinalSaleDependencies} = props;
    const finalSaleId = finalSaleDetails.finalSaleId;
    const loginUser = useContext(UserContext);
    const [openPrivateSaleSection, setOpenPrivateSaleSection] = useState(false);
    const [viewReasonForReportSale, setViewReasonForReportSale] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [finalSaleInfo, setFinalSaleInfo] = useState(finalSaleDetails ? finalSaleDetails : {});
    const [issuePrivateSaleReasons, setIssuePrivateSaleReasons] = useState([]);

    const fetchIssuePrivateSaleReasons = useCallback(async () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssuePrivateSaleReasonsByFinalSaleId",
            hdrStruct: null,
            queryParam: null,
            reqData: {"finalSaleId" : finalSaleId},
        };
      
        await IssuePrivateSaleReasonService.getPrivateSaleReasonsByIssueId(obj)
        .then((res) => res)
        .then((result) => {
            setIssuePrivateSaleReasons(result.data.responses[0]);
        });
    },[finalSaleId])

    const checkIfIsFinalPrivateSaleReason = useCallback(() => {
        var obj = {
        msgId: null,
        sysId: null,
        opr: "IsFinalPrivateSaleReason",
        hdrStruct: null,
        queryParam: null,
        reqData: {"finalSaleId" : finalSaleId},
        };

        FinalSaleReportService.checkIfIsFinalPrivateSaleReason(obj)
        .then((res) => res)
        .then((result) => {
            const responseObj = result.data.responses[0];
            if(responseObj.issuePrivateSaleResons) {
                setViewReasonForReportSale(true);
                fetchIssuePrivateSaleReasons();
            } else {
                if(responseObj.isProposePrivateSaleReason) {
                    swal("Debt issued under authority provided in Gov. Code sections 53580 - 53589.5 - Provisions Applicable to All Refunding Bonds of All Local Agencies or Gov. Code sections 54300-54700, Revenue Bond Law of 1941, requires a statement of the reasons why the bonds were sold at private sale or on a negotiated sale basis instead of at public (competitive) sale within two weeks of the sale pursuant to Gov. Code sections 53583(c)(2)(B) or 54418, respectively.", {
                        buttons: [false, true],
                    }).then((value) => {
                        setViewReasonForReportSale(true);
                    });
                }
            }
        }); 
        // eslint-disable-next-line
    }, [finalSaleId, fetchIssuePrivateSaleReasons]);
    
    useEffect(()=>{
        if(finalSaleInfo.saleTypeCode === 'N') {
            checkIfIsFinalPrivateSaleReason();
        }
        // eslint-disable-next-line
    },[finalSaleInfo.saleTypeCode]);

    const validateForm = async(e) => {
        let form = e.target.closest('form#typeOfSaleForm');

        let formErrors = validateFormFields(form.elements);        
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('typeOfSale', false);
        } else {
            setFormErrors({});
            if(finalSaleInfo.saleTypeCode === "N" && viewReasonForReportSale && issuePrivateSaleReasons.length === 0) {
                alert("Please select at least one negotiated sale reason.")
            } else {
                if(finalSaleInfo.saleTypeCode === "N" && viewReasonForReportSale) {
                    await saveIssuePrivateSaleReasons();    
                } 
                await saveTypeOfSale(); 
            }         
        }
    }
    const saveTypeOfSale = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };
    
        await FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                if(finalSaleInfo.pvtPlacementFinanceInd === "Y") {
                    validateManagementTakedownFees();
                } else {
                    toast.success("Type of Sale updated successfully!");
                    props.updateFinalSaleDetails(result.data.responses[0]);
                    props.sectionValidated('typeOfSale', true);
                    props.openWidget('uploadDoc');  
                }
            }
        });
    }
    const saveIssuePrivateSaleReasons = async () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "mergeIssuePrivateSaleReasonsByIssueId",
            hdrStruct: null,
            queryParam: null,
            reqData: {"id" : finalSaleId,
            "issuePrivateSaleReasons" : issuePrivateSaleReasons,
            "reportType" : "RFS"},
        };
      
        await IssuePrivateSaleReasonService.saveIssuePrivateSaleReasons(obj)
        .then((res) => res)
        .then((result) => {
            updateFinalSaleDependencies(result.data.responses[0], "issuePrivateSaleReasons");
            console.log("Private Sale Reasons Saved Successfully!");
        });
    }

    const validateManagementTakedownFees = async () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "validateManagementTotalTakedownFees",
            hdrStruct: null,
            queryParam: null,
            reqData: {"issueId" : finalSaleId},
        };
      
        await FinalSaleReportService.checkManagementTotalTakedownFees(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            } else{
                toast.success("Type of Sale updated successfully!");
                props.updateFinalSaleDetails(result.data.responses[0]);
                props.sectionValidated('typeOfSale', true);
                props.openWidget('uploadDoc');  
            }
        });
    }
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        let debtInfo = {...finalSaleInfo};
        debtInfo[name] = value;
        setFinalSaleInfo(debtInfo);
        if(name === 'saleTypeCode') {
            if(value === 'N') {
                checkIfIsFinalPrivateSaleReason();
            } else {
                setViewReasonForReportSale(false);
            }
        }
    }
    const handlePrivateSaleReasonChange = (e) => {
        let issPrivateSaleReasons = [...issuePrivateSaleReasons];
        const {value, checked, dataset} = e.target;
        if ( checked ) {
            let obj = {};
            obj['proposeDebtId'] = null;
            obj['reasonCodeId'] = parseInt(value);
            obj['createUser'] = loginUser;
            obj['finalSaleId'] = finalSaleId;
            obj['reasonOtherText'] = "";
            obj['cdiacCodeValueCode'] = dataset.cdiaccodevaluecode;
            issPrivateSaleReasons.push(obj);
        } else {
            const index = issPrivateSaleReasons.findIndex( x => x.reasonCodeId === parseInt(value) );
            issPrivateSaleReasons.splice(index, 1);
        }
        setIssuePrivateSaleReasons(issPrivateSaleReasons);
    }

    const handleReasonOtherTextChange = (e) => {
        let issPrivateSaleReasons = [...issuePrivateSaleReasons];
        const {value} = e.target;
        issPrivateSaleReasons.find( x => x.cdiacCodeValueCode === "6" ).reasonOtherText = value;
        setIssuePrivateSaleReasons(issPrivateSaleReasons);
    }
   
    return (
        <Card>
            <form className="form" id="typeOfSaleForm">
                <div className="mb-10">
                <span className="required">*</span>
                    <TooltipComponent
                  title="Prepopulated with data submitted on the RPDI – update if necessary. If the sale of debt was not made through
                  a formal Competitive bid process among multiple potential creditors, select Negotiated. For loans from the state
                  or federal government, or loans from a Mark-Roos authority, select Negotiated."
                  id="invoice"
                  placement="right">
                      <AiFillQuestionCircle className="help-icon" />
                  </TooltipComponent>{' '}
                    <Form.Check
                            inline
                            label="Competitive"
                            id="Competitive"
                            value="C"
                            name="saleTypeCode"
                            type="radio"
                            checked={finalSaleInfo.saleTypeCode === 'C'}
                            onChange={(e)=>handleChange(e)}
                        />
                    <Form.Check
                            inline
                            label="Negotiated"
                            id="Negotiated"
                            value="N"
                            name="saleTypeCode"
                            type="radio"
                            checked={finalSaleInfo.saleTypeCode === 'N'}
                            onChange={(e)=>handleChange(e)}
                        />
                        {formErrors['typeOfSale'] && <p className="error">{formErrors['typeOfSale']}</p>}
                </div>
                
                {viewReasonForReportSale && <div>
                    <div className="panel panel-default">
                        <div className="" role="tab">
                        <h5 className="panel-title">
                        <button
                        type="button"
                        className="" 
                        style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
                        aria-expanded={openPrivateSaleSection} aria-controls="dashboard" onClick={()=>setOpenPrivateSaleSection(!openPrivateSaleSection)}> {!openPrivateSaleSection ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Add Reason for Negotiated Sale</button> 
                        </h5>
                        </div>
                        <Collapse in={openPrivateSaleSection}>
                            <div id="dashboard">
                                <ReasonForPrivateSale handleChange={handlePrivateSaleReasonChange} handleReasonOtherTextChange={handleReasonOtherTextChange} issuePrivateSaleReasons={issuePrivateSaleReasons} issueId={finalSaleId}/>
                            </div>
                        </Collapse>
                    </div>
                </div>
                }

                <div className="mb-10">
                    <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Is this Financing a Private Placement?</label>
                    <br />
                    <Form.Check
                            inline
                            label="No"
                            value="N"
                            name="pvtPlacementFinanceInd"
                            id="PvtPlacementFinanceInd-1"
                            type="radio"
                            checked={finalSaleInfo.pvtPlacementFinanceInd === 'N'}
                            onChange={(e)=>handleChange(e)}
                            data-validations="required"
                        />
                    <Form.Check
                            inline
                            label="Yes"
                            value="Y"
                            name="pvtPlacementFinanceInd"
                            id="PvtPlacementFinanceInd-2"
                            type="radio"
                            checked={finalSaleInfo.pvtPlacementFinanceInd === 'Y'}
                            onChange={(e)=>handleChange(e)}
                            data-validations="required"
                        />
                    {formErrors['pvtPlacementFinanceInd'] && <p className="error">{formErrors['pvtPlacementFinanceInd']}</p>}
                </div>
                
                <div className="btn-div">
                    {/* <button className="custom-button">Back</button> */}
                    <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
                </div>
            </form>
           
        </Card>
    )
}

export default TypeOfSale
