import CommonService from "./CommonService";

const searchIssuerFilerRequests = (obj) => {
    return CommonService.apicall(obj)

};
const updateIssuerFilerRequest = (obj) => {
    return CommonService.apicall(obj)
};

const getIssuers = (obj) => {
    return CommonService.apicall(obj)
};

const getFilerIssuerAssociations = (obj) => {
    return CommonService.apicall(obj)
}

const createFilerIssuerAssoc = (obj) => {
    return CommonService.apicall(obj)
}

const deleteFilerIssuerAssoc = (obj) => {
    return CommonService.apicall(obj)
}

const notifyIssuerOrgAssoc = (obj) => {
    return CommonService.apicall(obj)
}

const deleteFilerRequest = (obj) => {
    return CommonService.apicall(obj)
}

const IssuerFilerService = {
    searchIssuerFilerRequests,
    updateIssuerFilerRequest,
    getIssuers,
    getFilerIssuerAssociations,
    createFilerIssuerAssoc,
    deleteFilerIssuerAssoc,
    notifyIssuerOrgAssoc,
    deleteFilerRequest
};


export default IssuerFilerService;