import {useState} from 'react'
import { Collapse } from "react-bootstrap"
import Issuance from './issuance'
import Draw from './draw'
// import RevenuewBondsReason from './revenueBondsReason'
import FilingContact from './filingContact'
import Comments from './comments'
import DrawOnReserveReview from './drawOnReserveReview'
import {FaPlus, FaMinus} from 'react-icons/fa'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const CollapsibleWidgets = (props) => {
  const {openSectionObj, toggleWidget, openSection, drawId, issuanceDetail, drawDetails, updateDraw, contact} = props;
  const [sectionValidated, setSectionValidated] = useState({
    issuance: true,
    draw: drawDetails && drawDetails.drawFlag ? true: false,
    filingContact: true
  });
  const updateSectionValidated = (sectionName, isValid) => {
    setSectionValidated(prevState=>({
      ...prevState, [sectionName]: isValid
    }))
  }
    return (
      <>
      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('issuance')}>
        <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
          id="issuance" aria-expanded={openSectionObj.issuance} aria-controls="collapse_issuance" onClick={()=>toggleWidget('issuance')}> {!openSectionObj.issuance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance </button> 
        {/* {sectionValidated.issuance && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>} */}
        </h5>
        <Collapse in={openSectionObj.issuance}>
          <div id="collapse_issuance">
          {openSectionObj.issuance && <Issuance openWidget={openSection} drawId={drawId} issuanceDetail={issuanceDetail}/> }
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
       <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('draw')}>
        <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="draw" aria-expanded={openSectionObj.draw} aria-controls="collapse_draw"> {!openSectionObj.draw ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Event Details</button> 
        {sectionValidated.draw && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
        </h5>
        <Collapse in={openSectionObj.draw}>
          <div id="collapse_draw">
          {openSectionObj.draw && <Draw openWidget={openSection} sectionValidated={updateSectionValidated} drawId={drawId} drawDetails={drawDetails} updateDraw={updateDraw}/> }
          </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('filingContact')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="filingContact" aria-expanded={openSectionObj.filingContact} aria-controls="collapse_filingContact"> {!openSectionObj.filingContact ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Filing Contact </button> 
          {/* {sectionValidated.filingContact && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>} */}
        </h5>
        <Collapse in={openSectionObj.filingContact}>
          <div id="collapse_filingContact">
          {openSectionObj.filingContact && <FilingContact openWidget={openSection} drawDetails={drawDetails}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
       <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('comments')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="comments" aria-expanded={openSectionObj.comments} aria-controls="collapse_comments"> {!openSectionObj.comments ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Comments </button> 
        <div style={{float: 'right', margin: '0 10px'}}><small>(Optional)</small></div>
        </h5>
        <Collapse in={openSectionObj.comments}>
          <div id="collapse_comments">
          {openSectionObj.comments && <Comments openWidget={openSection} drawId={drawId} drawDetails={drawDetails} updateDraw={updateDraw}/>}
            </div>
        </Collapse>
      </div> 
      </div>
      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('review')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="review" aria-expanded={openSectionObj.review} aria-controls="collapse_review"> {!openSectionObj.review ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Review</button> 
        </h5>
        <Collapse in={openSectionObj.review}>
          <div id="collapse_review">
          <DrawOnReserveReview openWidget={openSection} toggleWidget={toggleWidget} sectionValidated={sectionValidated} drawId={drawId} issuanceDetail={issuanceDetail} drawDetails={drawDetails} contact={contact}/>
          </div>
        </Collapse>
      </div>
      </div>
      </>
    );
}

export default CollapsibleWidgets
