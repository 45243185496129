import CommonService from "./CommonService";


const getIssuers = (obj) => {
    return CommonService.apicall(obj)

};

const addAuthorizationRef = (obj) => {
    return CommonService.apicall(obj)

};

const searchAuthorizationRef = (obj) => {
    return CommonService.apicall(obj)
};

const getAuthorizationRefs = (obj) => {
    return CommonService.apicall(obj)

};

const getAuthorizationSummary = (obj) => {
    return CommonService.apicall(obj)

};

const getIssuances = (obj) => {
    return CommonService.apicall(obj)

};

const updateAuthorizationRef = (obj) => {
    return CommonService.apicall(obj)

};
const getAuthorizationsByIssuerId = (obj) => {
    return CommonService.apicall(obj)

};

const sendRegisterEmail = (obj) => {
    return CommonService.apicall(obj)

};

const AuthorizationMaintenanceService = {
    addAuthorizationRef,
    searchAuthorizationRef,
    getIssuers,
    getAuthorizationRefs,
    getAuthorizationSummary,
    getIssuances,
    updateAuthorizationRef,
    getAuthorizationsByIssuerId,
    sendRegisterEmail
};


export default AuthorizationMaintenanceService;