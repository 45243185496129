import  {useState, useContext} from 'react'
import { Modal, Row, Col, Form} from 'react-bootstrap'
import React from "react";
import { useForm } from "react-hook-form";
import SeniorSubordinateService from '../../../services/SeniorSubordinateService'
import CommonService from "../../../services/CommonService";
import { UserContext } from "../../../UserContext";
import { toast } from 'react-toastify';
import Alert from 'react-bootstrap/Alert'
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const AddAssociation = ({onModalClose, associationType,selectedRow}) => {
    const { register, handleSubmit } = useForm();
    const user = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState();

    const [showReports, setShowReports] = useState(false);
    const [seniors, setSeniors] = useState([]);

    const viewFormatter = (row) => { 
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
               <button type="button" className="link-button dk-blue-color" onClick={(e)=>onRowClick(e, row)}>Associate</button>
            </div> 
        ); 
    }

    const onRowClick = (e, row) => {
      var obj = {
        msgId: null,
        sysId: null,
        opr: "postSeniorSubordinateAssocation",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "seniorSubordinateAssocation" :{
            "subordinateCdiacNbr": row.subordinateCdiacNbr,
            "seniorCdiacNbr": selectedRow.cdiacNbr,
            "createUser": CommonService.getUserName(user),
        }
        },
      };
  
      SeniorSubordinateService.addSeniorSubordinateAssoc(obj)
        .then((res) => res)
        .then((result) => {
          if (result.data.errors && result.data.errors.length > 0){
            setErrorMessage(result.data.errors[0].message)
        }
        else{
            toast.success("Subordinate associated successfully!");
            onModalClose();
        }
        });
    }
    const columns = [{
        dataField: 'subordinateCdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, {
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate']),
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      },
      { 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];
    
    const ReportsTable = () => {
        return(
          <DataGrid
          dataSource={seniors}
          sortField='subordinateCdiacNbr'
          emptyDataMessage = 'No Reports to Display'
          >
          {columns.map((column, i) => {
              return (
              <Column 
              key={i}
              field={column.dataField}
              header={column.text}
              sortable={column.sort} 
              body={column.formatter}
              />)
            }) 
          } 
          </DataGrid>
        )
    }
    const onSubmit = (data) => {

      var obj = {
        msgId: null,
        sysId: null,
        opr: "getSeniorSubordinateByIssuerId",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "searchSeniorSubAssociation" :{
            "issuerId": selectedRow.issuerId,
            "issueName": data.issueName,
            "cdiacNumber": data.cdiacNumber
        }
        },
      };
  
      SeniorSubordinateService.getSeniorSubordinateByIssuerId(obj)
        .then((res) => res)
        .then((result) => {
          Object.keys(result.data.responses).forEach((key) => {
            setSeniors(result.data.responses[key]);
          });
        });

      setShowReports(true);
    };


    return (
      <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
      <Modal.Header closeButton>Search Criteria</Modal.Header>
      <Modal.Body>
      {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
          {associationType === 'marksroos-authority' && 
          <Row>
              <Col md={3}><label style={{marginRight: '0'}}>Issuer Name Contains:</label></Col>
              <Col md={9}><Form.Control type="text" placeholder="Issuer Name"/></Col>
          </Row>
          }
          <Row>
              <Col md={3}><label style={{marginRight: '0'}}>Issue Name Contains:</label></Col>
              <Col md={9}><Form.Control type="text" placeholder="Issue Name" {...register("issueName")} /></Col>
          </Row>
          <Row>
              <Col md={3}><label>CDIAC Number:</label></Col>
              <Col md={4}><Form.Control type="text" placeholder="CDIAC Number" {...register("cdiacNumber")} /></Col>
              <Col md={2}><button type="submit" className="custom-button" >Go</button></Col>
          </Row>
        </form>

        <div>
            {showReports && <ReportsTable />
            }
        </div>
      </Modal.Body>

          <Modal.Footer>
          <button className="fright custom-button" onClick={()=>onModalClose()}>Close</button>
          </Modal.Footer>
        </Modal>
    )
}

export default AddAssociation
