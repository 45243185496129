import { useState, useEffect } from 'react'
import classes from '../formElements/Input.module.css'
import Card from '../ui/Card'
import { Form, Col , Row, Spinner} from "react-bootstrap"
import {FormInput} from '../formElements/FormInput'
import { runValidations } from '../../utils/index'
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import { toast } from 'react-toastify'

const FilingContact = (props) => {
    const {issueId, reportType} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [filingContactInfo, setFilingContactInfo] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        agency: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        city: "",
        state: "",
        zipCode: "",
        areaCode: "",
        phoneNumber: "",
        phoneExt: "",
        sendAcknowledgementTo: "",
    });
    const [filingContactErrors, setfilingContactErrors] = useState({});

    useEffect(()=>{
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueFilingContact",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issueId,
                "reportType" : reportType
            }
        };
       
        ReportOfProposedDebtService.getIssueFilingContact(obj)
          .then((res) => res)
          .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
              if(result.data.responses.length > 0) {
                setFilingContactInfo(result.data.responses[0]);
              }
            }
            setIsLoading(false);
        });
    }, [issueId, reportType])

    const onHandleChange = (e) => {
        const {name, value} = e.target;
        setFilingContactInfo({
            ...filingContactInfo,
            [name]: value
        });
    }
    const validateField = (e) => {
        const validationRes = runValidations(e);
        setfilingContactErrors({
            ...filingContactErrors, [e.target.name]: validationRes
          })
    }
    const inputProps = {
        onChange: (e) => onHandleChange(e),
        onBlur: (e)=> validateField(e)
    }
    
    const validateForm = (e) => {
        props.openWidget('interestDebtExempt');
    }


    return (
        <Card>
            {isLoading ? <Spinner animation="border" variant="primary" /> :
        <Form className="form">
            
            <div>
                <label>Who completed this form and may be contacted for information:</label>
            
                <Row>
                    <Col md={4} className={classes.control}>
                        <FormInput 
                            label="First Name" 
                            type="text" 
                            name="firstName" 
                            value={filingContactInfo.contactFirstName ? filingContactInfo.contactFirstName : ""}
                            disabled
                            {...inputProps}
                        />
                    </Col>
                    <Col md={4} className={classes.control}>
                        <FormInput 
                            label="Middle Name" 
                            type="text" 
                            name="middleName" 
                            value={filingContactInfo.contactMiddleName ? filingContactInfo.contactMiddleName : ""}
                            disabled
                            {...inputProps}
                        />
                    </Col>
                    <Col md={4} className={classes.control}>
                        <FormInput 
                            label="Last Name" 
                            type="text" 
                            name="lastName" 
                            value={filingContactInfo.contactLastName ? filingContactInfo.contactLastName : ""} 
                            disabled
                            {...inputProps}
                        />
                    </Col>
                </Row>
                <div className={classes.control}>
                    <label>E-Mail:</label>
                    <input type="email" 
                    value={filingContactInfo.contactEmailId ? filingContactInfo.contactEmailId : ""}
                    disabled/>
                </div>
                <div className={classes.control}>
                    <label>Firm/ Agency:</label>
                    <input type="text" value={filingContactInfo.orgName ? filingContactInfo.orgName : ""}
                    disabled/>
                </div>
                <Row>
                    <Col md={6} className={classes.control}>
                        <label>Address Line1:</label>
                        <input type="text"
                        value={filingContactInfo.contactAddressLine1 ? filingContactInfo.contactAddressLine1 : ""}
                        disabled/>
                    </Col>
                    <Col md={6} className={classes.control}>
                        <label>Address Line2:</label>
                        <input type="text" 
                        value={filingContactInfo.contactAddressLine2 ? filingContactInfo.contactAddressLine2 : ""}
                        disabled/>
                    </Col>
                    <Col md={6} className={classes.control}>
                        <label>Address Line3:</label>
                        <input type="text"
                        value={filingContactInfo.contactAddressLine3 ? filingContactInfo.contactAddressLine3 : ""} disabled/>
                    </Col>
                    <Col md={6} className={classes.control}>
                        <label>Address Line4:</label>
                        <input type="text" 
                        value={filingContactInfo.contactAddressLine4 ? filingContactInfo.contactAddressLine4 : ""}
                        disabled/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className={classes.control}>
                        <label>City:</label>
                        <input type="text" 
                        value={filingContactInfo.contactCityName ? filingContactInfo.contactCityName : ""}
                        disabled/>
                    </Col>
                    <Col md={4} className={classes.control}>
                        <label>State</label>
                        <input type="text" 
                        value={filingContactInfo.contactStateCode ? filingContactInfo.contactStateCode : ""}
                        disabled/>
                    </Col>
                    <Col md={4} className={classes.control}>
                        <label>Zip Code:</label>
                        <input type="text"
                        defaultValue={filingContactInfo.contactZipCode ? filingContactInfo.contactZipCode : ""}
                         disabled/>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className={classes.control}>
                        <label>Area Code:</label>
                        <input 
                        type="text"
                        defaultValue={filingContactInfo.contactPhoneAreaCode ? filingContactInfo.contactPhoneAreaCode : ""}
                        disabled/>
                    </Col>
                    <Col md={4} className={classes.control}>
                        <label>Phone Number (XXX-XXXX)</label>
                        <input 
                        type="text"
                        defaultValue={filingContactInfo.contactPhoneNumber ? filingContactInfo.contactPhoneNumber : ""}
                        disabled/>
                    </Col>
                    <Col md={4} className={classes.control}>
                        <label>Ext</label>
                        <input 
                        type="text"
                        defaultValue={filingContactInfo.contactPhoneExtn ? filingContactInfo.contactPhoneExtn : ""}
                        disabled/>
                    </Col>
                </Row>
            </div>
            
            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Next</button>
            </div>
        </Form> 
        }
        {/* <button onClick={()=>saveIssuerContact(issuerContact)}>Back</button>
        <button><Link to='/financing-participants'>Continue</Link></button> */}
        </Card>
    )
}
  
export default FilingContact;