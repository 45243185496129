import { Row, Col } from 'react-bootstrap'
import {useState, useEffect} from 'react'
import Card  from '../../ui/Card'
import SearchResultContainer from './searchResultContainer'
import { useForm } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import AuthorizationMaintenanceService from '../../../services/AuthorizationMaintenanceService'
import Spinner from 'react-bootstrap/Spinner'
import swal from 'sweetalert'
import CodeMaintenanceService from '../../../services/CodeMaintenanceService'
import AddIssuer from './addIssuer'

const IssuerMaintenance = (props) => {
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [openAddIssuerModal, setOpenAddIssuerModal] = useState(false);
    const [searchReq, setSearchReq] = useState([]);
    const { handleSubmit} = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [issuer, setIssuer] = useState([]);
    const [orgTypes, setOrgTypes] = useState([]);
    const [orgDivisions, setOrgDivisions] = useState([]);
    const [searchParams, setSearchParams] = useState({
      issuerId: '',
      orgTypeCodeId: '',
      orgDivisionCodeId: ''
    })
    
    const handleIssuerChange = (e, key) => {
      setSearchParams(prevState=>({
        ...searchParams, 
          [key]: e.length > 0 ? e[0][key] : ''
        })
      )
    }
    const handleChange = (e, key) => {
      setSearchParams(prevState=>({
        ...searchParams, 
          [key]:  e.length > 0 ? e[0]['cdiacCodeValueId'] : ''
        })
      )
    }

    const onSubmit = (data) => {
        if(searchParams.issuerId === '' && searchParams.orgTypeCodeId === '' && searchParams.orgDivisionCodeId === '') {
          var name = 'Please select at least one search or filter criteria !';
          var content = document.createElement('div');
          content.innerHTML = '<strong>'+ name +'</strong>';
          swal(content);
          return;
        }
        var obj = {
            "issuerId": searchParams.issuerId,
            "orgTypeCodeId": searchParams.orgTypeCodeId,
            "orgDivisionCodeId": searchParams.orgDivisionCodeId,
        }
        setSearchReq(obj);
        setShowSearchResult(true);
    }

    useEffect(() => {
      async function fetchOrgTypes() {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: { cdiacCode: "ORGT", active: "Y" },
        };
    
        await CodeMaintenanceService.getAllCodes(obj)
        .then((res) => res)
        .then((result) => {
            const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
            const orgTypesListNew = [];
            filteredList.forEach((orgType, i)=>{
                let obj = {};
                obj = orgType;
                obj.value = orgType.cdiacCodeValueId;
                obj.label = orgType.cdiacCodeValueDesc;
                orgTypesListNew.push(obj);
            })
            
            setOrgTypes(orgTypesListNew);
            setIsLoading(false);
        })
      }

      async function fetchOrgDivisions() {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: { cdiacCode: "ORGD" },
        };
    
        await CodeMaintenanceService.getAllCodes(obj)
        .then((res) => res)
        .then((result) => {
            setOrgDivisions(result.data.responses[0]);
        })
      }        
      getIssuers()
      fetchOrgTypes()
      fetchOrgDivisions()
    }, []);

    const getIssuers = () => {
      //Get Issuers   
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getIssuers",
        hdrStruct: null,
        queryParam: null,
        reqData: { },
      };
  
      AuthorizationMaintenanceService.getIssuers(obj)
        .then((res) => res)
        .then((result) => {
          Object.keys(result.data.responses).forEach((key) => {
              setIssuer(result.data.responses[key]);
          });
          setIsLoading(false);
        });
    }

    const handleModalClose = () => {
      setOpenAddIssuerModal(false)
      getIssuers()
    }

    // function sendRegisterEmail() {
    //   var obj = {
    //     msgId: null,
    //     sysId: null,
    //     opr: "OnboardUsers",
    //     hdrStruct: null,
    //     queryParam: null,
    //     reqData: { },
    //   };
  
    //   AuthorizationMaintenanceService.sendRegisterEmail(obj)
    //     .then((res) => res)
    //     .then((result) => {
    //       Object.keys(result.data.responses).forEach((key) => {
    //         toast.success("User registration email sent");
    //       });
    //       setIsLoading(false);
    //     });
    // }


    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title" >
                        <h1 className="menu-title">Issuer Maintenance</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                  {isLoading && <Spinner animation="border" variant="primary" />}
                  <Row>
                    <Col>
                      <div className="control">
                          <label>Issuer Name: </label>
                          <Typeahead
                            placeholder="Choose an Issuer"
                            id="issurer"
                            labelKey="issuerName"
                            onChange={e=>handleIssuerChange(e, "issuerId")}
                            inputProps={{ 'data-validations': 'required', name: 'issuerName' }}
                            options={issuer}
                          />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                    <div className="control">
                        <label>Type: </label>
                        <Typeahead 
                          id="orgTypeCodeId"
                          name="orgTypeCodeId"
                          placeholder="Choose Org Type..."
                          labelKey="cdiacCodeValueDesc"
                          onChange={(e)=>handleChange(e, 'orgTypeCodeId')}
                          options={orgTypes}
                        />
                    </div>
                  </Col>
                  <Col>
                    <div className="control">
                        <label>Division: </label>
                        <Typeahead
                          placeholder="Choose Org Division"
                          id="orgDivisonCodeId"
                          name="orgDivisonCodeId"
                          labelKey="cdiacCodeValueDesc"
                          onChange={e=>handleChange(e, "orgDivisionCodeId")}
                          options={orgDivisions}
                        />
                    </div>
                  </Col>
                  </Row>

                  <div style={{overflow: 'auto'}}>
                    <button className="custom-button bg-success text-light rounded" style={{ float: 'left' }} onClick={() => setOpenAddIssuerModal(true)} >
                      Add New Issuer
                    </button>
                    {/* <button className="custom-button bg-success text-light rounded" style={{ marginLeft: '200px' }} onClick={() => sendRegisterEmail()} >
                      Send Register Email
                    </button> */}
                    <button style={{float: 'right'}} className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Search</button>
                  </div>
                  </div>
                </div>
              </div>
          </div>
          {openAddIssuerModal && <AddIssuer onModalClose={() => handleModalClose()} />}
          {showSearchResult && <SearchResultContainer searchReq={searchReq}/>}         
           
        </Card>
        </form>
    )
}

export default IssuerMaintenance
