import {useState, useEffect, useContext} from 'react'
import CollapsibleWidget from './collapsibleWidgets'
import ProgressBar from '../layout/ProgressBar'
import {VscExpandAll, VscCollapseAll} from 'react-icons/vsc'
import History from '../../common/History'
import { renderToString } from 'react-dom/server'
import PrintPdfComponent from './printPdfComponent'
import { useLocation, useNavigate } from "react-router-dom";
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import { IssuerContext, UserContext } from '../../UserContext'
import HtmlPdfService from '../../services/HtmlPdfService'
import { toast } from 'react-toastify'
import CommonService from '../../services/CommonService'
import { configManager } from '../../configManager'
const ProposedSaleReport = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const issueId = pathname.substring(pathname.lastIndexOf('/') + 1);
    const loginUser = useContext(UserContext);
    const [showHistory, setShowHistory] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [proposedDebtDetails, setProposedDebtDetails] = useState({});
    const [proposeDebtDependencies, setProposeDebtDependencies] = useState({
        "issueAuthorizations" : [],
        "issueStatutes" : [],
        "issueContacts": [],
        "issueRefundAssocs" : [],
        "issueFinancingPurposes" : [],
        "issueDocuments" : [],
        "issueMkrAuthAssocs" : [],
        "issuePrivateSaleReasons" : [],
        "issueRepaymentSources" : [],
        "issueSocEnvImpacts" : []
    });

    const getFederalLawSelection = (proposeDebt) => {
        let federalTaxSelectionArr = proposeDebt.federalTaxFlag ? proposeDebt.federalTaxFlag.split("") : [];

        let federalLaw = [];
        federalTaxSelectionArr.forEach(val => {
          if( val === 'T') {
            federalLaw.push("Taxable");
          }
          if( val === 'E') {
            federalLaw.push("Tax Exempt, No AMT");
          }
          if( val === 'A') {
            federalLaw.push("Tax Exempt, with AMT");
          }
        });
        federalLaw = federalLaw.join(', ');
        return federalLaw;
    } 

    useEffect(()=>{
        const fetchProposeDebt = async () => {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getProposeDebt",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issueId  
                }
            };
            await ReportOfProposedDebtService.getProposedDebt(obj)
            .then((res) => res)
            .then((result) => {
                let federalLawDisplayText = getFederalLawSelection(result.data.responses[0]);
                result.data.responses[0].federalLawDisplayText = federalLawDisplayText;
                setProposedDebtDetails(result.data.responses[0]);
            });
        }
        const fetchProposedDebtOtherDetails = async () => {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getProposeDebtOtherDetails",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issueId  
                }
            };
            await ReportOfProposedDebtService.getProposedDebt(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors[0]) {
                    toast(result.data.errors[0].message);
                } else {
                    setProposeDebtDependencies(result.data.responses[0]);
                }
            });
        }
        const fetchData = async()=> {
            await fetchProposeDebt();
            await fetchProposedDebtOtherDetails();
            setIsLoading(false);
        }

        CommonService.showHideSpinner(true);
        fetchData();
        CommonService.showHideSpinner(false);
    },[issueId])

    const [openSection, setOpenSection] = useState({
        issuance: true,
        issuanceAuthorization: false,
        statutories: false,
        issuerContact: false,
        filingContact: false,
        financingParticipants: false,
        typeOfDebt: false,
        interestDebtExempt: false,
        misc: false,
        typeOfSale: false,
        sourceOfRepayment: false,
        purposeOfFinancing: false,
        uploadDoc: false,
        review: false
    });

    const updateProposedDebtDetails = (proposedDebtDetails) => {
        let federalLawDisplayText = getFederalLawSelection(proposedDebtDetails);
        proposedDebtDetails.federalLawDisplayText = federalLawDisplayText;
        setProposedDebtDetails(proposedDebtDetails);
    }
    const updateProposeDebtDependencies = (data, type) => {
        setProposeDebtDependencies(prevState => ({
            ...proposeDebtDependencies, [type] : data
        }));
    }
    const toggleWidget = (sectionName) => {
      setOpenSection({...openSection, [sectionName]: !(openSection[sectionName])})
    }

    const toggleAllWidgets = () => {
        setExpandAll( !(expandAll) );
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: !(expandAll) })));
        setOpenSection(openSecObj);
    }
    const openWidget = (sectionName) => {
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: false })));
        openSecObj[sectionName] = !(openSecObj[sectionName]);
        setOpenSection(openSecObj);
    }
    
    const handlePrint = () => {
        const html = renderToString(<PrintPdfComponent proposedDebtDetails={proposedDebtDetails} proposeDebtDependencies={proposeDebtDependencies} />)

        var obj = {
            msgId: null,
            sysId: null,
            opr: "getPdf",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "html": html,
                "id" : issueId,
                "cdiacNbr": proposedDebtDetails.cdiacNbr,
                "createUser":  loginUser,   
                "status": 'D',
                "reportType" : "RPD" 
            },
          };

        HtmlPdfService.generatePdf(obj)
        .then((res) => { 
             var base64EncodedPDF = res.data;
             var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
             var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
             var url = window.URL.createObjectURL(blob);
             window.open(url);

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
         
    }
    
    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard",{state:{dashboardSelection: dashboardSelectionObj}} );
    }
    return (
        
        <div>
            <div className="panel panel-default">
                <div className="panel-heading" role="tab"> 
                    <div className="container">
                        <div className="panel-title">
                        <div className="fright" style={{margin: '5px'}}>
                        {proposedDebtDetails.issueStatusFlag === 'R' && <button className="custom-button me-2" onClick={() => window.open(`${configManager.debtWatchUrl}/issue-level-detail/${proposedDebtDetails.cdiacNbr}`)}>View in DebtWatch</button>}
                        {proposedDebtDetails.issueStatusFlag === 'D' && <button className="custom-button" onClick={handlePrint}>Print PDF (Draft)</button>}
                        </div>

                        <h1 className="main-title">Report of Proposed Debt Issuance</h1>
                        <small>(*Required)</small>
                        </div>
                    </div>
                </div>
                <div className="panel-body" style={{padding: '10px 0'}}>
                    <div className="container">
                        <ProgressBar openSection={openWidget}/> 
                        <div className="center-align">
                        <div style={{float: 'left'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard</button></div>
                        <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>toggleAllWidgets()}>{expandAll ? <VscCollapseAll /> : <VscExpandAll /> } {expandAll ? "Collapse All" : "Expand All" }</button>
                            <div style={{float: 'right'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>setShowHistory(true)}>Show History</button></div>
                        </div><br/>
                        <div>
                            <p>CDIAC Number: ( {proposedDebtDetails.cdiacNbr} )</p>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <div /> :
                <IssuerContext.Provider value={proposedDebtDetails.issuerId && proposedDebtDetails.issuerId}>
                    <CollapsibleWidget openSectionObj={openSection} toggleWidget={toggleWidget} openSection={openWidget} issueId={issueId} 
                    proposedDebtDetails={proposedDebtDetails}
                    proposeDebtDependencies={proposeDebtDependencies}
                    updateProposedDebtDetails={updateProposedDebtDetails}
                    updateProposeDebtDependencies={updateProposeDebtDependencies}/>
                    <div className="panel panel-default">
                        <div className="panel-heading"> 
                            <div className="container">
                                <div className="panel-body" style={{padding: '10px 0 0'}} >
                                <button className="custom-button" onClick={()=>backToDashboard()}>Back to Dashboard</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </IssuerContext.Provider>
            }
            {showHistory && <History onModalClose={()=>setShowHistory(false)} issueId={proposedDebtDetails.proposeDebtId} reportTypeId="propose_debt_id"/>}
            
        </div>
    )
}

export default ProposedSaleReport
