import {Dropdown} from 'react-bootstrap'
import { toast } from 'react-toastify'
import IssuerFilerService from '../../../services/IssuerFilerService'
import CommonService from '../../../services/CommonService'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const IssuerIssuerAssociations = (props) => {
  const { associations, fetchAssocs } = props;

  const viewEditFormatter = (row) => {
    return (
      <div style={{ textAlign: "center", lineHeight: "normal" }}>
          <div className="dropdown">
              <Dropdown>
              <Dropdown.Toggle 
              variant="secondary btn-sm" 
              id="dropdown-basic">
                  Action
              </Dropdown.Toggle>

              <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                  <Dropdown.Item href="#" onClick={(e)=>handleActionClick(e, 'approve', row )}>Approve</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e)=>handleActionClick(e, 'delete', row )}>Delete</Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
          </div>
      </div> 
    );
  }

  const handleActionClick = (e, action, row) => {
    if( action === 'approve' ) {
      let obj = {
        msgId: null,
        sysId: null,
        opr: "updateLinkedIssuerAssoc",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "issuerAssocId" : row.issuerAssocId,
          "verifiedFlag" : "Y"
        },
      };
  
      IssuerFilerService.updateIssuerFilerRequest(obj)
        .then((res) => res)
        .then((result) => {
          toast.success("Issuer Association updated successfully.")
          fetchAssocs();
        });
    } else if( action === 'delete') {
      let obj = {
        msgId: null,
        sysId: null,
        opr: "deleteLinkedIssuerAssoc",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "issuerAssocId" : row.issuerAssocId
        },
      };
  
      IssuerFilerService.updateIssuerFilerRequest(obj)
        .then((res) => res)
        .then((result) => {
          toast.success("Issuer Association deleted successfully.")
          fetchAssocs();
        });
    }
}  


  const columns = [{
    dataField: 'issuerName',
    text: 'Delegated Issuer Name',
    sort: true,
  }, {
    dataField: 'linkedIssuerName',
    text: 'Associated Issuer Name',
    sort: true,
  },{
    dataField: 'verifiedFlag',
    text: 'Status',
    sort: true,
  },{
    dataField: 'createUser',
    text: 'Requestor',
    sort: true,
  },{
    dataField: 'createDatetime',
    text: 'Create Date',
    sort: true,
    formatter: (row) => CommonService.dateFormatter(row['createDatetime'])
  },{
    dataField: "",
    text: "Action",
    formatter: viewEditFormatter,
  }
  ];

  return (
    <>
      <div className="form">
        <DataGrid
            title="Pending Issuer Association Requests:"
            dataSource={associations}
            sortField='createDatetime'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
        </DataGrid>
      </div>
    </>
  )
}

export default IssuerIssuerAssociations
