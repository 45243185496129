import { Form, Modal } from 'react-bootstrap'
import { AiFillQuestionCircle } from 'react-icons/ai'
import CodeMaintenanceService from "../../services/CodeMaintenanceService";
import React from "react";
import { useForm } from "react-hook-form";
import { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify';
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import CommonService from "../../services/CommonService";
import TooltipComponent from '../../common/Tooltip';

const EditCodeForm = (props) => {
    const user = useContext(UserContext);
    const { onModalClose, codeInfo } = props;
    
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            cdiacCodeValueCode: codeInfo.cdiacCodeValueCode,
            cdiacCodeValueDesc: codeInfo.cdiacCodeValueDesc,
            cdiacCodeValueId: codeInfo.cdiacCodeValueId,
            cdiacCodeValueShortDesc: codeInfo.cdiacCodeValueShortDesc,
            cdiacCode: codeInfo.cdiacCode,
            activeInd: codeInfo.activeInd === 'Y'? true : false
            
        }
    });
    const [errorMessage, setErrorMessage] = useState();

    const onSubmit = (data) => {

        var obj = {
            msgId: null,
            sysId: null,
            opr: "putCdiacCodeValueRef",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "cdiacCodeValueId": data.cdiacCodeValueId, "cdiacCodeValueRef": {
                    "cdiacCodeValueCode": data.cdiacCodeValueCode,
                    "cdiacCodeValueDesc": data.cdiacCodeValueDesc,
                    "cdiacCodeValueShortDesc": data.cdiacCodeValueShortDesc,
                    "cdiacCode": data.cdiacCode,
                    "createUser": "SYSTEM",
                    "lastUpdateUser": CommonService.getUserName(user),
                    "activeInd": data.activeInd === true ? 'Y' : 'N',
                    "cdiacCodeValueId": data.cdiacCodeValueId
                }
            },
        };

        CodeMaintenanceService.updateCode(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0) {
                    setErrorMessage(result.data.errors[0].message)
                }
                else {
                    toast.success("Code updated successfully!");
                    onModalClose();
                }

            });
    }
    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Modal show={true} backdrop="static" onHide={onModalClose}>
                <Modal.Header closeButton><h3>Edit Code</h3></Modal.Header>
                <Modal.Body>
                    {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
                    </Alert>}
                    <div className="control">
                        <label><span className="required">* </span>Code: </label>
                        <input maxLength="6" {...register('cdiacCodeValueCode', { required: true })} disabled />
                    </div>
                    <div className="control">
                        <label><span className="required">* </span>Description: </label>
                        <textarea maxLength="500" {...register('cdiacCodeValueDesc', { required: true })} />
                        {errors.cdiacCodeValueDesc && <p style={{ color: 'red' }}>Description is required</p>}
                    </div>
                    <div className="control">
                        <label>Brief Description: </label>
                        <textarea maxLength="100" {...register('cdiacCodeValueShortDesc', { required: true })} />
                        {errors.cdiacCodeValueShortDesc && <p style={{ color: 'red' }}>Brief Description is required</p>}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Form.Check type="checkbox" label="Active?" {...register('activeInd')} />
                        <TooltipComponent
                            title="Only active values are displayed in the User Interface"
                            id="isActive"
                            placement="right">
                                <AiFillQuestionCircle className="help-icon mt-2" />
                        </TooltipComponent>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                    <button className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Update</button>
                </Modal.Footer>
            </Modal>
        </form>
    )
}

export default EditCodeForm
