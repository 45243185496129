import { useState, useEffect } from "react";
import AzureDocService from "../../services/AzureDocService";
import Spinner from "react-bootstrap/Spinner";
import DataGrid from "../GenericComponents/DataGrid";
import { Column } from "primereact/column";

const SearchResultContainer = (props) => {
  const { searchReq } = props;
  const [azureDoc, setAzureDoc] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getIssueDocumentsByCDIACNumbers",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        beginCDIAC: searchReq.beginCDIAC.toString(),
        endCDIAC: searchReq.endCDIAC.toString(),
      },
    };

    AzureDocService.getDocuments(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setAzureDoc(result.data.responses[key]);
        });
        setIsLoading(false);
      });
  }, [props, searchReq]);

  const handleViewDocument = (e, issueDocId) => {
    setIsLoading(true);
    let obj = {
      msgId: null,
      sysId: null,
      opr: "getPdfDocumentFromBlob",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        issueDocId: issueDocId,
        type: "issue_document",
      },
    };
    AzureDocService.getHistory(obj).then((res) => {
      var base64EncodedPDF = res.data.responses[0];
      var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF);
      function base64ToArrayBuffer(base64) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes;
      }
      setIsLoading(false);
      var blob = new Blob([arrrayBuffer], { type: "application/pdf" });
      var url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  };

  const fileNameFormatter = (row) => {
    return (
      <div style={{ lineHeight: "normal" }}>
        <button
          type="button"
          className="link-button dk-blue-color"
          onClick={(e) => handleViewDocument(e, row.issueDocId)}
        >
          {row.fileName}
        </button>
      </div>
    );
  };
  const columns = [
    {
      dataField: "cdiaC_NBR",
      text: "CDIAC Number",
      sort: true,
    },
    {
      dataField: "finalSaleId",
      text: "Final Sale ID",
      sort: true,
    },
    {
      dataField: "proposeDebtId",
      text: "Propose Debt",
      sort: true,
    },
    {
      dataField: "melloRoosId",
      text: "Mello Roos",
      sort: true,
    },
    {
      dataField: "docType",
      text: "Doc Type",
      sort: true,
    },
    {
      dataField: "fileName",
      text: "File Name",
      formatter: fileNameFormatter,
      sort: true,
    },
  ];

  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" />}
      <div className="form">
        <h2>Search Result: </h2>

        <DataGrid
            dataSource={azureDoc}
            sortField='reportId'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
          >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                />)
              }) 
            } 
          </DataGrid>
      </div>
    </>
  );
};

export default SearchResultContainer;
