import {useState, useEffect, useContext} from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import {validateFormFields} from '../../utils';
import CurrencyInput from '../formElements/CurrencyInput';
import FinalSaleReportService from '../../services/FinalSaleReportService';
import { toast } from 'react-toastify';
import {UserContext} from "../../UserContext";
import CommonService from '../../services/CommonService';

const AddLocalObligor = (props) => {
    const {onModalClose,markRoosAuthorityDetails, markRoosAuthority } = props;
    const [formErrors,setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const loginUser = useContext(UserContext);
    const [lobLookup, setLobLookup] = useState([]);

    const [stateValues, setStateValues] = useState({
        adminFeeAmt : '',
        mkrAuthLobId: '',
        issuerName:'',
        debtInstrumentCode:''
    })

    const handleChange = (e) => {
        const {name, value} = e.target;

        if(name === 'mkrAuthLobId'){
            const filteredItems = lobLookup.filter(x => x.mkrAuthLobId === parseInt(value));

            setStateValues(prevState => ({
                ...stateValues, [name]: value,
                issuerName : (filteredItems[0].issuerName),
                debtInstrumentCode : (filteredItems[0].debtInstrumentCode),
                principalSaleAmt: (filteredItems[0].principalSaleAmt),
            })
            )
        }
        else{
            setStateValues(prevState => ({
                ...stateValues, [name]: value
            })
            )
        }


    }

    useEffect(()=>{

        async function fetchLobLookups() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "getMarkRoosAuthorityLobLookup",
                hdrStruct: null,
                queryParam: null,
                reqData: { "authCdiacNbr": markRoosAuthority.cdiacNbr},
            };
        
            await FinalSaleReportService.getMarkRoosAuthorityLobLookup(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    setLobLookup(result.data.responses[0]);
                    setIsLoading(false);
                }

            })
        }
        fetchLobLookups();
        // eslint-disable-next-line
    },[])

    const handleSave = (form) => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "createMarkRoosLocalObligorFee",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "markRoosAuthorityLocalObligorFee":{      
                "markRoosAuthorityId"  : markRoosAuthorityDetails.markRoosAuthorityId,  
                "mkrAuthLobId" :  parseInt(stateValues.mkrAuthLobId),   
                "adminFeeAmt" : stateValues.adminFeeAmt,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser,
                "createUser":  CommonService.getUserName(loginUser)
            }
            }
        };
    
       FinalSaleReportService.createMarkRoosLocalObligorFee(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("Local Obligor was added Successfully!!");
                onModalClose();
            }

        });
    }
    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
           handleSave(form);
        }
    }
    return (
        <Modal  show={true} onHide={onModalClose}>
            <Modal.Header closeButton><h3>Add Local Obligor</h3></Modal.Header>
            <Modal.Body>
                <form id="add-form">
                    <Row>
                        <Col md={6}>
                            <div className="control">
                                <label><span className="required">*</span>CDIAC #:</label>
                                <Form.Select name="mkrAuthLobId"
                                            data-validations="required"
                                            onChange={(e) => handleChange(e)} isLoading={isLoading}>
                                            <option value="">Select</option>
                                            {lobLookup.map((item, i) => {
                                                return <option key={i} value={item.mkrAuthLobId}>{item.lobCdiacNbr}</option>
                                            })
                                            }
                                    </Form.Select>
                        {formErrors['mkrAuthLobId'] && <p className="error">{formErrors['mkrAuthLobId']}</p>}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <label>Issuer Name: </label>
                                <Form.Control type="text" name="issuerName" defaultValue= {stateValues.issuerName} readOnly />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <label>Principal Amount: </label>
                                <Form.Control type="text" name="principalAmount" defaultValue= {CommonService.currencyFormatter(stateValues.principalSaleAmt)}  readOnly />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <label>Bond Purchase(BP), Loan(L) or Other(O): </label>
                                <Form.Control type="text" name="obligorType" defaultValue= {stateValues.debtInstrumentCode}  readOnly />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="control">
                                <CurrencyInput 
                                label="Administration Fee (Charged to LOB) this FY" 
                                placeholder="0.00" 
                                name="adminFeeAmt"
                                type="text" 
                                onChange={(e)=>handleChange(e)}  
                                validations={ ["required", "max:999999999999.99"]}
                                error={formErrors && formErrors['adminFeeAmt']} 
                                isrequired="true"
                               />
                            </div>
                        </Col>

                    </Row>
                    <div className="btn-div">
                        <div className="fright">
                            <button type="button" className="custom-button custom-button-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                            <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddLocalObligor
