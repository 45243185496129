import { Row, Col, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import Card from "../../ui/Card";
import SearchResultContainer from "./searchResultContainer";
import { useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import InvoiceService from "../../../services/InvoiceService";
import { AlertType } from "../../../utils";
import CommonService from "../../../services/CommonService";

const Invoice = (props) => {
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchReq, setSearchReq] = useState([]);
  const { handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [issuer, setIssuer] = useState([]);

  const [searchParams, setSearchParams] = useState({
    issuerName: "",
    invoiceStatus: "",
    invoiceId: "",
    cdiacNbr: "",
  });

  const handleChange = (e, key) => {
    if (key === "invoiceId") {
      searchParams.invoiceId = e.target.value;
    } else if (key === "cdiacNbr") {
      searchParams.cdiacNbr = e.target.value;
    } else if (key === "invoiceStatus") {
      searchParams.invoiceStatus = e.target.value;
    } else {
      const selectedValue =  e.length > 0 ? e[0][key] : "";
      setSearchParams((prevState) => ({
        ...searchParams,
        [key]: selectedValue,
      }));
      
    }
  };

  const onSubmit = (data) => {
    var obj = {
      issuerName: searchParams.issuerName,
      invoiceId: searchParams.invoiceId,
      cdiacNbr: searchParams.cdiacNbr,
      invoiceStatus: searchParams.invoiceStatus,
      opr: "searchInvoice",
    };

    setSearchReq(obj);
    setShowSearchResult(true);
  };

  useEffect(() => {
    //Get Invoice Issuer List
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getIssuers",
      hdrStruct: null,
      queryParam: null,
      reqData: {},
    };

    InvoiceService.getInvoiceIssuerList(obj)
      .then((res) => res)
      .then((result) => {
        if(result.data.statusCode === 401 || result.data.statusCode === 500) {
          CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
        } else {
          setIssuer(result.data.responses[0]);
        };
        setIsLoading(false);
      });
  }, []);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Card>
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="container">
              <div id="packet_id" className="panel-title" >
                  <h1 className="menu-title">Invoices</h1>
              </div>
          </div>
        </div>
        <div className="panel-body">
          <div className="container">
            <div className="form">
              <Row>
                <Col>
                  <div className="control">
                    <label>Invoice Number: </label>
                    <input
                      type="text"
                      placeholder="Invoice Number"
                      id="invoiceId"
                      name="invoiceId"
                      onChange={(e) => handleChange(e, "invoiceId")}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="control">
                    <label>CDIAC #</label>
                    <input
                      type="text"
                      placeholder="CDIAC #"
                      id="cdiacNbr"
                      onChange={(e) => handleChange(e, "cdiacNbr")}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="control">
                      <label>Status: </label>
                      <Form.Select name="invoiceStatus" defaultValue={searchParams.invoiceStatus} onChange={(e)=>handleChange(e, "invoiceStatus")}>
                              <option value="">Select</option>
                              <option value="OP">Open</option>
                              <option value="PE">Pending</option>
                              <option value="PD">Paid</option>
                              <option value="CA">Cancelled</option>
                      </Form.Select>
                  </div>
                </Col>
                <Col>
                  <div className="control">
                    <label>Issuer Name: </label>
                    <Typeahead
                      placeholder="Choose an Issuer"
                      id="issurerName"
                      labelKey="issuerName"
                      onChange={(e) => handleChange(e, "issuerName")}
                      inputProps={{
                        "data-validations": "required",
                        name: "issuerName",
                        maxLength: "120",
                      }}
                      options={issuer}
                      isLoading={isLoading}
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ overflow: "auto" }}>
                <button
                className="custom-button"
                  style={{ float: "right" }}
                  type="Submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Search
                </button>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
        {showSearchResult && <SearchResultContainer searchReq={searchReq} />}
      </Card>
    </form>
  );
};

export default Invoice;
