import { useState, useEffect } from 'react'
import { Form, Modal, Row, Col } from 'react-bootstrap'
import Card from '../ui/Card'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { AlertType, validateFormFields } from '../../utils/index'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UserContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { IssuanceDetailContext } from '../../UserContext'
import IssueCdiacNumberService from '../../services/IssueCdiacNumberService'
import { Typeahead } from 'react-bootstrap-typeahead'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TooltipComponent from '../../common/Tooltip'
import { FaCalendarAlt } from 'react-icons/fa'

const RetiredIssues = (props) => {
    const {melloRoos, melloRoosDetails} = props;
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [showMaturedFields, setShowMaturedFields] = useState(false);
    const [showRedeemedFields, setShowRedeemedFields] = useState(false);
    const [showOtherFields, setShowOtherFields] = useState(false);
    const [showNewCdiacField, setShowNewCdiacField] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const user = useContext(UserContext);
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [cdiacNumbersList, setCdiacNumbersList] = useState([]);
    const [cdiacNbrsLoading, setCdiacNbrsLoading] = useState(true);
    const RetiredIssuesOptions = [
        { value: 'N', label: 'Not Retired' },
        { value: 'M', label: 'Matured' },
        { value: 'R', label: 'Redeemed Entirely' },
        { value: 'O', label: 'Other' },
    ]

    const [stateValues, setStateValues] = useState({
        retireDate : issuanceDetail.mlrRetireDate ? new Date(issuanceDetail.mlrRetireDate) : null,
        retireFlag: issuanceDetail.mlrRetireFlag,
        issueName: issuanceDetail.issueName,
        stateRefundingBondTitle: issuanceDetail.stateRefundingBondTitle,
        finalMaturityDate : issuanceDetail.mlrRetireDate ? new Date(issuanceDetail.mlrRetireDate) : null,
        correctCdiacNbrInd: melloRoosDetails.correctCdiacNbrInd,
        correctedRedeemCdiacNbr: melloRoosDetails.correctedRedeemCdiacNbr,
        otherRetiredText: issuanceDetail.otherRetireText,
        otherDate: issuanceDetail.mlrRetireDate ? new Date(issuanceDetail.mlrRetireDate) : null
    });

    useEffect(() => {    
        async function getCdiacNumbers() {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getCdiacNumbers",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {}
            };
           
            IssueCdiacNumberService.getCdiacNumbers(obj)
              .then((res) => res)
              .then((result) => {
                setCdiacNumbersList(result.data.responses[0]);
                setCdiacNbrsLoading(false);
              });
        }
        getCdiacNumbers();
    }, []);

    useEffect(()=>{
        handleRetirementSelection(stateValues.retireFlag);
    },[stateValues.retireFlag]);

    const handleRetirementSelection = (retireFlag) => {
        switch (retireFlag) {
            case "M":
                setShowMaturedFields(true);
                setShowRedeemedFields(false);
                setShowOtherFields(false);
                break;
            case "R":
                setShowMaturedFields(false);
                setShowRedeemedFields(true);
                setShowOtherFields(false);
                break;
            case "O":
                setShowRedeemedFields(false);
                setShowMaturedFields(false);
                setShowOtherFields(true);
                break;
            default:
                setShowMaturedFields(false);
                setShowRedeemedFields(false);
                setShowOtherFields(false);
                break;
        }
    }
    const handleCdiacNbrChange = (e, key) => {
        const value = e.length ? e[0].cdiacNbr : "";

        setStateValues(prevState => ({
            ...stateValues, [key]: value
        })
        )
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const handleChangeMaturityDate = (date, key) => {
        setStateValues(prevState => ({
            ...stateValues, [key]: date
        })
        )
    }

    const checkIfcdiacCorrect = (e) => {
        let newStateValues = {...stateValues};
        const {name, value} = e.target;
        newStateValues[name] = value
        if (e.target.checked && e.target.value === "N") {
            setShowNewCdiacField(true);
        } else {
            setShowNewCdiacField(false);
            newStateValues['correctedRedeemCdiacNbr'] = null;
        }
        
        setStateValues(newStateValues)
    }


    const validateForm = (e) => {

        let form = e.target.closest('form#retiredIssues');

        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});

            if(stateValues.retireFlag !== 'N' && melloRoosDetails.principalOutstdgAmt > 0) {
                CommonService.displayServerMessage("In order for this issue to be marked as retired, the Principal Amount of Bonds Outstanding in the Fund Balance section must be $0.", AlertType.Error);
                return;
            }
            if(stateValues.retireFlag === 'R'  && stateValues.retireDate !== undefined){
           
                var fullYearR = CommonService.getFullYear(melloRoos.fiscalYrEndDate);
                var previousYearR = fullYearR - 1;
                var compareDateR = CommonService.dateFormatter(stateValues.retireDate);
                var startDateR = CommonService.dateFormatter('7/1/'+ previousYearR);            
                var endDateR = CommonService.dateFormatter('6/30/' + fullYearR);

                if(compareDateR.valueOf() < startDateR.valueOf() || compareDateR.valueOf() > endDateR.valueOf() ) {
                    toast.error('Invalid Date. The date entered must fall within the filing year July 1st - June 30th');
                    return;
                }
                
            }

            if(stateValues.retireFlag === 'R' ){
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "updateMelloRoosNbrInd",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: {  
                        "id" : melloRoos.melloRoosId,
                        "env" : "I",
                        "melloRoos":{
                            "correctCdiacNbrInd" : stateValues.correctCdiacNbrInd,
                            "correctedRedeemCdiacNbr": stateValues.correctedRedeemCdiacNbr,
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
                    },
                };
            
                FinalSaleReportService.updateMelloRoosRetire(obj)
                    .then((result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        } else {
                            props.updateMelloRoosDetails(result.data.responses[0]);
                        }  
                        
                    });
            }

            if(stateValues.retireFlag === 'M'  && stateValues.finalMaturityDate !== undefined){

                var fullYear = CommonService.getFullYear(melloRoos.fiscalYrEndDate);
                var previousYear = fullYear - 1;
                
                var compareDate = CommonService.dateFormatter(stateValues.finalMaturityDate);
                var startDate = CommonService.dateFormatter('7/1/'+ previousYear);            
                var endDate = CommonService.dateFormatter('6/30/' + fullYear);

                if(compareDate.valueOf() < startDate.valueOf() || compareDate.valueOf() > endDate.valueOf() ) {
                    stateValues.retireDate = compareDate;
                }
                else{
                    toast.error('Invalid Date. The date entered must fall within the filing year July 1st - June 30th');
                    return;
                }
                
            }

            if(stateValues.retireFlag === 'O'  && stateValues.otherDate !== undefined){

                var fullYearO = CommonService.getFullYear(melloRoos.fiscalYrEndDate);
                var previousYearO = fullYearO - 1;
                var compareDateO = CommonService.dateFormatter(stateValues.otherDate);
                var startDateO = CommonService.dateFormatter('7/1/'+ previousYearO);            
                var endDateO = CommonService.dateFormatter('6/30/' + fullYearO);
                
                if(compareDateO.valueOf() < startDateO.valueOf() || compareDateO.valueOf() > endDateO.valueOf() ) {
                    stateValues.retireDate = compareDateO;
                }
                else{
                    toast.error('Invalid Date. The date entered must fall within the filing year July 1st - June 30th');
                    return;
                }
                
            }

            if(stateValues.retireFlag === 'O'  && stateValues.otherDate !== undefined && stateValues.otherRetiredText !== ''){
                var objanother = {
                    msgId: null,
                    sysId: null,
                    opr: "updateMelloRoosOther",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: {  
                        "env" : "I",
                        "melloRoos":{
                            "retireFlag" : stateValues.retireFlag,
                            "otherRetiredText" : stateValues.otherRetiredText,
                            "retireDate" : stateValues.otherDate,
                            "finalSaleId" : issuanceDetail.finalSaleId,
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
                    },
                };
            
                FinalSaleReportService.updateMelloRoosOther(objanother)
                    .then((result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        } else {
                            props.updateIssuanceDetails(result.data.responses[0]);
                        }                    
                    });
                
            }


            var updateobj = {
            msgId: null,
            sysId: null,
            opr: "updateMelloRoosRetire",
            hdrStruct: null,
            queryParam: null,
            reqData: {  
                "env" : "I",
                "melloRoos":{
                    "retireFlag" : stateValues.retireFlag,
                    "retireDate" : stateValues.retireFlag !== 'N' ? stateValues.retireDate : null,
                    "finalSaleId" : issuanceDetail.finalSaleId,
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user
                }
            },
            };
      
            FinalSaleReportService.updateMelloRoosRetire(updateobj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                }
                else{
                    props.updateIssuanceDetails(result.data.responses[0]);
                    toast.success("Retired Issue updated successfully!");
                }
                
            });

            props.openWidget('filingContact')
        }
    }
    return (
        <Card>
            <form className="form" id="retiredIssues">
                <h3>Retired Issues
                    <TooltipComponent
                    title="Click me for more info."
                    id="mlr-reports"
                    placement="right">
                        <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>
                    </TooltipComponent>

                    {showInstructionsModal &&
                        <Modal show={true} backdrop="static" onHide={() => setShowInstructionsModal(false)}>
                            <Modal.Body>
                                <div>
                                    <span>{props.instructions}</span>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" onClick={() => setShowInstructionsModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }</h3>

                <Row>
                    <Col md={6}>
                        <label><span className="required">*</span>Indicate Reason for Retirement:</label>
                        <Form.Select name="retireFlag"
                        defaultValue={issuanceDetail.mlrRetireFlag}
                            data-validations="required"
                            onChange={(e) => handleChange(e)}>
                            <option value="">Select</option>
                            {RetiredIssuesOptions.map((item, i) => {
                                return <option key={i} value={item.value}>{item.label}</option>
                            })
                            }
                        </Form.Select>
                        {formErrors['retireFlag'] && <p className="error">{formErrors['retireFlag']}</p>}
                    </Col>
                </Row>

                {showMaturedFields &&
                    <Row>
                        <Col md={6}>
                        <div className="control">
                            <label><span className="required">*</span>Final Maturity Date:</label>
                            <span>
                            <DatePicker 
                            selected={stateValues && stateValues.finalMaturityDate ? stateValues.finalMaturityDate : null}
                            dateFormat="M/d/yyyy"
                            value={stateValues.finalMaturityDate}
                            maxDate= {new Date(9999, 12, 31)}
                            name="finalMaturityDate"
                            onChange={(e) => handleChangeMaturityDate(e, 'finalMaturityDate')} 
                            error={formErrors && formErrors['finalMaturityDate']} 
                            />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                            {formErrors['finalMaturityDate'] && <p className="error">{formErrors['finalMaturityDate']}</p>}
                            <input type="hidden" data-validations={["required"]}  value={CommonService.dateFormatter(stateValues.finalMaturityDate)} name="finalMaturityDate" />
                        </div>
                        </Col>
                    </Row>
                }

                {showRedeemedFields && <>
                    <Row>
                        <Col>
                            <label>CDIAC #:</label>
                            <Form.Control type="text" value={issuanceDetail.redeemCDIACNumber}  readOnly />
                        </Col>
                        <Col>
                            <label>Issue Name:</label>
                            <Form.Control type="text" value={issuanceDetail.stateRefundingBondTitle} disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label><span className="required">*</span>Is the CDIAC mentioned correct?</label>
                            <Form.Check inline type="radio" name="correctCdiacNbrInd" id="correctCdiacNbrIndY" label="Yes" value="Y" checked={stateValues.correctCdiacNbrInd === "Y"} onChange={(e) => checkIfcdiacCorrect(e)} data-validations="required" />
                            <Form.Check inline type="radio" name="correctCdiacNbrInd" id="correctCdiacNbrInd_N" label="No" value="N" checked={stateValues.correctCdiacNbrInd === "N"}  onChange={(e) => checkIfcdiacCorrect(e)} data-validations="required" />
                            {formErrors['correctCdiacNbrInd'] && <p className="error">{formErrors['correctCdiacNbrInd']}</p>}
                        </Col>

                        {(showNewCdiacField || stateValues.correctCdiacNbrInd === "N") && 
                        <Col md={6} >
                            <label>
                                {stateValues.correctCdiacNbrInd === "N" && <span className="required">*</span>}Corrected CDIAC #:</label>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Typeahead
                                                    placeholder="Choose CDIAC Number"
                                                    id="cdiacNbr"
                                                    labelKey="cdiacNbr"
                                                    onChange={(e)=>handleCdiacNbrChange(e, 'correctedRedeemCdiacNbr')}
                                                    options={cdiacNumbersList}
                                                    selected={
                                                        cdiacNumbersList.filter(option => 
                                                            option.cdiacNbr === stateValues.correctedRedeemCdiacNbr)
                                                    }
                                                    inputProps={{ 'data-validations': (stateValues.correctCdiacNbrInd === "N" ? 'required' : ''), name: 'correctedCdiacNbr' }}
                                                    isLoading={cdiacNbrsLoading}
                                            />
                                            {formErrors['correctedCdiacNbr'] && <span className="error">{formErrors['correctedCdiacNbr']}</span>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </Col>  
                        }
                    </Row>
                    <Row>
                    <Col md={6} className="control">
                            <label><span className="required">*</span>Redemption Date:</label>
                            <span>
                            <DatePicker 
                            selected={stateValues.retireDate ? new Date(stateValues.retireDate) : null}
                            dateFormat="M/d/yyyy"
                            name="redemptionDate"
                            maxDate= {new Date(9999, 12, 31)}
                            data-validations={['required']}
                            onChange={(e)=>handleChangeMaturityDate(e, 'retireDate')}
                            error={formErrors && formErrors["redemptionDate"]}  
                            />
                            <input type="hidden" value={stateValues.retireDate} data-validations={['required']} name="redemptionDate" />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                           {formErrors.redemptionDate && <p className="error">RedemptionDate Date is required</p>}
                           <input type="hidden" data-validations={["required"]}  value={CommonService.dateFormatter(stateValues.retireDate)} name="redemptionDate" />
                        </Col>
                    </Row></>
                }

                {showOtherFields && <Row>
                    <Col md={6}>
                        <label><span className="required">*</span>Other</label>
                        <Form.Control type="text" name="otherRetiredText" data-validations="required" onChange={(e)=>handleChange(e)} value={stateValues.otherRetiredText}/>
                        {formErrors['otherRetiredText'] && <p className="error">{formErrors['otherRetiredText']}</p>}
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <label><span className="required">*</span>Date:</label>
                            <span>
                            <DatePicker 
                            selected={stateValues && stateValues.otherDate ? new Date(stateValues.otherDate) : null}
                            dateFormat="M/d/yyyy"
                            value={stateValues.otherDate}
                            maxDate= {new Date(9999, 12, 31)}
                            name="otherDate"
                            onChange={(e) => handleChangeMaturityDate(e, 'otherDate')} 
                            error={formErrors && formErrors['otherDate']}   
                            />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                            {formErrors['otherDate'] && <p className="error">{formErrors['otherDate']}</p>}
                            <input type="hidden" data-validations={["required"]}  value={CommonService.dateFormatter(stateValues.otherDate)} name="otherDate" />
                        </div>
                    </Col></Row>
                }

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('foreclosure')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={e => validateForm(e)}>Save & Next</button>
                </div>
            </form>
        </Card>
    )
}

export default RetiredIssues
