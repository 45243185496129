import { Form } from 'react-bootstrap';
import {useState, useEffect} from 'react'
import Card  from '../../ui/Card'
import FinancialPurposeDetails from './financialPurposeDetails';
import FinancialPurposeMaintenanceService from "../../../services/FinancialPurposeMaintenanceService";
import Spinner from 'react-bootstrap/Spinner';

const FinancingPurposeMaintenance = () => {

    const [selectedFinancingPurposeGroup, setSelectedFinancingPurposeGroup] = useState(null);
    const [selectedFinancingPurposeGroupVal, setSelectedFinancingPurposeGroupVal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [financingPurposeGroups, setFinancingPurposeGroups] = useState([]);

    useEffect(() => {
        var obj = {
          msgId: "null",
          sysId: null,
          opr: "GetLookups",
          hdrStruct: null,
          queryParam: null,
          reqData: { cdiacCode: "FNPG", active: "Y" },
        };
    
        FinancialPurposeMaintenanceService.getAllFinancingPurposeGroups(obj)
          .then((res) => res)
          .then((result) => {
            setFinancingPurposeGroups(result.data.responses);
            setIsLoading(false);
          });
      }, []);


    const handleChange = (e) => {
        let financeCategoryCode = e.target.value;
        setSelectedFinancingPurposeGroup(financeCategoryCode);
        setSelectedFinancingPurposeGroupVal(e.target[e.target.selectedIndex].text);

    }



    return (

        <Card>
          <div className="panel panel-default">
           <div className="panel-heading">
              <div className="container">
                  <div id="packet_id" className="panel-title">
                      <h1 className="menu-title">Financing Purpose Maintenance</h1>
                  </div>
              </div>
          </div>
          <div className="panel-body">
            <div className="container">
              {isLoading && <Spinner animation="border" variant="primary" />}

            <div className="form">
                <div>
                    <label>Financing Purpose Group: </label>
                    {Object.keys(financingPurposeGroups).map((key) => {
            return (
              <div key={key}>
                <Form.Select
                  as="select"
                  onChange={(e) => handleChange(e)}
                  value={selectedFinancingPurposeGroup  || ''}
                >
                  <option>Select</option>
                  {financingPurposeGroups[key].map((dataItem) => {
                    return (
                      <option
                        key={dataItem.cdiacCodeValueCode}
                        value={dataItem.cdiacCodeValueId}
                      >
                        {dataItem.cdiacCodeValueDesc}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            );
          })}
                </div>

                {selectedFinancingPurposeGroup && <FinancialPurposeDetails selectedFinancingPurposeGroup={selectedFinancingPurposeGroup} selectedFinancingPurposeGroupVal={selectedFinancingPurposeGroupVal}/>}
                
            </div>
            </div>
          </div>
        </div>
            
        </Card>
    )
}

export default FinancingPurposeMaintenance
