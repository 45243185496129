import { Modal, Row, Col, Form} from 'react-bootstrap';
import React, {useState, useContext, useEffect} from 'react';
import Alert from 'react-bootstrap/Alert';
import { toast } from 'react-toastify';
import { UserContext } from '../../../UserContext';
import CommonService from '../../../services/CommonService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {FaCalendarAlt} from 'react-icons/fa';
import { validateFormFields } from '../../../utils/index';
import { Typeahead } from 'react-bootstrap-typeahead';
import BaseService from '../../../services/BaseService';

const AddElectionForm = (props) => {
    const user = useContext(UserContext);
    const {onModalClose} = props;
    const [formErrors, setFormErrors] = useState({});
    const [issuers, setIssuers] = useState([]);
    const [debtInstrumentRefs, setDebtInstrumentRefs] = useState([]);
    const [financePurposes, setFinancePurposes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDebtInstrumentRefsLoading, setIsDebtInstrumentRefsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [stateValues, setStateValues] = useState({
        issuerId: '',
        debtInstrumentCode: '',
        financePurposeCode: "",
        resultCode: '',
        yesPct: '',
        noPct: '',
        thresholdValue: '',
        measureName:'',
        electionYear: '',
        electionType:'',
        electionDate:''
    })

    useEffect(() => {
        //Get Issuers   
        async function getIssuers(){
            var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssuers",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
            };
        
            BaseService.processRequest(obj)
            .then((res) => res)
            .then((result) => {
                Object.keys(result.data.responses).forEach((key) => {
                    setIssuers(result.data.responses[key])
                });
                setIsLoading(false)
            });
        }
        //Get Debt instrument types
        async function getDebtInstrumentRefs(){
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getAllDebtInstrumentRefs",
                hdrStruct: null,
                queryParam: null,
                reqData: {},
            };
        
            await BaseService.processRequest(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    CommonService.displayServerMessage(result.data.errors[0].message);
                }
                else{
                    setDebtInstrumentRefs(result.data.responses[0])
                }
                setIsDebtInstrumentRefsLoading(false)
            });
        }
        //Get finance purposes
        async function getFinancePurposeTypes (){
            var obj = {
            msgId: null,
            sysId: null,
            opr: "getAllFinancingPurposes",
            hdrStruct: null,
            queryParam: null,
            reqData: {},
            };
        
            await BaseService.processRequest(obj)
            .then((res) => res)
            .then((result) => {   
                if (result.data.errors && result.data.errors.length > 0){
                    CommonService.displayServerMessage(result.data.errors[0].message);
                }
                else{
                    setFinancePurposes(result.data.responses[0]);
                }
            });
        }
        CommonService.showHideSpinner(true);
        getIssuers();
        getDebtInstrumentRefs();
        getFinancePurposeTypes();
        CommonService.showHideSpinner(false);
    }, []);

    const handleDateChange = (e, key) => {
        setStateValues(prevState=>({
            ...prevState, 
            [key]: e
        })
        )
    }
    
    const handleChange = (e, key) => {
        let value = ""
        if(key === "issuerId" || key === "debtInstrumentCode" || key === "financePurposeCode") {
            value = e.length ? e[0][key] : "";
        } else {
            value = e.target.value;
        }
        if(key === "amtText") {
            setCount(e.target.value.length);
            const color = (e.target.value.length === 2000) ? 'orangered' : 'yellowgreen';
            setBgcolor(color);
        }
        setStateValues(prevState =>({
            ...prevState, [key] : value
        }));
    }

    const handleSubmit = (e) => {
        let form = e.target.closest('form#addElectionForm');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            const yesPercent = typeof(stateValues.yesPct) === 'string' ? parseFloat(stateValues.yesPct.replace(/\$|,/g, '')) : stateValues.yesPct
            const noPercent = typeof(stateValues.noPct) === 'string' ? parseFloat(stateValues.noPct.replace(/\$|,/g, '')) : stateValues.noPct
            
            const totalPct = yesPercent + noPercent
            if(totalPct !== 100){
                toast.error("Total of Yes and No Percentage should be equal to 100.")
                return
            }
            
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "postStateElection",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "stateElection": {
                        "issuerId": stateValues.issuerId,
                        "debtInstrumentCode" : stateValues.debtInstrumentCode,
                        "amtText": stateValues.amtText,
                        "financePurposeCode": stateValues.financePurposeCode,
                        "measureName": stateValues.measureName,
                        "yesPct": yesPercent,
                        "noPct": noPercent,
                        "resultCode": stateValues.resultCode,
                        "thresholdValue": stateValues.thresholdValue,
                        "electionYear": stateValues.electionYear,
                        "electionType": stateValues.electionType,
                        "electionDate": stateValues.electionDate,
                        "createUser": CommonService.getUserName(user),
                        "lastUpdateUser": CommonService.getUserName(user),
                    }
                }
            };
      
            BaseService.processRequest(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("Election added successfully!");
                    onModalClose();
                }
            });
        }
    }

    return (
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Add New Election</h3></Modal.Header>
            <Modal.Body>
            <form id="addElectionForm">
                {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
                </Alert>}
                <Row>
                    <Col>
                      <div className="control">
                          <label><span className="required">*</span>Agency Name: </label>
                          <Typeahead
                            placeholder="Choose an Issuer"
                            id="issuerId"
                            labelKey="issuerName"
                            onChange={e=>handleChange(e, "issuerId")}
                            inputProps={{ 'data-validations': 'required', name: 'issuerId' }}
                            options={issuers}
                            selected = {
                                issuers.filter(option => 
                                    option.issuerId === stateValues.issuerId)
                                }
                                isLoading = {isLoading}
                          />
                      </div>
                      {formErrors.issuerId && <p className="error">Agency Name is required</p>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                      <div className="control">
                          <label><span className="required">*</span>Type of Tax Debt: </label>
                          <Typeahead
                            placeholder="Choose Type of Tax Debt"
                            id="debtInstrumentCode"
                            labelKey="debtInstrumentName"
                            onChange={e=>handleChange(e, "debtInstrumentCode")}
                            inputProps={{ 'data-validations': 'required', name: 'debtInstrumentCode' }}
                            options={debtInstrumentRefs}
                            selected = {
                                debtInstrumentRefs.filter(option => 
                                    option.debtInstrumentCode === stateValues.debtInstrumentCode)
                                }
                                isLoading = {isDebtInstrumentRefsLoading}
                          />
                      </div>
                      {formErrors.debtInstrumentCode && <p className="error">Type of Tax Debt is required</p>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Amount Text:</label>
                        <Form.Control as="textarea" rows={2} name="amtText" value={stateValues.amtText} 
                        onChange={(e)=>handleChange(e, "amtText")} 
                        maxLength="2000"/>
                        <span style={{position: 'relative', left: '40%' , backgroundColor: bgcolor }}>{count} out of 2000 max</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                      <div className="control">
                          <label><span className="required">*</span>Purpose: </label>
                          <Typeahead
                            placeholder="Choose purpose"
                            labelKey="financePurposeName"
                            id="financePurposeCode"
                            onChange={e=>handleChange(e, "financePurposeCode")}
                            inputProps={{ 'data-validations': 'required', name: 'financePurposeCode' }}
                            options={financePurposes}
                            selected = {
                                financePurposes.filter(option => 
                                    option.financePurposeCode === stateValues.financePurposeCode)
                                }
                                isLoading = {isDebtInstrumentRefsLoading}
                          />
                      </div>
                      {formErrors.financePurposeCode && <p className="error">Purpose is required</p>}
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="control">
                            <label><span className="required">*</span>Measure:</label>
                            <Form.Control type="text" name="measureName" value={stateValues.measureName} 
                            onChange={(e)=>handleChange(e, "measureName")} 
                            maxLength="50"
                            data-validations="required"/>
                        </div>
                        {formErrors.measureName && <p className="error">{formErrors.measureName}</p>}
                    </Col>
                    <Col md={4}>
                        <div className="control">
                            <label><span className="required">*</span>Yes Percentage:</label>
                            <Form.Control type="text" name="yesPct" value={stateValues.yesPct} 
                            onChange={(e)=>handleChange(e, "yesPct")} 
                            maxLength="5"
                            data-validations="required"/>
                        </div>
                        {formErrors.yesPct && <p className="error">{formErrors.yesPct}</p>}
                    </Col>
                    <Col md={4}>
                        <div className="control">
                            <label><span className="required">*</span>No Percentage:</label>
                            <Form.Control type="text" name="noPct" value={stateValues.noPct} 
                            onChange={(e)=>handleChange(e, "noPct")} 
                            maxLength="5"
                            data-validations="required"/>
                        </div>
                        {formErrors.noPct && <p className="error">{formErrors.noPct}</p>}
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="control">
                            <label><span className="required">*</span>Result:</label>
                            <Form.Control as="select" name="resultCode" selected={stateValues.resultCode} onChange={(e)=>handleChange(e, "resultCode")} data-validations="required">
                                <option value="">Select</option>
                                <option value="Pass">Pass</option>
                                <option value="Fail">Fail</option>
                                <option value="N/A">N/A</option>
                            </Form.Control>
                        </div>
                        {formErrors.resultCode && <p className="error">{formErrors.resultCode}</p>}
                    </Col>
                    <Col md={4}>
                        <div className="control">
                            <label><span className="required">*</span>Threshold:</label>
                            <Form.Control as="select" name="thresholdValue" value={stateValues.thresholdValue}onChange={(e)=>handleChange(e, "thresholdValue")} data-validations="required">
                                <option value="">Select</option>
                                <option value="55%">55%</option>
                                <option value="Two-thirds">Two-thirds</option>
                                <option value="Majority">Majority</option>
                                <option value="Not available">Not available</option>
                            </Form.Control>
                        </div>
                        {formErrors.thresholdValue && <p className="error">{formErrors.thresholdValue}</p>}
                    </Col>
                    <Col md={4}>
                        <div className="control">
                            <label><span className="required">*</span>Election Year:</label>
                            <Form.Control type="text" name="electionYear" value={stateValues.electionYear} 
                            onChange={(e)=>handleChange(e, "electionYear")} 
                            length="4"
                            data-validations="required"/>
                        </div>
                        {formErrors.electionYear && <p className="error">{formErrors.electionYear}</p>}
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="control">
                            <label><span className="required">*</span>Election Type:</label>
                            <Form.Control type="text" name="electionType" value={stateValues.electionType} 
                            onChange={(e)=>handleChange(e, "electionType")} 
                            data-validations="required"
                            />
                        </div>
                        {formErrors.electionType && <p className="error">{formErrors.electionType}</p>}
                    </Col>
                    <Col md={4}>
                        <div className="control">
                            <label><span className="required">*</span>Election Date: </label>
                            <div>
                                <span>
                                <DatePicker 
                                selected={stateValues.electionDate ? new Date(stateValues.electionDate) : null}
                                dateFormat="M/d/yyyy"
                                maxDate= {new Date(9999, 12, 31)}
                                name="electionDate"
                                onChange={(e) => handleDateChange(e, 'electionDate')} 
                                error={formErrors && formErrors['electionDate']}                                
                                />
                                <FaCalendarAlt className="date-picker-icon"/>
                                </span>
                                {formErrors.electionDate && <p className="error">Election Date is required</p>}
                                <input type="hidden" data-validations={["required"]}  value={stateValues.electionDate} name="electionDate" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                    <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
            </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddElectionForm
