import React from 'react'
import Card from '../ui/Card'
import {Card as BsCard, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const Admin = () => {
    return (
        <Card>
            {/* <div className="form"> */}
            <div className="panel panel-default">
              <div className="panel-heading">
                    <div className="container">
                        <div id="packet_id" className="panel-title">
                            <h1 className="menu-title">Admin</h1>
                        </div>
                    </div>
                </div>

                <div className="panel-body">
                    <div className="container">
                        <br/>
                        <div className="row">
                            <Col xs={12} sm={6} xl={4} className="mb-4 d-flex align-items-stretch">
                                <BsCard variant="light"  style={{width: '100%'}} className="bg-white shadow-sm mb-4 admin-widget" >
                                    <BsCard.Header className="text-center">Code Maintenance</BsCard.Header>

                                    <BsCard.Body>
                                        <ul style={{listStyle: 'none'}}>
                                            <li><Link to="/admin/code-maintenance">System Code Maintenance</Link></li>
                                            <li><Link to="/admin/debt-instrument-maintenance">Debt Instrument Maintenance</Link></li>
                                            <li><Link to="/admin/financing-purpose-maintenance">Financial Purpose Maintenance</Link></li>
                                            <li><Link to="/admin/county-maintenance">County Maintenance</Link></li>
                                            <li><Link to="/admin/statute-maintenance">Statute Maintenance</Link></li>
                                        </ul>
                                    </BsCard.Body>
                                </BsCard>
                            </Col>

                            <Col  xs={12} sm={6} xl={4} className="mb-4 d-flex align-items-stretch">
                                <BsCard variant="light"  style={{width: '100%'}} className="bg-white shadow-sm mb-4 admin-widget" >
                                    <BsCard.Header className="text-center">Reports</BsCard.Header>

                                    <BsCard.Body>
                                        <ul style={{listStyle: 'none'}}>
                                            <li><Link to="/admin/reports">Reports</Link></li>
                                            <li> <button
                                                     style={{color: '#2196F3', border:'none', background: 'transparent'}}
                                                      onClick={(e) => {
                                                      e.preventDefault();
                              window.location.href='https://app.powerbigov.us/groups/e32a9b62-0b02-467e-bdd0-eb7a370e3a85/reports/68710dc7-35a2-4785-aad1-3a3ae5598906/ReportSectiona6fbffd6d30ef7265f87';
                            }}
                        > Monthly Tracking Reports</button></li>
                                            <li> <button
                                                     style={{color: '#2196F3', border:'none', background: 'transparent'}}
                                                      onClick={(e) => {
                                                      e.preventDefault();
                              window.location.href='https://app.powerbigov.us/groups/e32a9b62-0b02-467e-bdd0-eb7a370e3a85/reports/a0ea97be-4bdb-46fd-9320-be2d66a5772c/ReportSection';
                            }}
                        > Annually Accumulative Summary Reports</button></li>
                                        </ul>
                                    </BsCard.Body>
                                </BsCard>
                            </Col>

                            <Col  xs={12} sm={6} xl={4} className="mb-4 d-flex align-items-stretch">
                                <BsCard variant="light" style={{width: '100%'}} className="bg-white shadow-sm mb-4 admin-widget" >
                                    <BsCard.Header className="text-center">Other</BsCard.Header>

                                    <BsCard.Body>
                                        <ul style={{listStyle: 'none'}}>
                                            {/* <li><Link to="/admin/organization-maintenance">Issuer/Contact Maintenance</Link></li> */}
                                            <li><Link to="/admin/authorization-maintenance">Authorization Maintenance</Link></li>
                                            <li><Link to="/admin/fee-schedule-maintenance">Fee Schedule Maintenance</Link></li>
                                            <li><Link to="/admin/state-election">State Election Data</Link></li>
                                            <li><Link to="/admin/remittance-advice-template">Remittance Advice Template</Link></li>
                                        </ul>
                                    </BsCard.Body>
                                </BsCard>
                            </Col>
                        
                            <Col xs={12} sm={6} xl={4} className="mb-4 d-flex align-items-stretch">
                                <BsCard variant="light" style={{width: '100%'}} className="bg-white shadow-sm mb-4 admin-widget" >
                                    <BsCard.Header className="text-center">Associations</BsCard.Header>

                                    <BsCard.Body>
                                        <ul style={{listStyle: 'none'}}>
                                            <li><Link to="/admin/associations">Associations</Link></li>
                                        </ul>
                                    </BsCard.Body>
                                </BsCard>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4 d-flex align-items-stretch">
                                <BsCard variant="light" style={{width: '100%'}} className="bg-white shadow-sm mb-4 admin-widget" >
                                    <BsCard.Header className="text-center">Issuer/Filer Requests</BsCard.Header>

                                    <BsCard.Body>
                                        <ul style={{listStyle: 'none'}}>
                                            <li><Link to="/admin/issuer-filer-requests">Issuer/Filer Requests</Link></li>
                                            <li><Link to="/admin/issuer-maintenance">Issuer Maintenance</Link></li>
                                            <li><Link to="/admin/org-maintenance">Org/Filer Maintenance</Link></li>
                                            <li><Link to="/admin/pending-association-requests">Pending Association Requests</Link></li>
                                        </ul>
                                    </BsCard.Body>
                                </BsCard>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4 d-flex align-items-stretch">
                                <BsCard variant="light" style={{width: '100%'}} className="bg-white shadow-sm mb-4 admin-widget" >
                                    <BsCard.Header className="text-center">ADTR Batch</BsCard.Header>

                                    <BsCard.Body>
                                        <ul style={{listStyle: 'none'}}>
                                            <li><Link to="/admin/adtr-batch">Process ADTR Batch</Link></li>
                                            <li><Link to="/admin/adtr">Review Submitted ADTRs</Link></li>
                                        </ul>
                                    </BsCard.Body>
                                </BsCard>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4 d-flex align-items-stretch">
                                <BsCard variant="light" style={{width: '100%'}} className="bg-white shadow-sm mb-4 admin-widget" >
                                    <BsCard.Header className="text-center">CDIAC # Quick Links</BsCard.Header>

                                    <BsCard.Body>
                                        <ul style={{listStyle: 'none'}}>
                                            <li><Link to="/admin/status-history-upload-documents">Reporting History & Uploaded Documents</Link></li>
                                        </ul>
                                    </BsCard.Body>
                                </BsCard>
                            </Col>
                            <Col xs={12} sm={6} xl={4} className="mb-4 d-flex align-items-stretch">
                                <BsCard variant="light" style={{width: '100%'}} className="bg-white shadow-sm mb-4 admin-widget" >
                                    <BsCard.Header className="text-center">DebtWatch Maintenance</BsCard.Header>

                                    <BsCard.Body>
                                        <ul style={{listStyle: 'none'}}>
                                            <li><Link to="/admin/debtwatch-configuration">DebtWatch Configuration</Link></li>
                                        </ul>
                                    </BsCard.Body>
                                </BsCard>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </Card>
    )
}

export default Admin
