import { useState, useEffect, useCallback } from "react";
import Spinner from "react-bootstrap/Spinner";
import InvoiceService from "../../../../services/InvoiceService";
import CommonService from "../../../../services/CommonService";
import EditInvoiceComment from "./editInvoiceComment";
import AddInvoiceComment from "./addInvoiceComment";
import DeleteInvoiceComment from "./deleteInvoiceComment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import DataGrid from "../../../GenericComponents/DataGrid";
import { Column } from "primereact/column";
import { useParams } from "react-router-dom";

const ViewComments = (props) => {
  const params= useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceComments, setInvoiceComments] = useState([]);
  const [selectedCommentInfo, setSelectedCommentInfo] = useState([]);
  const invoiceId = params.invoiceId;
  const [editComment, setEditComment] = useState(false);
  const [openNewCommentModal, setOpenNewCommentModal] = useState(false);
  const [openDeleteCommentModal, setOpenDeleteCommentModal] = useState(false);

  const fetchData = useCallback(() => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getInvoiceComments",
      hdrStruct: null,
      queryParam: null,
      reqData: { invoiceId: invoiceId },
    };

    InvoiceService.getInvoiceComments(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setInvoiceComments(result.data.responses[key]);
          setIsLoading(false);
        });
      });
  }, [invoiceId]);

  useEffect(() => {
    fetchData(invoiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  const onRowClick = (e, row) => {
    e.preventDefault();
    setSelectedCommentInfo(row);
    setEditComment(true);
  };
  const onDeleteClick = (e, row) => {
    e.preventDefault();
    setSelectedCommentInfo(row);
    setOpenDeleteCommentModal(true);
  };
  const onAddButtonClick = (e) => {
    e.preventDefault();
    setOpenNewCommentModal(true);
  };
  const onNewCommentModalClose = () => {
    setOpenNewCommentModal(false);
    fetchData(invoiceId);
  };
  const onEditCommentModalClose = () => {
    setEditComment(false);
    fetchData(invoiceId);
  };
  const onDeleteCommentModalClose = () => {
    setOpenDeleteCommentModal(false);
    fetchData(invoiceId);
  };

  const viewActionFormatter = (row) => {
    return (
      <div
        style={{
          lineHeight: "normal",
        }}
      >
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="custom-icon mr-2"
          onClick={(e) => onRowClick(e, row)}
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="custom-icon"
          onClick={(e) => onDeleteClick(e, row)}
        />
      </div>
    );
  };
  const columns = [
    {
      dataField: "invoiceComments",
      text: "Comments",
      sort: true,
    },
    {
      dataField: "createUser",
      text: "Created By",
      sort: true,
    },
    {
      dataField: "createDatetime",
      text: "Created Date",
      sort: true,
      formatter: (row) => CommonService.dateFormatter(row['createDatetime']),
    },
    {
      dataField: "",
      text: "Manage",
      formatter: viewActionFormatter,
    }
  ];

  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" />}
      <div>
        <div className="panel panel-default">
          <div className="panel-heading" role="tab"> 
              <div className="container">
                  <div className="panel-title">
                      <h1 className="main-title">Invoice Comments ({invoiceId})</h1>
                  </div>
              </div>
          </div>
          <div className="panel-body" style={{padding: '10px 0'}}>
            <div className="container">
            <div className="form">
              <div className="d-flex justify-content-end mb-2">
                  <Link to={`/invoice/invoiceForm/`} className="btn bg-secondary text-white rounded mr-1"> Back </Link>
                <button
                  className="btn bg-success text-light rounded"
                  style={{ float: "right" }}
                  onClick={(e) => onAddButtonClick(e)}
                >
                  Add New Comment
                </button>
              </div>
              <DataGrid
              dataSource={invoiceComments}
              sortField='createDatetime'
              searchToolTip="Use the search feature to quickly retrieve the system code values"
              emptyDataMessage='No data available to display'
              >
              {columns.map((column, i) => {
                  return (
                  <Column 
                  key={i}
                  field={column.dataField}
                  header={column.text}
                  sortable={column.sort} 
                  body={column.formatter}
                  hidden={column.hidden}
                  />)
                  }) 
              } 
              </DataGrid>
            </div>
            </div>
          </div>
        </div>
      </div> 

      {editComment && (
        <EditInvoiceComment
          onModalClose={(e) => onEditCommentModalClose(e)}
          invoiceComments={selectedCommentInfo}
        />
      )}
      
      {openNewCommentModal && (
        <AddInvoiceComment
          onModalClose={(e) => onNewCommentModalClose(e)}
          invoiceId={invoiceId}
        />
      )}
      {openDeleteCommentModal && (
        <DeleteInvoiceComment
          onModalClose={(e) => onDeleteCommentModalClose(e)}
          invoiceComments={selectedCommentInfo}
        />
      )}
    </>
  );
};

export default ViewComments;
