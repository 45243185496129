import { Row, Col,Modal, Button, Spinner} from 'react-bootstrap'
import {useState, useContext, useEffect} from 'react'
import {FormInput} from'../formElements/FormInput'
import { IssuerContext, UserContext } from "../../UserContext"
import IssuerService from '../../services/IssuerService'
import CodeMaintenanceService from '../../services/CodeMaintenanceService'
import MaskedInput from 'react-text-mask'
import { validateFormFields } from '../../utils/index'
import { Typeahead } from 'react-bootstrap-typeahead'
import { toast } from 'react-toastify'
import CommonService from '../../services/CommonService'

const AddContactModal = (props) => {
    const { onModalClose, handleContactAfterSave} = props;
    const user = useContext(UserContext);
    const issuerId = useContext(IssuerContext);
    const contactType = props.contactType ? props.contactType : "" ;
    const [orgInfo, setOrgInfo] = useState({
        "orgName" : "",
        "orgTypeId" : "",
        "orgDivisionId" : ""
    });

    const [contact, setContact] = useState({
        "contactAddressLine1" : "",
        "contactAddressLine2" : "",
        "contactAddressLine3" : "",
        "contactAddressLine4" : "",
        "contactCityName" : "",
        "contactStateCode" : "",
        "contactZipCode" : "",
        "contactPhoneAreaCode" : "",
        "contactPhoneNumber" : "",
        "contactPhoneExtn" : "",
        "contactEmailId" : ""
    });
    const [isLoading, setIsLoading] = useState(true);
    const [orgTypes, setOrgTypes] = useState([]);
    const [orgDivisions, setOrgDivisions] = useState([])
    const [formErrors, setFormErrors] = useState({});
    useEffect(()=>{
        async function fetchOrgTypes() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGT", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                const orgTypesListNew = [];
                filteredList.forEach((orgType, i)=>{
                    let obj = {};
                    obj = orgType;
                    obj.value = orgType.cdiacCodeValueId;
                    obj.label = orgType.cdiacCodeValueDesc;
                    orgTypesListNew.push(obj);
                })
                
                setOrgTypes(orgTypesListNew);
                setIsLoading(false);
            })
        }

        async function fetchOrgDivisions() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGD" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                setOrgDivisions(result.data.responses[0]);
            })
        }
        fetchOrgTypes();
        fetchOrgDivisions();
    },[])

    const handleOrgChange = (e, key) => {
        const val = (key === "orgName") ? e.target.value : (e.length ? e[0].cdiacCodeValueId : "");
        
        setOrgInfo(prevState => ({
            ...prevState, [key] : val
        }))
    }

    const handleContactChange = e => {
        const {name} = e.target;
        let value = e.target.value;

        if(name === 'contactCityName') {
            value = value.replace(/[^A-Za-z\s]/ig, '');
        }
        if(name === 'contactStateCode') {
            value = value.replace(/[^A-Za-z]/ig, '');
        }
        if(name === 'contactPhoneExtn') {
            value = value.replace(/[^\d]$/, "").substr(0, 10);
        }
        if(name === 'contactZipCode') {
            value = value.replace(/[^\d{5}-]$/, "").substr(0, 10);
        }
        setContact(prevState => ({
            ...prevState, [name] : value
        }))
    }

    const saveContact = (e) => {
        let form = e.target.closest('form#contactForm');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            if (contactType === "organization") {
                saveOrganization();
            } else {
                saveIndividual();
            }
        }
    }

    const saveOrganization = () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "postOrganization",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                organization: {
                   "orgName": orgInfo.orgName,
                   "orgTypeId": orgInfo.orgTypeId,
                   "orgDivisionId": orgInfo.orgDivisionId,
                   "activeInd": 'Y',
                   "createUser": CommonService.getUserName(user),
                   "lastUpdateUser": CommonService.getUserName(user)
                },
                contact : {
                    "contactAddressLine1" : contact.contactAddressLine1,
                    "contactAddressLine2" : contact.contactAddressLine2,
                    "contactAddressLine3" : contact.contactAddressLine3,
                    "contactAddressLine4" : contact.contactAddressLine4,
                    "contactCityName" : contact.contactCityName,
                    "contactStateCode" : contact.contactStateCode,
                    "contactZipCode" : contact.contactZipCode,
                    "contactPhoneAreaCode" : contact.contactPhoneAreaCode,
                    "contactPhoneNumber" : contact.contactPhoneNumber,
                    "contactPhoneExtn" : contact.contactPhoneExtn,
                    "contactEmailId" : contact.contactEmailId,
                    "activeInd": 'Y',
                    "orgAddressInd" : 'Y',
                    "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user)
                }
            }
          };
      
        IssuerService.createNewContact(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors[0]) {
                toast.error(result.data.errors[0].message);
            } else {
                toast.success("Organization Saved Successfully!");
                handleContactAfterSave();
            }
        });
    }

    const saveIndividual = () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "postIssuerContact",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issuerId" : issuerId,
                contact : {
                    "contactFirstName" : contact.contactFirstName,
                    "contactMiddleName" : contact.contactMiddleName,
                    "contactLastName" : contact.contactLastName,
                    "contactAddressLine1" : contact.contactAddressLine1,
                    "contactAddressLine2" : contact.contactAddressLine2,
                    "contactAddressLine3" : contact.contactAddressLine3,
                    "contactAddressLine4" : contact.contactAddressLine4,
                    "contactCityName" : contact.contactCityName,
                    "contactStateCode" : contact.contactStateCode,
                    "contactZipCode" : contact.contactZipCode,
                    "contactPhoneAreaCode" : contact.contactPhoneAreaCode,
                    "contactPhoneNumber" : contact.contactPhoneNumber,
                    "contactPhoneExtn" : contact.contactPhoneExtn,
                    "contactEmailId" : contact.contactEmailId !== "" ? contact.contactEmailId : null,
                    "activeInd": 'Y',
                    "orgAddressInd" : 'N',
                    "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user)
                }
            }
          };
      
        IssuerService.createNewContact(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors[0]) {
                toast.error(result.data.errors[0]);
            } else {
                toast.success("Individual Contact Saved Successfully!");
                handleContactAfterSave();
            }
        });
    }

    return (
        <Modal show={true} onHide={onModalClose} >
            <Modal.Header closeButton>
                <h3>Add {contactType === 'organization' ? 'Organization' : 'Issuer Representative'}</h3>
            </Modal.Header>
            <Modal.Body className="form">
                <form id="contactForm">                 
                    {contactType === 'organization' ?
                    ( isLoading ? <Spinner animation="border" variant="primary" /> :<div className="organizationForm">
                        <Row>
                            <Col md={6} className="control">
                            <FormInput 
                                type="text" 
                                name="orgName" 
                                label="Organization Name"
                                isrequired="true"
                                onChange={(e)=>handleOrgChange(e, 'orgName')}
                                value={orgInfo.orgName}
                                validations={["required"]}
                            />
                            {formErrors['orgName'] && <span className="error">{formErrors['orgName']} </span>}
                            </Col>
                        </Row>
                        <Row>
                            <label><span className="required">*</span>Organization Type</label>
                            <Typeahead 
                                id="orgType"
                                name="orgType"
                                placeholder="Choose Org Type..."
                                labelKey="cdiacCodeValueDesc"
                                onChange={(e)=>handleOrgChange(e, 'orgTypeId')}
                                options={orgTypes}
                                inputProps={{ 'data-validations': 'required', name: 'orgType' }}
                                />
                            {formErrors['orgType'] && <span className="error">{formErrors['orgType']}</span>}
                        </Row>
                        <div>
                            <label><span className="required">*</span>Division</label>
                            <Typeahead 
                            id="orgDivision"
                            name="orgDivision"
                            placeholder="Choose Division..."
                            labelKey="cdiacCodeValueDesc"
                            onChange={(e)=>handleOrgChange(e, 'orgDivisionId')}
                            options={orgDivisions}
                            inputProps={{ 'data-validations': 'required', name: 'orgDivision' }}/>
                           {formErrors['orgDivision'] && <span className="error">{formErrors['orgDivision']}</span>} 
                        </div>
                        
                    </div>)  :
                    <div className="control">
                        <Row>
                        <Col md={4}>
                            <div className="control"> 
                                <FormInput label="First Name" 
                                name="contactFirstName" 
                                type="text" 
                                validations={["required", "maxLength:120"]}
                                isrequired="true"
                                onChange={(e)=>handleContactChange(e)}
                                />
                                {formErrors['contactFirstName'] && <span className="error">{formErrors['contactFirstName']} </span>}
                            </div>
                        </Col>

                        <Col md={4} className="control">
                        <FormInput label="Middle Name" 
                            name="contactMiddleName" 
                            type="text"
                            validations={["maxLength:120"]}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                        <Col md={4} className="control">
                        <FormInput label="Last Name" 
                            name="contactLastName" 
                            type="text"  
                            validations={["required", "maxLength:120"]}
                            isrequired="true"
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactLastName'] && <span className="error">{formErrors['contactLastName']} </span>}
                        </Col>   
                    </Row>
                    </div>
                    }
                
                    <Row>
                        <Col md={6} className="">
                            <FormInput type="text" label="Address Line1" name="contactAddressLine1" validations={["required"]} isrequired="true" onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactAddressLine1'] && <span className="error">{formErrors['contactAddressLine1']} </span>}
                        </Col>
                        <Col md={6} className="">
                            <FormInput type="text" label="Address Line2" name="contactAddressLine2" onChange={(e)=>handleContactChange(e)}/>
                        </Col>
                        <Col md={6} className="">
                            <FormInput type="text" label="Address Line3" name="contactAddressLine3" onChange={(e)=>handleContactChange(e)}/>
                        </Col>
                        <Col md={6} className="">
                            <FormInput type="text" label="Address Line4" name="contactAddressLine4" onChange={(e)=>handleContactChange(e)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormInput type="text" label="City" name="contactCityName" 
                            validations={["required"]} 
                            value={contact.contactCityName}
                            maxLength="50"
                            isrequired="true" onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactCityName'] && <span className="error">{formErrors['contactCityName']} </span>}
                        </Col>
                        <Col md={4}>
                            <FormInput type="text" label="State" 
                            value={contact.contactStateCode}
                            name="contactStateCode" validations={["required","length:2"]} 
                            maxLength="2"
                            isrequired="true" onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactStateCode'] && <span className="error">{formErrors['contactStateCode']} </span>}
                        </Col>
                        <Col md={4}>
                            <FormInput type="text" label="Zip Code"
                            value={contact.contactZipCode}
                            name="contactZipCode" validations={["required","zipcode"]} isrequired="true" onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactZipCode'] && <span className="error">{formErrors['contactZipCode']} </span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <label><span className="required">*</span>Area Code (XXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactPhoneAreaCode"
                                data-validations={["required", "telNo:3"]}
                                onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactPhoneAreaCode'] && <span className="error">{formErrors['contactPhoneAreaCode']} </span>}
                        </Col>
                        <Col md={4} className="control">
                            <label><span className="required">*</span>Phone Number (XXX-XXXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactPhoneNumber"
                                data-validations={["required", "telNo:8"]}
                                onChange={(e)=>handleContactChange(e)}
                            />
                             {formErrors['contactPhoneNumber'] && <span className="error">{formErrors['contactPhoneNumber']} </span>}
                        </Col>
                        <Col md={4}>
                            <FormInput type="text" label="Ext" name="contactPhoneExtn" onChange={(e)=>handleContactChange(e)}/>
                        </Col>
                        <Col md={6}>
                            <FormInput type="email" name="contactEmailId" label="Email" isrequired="true"  onChange={(e)=>handleContactChange(e)} validations={["required", "email"]}/>
                            {formErrors['contactEmailId'] && <span className="error">{formErrors['contactEmailId']} </span>}
                        </Col>
                    </Row>
                        
                    <Modal.Footer>
                        <Button type="button" className="custom-button-secondary" onClick={onModalClose}>Cancel</Button>
                        <Button type="button" className="custom-button" onClick={(e)=>saveContact(e)}>Save</Button>
                </Modal.Footer>
                
                </form>
            </Modal.Body>
            
            
        </Modal>
    )
}

export default AddContactModal
