import { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import InvoiceService from "../../../../services/InvoiceService";
import CommonService from "../../../../services/CommonService";
import EditInvoicePayment from "./editInvoicePayment";
import AddInvoicePayment from "./addInvoicePayment";
import DeleteInvoicePayment from "./deleteInvoicePayment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import BaseService from "../../../../services/BaseService";
import DataGrid from "../../../GenericComponents/DataGrid";
import { Column } from "primereact/column";

const ViewPayments = (props) => {
  let params = useParams();
  const {invoiceId, invoiceStatusFlag} = params;
  const [isLoading, setIsLoading] = useState(true);
  const [invoicePayments, setInvoicePayments] = useState([]);
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState([]);
  const [editPayment, setEditPayment] = useState(false);
  const [openNewPaymentModal, setOpenNewPaymentModal] = useState(false);
  const [openDeletePaymentModal, setOpenDeletePaymentModal] = useState(false);
  const [remainingDueAmount, setRemainingDueAmount] = useState(0);

  useEffect(() => {
    async function fetchInvoice(){
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getInvoicePaymentDueAmt",
        hdrStruct: null,
        queryParam: null,
        reqData: { id: invoiceId },
      };
      BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
          if(result.data.responses && result.data.responses[0]) {
            setRemainingDueAmount(result.data.responses[0]);
          }
        });
    }
    fetchInvoice(invoiceId);
  },[invoiceId]);

  useEffect(()=>{
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getInvoicePayment",
      hdrStruct: null,
      queryParam: null,
      reqData: { invoiceId: invoiceId },
    };
    InvoiceService.getInvoicePayment(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setInvoicePayments(result.data.responses[key]);
          result.data.responses[key].length && calculateRemainingDueAmt(result.data.responses[key]);
        });
        setIsLoading(false);
      });
  }, [invoiceId, editPayment, openDeletePaymentModal, openNewPaymentModal]);

  const calculateRemainingDueAmt = (invoicePayments) => {    
    const totalInvoiceDueAmt = invoicePayments[0].invoiceDueAmt;
    const totalPayment =  invoicePayments.map(i => i.invoicePaymentAmt).reduce((prev, curr) => prev + curr, 0)
    
    let remainingDue = ((totalInvoiceDueAmt*10 - totalPayment*10)/10).toFixed(2);
    remainingDue = String(remainingDue).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setRemainingDueAmount(remainingDue);
  }
  const onRowClick = (e, row) => {
    e.preventDefault();
    setSelectedPaymentInfo(row);
    setEditPayment(row);
  };
  const onDeleteClick = (e, row) => {
    e.preventDefault();
    setSelectedPaymentInfo(row);
    setOpenDeletePaymentModal(true);
  };
  const onAddButtonClick = (e) => {
    e.preventDefault();

    setOpenNewPaymentModal(true);
  };
  const viewActionFormatter = (row) => {
    return (
      <div
        style={{
          textAlign: "center",
          lineHeight: "normal",
        }}
      >
        <FontAwesomeIcon
          icon={faPencilAlt}
          className="custom-icon mr-2"
          onClick={(e) => onRowClick(e, row)}
        />
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="custom-icon"
          onClick={(e) => onDeleteClick(e, row)}
        />
      </div>
    );
  };
  
  const columns = [
    {
      dataField: "invoicePaymentId",
      text: "Payment #",
      sort: true,
    },
    {
      dataField: "invoicePaymentAmt",
      text: "Payment Amount",
      sort: true,
      formatter: (row) => CommonService.currencyFormatter(row['invoicePaymentAmt']),
    },
    {
      dataField: "invoicePaymentDate",
      text: "Payment Date",
      sort: true,
      formatter: (row) => CommonService.dateFormatter(row['invoicePaymentDate']),
    },
    {
      dataField: "checkNbr",
      text: "Check Nbr",
      sort: true,
    },
    {
      dataField: "createUser",
      text: "Created By",
      sort: true,
    },
    {
      dataField: "createDatetime",
      text: "Created Date",
      sort: true,
      formatter: (row) => CommonService.dateFormatter(row['createDatetime']),
    },
    {
      dataField: "lastUpdateUser",
      text: "Last Updated By",
      sort: true,
    },
    {
      dataField: "lastUpdateDatetime",
      text: "Last Updated",
      sort: true,
      formatter: (row) => CommonService.dateFormatter(row['lastUpdateDatetime']),
    },

    {
      dataField: "",
      text: "Manage",
      formatter: viewActionFormatter,
    }
  ];

  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" />}
      <div>
        <div className="panel panel-default">
          <div className="panel-heading" role="tab"> 
              <div className="container">
                  <div className="panel-title">
                      <h1 className="main-title">Invoice Payments ({invoiceId})</h1>
                  </div>
              </div>
          </div>
          <div className="panel-body" style={{padding: '10px 0'}}>
              <div className="form">
                <p className="">Remaining Amount Due: {'$'+ remainingDueAmount}</p>
              <div className="d-flex justify-content-end mb-1">
                <Link to={`/invoice/invoiceForm/`} className="btn bg-secondary text-white rounded mr-1"> Back </Link>
                <button
                  className="btn bg-success text-light rounded"
                  onClick={(e) => onAddButtonClick(e)}
                >
                  Add Payment
                </button>
              </div>

              <DataGrid
                dataSource={invoicePayments}
                sortField='createDatetime'
                emptyDataMessage='No data available to display'
                showPaginator={false}
                showQuickSearch={false}
                >
                {columns.map((column, i) => {
                    return (
                    <Column 
                    key={i}
                    field={column.dataField}
                    header={column.text}
                    sortable={column.sort} 
                    body={column.formatter}
                    hidden={column.hidden}
                    />)
                    }) 
                } 
                </DataGrid>
              </div>
          </div>
        </div>
      </div>  
      {editPayment && (
        <EditInvoicePayment
          onModalClose={() => setEditPayment(false)}
          invoicePayments={selectedPaymentInfo}
          remainingDueAmount={remainingDueAmount}
        />
      )}
      
      {openNewPaymentModal && (
        <AddInvoicePayment
          onModalClose={() => setOpenNewPaymentModal(false)}
          invoiceId={invoiceId}
          invoiceStatusFlag={invoiceStatusFlag}
          remainingDueAmount={remainingDueAmount}
        />
      )}
      {openDeletePaymentModal && (
        <DeleteInvoicePayment
          onModalClose={() => setOpenDeletePaymentModal(false)}
          invoicePayments={selectedPaymentInfo}
        />
      )}
    </>
  );
};

export default ViewPayments;
