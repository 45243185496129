import {useContext, useState} from 'react'
import Card from '../ui/Card'
import PrintPdfComponent from './printPdfComponent'
import CommonService from '../../services/CommonService'
import {UserContext} from '../../UserContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify'
import { renderToString } from 'react-dom/server'
import swal from 'sweetalert'
import EditReasonModal from '../reports/editReasonModal'
import ReportsService from '../../services/ReportsService'

const DrawOnReserveReview = (props) => {
    const {issuanceDetail, drawDetails, contact}= props
    const loginUser = useContext(UserContext)
    const [showEditReasonModal, setShowEditReasonModal] = useState(false)

    const finalDraw = async() => {
        const html = renderToString(<PrintPdfComponent issuanceDetail = {issuanceDetail} drawDetails={drawDetails} contact={contact}/>)

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putDraw",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  
                "id": props.drawId, 
                "env" : "I",
                "draw":{
                    "statusFlag" : drawDetails.statusFlag,
                    "lastUpdateUser": CommonService.getUserName(loginUser),
                    "emailId" : loginUser               
                },
                "html": html,
                "cdiacNbr": issuanceDetail.cdiacNumber,
                "reportType" : "DRW"
            }
        };
    
       await FinalSaleReportService.updateDraw(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data && result.data.responses[0]) {
                    var base64EncodedPDF = result.data.responses[0].pdf;
                    var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
                    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                    var url = window.URL.createObjectURL(blob);
                    window.open(url);
                }
                toast("Draw updated Successfully!!");
            }

        });
    }

    const validateRequiredFields = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getValidationMessagesDraw",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "drawId": props.drawId
            }
        };
        FinalSaleReportService.getValidationMessages(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0].length > 0) {
                  let alertMessage = "";
                  result.data.responses[0].forEach(item => {
                    alertMessage +=  "- " + item.message + "\n";
                  });
                  alert(alertMessage);
                } else {
                    if(drawDetails.statusFlag === "R") {
                        swal({
                            text: "Do you want to re-edit this record?",
                            icon: "warning",
                            buttons: [true, 'Yes'],
                            closeOnClickOutside: false
                        }).then((confirm) => {
                            if (confirm) {
                                setShowEditReasonModal(true)
                            } 
                        });
                    } else {
                        finalDraw(); 
                    }      
                }
            }

        });
    }

    const validateDraw = () => {
        props.toggleWidget('review');
        validateRequiredFields();         
    }

    const saveEditReason = (editReason) => {
        let obj ={
             "msgId": null,
             "sysId": null,
             "opr": "postEditReason",
             "hdrStruct": null,
             "queryParam": null,
             "reqData": {
                 "editReason" : {
                     "editReasonCodeId" : editReason.editReasonCodeId,
                     "comment" : editReason.comment,
                     "drawId" : props.drawId,
                     "createUser" : CommonService.getUserName(loginUser)
                 }
             }
         } 
     
         ReportsService.saveEditReason(obj)
         .then((res) => res)
         .then((result) => {
             if(result.data.errors.length > 0) {
 
             } else {
                 toast.success('Edit Reason saved successfully!')
                 setShowEditReasonModal(false)
                 finalDraw()
             }
         });
     }

    return (
        <Card>
            <div className="form" style={{paddingBottom: 0}}>
                <div className="row">
                    <div className="col-sm-8 col-12">
                        <h3>Draw on Reserve/Default/Replenishment</h3>
                    </div>
                    {/* <div className="col-sm-4 col-12">
                        <BiPrinter className="printBtn" onClick={handlePrint} title="Print submitted pdf"/>
                    </div> */}
                </div>
                <PrintPdfComponent issuanceDetail={issuanceDetail} drawDetails={drawDetails} contact={contact}/>
                <div className="form">
                    <div className="btn-div">
                    <button className="fright custom-button" type="button" onClick={()=>validateDraw()}>Validate & Submit</button>
                    </div>

                </div>
            </div>
            {showEditReasonModal && <EditReasonModal  onModalClose={()=>setShowEditReasonModal(false)} handleSave={saveEditReason} cdiacReasonCode="DRWER"/>}
        </Card>
    )
}

export default DrawOnReserveReview
