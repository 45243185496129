import { Form } from 'react-bootstrap';
import {useState, useEffect} from 'react'
// import { useLocation, useNavigate } from 'react-router-dom'
import Card  from '../../ui/Card'
import CountyDetails from './countiesDetails';
import CountyMaintenanceService from "../../../services/CountyMaintenanceService";
import Spinner from 'react-bootstrap/Spinner'

const CountyMaintenance = () => {

    const [selectedCounty, setSelectedCounty] = useState(null);
    const [region, setRegion] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [counties, setCounties] = useState([]);

    const handleChange = (e) => {
        let county = e.target.value;
        setSelectedCounty(county);
        setRegion(e.target[e.target.selectedIndex].text);
    }

    useEffect(() => {
        var obj = {
          msgId: "null",
          sysId: null,
          opr: "GetLookups",
          hdrStruct: null,
          queryParam: null,
          reqData: { cdiacCode: "REGN", active: "Y" },
        };
    
        CountyMaintenanceService.getAllCounties(obj)
          .then((res) => res)
          .then((result) => {
            setCounties(result.data.responses);
            setIsLoading(false);
          });
      }, []);

    return (

        <Card>
            <div className="panel panel-default">
              <div className="panel-heading">
                    <div className="container">
                        <div id="packet_id" className="panel-title">
                            <h1 className="menu-title">County Maintenance</h1>
                        </div>
                    </div>
                </div>
                <div className="panel-body">
                    <div className="container">
                {isLoading && <Spinner animation="border" variant="primary" />}

                <div className="form">
                    <label>Region: </label>
                    {Object.keys(counties).map((key) => {
                    return (
                      <div key={key}>
                        <Form.Select
                          as="select"
                          onChange={(e) => handleChange(e)}
                          value={selectedCounty  || ''}
                        >
                          <option>Select</option>
                          {counties[key].map((dataItem) => {
                            return (
                              <option
                                key={dataItem.cdiacCodeValueDesc}
                                value={dataItem.cdiacCodeValueId}
                              >
                                {dataItem.cdiacCodeValueDesc}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    );
                  })}
                </div>
                </div>
                </div>

                {selectedCounty && <CountyDetails selectedCounty={selectedCounty} region={region}/>}
            
            </div>
            
        </Card>
    )
}

export default CountyMaintenance
