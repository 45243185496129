import { useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../ui/Card'
import ContextModal from './contextModal'
import CommonService from '../../services/CommonService'
import { UserContext } from "../../UserContext";
import FinalSaleReportService from "../../services/FinalSaleReportService";
import { toast } from 'react-toastify';
import { useNavigate, useParams, useLocation } from 'react-router-dom'

const MKRLocalObligors = (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [openContextModal, setOpenContextModal] = useState(false);
    const cdiacNo = params.cdiac;
    const user = useContext(UserContext);
    let markRoosLocalOblig = location.state.reportData;
    let finalSaleId = location.state.reportData.finalSaleId;

    if(!location.state.reportData){
        markRoosLocalOblig = location.state.markRoosLocalOblig
        finalSaleId = location.state.markRoosLocalOblig.finalSaleId;
    }

    const [stateValues, setStateValues] = useState({
        fiscalYrEndDate : markRoosLocalOblig.fiscalYrEndDate
    })
    const handleChange = (e) => {
        const {name, value} = e.target;
        const date = `6/30/ + ${value}`;

        setStateValues(prevState => ({
            ...stateValues, [name]: CommonService.dateFormatter(date)
        })
        )


    }

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state:{dashboardSelection: dashboardSelectionObj} });
    }

    const handleSubmit = () => {

        if (markRoosLocalOblig.mkrLobId === null || 
            CommonService.getFullYear(stateValues.fiscalYrEndDate) !== CommonService.getFullYear(markRoosLocalOblig.fiscalYrEndDate) ) {
            var obj = {
                "msgId": null,
                "sysId": null,
                "opr": "createMKRLOB",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "cdiacNbr": cdiacNo, "marksRoosLocalObligor": {
                        "fiscalYrEndDate": stateValues.fiscalYrEndDate,
                        "finalSaleId": markRoosLocalOblig.finalSaleId,
                        "createUser": CommonService.getUserName(user),
                        "lastUpdateUser": CommonService.getUserName(user),
                        "emailId": user
                    }

                }
            };

            FinalSaleReportService.createMKRLOB(obj)
                .then((res) => res)
                .then(async (result) => {
                    if (result.data.errors && result.data.errors.length > 0) {
                        toast.error(result.data.errors[0].message);
                        return;
                    }
                    else {
                        toast.success("The Mark Roos Local Obligor was created  successfully.");
                        markRoosLocalOblig.mkrLobId = result.data.responses[0].marksRoosLocalObligorId;
                        setOpenContextModal(true);
                    }
                });


        }
        else {
            var anotherobj ={
                "msgId": null,
                "sysId": null,
                "opr": "validateMKRLOB",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {"cdiacNbr": cdiacNo, "marksRoosLocalObligor": {
                    "fiscalYrEndDate" : stateValues.fiscalYrEndDate
                }
    
                }
              } ;
          
            FinalSaleReportService.validateMKRLOB(anotherobj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    navigate(`/mkr-local-obligors-yfs/${cdiacNo}`, {state:{finalSaleId, markRoosLocalOblig} });
                  }
            });
        }


    }


    return (
        <Card>
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading" role="tab">
                        <div className="container">
                            <div className="panel-title">
                                <h1 className="main-title">Marks Roos Yearly Fiscal Status Report For Local Obligors</h1>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body" style={{ padding: '10px 0' }}>
                        <div className="container">
                            <div>
                                <Row>
                                    <Col>
                                        <div>
                                            <label> CDIAC #: </label>
                                            <input type="text" defaultValue={cdiacNo} disabled />
                                        </div>
                                        <div>
                                        <label><span className="required">* </span>Balances Reported as of June 30, <input type="number" onChange={(e)=>handleChange(e)} maxLength="4" name="fiscalYrEndDate" defaultValue={CommonService.getFullYear(stateValues.fiscalYrEndDate)}/></label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ overflow: 'auto' }}>
                                <button type="button" onClick={(e) => handleSubmit(e)} style={{ float: 'right' }} >Submit</button>

                                <button
                                    type="button"
                                    className="button"
                                    onClick={() => backToDashboard()}>Back to Dashboard
                                </button>


                            </div>
                        </div>
                    </div>



                </div>
            </div>

            {openContextModal && <ContextModal onModalClose={() => setOpenContextModal(false)} cdiacNo={cdiacNo} finalSaleId={markRoosLocalOblig.finalSaleId} markRoosLocalOblig={markRoosLocalOblig} />
            }

        </Card>
    )
}

export default MKRLocalObligors
