import { useEffect, useState, useContext } from 'react'
import {Dropdown} from 'react-bootstrap'
import swal from 'sweetalert'
import ReportsService from '../../services/ReportsService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UserContext'
import {useNavigate} from 'react-router-dom'
import { AlertType } from '../../utils'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'
import { configManager } from '../../configManager'
import TooltipComponent from '../../common/Tooltip'

const MelloRoosReports = (props) => {
    const loginUserId = useContext(UserContext)
    const {issuerId, reportStatus, searchCriteria} = props;
    const [melloRoos, setMelloRoos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getMelloRoosWorkLoad(issuerId);
    // eslint-disable-next-line
    }, [issuerId, searchCriteria]);

    const getMelloRoosWorkLoad = (issuerId) => {
        CommonService.showHideSpinner(true);
        let obj;
        if(reportStatus === "view-previous") {
            obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getOldMelloRoos",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issuerId,
                    "env" : 'I',
                    "searchCriteria": searchCriteria
                }
            }
        } else {
            obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getPendingMelloRoos",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : issuerId,
                    "env" : 'I'
                }
            }
        } 
        ReportsService.getReports(obj)
        .then((res) => res)
        .then((result) => {
            CommonService.showHideSpinner(false);
            if (result.data.errors && result.data.errors.length > 0){
                CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error)
            }
            else{
                setMelloRoos(result.data.responses[0]);
            }

        });
    }
    const actions = {
        'D' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'S' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'R' : [
            {value:'E', label: 'Edit'},
            {value:'SH', label: 'View History'},
            {value:'DW', label: 'View in DebtWatch'}
        ],
        'PD' : [
            {value:'PD', label: 'Report MLR'},
            {value:'SH', label: 'View History'}
        ],
        'PEND' : [
            {value:'PEND', label: 'Report MLR'},
            {value:'SH', label: 'View History'}
        ],
        'E' : [
            {value:'E', label: 'Edit'},
            {value:'SH', label: 'View History'}
        ]
    }
    const viewFormatter = (row) => { 
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
                <div className="dropdown">
                    <Dropdown>
                    <Dropdown.Toggle 
                    variant="secondary btn-sm" 
                    id="dropdown-basic">
                        Action
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                        {actions[row['issueStatusFlag']].map((action, i)=> {
                             if(action.value === "DW") {
                                const fiscalYear = new Date(row['fiscalYrEndDate']).getFullYear();
                                const canViewInDW = (fiscalYear <= row['mlrPublicationYear']);
                                const tooltipText = `Mello Roos for reporting years after ${row['mlrPublicationYear']} are not yet published in DebtWatch.`
                                return <TooltipComponent title={tooltipText} placement="left" className="ml-0">
                                    <Dropdown.Item key={i} href="#" onClick={(e)=>handleActionClick(e, action.value, row )} disabled={!canViewInDW}>{action.label}</Dropdown.Item>
                                    </TooltipComponent>
                            }
                            return <Dropdown.Item key={i} href="#" onClick={(e)=>handleActionClick(e, action.value, row )}>{action.label}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div> 
        ); 
    }

    const CancelMelloRoos = (melloRoosId) => {
        CommonService.showHideSpinner(true);
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "CancelMelloRoos",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : melloRoosId,
                "user" : loginUserId,
                "env" : 'I'
            }
          } ;
      
        ReportsService.cancelReport(obj)
        .then((res) => res)
        .then(async (result) => {
            CommonService.showHideSpinner(false);
            if (result.data.errors && result.data.errors.length > 0){
                CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error)
            }
            else{
                CommonService.displayServerMessage("The transaction has been cancelled and an acknowledgement email has been sent.", AlertType.Success);
                getMelloRoosWorkLoad(issuerId);
            }
            
        });
    }
    
    const handleActionClick = (e, action, row) => {
        const melloRoosId = row.melloRoosId;
        if( action === 'C' ) {
            swal("Are you sure you want to permanently cancel this transaction?", {
                buttons: ["No", "Yes"],
            }).then((value) => {
               if(value){
                CancelMelloRoos(melloRoosId);
               }
            });
        } else if( action === 'E') {
            navigate('/mello-roos-yfs/'+ row.cdiacNbr,{ state:{reportData: row}});
        } else if( action === 'PEND') {
            navigate('/mello-roos/'+ row.cdiacNbr,{state:{reportData: row} });
        } else if( action === 'PD') {
            navigate('/mello-roos/'+ row.cdiacNbr,{state:{reportData: row}});
        } else if( action === 'R') {
            alert("Clicked Review");
        } else if( action === 'SH') {
            navigate(`/admin/status-history-upload-documents/${row.cdiacNbr}`);
        } else if( action === 'DW') {
            window.open( `${configManager.debtWatchUrl}/issue-level-detail/${row.cdiacNbr}`);
        }
    }   

    const columns = [{
        dataField: 'cdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, 
      {
        dataField: 'fiscalYrEndDate',
        text: 'Fiscal Yr End Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['fiscalYrEndDate'])
      },
      {
        dataField: 'issueStatus',
        text: 'Status',
        sort: true
      },{
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      },{
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate'])
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      },{
        dataField: 'retiredFlag',
        text: 'Not Outstanding',
        sort: true,
        hidden: reportStatus === "view-previous" ? false : true
      },{ 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter,
    }
    
    ]
    return (
        <div>
            <DataGrid
            title="Mello Roos Reports"
            dataSource={melloRoos}
            sortField='cdiacNbr'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
        </div>
    )
}

export default MelloRoosReports
