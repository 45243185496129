import React, { useState, useContext } from 'react'
import Card from '../ui/Card'
import { Form, Row, Col } from 'react-bootstrap'
import { validateFormFields } from '../../utils/index'
import { FaCalendarAlt} from 'react-icons/fa'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { UserContext } from '../../UserContext'
import CommonService from '../../services/CommonService'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'

const Credit = (props) => {
    const {finalSaleDetails, updateFinalSaleDetails} = props;
    const loginUser = useContext(UserContext);
    const [fieldsDisable, setFieldsDisable] = useState(finalSaleDetails.creditNotRatedInd === "Y" ? true : false);
    const [finalSaleInfo, setFinalSaleInfo] = useState(finalSaleDetails ? 
        {...finalSaleDetails, 'enhancementExpDate' : finalSaleDetails.enhancementExpDate ? new Date(finalSaleDetails.enhancementExpDate) : null } :
         {});

    const [formErrors, setFormErrors] = useState({})
    
    const handleChange = e => {
        const {name, value } = e.target;
        setFinalSaleInfo(prevState=>({
            ...finalSaleInfo, [name]: value
        }))
        if(name==="creditNotRatedInd") {
            (value === "Y") ? setFieldsDisable(true) : setFieldsDisable(false);
        }
    }

    const handleEnhancementExpDate = (e, key) => {
        setFinalSaleInfo(prevState=>({
            ...finalSaleInfo, 
            [key]: e
        })
        )
    }

    const saveFinalSaleInfo = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleInfo.finalSaleId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };
    
        FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Credit details updated successfully!");
                updateFinalSaleDetails(result.data.responses[0]);
                props.sectionValidated('credit', true);
                props.openWidget('financingParticipants');
            }
        });
        
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#creditForm');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('credit', false);
        } else {
            setFormErrors({});
            saveFinalSaleInfo();
        }
    }

    return (
        <Card>
        <Form className="form" id="creditForm">
            <div className="mb-10">
                <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Credit Enhancement/Guaranty:</label>
                <br />
                <Form.Check
                        inline
                        label="Insurance"
                        name="guaranteeFlag"
                        value="I"
                        id="insurance"
                        checked={finalSaleInfo.guaranteeFlag === 'I'}
                        type="radio"
                        onChange={(e)=>handleChange(e)}
                        data-validations="required"
                    />
                <Form.Check
                        inline
                        label="Letter of Credit"
                        name="guaranteeFlag"
                        id="letterOfCredit"
                        value="L"
                        checked={finalSaleInfo.guaranteeFlag === 'L'}
                        type="radio"
                        onChange={(e)=>handleChange(e)}
                        data-validations="required"
                    />
                <Form.Check
                        inline
                        label="Other Enhancement Type"
                        name="guaranteeFlag"
                        id="otherEnhancementType"
                        value="O"
                        checked={finalSaleInfo.guaranteeFlag === 'O'}
                        type="radio"
                        onChange={(e)=>handleChange(e)}
                        data-validations="required"
                    />
                <Form.Check
                        inline
                        label="State Intercept Program"
                        name="guaranteeFlag"
                        id="stateIntercept"
                        value="T"
                        checked={finalSaleInfo.guaranteeFlag === 'T'}
                        type="radio"
                        onChange={(e)=>handleChange(e)}
                        data-validations="required"
                    />
                <Form.Check
                        inline
                        label="None"
                        name="guaranteeFlag"
                        value="N"
                        id="none"
                        type="radio"
                        checked={finalSaleInfo.guaranteeFlag === 'N'}
                        onChange={(e)=>handleChange(e)}
                        data-validations="required"
                    />
                {formErrors['guaranteeFlag'] && <p className="error">{formErrors['guaranteeFlag']}</p>}
            </div>
            <div className="control">
                <Row>
                    <Col md={6}>
                    <label>Enhancement Expiration Date:</label>
                    <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.enhancementExpDate ? finalSaleInfo.enhancementExpDate : null}
                        dateFormat="M/d/yyyy"
                        name="enhancementExpDate"
                        maxDate= {new Date(9999, 12, 31)}
                        onChange={(e) => handleEnhancementExpDate(e, 'enhancementExpDate')} 
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                    </span>
                    {formErrors.enhancementExpDate && <p className="error">{formErrors.enhancementExpDate}</p>}
                    <input type="hidden" value={finalSaleInfo.enhancementExpDate} name="enhancementExpDate" />


                    </Col>
                </Row>
            </div>
            <div>
                <Row>
                    <Col>
                    <label><span className="required">*</span>Credit Rating: </label>
                    <Form.Check inline type="radio" name="creditNotRatedInd" id="not-rated" label="Not Rated" value="Y" checked={finalSaleInfo.creditNotRatedInd === "Y"} onChange={(e)=>handleChange(e)} data-validations="required"/>
                    <Form.Check inline type="radio" name="creditNotRatedInd" id="rated" label="Rated" value="N" checked={finalSaleInfo.creditNotRatedInd === "N"} onChange={(e)=>handleChange(e)} data-validations="required"/>
                    </Col>
                    {formErrors['creditNotRatedInd'] && <p className="error">{formErrors['creditNotRatedInd']}</p>}
                </Row>
                <Row>
                    <Col>
                        <label>Standard & Poor</label>
                        <Form.Control type="text" name="stdsPoorRate" value={finalSaleInfo.stdsPoorRate} 
                        onChange={(e)=>handleChange(e)} 
                        maxLength="15"
                        disabled={fieldsDisable}/>
                    </Col>
                    <Col>
                        <label>Fitch</label>
                        <Form.Control type="text" name="fitchRate" value={finalSaleInfo.fitchRate} 
                        onChange={(e)=>handleChange(e)} 
                        maxLength="15"
                        disabled={fieldsDisable}/>
                    </Col>
                    <Col>
                        <label>Moody's</label>
                        <Form.Control type="text" name="moodyRate" value={finalSaleInfo.moodyRate} 
                        onChange={(e)=>handleChange(e)} 
                        maxLength="15"
                        disabled={fieldsDisable}/>
                    </Col>
                    <Col>
                        <label>Other</label>
                        <Form.Control type="text" name="otherRate" value={finalSaleInfo.otherRate} 
                        onChange={(e)=>handleChange(e)} 
                        maxLength="15"
                        disabled={fieldsDisable}/>
                    </Col>
                </Row>
            </div>

            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="custom-button fright" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            
        </Form> 
        </Card>
    )
}

  
export default Credit;