import React from 'react';
import {Modal, Spinner} from 'react-bootstrap';
export default function Loader() {
    return (
        <Modal show={true} className="modal bd-example-modal-lg" data-backdrop="static">
            <div className="modal-dialog modal-sm">
                    <Spinner animation="border"/>
            </div>
        </Modal>
    )
}