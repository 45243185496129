import { Row, Col, Form, FormSelect } from 'react-bootstrap'
import {useState, useEffect} from 'react'
import Card  from '../../ui/Card'
import IssuerRequestsContainer from './issuerRequestsContainer'
import FilerRequestsContainer from './filerRequestsContainer'
import { useForm } from "react-hook-form"
const IssuerFilerRequests = () => {
    const { handleSubmit} = useForm();
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchReq, setSearchReq] = useState([]);
    const [searchParams, setSearchParams] = useState({
        'issuerFiler': 'Issuer',
        'status': 'S'
    });

    useEffect(() => {
        
    }, []);

    

    const handleChange = (e) => {
        setShowSearchResult(false);
        const {name, value} = e.target;
        setSearchParams(prevState=>({
            ...searchParams, [name] : value
        }))
    }
    
    const onSubmit = (data) => {
        var obj = {
            "IssuerFiler": searchParams.issuerFiler,
            "Status": searchParams.status,
        }

        setSearchReq(obj);
        setShowSearchResult(true);
    }

    return (

        <Card>
        <div className="panel panel-default">
            <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title">
                        <h1 className="menu-title">Issuer/Filer Requests Dashboard</h1>
                    </div>
                </div>
            </div>
            <div className="panel-body">
                <div className="container form">
                    <form>
                    {/* {isLoading && <Spinner animation="border" variant="primary" />} */}
                    <div>
                    <Form.Check
                            inline
                            label="Issuer Requests"
                            value="Issuer"
                            name="issuerFiler"
                            id="issuerFiler_0"
                            type="radio"
                            checked={searchParams.issuerFiler === 'Issuer'}
                            onChange={(e)=>handleChange(e)}
                        />
                    <Form.Check
                            inline
                            label="Filer Requests"
                            value="Filer"
                            name="issuerFiler"
                            id="issuerFiler_1"
                            type="radio"
                            checked={searchParams.issuerFiler === 'Filer'}
                            onChange={(e)=>handleChange(e)}
                        />
                    {/* {formErrors['issuerFiler'] && <p className="error">{formErrors['issuerFiler']}</p>} */}
                    </div>
                    <Row>
                        <Col md={6}>
                            <label>Request Status</label>
                            <FormSelect as="select" name="status" defaultValue={searchParams.status} onChange={(e)=>handleChange(e)}>
                                <option value="S">Submitted</option>
                                <option value="A">Approved</option>
                                <option value="D">Denied</option>
                            </FormSelect>
                        </Col>
                    </Row>

                    
                    <div style={{overflow: 'auto'}}>
                        <button style={{float: 'right'}} className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Go</button>
                    </div>
                    </form>
                </div>
            </div>
        </div> 
        {showSearchResult && (
            (searchParams.issuerFiler === 'Issuer') ?
            <IssuerRequestsContainer searchReq={searchReq} /> :
            <FilerRequestsContainer searchReq={searchReq}/>
        )}  
        </Card>
    )
}

export default IssuerFilerRequests
