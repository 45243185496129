import {Modal, Col, Row, Form, Spinner} from 'react-bootstrap'
import React, { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify'
import { UserContext } from '../../../UserContext'
import { validateFormFields } from '../../../utils'
import MaskedInput from 'react-text-mask'
import {FormInput} from '../../../components/formElements/FormInput'
import CodeMaintenanceService from '../../../services/CodeMaintenanceService'
import { Typeahead } from 'react-bootstrap-typeahead'
import IssuerMaintenanceService from '../../../services/IssuerMaintenanceService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import CommonService from '../../../services/CommonService'
import AddContact from './addContact'
import swal from 'sweetalert'
import {AiFillQuestionCircle} from 'react-icons/ai'
import IssuerFilerService from '../../../services/IssuerFilerService'
import IssuerService from '../../../services/IssuerService'
import TooltipComponent from '../../../common/Tooltip'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const EditContact = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, contactInfo} = props;
    const [formErrors, setFormErrors] = useState({});
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [contact, setContact] = useState({...contactInfo});
    const handleContactChange = e => {
        const {name} = e.target;
        let value = e.target.value;
        if(name === 'contactCityName') {
            value = value.replace(/[^A-Za-z\s]/ig, '');
        }
        if(name === 'contactStateCode') {
            value = value.replace(/[^A-Za-z]/ig, '');
        }
        if(name === 'contactPhoneExtn') {
            value = value.replace(/[^\d]$/, "").substr(0, 10);
        }
        if(name === 'contactZipCode') {
            value = value.replace(/[^\d{5}-]$/, "").substr(0, 10);
        }
        if(name === 'activeInd' || name === 'adtrBatchInd') {
            value = e.target.checked ? 'Y' : 'N';
        }

        setContact(prevState => ({
            ...prevState, [name] : value
        }))
    }
    const handleCommentValue = (e, key) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setContact(prevState => ({
            ...prevState, [key] : e.target.value
        }))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target.closest('form#edit-contact');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "putContact",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "contact" : {
                        "contactId": contact.contactId,
                        "activeInd": contact.activeInd,
                        "adtrBatchInd": contact.adtrBatchInd ? contact.adtrBatchInd : "N",
                        "contactAddressLine1": contact.contactAddressLine1,
                        "contactAddressLine2": contact.contactAddressLine2,
                        "contactAddressLine3": contact.contactAddressLine3,
                        "contactAddressLine4": contact.contactAddressLine4,
                        "contactCityName": contact.contactCityName,
                        "contactStateCode": contact.contactStateCode,
                        "contactZipCode": contact.contactZipCode,
                        "contactFaxAreaCode": contact.contactFaxAreaCode,
                        "contactFaxNumber": contact.contactFaxNumber,
                        "contactEmailId": contact.contactEmailId !== "" ? contact.contactEmailId : null,
                        "contactFirstName": contact.contactFirstName,
                        "contactLastName": contact.contactLastName,
                        "contactMiddleName": contact.contactMiddleName,
                        "contactPhoneAreaCode": contact.contactPhoneAreaCode,
                        "contactPhoneExtn": contact.contactPhoneExtn,
                        "contactPhoneNumber": contact.contactPhoneNumber,
                        "contactComment": contact.contactComment,
                        "orgAddressInd": contact.orgAddressInd,
                        "isUserInAzureB2C" : contact.isUserInAzureB2C,
                        "lastUpdateUser": CommonService.getUserName(user)
                    }
                },
            };
        
            IssuerMaintenanceService.updateContact(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        if(result.data.errors[0].code === '200'){
                            swal(result.data.errors[0].message, {
                                buttons: ["No", "Yes"],
                            }).then((value) => {
                               if(value){
                                   createB2CUserProfile()
                               } else{
                                toast.success("Contact updated successfully!")
                                onModalClose();
                                }
                            });
                        } else {
                            toast.error(result.data.errors[0].message)
                        }
                    }
                    else{
                        toast.success("Contact updated successfully!");
                        onModalClose();
                    }
                    
                });
        }
    }

    const createB2CUserProfile = () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "createB2CUserProfile",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "contact" : contact,
            },
        };

        IssuerMaintenanceService.createB2CUserProfile(obj)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("Contact updated successfully!");
                onModalClose();
            }
        });
    }
    
    return (
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Edit Contact</h3></Modal.Header>
            <Modal.Body>
            <form id="edit-contact">
                <div className="contactForm">
                    <Row>
                        <Col md={6} className="control">
                        <FormInput type="text" label="Contact First Name" value={contact.contactFirstName} name="contactFirstName" isrequired="true"  validations={["required"]} onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactFirstName'] && <span className="error">{formErrors['contactFirstName']}</span>}

                        </Col>
                        <Col md={6} className="control">
                            <label>Contact Middle Name:</label>
                            <input type="text" name="contactMiddleName"value={contact.contactMiddleName} onChange={(e)=>handleContactChange(e)}/>
                        </Col>
                        <Col md={6} className="control">
                        <FormInput type="text" label="Contact Last Name" value={contact.contactLastName} name="contactLastName" isrequired="true"  validations={["required"]} onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactLastName'] && <span className="error">{formErrors['contactLastName']}</span>}
                        </Col>
                        <div>
                            <Form.Check inline type="checkbox" id="orgAddressInd" name="orgAddressInd" label="Primary Contact?" checked={contact.orgAddressInd === 'Y'} onChange={(e)=>handleContactChange(e)} />
                        </div>
                    </Row>
                    <Row>                       
                        <Col md={6} className="control">
                            <FormInput type="text" label="Address Line1" name="contactAddressLine1" isrequired="true"  
                            validations={["required"]} value={contact.contactAddressLine1}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactAddressLine1'] && <span className="error">{formErrors['contactAddressLine1']}</span>}
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line2:</label>
                            <input type="text" name="contactAddressLine2" value={contact.contactAddressLine2}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line3:</label>
                            <input type="text" name="contactAddressLine3"  value={contact.contactAddressLine3} 
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line4:</label>
                            <input type="text" name="contactAddressLine4"   value={contact.contactAddressLine4}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <FormInput type="text" label="City" name="contactCityName" isrequired="true" 
                            validations={["required"]} 
                            maxLength="50"
                            value={contact.contactCityName}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactCityName'] && <span className="error">{formErrors['contactCityName']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="text" label="State" name="contactStateCode" isrequired="true"  
                            validations={["required", "minLength:2"]} maxLength="2" 
                            value={contact.contactStateCode}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactStateCode'] && <span className="error">{formErrors['contactStateCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="text" label="Zip Code" name="contactZipCode" isrequired="true"  
                            validations={["required","zipCode"]} 
                            value={contact.contactZipCode}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactZipCode'] && <span className="error">{formErrors['contactZipCode']}</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <label><span className="required">*</span>Area Code (XXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactPhoneAreaCode"
                                data-validations={["required", "telNo:3"]}
                                value={contact.contactPhoneAreaCode}
                                onChange={(e)=>handleContactChange(e)}
                                onBlur={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactPhoneAreaCode'] && <span className="error">{formErrors['contactPhoneAreaCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label><span className="required">*</span>Phone Number (XXX-XXXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactPhoneNumber"
                                value={contact.contactPhoneNumber}
                                data-validations={["required", "telNo:8"]}
                                onChange={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactPhoneNumber'] && <span className="error">{formErrors['contactPhoneNumber']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label>Ext</label>
                            <input type="text" name="contactPhoneExtn" value={contact.contactPhoneExtn}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <label>Fax Area Code (XXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactFaxAreaCode"
                                value={contact.contactFaxAreaCode} 
                                data-validations={["telNo:3"]}
                                onChange={(e)=>handleContactChange(e)}
                                onBlur={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactFaxAreaCode'] && <span className="error">{formErrors['contactFaxAreaCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label>Fax Number (XXX-XXXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactFaxNumber"
                                data-validations={["telNo:8"]}
                                value={contact.contactFaxNumber}
                                onChange={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactFaxNumber'] && <span className="error">{formErrors['contactFaxNumber']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="email" name="contactEmailId" label="E-Mail"  
                                value={contact.contactEmailId}
                                validations={["email"]}
                                onChange={(e)=>handleContactChange(e)} 
                                disabled={contact.isUserInAzureB2C}
                                />
                            {formErrors['contactEmailId'] && <span className="error">{formErrors['contactEmailId']}</span>}
                        </Col>
                    </Row> 
                    <div>
                        <Form.Check inline type="checkbox" id="activeInd" name="activeInd" label="Is Active?" checked={contact.activeInd === 'Y'} onChange={(e)=>handleContactChange(e)} />
                    </div>
                    <div>
                        <Form.Check inline type="checkbox" id="adtrBatchInd" name="adtrBatchInd" label="ADTR Batch?" checked={contact.adtrBatchInd === 'Y'} onChange={(e)=>handleContactChange(e)} />
                    </div>
                    <Row>
                    <div>
                        <label>Comment: </label>
                        <div className="comments-box">
                            <textarea name="contactComment" value={contact.contactComment}
                            id="comments-input" onChange={(e)=>handleCommentValue(e,'contactComment')}
                            className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                            <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                        </div>
                    </div>
                    </Row>
                </div>
                <div className="btn-div">
                <div className="fright">
                    <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                    <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Update Contact</button>
                    </div>
                </div>
            </form>                
            </Modal.Body>
        </Modal>
    )
}
const EditIssuer = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, issuerInfo} = props;
    const [formErrors, setFormErrors] = useState({});
    const [orgTypes, setOrgTypes] = useState([]);
    const [orgDivisions, setOrgDivisions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [socrataGroups, setSocrataGroups] = useState([]);
    const [counties, setCounties] = useState([]);
    const [issuerCounties, setIssuerCounties] = useState([]);
    const [selectedIssuerCounty, setSelectedIssuerCounty] = useState(null);
    const [issuerContcts, setIssuerContacts] = useState([]);
    const [issuerRequest, setIssuerRequest] = useState({...issuerInfo});
    const [errorMessage, setErrorMessage] = useState();
    const [selectedContactInfo, setSelectedContactInfo] = useState(null);
    const [editContact, setEditContact] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [addContact, setAddIssuerContact] = useState(false);
    const [addNewIssuer, setAddNewIssuer] = useState(false);
    const [selectedNewIssuerAssoc, setSelectedNewIsuerAssoc] = useState(null);
    const [issuersList, setIssuersList] = useState([]);
    const [issuersListLoading, setIssuersListLoading] = useState(false);
    const [issuerAssocs, setIssuerAssocs] = useState([]);
    const [issuerAssocsLoading, setIssuerAssocsLoading] = useState(true);
    
    useEffect(()=>{
        async function getIssuerByIssuerId() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "getIssuerByIssuerId",
                hdrStruct: null,
                queryParam: null,
                reqData: { issuerId: issuerInfo.issuerId },
            };
        
            await IssuerService.getIssuerByIssuerId(obj)
            .then((res) => res)
            .then((result) => {
                setIssuerRequest(result.data.responses[0]);
            })
        }

        async function fetchOrgTypes() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGT", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                const orgTypesListNew = [];
                filteredList.forEach((orgType, i)=>{
                    let obj = {};
                    obj = orgType;
                    obj.value = orgType.cdiacCodeValueId;
                    obj.label = orgType.cdiacCodeValueDesc;
                    orgTypesListNew.push(obj);
                })
                
                setOrgTypes(orgTypesListNew);
                setIsLoading(false);
            })
        }

        async function fetchOrgDivisions() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGD" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                setOrgDivisions(result.data.responses[0]);
            })
        }

        async function fetchSocrataGroups() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "SOCR", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                setSocrataGroups(result.data.responses[0]);
            })
        }

        getIssuerByIssuerId();
        fetchOrgTypes();
        fetchOrgDivisions();
        fetchSocrataGroups();
        getCounties();
        getIssuerCounties();
        getIssuerContactsByIssuerId();
        getIssuerAssocs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    async function getIssuers() {
        setIssuersListLoading(true);
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssuers",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
          };
      
        await IssuerFilerService.getIssuers(obj)
            .then((res) => res)
            .then((result) => {
              Object.keys(result.data.responses).forEach((key) => {
                  setIssuersList(result.data.responses[key]);
              });
              setIssuersListLoading(false);
            });
    }
    async function getIssuerAssocs() {
        setIssuerAssocsLoading(true);
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getIssuerAssocsByIssuerId",
            hdrStruct: null,
            queryParam: null,
            reqData: {"issuerId": issuerInfo.issuerId},
        };
    
        await IssuerService.getIssuerAssocs(obj)
        .then((res) => res)
        .then((result) => {
          if(result.data.errors && result.data.errors.length > 0){
            alert(result.data.errors[0])
          } else {
          setIssuerAssocs(result.data.responses[0]);
          }
          setIssuerAssocsLoading(false);
        })
    }
    const getIssuerContactsByIssuerId = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssuerContactsByIssuerId",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "issuerId" : issuerInfo.issuerId
            },
          };
      
          await IssuerMaintenanceService.getIssuerContacts(obj)
            .then((res) => res)
            .then((result) => {
              Object.keys(result.data.responses).forEach((key) => {
                  setIssuerContacts(result.data.responses[key]);
              });
              setIsLoading(false);
            });
    }
    const getCounties = async () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getCounties",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
          };
      
        await CodeMaintenanceService.getAllCodes(obj)
        .then((res) => res)
        .then((result) => {
            setCounties(result.data.responses[0]);
        })
    }
    const getIssuerCounties = async () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getIssuerCounties",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issuerId" : issuerInfo.issuerId
             },
          };
      
        await CodeMaintenanceService.getAllCodes(obj)
        .then((res) => res)
        .then((result) => {
            setIssuerCounties(result.data.responses[0]);
        })
    }

    const handleCommentValue = (e, key) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setIssuerRequest(prevState => ({
            ...prevState, [key] : e.target.value
        }))
    };


    const handleOrgChange = (e, key) => {
        const val = (key === "issuerName") ? e.target.value : (e.length ? e[0].cdiacCodeValueId : "");

        setIssuerRequest(prevState => ({
            ...prevState, [key] : val
        }))
    }

    const handleSocrataGroupChange = (e) => {
        const selectedValue =  e.length > 0 ? e[0].cdiacCodeValueId : ""
        setIssuerRequest(prevState => ({
            ...prevState, 'issuerSocrataGroupCodeId' : selectedValue
        }))
    }

    const handleIssuerShortNameChange = e => {
        const {name, value} = e.target;
        setIssuerRequest(prevState => ({
            ...prevState, [name] : value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target.closest('form#edit-issuer');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "putIssuer",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "issuerId" : issuerRequest.issuerId,
                    "issuer" :{
                        "issuerName": issuerRequest.issuerName,
                        "issuerShortName": issuerRequest.issuerShortName,
                        "orgTypeCodeId": issuerRequest.orgTypeCodeId,
                        "issuerDivisonCodeId": issuerRequest.issuerDivisonCodeId,
                        "issuerSocrataGroupCodeId": issuerRequest.issuerSocrataGroupCodeId,
                        "agencyTypeCodeId": issuerRequest.agencyTypeCodeId,
                        "issuerComment": issuerRequest.issuerComment,
                        "lastUpdateUser": CommonService.getUserName(user)
                    }
                },
            };
        
            IssuerMaintenanceService.updateIssuer(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        setErrorMessage(result.data.errors[0].message)
                    }
                    else{
                        toast.success("Issuer updated successfully!");
                        onModalClose();
                    }
                    
                });
        }
    }

    const viewEditFormatter = (row) => {
        return (
          <div style={{
            textAlign: "center",
            lineHeight: "normal"
          }}>
            <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
          </div>
        );
      }
    const onRowClick = (e, row) => {
        e.preventDefault();
        setSelectedContactInfo(row);
        setEditContact(true);
        setShowModal(false);
    }

    const handleContatModalClose = () => {
        setEditContact(false);
        setShowModal(true);
        getIssuerContactsByIssuerId();
    }
    const statusFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
            {row.activeInd === "Y" ? "Yes" : "No"}
            </div>
        );
      }
    const issuerContactColumns = [{
        dataField: 'contactFirstName',
        text: 'Contact First Name',
        sort: true,
      }, {
        dataField: 'contactLastName',
        text: 'Contact Last Name',
        sort: true,
      },{
        dataField: 'contactEmailId',
        text: 'Contact Email',
        sort: true,
      },{
        dataField: 'activeInd',
        text: 'Is Active?',
        sort: true,
        formatter: statusFormatter
      },{
        dataField: "",
        text: "Action",
        formatter: viewEditFormatter,
      }
      ];

    const handleIssuerCountyChange = e => {
        const selectedVal =  e.length > 0 ? e[0].countyCode : null
        setSelectedIssuerCounty(selectedVal)
    }
    const saveIssuerCounty = () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "postIssuerCounty",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issuerCounty" : {
                    "issuerId": issuerInfo.issuerId,
                    "countyCode": selectedIssuerCounty,
                    "createUser": CommonService.getUserName(user)
                },
            },
          };
      
        IssuerMaintenanceService.saveIssuerCounty(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors[0]) {
                toast.error(result.data.errors[0].message);
            } else {
                toast.success("Issuer County Saved Successfully.");
                setSelectedIssuerCounty(null);
                getIssuerCounties();
            }
        });
    }
    const deleteIssuerCounty = (county) => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "deleteIssuerCounty",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issuerCountyId" : county.issuerCountyId,
            },
          };
      
        IssuerMaintenanceService.deleteIssuerCounty(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors[0]) {
                toast.error(result.data.errors[0].message);
            } else {
                toast.success("Issuer County Deleted Successfully.");
                getIssuerCounties();
            }
        });
    }

    const handleAddContact = (e) => {
        e.preventDefault();
        setAddIssuerContact(true);
        setShowModal(false);
    }
    const handleAddContatModalClose = () => {
        setAddIssuerContact(false);
        setShowModal(true);
        getIssuerContactsByIssuerId();
    }

    const handleAddNewIssuer = () => {
        issuersList.length === 0 && getIssuers() 
        setAddNewIssuer(true)
    }
    const handleFilerIssuerAssocChange = (e) => {
        if(e.length > 0) {
          setSelectedNewIsuerAssoc(e[0])
        }
    }

    const createIssuerAssociation = async(assoc) => {
        var obj = {
          msgId: "null",
          sysId: null,
          opr: "postIssuerAssocFromIssuerMaintenance",
          hdrStruct: null,
          queryParam: null,
          reqData: { 
            "issuerAssoc" : {
                "issuerId" : issuerInfo.issuerId,
                "linkedIssuerId" : selectedNewIssuerAssoc.issuerId,
                "verifiedFlag" : "N",
                "createUser" : CommonService.getUserName(user)
            } 
          },
        };
    
        await IssuerFilerService.createFilerIssuerAssoc(obj)
        .then((res) => res)
        .then((result) => {
          if (result.data.errors && result.data.errors.length > 0){
            toast.error(result.data.errors[0].message);
          } else {
              toast.success("Issuer Association Created Successfully");
              setAddNewIssuer(false);
              getIssuerAssocs();
          }
        })
      }
  
      const deleteIssuerAssociation = (assoc) => {
        swal({
          title: "Are you sure?",
          text: "Deleting this Associated Issuer will prevent you from submitting, editing, or viewing reports of the deleted Issuer.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            var obj = {
              msgId: "null",
              sysId: null,
              opr: "deleteLinkedIssuerAssoc",
              hdrStruct: null,
              queryParam: null,
              reqData: { 
                "issuerAssocId" : assoc.issuerAssocId
              },
            };
        
            IssuerFilerService.createFilerIssuerAssoc(obj)
            .then((res) => res)
            .then((result) => {
              if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
              } else {
                  toast.success("Issuer Association Deleted Successfully");
                  setAddNewIssuer(false);
                  getIssuerAssocs();
              }
            })
          }
        });
      }
    
    const issuerAssocFormatter = (row) => { 
        return (
            <div style={{ lineHeight: "normal"}}> 
            <FontAwesomeIcon icon={faTrash} className="custom-icon" onClick={(e)=>deleteIssuerAssociation(row)}/>
            </div> 
        )
    }
    const issuerAssocColumns = [
        {
            dataField: 'issuerName',
            text: 'Issuer Name',
            sort: true
        },{
            dataField: 'createUser',
            text: 'Create User',
            sort: true
        },{
            dataField: 'verifiedFlag',
            text: 'Status',
            sort: true,
        },
        { 
            dataField: "",
            text: "Actions", 
            formatter: issuerAssocFormatter,
        }
    ];

    return (
        <>
        <Modal show={showModal} backdrop="static" onHide={onModalClose} style={{'zindex': 1000}}>
            <Modal.Header closeButton><h3>Edit Issuer</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
                <div>
                    <form id="edit-issuer">
                    <Row>
                        <Col md={6} className="control">
                        <FormInput 
                            type="text" 
                            name="issuerName" 
                            label="Issuer Organization Name"
                            value={issuerRequest.issuerName}
                            isrequired="true"
                            validations={["required"]}
                            onChange={(e)=>handleOrgChange(e, 'issuerName')}
                            maxLength="120"
                        />
                        {formErrors['issuerName'] && <span className="error">{formErrors['issuerName']}</span>}
                        </Col>
                        
                        <Col md={6}>
                            <label><span className="required">*</span>Organization Type</label>
                            <Typeahead 
                                id="orgTypeCodeId"
                                name="orgTypeCodeId"
                                placeholder="Choose Org Type..."
                                labelKey="cdiacCodeValueDesc"
                                selected = {
                                    orgTypes.filter(option => 
                                        option.cdiacCodeValueId === issuerRequest.orgTypeCodeId)
                                    }
                                onChange={(e)=>handleOrgChange(e, 'orgTypeCodeId')}
                                options={orgTypes}
                                inputProps={{ 'data-validations': 'required', name: 'orgTypeCodeId' }}
                                isLoading={isLoading}
                                />
                        {formErrors['orgTypeCodeId'] && <span className="error">{formErrors['orgTypeCodeId']}</span>}
                        </Col>

                        <Col md={6} className="control">
                            <label>Issuer Short Name</label>
                            <input type="text" name="issuerShortName" value={issuerRequest.issuerShortName}
                            onChange={(e)=>handleIssuerShortNameChange(e)}
                            />
                        </Col>

                        <Col md={6}>
                            <label>Socrata Groups</label>
                            <Typeahead 
                                id="issuerSocrataGroupCodeId"
                                name="issuerSocrataGroupCodeId"
                                placeholder="Choose Socrata Group..."
                                labelKey="cdiacCodeValueDesc"
                                selected = {
                                    socrataGroups.filter(option => 
                                        option.cdiacCodeValueId === issuerRequest.issuerSocrataGroupCodeId)
                                    }
                                onChange={(e)=>handleSocrataGroupChange(e)}
                                options={socrataGroups}
                                inputProps={{ name: 'issuerSocrataGroupCodeId' }}
                                isLoading={isLoading}
                                />
                        {formErrors['issuerSocrataGroupCodeId'] && <span className="error">{formErrors['issuerSocrataGroupCodeId']}</span>}
                        </Col>
                    
                        <Col md={6}>
                            <label><span className="required">*</span>Division</label>
                            <Typeahead 
                                id="issuerDivisonCodeId"
                                name="issuerDivisonCodeId"
                                placeholder="Choose Org Division..."
                                labelKey="cdiacCodeValueDesc"
                                selected = {
                                    orgDivisions.filter(option => 
                                        option.cdiacCodeValueId === issuerRequest.issuerDivisonCodeId)
                                    }
                                onChange={(e)=>handleOrgChange(e, 'issuerDivisonCodeId')}
                                options={orgDivisions}
                                inputProps={{ 'data-validations': 'required', name: 'issuerDivisonCodeId' }}
                                />
                        {formErrors['issuerDivisonCodeId'] && <span className="error">{formErrors['issuerDivisonCodeId']}</span>}
                        </Col>

                        <Col md={6}>
                            <label><span className="required">*</span>Agency Type</label>
                            <Typeahead 
                                id="agencyTypeCodeId"
                                name="agencyTypeCodeId"
                                placeholder="Choose Agency Type..."
                                labelKey="cdiacCodeValueDesc"
                                selected = {
                                    orgTypes.filter(option => 
                                        option.cdiacCodeValueId === issuerRequest.agencyTypeCodeId)
                                    }
                                onChange={(e)=>handleOrgChange(e, 'agencyTypeCodeId')}
                                options={orgTypes}
                                inputProps={{ 'data-validations': 'required', name: 'agencyTypeCodeId' }}
                                isLoading={isLoading}
                                
                                />
                        {formErrors['agencyTypeCodeId'] && <span className="error">{formErrors['agencyTypeCodeId']}</span>}
                        </Col>
                    </Row>
                    <Row>
                    <div>
                        <label>Comment: </label>
                        <div className="comments-box">
                            <textarea name="issuerComment" value={issuerRequest.issuerComment}
                            id="issuer-comments" onChange={(e)=>handleCommentValue(e,'issuerComment')}
                            className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                            <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                        </div>
                    </div>
                    </Row>
                    <div className="btn-div">
                        <div className="fright">
                        <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                        <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                        </div>
                    </div>
                    </form>
                    <hr/>
                    <Row>
                        <p><b>Issuer Counties:</b></p>
                        {issuerCounties && issuerCounties.map((county, i) => {
                            return (<p>{county.countyName}<FontAwesomeIcon style={{width: '3em' }}icon={faTimes} className="custom-icon" onClick={()=>deleteIssuerCounty(county)}/> </p>)
                        })}
                        <Col md={6} className="control">
                            <Typeahead id="countyCode" 
                                name="issuerCountyCode"
                                placeholder="Choose County..."
                                labelKey="countyName"
                                selected = {
                                    counties.filter(option => 
                                        option.countyCode === selectedIssuerCounty)
                                    }
                                onChange={(e)=>handleIssuerCountyChange(e)}
                                options={counties} isLoading={isLoading}
                                />
                        </Col>
                        <Col md={6} className="control">
                        <button className="custom-button" type="button" onClick={()=>saveIssuerCounty()} disabled={selectedIssuerCounty ? false : true}>Save Issuer County</button>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        {issuerAssocsLoading ? <Spinner animation="border" variant="primary" /> :
                         <DataGrid
                         dataSource={issuerAssocs}
                         sortField='cdiacNbr'
                         searchToolTip="Use the search feature to quickly retrieve the system code values"
                         emptyDataMessage='No data available to display'
                         showPaginator={false}
                         showQuickSearch={false}
                         >
                         {issuerAssocColumns.map((column, i) => {
                             return (
                             <Column 
                             key={i}
                             field={column.dataField}
                             header={column.text}
                             sortable={column.sort} 
                             body={column.formatter}
                             hidden={column.hidden}
                             />)
                             }) 
                         } 
                         </DataGrid>
                        }
                        <br />
                        <div>
                        <button type="button" className="custom-button" onClick={()=>handleAddNewIssuer(true)}>Add Issuer</button>
                        <TooltipComponent title="Select Add Issuer to add yourself as a representative of an Issuer (not shown above) and obtain full Issuer permissions to submit, edit, or view reports of the selected issuer." id="edit-county"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                        </div>
                        {addNewIssuer && 
                        <div>
                        <Row>
                            <Col md={6}>
                                <Typeahead id="addIssuer" 
                                placeholder="Choose Issuer.....Type name to search"
                                labelKey="issuerName"
                                options={issuersList} isLoading={issuersListLoading}
                                onChange={(e)=>handleFilerIssuerAssocChange(e)}/>
                            </Col>
                            <Col>
                                <button className="custom-button" type="button" onClick={()=>createIssuerAssociation()}>Save</button>{" "}
                            </Col>
                        </Row>
                        </div>
                        }
                    </Row>
                    <hr/>
                    <Row>
                        <Col className="control">
                        <p className="fleft"><b>Issuer Contacts:</b></p>
                        <button className="fright custom-button" type="button" data-dismiss="modal" onClick={(e)=>handleAddContact(e)}>Create Issuer Contact</button>
                        </Col>
                        <DataGrid
                        dataSource={issuerContcts}
                        sortField='cdiacNbr'
                        showPaginator={false}
                        showQuickSearch={false}
                        >
                        {issuerContactColumns.map((column, i) => {
                            return (
                            <Column 
                            key={i}
                            field={column.dataField}
                            header={column.text}
                            sortable={column.sort} 
                            body={column.formatter}
                            hidden={column.hidden}
                            />)
                            }) 
                        } 
                        </DataGrid>
                        
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
        {editContact && <EditContact contactInfo={selectedContactInfo} onModalClose={()=>handleContatModalClose()}/>}
        {addContact && <AddContact onModalClose={()=>handleAddContatModalClose()} issuerId={issuerInfo.issuerId}/>}
        </>
    )
}

export default EditIssuer
