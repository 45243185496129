import { Row, Col, Button } from 'react-bootstrap';
import {useState, useEffect} from 'react'
import Card  from '../../ui/Card'
import Select from 'react-select'
import SearchResultContainer from './searchResultContainer'
import { useForm } from "react-hook-form";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import AuthorizationMaintenanceService from "../../../services/AuthorizationMaintenanceService";
import swal from 'sweetalert'
import CommonService from '../../../services/CommonService';
import { AlertType } from '../../../utils';

const AuthorizationMaintenance = (props) => {
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchReq, setSearchReq] = useState([]);
    const { handleSubmit} = useForm();
    const [issuer, setIssuer] = useState([]);
    const [authorizationRef, setAuthorizationRef] = useState([]);

    const [stateValues, setStateValues] = useState({
        value: '',
        label: ''
    })
    const [searchParams, setSearchParams] = useState({
      issuerId: '',
      authorizationId: ''
    })
    
    const handleChange = (e, key) => {
      if( e.length ) {
        setSearchParams(prevState=>({
          ...searchParams, 
            [key]: e[0][key]
          })
        )
      }
    }

    const onSubmit = (data) => {

        if(searchParams.issuerId === '' && searchParams.authorizationId === '' && stateValues.value === '') {
          var name = 'Please select at least one search or filter criteria !';
          var content = document.createElement('div');
          content.innerHTML = '<strong>'+ name +'</strong>';
          swal(content);
          return;
        }
        var obj = {
            "issuerId": searchParams.issuerId,
            "authorizationId": searchParams.authorizationId,
            "authorityOriginCode": stateValues.value,
        }

        setSearchReq(obj);
        setShowSearchResult(true);

      }
    
    const origins = [
        {value:"E", label:"Elections"},
        {value:"I", label:"Issues"},
        {value:null, label:"None"}
    ]

    const showDetails = (e) => {
 
        setStateValues(prevState=>({
         ...stateValues, 
         value: e.value,
         label: e.label
         })
        )
       };

      useEffect(() => {
        CommonService.showHideSpinner(true);
        getIssuers();
        getAuthorizationRefs();
        CommonService.showHideSpinner(false);
      }, []);

      const getIssuers = async() => {
         //Get Issuers   
         let obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuers",
          hdrStruct: null,
          queryParam: null,
          reqData: { },
        };
    
        await AuthorizationMaintenanceService.getIssuers(obj)
          .then((res) => res)
          .then((result) => {
            if(result.data.statusCode === 401 || result.data.statusCode === 500) {
              CommonService.showHideSpinner(false);
              CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
            } else {         
              setIssuer(result.data.responses[0]);
            };
          });
      }
      const getAuthorizationRefs = async() => {
        //Get Issuers   
        let obj = {
          msgId: null,
          sysId: null,
          opr: "getAuthorizationRefs",
          hdrStruct: null,
          queryParam: null,
          reqData: { },
        };
    
        await AuthorizationMaintenanceService.getAuthorizationRefs(obj)
          .then((res) => res)
          .then((result) => {
            if(result.data.statusCode === 401 || result.data.statusCode === 500) {
              CommonService.showHideSpinner(false);
              CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
            } else {
              setAuthorizationRef(result.data.responses[0]);
            }
          });
      }
    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title">
                        <h1 className="menu-title">Authorization Maintenance</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                  <Row>
                    <Col>
                      <div className="control">
                          <label>Issuer Name: </label>
                          <Typeahead
                            placeholder="Choose an Issuer"
                            id="issurer"
                            labelKey="issuerName"
                            onChange={e=>handleChange(e, "issuerId")}
                            inputProps={{ 'data-validations': 'required', name: 'issuerName' }}
                            options={issuer}
                          />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                  <Col>
                    <div className="control">
                        <label>Authorization Name: </label>
                        <Typeahead
                                placeholder="Choose an Authorization Name"
                                id="authorizationRef"
                                labelKey="authorizationName"
                                onChange={e=>handleChange(e, "authorizationId")}
                                inputProps={{ 'data-validations': 'required', name: 'authorizationName' }}
                                options={authorizationRef}
                        />
                    </div>
                  </Col>
                  <Col>
                    <div className="control">
                        <label>Origin: </label>
                        <Select onChange={(e)=>showDetails(e)}  options={origins}/>
                    </div>
                  </Col>
                  </Row>

                  <div style={{overflow: 'auto'}}>
                    <Button color='primary'  style={{float: 'right'}} type="Submit" onClick={handleSubmit(onSubmit)}>Search</Button>
                  </div>
                  </div>
                </div>
              </div>
          </div>
          {showSearchResult && <SearchResultContainer searchReq={searchReq}/>}         
           
        </Card>
        </form>
    )
}

export default AuthorizationMaintenance
