import React, { useState, useContext, useEffect } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import InvoiceService from "../../../../services/InvoiceService";
import Alert from "react-bootstrap/Alert";
import CommonService from "../../../../services/CommonService";
import { toast } from "react-toastify";
import { UserContext } from "../../../../UserContext";
import "react-datepicker/dist/react-datepicker.css";

const EditInvoiceComment = (props) => {
  const user = useContext(UserContext);
  const { onModalClose } = props;
  const stateValues = props.invoiceComments;
  const [errorMessage, setErrorMessage] = useState();
  const [cdiacBgColor, setCdiacBgcolor] = useState("yellowgreen");
  const [cdiacCommentValue, setCdiacCommentValue] = useState(
    stateValues.invoiceComments
  );
  const [cdiacCommentCount, setCdiacCommentCount] = useState(
    stateValues.invoiceComments.length
  );

  // const handleInvChange = (e) => {
  //   const selectedVal = e.length > 0 ? e[0].cdiacCodeValueId : [];

  //   setStateValues((prevState) => ({
  //     ...prevState,
  //     invCommentTypeCodeId: selectedVal,
  //   }));
  // };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setStateValues((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const onSubmit = (data) => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "updateInvoiceComment",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        invoiceCommentId: data.invoiceCommentId,
        invoiceComment: {
          invoiceCommentId: data.invoiceCommentId,
          //invCommentTypeCodeId: data.invCommentTypeCodeId,
          //printMemoFlag: data.printMemoFlag,
          invoiceComments: cdiacCommentValue,
          lastUpdateUser: CommonService.getUserName(user),
        },
      },
    };
    InvoiceService.updateInvoiceComment(obj).then((result) => {
      if (result.data.errors && result.data.errors.length > 0) {
        setErrorMessage(result.data.errors[0].message);
      } else {
        toast.success("Invoice Comment updated successfully!");
        onModalClose();
      }
    });
  };
  useEffect(() => {
    // async function fetchInvoiceCommentTypes() {
    //   var obj = {
    //     msgId: "null",
    //     sysId: null,
    //     opr: "GetLookups",
    //     hdrStruct: null,
    //     queryParam: null,
    //     reqData: { cdiacCode: "INVC", active: "Y" },
    //   };

    //   await CodeMaintenanceService.getAllCodes(obj)
    //     .then((res) => res)
    //     .then((result) => {
    //       const filteredList = result.data.responses[0].filter(
    //         (el) => el.activeInd === "Y"
    //       );
    //       const invTypesListNew = [];
    //       filteredList.forEach((invType, i) => {
    //         let obj = {};
    //         obj = invType;
    //         obj.value = invType.cdiacCodeValueId;
    //         obj.label = invType.cdiacCodeValueDesc;
    //         invTypesListNew.push(obj);
    //       });

    //       setInvTypes(invTypesListNew);
    //     });
    // }
    //fetchInvoiceCommentTypes();
  }, []);

  const handleCdiacCommentValue = (e) => {
    setCdiacCommentCount(e.target.value.length);
    const color = e.target.value.length === 4000 ? "orangered" : "yellowgreen";
    setCdiacBgcolor(color);
    setCdiacCommentValue(e.target.value);
  };

  return (
    <Modal show={true} backdrop="static" onHide={onModalClose}>
      <Modal.Header closeButton>
        <h3>Edit Invoice Comment</h3>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert variant="danger">
            <p>{errorMessage}</p>
          </Alert>
        )}

        <form id="editCommentForm">
         
         {/* <Row>

            <Col md={6}>
              <label>Type</label>
              <Form.Control
                type="text"
                name="invoiceCommentTypeCodeId"
                value={stateValues.invCommentTypeCodeId}
                disabled
              />
            </Col>
            <Col md={6}>
              <label>
                <span className="required">*</span>Comment Type
              </label>
              <Typeahead
                id="invoiceCommentTypeCodeId"
                name="invoiceCommentTypeCodeId"
                placeholder="Choose Inv Comment Type..."
                labelKey="cdiacCodeValueDesc"
                onChange={(e) => handleInvChange(e, "issuerTypeCodeId")}
                options={invTypes}
                inputProps={{
                  "data-validations": "required",
                  name: "invoiceCommentTypeCodeId",
                }}
                isLoading={isLoading}
              />
              {formErrors["invoiceCommentTypeCodeId"] && (
                <span className="error">
                  {formErrors["invoiceCommentTypeCodeId"]}
                </span>
              )}
            </Col>
            <Col md={6}>
              <label>Print Memo:</label>
              <Form.Control
                type="text"
                name="printMemoFlag"
                value={stateValues.printMemoFlag}
                onChange={(e) => handleChange(e)}
              />
            </Col>
          </Row> */}
          <Row>
            <Col className="control">
              <label>Comment:</label>
              <textarea
                name="invoiceComments"
                id="invoiceComments"
                onChange={handleCdiacCommentValue}
                rows="5"
                maxLength="4000"
                placeholder="Add a comment..."
                value={cdiacCommentValue}
              />
              <span
                style={{
                  position: "relative",
                  left: "45%",
                  backgroundColor: cdiacBgColor,
                }}
              >
                {cdiacCommentCount} out of 4000 max
              </span>
              <span
                style={{
                  position: "relative",
                  float: "right",
                  marginTop: "5px",
                }}
              ></span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Created On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="createDatetime"
                      value={CommonService.dateFormatter(
                        stateValues.createDatetime
                      )}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Created By:</label>
              <Form.Control
                type="text"
                name="createUser"
                value={stateValues.createUser}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Last Updated On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="lastUpdateDateTime"
                      value={CommonService.dateFormatter(
                        stateValues.lastUpdateDatetime
                      )}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Last Updated By</label>
              <Form.Control
                type="text"
                name="lastUpdateUserId"
                value={stateValues.lastUpdateUser}
                disabled
              />
            </Col>
          </Row>

          <div className="btn-div">
            <div className="fright">
              <button
                type="button"
                className="custom-button-secondary  btn-secondary"
                onClick={onModalClose}
              >
                Cancel
              </button>{" "}
              <button
                className="custom-button"
                type="button"
                onClick={(e) => onSubmit(stateValues)}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditInvoiceComment;
