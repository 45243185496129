import { Row, Col } from 'react-bootstrap';
import {useState, useEffect} from 'react';
import Card  from '../../ui/Card';
import SearchResultContainer from './searchResultContainer';
import { useForm } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import BaseService from '../../../services/BaseService';

const OrgMaintenance = (props) => {
    const [showSearchResult, setShowSearchResult] = useState(false);
    const { handleSubmit} = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [orgId, setOrgId] = useState("");
    
    const handleOrgChange = (e, key) => {
      setOrgId(e.length > 0 ? e[0][key] : '')
    }

    const onSubmit = (data) => {
        if(orgId === '') {
          var name = 'Please select Organization name !';
          var content = document.createElement('div');
          content.innerHTML = '<strong>'+ name +'</strong>';
          swal(content);
          return;
        }
        setShowSearchResult(true);
    }

    useEffect(() => {
      getOrganizations()
    }, []);

    const getOrganizations = () => {
      //Get Issuers   
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getOrganizationNames",
        hdrStruct: null,
        queryParam: null,
        reqData: { },
      };
  
      BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
          Object.keys(result.data.responses).forEach((key) => {
              setOrganizations(result.data.responses[key]);
          });
          setIsLoading(false);
        });
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title">
                        <h1 className="menu-title">Organization/Filer Contact Maintenance</h1>
                        
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                  {isLoading && <Spinner animation="border" variant="primary" />}
                  <Row>
                    <Col>
                      <div className="control">
                          <label>Organization Name: </label>
                          <Typeahead
                            placeholder="Choose Organization Name"
                            id="issurer"
                            labelKey="orgName"
                            onChange={e=>handleOrgChange(e, "orgId")}
                            inputProps={{ 'data-validations': 'required', name: 'orgName' }}
                            options={organizations}
                          />
                      </div>
                    </Col>
                  </Row>
                  
                  <div style={{overflow: 'auto'}}>
                    <div className="fleft">
                      <a href="/admin" className="dk-blue-color link-button">Back to Admin</a>
                    </div>
                    <button style={{float: 'right', marginBottom: '0'}} className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Search</button>
                  </div>
                  </div>
                </div>
              </div>
          </div>
          {showSearchResult && <SearchResultContainer orgId={orgId} />}         
           
        </Card>
        </form>
    )
}

export default OrgMaintenance
