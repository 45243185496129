import { Modal, Button} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const ContextModal = ({onModalClose, cdiacNo}) => {
    const navigate = useNavigate();    

    const handleClick = () => {
        onModalClose();
        navigate('/mkr-authority-issue-yfs/'+cdiacNo);

    }

    return (
        <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
            <Modal.Header closeButton>
            <Modal.Title>MARKS ROOS YEARLY FISCAL STATUS REPORT FOR AUTHORITY ISSUE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="mb-10 mkr-context">
                <p>The following guidelines are provided by the California Debt and Investment Advisory Commission (“CDIAC” or the “Commission”) to implement the reporting requirements of Government Code Section 6599.1(b) of the Marks-Roos Local Bond Pooling Act of 1985, as amended (Sections 6584 et seq.) (the “Act”).</p>
                <p>Any joint powers authority (“Authority”) selling bonds pursuant to the Act, on or after January 1, 1996, which uses the proceeds of the Marks-Roos bonds to acquire one or more local obligations or transfers funds to a local obligor under the terms of a debt contract between the Authority and the Local Obligor is required to report annually on the fiscal status of the Authority bonds and the local obligations acquired until the final maturity of the authority bonds. These financings are identified as reportable Marks-Roos issues. The report must be submitted to CDIAC no later than October 30 each year.</p> 
                <p>CDIAC has developed the Marks-Roos Yearly Fiscal Status Report for Authority Issue to standardize and facilitate reporting on the Authority bonds. The annual report covers Authority bonds sold under the Act between January 1, 1996 through June 30th of the reporting year which used the proceeds to acquire one or more local obligations under the terms of a debt contract between the Authority and the L ocal Obligor and, which are still outstanding as of the beginning of the fiscal reporting period.</p>
                <p>These instructions pertain to the reporting requirements for the reportable Marks-Roos Authority issues. The JPA is usually identified as a Financing Authority (FA), Public Financing Authority (PFA) or Public Facilities Financing Authority (PFFA) and is referred to herein as the Authority. An Authority should use the Marks-Roos Yearly Fiscal Status Report for Authority Issue to satisfy their annual reporting requirement for the Authority Issue. The Marks-Roos Yearly Fiscal Status Report for Local Obligors should be used to satisfy reporting requirements for acquired local obligations.</p>
                <p><b>Definitions used in these guidelines:</b></p>
                <p><em><strong>Acquired local obligation (“LOB”)</strong></em> means the bonds of a local agency which were purchased with the proceeds of the Authority bonds or a loan made to a local agency with the proceeds of the Authority bonds.</p>
                <p><em><strong>Bonds</strong></em>, as defined in the Act (Government Code Section 6585(c)), means – (1) Bonds, including, but not limited to, assessment bonds, redevelopment bonds, government-issued mortgage bonds, and industrial development bonds; (2) Notes, including bonds, revenue, tax, or grant anticipation notes; (3) Commercial paper, floating rate and variable maturity securities, and any other evidence of indebtedness; and (4) Certificates of participation or lease-purchase agreements.</p>
                <p><em><strong>Creditor</strong></em> means the designation in an issue as an underwriter, purchaser, lender, lessor or a syndicate of such that are bound by agreement to operate as a single Creditor as defined herein.</p>
                <p><em><strong>Date of Bond Issue/Loan</strong></em> means the date of sale (date of the mutual acceptance of the terms of the contract between the Authority and the Creditor).</p>
                <p><em><strong>Final Maturity</strong></em> means the point at which the holders of the bonds have been fully repaid the principal amount of the bonds(s) plus accrued interest and redemption premium, if applicable.</p>
                <p><em><strong>Fiscal Year or Fiscal Reporting Period</strong></em> means July 1st through June 30th annually.</p>
                <p><em><strong>Issue</strong></em> means Bonds as described above.</p>
                <p><em><strong>Local Obligor</strong></em> means the local agency whose bonds were purchased by the Authority or who received a loan from the Authority using the proceeds of the Authority bond sale or under the terms of a debt contract between the Authority and the local obligations.</p>
                <p><em><strong>Reportable Marks-Roos Issue</strong></em> means the bonds issued by an Authority under the Act and the local obligations acquired or transferred with the proceeds of those bonds.</p>
                <p><em><strong>Reporting Year</strong></em> means the year beginning July 1st through June 30th.</p>
                <p><em><strong>Retired</strong></em> means that all bonds constituting an issue have reached the condition of Final Maturity.</p>
                <p><em><strong>Settlement Date</strong></em> means the date on which the assets or rights to use transfer from the Creditor to the Authority.</p>
                <p><em><strong>Yearly Fiscal Status Report</strong></em> means the Marks-Roos Yearly Fiscal Status Report for Authority Issue.</p>
                
                <h4>State Law</h4>
                <p>Section 6599.1(b) of the Government Code states that beginning January 1, 1996, and each year thereafter the sale of any bonds by the Authority for the purpose of acquiring local obligations, the legislative body shall, not later than October 30th of each year until the final maturity of the bonds, supply the following information to the Commission:</p>

                <ol>
                    <li>The principal amount of the bonds outstanding, both Authority bonds and local obligations with the proceeds of the Authority bonds.</li>
                    <li>The balance in the reserve fund.</li>
                    <li>The costs of issuance, including ongoing fees.</li>
                    <li>The total amount of administrative fees collected.</li>
                    <li>The amount of administrative fees charged to each local obligation.</li>
                    <li>The interest earnings and terms of all guaranteed investment contracts.</li>
                    <li>Commissions and fees paid on guaranteed investment contracts.</li>
                    <li>The delinquency rate on all local obligations.</li>
                    <li>The balance in capitalized interest accounts.</li>
                </ol>

                <h4>General Guidelines</h4>
                <p>A Yearly Fiscal Status report must be submitted for all Authority bonds issued under the Act if the proceeds were used to acquire one or more local obligation(s) or transferred funds to a local obligor under the terms of a debt contract on or after January 1, 1996 and if both the Date of Bond Issue and the Settlement Date occur prior to June 30th of the reporting year, the end of the fiscal reporting period. The report must be received no later than October 30th of the reporting year.</p>
                <p>In order to assist issuers in satisfying the annual reporting requirements of the Act, an Authority will receive a courtesy request to complete a Yearly Fiscal Status Report from the Commission on or about August 1st of each year. The request will include a list of all reportable Authority bonds and their associated LOBs with a Date of Bond Issue and Settlement Date prior to the end of the fiscal reporting period for the reporting year. A report on the status of all Authority bonds which were outstanding as of the beginning of the reporting fiscal period must be submitted. Non receipt of this courtesy request does not relieve the Authority of the responsibility to report. An Authority selling an issue structured as Senior/Subordinate series bonds will be required to file a Yearly Fiscal Status Report for each series. Issuers should use the Marks-Roos Yearly Fiscal Status Report for Local Obligors to submit the fiscal status of acquired local obligation.</p>

               </div>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={()=>handleClick()}>
                Go
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ContextModal
