import Card from '../../ui/Card'
import {Form, Spinner} from 'react-bootstrap'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import AddStatutoryCodeForm from './addStatutoryCode'
import Table from '../../Table'
import IssueStatutesService from '../../../services/IssueStatuteService'
import StatuteMaintenanceService from '../../../services/StatuteMaintenanceService'
import { toast } from 'react-toastify'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { UserContext } from '../../../UserContext'
import CommonService from '../../../services/CommonService'
import FinalSaleReportService from '../../../services/FinalSaleReportService'
import { validateFormFields } from '../../../utils'
import TooltipComponent from '../../../common/Tooltip'

const Statutory = (props) => {
    const issueId = props.finalSaleDetails.finalSaleId;
    const {updateFinalSaleDependencies} = props;
    const loginUser = useContext(UserContext);
    const [finalSaleDetails, setFinalSaleDetails] = useState(props.finalSaleDetails ? props.finalSaleDetails : {});
    const [addStatutoryCode, setAddStatutoryCode] = useState(false);
    const [statutories, setStatutories] = useState([]);
    const [editIdx, setEditIndex] = useState(-1);
    const [statuteRefs, setstatuteRefs] = useState([]);
    const [canEdit, setCanEdit ] = useState(false);  
    const [isLoading, setIsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const getStatutesByIssueId = useCallback(async (statuteRefs) => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "getIssueStatutesByIssueId",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": { "id" : issueId, "issueType" : "RFS" }
      };
     await IssueStatutesService.getStatutesByIssueId(obj)
        .then((res) => res)
        .then((result) => {
          let issueStatutesArr = [];
          result.data.responses[0].forEach((item, i) => {
            let statuteText = statuteRefs.filter(ref => ref.statuteId === item.statuteId);
            
            let statute = {...item, 
              'statute' : statuteText.length && statuteText !== undefined ? statuteText[0]['label'] : item.displayStatuteName,
              'notEditable': item.statuteId !== "OTH" ? true : false
            }
            issueStatutesArr.push(statute);
          });
          setStatutories(issueStatutesArr);
          updateFinalSaleDependencies(result.data.responses[0], 'issueStatutes');
          setIsLoading(false);
        });
        // eslint-disable-next-line
    },[issueId]);

    useEffect(()=>{
      const getAllStatuteRefs = async () => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getStatuteRefs",
          hdrStruct: null,
          queryParam: null,
          reqData: {},
        };
   
        StatuteMaintenanceService.getAllStatutes(obj)
          .then((res) => res)
          .then((result) => {
           let statutesArr = [];
           const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
           filteredList.forEach((item, i) => {
                let Obj = {};
                Obj['value'] = item.statuteCode;
                Obj['label'] = item.statuteDesc + " - " + item.statuteCode + " - (" + item.statuteSection + ")";
                Obj['statuteId'] = item.statuteId;
                statutesArr.push(Obj);
            });
            setstatuteRefs(statutesArr);
            getStatutesByIssueId(statutesArr)
          });
      }
    
    getAllStatuteRefs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueId]);

    const handleChange = (e) => {  
      const {name, value} = e.target;
      setFinalSaleDetails({
          ...finalSaleDetails,
          [name]: value
      });
    }
    const handleModalClose = () => {
        setAddStatutoryCode(false);
        setTimeout(() => {
          getStatutesByIssueId(statuteRefs);
        }, 1000);
    }

    const updateFinalSaleInfo = () => {
      let obj = {
        "msgId": null,
        "sysId": null,
        "opr": "putFinalSale",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
            "id": finalSaleDetails.finalSaleId,
            "finalSale": {...finalSaleDetails,
            'lastUpdateUser': CommonService.getUserName(loginUser)}
        }
      };
  
      FinalSaleReportService.updateFinalSale(obj)
      .then((res) => res)
      .then((result) => {
          if(result.data.responses[0]) {
              props.updateFinalSaleDetails(result.data.responses[0]);
              props.sectionValidated('statutes', true);
              props.openWidget('filingContact');
          }
      });
      
    }

    const validateForm = (e) => {
      let form = e.target.closest('form#statutoriesForm');

      let formErrors = validateFormFields(form.elements);
      if(Object.keys(formErrors).length > 0) {
          setFormErrors(formErrors);
          props.sectionValidated('statutes', false);
      } else {
          setFormErrors({});
          if(statutories.length !== 0) {
            updateFinalSaleInfo();
          } else {
            props.sectionValidated('statutes', false);
            alert("Please select at least one Statutory Code.");
          } 
      }       
    }

    const handleRemove = (i, statuteData) => {
      const issueStatuteId = statuteData.issueStatuteId;
      deleteStatute(issueStatuteId);
    }

    const startEditing = (i, rowData) => {
      if(rowData.statuteId === 'OTH') {
        setCanEdit(true);
      } else {
        setCanEdit(false);
      }
      setEditIndex(i);
    }

    const handleSave = (i, statuteData) => {
      updateStatute(statuteData);
      setEditIndex(-1);
    }

    const stopEditing = (i) => {
      setEditIndex(-1);
    }

    const updateStatute = (statuteData)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueStatute",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : statuteData.issueStatuteId,
                "issueStatute": statuteData
            }
        };

        IssueStatutesService.addStatute(obj)
        .then((res) => res)
        .then((result) => {
          getStatutesByIssueId(statuteRefs);
        });
    }

    const deleteStatute = (issueStatuteId)  => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "deleteIssueStatute",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": {
            "id" : issueStatuteId,
          }
      };

      IssueStatutesService.deleteStatute(obj)
      .then((res) => res)
      .then((result) => {
          toast.success(
            'Statute Deleted Successfully'
          );
          getStatutesByIssueId(statuteRefs);
      });
    }
    const handleStatutoryChange = (e, name, i) => {
      const { value } = e.target;
      const statutoriesArr =[...statutories];
      statutoriesArr[i][name] = value;
      setStatutories(statutoriesArr);
    }
    return (
        <Card>
            <Form className="form" id="statutoriesForm">
            <div>
            <div className="mb-3">
                <label htmlFor="validateAction"><span className="required">*</span>Will a Validation Action be Pursued: 
                  <TooltipComponent
                  title="Whether a validation action will be pursued"
                  id="invoice"
                  placement="right">
                      <AiFillQuestionCircle className="help-icon heading-help-icon" />
                  </TooltipComponent>
                </label>
                <Form.Check
                    inline
                    label="No"
                    name="validationActionFlag"
                    type="radio"
                    value="N"
                    id="validationActionFlag-1"
                    checked={finalSaleDetails.validationActionFlag === 'N'}
                    onChange={(e)=>handleChange(e)}
                    data-validations="required"
                />
                <Form.Check
                    inline
                    label="Yes"
                    name="validationActionFlag"
                    type="radio"
                    value="Y"
                    id="validationActionFlag-2"
                    checked={finalSaleDetails.validationActionFlag === 'Y'}
                    onChange={(e)=>handleChange(e)}
                    data-validations="required"
                />
                <Form.Check
                    inline
                    label="Unknown"
                    name="validationActionFlag"
                    value="U"
                    type="radio"
                    id="validationActionFlag-3"
                    checked={finalSaleDetails.validationActionFlag === 'U'}
                    onChange={(e)=>handleChange(e)}
                    data-validations="required"
                />
                {formErrors['validationActionFlag'] && <p className="error">{formErrors['validationActionFlag']}</p>}
            </div>

            <br/>
            {isLoading ? <Spinner /> : statutories.length !== 0 && <Table 
                    handleRemove={handleRemove}
                    startEditing={startEditing}
                    editIdx={editIdx}
                    stopEditing={stopEditing}
                    handleSave={handleSave}
                    handleChange={handleStatutoryChange}
                    data={statutories}
                    header={[
                      {
                        name: "Statute",
                        prop: "statute",
                        fieldType: "input",
                        editable: false
                      },
                      {
                        name: "Other Code",
                        prop: "otherStatuteCode",
                        fieldType: "input",
                        editable: canEdit
                      },
                      {
                        name: "Other Section",
                        prop: "otherStatuteText",
                        fieldType: "input",
                        editable: canEdit
                      },
                      {
                        name: "Other Desc",
                        prop: "otherStatuteBriefDesc",
                        fieldType: "input",
                        editable: canEdit
                      }
                    ]}
                />
            }
            <span className="required" style={{fontSize: '24px'}}>* </span><button type="button" className="custom-button" onClick={()=>setAddStatutoryCode(true)}>Add Statute</button>
            </div>
           
            {addStatutoryCode && <AddStatutoryCodeForm onModalClose={handleModalClose} issueId={issueId} statuteRefs={statuteRefs}/>}
            
            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="custom-button fright" type="button" onClick={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </Form>
        </Card>
    )
}

export default Statutory
