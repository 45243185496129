import {Modal, Col, Row, Form} from 'react-bootstrap'
import {useState, useEffect} from 'react'
import Alert from 'react-bootstrap/Alert'
import AuthorizationMaintenanceService from "../../../services/AuthorizationMaintenanceService";
import Spinner from 'react-bootstrap/Spinner'
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import CommonService from "../../../services/CommonService";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import CurrencyInput from '../../formElements/CurrencyInput';
import { validateFormFields } from '../../../utils';

const AddAuthorization = (props) => {
    const user = useContext(UserContext);
    const {onModalClose} = props;
    const [errorMessage, setErrorMessage] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [issuer, setIssuer] = useState([]);
    const [electionDate, setElectionDate] = useState(null);
    const [authorizationInfo, setAuthorizationInfo] = useState({
        'authorizationAmt' : '',
        'originCode' : ''
    });

    const [issuerId, setIssuerId] = useState({
        issuerId: ''
      })

    const handleChange = (e, key) => {
        if( e.length ) {
            setIssuerId(prevState=>({
            ...issuerId, 
              [key]: e[0][key]
            })
          )
        }
    }

    useEffect(() => {
        //Get Issuers   
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuers",
          hdrStruct: null,
          queryParam: null,
          reqData: { },
        };
    
        AuthorizationMaintenanceService.getIssuers(obj)
          .then((res) => res)
          .then((result) => {
            Object.keys(result.data.responses).forEach((key) => {
                setIssuer(result.data.responses[key]);
            });
            setIsLoading(false);
          });
      }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target.closest('form#add-authorization');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "postAuthorizationRef",
                hdrStruct: null,
                queryParam: null,
                reqData: { "authorizationRef":{
                    "issuerId": issuerId.issuerId,
                    "authorizationName": authorizationInfo.authorizationName,
                    "authorityOriginCode": authorizationInfo.originCode,
                    "electionDate": CommonService.dateFormatter(electionDate),
                    "authorizationAmt":parseFloat(authorizationInfo.authorizationAmt.replace(/\$|,/g, '')),
                    "createUser":  CommonService.getUserName(user),
                    "lastUpdateUser":  CommonService.getUserName(user)
                }
                },
            };

            AuthorizationMaintenanceService.addAuthorizationRef(obj)
                .then((res) => res)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        setErrorMessage(result.data.errors[0].message)
                    }
                    else{
                        toast.success("Authorization added successfully!");
                        onModalClose();
                    }
                    
                });
        }
    }
    return (        
        <Modal show={true} backdrop="static" onHide={onModalClose} size='lg'>
            <Modal.Header closeButton><h3>Add New Authorization</h3></Modal.Header>
            <Modal.Body>
                
            {isLoading && <Spinner animation="border" variant="primary" />}

            {errorMessage && 
                <Alert variant="danger">
                            <p>
                                {errorMessage}
                            </p>
                </Alert>
            }
            <form id="add-authorization">
                <Row>
                    <Col md={6} className="control">
                        <label><span className="required">*</span>Issuer Name:</label>
                        <Typeahead
                                placeholder="Choose an Issuer"
                                id="issurer"
                                labelKey="issuerName"
                                onChange={e=>handleChange(e, "issuerId")}
                                inputProps={{ 'data-validations': 'required', name: 'issuerName', maxLength: '10' }}
                                options={issuer}
                        />
                        {formErrors.issuerName && <p className="error">Issuer Name is required</p>}
                    </Col>
                    <Col md={6} className="control">
                        <label><span className="required">*</span>Authorization Name:</label>
                        <input
                            type="text" 
                            name="authorizationName"
                            value={authorizationInfo.authorizationName}
                            onChange={(e)=>setAuthorizationInfo({...authorizationInfo, 'authorizationName' : e.target.value })}
                            data-validations="required"
                        />
                        {formErrors.authorizationName && <p className="error">Authorization Name is required</p>}
                    </Col>
                </Row>
                      
                <Row>
                    <Col md={6} >
                        <label><span className="required">*</span>Voter Measure:</label>
                        <Form.Check
                            inline
                            label="No"
                            name="voterMeasureInd"
                            value="N"
                            type="radio"
                            id="voterMeasure_0"
                            checked={authorizationInfo.originCode === 'I'}
                            onChange={(e)=>setAuthorizationInfo({...authorizationInfo, 'originCode' : 'I' })}
                        />
                        <Form.Check
                            inline
                            label="Yes"
                            name="voterMeasureInd"
                            value="Y"
                            type="radio"
                            id="voterMeasure_1"
                            checked={authorizationInfo.originCode === 'E'}
                            onChange={(e)=>setAuthorizationInfo({...authorizationInfo, 'originCode' : 'E' })}
                        />
                        {formErrors.originType && <p className="error">{formErrors.originType}</p>}
                    </Col>
                        <Col md={6} className="control">
                            <label><span className="required">*</span>Authorization Date (By Election or Governing Body):</label>
                            <span>
                            <DatePicker 
                            selected={electionDate ? new Date(electionDate) : null}
                            name="electionDate"
                            dateFormat="M/d/yyyy"
                            maxDate= {new Date(9999, 12, 31)}
                            onChange={(e) => setElectionDate(e, "electionDate")} 
                            />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                            {formErrors.electionDate && <p className="error">Election Date is required</p>}
                            <input type="hidden" value={electionDate} name="electionDate" data-validations={['required']}/>
                        </Col>
                        <Col md={6} className="control">
                            <CurrencyInput 
                                placeholder="0.00" 
                                label="Original Authorization Amount" 
                                name="authorizationAmt" 
                                type="text" 
                                onChange={(e)=>setAuthorizationInfo({...authorizationInfo, 'authorizationAmt' : e.target.value })}
                                value={authorizationInfo.authorizationAmt}
                                validations={["required", "max:999999999999.99"]}
                                isrequired="true"
                            />
                            {formErrors.authorizationAmt && <p className="error">Authorization Amount is required</p>}
                        </Col>
                </Row>
                <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                    <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
            </form>                
            </Modal.Body>
        </Modal>
    )
}

export default AddAuthorization
