import React, { useState, useContext } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import InvoiceService from "../../../../services/InvoiceService";
import Alert from "react-bootstrap/Alert";
import CommonService from "../../../../services/CommonService";
import { toast } from "react-toastify";
import { UserContext } from "../../../../UserContext";
import "react-datepicker/dist/react-datepicker.css";

const AddInvoiceComment = (props) => {
  const user = useContext(UserContext);
  const { onModalClose } = props;
  const stateValues = {
    invoiceId: props.invoiceId,
    invoiceCommentId: 0,
    invCommentTypeCodeId: null,
    invComments: null,
    printMemoFlag: "P",
    createUser: CommonService.getUserName(user),
    createDateTime: new Date(),
    lastUpdateUser: CommonService.getUserName(user),
    lastUpdateDatetime: new Date(),
  };

  const [errorMessage, setErrorMessage] = useState();
  const [cdiacBgColor, setCdiacBgcolor] = useState("yellowgreen");
  const [cdiacCommentValue, setCdiacCommentValue] = useState();
  const [cdiacCommentCount, setCdiacCommentCount] = useState(0);

  const onSubmit = (data) => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "createInvoiceComment",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        invoiceComment: {
          invoiceCommentId: 0,
          invoiceId: data.invoiceId,
         // invCommentTypeCodeId: data.invCommentTypeCodeId,
         // printMemoFlag: data.printMemoFlag,
          invoiceComments: cdiacCommentValue,
          createUser: data.createUser,
          createDateTime: data.createDateTime,
          lastUpdateUser: data.lastUpdateUser,
          lastUpdateDatetime: data.lastUpdateDatetime,
        },
      },
    };
    InvoiceService.createInvoiceComment(obj).then((result) => {
      if (result.data.errors && result.data.errors.length > 0) {
        setErrorMessage(result.data.errors[0].message);
      } else {
        toast.success("Invoice Comment created successfully!");
        onModalClose();
      }
    });
  };

  const handleCdiacCommentValue = (e) => {
    setCdiacCommentCount(e.target.value.length);
    const color = e.target.value.length === 4000 ? "orangered" : "yellowgreen";
    setCdiacBgcolor(color);
    setCdiacCommentValue(e.target.value);
  };

  return (
    <Modal show={true} backdrop="static" onHide={onModalClose}>
      <Modal.Header closeButton>
        <h3>Add Comment</h3>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert variant="danger">
            <p>{errorMessage}</p>
          </Alert>
        )}

        <form id="editCommentForm">
          <Row>
            <Col className="control">
              <label>Comment:</label>
              <textarea
                name="invoiceComments"
                id="invoiceComments"
                onChange={handleCdiacCommentValue}
                rows="5"
                maxLength="4000"
                placeholder="Add a comment..."
                value={cdiacCommentValue}
              />
              <span
                style={{
                  position: "relative",
                  left: "45%",
                  backgroundColor: cdiacBgColor,
                }}
              >
                {cdiacCommentCount} out of 4000 max
              </span>
              <span
                style={{
                  position: "relative",
                  float: "right",
                  marginTop: "5px",
                }}
              ></span>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Created On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="createDateTime"
                      value={CommonService.dateFormatter()}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Created By:</label>
              <Form.Control
                type="text"
                name="createUser"
                value={stateValues.createUser}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Last Updated On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="lastUpdateDateTime"
                      value={CommonService.dateFormatter(
                        stateValues.lastUpdateDatetime
                      )}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Last Updated By</label>
              <Form.Control
                type="text"
                name="lastUpdateUserId"
                value={stateValues.lastUpdateUser}
                disabled
              />
            </Col>
          </Row>

          <div className="btn-div">
            <div className="fright">
              <button
                type="button"
                className="custom-button-secondary  btn-secondary"
                onClick={onModalClose}
              >
                Cancel
              </button>{" "}
              <button
                className="custom-button"
                type="button"
                onClick={(e) => onSubmit(stateValues)}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddInvoiceComment;
