import {useState, useContext} from 'react'
import {Modal, Row, Col, Form} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import AddContactModal from '../dashboard/addContactModal'
import {validateFormFields} from '../../utils'
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import IssueContactService from '../../services/IssueContactService'
import { toast } from 'react-toastify'
import { IssuerContext, UserContext } from "../../UserContext"
import CommonService from '../../services/CommonService';

const AddFinancingParticipant = (props) => {
    const {issueId, onModalClose, roles} = props;
    const issuerId = useContext(IssuerContext);
    const user = useContext(UserContext);
    const [addContact, setAddContact] = useState(false);
    const [formErrors, setFormErrors] = useState({})
    const [organizations, setOrganizations] = useState([]);
    const [issuerContacts, setIssuerContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [financingParticipant, setFinancingParticipant] = useState(
        {
            contactRole: {code: "", desc: ""},
            contactId: "",
            contactType: ""
        }
    )
    const handleContactChange = (e, key) => {
        let fContact = {...financingParticipant};        
        if( e.length ) {
            fContact.contactId = e[0][key];
        }        
        setFinancingParticipant(fContact);
    }
    const handleContactRoleChange = (e) => {
        let fContact = {...financingParticipant};
        fContact.contactRole.code = e.target.value;
        fContact.contactRole.desc = e.target[e.target.selectedIndex].text;
        setFinancingParticipant(fContact);
    }
    const handleContactTypeChange = (e) => {
        let fContact = {...financingParticipant};
        fContact.contactType = e.target.value;
        setIsLoading(true);
        if(e.target.value === "organization") {
            getOrganizations();
        } else {
            getContacts();
        }
        setFinancingParticipant(fContact);
    }

    const getOrganizations = () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getOrganizations",
            hdrStruct: null,
            queryParam: null,
            reqData: {},
          };
      
          ReportOfProposedDebtService.getOrganizations(obj)
            .then((res) => res)
            .then((result) => {
              setOrganizations(result.data.responses[0]);
              setIsLoading(false);
            });
    }

    const getContacts = () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getIssuerContacts",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "id" : issuerId
            },
          };
      
          ReportOfProposedDebtService.getIssuerContacts(obj)
            .then((res) => res)
            .then((result) => {
              setIssuerContacts(result.data.responses[0]);
              setIsLoading(false);
            });
    }

    const handleAddContact = (e, i) => {
        setAddContact(true);
    }

    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            saveFinancialParticipant();

        }
    }

    const saveFinancialParticipant = () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "postIssueContactAssoc",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issueContactAssoc" : {
                    "finalSaleId": null,
                    "proposeDebtId": issueId,
                    "contactId" : financingParticipant.contactId,
                    "roleCodeId" : financingParticipant.contactRole.code,
                    "feeAmt": null,
                    "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user),
                }
            },
          };
      
          IssueContactService.addIssueContact(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message)
                }
                else{
                    toast.success("Financial Participant Saved successfully!");
                    onModalClose();
                }
            });
    }

    const handleContactAfterSave = async() => {
        setIsLoading(true);
        const financingcontact = {...financingParticipant};
        if(financingcontact.contactType === 'organization') {
           await getOrganizations();
        } else {
            await getContacts();
        }
        setAddContact(false);
    }
    return (
        <Modal show={true} onHide={props.onModalClose} size='lg'>
            <Modal.Header closeButton>
                <h3>Add Financing Participant</h3>
            </Modal.Header>
            <Modal.Body>
                <form id="add-form">
                        <div>
                            <label><span className="required">*</span>Financing Participant:</label>
                            <Form.Select name="contactRole" 
                                data-validations="required" 
                                value={financingParticipant.contactRole.code} 
                                onChange={(e)=>handleContactRoleChange(e)}
                                style={formErrors['contactRole'] && {border: `solid 1px red`}}>
                                <option value="">select</option>
                                { roles.map((role, i) => {
                                    return <option key={i} value={role.cdiacCodeValueId}>{role.cdiacCodeValueDesc}</option>
                                })

                                }
                            </Form.Select>
                            {formErrors['contactRole'] && <p className="error">{ formErrors['contactRole'] }</p>}
                        </div>
                        { financingParticipant.contactRole.code !== "" && 
                            <>
                            <div>
                                <label><span className="required">*</span>Contact Type: </label>
                                <div>
                                <Form.Check inline type="radio" name="contact_type" label="Organization" id="organization" value="organization" checked={financingParticipant.contactType === 'organization'} onChange={(e)=>handleContactTypeChange(e)} data-validations="required" />

                                <Form.Check inline type="radio" name="contact_type" label="Issuer Representative" id="individual" value="individual" checked={financingParticipant.contactType === 'individual'} onChange={(e)=>handleContactTypeChange(e)} data-validations="required" />
                                </div>
                                {formErrors['contact_type'] && <p className="error">{ formErrors['contact_type'] }</p>}
                            </div>
                            <div className="control">
                                <label><span className="required">*</span>{financingParticipant.contactRole.desc}:</label>
                                {financingParticipant.contactType === 'individual' ?
                                    <Row>
                                    <Col>
                                        <div>
                                            <label>Individual Name</label>
                                            <Typeahead
                                            id="indivisuaCcontact"
                                            isLoading={isLoading}
                                            labelKey="contactFullName"
                                            onChange={e=>handleContactChange(e, "contactId")}
                                            emptyLabel={isLoading ? 'Loading...' : 'No Individuals Found'}
                                            options={isLoading ? [] : issuerContacts}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                :  <div className="control">
                                    <label> Organization Name </label>
                                    <Typeahead
                                    id="contact"
                                    labelKey="orgName"
                                    isLoading={isLoading}
                                    onChange={e=>handleContactChange(e, "contactId")}
                                    style={formErrors['contact'] && {border: `solid 1px red`}}
                                    inputProps={{ 'data-validations': 'required', name: 'contact' }}
                                    emptyLabel={isLoading ? 'Loading...' : 'No Organizations Found'}
                                    options={isLoading ? [] : organizations}
                                    />
                                    {formErrors['contact'] && <p className="error">{ formErrors['contact'] }</p>}
                                    </div>
                                    
                                }
                                
                            </div>
                            <button type="button" className="link-button" onClick={(e)=>handleAddContact(e)} id="add_new"><small>Add New {financingParticipant.contactType === "organization" ? "Organization" : "Issuer Representative"}</small></button>
                            </>
                        }
                    

                    <div className="btn-div">
                        <div className="fright">
                            <button type="button" className="custom-button-secondary btn-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                            <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                        </div>
                    </div>
                    {addContact && <AddContactModal onModalClose={()=>setAddContact(false)} handleContactAfterSave={handleContactAfterSave} contactType={financingParticipant.contactType} />}
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddFinancingParticipant
