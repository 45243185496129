import {useState, useEffect} from 'react'
import Card from '../../ui/Card'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditFeeForm from './editFeeForm'
import AddFeeForm from './addFeeForm'
import FeeScheduleMaintenanceService from "../../../services/FeeScheduleMaintenanceService";
import CommonService from "../../../services/CommonService";
import { AlertType } from '../../../utils';
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const FeeScheduleMaintenance = () => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editFeeInfo, setEditFeeInfo] = useState([])
    const [openAddFeeeModal, setOpenAddFeeModal] = useState(false);
    const [feeSchedules, setFeeSchedules] = useState([]);

    const viewFormatter = (row) => { 
        return ( 
              <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
              <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/>
         </div> 
        ); 
    }

    const getFeeScheduleRefs = () => {
      CommonService.showHideSpinner(true);
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getFeeScheduleRefs",
        hdrStruct: null,
        queryParam: null,
        reqData: {},
      };

      FeeScheduleMaintenanceService.getAllFeeSchedules(obj)
        .then((res) => res)
        .then((result) => {
          if(result.data.statusCode === 401 || result.data.statusCode === 500) {
            CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
          } else {
            setFeeSchedules(result.data.responses[0]);
          };
          CommonService.showHideSpinner(false);
        });
    }

    useEffect(() => {
      getFeeScheduleRefs();
    }, []);

    const onRowClick = (e, row) => {
      setOpenEditModal(true);
      setEditFeeInfo(row);
    }

    const handleAddModalClose = () => {
      setOpenAddFeeModal(false);
      getFeeScheduleRefs();
    }
    const handleModalClose = () => {
      setOpenEditModal(false);
      getFeeScheduleRefs();
    }

    const currencyTemplate = (row, fieldName) => {
      return CommonService.currencyFormatter(row[fieldName])
    }
    const dateTemplate = (row, fieldName) => {
      return CommonService.dateFormatter(row[fieldName])
    }

    return (
        <Card>
          <div>
            <div className="panel panel-default" id="stepspanel" style={{marginBottom: '0px'}}>

              <div className="panel-heading">
                  <div className="container">
                      <div id="packet_id" className="panel-title">
                          <h1 className="menu-title">Fee Schedule Maintenance</h1>
                      </div>
                  </div>
              </div>

              <div className="panel-body">
                <div className="form">
                  <DataGrid
                  dataSource={feeSchedules}
                  sortField='effectiveStartDate'
                  addNew={true}
                  addNewBtnText="Add New Fee Schedule"
                  onAddNew={()=>setOpenAddFeeModal(true)}
                  >
                    <Column field='effectiveStartDate' header='Effective Start Date' sortable body={(row)=>dateTemplate(row, 'effectiveStartDate')}/>
                    <Column field='effectiveEndDate' header='Effective End Date' sortable body={(row)=>dateTemplate(row, 'effectiveEndDate')}/>
                    <Column field='principalMinAmt' header='Min Principal Amount' sortable body={(row)=>currencyTemplate(row, 'principalMinAmt')} />
                    <Column field='principalMaxAmt' header='Max Principal Amount' sortable body={(row)=>currencyTemplate(row, 'principalMaxAmt')} />
                    <Column field='createDatetime' header='Date Created' sortable body={(row)=>dateTemplate(row, 'createDatetime')} />
                    <Column field='createUser' header='Created By' sortable />
                    <Column field='' header='' body={viewFormatter}/>
                  </DataGrid>
                </div>
              </div>

            </div>
          </div>
            
            {openAddFeeeModal && <AddFeeForm onModalClose={()=>handleAddModalClose(false)}/>}
            {openEditModal && <EditFeeForm onModalClose={()=>handleModalClose()} feeInfo={editFeeInfo} />}
        </Card>
    )
}

export default FeeScheduleMaintenance
