import CommonService from "./CommonService";


const getReportGroups = (obj) => {
    return CommonService.apicall(obj)

};

const getReportRefDetails = (obj) => {
    return CommonService.apicall(obj)

};

const addReportRef = (obj) => {
    return CommonService.apicall(obj)

};

const updateReportRef = (obj) => {
    return CommonService.apicall(obj)

};

const ReportRefService = {
    getReportGroups,
    getReportRefDetails,
    addReportRef,
    updateReportRef
};

export default ReportRefService;