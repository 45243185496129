import {useState, useEffect, useCallback, useContext} from 'react'
import { Form, Col, Row, Modal } from 'react-bootstrap'
import Card from '../../../components/ui/Card'
import { validateFormFields } from '../../../utils/index'
import CurrencyInput from '../../formElements/CurrencyInput'
import MaxCurrencyInput from '../../formElements/MaxCurrencyInput'
import Select from 'react-select'
import { UserContext } from '../../../UserContext'
import { toast } from 'react-toastify'
import { Typeahead } from 'react-bootstrap-typeahead'
import IssueCdiacNumberService from '../../../services/IssueCdiacNumberService'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import { AiFillQuestionCircle } from 'react-icons/ai'
import FinalSaleReportService from '../../../services/FinalSaleReportService'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FaCalendarAlt} from 'react-icons/fa'
import TooltipComponent from '../../../common/Tooltip'

const federalTaxOptions = [
    { value: "T", label : "Taxable"},
    { value: "E", label : "Tax Exempt, No AMT"},
    { value: "A", label : "Tax Exempt, with AMT"}
]
const InterestDebtExempt = (props) => {   
    const loginUser = useContext(UserContext);
    const {finalSaleDetails} = props;
    const issueId = props.finalSaleDetails.finalSaleId;
    const [showStateTaxAmount, setShowStateTaxAmount] = useState(false);
    const [showStateTaxExemptAmount, setShowStateTaxExemptAmount] = useState(false);
    const [showStateTaxTotalAmount, setShowStateTaxTotalAmount] = useState(false);
    const [stateTaxTotal, setStateTaxTotal] = useState("0.00");
    const [showCdiacInstructionsModal, setShowCdiacInstructionsModal] = useState(false);

    const [showFederalTaxAmount, setShowFederalTaxAmount] = useState(false);
    const [showFederalTaxExemptAmount, setShowFederalTaxExemptAmount] = useState(false);
    const [showAltMinTaxAmount, setShowAltMinTaxAmount] = useState(false);
    const [showFederalTaxTotalAmount, setShowFederalTaxTotalAmount] = useState(false);
    const [federalTaxTotal, setFederalTaxTotal] = useState("0.00");
    const [finalSaleInfo, setFinalSaleInfo] = useState(finalSaleDetails ?
        {...finalSaleDetails,
            'cabMaturityDate': finalSaleDetails.cabMaturityDate ? new Date(finalSaleDetails.cabMaturityDate) : null, 
            'cabConversionDate': finalSaleDetails.cabConversionDate ? new Date(finalSaleDetails.cabConversionDate) : null, 
            'cabConversionMaturityDate': finalSaleDetails.cabConversionMaturityDate ? new Date(finalSaleDetails.cabConversionMaturityDate) : null
        } :
        {});
    const [formErrors,setFormErrors] = useState({});
    const [defaultFederalTaxSelection, setDefaultFederalTaxSelection] = useState([{}]);
    const [cdiacNumbersList, setCdiacNumbersList] = useState([]);
    const [nicDisabled, setNicDisabled] = useState(finalSaleDetails.nicInterestTypeInd !== "Y" && true);
    const [ticDisabled, setTicDisabled] = useState(finalSaleDetails.ticInterestTypeInd !== "Y" && true);
    const [varDisabled, setVarDisabled] = useState(finalSaleDetails.varInterestTypeInd !== "Y" &&true);
    const [otherDisabled, setOtherDisabled] = useState(finalSaleDetails.otherInterestTypeInd !== "Y" &&true);

    const [issueSeriesCdiacNbrs, setIssueSeriesCdiacNbrs] = useState([
        {seriesCdiacNbr: ''}
    ]);

    const getCdiacNumbers = useCallback (() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getCdiacNumbers",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {}
        };
       
        IssueCdiacNumberService.getCdiacNumbers(obj)
          .then((res) => res)
          .then((result) => {
            setCdiacNumbersList(result.data.responses[0]);
          });
    },[]);

    useEffect(()=>{
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueSeriesByCdiacNbr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "cdiacNbr" : finalSaleInfo.cdiacNbr
            }
        };
       
        ReportOfProposedDebtService.getIssueSeriesByIssueId(obj)
          .then((res) => res)
          .then((result) => {
              if(result.data.errors.length > 0) {
                toast.error(result.data.errors[0].message);
              } else if(result.data.responses[0].length > 0) {
                setIssueSeriesCdiacNbrs(result.data.responses[0]);
              } else {
                setIssueSeriesCdiacNbrs([
                    {seriesCdiacNbr: ''}
                ]);
              }
          });
        
        getCdiacNumbers();
    }, [getCdiacNumbers, issueId, finalSaleInfo.cdiacNbr]);

    useEffect(() => {
        finalSaleInfo.federalTaxFlag && calculateFederalTaxTotal(finalSaleInfo);
       
        const federalTaxSelectionArr = finalSaleInfo.federalTaxFlag ? finalSaleInfo.federalTaxFlag.split("") : [];

        (federalTaxSelectionArr.length > 1) ? setShowFederalTaxTotalAmount(true) : setShowFederalTaxTotalAmount(false);
        
        let defaultFederalTaxSelectionArr = [];
        federalTaxSelectionArr.forEach(val => {
            if( val === 'T') {
                setShowFederalTaxAmount(true);
            }
            if( val === 'E') {
                setShowFederalTaxExemptAmount(true);
            }
            if( val === 'A') {
                setShowAltMinTaxAmount(true);
            }
            defaultFederalTaxSelectionArr.push(federalTaxOptions.find(item=> item.value === val))
        });

        setDefaultFederalTaxSelection(defaultFederalTaxSelectionArr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ finalSaleInfo.federalTaxFlag]);

    useEffect(()=> {
        finalSaleInfo.stateTaxFlag && calculateStateTaxTotal(finalSaleInfo);

        switch (finalSaleInfo.stateTaxFlag) {
            case "Y" :
                setShowStateTaxAmount(true);
                setShowStateTaxExemptAmount(false);
                setShowStateTaxTotalAmount(false);
                setFinalSaleInfo(prevState=>({
                    ...prevState, 'stateTaxExemptAmt': ''
                }))
                break;
            case "N" :
                setShowStateTaxAmount(false);
                setShowStateTaxExemptAmount(true);
                setShowStateTaxTotalAmount(false);
                setFinalSaleInfo(prevState=>({
                    ...prevState, 'stateTaxPrincipalAmt': ''
                }))
                break;
            case "B":
                setShowStateTaxAmount(true);
                setShowStateTaxExemptAmount(true);
                setShowStateTaxTotalAmount(true);
                break;
            default :
                setShowStateTaxAmount(false);
                setShowStateTaxExemptAmount(false);
                setShowStateTaxTotalAmount(false);
                break;

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[finalSaleInfo.stateTaxFlag]);

    useEffect(()=> {
        calculateStateTaxTotal(finalSaleInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[finalSaleInfo.stateTaxPrincipalAmt, finalSaleInfo.stateTaxExemptAmt]);

    useEffect(()=> {
        calculateFederalTaxTotal(finalSaleInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[finalSaleInfo.federalTaxPrincipalAmt, finalSaleInfo.federalTaxExemptAmt, finalSaleInfo.altMinTaxAmt]);

    const saveInterestTaxExempt = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleInfo.finalSaleId,
                "finalSale": {...finalSaleInfo}
            }
        };
    
        FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Tax Status/Exempt updated successfully!");
                props.updateFinalSaleDetails(result.data.responses[0]);
                props.sectionValidated('interestDebtExempt', true);
                props.openWidget('typeOfDebt');
            }
        });
    }
    const validateForm = (e) => {
        let form = e.target.closest('form#add-form');

        let formErrors = validateFormFields(form.elements);

        let finalSaleInfoArr = {...finalSaleInfo};
        if( !finalSaleInfoArr['federalTaxFlag'] || finalSaleInfoArr['federalTaxFlag'] === "" ) {
            formErrors['federalTaxFlag'] = "This field is required."
        }
        if(stateTaxTotal !== finalSaleInfoArr.principalSaleAmt) {
            formErrors['stateTaxTotal'] = "State tax total amount should be equal to the principal amount.";
        }
        if(federalTaxTotal !== finalSaleInfoArr.principalSaleAmt) {
            formErrors['federalTaxTotal'] = "Federal tax total amount should be equal to the principal amount.";
        }
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            saveInterestTaxExempt();
        }
    }

    const handleUnderStateLawChange = (e) => {
        const {name,value} = e.target;
        setFinalSaleInfo(prevState=>({
            ...prevState, [name]: value
        }))
    }
    const handleUnderFederalLawChange = (e) => {
        setShowFederalTaxAmount(false);
        setShowFederalTaxExemptAmount(false);
        setShowAltMinTaxAmount(false);

        let selectionArr = [];
        e.map(x=>{
           return selectionArr.push(x.value);
        });
        
        var one = ['T', 'E', 'A'];
        var resultArr = one.filter(function(val){
            return !selectionArr.find(function(obj){
                return val===obj;
            });
        });

        let fedExemptAmt = finalSaleInfo.federalTaxExemptAmt;
        let fedPrincipalAmt = finalSaleInfo.federalTaxPrincipalAmt;
        let altMinAmt = finalSaleInfo.altMinTaxAmt;
        resultArr.forEach(k => {
            if(k === 'E') {
                fedExemptAmt = "";
            } else if(k === 'T') {
                fedPrincipalAmt = "";
            } else if(k === 'A'){
                altMinAmt = "";
            }
        })


        setFinalSaleInfo(prevState=>({
            ...prevState, 
            federalTaxFlag: selectionArr.join(''),
            federalTaxExemptAmt: fedExemptAmt,
            federalTaxPrincipalAmt: fedPrincipalAmt,
            altMinTaxAmt: altMinAmt
        }))
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        const taxableInfo = {...finalSaleInfo};
        taxableInfo[name] = value;
        setFinalSaleInfo(taxableInfo);
    }

    const handleDate = (e, key) => {
        setFinalSaleInfo(prevState=>({
            ...prevState, 
            [key]: e
        })
        )
    }
    const handleInterestChange = (e) => {
        const {name, value} = e.target;
        const taxableInfo = {...finalSaleInfo};
        if(/^-?\d*[.,]?\d{0,3}$/.test(value)) {
            taxableInfo[name] = value;
        }
        setFinalSaleInfo(taxableInfo); 
    }
    const handleInterestTypeChange = (e) => {
        let value = "";
        if(e.target.checked) {
            value = "Y";
        } else {
            value = "N";
        }
        const taxableInfo = {...finalSaleInfo};
        taxableInfo[e.target.name] = value;
        setFinalSaleInfo(taxableInfo);

        switch(e.target.name) {
            case "nicInterestTypeInd":
                setNicDisabled(!nicDisabled);
                break;
            case "ticInterestTypeInd":
                setTicDisabled(!ticDisabled);
                break;
            case "varInterestTypeInd":
                setVarDisabled(!varDisabled);
                break;
            case "otherInterestTypeInd":
                setOtherDisabled(!otherDisabled);
                break;
            default:
                break;
        }
    }

    const calculateStateTaxTotal = (finalSaleInfo) => {
       const stateTaxPriAmt = ( finalSaleInfo['stateTaxPrincipalAmt'] && finalSaleInfo['stateTaxPrincipalAmt'] !== "" ) ?
           typeof finalSaleInfo['stateTaxPrincipalAmt'] === 'string' ? finalSaleInfo['stateTaxPrincipalAmt'].replace(/,/g,'') : finalSaleInfo['stateTaxPrincipalAmt']
        : 0;

        const stateTaxExptAmt = ( finalSaleInfo['stateTaxExemptAmt'] && finalSaleInfo['stateTaxExemptAmt'] !== "" ) ?
           typeof finalSaleInfo['stateTaxExemptAmt'] === 'string' ? finalSaleInfo['stateTaxExemptAmt'].replace(/,/g,'') : finalSaleInfo['stateTaxExemptAmt']
        : 0;

        const stateTaxtotal = parseFloat(stateTaxPriAmt) + Number(stateTaxExptAmt);
        setStateTaxTotal(stateTaxtotal);
    }
    const calculateFederalTaxTotal = (finalSaleInfo) => {
        const federalTaxPriAmt = ( finalSaleInfo['federalTaxPrincipalAmt'] && finalSaleInfo['federalTaxPrincipalAmt'] !== "" ) ?
        typeof finalSaleInfo['federalTaxPrincipalAmt'] === 'string' ? finalSaleInfo['federalTaxPrincipalAmt'].replace(/,/g,'') : finalSaleInfo['federalTaxPrincipalAmt']
        : 0;

        const federalTaxExptAmt = ( finalSaleInfo['federalTaxExemptAmt'] && finalSaleInfo['federalTaxExemptAmt'] !== "" ) ?
        typeof finalSaleInfo['federalTaxExemptAmt'] === 'string' ? finalSaleInfo['federalTaxExemptAmt'].replace(/,/g,'') : finalSaleInfo['federalTaxExemptAmt']
        : 0;

        const federalAltMinAmt = ( finalSaleInfo['altMinTaxAmt'] && finalSaleInfo['altMinTaxAmt'] !== "" ) ?
        typeof finalSaleInfo['altMinTaxAmt'] === 'string' ? finalSaleInfo['altMinTaxAmt'].replace(/,/g,'') : finalSaleInfo['altMinTaxAmt']
        : 0;

        const federalTaxtotal = parseFloat(federalTaxPriAmt) + Number(federalTaxExptAmt) + Number(federalAltMinAmt);
        setFederalTaxTotal(federalTaxtotal);
    }

    const handleCdiacNbrChange = (e, i) => {
        const selectedValue = e.length ? e[0].cdiacNbr : "";
        let cidacNumArr = [...issueSeriesCdiacNbrs];
        cidacNumArr[i].seriesCdiacNbr = selectedValue;
        setIssueSeriesCdiacNbrs(cidacNumArr);
    }

    const saveIssueSeriesCdiac = async (i) => {
        let cidacNumArr = [...issueSeriesCdiacNbrs];
        const cdiacNbrObj = cidacNumArr[i];
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "postIssueSeries",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueSeries" : {
                    'seriesCdiacNbr': cdiacNbrObj.seriesCdiacNbr,
                    'cdiacNbr' : finalSaleInfo.cdiacNbr,
                    'createUser': loginUser
                }
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast(result.data.errors[0].message)
            }
            else{
                const issueSeriesId = result.data.responses[0];
                cdiacNbrObj['issueSeriesId'] = issueSeriesId;
                setIssueSeriesCdiacNbrs(cidacNumArr);
                toast.success("CDIAC # saved successfully!");
            }
        });
    }

    const deleteIssueSeriesCdiac = async (i) => {
        let cidacNumArr = [...issueSeriesCdiacNbrs];
        const IssueSeriesId =  cidacNumArr[i].issueSeriesId;
        if ( IssueSeriesId ) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "deleteIssueSeries",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": IssueSeriesId
                }
            };
        
            await IssueCdiacNumberService.deleteIssueSeries(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast(result.data.errors[0].message)
                }
                else{
                    cidacNumArr.splice(i,1);
                    setIssueSeriesCdiacNbrs(cidacNumArr);
                    toast.success("CDIAC # deleted successfully!");
                }
            });
        } else {
            cidacNumArr.splice(i,1);
            setIssueSeriesCdiacNbrs(cidacNumArr);
        }
    }

    const addCdiacNumber = () => {
        setIssueSeriesCdiacNbrs(prevState=>([
            ...issueSeriesCdiacNbrs, {seriesCdiacNbr: ''}
        ]))
    }

    return (
        <Card>
        <Form className="form" id="add-form">
            <Row>
            <Col md={4}><label><span className="required">*</span>Under State Law:</label></Col>
            <Col md={4}>
                <Form.Select name="stateTaxFlag" onChange={e=>handleUnderStateLawChange(e)} defaultValue={finalSaleInfo['stateTaxFlag']} data-validations="required">
                <option value="">Select</option>
                <option value="Y">Taxable</option>
                <option value="N">Tax-Exempt</option>
                <option value="B">Both</option>
                </Form.Select>
                {formErrors['stateTaxFlag'] && <p className="error">{formErrors['stateTaxFlag']}</p>}
            </Col>
            {formErrors['stateTaxTotal'] && <Col><p className="error">{formErrors['stateTaxTotal']}</p></Col>}
            </Row>

            <div id="stateTax">
                <Row>
                    {showStateTaxAmount && <Col md={6}>
                    <div className="control">
                        <span className="required">*</span>
                        <CurrencyInput
                        label="State Taxable Principal Amount" 
                        name="stateTaxPrincipalAmt" 
                        value={finalSaleInfo.stateTaxPrincipalAmt}
                        onChange={(e)=>handleChange(e)}
                        validations={["required"]}
                        style={formErrors['stateTaxPrincipalAmt'] && {border: 'solid 1px red'}}
                        error={formErrors && formErrors['stateTaxPrincipalAmt']}/>
                    </div></Col>
                    }
                    {showStateTaxExemptAmount && <Col md={6}>
                        <div className="control">
                            <span className="required">*</span>
                            <CurrencyInput
                            label="State Tax-Exempt Principal Amount" 
                            name="stateTaxExemptAmt" 
                            value={finalSaleInfo.stateTaxExemptAmt}
                            onChange={(e)=>handleChange(e)}
                            validations={["required"]}
                            style={formErrors['stateTaxExemptAmt'] && {border: 'solid 1px red'}}
                            error={formErrors && formErrors['stateTaxExemptAmt']}/>
                        </div></Col>
                    }
                </Row>
                <Row>
                    {showStateTaxTotalAmount && <Col md={6}>
                        <div className="control">
                            <span className="required">*</span>
                            <CurrencyInput
                            label="Total Principal" 
                            id="stateTotal"
                            name="stateTaxTotalAmount" 
                            readOnly
                            value={stateTaxTotal}
                        />
                        </div></Col>
                    }
                </Row>
            </div>
            
            <Row>
            <Col md={4}><label><span className="required">*</span>Under Federal Law (Multiple-Select):</label></Col>
            <Col md={4}>
                <Select 
                value={defaultFederalTaxSelection}
                options={federalTaxOptions}
                isMulti
                onChange={e=>handleUnderFederalLawChange(e)}
                />
                {formErrors['underFederalLaw'] && <p className="error">{formErrors['underFederalLaw']}</p>}
            </Col>
            {formErrors['federalTaxTotal'] && <Col><p className="error">{formErrors['federalTaxTotal']}</p></Col>}

            </Row>

            <div id="federalTax">
                <Row>{showFederalTaxAmount && <Col md={6}>
                    <div className="control">
                        <span className="required">*</span>
                        <CurrencyInput
                        label="Federal Taxable Principal Amount" 
                        name="federalTaxPrincipalAmt" 
                        value={finalSaleInfo.federalTaxPrincipalAmt}
                        onChange={(e)=>handleChange(e)}
                        validations={["required"]}
                        style={formErrors['federalTaxPrincipalAmt'] && {border: 'solid 1px red'}}
                        error={formErrors && formErrors['federalTaxPrincipalAmt']}/>
                    </div></Col>
                }
                {showFederalTaxExemptAmount && <Col md={6}>
                    <div className="control">
                        <span className="required">*</span>
                        <CurrencyInput
                        label="Federal Tax-exempt (No AMT) Principal Amount" 
                        name="federalTaxExemptAmt" 
                        value={finalSaleInfo.federalTaxExemptAmt}
                        onChange={(e)=>handleChange(e)}
                        validations={["required"]}
                        style={formErrors['federalTaxExemptAmt'] && {border: 'solid 1px red'}}
                        error={formErrors && formErrors['federalTaxExemptAmt']}/>
                    </div></Col>
                }
                {showAltMinTaxAmount && <Col md={6}>
                    <div className="control">
                        <span className="required">*</span>
                        <CurrencyInput
                        label="Federal Tax-exempt (AMT) Principal Amount" 
                        name="altMinTaxAmt" 
                        value={finalSaleInfo.altMinTaxAmt}
                        onChange={(e)=>handleChange(e)}
                        validations={["required"]}
                        style={formErrors['altMinTaxAmt'] && {border: 'solid 1px red'}}
                        error={formErrors && formErrors['altMinTaxAmt']}/>
                    </div></Col>
                }
                </Row>
            </div>

            <Row>
            {showFederalTaxTotalAmount && <Col md={6}>
                <div className="control">
                    <CurrencyInput
                    label="Total Principal" 
                    name="federalTaxTotalAmount" 
                    readOnly
                    value={federalTaxTotal}
                    />
                </div></Col>
            }
            </Row>

            <Row>
               <label>To link "Split" issues together, please select CDIAC # based on tax status: {" "}
                <TooltipComponent
                    title="Click me for more info."
                    id="redemption-date"
                    placement="right">
                        <AiFillQuestionCircle onClick={()=>setShowCdiacInstructionsModal(true)} className="help-icon heading-help-icon"/>
                </TooltipComponent>

                {showCdiacInstructionsModal && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowCdiacInstructionsModal(false)}>
                        <Modal.Body>
                            <div>
                               <p>The costs of issuance may be reported on EITHER CDIAC# that have been linked with different designated tax statuses but should not exceed the total aggregate amount of the costs of issuance for the linked CDIAC#s. For example, if the total aggregate amount for bond counsel services is $50,000, the amounts allocated to each linked CDIAC# must equal $50,000.</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="custom-button-secondary btn-secondary" onClick={()=>setShowCdiacInstructionsModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }</label>
                <Col md={12} >
                    <table>
                        <thead>
                            <tr>
                                <td>CDIAC #</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                        {issueSeriesCdiacNbrs && issueSeriesCdiacNbrs.map((item, i) => {
                            return <tr key={i}>
                                <td className="col-md-6" style={{paddingLeft: '0'}}>
                                    <Typeahead
                                            placeholder="Choose CDIAC Number"
                                            id="cdiacNbr"
                                            labelKey="cdiacNbr"
                                            onChange={e=>handleCdiacNbrChange(e, i)}
                                            options={cdiacNumbersList}
                                            selected={cdiacNumbersList.filter(option => 
                                                option.cdiacNbr === item.seriesCdiacNbr)}
                                    />
                                </td>
                                <td className="tblCol col-md-6"> 
                                   {!item.issueSeriesId && <button type="button" className="custom-button" disabled = { !item.seriesCdiacNbr && true} onClick={()=>saveIssueSeriesCdiac(i)}>Save</button>}{" "}                          
                                    <button type="button" className="custom-button" onClick={()=>deleteIssueSeriesCdiac(i)}>Delete</button>                               
                                </td>
                            </tr>
                        }) }
                        </tbody>
                    </table>
                    
                </Col>  
            </Row>
            <br />
            <button type="button" className="custom-button" onClick={()=>addCdiacNumber()}>Add CDIAC #</button>

            <div>
                <label><span className="required">*</span>Interest Type</label>
                <div>
                <Row>
                    <div className="dflex">
                        <Form.Check type="checkbox" id="nic" label="Net Interest Cost (NIC):" name="nicInterestTypeInd" className="check-box" onChange={e=>handleInterestTypeChange(e)} checked={finalSaleInfo.nicInterestTypeInd === "Y"} data-validations="required"/>
                        <Col md={4} className="dflex control">
                            <input 
                            name="nicInterestCostRate" 
                            value={finalSaleInfo.nicInterestCostRate ? finalSaleInfo.nicInterestCostRate : "" } 
                            data-validations={!nicDisabled && ["required"]} 
                            maxLength="7"
                            placeholder="XXX.XXX"
                            onChange={(e)=>handleInterestChange(e)} 
                            disabled={nicDisabled}/>
                        </Col>
                        {formErrors['nicInterestCostRate'] && <p className="error">{formErrors['nicInterestCostRate']}</p>}
                    </div>
                </Row>
                <Row>
                    <div className="dflex">
                        <Form.Check type="checkbox" id="tic" label="True Interest Cost (TIC):" name="ticInterestTypeInd" className="check-box" onChange={e=>handleInterestTypeChange(e)} checked={finalSaleInfo.ticInterestTypeInd === "Y"} data-validations="required"/>
                        <Col md={4} className="dflex control">
                            <input 
                            name="ticInterestCostRate"
                            data-validations={!ticDisabled && ["required"]}
                            maxLength="7"
                            placeholder="XXX.XXX"
                            value={finalSaleInfo.ticInterestCostRate ? finalSaleInfo.ticInterestCostRate : ""}
                            onChange={(e)=>handleInterestChange(e)} 
                            disabled={ticDisabled}
                            />
                        </Col>
                        {formErrors['ticInterestCostRate'] && <p className="error">{formErrors['ticInterestCostRate']}</p>}
                    </div>
                </Row>
                <Row>
                    <div className="dflex">
                        <Form.Check type="checkbox" id="variable" label="Variable:" name="varInterestTypeInd" className="check-box" onChange={e=>handleInterestTypeChange(e)} checked={finalSaleInfo.varInterestTypeInd === "Y"} data-validations="required" />
                        <Col md={4} className="dflex control">
                            <input name="varInterestCostRate"
                            data-validations={!varDisabled && ["required"]}
                            maxLength="7"
                            placeholder="XXX.XXX"
                            value={finalSaleInfo.varInterestCostRate ? finalSaleInfo.varInterestCostRate : ""}
                            onChange={(e)=>handleInterestChange(e)} 
                            disabled={varDisabled}
                            />
                        </Col>
                        {formErrors['varInterestCostRate'] && <p className="error">{formErrors['varInterestCostRate']}</p>}
                    </div>
                </Row>
                <Row>
                    <div>
                    <div className="dflex">
                        <Form.Check type="checkbox" id="other" label="Other:"name="otherInterestTypeInd" className="check-box" onChange={e=>handleInterestTypeChange(e)} checked={finalSaleInfo.otherInterestTypeInd === "Y"} data-validations="required"/>
                        <Col md={4} className="control">
                            <input name="otherInterestCostRate"
                            data-validations={!otherDisabled && ["required"]}
                            maxLength="7"
                            placeholder="XXX.XXX"
                            value={finalSaleInfo.otherInterestCostRate ? finalSaleInfo.otherInterestCostRate : ""}
                            onChange={(e)=>handleInterestChange(e)} 
                            disabled={otherDisabled}
                            />
                        </Col>
                        {formErrors['otherInterestCostRate'] && <p className="error">{formErrors['otherInterestCostRate']}</p>}
                        </div>
                        <Col className="flex">
                            <label>Other Description:</label>
                            <Form.Control type="text" name="otherInterestText" 
                            onChange={e=>handleChange(e)} value={finalSaleInfo.otherInterestText} 
                            disabled={otherDisabled} data-validations={!otherDisabled && "required"} maxLength="40"/>
                            {formErrors['otherInterestText'] && <p className="error">{formErrors['otherInterestText']}</p>}
                        </Col>
                    </div>
                </Row>
                </div>
            </div>

            <div className="mb-10">
                <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Capital Appreciation Debt:</label>
                <br />
                <Form.Check
                        inline
                        label="Yes"
                        name="capitalAppreciationInd"
                        value="Y"
                        type="radio"
                        id="capitalAppreciationInd-yes"
                        data-validations="required"
                        checked={finalSaleInfo.capitalAppreciationInd === "Y"}
                        onChange={(e)=>handleChange(e)}
                    />
                <Form.Check
                        inline
                        label="No"
                        name="capitalAppreciationInd"
                        value="N"
                        type="radio"
                        id="capitalAppreciationInd-no"
                        data-validations="required"
                        checked={finalSaleInfo.capitalAppreciationInd === "N"}
                        onChange={(e)=>handleChange(e)}
                    />
                {formErrors['capitalAppreciationInd'] && <p className="error">{formErrors['capitalAppreciationInd']}</p>}
            </div>

            {finalSaleInfo.capitalAppreciationInd === 'Y' &&
                <><Row>
                    <Col md={6}>
                        <div className="control">
                            <CurrencyInput placeholder="0.00" name="cabDenominationAmt" value={finalSaleInfo.cabDenominationAmt} label="CAB Amount" onChange={(e)=>handleChange(e)}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <MaxCurrencyInput 
                            placeholder="0.00" 
                            label="CAB Maturity Value" 
                            name="cabMaturityAmt" 
                            type="text" 
                            value={finalSaleInfo.cabMaturityAmt ? finalSaleInfo.cabMaturityAmt  : ""}
                            onChange={(e)=>handleChange(e)} 
                            validations={["max:9999999999999.99"]}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <label>CAB Maturity Date</label>
                            <span>
                            <DatePicker 
                            selected={finalSaleInfo && finalSaleInfo.cabMaturityDate ? finalSaleInfo.cabMaturityDate : null}
                            dateFormat="M/d/yyyy"
                            name="cabMaturityDate"
                            maxDate= {new Date(9999, 12, 31)}
                            onChange={(e) => handleDate(e, "cabMaturityDate")} 
                            error={formErrors && formErrors['cabMaturityDate']}                                
                            />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                            <input type="hidden" value={finalSaleInfo.cabMaturityDate} name="cabMaturityDate" />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <CurrencyInput placeholder="0.00" name="cabAccretedIntAmt" value={finalSaleInfo.cabAccretedIntAmt} label="CAB Accreted Interest" onChange={(e)=>handleChange(e)}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <CurrencyInput placeholder="0.00" name="cabConversionAmt" value={finalSaleInfo.cabConversionAmt} label="CAB Conversion Amount" onChange={(e)=>handleChange(e)}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <CurrencyInput placeholder="0.00" name="cabConversionValueAmt" value={finalSaleInfo.cabConversionValueAmt} label="CAB Conversion Value" onChange={(e)=>handleChange(e)}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <label>CAB Maturity Date</label>
                            <span>
                            <DatePicker 
                            selected={finalSaleInfo && finalSaleInfo.cabConversionDate ? finalSaleInfo.cabConversionDate : null}
                            dateFormat="M/d/yyyy"
                            name="cabConversionDate"
                            maxDate= {new Date(9999, 12, 31)}
                            onChange={(e) => handleDate(e, "cabConversionDate")} 
                            error={formErrors && formErrors['cabConversionDate']}                                
                            />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                            <input type="hidden" value={finalSaleInfo.cabConversionDate} name="cabConversionDate" />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <label>CAB Conversion Maturity Date</label>
                            <span>
                            <DatePicker 
                            selected={finalSaleInfo && finalSaleInfo.cabConversionMaturityDate ? finalSaleInfo.cabConversionMaturityDate : null}
                            dateFormat="M/d/yyyy"
                            name="cabConversionMaturityDate"
                            maxDate= {new Date(9999, 12, 31)}
                            onChange={(e) => handleDate(e, "cabConversionMaturityDate")} 
                            />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                            <input type="hidden" value={finalSaleInfo.cabConversionMaturityDate} name="cabConversionMaturityDate" />
                        </div>
                    </Col>

                </Row>
                <div>
                <label style={{marginBottom: '0.5rem'}}>CAB Callable:</label>
                <Form.Check
                        inline
                        label="Yes"
                        id="cabCallableInd_yes"
                        name="cabCallableInd"
                        value="Y"
                        type="radio"
                        checked={finalSaleInfo.cabCallableInd === "Y"}
                        onChange={(e)=>handleChange(e)} 
                    />
                <Form.Check
                        inline
                        label="No"
                        id="cabCallableInd_no"
                        name="cabCallableInd"
                        value="N"
                        type="radio"
                        checked={finalSaleInfo.cabCallableInd === "N"}
                        onChange={(e)=>handleChange(e)} 
                    />
                {formErrors['cabCallableInd'] && <p className="error">{formErrors['cabCallableInd']}</p>}
            </div></>
            }

                        
            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
            </div>
        </Form> 
        </Card>
    )
}

export default InterestDebtExempt
