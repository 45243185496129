import React, {useState} from 'react';
import { Form, Row, Col, Modal} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import {validateFormFields} from '../../utils';

const DashboardSearchModal = ({onModalClose, handleSearch}) => {
    const [formErrors, setFormErrors] = useState({});
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 12);

    const defaultToDate = new Date();
    const defaultFromDate = fromDate;

    const [searchObj, setSearchObj] = useState({
      cdiacNo:'',
      issueName: '',
      endDate: defaultToDate,
      beginDate: defaultFromDate,
      retiredFlag: 'N'
    })

    const handleChange = (e, k) => {
      if(k === 'cdiacNo') {
        setSearchObj({...searchObj, 
          cdiacNo : e.target.value,
          issueName: '',
          retiredFlag: null
        })
      } else if(k === 'issueName') {
        setSearchObj({...searchObj, [k] : e.target.value })
      } else if (k === "retiredFlag") {
        const val =  e.target.checked ? 'N' : 'Y';
        setSearchObj({...searchObj, [k] : val })
      } else {
        setSearchObj({...searchObj, [k] : e })
      }
    }

    const handleSubmit = (e) => {
      const form = e.target.closest('form#search-modal');
      let formErrors = validateFormFields(form.elements);
      if(Object.keys(formErrors).length > 0) {
        setFormErrors(formErrors);
      } else {
        handleSearch({...searchObj});
      }
    }

    const handleClearSearch = () => {
      const currSearchObj = {...searchObj, 
        cdiacNo:'',
        issueName: '',
        endDate: defaultToDate,
        beginDate: defaultFromDate,
        retiredFlag: 'N'
      }
      setSearchObj(currSearchObj)
    }

    return (
      <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
      <Modal.Header closeButton>Search Criteria</Modal.Header>
      <Modal.Body>
        <form id="search-modal">
          <div className="control">
            <label>CDIAC #:</label>
            <input type="text" value={searchObj.cdiacNo} onChange={(e)=>handleChange(e, 'cdiacNo')}/>
          </div>
          <div className="control">
            <label>Issuer Name Contains:</label>
            <input type="text" value={searchObj.issueName} onChange={(e)=>handleChange(e, 'issueName')} disabled={searchObj.cdiacNo !== ""}/>
          </div>
          <div className="">
            <label>Sale Date Range: </label>
            <Row>
              <Col md={6} className="control"><span className="required"> * </span> From
              <span>
                <DatePicker 
                key="1"
                selected={searchObj.beginDate}
                dateFormat="M/d/yyyy" 
                maxDate= {new Date(9999, 12, 31)}
                onChange={(e)=>handleChange(e, 'beginDate')} 
                disabled={searchObj.cdiacNo !== ""}/>
                <FaCalendarAlt className="date-picker-icon"/>
              </span>
              <input type="hidden" data-validations={["required"]}  value={searchObj.beginDate ? new Date(searchObj.beginDate) : ""} name="beginDate" />    
              { formErrors && formErrors['beginDate'] && <p className="error">{ formErrors['beginDate'] }</p>}
              </Col>

              <Col md={6} className="control"><span className="required"> * </span> To
              <span>
                <DatePicker 
                key="2"
                selected={searchObj.endDate}
                onChange={(e)=>handleChange(e, "endDate")}
                dateFormat="M/d/yyyy" 
                maxDate= {new Date(9999, 12, 31)} 
                disabled={searchObj.cdiacNo !== ""}/>
                <FaCalendarAlt className="date-picker-icon"/>
              </span>
              <input type="hidden" data-validations={["required"]}  value={searchObj.endDate ? new Date(searchObj.endDate) : ""} name="endDate" />    
              { formErrors && formErrors['endDate'] && <p className="error">{ formErrors['endDate'] }</p>}
              </Col>
            </Row>
          </div>
          <div>
            <Form.Check 
            inline 
            type="checkbox" 
            id="retiredFlag" 
            label="Ongoing Reportable?" 
            checked={searchObj.retiredFlag === 'N'} 
            onChange={(e)=>handleChange(e, 'retiredFlag')} 
            disabled={searchObj.cdiacNo !== ""}/>
          </div>
          <div>
            <button type="button" className="link-button" onClick={()=>handleClearSearch()}>Clear Search </button>
          </div>
          <div className="btn-div">
            <div className="fright">
              <button type="button" className="custom-button-secondary btn-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
              <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Search</button>
            </div>
          </div>

          </form>
          </Modal.Body>

          
        </Modal>
    )
}

export default DashboardSearchModal
