import CommonService from "./CommonService";


const generatePdf = (obj) => {
    return CommonService.apicall(obj)
};

const HtmlPdfService = {
    generatePdf
};


export default HtmlPdfService;

