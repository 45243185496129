import { useState, useContext, useCallback, useEffect } from 'react'
import Card from '../ui/Card'
import { Spinner, Modal} from 'react-bootstrap'
import classes from '../documentUpload/documentUpload.module.css'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import BlobService from '../../services/BlobService'
import { UserContext } from '../../UserContext'
import ReportOfProposedDebtService from '../../services/ReportOfProposedDebtService'
import CommonService from '../../services/CommonService'
import { VscTrash } from 'react-icons/vsc'
import swal from 'sweetalert'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const DocUpload = (props) => {
    const {melloRoosDetails} = props;
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [file, setDocuments] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const loginUser = useContext(UserContext);
    const [proposedDebtDocuments, setProposedDebtDocuments] = useState([]);

    const handleDeleteFile = (data) => {    
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this document!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                setShowSpinner(true);
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "deleteBlob",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: {
                        "issueDocId" : data.issueDocId             
                    },
                  };
              
                  BlobService.deleteFile(obj)
                    .then((res) => res)
                    .then((result) => {
                        if(result.data.errors && result.data.errors.length > 0){
                            setShowSpinner(false);
                            toast.error(result.data.errors[0].message)
                        }
                        else{
                            setShowSpinner(false);
                            toast.success("Document removed successfully!");
                            fetchData();
                        }
                        
                    });
            } 
          });
        
      }

    const onChangeHandler = (e) => {
        let file = e.target.files[0];
        if (file.size > 100000000) {
            toast.error("File size exceeds a maximum limit of 100 MB. Please try with a small file size or contact CDIAC.");
            e.target.value = null;

        }
        else if (!file.type.match('application/pdf')) {
            toast.error("Only PDF files can be uploaded");
            e.target.value = null;
        }
        else {
            setDocuments(file);

        }

    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
      }, []);

    const fetchData = useCallback(() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueDocumentsByIssueId",
            hdrStruct: null,
            queryParam: null,
            reqData: { "id": melloRoosDetails.melloRoosId,
                "reportType" : "MLR"},
          };
      
          ReportOfProposedDebtService.getProposedDebtDocuments(obj)
            .then((res) => res)
            .then((result) => {
              setProposedDebtDocuments(result.data.responses[0]);
              props.updateIssueDocuments(result.data.responses[0]);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [melloRoosDetails.melloRoosId])

    const validateForm = () => {
        props.openWidget('foreclosure');
    }
    const handleUpload = (e) => {
        setFormErrors({});
        if (file) {
            setShowSpinner(true);
            let formData = new FormData();

            formData.append('file', file);
            formData.append('docTypeCodeId', 0);
            formData.append('createUser', loginUser);
            formData.append('finalSaleId', "");
            formData.append('mimeType', "pdf");
            formData.append('melloRoosId', melloRoosDetails.melloRoosId);
            formData.append('fileName', file.name);

            BlobService.uploadFile(formData)
                .then((res) => {
                    if (res.data.errors && res.data.errors.length > 0) {
                        setShowSpinner(false);
                        toast.error(res.data.errors[0].message);
                    }
                    else {
                        setShowSpinner(false);
                        e.target.value = null;
                        toast.success("File uploaded successfully!");
                        fetchData()
                    }

                }) // Handle the response from backend here
                .catch((err) => { }); // Catch errors if any
         } 
    }

    const handleViewDocument = (e, issueDocId) => {
        setShowSpinner(true);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getPdfDocumentFromBlob",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { 
                "issueDocId" : issueDocId,
                "type" : "issue_document"
            }
        };
        ReportOfProposedDebtService.getHistory(obj)
          .then((res) => {
            var base64EncodedPDF = res.data.responses[0];
            var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
            function base64ToArrayBuffer(base64) {
                let binaryString = window.atob(base64);
                let binaryLen = binaryString.length;
                var bytes = new Uint8Array(binaryLen);
                for (var i = 0; i < binaryLen; i++) {
                    var ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }
                return bytes;
            }
            setShowSpinner(false);
            var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
            var url = window.URL.createObjectURL(blob);
            window.open(url);
        });
    }

    const fileNameFormatter = (row) => { 
        return ( 
            <div style={{lineHeight: "normal" }}>
                <button
                    type="button"
                    className="link-button dk-blue-color"
                    onClick={(e)=>handleViewDocument(e, row.issueDocId)}>{row.fileName}
                </button>
            </div> 
        ); 
    }

    const viewFormatter = (row) => { 
        return ( 
            <div style={{lineHeight: "normal" }}>
                <VscTrash className="button" onClick={(e) => handleDeleteFile(row)}/>
            </div> 
        ); 
    }

    const columns = [{
        dataField: 'documentDesc',
        text: 'Document Type',
        sort: true
      }, {
        dataField: 'fileName',
        text: 'Document Name',
        formatter: fileNameFormatter,
        sort: true,
      }, {
        dataField: 'createDatetime',
        text: 'File Upload Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['createDatetime'])
      },
      { 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter,}
    ];
    return (
        <Card>
            <div className="form">
            <h3>Delinquency Parcel Reporting <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

            {showInstructionsModal && 
            <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                <Modal.Body>
                    <div>
                    You Must Upload the current tax roll delinquent parcel detail report that states the number of parcecls that are delinquent with respect to their special tax payments.
                            The amount that each parcel is delinquent, the total amount of special taxes due on the delinquent parcels, the length of time that each has been delinquent before submitting the Mello Roos YFSR.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>
            }</h3>

            <div>
                <i>
                    <ul  className={classes.docrequirements}>
                        <li>(1) YOU MUST UPLOAD THE CURRENT TAX ROLL DELINQUENT PARCEL DETAIL REPORT THAT STATES THE NUMBER OF PARCELS THAT ARE DELINQUENT WITH RESPECT TO THEIR SPECIAL TAX PAYMENTS,
                                    THE AMOUNT THAT EACH PARCEL IS DELINQUENT, THE TOTAL AMOUNT OF SPECIAL TAXES DUE ON THE DELINQUENT PARCELS, THE LENGTH OF TIME THAT EACH HAS BEEN DELINQUENT BEFORE SUBMITTING THE MELLO-ROOS YFSR.</li>
                        <li>(2) Only PDF document are accepted.</li>
                        <li>(3) Maximum file size for each document is 100 MB. If your document is larger than 100 MB, please contact CDIAC</li>
                        <li>(4) Please ensure that all sensitive/confidential information is redacted</li>
                        <li>(5) Do not Upload Checks or Invoices</li>
                        <li>(6) Only one document can be uploaded</li>
                    </ul>
                </i>
            </div>

            <div className="mb-20">
                <label className="mb-10">Choose a document to upload</label>
                    <div className="mb-10">
                        <input type="file" className="form-control" name="file" accept="application/pdf" onChange={(e)=>onChangeHandler(e)}/>
                    </div>
                    {formErrors['inputFile'] && <p className="error">{formErrors['inputFile']}</p>}
                    <div className="mb-10">
                        <button id="color-2" type="button" onClick={(e) => handleUpload(e)}>Upload File</button>
                    </div>
            </div>  

            {showSpinner && <Modal show={true} className="modal bd-example-modal-lg" data-backdrop="static">
                <div className="modal-dialog modal-sm">
                        <Spinner animation="border"/>
                </div>
            </Modal>
            }

<div className="mb-20">
                <label className="mb-10">Document Already Uploaded</label>
                <DataGrid
                dataSource={proposedDebtDocuments}
                sortField='createDatetime'
                emptyDataMessage='No data available to display'
                showPaginator={false}
                showQuickSearch={false}
                >
                {columns.map((column, i) => {
                    return (
                    <Column 
                    key={i}
                    field={column.dataField}
                    header={column.text}
                    sortable={column.sort} 
                    body={column.formatter}
                    hidden={column.hidden}
                    />)
                    }) 
                } 
                </DataGrid>
            </div> 

            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Save & Next</button>
            </div>

            </div>
        </Card>
    )
}

export default DocUpload
