import CommonService from "./CommonService";

const getAllCodes = (obj) => {
    return CommonService.apicall(obj)

};

const addCode = (obj) => {
    return CommonService.apicall(obj)

};

const updateCode = (obj) => {
    return CommonService.apicall(obj)

};

const CodeMaintenanceService = {
    getAllCodes,
    addCode,
    updateCode
};


export default CodeMaintenanceService;