import axios from "axios";
import CommonService from "./CommonService";
import { configManager } from "../configManager";

const uploadFile = (obj) => {
    return apicall(obj)

};

const deleteFile = (obj) => {
    return CommonService.apicall(obj)

};

const BlobService = {
    uploadFile,
    deleteFile
};


export default BlobService;

function apicall(obj) {
    const idToken = localStorage.getItem("token");
    const headers = {
        'Authorization': `Bearer ${idToken}`,
        'AppId': 'Internal'
    };
    return axios({
        method: "post",
        headers: headers,
        url: `${configManager.fileUploadApiUrl}/UploadFile`,
        data: obj
    });
}