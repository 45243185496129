import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditCodeForm from './editCodeForm'
import AddCodeForm from './addCodeForm'
import CountyMaintenanceService from "../../services/CountyMaintenanceService";
import Spinner from 'react-bootstrap/Spinner';
import DataGrid from '../GenericComponents/DataGrid';
import { Column } from 'primereact/column';
import CommonService from '../../services/CommonService';

const CodeDetails = (props) => {
  const {cdiacCode, cdiacCodeVal} = props.cdiacValues;
  const [openEditCodeModal, setOpenModal] = useState(false);
  const [openAddCodeModal, setOpenAddCodeModal] = useState(false);
  const [editCodeInfo, setEditCodeInfo] = useState([])
  const [codes, setCounties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const viewFormatter = (row) => {
    return (
      <div
        style={{
          textAlign: "center",
          lineHeight: "normal"
        }}>
        <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e) => onRowClick(e, row)} />
      </div>
    );
  }

  const onRowClick = (e, row) => {
    // e.preventDefault();
    setOpenModal(true);
    setEditCodeInfo(row);
  }

  useEffect(() => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "GetLookups",
      hdrStruct: null,
      queryParam: null,
      reqData: { cdiacCode: cdiacCode, active: "Y" },
    };

    CountyMaintenanceService.getAllCounties(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setCounties(result.data.responses[key]);
          setIsLoading(false);
        });

      });
  }, [cdiacCode,  openAddCodeModal, openEditCodeModal]);

  const columns = [{
    dataField: 'cdiacCodeValueCode',
    text: 'Code',
    sort: true,
  }, {
    dataField: 'cdiacCodeValueDesc',
    text: 'Description',
    sort: true
  }, {
    dataField: 'cdiacCodeValueShortDesc',
    text: 'Brief Description',
    sort: true
  }, {
    dataField: 'createDatetime',
    text: 'Date Created',
    sort: true,
    formatter: (row) => CommonService.dateFormatter(row['createDatetime'])
  }, {
    dataField: 'lastUpdateDatetime',
    text: 'Last Updated',
    sort: true,
    formatter: (row) => CommonService.dateFormatter(row['lastUpdateDatetime'])
  }, {
    dataField: 'createUser',
    text: 'Created By',
    sort: true,
  }, {
    dataField: 'lastUpdateUser',
    text: 'Last Updated By',
    sort: true,
  }, {
    dataField: 'activeInd',
    text: 'Active',
    sort: true,
  }, {
    dataField: "",
    text: "",
    formatter: viewFormatter,
  }
  ];


  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" />}
      <div className="form">
          <DataGrid
          dataSource={codes}
          sortField='cdiacCodeValueCode'
          title={cdiacCodeVal}
          addNew={true}
          onAddNew={()=>setOpenAddCodeModal(true)}
          searchToolTip="Use the search feature to quickly retrieve the system code values"
          >
          {columns.map((column, i) => {
              return (
              <Column 
              key={i}
              field={column.dataField}
              header={column.text}
              sortable={column.sort} 
              body={column.formatter}
              />)
            }) 
          } 
          </DataGrid>
      </div>
      {openEditCodeModal && <EditCodeForm onModalClose={() => setOpenModal(false)} codeInfo={editCodeInfo} />}
      {openAddCodeModal && <AddCodeForm onModalClose={() => setOpenAddCodeModal(false)} codeInfo={cdiacCode}/>}
    </>
  )
}

export default CodeDetails
