import React, { useState, useContext } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import InvoiceService from "../../../../services/InvoiceService";
import Alert from "react-bootstrap/Alert";
import CommonService from "../../../../services/CommonService";
import { toast } from "react-toastify";
import { UserContext } from "../../../../UserContext";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from "../../../formElements/CurrencyInput";

const AddInvoicePayment = (props) => {
  const user = useContext(UserContext);
  const { onModalClose } = props;
  const [remainingDueAmount, setRemainingDueAmount] = useState(props.remainingDueAmount);

  const [stateValues, setStateValues] = useState({
    invoiceId: props.invoiceId,
    invoicePaymentId: 0,
    invoicePaymentAmt: 0,
    invoicePaymentDate: new Date(),
    checkNbr: "",
    rptCollectionNbr: "",
    rptCollectionDate: null,
    invoiceStatusFlag: props.invoiceStatusFlag,
    createUser: CommonService.getUserName(user),
    createDateTime: new Date(),
    lastUpdateUser: CommonService.getUserName(user),
    lastUpdateDatetime: new Date(),
  });

  const [errorMessage, setErrorMessage] = useState();

  const handleDateChange = (e, key) => {
    setStateValues((prevState) => ({
      ...prevState,
      [key]: e,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStateValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleAmountChange = (e) => {
    const { value } = e.target;
    const due = props.remainingDueAmount - parseFloat(value.replace(/\$|,/g, ''))
    setRemainingDueAmount(Math.ceil(due * 10) / 10);
  };

  const onSubmit = (data) => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "createInvoicePayment",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        invoicePayment: {
          invoicePaymentId: 0,
          invoiceId: data.invoiceId,
          invoicePaymentAmt: data.invoicePaymentAmt,
          invoicePaymentDate: data.invoicePaymentDate,
          checkNbr: data.checkNbr,
          rptCollectionNbr: data.rptCollectionNbr,
          rptCollectionDate: data.rptCollectionDate,
          invoiceStatusFlag: data.invoiceStatusFlag,
          createUser: data.createUser,
          createDateTime: data.createDateTime,
          lastUpdateUser: data.lastUpdateUser,
          lastUpdateDatetime: data.lastUpdateDatetime,
        },
        updateInvoiceStatus: (props.invoiceStatusFlag !== data.invoiceStatusFlag)
      },
    };
    InvoiceService.createInvoicePayment(obj).then((result) => {
      if (result.data.errors && result.data.errors.length > 0) {
        setErrorMessage(result.data.errors[0].message);
      } else {
        toast.success("Invoice Payment created successfully!");
        onModalClose();
      }
    });
  };

  return (
    <Modal show={true} backdrop="static" onHide={onModalClose}>
      <Modal.Header closeButton>
        <h3>Add Payment</h3>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert variant="danger">
            <p>{errorMessage}</p>
          </Alert>
        )}

        <form id="editPaymentForm">
          <Row>
          <Col md={6}>
              <label>Invoice #:</label>
              <Form.Control
                type="text"
                name="invoiceId"
                value={stateValues.invoiceId}
                onChange={(e) => handleChange(e)}
                maxLength="16"
                disabled
              />
            </Col>
            <Col md={6}>
              <div className="control">
                  <label>Status: </label>
                  <Form.Select name="invoiceStatusFlag" defaultValue={stateValues.invoiceStatusFlag} onChange={(e)=>handleChange(e)}>
                    <option value="OP">Open</option>
                    <option value="PE">Pending</option>
                    <option value="PD">Paid</option>
                    <option value="CA">Cancelled</option>
                  </Form.Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <div className="control">
              <CurrencyInput
              label="Invoice Payment Amount" 
              name="invoicePaymentAmt" 
              defaultValue={stateValues.invoicePaymentAmt} 
              onBlur={handleChange}
              onChange={(e)=>handleAmountChange(e)}
              />
              </div>
            </Col>

            <Col md={6}>
            <div className="control">
              <label>Invoice Payment Date:</label>
              <span>
                <DatePicker
                  selected={
                    stateValues.invoicePaymentDate
                      ? new Date(stateValues.invoicePaymentDate)
                      : null
                  }
                  dateFormat="M/d/yyyy"
                  maxDate={new Date(9999, 12, 31)}
                  name="invoicePaymentDate"
                  onChange={(e) => handleDateChange(e, "invoicePaymentDate")}
                />
                <FaCalendarAlt className="date-picker-icon" />
              </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label>Payment Detail (Wire/Check)</label>
              <Form.Control
                type="text"
                name="checkNbr"
                value={stateValues.checkNbr}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <label>Remaining Amount Due</label>
              <Form.Control
                type="text"
                value={remainingDueAmount}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label>Report of Collection (RC) #:</label>
              <Form.Control
                type="text"
                name="rptCollectionNbr"
                value={stateValues.rptCollectionNbr}
                onChange={(e) => handleChange(e, "rptCollectionNbr")}
              />
            </Col>
            <Col md={6}>
              <div className="control">
                <label>RC Date:</label>
                <div>
                  <span>
                    <DatePicker
                      selected={
                        stateValues.rptCollectionDate
                          ? new Date(stateValues.rptCollectionDate)
                          : null
                      }
                      dateFormat="M/d/yyyy"
                      maxDate={new Date(9999, 12, 31)}
                      name="rptCollectionDate"
                      onChange={(e) => handleDateChange(e, "rptCollectionDate")}
                    />
                    <FaCalendarAlt className="date-picker-icon" />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Created On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="createDateTime"
                      value={CommonService.dateFormatter()}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Created By:</label>
              <Form.Control
                type="text"
                name="createUser"
                value={stateValues.createUser}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Last Updated On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="lastUpdateDateTime"
                      value={CommonService.dateFormatter(
                        stateValues.lastUpdateDatetime
                      )}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Last Updated By</label>
              <Form.Control
                type="text"
                name="lastUpdateUserId"
                value={stateValues.lastUpdateUser}
                disabled
              />
            </Col>
          </Row>

          <div className="btn-div">
            <div className="fright">
              <button
                type="button"
                className="custom-button-secondary  btn-secondary"
                onClick={onModalClose}
              >
                Cancel
              </button>{" "}
              <button
                className="custom-button"
                type="button"
                onClick={(e) => onSubmit(stateValues)}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddInvoicePayment;
