import { AlertType } from '../utils';
import { toast } from 'react-toastify';
import axios from "axios";
import { configManager } from '../configManager';

const currencyFormatter = (amount) => { 
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }); 
      return amount && amount != null ? formatter.format(amount) : '$0.00';
}

const dateFormatter = (input) => { 
    if (input !== null ) {
        let date = new Date(input);
        let d = date.getDate();
        let m = date.getMonth();
        let y = date.getFullYear();
        return `${m + 1}/${d}/${y}`;
    }
    return "";
}

const getUserName= (user) => {
    if (user !== null) {
        if (user !== null) {
            return user.split('@')[0] === null ? 'SYSTEM' : user.split('@')[0]
        }
        else{
            return 'SYSTEM';
        }
    }
}

const addCommas = (input)=> { 
    if(input !== null && input !== undefined){
        return  input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } 
}

// const dateFormatterDom = (input) => { 
//     if(input !== null && input !== undefined){
//         return  moment(input).format('yyyy-MM-DD')
//     } 
// }
const base64ToArrayBuffer = (base64) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

const getFullYear = (input) => { 
    if(input !== null && input !== undefined){
        var date = new Date(input);
            if (!isNaN(date)) {
            return date.getFullYear();
            }
    } 
}

const convertAmount = (inputAmount) => {
    const amt = ( typeof inputAmount === 'string' ) 
            ? inputAmount !== "" ? parseFloat(inputAmount.replace(/,/g, '')) : 0.00
            : inputAmount !== "" ? inputAmount : 0;    
    return amt;
}
const dateFormatterMonthDayYear = (input) => { 
    if(input !== null && input !== undefined){
        let date = new Date(input);
        return date.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})
    } 
}

const showHideSpinner = (show) => {
    let loaderDivId='HeaderAlertMessage'
    let loaderDiv = document.getElementById(loaderDivId);
    if(loaderDiv){
      if(show) {
        loaderDiv.style.display = 'block';
        loaderDiv.classList.add('loader');
        loaderDiv.innerHTML = ""
      } else {
        loaderDiv.style.display = 'block';
        loaderDiv.classList.remove('loader');
      }
    }
  }
const displayServerMessage = (message, alertType = AlertType.Error) => {
      let typerror = "success";
      let color = "success" 

      if(alertType === AlertType.Error){
        color = 'danger';
        typerror = "error"
      }else if(alertType === AlertType.Warning){
        color = 'warning';  
        typerror = "error"
      }else{
        color = 'success'; 
      } 
      
      toast[typerror](message,{className:`alert alert-${color}`}); 
}
const handleServiceError= (error) => {
        
    let message = error.message;
    if(error.response){
        let data = error.response.data;
        if(data){
            if(data.title){
                message = `${data.title}`
            }
            try{
                if(data.errors){
                    for (let err of data.errors) {
                        message = `${message}. ${err.Message}, Trace Id:${err.TraceId}`
                    }
                }
                else{
                    if(Array.isArray(data)){
                        for (let err of data) {
                            if(err.Stack){
                                if(err.Stack.includes('ValidationException')){
                                    message = `${err.Message}`
                                }
                            }else{
                                message = `${message}. ${err.Message}, Trace Id:${err.TraceId}`
                            }
                            
                        }
                    }else{
                        message = `${message}. ${data}`
                    }
                }
            }catch{
                message = JSON.stringify(data.errors)
            }
        }
    }
    return {success:false, message:message,data:undefined };
}

const apicall = (obj) => {
    const idToken = localStorage.getItem("token");
     const headers = {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json',
        'AppId': 'Internal'
    };
    return axios({
        method: "post",
        headers: headers,
        url: `${configManager.apiUrl}/CdiacAdmin`,
        withCredentials: false,
        data: obj,
    });
}

const CommonService = {
    currencyFormatter,
    dateFormatter,
    getUserName,
    addCommas,
   // dateFormatterDom,
    getFullYear,
    dateFormatterMonthDayYear,
    base64ToArrayBuffer,
    showHideSpinner,
    displayServerMessage,
    apicall,
    handleServiceError,
    convertAmount
};

export default CommonService;