import { useContext, useState } from 'react'
import swal from 'sweetalert'
import Card from '../../ui/Card'
import { UserContext } from '../../../UserContext'
import { toast } from 'react-toastify'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import PrintPdfComponent from '../printPdfComponent'
import { renderToString } from 'react-dom/server'
import CommonService from '../../../services/CommonService'
import BaseService from '../../../services/BaseService'
import { AlertType } from '../../../utils'
const ProposedDebtReview = (props) => {
    const {issueId, proposedDebtDetails, proposeDebtDependencies, updateProposedDebtDetails} = props;
    const loginUserId = useContext(UserContext);
    const [proposedDebtInfo, setProposedDebtInfo] = useState(props.proposedDebtDetails);
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');

    const finalProposeDebt = async() => {
        const html = renderToString(<PrintPdfComponent proposedDebtDetails={proposedDebtDetails} proposeDebtDependencies={proposeDebtDependencies} />)

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "finalProposedDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "proposeDebtId": issueId,
                "user": loginUserId,
                "env" : "I",
                "html": html,
                "reportType" : "RPD"
            }
        };
    
       await ReportOfProposedDebtService.finalProposeDebt(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data && result.data.responses[0]) {
                    updateProposedDebtDetails(result.data.responses[0].proposedDebtDetails);
                    var base64EncodedPDF = result.data.responses[0].pdf;
                    var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
                    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                    var url = window.URL.createObjectURL(blob);
                    window.open(url);
                }
                toast.success("Report of Proposed Debt form has been successfully validated and submitted!");
            }

        });
    }

    const checkIfMarksRoosReportable = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "IsProposeDebtMarksRoosReportable",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "proposeDebtId": issueId,
            }
        };
    
       await ReportOfProposedDebtService.checkIfMarksRoosReportable(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0]) {
                    swal("This debt issue may be reportable pursuant to GOV 6599.1 of the Marks-Roos Local Bond Pooling Act if the proceeds of the debt are used to acquire local obligations or transferred to a local obligor under the terms of a debt contract. (CDIAC Regulations, Section 6051).")
                    .then(() => {
                        finalProposeDebt();
                      });
                } else {
                    finalProposeDebt();
                }

            }

        });
    }
    async function checkFinancingParticipants() {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "checkProposeDebtFinancingParticipants",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "proposeDebtId": issueId,
                "filingDate" : proposedDebtDetails.filingDate
            }
        };
        await ReportOfProposedDebtService.checkFinancingParticipants(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0] && result.data.responses[0].length > 0) {
                    let alertMessage = "";
                   (result.data.responses).forEach((msg, i) => {
                    alertMessage +=  "- " + msg + "\n";
                    });
                    alert(alertMessage); 
                } else {
                    checkIfMarksRoosReportable();
                }
            }
        })
    }
    const validateRequiredFields = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getValidationMessagesProposeDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "proposeDebtId": issueId
            }
        };
        ReportOfProposedDebtService.getValidationMessages(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0].length > 0) {
                  let alertMessage = "";
                  result.data.responses[0].forEach(item => {
                    alertMessage +=  "- " + item.message + "\n";
                  });
                  alert(alertMessage);
                } else {
                    checkFinancingParticipants();
                }
            }

        });
    }
    const validateProposedDebt = () => {
        props.toggleWidget('review');
        validateRequiredFields();
    }

    const handleCdiacComment = (e) => { 
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);       
        setProposedDebtInfo(prevState=>({
            ...prevState, 
            'cdiacComment': e.target.value
        })
        )    
    };
    const saveComment = async () => {
        CommonService.showHideSpinner(true);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putProposeDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issueId,
                "proposeDebt": {...proposedDebtInfo}
            }
        };
    
       await BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
            CommonService.showHideSpinner(false);
            if (result.data.errors && result.data.errors.length > 0){
                CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
            }
            else{
                CommonService.displayServerMessage("Comment Saved Successfully!", AlertType.Success);
            }
        });
    }
    return (
        <Card>
            <div className="form">
                <div className="">
                    <form>
                        <label>Cdiac Comments:</label>
                        <textarea name="cdiacComment" value={proposedDebtInfo.cdiacComment} 
                        id="cdaic-comment" onChange={(e)=>handleCdiacComment(e)}
                        className="form-control" rows="5" maxLength="2000" placeholder="Add a comment..."/>
                    <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 2000 max</span>
                    <div style={{overflow: 'hidden'}}>
                        <span style={{float: 'right'}}><button className="custom-button" type="button" disabled={(proposedDebtInfo.cdiacComment !== "") ? false : true} onClick={()=>saveComment()}>Save</button></span>
                    </div>
                    </form>
                </div>
                <div className="separator">REVIEW</div>
                <div>
                <PrintPdfComponent proposedDebtDetails={proposedDebtDetails} proposeDebtDependencies={proposeDebtDependencies} />
                </div>
                <div className="btn-div">
                <button className="fright custom-button" type="button" onClick={()=>validateProposedDebt()}>Validate & Submit</button>
                </div>
            </div>
        </Card>
    )
}

export default ProposedDebtReview
