import CommonService from "./CommonService";


const getAllStatutes = (obj) => {
    return CommonService.apicall(obj)

};

const addStatute = (obj) => {
    return CommonService.apicall(obj)

};

const updateStatute = (obj) => {
    return CommonService.apicall(obj)

};

const StatuteMaintenanceService = {
    getAllStatutes,
    addStatute,
    updateStatute
};

export default StatuteMaintenanceService;
