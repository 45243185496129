import { Collapse } from "react-bootstrap";
import Issuance from './issuance';
import {FaPlus, FaMinus} from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import RetiredIssues from './retiredIssues';
import FilingContact from './filingContact';
import Comments from './comments';
import AdtrReview from './adtrReview';
import IssuanceAuthorization from './issuanceAthorization';
import PrincipalOutStanding from './principalOutstanding';
import UseOfProceeds from './useOfProceeds';

const CollapsibleWidgets = (props) => {
    const {openSectionObj, toggleWidget, openSection, adtrDetails, contact, adtrDependenciesData, updateIssuanceDetails, updateAdtrDependencies} = props;
    const isFirstYear = adtrDependenciesData.isAdtrFirstYear;

    return (
      <>
      <div className="panel panel-default">
        <div className="panel-heading" role="tab"> 
          <h5 className="panel-title icon-style" onClick={()=>toggleWidget('issuance')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="issuance" aria-expanded={openSectionObj.issuance} aria-controls="collapse_issuance"> {!openSectionObj.issuance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance </button> 
          </h5>
          <Collapse in={openSectionObj.issuance}>
            <div id="collapse_issuance">
            {openSectionObj.issuance && <Issuance openWidget={openSection} adtrDetails={adtrDetails} isAdtrFirstYear={isFirstYear} updateIssuanceDetails={updateIssuanceDetails}/>}
            </div>
          </Collapse>
        </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('issuanceAuthorization')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.issuanceAuthorization} aria-controls="collapse_issuance_authorization"> {!openSectionObj.issuanceAuthorization ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance Authorization </button> 
        </h5>
        <Collapse in={openSectionObj.issuanceAuthorization}>
          <div id="collapse_issuance_authorization">
          {openSectionObj.issuanceAuthorization && <IssuanceAuthorization openWidget={openSection} issueId={adtrDetails.adtrId} isAdtrFirstYear={isFirstYear} updateAdtrDependencies={updateAdtrDependencies}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('principalOutstanding')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.principalOutstanding} aria-controls="collapse_issuance_authorization"> {!openSectionObj.principalOutstanding ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Principal Outstanding </button> 
        </h5>
        <Collapse in={openSectionObj.principalOutstanding}>
          <div id="collapse_issuance_authorization">
          {openSectionObj.principalOutstanding && <PrincipalOutStanding openWidget={openSection} issueId={adtrDetails.adtrId} adtrDependenciesData={adtrDependenciesData} updateAdtrDependencies={updateAdtrDependencies}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('useOfProceeds')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
             id="issuer" aria-expanded={openSectionObj.useOfProceeds} aria-controls="collapse_issuance_authorization"> {!openSectionObj.useOfProceeds ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Use of Proceeds </button> 
        </h5>
        <Collapse in={openSectionObj.useOfProceeds}>
          <div id="collapse_issuance_authorization">
          {openSectionObj.useOfProceeds && <UseOfProceeds openWidget={openSection} adtrId={adtrDetails.adtrId} adtrDependenciesData={adtrDependenciesData} updateAdtrDependencies={updateAdtrDependencies}/>}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('filingContact')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="filingContact" aria-expanded={openSectionObj.filingContact} aria-controls="collapse_filingContact" > {!openSectionObj.filingContact ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Filing Contact</button> 
        </h5>
        <Collapse in={openSectionObj.filingContact}>
          <div id="collapse_filingContact">
          {openSectionObj.filingContact && <FilingContact openWidget={openSection} contact={contact}/> }
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('comments')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="comments" aria-expanded={openSectionObj.comments} aria-controls="collapse_comments" > {!openSectionObj.comments ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Comments</button> 
        <div style={{float: 'right', margin: '0 10px'}}><small>(Optional)</small></div>
        </h5>
        <Collapse in={openSectionObj.comments}>
          <div id="collapse_comments">
          {openSectionObj.comments && <Comments openWidget={openSection} sectionName={'review'} adtrDetails={adtrDependenciesData.adtrInfo} adtrIssueComments={adtrDependenciesData.issueComments} updateAdtrDependencies={updateAdtrDependencies}/> }
            </div>
        </Collapse>
      </div>
      </div>
      
      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style" onClick={()=>toggleWidget('retiredIssues')}>
          <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
            id="retiredIssues" aria-expanded={openSectionObj.retiredIssues} aria-controls="collapse_retiredIssues"> {!openSectionObj.retiredIssues ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; ADTR Reportable</button> 
        </h5>
        <Collapse in={openSectionObj.retiredIssues}>
          <div id="collapse_retiredIssues">
          {openSectionObj.retiredIssues && <RetiredIssues openWidget={openSection} adtrDependenciesData={adtrDependenciesData} />}
            </div>
        </Collapse>
      </div>
      </div>

      <div className="panel panel-default">
      <div className="panel-heading" role="tab"> 
      	<h5 className="panel-title icon-style"  onClick={()=>toggleWidget('review')}>
        <button
            type="button"
            className="link-button" 
            style={{color: '#000', textDecoration: 'none', fontWeight: '500'}} 
             id="review" aria-expanded={openSectionObj.review} aria-controls="collapse_review"> {!openSectionObj.review ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Review</button> 
        </h5>
        <Collapse in={openSectionObj.review}>
          <div id="collapse_review">
          <AdtrReview openWidget={openSection} toggleWidget={toggleWidget} adtrDependenciesData={adtrDependenciesData} contact={contact}/>
          </div>
        </Collapse>
      </div>
      </div>
      </>
    );
}

export default CollapsibleWidgets
