import CommonService from "./CommonService";


const getAllDebtGroups = (obj) => {
    return CommonService.apicall(obj)
};

const getDebtGroupDetails = (obj) => {
    return CommonService.apicall(obj)

};

const addDebtInstrument = (obj) => {
    return CommonService.apicall(obj)

};

const updateDebtInstrument = (obj) => {
    return CommonService.apicall(obj)

};


const DebtInstrumentMaintenanceService = {
    getAllDebtGroups,
    getDebtGroupDetails,
    addDebtInstrument,
    updateDebtInstrument
};

export default DebtInstrumentMaintenanceService;