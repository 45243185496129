import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import CommonService from '../../../services/CommonService'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const UploadedDocuments = (props) => {
  const { uploadedDocuments } = props;

  const handleViewDocument = (e, issueDocId) => {
    CommonService.showHideSpinner(true);
    let obj = {
        "msgId": null,
        "sysId": null,
        "opr": "getPdfDocumentFromBlob",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": { 
            "issueDocId" : issueDocId,
            "type" : "issue_document"
        }
    };
    ReportOfProposedDebtService.getHistory(obj)
      .then((res) => {
        var base64EncodedPDF = res.data.responses[0];
        var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
        function base64ToArrayBuffer(base64) {
            let binaryString = window.atob(base64);
            let binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        }
        CommonService.showHideSpinner(false);
        var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
        var url = window.URL.createObjectURL(blob);
        window.open(url);
    });
  }
  const fileNameFormatter = (row) => { 
    return ( 
        <div style={{lineHeight: "normal" }}>
            <button
                type="button"
                className="link-button dk-blue-color"
                onClick={(e)=>handleViewDocument(e, row.issueDocId)}>{row.fileName}
            </button>
        </div> 
    ); 
  } 


  const columns = [{
    dataField: 'source',
    text: 'Source', 
    sort: true,
  }, {
    dataField: 'documentType',
    text: 'Document Type',
    sort: true,
  }, {
    dataField: 'fileName',
    text: 'File Name',
    sort: true,
    formatter: fileNameFormatter,
  }, {
    dataField: 'createDateTime',
    text: 'Create Date',
    sort: true,
    formatter: (row) => CommonService.dateFormatter(row['createDateTime']),
  }, {
    dataField: 'createUser',
    text: 'Create User',
    sort: true,
  }
  ];

  return (
    <>
      <div className="form">
        <DataGrid
            title="Uploaded Documents"
            dataSource={uploadedDocuments}
            sortField='createDateTime'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
      </div>
    </>
  )
}

export default UploadedDocuments
