import {useState} from 'react'
import { Collapse } from "react-bootstrap"
import Issuance from './issuance'
import IssuanceAuthorization from './issuanceAthorization'
import Statutories from './statutories'
import FilingContact from '../filingContact'
import RetiredIssues from './retiredIssues'
import InterestDebtExempt from './interestDebtExempt'
import TypeOfSale from './typeOfSale'
import TypeOfDebt from './typeOfDebt'
import SourceOfRepayment from './sourceOfRepayment'
import PurposeOfFinancing from './purposeOfFinancing'
import DocumentUpload from './documentUpload'
import FinalSaleReview from './finalSaleReportReview'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FaPlus, FaMinus} from 'react-icons/fa'
import 'bootstrap/dist/css/bootstrap.min.css'
import Dates from './dates'
import Credit from './credit'

const CollapsibleWidgets = (props) => {
  const {openSectionObj, toggleWidget, openSection, 
    finalSaleDetails, updateFinalSaleDetails,
    finalSaleDependencies, updateFinalSaleDependencies, updateIssueComments} = props;

  const [sectionValidated, setSectionValidated] = useState({
    issuance: finalSaleDetails.maturityStructureCode ? true : false,
    dates: finalSaleDetails.finalMaturityDate ? true : false,
    issuanceAuthorization: finalSaleDependencies.issueAuthorizations && finalSaleDependencies.issueAuthorizations.length > 0 ? true :false,
    statutes: finalSaleDependencies.issueStatutes && finalSaleDependencies.issueStatutes.length > 0 ? true : false,
    filingContact: true,
    credit: finalSaleDetails.guaranteeFlag ? true : false,
    financingParticipants: finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.length > 0 ? true : false,
    typeOfDebt: finalSaleDetails.debtInstrumentGroupCodeId ? true : false,
    interestDebtExempt: finalSaleDetails.capitalAppreciationInd ? true : false,
    sourceOfRepayment: finalSaleDetails.seniorInd ? true : false,
    purposeOfFinancing: finalSaleDependencies.issueFinancingPurposes && finalSaleDependencies.issueFinancingPurposes.length > 0 ? true : false,
    typeOfSale: finalSaleDetails.saleTypeCode ? true : false,
    uploadDoc: finalSaleDetails.officialStmtRcvdFlag ? true : false
  });

  const updateSectionValidated = (sectionName, isValid) => {
    setSectionValidated(prevState=>({
      ...prevState, [sectionName]: isValid
    }))
  }

  return (
    <>
    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style icon-style" onClick={()=>toggleWidget('issuance')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
          id="issuance" aria-expanded={openSectionObj.issuance} aria-controls="collapse_issuer" > {!openSectionObj.issuance ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance </button> 
      {sectionValidated.issuance && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.issuance}>
        <div id="collapse_issuer">
          {openSectionObj.issuance && <Issuance openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails}  updateFinalSaleDependencies={updateFinalSaleDependencies} finalSaleDependencies={finalSaleDependencies}/>}
        </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style icon-style" onClick={()=>toggleWidget('dates')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
          id="dates" aria-expanded={openSectionObj.dates} aria-controls="collapse_issuer" > {!openSectionObj.dates ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Dates </button> 
      {sectionValidated.dates && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.dates}>
        <div id="collapse_issuer">
          {openSectionObj.dates && <Dates openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails}/>}
        </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('issuanceAuthorization')}>
        <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.issuanceAuthorization} aria-controls="collapse_issuance_authorization" > {!openSectionObj.issuanceAuthorization ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Issuance Authorization </button> 
        {sectionValidated.issuanceAuthorization && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.issuanceAuthorization}>
        <div id="collapse_issuance_authorization">
        {openSectionObj.issuanceAuthorization && <IssuanceAuthorization openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDependencies={updateFinalSaleDependencies}/> }
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('statutories')}>
        <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.statutories} aria-controls="collapse_statutories" > {!openSectionObj.statutories ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Statutory Authority </button> 
        {sectionValidated.statutes && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.statutories}>
        <div id="collapse_statutories">
        {openSectionObj.statutories && <Statutories openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails} updateFinalSaleDependencies={updateFinalSaleDependencies}/> }
          </div>
      </Collapse>
    </div>
    </div>

  <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('filingContact')}>
        <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="filingContact" aria-expanded={openSectionObj.filingContact} aria-controls="collapse_filing_contact"> {!openSectionObj.filingContact ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Filing Contact </button> 
        {sectionValidated.filingContact && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.filingContact}>
        <div id="collapse_issuance_authorization">
        {openSectionObj.filingContact &&  <FilingContact openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} issueId={finalSaleDetails.finalSaleId} reportType="RFS"/> }
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('credit')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.credit} aria-controls="collapse_credit" > {!openSectionObj.credit ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Credit</button> 
        {sectionValidated.credit && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.credit}>
        <div id="collapse_credit">
          {openSectionObj.credit && <Credit openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails}/>}
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('financingParticipants')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.financingParticipants} aria-controls="collapse_financingParticipants" > {!openSectionObj.financingParticipants ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Financing Participants/Costs</button> 
        {sectionValidated.financingParticipants && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.financingParticipants}>
        <div id="collapse_financingParticipants">
          {openSectionObj.financingParticipants && <RetiredIssues openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails}  updateFinalSaleDependencies={updateFinalSaleDependencies}/>}
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('interestDebtExempt')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
          id="issuer" aria-expanded={openSectionObj.interestDebtExempt} aria-controls="collapse_interestDebtExempt"> {!openSectionObj.interestDebtExempt ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Tax Status/Interest</button> 
        {sectionValidated.interestDebtExempt && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.interestDebtExempt}>
        <div id="collapse_interestDebtExempt">
        {openSectionObj.interestDebtExempt &&  <InterestDebtExempt openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails}/> }
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('typeOfDebt')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.typeOfDebt} aria-controls="collapse_typeofdebt"> {!openSectionObj.typeOfDebt ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Type of Debt Instrument </button> 
        {sectionValidated.typeOfDebt && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.typeOfDebt}>
        <div id="collapse_typeofdebt">
        {openSectionObj.typeOfDebt && <TypeOfDebt openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails}/> }
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('sourceOfRepayment')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.sourceOfRepayment} aria-controls="collapse_source_of_repayment"> {!openSectionObj.sourceOfRepayment ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Source of Repayment </button> 
        {sectionValidated.sourceOfRepayment && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.sourceOfRepayment}>
        <div id="collapse_source_of_repayment">
        {openSectionObj.sourceOfRepayment && <SourceOfRepayment openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails} updateFinalSaleDependencies={updateFinalSaleDependencies}/> }
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('purposeOfFinancing')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.purposeOfFinancing} aria-controls="collapse_purpose_of_financing"> {!openSectionObj.purposeOfFinancing ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Purpose of Financing </button> 
        {sectionValidated.purposeOfFinancing && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.purposeOfFinancing}>
        <div id="collapse_issuance_authorization">
        {openSectionObj.purposeOfFinancing && <PurposeOfFinancing openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDependencies={updateFinalSaleDependencies}/> }
        </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('typeOfSale')}>
        <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.typeOfSale} aria-controls="collapse_typeofsale"> {!openSectionObj.typeOfSale ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Type Of Sale </button> 
        {sectionValidated.typeOfSale && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.typeOfSale}>
        <div id="collapse_typeofsale">
        {openSectionObj.typeOfSale && <TypeOfSale openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails} updateFinalSaleDependencies={updateFinalSaleDependencies}/> }
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('uploadDoc')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="issuer" aria-expanded={openSectionObj.uploadDoc} aria-controls="collapse_upload_doc"> {!openSectionObj.uploadDoc ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Document Submittal </button> 
        {sectionValidated.uploadDoc && <div style={{float: 'right', margin: '0 10px'}}><FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/></div>}
      </h5>
      <Collapse in={openSectionObj.uploadDoc}>
        <div id="collapse_issuance_authorization">
        {openSectionObj.uploadDoc && <DocumentUpload openWidget={openSection} sectionValidated={updateSectionValidated} finalSaleDetails={finalSaleDetails} updateFinalSaleDetails={updateFinalSaleDetails} updateFinalSaleDependencies={updateFinalSaleDependencies}/> }
          </div>
      </Collapse>
    </div>
    </div>

    <div className="panel panel-default">
    <div className="panel-heading" role="tab"> 
      <h5 className="panel-title icon-style" onClick={()=>toggleWidget('review')}>
      <button
          type="button"
          className="link-button" 
          style={{color: '#000', textDecoration: 'none', fontWeight: '500'}}
           id="review" aria-expanded={openSectionObj.review} aria-controls="collapse_review"> {!openSectionObj.review ? <FaPlus className="icon-style"/> : <FaMinus className="icon-style"/>}&nbsp; Review </button> 
      </h5>
      <Collapse in={openSectionObj.review}>
        <div id="collapse_review">
        <FinalSaleReview sectionValidated={sectionValidated} toggleWidget={toggleWidget} finalSaleDetails={finalSaleDetails} finalSaleDependencies={finalSaleDependencies} updateFinalSaleDetails={updateFinalSaleDetails} updateIssueComments={updateIssueComments}/>
          </div>
      </Collapse>
    </div> 
    </div>
    </>
  );
}

export default CollapsibleWidgets
