import {useState, useEffect} from 'react'
import Card from '../../ui/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditCodeForm from './editCodeForm'
import AddStatuteForm from './addStatuteForm'
import StatuteMaintenanceService from "../../../services/StatuteMaintenanceService";
import Spinner from 'react-bootstrap/Spinner'
import CommonService from "../../../services/CommonService";
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const StatuteMaintenance = () => {
    const [openEditCodeModal, setOpenModal] = useState(false);
    const [openAddCodeModal, setOpenAddCodeModal] = useState(false);
    const [editCodeInfo, setEditCodeInfo] = useState([])
    const [statutes, setStatute] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const viewFormatter = (row) => { 
        return ( 
              <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
           <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
         </div> 
        ); 
    }

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getStatuteRefs",
          hdrStruct: null,
          queryParam: null,
          reqData: {},
        };
    
        StatuteMaintenanceService.getAllStatutes(obj)
          .then((res) => res)
          .then((result) => {
            Object.keys(result.data.responses).forEach((key) => {
                setStatute(result.data.responses[key]);
            });
            setIsLoading(false);
          });
      }, [openAddCodeModal, openEditCodeModal]);

    const onRowClick = (e, row) => {
        setOpenModal(true);
        setEditCodeInfo(row);
    }

    const columns = [
      {
        dataField: 'statuteId',
        text: 'ID',
        sort: true,
        headerStyle: () => {
          return { width: '5%'};
        },
      },
      {
        dataField: 'statuteCode',
        text: 'Code',
        sort: true,
        headerStyle: () => {
          return { width: '5%'};
        },
      }, {
        dataField: 'statuteSection',
        text: 'Section',
        sort: true,
        headerStyle: () => {
          return { width: '12%'};
        },
      }, {
        dataField: 'statuteDesc',
        text: 'Description',
        sort: true,
      }, {
        dataField: 'createDatetime',
        text: 'Date Created',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['createDatetime'])
      },{
        dataField: 'lastUpdateDatetime',
        text: 'Last Updated',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['lastUpdateDatetime'])
      }, {
        dataField: 'createUser',
        text: 'Created By',
        sort: true,
      },{
        dataField: 'lastUpdateUser',
        text: 'Last Updated By',
        sort: true,
      },{
        dataField: 'activeInd',
        text: 'Active',
        sort: true,
        headerStyle: () => {
          return { width: '5%'};
        }
      },{ 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];
      
    return (
        <Card>
                <div className="panel panel-default">
                    <div className="panel-heading" role="tab"> 
                        <div className="container">
                            <div className="panel-title">
                                <h1 className="main-title">Statute Maintenance</h1>
                            </div>
                        </div>
                    </div>

                    <div className="panel-body" style={{padding: '10px 0'}}>
                        <div className="container">
                        {isLoading && <Spinner animation="border" variant="primary" />}
                            <div className="">
                                {/* <h1>{history.location.groupVal}</h1> */}


                                <DataGrid
                                dataSource={statutes}
                                sortField='statuteId'
                                addNew={true}
                                addNewBtnText="Add New Statute"
                                onAddNew={()=>setOpenAddCodeModal(true)}
                                >
                                {columns.map((column, i) => {
                                    return (
                                    <Column 
                                    key={i}
                                    field={column.dataField}
                                    header={column.text}
                                    sortable={column.sort} 
                                    body={column.formatter}
                                    />)
                                  }) 
                                } 
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
        {openEditCodeModal && <EditCodeForm onModalClose={()=>setOpenModal(false)} codeInfo={editCodeInfo} />}
        {openAddCodeModal && <AddStatuteForm onModalClose={()=>setOpenAddCodeModal(false)} />}
        </Card>
    )
}

export default StatuteMaintenance
