import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditFinancePurposeForm from './editFinancePurposeForm'
import AddFinancePurposeForm from './addFinancePurposeForm'
import FinancialPurposeMaintenanceService from "../../../services/FinancialPurposeMaintenanceService";
import Spinner from 'react-bootstrap/Spinner';
import CommonService from "../../../services/CommonService";
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const FinancialPurposeDetails = (props) => {
    const {selectedFinancingPurposeGroupVal} = props;
    const [openEditCodeModal, setOpenModal] = useState(false);
    const [openAddCodeModal, setOpenAddCodeModal] = useState(false);
    const [editFinancialPurposeInfo, setEditFinancialPurposeInfo] = useState([])
    const [financialPurposeGroups, setFinancialPurposeGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const viewFormatter = (row) => { 
        return ( 
              <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
           <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
         </div> 
        ); 
    }

    useEffect(() => {
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getFinancingPurposeRefsByGroupId",
        hdrStruct: null,
        queryParam: null,
        reqData: { "id": props.selectedFinancingPurposeGroup },
      };
  
      FinancialPurposeMaintenanceService.getFinancingPurposeGroupDetails(obj)
        .then((res) => res)
        .then((result) => {
          Object.keys(result.data.responses).forEach((key) => {
              setFinancialPurposeGroups(result.data.responses[key]);
          });
          setIsLoading(false);
        });
    }, [props, openAddCodeModal, openEditCodeModal]);

    const onRowClick = (e, row) => {
       // e.preventDefault();
        setOpenModal(true);
        setEditFinancialPurposeInfo(row);
    }

    const columns = [{
        dataField: 'financePurposeCode',
        text: 'Finance Purpose Code',
        sort: true,
      }, {
        dataField: 'financePurposeName',
        text: 'Finance Purpose Name',
        sort: true
      }, {
        dataField: 'createDatetime',
        text: 'Date Created',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['createDatetime'])
      },{
        dataField: 'lastUpdateDatetime',
        text: 'Last Updated',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['lastUpdateDatetime'])
      }, {
        dataField: 'createUser',
        text: 'Created By',
        sort: true,
      },{
        dataField: 'lastUpdateUser',
        text: 'Last Updated By',
        sort: true,
      },{
        dataField: 'activeInd',
        text: 'Active',
        sort: true,
      },{ 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];
      
    return (
        <>
         {isLoading && <Spinner animation="border" variant="primary" />}
        <div className="form">
            <DataGrid
            dataSource={financialPurposeGroups}
            sortField='financePurposeCode'
            title={selectedFinancingPurposeGroupVal}
            addNew={true}
            addNewBtnText="Add New Finance Purpose"
            onAddNew={()=>setOpenAddCodeModal(true)}
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                />)
              }) 
            } 
            </DataGrid>
          </div>
        {openEditCodeModal && <EditFinancePurposeForm onModalClose={()=>setOpenModal(false)} financialPurpose={editFinancialPurposeInfo} groupValue={selectedFinancingPurposeGroupVal}/>}
        {openAddCodeModal && <AddFinancePurposeForm onModalClose={()=>setOpenAddCodeModal(false)} groupValue={selectedFinancingPurposeGroupVal} selectedFinancingPurposeGroup ={props.selectedFinancingPurposeGroup}/>}
        </>
    )
}

export default FinancialPurposeDetails
