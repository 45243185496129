import React, { useState } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import InvoiceService from "../../../../services/InvoiceService";
import Alert from "react-bootstrap/Alert";
import CommonService from "../../../../services/CommonService";
import { toast } from "react-toastify";
import swal from "sweetalert";

const DeleteInvoiceComment = (props) => {
  const { onModalClose } = props;
  const stateValues = props.invoiceComments;
  const [errorMessage, setErrorMessage] = useState();

  const handleDeleteFile = (data) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "deleteInvoiceComment",
          hdrStruct: null,
          queryParam: null,
          reqData: {
            invoiceCommentId: data.invoiceCommentId,
          },
        };
        InvoiceService.deleteInvoiceComment(obj).then((result) => {
          if (result.data.errors && result.data.errors.length > 0) {
            setErrorMessage(result.data.errors[0].message);
          } else {
            toast.success("Invoice Comment Deleted successfully!");
            onModalClose();
          }
        });
      }
    });
  };

  return (
    <Modal show={true} backdrop="static" onHide={onModalClose}>
      <Modal.Header closeButton>
        <h3>Delete Invoice Comment</h3>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert variant="danger">
            <p>{errorMessage}</p>
          </Alert>
        )}

        <form id="editCommentForm">
          {/* <Row>
            <Col md={6}>
              <label>Type</label>
              <Form.Control
                type="text"
                name="invoiceCommentTypeCodeId"
                value={stateValues.invCommentTypeCodeId}
                disabled
              />
            </Col>
            <Col md={6}>
              <label>Print Memo:</label>
              <Form.Control
                type="text"
                name="printMemoFlag"
                value={stateValues.printMemoFlag}
                disabled
              />
            </Col>
          </Row> */}
          <Row>
            <Col className="control">
              <label>Comment:</label>
              <textarea
                name="invoiceComments"
                id="invoiceComments"
                rows="5"
                maxLength="4000"
                value={stateValues.invoiceComments}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Created On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="createDateTime"
                      value={CommonService.dateFormatter(
                        stateValues.createDateTime
                      )}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Created By:</label>
              <Form.Control
                type="text"
                name="createUser"
                value={stateValues.createUser}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>Last Updated On:</label>
                <div>
                  <span>
                    <Form.Control
                      type="text"
                      name="lastUpdateDateTime"
                      value={CommonService.dateFormatter(
                        stateValues.lastUpdateDatetime
                      )}
                      disabled
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>Last Updated By</label>
              <Form.Control
                type="text"
                name="lastUpdateUserId"
                value={stateValues.lastUpdateUser}
                disabled
              />
            </Col>
          </Row>

          <div className="btn-div">
            <div className="fright">
              <button
                type="button"
                className="custom-button-secondary  btn-secondary"
                onClick={onModalClose}
              >
                Cancel
              </button>{" "}
              <button
                className="custom-button"
                type="button"
                onClick={(e) => handleDeleteFile(stateValues)}
              >
                Delete
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteInvoiceComment;
