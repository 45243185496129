import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditReportForm from './editReportForm'
import AddReportForm from './addReportForm'
import Spinner from 'react-bootstrap/Spinner';
import ReportRefService from "../../../services/ReportRefService";
import CommonService from "../../../services/CommonService";
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const ReportsDetails = (props) => {
    const [openEditCodeModal, setOpenModal] = useState(false);
    const [openAddCodeModal, setOpenAddCodeModal] = useState(false);
    const [reportRefs, setReportRefs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editReportInfo, setEditReportInfo] = useState(null);

    const viewFormatter = (row) => { 
        return ( 
          <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
           <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
          </div> 
        ); 
    }

  useEffect(() => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getReportRefsByGroupId",
      hdrStruct: null,
      queryParam: null,
      reqData: { "id": props.selectedReportGroup },
    };

    ReportRefService.getReportRefDetails(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setReportRefs(result.data.responses[key]);
        });
        setIsLoading(false);
      });
  }, [props, openEditCodeModal, openAddCodeModal]);

    const onRowClick = (e, row) => {
       // e.preventDefault();
        setOpenModal(true);
        setEditReportInfo(row);
    }

    const columns = [{
        dataField: 'reportId',
        text: 'Id',
        sort: true,
      }, {
        dataField: 'reportName',
        text: 'Report Name',
        sort: true
      }, {
        dataField: 'reportDesc',
        text: 'Description',
        sort: true
      }, {
        dataField: 'reportUrl',
        text: 'Report URL',
        sort: true
      }, {
        dataField: 'createdDatetime',
        text: 'Create Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['createdDatetime'])
      },{
        dataField: 'lastUpdatedDatetime',
        text: 'Last Update',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['lastUpdatedDatetime'])
      },{
        dataField: 'activeInd',
        text: 'Active',
        sort: true,
      },{ 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];
      
    return (
        <>
        {isLoading && <Spinner animation="border" variant="primary" />}
        <div className="">
          <DataGrid
            dataSource={reportRefs}
            sortField='reportId'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            addNew={true}
            addNewBtnText="Add New Report"
            onAddNew={()=>setOpenAddCodeModal(true)}
          >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                />)
              }) 
            } 
          </DataGrid>
        </div>
        {openEditCodeModal && <EditReportForm onModalClose={()=>setOpenModal(false)} reportInfo={editReportInfo} groupName={props.selectedReportGroupName} />}
        {openAddCodeModal && <AddReportForm onModalClose={()=>setOpenAddCodeModal(false)} selectedReportGroupId={props.selectedReportGroup} groupName={props.selectedReportGroupName}/>}
        </>
    )
}

export default ReportsDetails
