import { LogLevel } from "@azure/msal-browser";


export const configManager = {
    apiUrl: process.env.NODE_ENV==="development"? "http://localhost:7071/api":process.env.REACT_APP_API_GATEWAY_URL,
    fileUploadApiUrl: process.env.NODE_ENV==="development"? "http://localhost:7071/api":process.env.REACT_APP_UPLOAD_URL,
    clientId: process.env.NODE_ENV==="development"? "7549954d-61d0-4fc3-a1ef-1f6f5f1d57ab":process.env.REACT_APP_AD_CLIENT_ID,
    groupId: process.env.NODE_ENV==="development"? "b14a5d78-923e-482a-a884-3f0f3df78ffa":process.env.REACT_APP_AD_GROUP_ID,
    authUrl: process.env.NODE_ENV==="development"? "https://login.microsoftonline.com/3bee5c8a-6cb4-4c10-a77b-cd2eaeb7534e":process.env.REACT_APP_AD_URL, 
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
    redirectUrl: `${window.location.protocol}//${window.location.host.toLowerCase()}/dashboard`,    
    debtWatchUrl: process.env.NODE_ENV==="development"? "https://debtwatch-dev.treasurer.ca.gov":process.env.REACT_APP_DEBT_WATCH_URL,    
}

export const msalConfig = {
    auth: {
        clientId: configManager.clientId, 
        authority: configManager.authUrl, 
        redirectUri: configManager.redirectUrl,
        postLogoutRedirectUri: "/"

    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {   
        loggerOptions: {    
            loggerCallback: (level, message, containsPii) => {  
                if (containsPii) {      
                    return;     
                }       
                switch (level) {        
                    case LogLevel.Error:        
                        console.error(message);     
                        return;     
                    case LogLevel.Info:     
                        console.info(message);      
                        return;     
                    case LogLevel.Verbose:      
                        console.debug(message);     
                        return;     
                    case LogLevel.Warning:      
                        console.warn(message);      
                        return;
                    default:
                        return;
                }   
            }   
        }   
    }
};

export const loginRequest = {
    scopes: configManager.scopes
};
