import CommonService from '../../services/CommonService';
const InvoicePdf = (props) => {
      const {finalSaleDetails} = props; 
      const totalDueLabel = finalSaleDetails.invoiceFeeAmt < 5000 ? "Principal Amount x 0.00025": "Maximum Fee";
        
      return (
       <>
        <html>
         <head>
         <meta charset="UTF-8"></meta>
         <style>{"\
          div.repeat-header {\
            padding-top: 0.1in !important;\
            display: block !important;\
            text-align: center;\
            position: running(repeat-header) !important;\
          }\
          .report-header {\
              text-align: center;\
              padding: 5px;\
              width: 100%;\
              height: 40px;\
          }\
          .report-heading-left {\
              font-size: 18px;\
              font-weight: 800;\
              color: black;\
              text-align: left;\
              width: 20%;\
          }\
          .report-heading-center {\
              font-size: 12px;\
              font-weight: 800;\
              color: black;\
              text-align: center;\
              width: 55%;\
          }\
          .report-heading-right {\
              font-size: 12px;\
              color: black;\
              text-align: right;\
              width: 25%;\
          }\
          .report-heading-title {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              min-width: 65%;\
          }\
          .report-heading-date {\
              font-size: 10px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
          }\
          div.footer {\
            position: running(footer) !important;\
          }\
          #current-page-placeholder::before {\
            content: counter(page);\
          }\
          #total-pages-placeholder::before {\
            content: counter(pages);\
          }\
          @page {\
              margin-top: 80px;\
              @top-center {\
                  content: element(repeat-header) !important\
              }\
          }\
          @page {\
              @bottom-right {\
                  content: element(footer) !important;\
                  font-size: 10px;\
              }\
          }\
          div.repeat-header table {\
              page-break-inside: avoid;\
          }\
          div.repeat-header tr {\
              page-break-inside: avoid;\
              page-break-after: auto\
          }\
          div.repeat-header thead {\
              display: table-header-group\
          }\
          div.repeat-header tfoot {\
              display: table-footer-group\
          }\
          div.break-page {\
              page-break-after: always;\
          }\
          .table a {\
              color: black;\
              text-decoration: underline;\
          }\
          .table .tableheader th, .table tbody th {\
              color: #fff;\
              background-color: darkblue;\
              border-color: #454d55;\
              vertical-align: middle;\
              font-size: 1.0em;\
          }\
          .v-top {\
              vertical-align: top !important;\
          }\
          tr {\
              page-break-inside: avoid\
          }\
          .invoice-box{\
            max-width: 800px;\
            margin: auto;\
            padding: 20px;\
            font-size: 16px;\
            line-height: 18px;\
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, serif;\
            color: #000;\
          }\
          .invoice-section .tbold {\
            font-weight: bold;\
            }\
          .invoice-box table {\
            width: 100%;\
            line-height: inherit;\
            text-align: left;\
          }\
          .invoice-box table.table-border {\
            border: 1px solid #eee;\
          }\
          .invoice-box table td {\
            padding: 5px;\
            vertical-align: top;\
          }\
          .border-top {\
            border-top: 1px solid #000;\
            margin: 0.15rem; !important\
            color: #000000;\
            opacity: .5;\
          }\
          .invoice-box table tr.information table td {\
              padding-bottom: 10px;\
          }\
          .invoice-box table tr.item td {\
            border-bottom: 1px solid #eee;\
          }\
          .invoice-box table tr.item.last td {\
              border-bottom: none;\
          }\
          .invoice-box .invoice-comment{\
            color: red;\
            margin: 10px 0;\
          }\
          .invoice-box .invoice-section{\
            margin: 10px 0;\
          }\
          .invoice-box .invoice-section .invoice-info {\
            overflow: auto;\
          }\
          @media only screen and (max-width: 600px) {\
          .invoice-box table tr.top table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          .invoice-box table tr.information table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          }\
        "}</style>
         </head>
          <body className="pdf-box">
            <div className="invoice-box">
                <hr className="border-top"/>
                <hr className="border-top"/>
                <div className="invoice-section">
                    <div className="invoice-info">
                        <div className="invoicee-data" style={{float: 'left'}}>
                            INVOICEE
                        </div>
                        <div className="invoice-data" style={{float: 'right'}}>
                            <table>
                                <tbody>
                                <tr><td>Invoice Date: INVOICE_DATE</td></tr>
                                <tr><td>Reporting Date: {CommonService.dateFormatter(finalSaleDetails.finalSaleReceiveDate)}</td></tr>
                                <tr><td>Invoice Id: INVOICE_ID</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr className="border-top"/>
                <hr className="border-top"/>
                <div className="invoice-section">
                <p>Reporting fee levied pursuant to Section 8856 of the Government Code:</p>
                <table cellPadding="0" cellSpacing="0">
                    <tbody>
                    <tr className="information">
                        <td colSpan="12">
                        <table>
                            {/* <tr>
                            <td>
                                <table> */}
                                    <tr><td colSpan="6" className="tbold">CDIAC Nbr:</td><td colSpan="6">{finalSaleDetails.cdiacNbr}</td></tr>
                                    <tr><td colSpan="6" className="tbold">Issuer:</td><td colSpan="6">{finalSaleDetails.issuerName}</td></tr>
                                    <tr><td colSpan="6" className="tbold">Issue Name:</td><td colSpan="6">{finalSaleDetails.issueName}</td></tr>
                                    <tr><td colSpan="6" className="tbold">Project/Issue Info:</td><td colSpan="6">{finalSaleDetails.projectName}</td></tr>
                                    <tr><td colSpan="6" className="tbold">Principal Amount:</td><td colSpan="6">{CommonService.currencyFormatter(finalSaleDetails.principalSaleAmt)}</td></tr>
                                    <tr><td colSpan="6" className="tbold">Issue Date:</td><td colSpan="6">{CommonService.dateFormatter(finalSaleDetails.settlementDate)}</td></tr>
                                {/* </table>
                            </td>
                            </tr> */}
                        </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                    <tr><td colSpan="6" className="tbold">TOTAL DUE: {totalDueLabel} <br/><small>(Not to exceed $5,000.00)</small>:</td><td colSpan="6">{CommonService.currencyFormatter(finalSaleDetails.invoiceFeeAmt)}</td></tr>                           
                    </tbody>
                </table>
                </div>
                {finalSaleDetails.invoiceComment &&
                <div className="invoice-section">
                    <p className="invoice-comment">NOTE: {finalSaleDetails.invoiceComment}</p>
                </div>
                }
                <div className="invoice-section" style={{margin: '30px 0'}}>
                    <div style={{textAlign: 'center'}}>
                        REMITTANCE_TEMPLATE
                    </div>
                </div>
                <div className="invoice-section" style={{overflow: 'auto', marginTop: '25px'}}>
                    <div style={{float: 'right'}}>
                        CONTACT_EMAIL
                    </div>
                </div>
           </div>
           
          </body>
        </html>
       </>
      );
  }

  export default InvoicePdf;