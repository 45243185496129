import FinalSaleReports from './finalSaleReports'
import ProposedDebtReports from './proposedDebtReports'
import ADTRReports from './adtrReports'
import MarksRoosAuthorityReports from './marksRoosAuthorityReports'
import MarksRoosLocalObligorReports from './marksRoosLocalObligorReports'
import DrawReports from './drawOnReserve'
import MelloRoosReports from './melloRoosReports'
const Reports = (props) => {
    const {reportType, reportStatus, searchCriteria} = props;
    
    return (
        <div>
            {reportType === 'PD' && <ProposedDebtReports newReportLink={props.newReportLink} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'FS' && <FinalSaleReports newReportLink={props.newReportLink} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'DRAW' && <DrawReports newReportLink={props.newReportLink} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'ADTR' && <ADTRReports newReportLink={props.newReportLink} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'MLR' && <MelloRoosReports newReportLink={props.newReportLink} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'MKRA' && <MarksRoosAuthorityReports newReportLink={props.newReportLink} reportStatus={reportStatus} searchCriteria={searchCriteria}/> }
            {reportType === 'MKRL' && <MarksRoosLocalObligorReports newReportLink={props.newReportLink} reportStatus={reportStatus} searchCriteria={searchCriteria}/> } 
        </div>
    )
}

export default Reports
