import { useState, useEffect } from 'react'
import EditContact from './editContact'
import AddContact from './addContact'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import BaseService from '../../../services/BaseService'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const SearchResultContainer = (props) => {
  const [editContact, setEditContact] = useState(false);
  const [selectedContactInfo, setSelectedContactInfo] = useState([]);
  const { orgId } = props;
  const [filerContacts, setFilerContacts] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [addContact, setAddContact] = useState(false);

  const viewEditFormatter = (row) => {
    return (
      <div style={{
        textAlign: "center",
        lineHeight: "normal"
      }}>
        <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
      </div>
    );
  }
  const onRowClick = (e, row) => {
    // e.preventDefault();
    setSelectedContactInfo(row);
    setEditContact(true);
  }

  const getContactsByOrgId = () => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getContactsByOrgId",
      hdrStruct: null,
      queryParam: null,
      reqData: {
          "orgId": orgId,
      },
    };

    BaseService.processRequest(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setFilerContacts(result.data.responses[key]);
        });
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getContactsByOrgId();
    // eslint-disable-next-line
  }, [orgId]);

  const columns = [{
    dataField: 'contactFirstName',
    text: 'Contact First Name',
    sort: true,
  },{
    dataField: 'contactLastName',
    text: 'Contact Last Name',
    sort: true,
  }, {
    dataField: 'contactEmailId',
    text: 'Contact Email',
    sort: true,
  },{
    dataField: 'activeInd',
    text: 'Is Active',
    sort: true,
  }, {
    dataField: "",
    text: "Action",
    formatter: viewEditFormatter,
  }
  ];

  const handleModalClose = () => {
    setEditContact(false);
    getContactsByOrgId();
  }

  const handleAddContact = (e) => {
    e.preventDefault();
    setAddContact(true);
  }
  const handleAddContatModalClose = () => {
    setAddContact(false);
    getContactsByOrgId();
}

  return (
    <>
      <hr/>
      {isLoading && <Spinner animation="border" variant="primary" />}
      <div className="form">
        <h2>Search Result: </h2>
        <button className="fright custom-button" type="button" data-dismiss="modal" onClick={(e)=>handleAddContact(e)}>Add Contact</button>
        <DataGrid
            title="Annual Debt Transparency Reports"
            dataSource={filerContacts}
            sortField='contactId'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
        </DataGrid>
      </div>
      {editContact && <EditContact onModalClose={() => handleModalClose()} contactInfo={selectedContactInfo} />}
      {addContact && <AddContact onModalClose={()=>handleAddContatModalClose()} orgId={orgId}/>}
    </>
  )
}

export default SearchResultContainer
