import { useContext, useState, useEffect } from 'react';
import Card from '../../ui/Card';
import PrintPdfComponent from '../printPdfComponent';
import { Form, Row, Col, Table } from 'react-bootstrap';
import FinalSaleReportService from '../../../services/FinalSaleReportService';
import { UserContext } from '../../../UserContext';
import { toast } from 'react-toastify';
import CommonService from '../../../services/CommonService';
import Select from 'react-select';
import CountyMaintenanceService from '../../../services/CountyMaintenanceService';
import { renderToString } from 'react-dom/server';
import swal from 'sweetalert';
import EditReasonModal from '../../reports/editReasonModal';
import ReportsService from '../../../services/ReportsService';
import InvoicePdf from '../invoicePdf';
import BaseService from '../../../services/BaseService';

const FinalSaleReview = (props) => {
    const {finalSaleId} = props.finalSaleDetails;
    const {finalSaleDetails, finalSaleDependencies, updateIssueComments} = props;
    const loginUserId = useContext(UserContext);
    const [finalSaleInfo, setFinalSaleInfo] = useState(props.finalSaleDetails);
    const [commentTypes, setCommentTypes] = useState([]);
    const [issueComments, setIssueComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [comment, setComment] = useState("");
    const [commentTypeCodeId, setCommentTypeCodeId] = useState(null);
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [showEditReasonModal, setShowEditReasonModal] = useState(false)
    const [invoiceCommentCount, setInvoiceCommentCount] = useState(0);
    const [invoiceCommentBgcolor, setInvoiceCommentBgcolor] = useState('yellowgreen');

    useEffect(()=>{
        setFinalSaleInfo(finalSaleDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.finalSaleDetails])

    async function fetchIssueComments () {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueComments",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "issueComment":{
                    "finalSaleId" : finalSaleId 
                }
            },
            };
        
        FinalSaleReportService.getIssueComments(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0 ) {
                toast.error(result.data.errors[0].message);
            }else {
                let reasonsArr = [];
                result.data.responses[0].forEach((item, i) => {
                    item.displayComment = item.comment;
                    if( item.comment.length > 32 ) {
                        item.displayComment = item.comment.substr(0, 32) + "...";
                        item.expanded = false;
                    }
                    
                    reasonsArr.push(item);
                })
                setIssueComments(reasonsArr);
                updateIssueComments(reasonsArr);
            }
        });
    }
    useEffect(()=>{
        fetchIssueComments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[finalSaleId])
    useEffect(() => {
        async function fetchCommentTypes () {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: { cdiacCode: "CMNT", active: "Y" },
            };
        
            CountyMaintenanceService.getAllCounties(obj)
            .then((res) => res)
            .then((result) => {
                let codesArr = [];
                result.data.responses[0].forEach((item, i) => {
                    let Obj = {};
                    Obj['value'] = item.cdiacCodeValueId;
                    Obj['label'] = item.cdiacCodeValueDesc;
                    codesArr.push(Obj);
                });
                setCommentTypes(codesArr);
                setIsLoading(false);
            });
        }
        
        fetchCommentTypes();
        // eslint-disable-next-line
      }, []);

    const handleChange = (e) => {
        const {name} = e.target;
        let value = e.target.value;
        if(name === "invoiceFeeMethodCode" && value === "") {
            value = null;
        }
        setFinalSaleInfo(prevState=>({
            ...prevState, 
            [name]: value
        })
        )
    }
    const saveFinalSaleInfo = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleInfo.finalSaleId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUserId)}
            }
        };
    
        await FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                props.updateFinalSaleDetails(result.data.responses[0]);
            }
        });
        
    }
    async function getFinalSale() {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : finalSaleId  
            }
        };
        await FinalSaleReportService.getFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            setFinalSaleInfo(result.data.responses[0]);
        });
    }
    const calculateInvoiceFee = async() => {
        setIsLoading(true);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "calculateInvoiceFee",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleId,
            }
        };
    
       await FinalSaleReportService.calculateInvoiceFee(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0] === "Y") {
                getFinalSale();
            }
            setIsLoading(false);
        });
    }

    const saveInvoice = async() => {
        setIsLoading(true);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "saveInvoice",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId,
                "user": loginUserId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUserId)
                }
            }
        };
       await BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                props.updateFinalSaleDetails(result.data.responses[0].finalSaleDetails);
                toast("Invoice Saved Successfully.");
                setIsLoading(false);
            }
        });
    }
    const generateInvoice = async() => {
        setIsLoading(true);
        const html = renderToString(<InvoicePdf finalSaleDetails={finalSaleDetails}/>)

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "generateInvoice",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId,
                "user": CommonService.getUserName(loginUserId),
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUserId)
                },
                "html": html
            }
        };
    
       await BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                if(result.data && result.data.responses[0]) {
                    var base64EncodedPDF = result.data.responses[0].pdf;
                    var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
                    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                    var url = window.URL.createObjectURL(blob);
                    window.open(url);
                } 
                toast.success("Invoice Generated!");           
            }
            setIsLoading(false);
        });
    }

    const sendInvoice = async() => {
        setIsLoading(true);

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "sendInvoice",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId,
                "user": CommonService.getUserName(loginUserId),
            }
        };
    
       await BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("Invoice Sent Successfully!");           
            }
            setIsLoading(false);
        });
    }
    const validateFinancingParticipantsFees = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateFinalSaleFinancingParticipantsFees",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId
            }
        };
        FinalSaleReportService.validateFinancingParticipantsFees(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else if(result.data.responses[0].length > 0){
                let alertMessage = "";
                (result.data.responses[0]).forEach((el, i) => {
                alertMessage +=  "- " + el.message + "\n";
                });
                alert(alertMessage); 
            } else {
                if(finalSaleDetails.issueStatusFlag === "R") {
                    swal({
                        text: "Do you want to re-edit this record?",
                        icon: "warning",
                        buttons: [true, 'Yes'],
                        closeOnClickOutside: false
                    }).then((confirm) => {
                        if (confirm) {
                            setShowEditReasonModal(true)
                        } 
                    });
                } else {
                    finalSaleFinalSubmit();
                }
            }

        });
    }

    const finalSaleFinalSubmit = async() => {
        const html = renderToString(<PrintPdfComponent finalSaleDetails={finalSaleDetails} finalSaleDependencies={finalSaleDependencies} issueComments={issueComments}/>)

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "finalSaleFinalSubmit",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId,
                "user": loginUserId,
                "invoiceFeeAmt" : finalSaleInfo.invoiceFeeAmt,
                "env" : "I",
                "html": html,
                "reportType" : "RFS"
            }
        };
    
       await FinalSaleReportService.finalSaleFinalSubmit(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data && result.data.responses[0]) {
                    props.updateFinalSaleDetails(result.data.responses[0].finalSaleDetails);
                    var base64EncodedPDF = result.data.responses[0].pdf;
                    var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
                    var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
                    var url = window.URL.createObjectURL(blob);
                    window.open(url);
                } 
                toast.success("Report of Final Sale form has been successfully validated and submitted!");            }

        });
    }

    const validateRequiredFields = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId": finalSaleId
            }
        };
        FinalSaleReportService.getValidationMessages(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                if(result.data.responses[0].length > 0) {
                  let alertMessage = "";
                  result.data.responses[0].forEach(item => {
                    alertMessage +=  "- " + item.message + "\n";
                  });
                  alert(alertMessage);
                } else {
                    validateFinancingParticipantsFees();         
                }
            }

        });
    }

    const validateFinalSale =  () => {
        if((props.finalSaleDetails.invoiceFeeAmt !== finalSaleInfo.invoiceFeeAmt) || (props.finalSaleDetails.invoiceFeeMethodCode !== finalSaleInfo.invoiceFeeMethodCode)){
            saveFinalSaleInfo();
        }
        props.toggleWidget('review');
        validateRequiredFields();
    }

    const handleCommentValue = (e) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setComment(e.target.value);
    };
    const handleInvoiceComment = (e) => {
        setInvoiceCommentCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setInvoiceCommentBgcolor(color);
        
        setFinalSaleInfo(prevState=>({
            ...prevState, 
            'invoiceComment': e.target.value
        })
        )    
    };

    const saveComment = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "saveComment",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueComment" : {
                    "finalSaleId" : finalSaleId,
                    "commentTypeCodeId": commentTypeCodeId,
                    "comment": comment,
                    "createUser": CommonService.getUserName(loginUserId)
                }
            }
        };
    
       await FinalSaleReportService.saveComment(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message);
            }
            else{
                fetchIssueComments();
                setCommentTypeCodeId(null);
                setComment("");
            }
        });
    }
    const toggleText = (e, i) => {
        const history = [...issueComments];
        history[i].expanded = !history[i].expanded;
        if(history[i].expanded) {
            history[i].displayComment = history[i].comment;
        } else {
            history[i].displayComment =  history[i].comment.substr(0, 32) + "...";
        }
        setIssueComments(history);
    }
    const saveEditReason = (editReason) => {
        let obj ={
             "msgId": null,
             "sysId": null,
             "opr": "postEditReason",
             "hdrStruct": null,
             "queryParam": null,
             "reqData": {
                 "editReason" : {
                     "editReasonCodeId" : editReason.editReasonCodeId,
                     "comment" : editReason.comment,
                     "finalSaleId" : finalSaleId,
                     "createUser" : CommonService.getUserName(loginUserId)
                 }
             }
         } 

         ReportsService.saveEditReason(obj)
         .then((res) => res)
         .then((result) => {
             if(result.data.errors.length > 0) {
 
             } else {
                 toast.success('Edit Reason saved successfully!')
                 setShowEditReasonModal(false)
                 finalSaleFinalSubmit()
             }
         });
    }
    return (
        <Card>
            <div className="form" style={{paddingBottom: 0}}>
            <div className="control">
                <div>
                    <button type="button" onClick={()=>calculateInvoiceFee()}>Calculate Fee</button>
                </div>
                <Row>
                    <Col>
                        <label>Invoice Fee:</label>
                        <Form.Control type="text" name="invoiceFeeAmt" value={finalSaleInfo.invoiceFeeAmt} onChange={(e)=>handleChange(e)} />
                    </Col>
                    <Col>
                        <label>Fee Method:</label>
                        <Form.Select name="invoiceFeeMethodCode" value={finalSaleInfo.invoiceFeeMethodCode} defaultValue={finalSaleInfo.invoiceFeeMethodCode} onChange={(e)=>handleChange(e)}>
                            <option value=""></option>
                            <option value="F">Calculated</option>
                            <option value="M">Maximum</option>
                            <option value="S">Short Term</option>
                        </Form.Select>
                    </Col>
                </Row>
                <div className="row">
                    <Col>
                    <div>
                    <label>Invoice Comments:</label>
                    <textarea name="invoiceComment" value={finalSaleInfo.invoiceComment} 
                    id="invoice-comments" onChange={(e)=>handleInvoiceComment(e)}
                    className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                    <span style={{position: 'relative', left: '45%' , backgroundColor: invoiceCommentBgcolor }}>{invoiceCommentCount} out of 4000 max</span>
                    </div>
                    </Col>
                </div>
            </div>
            <div style={{overflow: 'auto'}}>
                <p className="fleft">Invoice Contact Email: <b>{finalSaleInfo.invoiceContactEmail}</b></p>
                <div className="fright">
                <button className="custom-button mr-2" disabled={!finalSaleInfo.invoiceFeeAmt || finalSaleInfo.invoiceFeeAmt === 0} onClick={()=>saveInvoice()}>Save</button>
                <button className="custom-button mr-2" disabled={!finalSaleInfo.invoiceFeeAmt || finalSaleInfo.invoiceFeeAmt === 0} onClick={()=>generateInvoice()}>Generate Invoice</button>
                <button className="custom-button" disabled={!finalSaleInfo.invoiceFeeAmt || finalSaleInfo.invoiceFeeAmt === 0} onClick={()=>sendInvoice()}>Send Invoice</button>
                </div>
            </div>
            <hr />
            <div>
                <label>Comments: </label>
                {issueComments.length > 0 &&
                <Table>
                <thead>
                    <tr>
                        <th>Comment Type</th>
                        <th>Comment</th>
                        <th>Create User</th>
                        <th>Create Date</th>
                    </tr>
                </thead>
                <tbody>
                    {issueComments.map((comment,i)=>{
                            return (
                                <tr key={i}>
                                <td>{comment.cdiacCodeValueDesc}</td>
                                <td>{comment.displayComment}&nbsp; 
                                {comment.hasOwnProperty('expanded') ? 
                                    (comment.expanded ? 
                                    <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show less
                                    </button>
                                    : <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show more
                                    </button>
                                    ) : ""
                                }
                                </td>
                                <td>{comment.createUser}</td>
                                <td>{CommonService.dateFormatter(comment.createDatetime)}</td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
            }
                <Row>
                    <Col md={6}>
                    <label>Comment Type</label>
                    <Select 
                    options={commentTypes} 
                    isLoading={isLoading}
                    onChange={(e)=>setCommentTypeCodeId(e.value)}
                    />
                    </Col>
                    
                </Row>
                <textarea name="comment" value={comment} 
                id="comments-input" onChange={(e)=>handleCommentValue(e)}
                    className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                    <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                    <span style={{position: 'relative', float: 'right', marginTop: '5px' }}><button type="button" disabled={(commentTypeCodeId !== null && comment !== "") ? false : true}onClick={()=>saveComment()}>Submit</button></span>
                </div>
            </div>
            <div className="separator">REVIEW</div>
            <PrintPdfComponent finalSaleDetails={finalSaleDetails} finalSaleDependencies={finalSaleDependencies} issueComments={issueComments}/> 
            <div className="form">
                <div className="btn-div">
                {/* <Link to="/dashboard/report-proposed-sale/upload-documents"><button className="custom-button">Back</button></Link> */}
                <button className="fright custom-button" type="button" onClick={()=>validateFinalSale()}>Validate & Submit</button>
                </div>

            </div>
            {showEditReasonModal && <EditReasonModal  onModalClose={()=>setShowEditReasonModal(false)} handleSave={saveEditReason} cdiacReasonCode="RFSER"/>}
        </Card>
    )
}

export default FinalSaleReview
