import React, { useState, useEffect, useCallback, useContext } from 'react'
import Card from '../../ui/Card'
import { Form, Row, Col, Modal, FormGroup, Alert } from "react-bootstrap"
import { FormInput } from '../../formElements/FormInput'
import CurrencyInput from '../../formElements/CurrencyInput'
import { runValidations, validateFormFields } from '../../../utils/index'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {FaPlusCircle, FaMinusCircle} from 'react-icons/fa'
import swal from 'sweetalert'
import { toast } from 'react-toastify'
import IssueCdiacNumberService from '../../../services/IssueCdiacNumberService'
import IssuerService from '../../../services/IssuerService'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import FinalSaleReportService from '../../../services/FinalSaleReportService'
import IssueSocialImpactService from '../../../services/IssueSocialImpactService'
import CommonService from '../../../services/CommonService'
import { Typeahead } from 'react-bootstrap-typeahead'
import AddSocialImpactBond from './addSocialImpactBond'
import Table from '../../Table'
import { IssuerContext, UserContext } from '../../../UserContext'
import TooltipComponent from '../../../common/Tooltip'

const socialImpactBonds = [
    { code: "G", value: "Green"},
    { code: "S", value : "Social Impact"},
    { code: "U", value : "Sustainability"},
    { code: "N", value : "None"}
]
const IssuerDetails = (props) => {
    const {issuerDetails} = props;
return (
    <Modal show={true} onHide={props.onModalClose}>
        <Modal.Header closeButton>
            <h1>Issuer Details</h1>
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Col md={6}>
                    <label>Address Line1</label>
                    <Form.Control type="text" value={issuerDetails.contactAddressLine1} readOnly/>
                </Col>
                <Col md={6}>
                    <label>Address Line2</label>
                    <Form.Control type="text" value={issuerDetails.contactAddressLine2} readOnly/>
                </Col>
                <Col md={6}>
                    <label>Address Line3</label>
                    <Form.Control type="text" value={issuerDetails.contactAddressLine3} readOnly/>
                </Col>
                <Col md={6}>
                    <label>Address Line4</label>
                    <Form.Control type="text" value={issuerDetails.contactAddressLine4} readOnly/>
                </Col>
                <Col md={6}>
                    <label>City</label>
                    <Form.Control type="text" value={issuerDetails.contactCityName} readOnly/>
                </Col>
                <Col md={6}>
                    <label>State</label>
                    <Form.Control type="text" value={issuerDetails.contactStateCode} readOnly/>
                </Col>
                <Col md={6}>
                    <label>ZipCode</label>
                    <Form.Control type="text" value={issuerDetails.contactZipCode} readOnly/>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
        </Modal.Footer>
    </Modal>
)
}
const Issuance = (props) => {
    const {finalSaleDetails, updateFinalSaleDetails, finalSaleDependencies} = props;
    const issueId = props.finalSaleDetails.finalSaleId;
    const issuerId = useContext(IssuerContext);
    const loginUser = useContext(UserContext);
    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    const [issuanceInfo, setIssuanceInfo] = useState(finalSaleDetails ? finalSaleDetails : {});

    const [formErrors, setFormErrors] = useState({})
    const [showCdiacSection, setShowCdiacSection] = useState(false);
    const [netPremium, setNetPremium] = useState(0);
    const [cdiacNumbersList, setCdiacNumbersList] = useState([]);
    const [issuerDetails, setIssuerDetails] = useState(null);
    const [issueRefundCdiacNumbers, setIssueRefundCdiacNumbers] = useState([]);
    const [showDebtPolicyHelpTextModal, setShowDebtPolicyHelpTextModal] = useState(false);
    const [showDebtForRefundingTextField, setShowDebtForRefundingTextField] = useState(finalSaleDetails && finalSaleDetails.refndRedeemPaydnRefinInd === 'Y' ? true : false);
    const [errorMessage, setErrorMessage] = useState();
    const [addSocialImpactBond, setAddSocialImpactBond] = useState(false);
    const [editIdx, setEditIndex] = useState(-1);
    const [total, setTotal] = useState(0.00);
    const [cdiacRedeemTotal, setCdiacRedeemTotal] = useState(0.00);
    const [issueSocialImpactBonds, setIssueSocialImpactBonds] = useState([]);
    const [mkrLobcdiacNbrs, setMkrLobCdiacNbrs] = useState(finalSaleDependencies && finalSaleDependencies.issueMkrAuthAssocs.length > 0 ? finalSaleDependencies.issueMkrAuthAssocs : [{authCdiacNbr: ''}]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssuerDetailsByIssuerId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issuerId 
            }
        };
       
        IssuerService.getIssuerContactByIssuerId(obj)
          .then((res) => res)
          .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
              if(result.data.responses.length > 0) {
                setIssuerDetails(result.data.responses[0]);
              }
            }
        });
    }, [issuerId]);

    useEffect(()=>{
        if(issuanceInfo.marksRoosLocalObligInd === 'Y') {
            setShowCdiacSection(true);
            getCdiacNumbers();
        }
    }, [issuanceInfo.marksRoosLocalObligInd, issueId])
    
    const getMkrAuthAssocs = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getMkrAuthLobAssocsByCdiacNbr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "cdiacNbr" : finalSaleDetails.cdiacNbr
            }
        };
       
        ReportOfProposedDebtService.getMkrAuthLobAssocsByIssueId(obj)
          .then((res) => res)
          .then((result) => {
              if(result.data.responses[0].length > 0) {
                setMkrLobCdiacNbrs(result.data.responses[0]);
                props.updateFinalSaleDependencies(result.data.responses[0], "issueMkrAuthAssocs");
              } else {
                setMkrLobCdiacNbrs([
                    {authCdiacNbr: ''}
                ]);
              }
          });
    }
    useEffect(()=>{
        calculateNetPremium(issuanceInfo)
        // eslint-disable-next-line
    }, [issuanceInfo.premiumAmt, issuanceInfo.premiumDiscountAmt]);

    useEffect(() => {
        calculateSocialImpactAmountTotal(issueSocialImpactBonds);
    }, [issueSocialImpactBonds]);

    const calculateSocialImpactAmountTotal = (issueSocialImpactBonds) => {
        const total = issueSocialImpactBonds.reduce((total, item) => total + parseFloat(item.envSocialImpactAmt !== "" ? 
        ( typeof item.envSocialImpactAmt === "string" ? item.envSocialImpactAmt.replace(/,/g,''): item.envSocialImpactAmt)
         : 0), 0);
        setTotal(total.toLocaleString());
    }
    useEffect(() => {
        calculateRedeemAmountTotal(issueRefundCdiacNumbers);
    }, [issueRefundCdiacNumbers]);

    const calculateRedeemAmountTotal = (issueRefundCdiacNumbers) => {
        const redeemTotal = issueRefundCdiacNumbers.reduce((total, item) => total + parseFloat(item.amount !== "" ? 
        ( typeof item.amount === "string" ? item.amount.replace(/,/g,''): item.amount)
         : 0), 0);
        setCdiacRedeemTotal(redeemTotal.toLocaleString());
    }

    const calculateNetPremium=(issuanceInfo) => {
        const {premiumAmt, premiumDiscountAmt} = issuanceInfo;
        setNetPremium(Number(premiumAmt && premiumAmt !== "" ? (typeof (premiumAmt) === 'number' ? premiumAmt : premiumAmt.replace(/,/g, '') ) : 0) - Number(premiumDiscountAmt && premiumDiscountAmt !== '' ? (typeof (premiumDiscountAmt) === 'number' ? premiumDiscountAmt : premiumDiscountAmt.replace(/,/g,'')) : 0))
    }

    useEffect(()=> {
        showDebtForRefundingTextField && getIssueRefundAssocCdiac();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showDebtForRefundingTextField])
    
    const getIssueSocialImpactBonds = useCallback(async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueSocEnvImpactsByFinalSaleId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { "id" : issueId }
        };
       await IssueSocialImpactService.getIssueSocEnvImpactsByIssueId(obj)
          .then((res) => res)
          .then((result) => {
              let envSocialImpactsArr = [];
              result.data.responses[0].forEach((item, i) => {
                let socialImpact = socialImpactBonds.find(ref => ref.code === item.envSocialImpactCode);
                let envSocialImpact = {...item, 
                  'envSocialImpactValue' : socialImpact.value
                }
                envSocialImpactsArr.push(envSocialImpact);
              }); 
            setIssueSocialImpactBonds(envSocialImpactsArr);
            //setIsLoading(false);
          });
    },[issueId]);

    useEffect(()=>{
        getIssueSocialImpactBonds();
        // eslint-disable-next-line
    },[]);

    const handleChange = (e) => {  
        const {name, value} = e.target;
        setIssuanceInfo({
            ...issuanceInfo,
            [name]: value
        });

        if( name === "marksRoosLocalObligInd") {
            if(value === 'Y') {
                setShowCdiacSection(true);
            } else {
                setShowCdiacSection(false);
            }
        }

        if( name === "refndRedeemPaydnRefinInd"){
            if( value === 'Y' && e.target.checked ) {
                setShowDebtForRefundingTextField(true)
            } else {
                setShowDebtForRefundingTextField(false)
            }
       }
    }
    const handleCdiacNbrChange = (e, i) => {
        const selectedValue = e.length ? e[0].cdiacNbr : "";
        
        let cidacNumArr = [...mkrLobcdiacNbrs];
        cidacNumArr[i].authCdiacNbr = selectedValue;
        setMkrLobCdiacNbrs(cidacNumArr);
    }

    const getCdiacNumbers = () => {
        setIsLoading(true);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getCdiacNumbers",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {}
        };
       
        IssueCdiacNumberService.getCdiacNumbers(obj)
          .then((res) => res)
          .then((result) => {
            setCdiacNumbersList(result.data.responses[0]);
            setIsLoading(false);
          });
    }
    const addCdiacNumber = () => {
        setMkrLobCdiacNbrs(prevState=>([
            ...mkrLobcdiacNbrs, {authCdiacNbr: ''}
        ]))
    }
    const removeCdiacNumber = (i) => {
        let cidacNumArr = [...mkrLobcdiacNbrs];

        const mkrAuthLobId =  cidacNumArr[i].mkrAuthLobId;
        if ( mkrAuthLobId ) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "deleteMkrAuthLobAssoc",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": mkrAuthLobId
                }
            };
        
            IssueCdiacNumberService.deleteMkrAuthLobAssoc(obj)
            .then((res) => res)
            .then((result) => {
                getMkrAuthAssocs();
                // if(cidacNumArr.length === 1) {
                //     cidacNumArr.splice(i,1);
                //     let obj = {};
                //     obj.authCdiacNbr = ""
                //     cidacNumArr.push(obj);
                // } else {
                //     cidacNumArr.splice(i,1);
                // }
                // setMkrLobCdiacNbrs(cidacNumArr);
            });
        } else {
            if(cidacNumArr.length === 1) {
                cidacNumArr[0].authCdiacNbr = ""
            } else {
                cidacNumArr.splice(i,1);
            }
            setMkrLobCdiacNbrs(cidacNumArr);
        } 
    }
    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }
    const saveIssuance = () => {
        let envTotal = ( typeof total === "string" ? total.replace(/,/g,'') : total);
        let rfndRedeemTotal = ( typeof cdiacRedeemTotal === "string" ? Number(cdiacRedeemTotal.replace(/,/g,'')) : cdiacRedeemTotal);
        let refndRedeemPaydnRefinAmt = ( typeof issuanceInfo.refndRedeemPaydnRefinAmt === "string" ? Number(issuanceInfo.refndRedeemPaydnRefinAmt.replace(/,/g,'')) : issuanceInfo.refndRedeemPaydnRefinAmt);
        if(rfndRedeemTotal > refndRedeemPaydnRefinAmt) {
            swal("The CDIAC amounts entered exceed the Total Refunding Amount.");
            return;
        }
        
        if(envTotal > issuanceInfo.principalSaleAmt) {
            swal("The sum of all the environmental and social impacts cannot exceed the principal amount.");
        } else {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "putFinalSale",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": issuanceInfo.finalSaleId,
                    "finalSale": {...issuanceInfo,
                        'lastUpdateUser': CommonService.getUserName(loginUser)}
                }
            };
        
            FinalSaleReportService.updateFinalSale(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.responses[0]) {
                    toast.success("Issuance updated successfully!");
                    updateFinalSaleDetails(result.data.responses[0]);
                    props.sectionValidated('issuance', true);
                    props.openWidget('dates');
                }
            });
        }
    }
    const saveMkrAuthLobCdiacNbrs = async (cdiacNumsArr) => {
        for (const item of cdiacNumsArr) {
            if(!item.mkrAuthLobId) {
                let obj = {
                    "msgId": null,
                    "sysId": null,
                    "opr": "postMkrAuthLobAssoc",
                    "hdrStruct": null,
                    "queryParam": null,
                    "reqData": {
                        "mkrAuthLobAssoc": {
                            "authCdiacNbr": item.authCdiacNbr,
                            "lobCdiacNbr" : finalSaleDetails.cdiacNbr,
                            "createUser": loginUser
                        }
                    }
                };
            
                await IssueCdiacNumberService.saveMkrLobAssoc(obj)
                .then((res) => res)
                .then((result) => {
                    if(result.data.errors.length > 0) {
                        toast.error(result.data.errors[0].message);
                    } else {
                        props.updateFinalSaleDependencies(result.data.responses[0], "issueMkrAuthAssocs");
                    }
                });
            }
        }
        
    }
    const validateForm = (e) => {
        let form = e.target.closest('form#issuerForm');

        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('issuance', false);
        } else {
            setFormErrors({});
            //setFormSubmittingLoader(true);
            if(issuanceInfo.marksRoosAuthorityInd === 'Y' || issuanceInfo.marksRoosLocalObligInd === 'Y'){
                swal("The costs of issuance may be reported at EITHER the Authority level or at the associated LOB/Participant level, but when aggregated should not exceed the total costs of issuance for the associated Authority and LOBs/Participants involved in this pooled financing. For example, if bond counsel services for the pooled financing total $50,000, the costs of bond counsel services reported for the Authority and each LOB/Participant shall not exceed $50,000 when added together.",{
                    buttons: [false, true],
                }).then(async(value) => {
                    if(mkrLobcdiacNbrs[0].authCdiacNbr !== "") {
                        let cdiacNumsArr = [...mkrLobcdiacNbrs];                   
                        await saveMkrAuthLobCdiacNbrs(cdiacNumsArr);                    
                    }
                    saveIssuance();
                })
            } else {
                saveIssuance();
            }
        }
    }

    const handleCdiacAssocChange = (e, i) => {
        const {name, value} = e.target;
        let cidacNumArr = [...issueRefundCdiacNumbers];
        cidacNumArr[i][name] = value;
        setIssueRefundCdiacNumbers(cidacNumArr);
    }

    const addIssueRefundCdiacNumber = () => {
        setIssueRefundCdiacNumbers(prevState=>([
            ...issueRefundCdiacNumbers, {cdiacNum: '', amount: ''}
        ]))
    }
  
    const validateCdiacNumber = async (i, action) => {
        let cidacNumArr = [...issueRefundCdiacNumbers];
        const cdiacNbr = cidacNumArr[i].cdiacNum;
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "IsIssuerAssocValidCdiacNumber",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "cdiacNumber" : cdiacNbr,
                "issuerId": issuerId
            }
        };
    
      await IssueCdiacNumberService.validateIssueRefundCdiac(obj)
        .then((res) => res)
        .then((result) => {
            const response = result.data.responses[0];
            if(!response.isValidCdiac) {
                toast.error("This CDIAC Number does not exist or has been cancelled.");
            } else {
                if(!response.IsIssuerAssocValidCdiacNumber) {
                    swal({
                        text: "The CDIAC number you have entered is associated with a different Issuer.Do you wish to continue?",
                        icon: "warning",
                        buttons: ["No", "Yes"],
                    })
                    .then((yesContinue) => {
                        if (yesContinue) {
                            if(action === "create"){
                                saveIssueRefundCdiac(i);
                            } else if(action === "update"){
                                updateIssueRefundCdiac(i);
                            }else{}
                        } else {
                        swal.close();
                        }
                    });
                } else {
                    if(action === "create"){
                        saveIssueRefundCdiac(i);
                    } else if(action === "update"){
                        updateIssueRefundCdiac(i);
                    }else{}
                }
            }
        });
    }
    const getIssueRefundAssocCdiac = async (i) => {
        let cidacNumArr = [];

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueRefundAssocByCdiacNbr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "cdiacNbr" : finalSaleDetails.cdiacNbr
            }
        };
    
      await ReportOfProposedDebtService.getIssueRefundAssocsByIssueId(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                setErrorMessage(result.data.errors[0].message)
            }
            else{
                result.data.responses[0].forEach((item,i) => {
                    const cdiacNbrObj = {};
                    cdiacNbrObj.cdiacNum = item.linkedCdiacNbr;
                    cdiacNbrObj.issueRefundId = item.issueRefundId;
                    cdiacNbrObj.amount = item.redeemAmt;
                    cidacNumArr.push(cdiacNbrObj);
                })
                setIssueRefundCdiacNumbers(cidacNumArr);
                props.updateFinalSaleDependencies(result.data.responses[0], 'issueRefundAssocs');
            }
        });
    }
    const saveIssueRefundCdiac = async (i) => {
        let cidacNumArr = [...issueRefundCdiacNumbers];
        const cdiacNbrObj = cidacNumArr[i];

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "postIssueRefundAssoc",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueRefundAssoc" : {
                    'linkedCdiacNbr': cdiacNbrObj.cdiacNum,
                    'redeemAmt': cdiacNbrObj.amount ,
                    'cdiacNbr' : finalSaleDetails.cdiacNbr,
                    'createUser': loginUser
                },
                "env": "RFS"
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                setErrorMessage(result.data.errors[0].message)
            }
            else{
                getIssueRefundAssocCdiac();
                // const issueRefundId = result.data.responses[0].newId;
                // cdiacNbrObj['issueRefundId'] = issueRefundId;
                 setCdiacRedeemTotal(result.data.responses[0].cdiacRedeemTotal);
                // setIssueRefundCdiacNumbers(cidacNumArr);
                toast.success("CDIAC # saved successfully!");
            }
        });
    }
    const updateIssueRefundCdiac = async (i) => {
        let cidacNumArr = [...issueRefundCdiacNumbers];
        const issueRefundId = cidacNumArr[i].issueRefundId;
        if( issueRefundId ) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "updateIssueRefundAssoc",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": issueRefundId,
                    "issueRefundAssoc" : {
                        "linkedCdiacNbr": cidacNumArr[i].cdiacNum,
                        "redeemAmt": cidacNumArr[i].amount,
                        "lastUpdateUser" : loginUser
                    }
                }
            };
        
            await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    getIssueRefundAssocCdiac();
                    toast.success("Cdiac Number and Amount Updated successfully!");
                }
            });
        }
    }
    const deleteIssueRefundCdiac = async (i) => {
        let cidacNumArr = [...issueRefundCdiacNumbers];
        const issueRefundId = cidacNumArr[i].issueRefundId;
        if( issueRefundId ) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "deleteIssueRefundAssoc",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": issueRefundId
                }
            };
        
            await IssueCdiacNumberService.deleteIssueRefundAssoc(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    getIssueRefundAssocCdiac();
                    toast.success("Cdiac Number deleted successfully!");
                }
            });
        } else {
            cidacNumArr.splice(i,1);
            setIssueRefundCdiacNumbers(cidacNumArr);
        }
    }

    const startEditing = i => {
        setEditIndex(i);
    };
    
    const stopEditing = () => {
        setEditIndex(-1);
    };

    const updateSocialEnvImpact = (socEnvImpactData)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueSocEnvImpact",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": socEnvImpactData.envSocialImpactId,
                "issueSocialEnvironmentalImpact": socEnvImpactData
            }
        };

        IssueSocialImpactService.updateSocialEnvImpact(obj)
        .then((res) => res)
        .then((result) => {
            getIssueSocialImpactBonds()
        });
    }

    const deleteSocialEnvImpact = (socEnvImpactId)  => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "deleteIssueSocEnvImpact",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": {
            "id" : socEnvImpactId,
          }
      };

      IssueSocialImpactService.deleteSocialEnvImpact(obj)
      .then((res) => res)
      .then((result) => {
        //   alert(
        //     'Deleted Successfully'
        //   )
      });
    }

    const handleSave = (i, rowData) => {
        updateSocialEnvImpact(rowData);
        setEditIndex(-1);
    }
    const handleRemove = (i, rowData) => {
        const socEnvImpactId = rowData.envSocialImpactId;
        deleteSocialEnvImpact(socEnvImpactId);
        setIssueSocialImpactBonds(
            issueSocialImpactBonds.filter((row, j) => j !== i)
        );
      
    }
    const handleEnvironmentalSocialImpactChange = (e, name, i) => {
        const { value } = e.target;
        const issueSocialImpactBondsnew =[...issueSocialImpactBonds];        
        issueSocialImpactBondsnew[i][name] = value;
    }

    const handleModalClose = () => {
        setAddSocialImpactBond(false);
        getIssueSocialImpactBonds();
    }
    return (
        <Card>
             {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
        <Form className="form" id="issuerForm">
            <div style={{float: 'right', marginTop: '10px'}}>
                <button
                    type="button"
                    className="dk-blue-color link-button" 
                    onClick={()=>setShowIssuerDetails(true)}
                    >View Issuer details</button>
            </div>
            <FormInput label="Issuer Name" 
                name="issuerName" 
                type="text" 
                // onChange={(e)=>handleChange(e)} 
                // onBlur={e=>validateField(e)}
                // error={formErrors.issuerName} 
                // validations={["required", "maxLength:120"]}
                // isrequired="true"
                value={issuerDetails && issuerDetails.issuerName}
                disabled={true}
                helpText="Name of the issuer"/>
            
            <FormInput label="Issue Name" 
                name="issueName" 
                type="text" 
                value={issuanceInfo.issueName}
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                error={formErrors.issueName} 
                validations={["required", "maxLength:200"]}
                isrequired="true"
                helpText="Name of the issue"/>

            <FormInput label="Project Name" 
                name="projectName" 
                type="text" 
                value={issuanceInfo.projectName}
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                error={formErrors.projectName} 
                validations={["maxLength:200"]}
                helpText="Name of the project"/>
            <Row>
                <Col md={6}>
                    <div className="control">
                    <CurrencyInput placeholder="0.00" 
                    label="Principal" 
                    name="principalSaleAmt" 
                    type="text" 
                    value={issuanceInfo.principalSaleAmt ? issuanceInfo.principalSaleAmt : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.principalSaleAmt} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired="true"
                    helpText="Prepopulated from data submitted on the RPDI, update if necessary."/>
                    </div>
                </Col>            
                <Col md={6}>
                    <div className="control">
                        <CurrencyInput placeholder="0.00" 
                        label="Original Issue Premium" 
                        name="premiumAmt" 
                        type="text" 
                        value={issuanceInfo.premiumAmt ? issuanceInfo.premiumAmt : ""}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.premiumAmt} 
                        validations={["required", "max:999999999999.99"]}
                        isrequired="true"
                        helpText="Enter the total dollar amount of the original issue premium, if applicable. For a public bond offering, this
                        information is generally identified in the official statement."
                    />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="control">
                    <CurrencyInput placeholder="0.00" 
                    label="Original Issue Discount" 
                    name="premiumDiscountAmt" 
                    type="text" 
                    value={issuanceInfo.premiumDiscountAmt ? issuanceInfo.premiumDiscountAmt : ""}
                    onChange={(e)=>handleChange(e)} 
                    onBlur={e=>validateField(e)}
                    error={formErrors.premiumDiscountAmt} 
                    validations={["required", "max:999999999999.99"]}
                    isrequired="true"/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="control">
                        <label>Net Original Issue Premium/Discount:</label>
                        <Form.Control placeholder="0.00" 
                        name="netPremium" 
                        type="text"
                        value={CommonService.currencyFormatter(netPremium)}
                        disabled={true}
                        />
                    </div>
                </Col>
            </Row>
                
            <br />
            <div className="mb-3">
                <label><span className="required">*</span>Is any portion of the principal or net original issue premium used to refund, redeem, paydown, or refinance outstanding debt?
                    <TooltipComponent
                title="Select “Yes” if any amount of the proposed principal will be used to redeem securities, pay down
                outstanding balances, or refund or refinance outstanding debt."
                id="mlr-reports"
                placement="right">
                    <AiFillQuestionCircle className="help-icon"/>
                </TooltipComponent>
                </label>
                <div>
                <Form.Check
                    style={{marginBottom: '1rem'}}
                    inline
                    label="No"
                    value="N"
                    name="refndRedeemPaydnRefinInd"
                    type="radio"
                    id="refndRedeemPaydnRefinInd_0"
                    checked={issuanceInfo.refndRedeemPaydnRefinInd === 'N'}
                    onChange={(e)=>handleChange(e)}
                />
                <Form.Check
                    inline
                    label="Yes"
                    value="Y"
                    name="refndRedeemPaydnRefinInd"
                    type="radio"
                    id="refndRedeemPaydnRefinInd_1"
                    checked={issuanceInfo.refndRedeemPaydnRefinInd === 'Y'}
                    onChange={(e)=>handleChange(e)}
                />
                {formErrors['refndRedeemPaydnRefinInd'] && <p className="error">{formErrors['refndRedeemPaydnRefinInd']}</p>}
                {showDebtForRefundingTextField && 
                    <>
                    <Row>
                        <Col md={6} >
                        <div className="control">
                            <label><span className="required">*</span>Total Refunding Amount from Principal & Net Premium:
                            <TooltipComponent
                            title="Prepopulated from data submitted on the RPDI as to principal only. Update to the amount of principal and net
                            premium used to refund, redeem, pay down or refinance outstanding debt. The total refunding amount may not
                            exceed the total of principal and net original issue premium submitted for this debt issue."
                            id="mlr-reports"
                            placement="right">
                                <AiFillQuestionCircle className="help-icon"/>
                            </TooltipComponent>
                            </label>
                            <CurrencyInput placeholder="0.00" 
                            name="refndRedeemPaydnRefinAmt" 
                            value={issuanceInfo.refndRedeemPaydnRefinAmt} 
                            error={formErrors.refndRedeemPaydnRefinAmt}
                            onChange={(e)=>handleChange(e)}
                            label="" validations={["required"]}/>
                        </div>
                        </Col>  
                    </Row>
                    
                    <label>Enter CDIAC# and amount of the debt to be refunded, redeemed, paid down, or refinanced:
                        <TooltipComponent
                        title="Prepopulated from data submitted on the RPDI as to principal only. Update previously submitted CDIAC #s and
                        amounts to include net original issuance premium, if applicable. If blank, enter the CDIAC #s and amounts of the
                        outstanding debt that the Total Refunding Amount will refund, redeem, paydown, or refinance. Multiple
                        CDIAC#s may be added. Amounts in total may not exceed the Total Refunding Amount."
                        id="mlr-reports"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon"/>
                        </TooltipComponent>
                    </label>
                    <br/>
                    <button type="button" className="custom-button" onClick={()=>addIssueRefundCdiacNumber()}>Add</button>
                    {issueRefundCdiacNumbers.length > 0 && <Row>
                        <Col md={9} >
                            <table>
                                <thead>
                                    <tr>
                                        <td>CDIAC #</td>
                                        <td>Amount</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                {issueRefundCdiacNumbers && issueRefundCdiacNumbers.map((item, i) => {
                                    return <tr key={i}>
                                        <td>
                                            <div className="control">
                                                <Form.Control type="text" name="cdiacNum" value={item.cdiacNum} 
                                                onChange={(e)=>handleCdiacAssocChange(e, i)}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="control">
                                                <CurrencyInput placeholder="0.00" type="text" name="amount" value={item.amount} onChange={(e)=>handleCdiacAssocChange(e, i)}/>
                                            </div>
                                        </td>
                                        <td> 
                                            {item.issueRefundId ?  
                                            <button type="button" className="custom-button button-text" onClick={()=>validateCdiacNumber(i, "update")}>Update</button>
                                            :
                                            <button type="button" className="custom-button button-text" onClick={()=>validateCdiacNumber(i, "create")}>Save</button>                               
                                            }{" "}
                                            <button type="button" className="custom-button button-text" onClick={()=>deleteIssueRefundCdiac(i)}>Delete</button>
                                        </td>
                                    </tr>
                                }) }
                                </tbody>
                            </table>
                            
                        </Col>  
                    </Row>
                    }
                    </>
                }
                </div>
            </div>

            <br />
            <div className="mb-3">
                <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Issuer certifies that it has complied with Gov. Code section 8855(i) with respect to local debt policies:{"  "}
                <TooltipComponent
                title="Click me for more info."
                id="mlr-reports"
                placement="right">
                    <AiFillQuestionCircle onClick={()=>setShowDebtPolicyHelpTextModal(true)} className="help-icon heading-help-icon"/>
                </TooltipComponent>

                {showDebtPolicyHelpTextModal && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowDebtPolicyHelpTextModal(false)}>
                        <Modal.Body>
                            <div>
                                <span>The issuer must provide certification that it has adopted local debt policies concerning the use of debt
and that this contemplated debt issuance is consistent with those local debt policies. A local debt policy
shall include all of the following:</span>
                                <ul style={{listStyleType: 'lower-alpha'}}>
                                    <li>The purposes for which the debt proceeds may be used.</li>
                                    <li>The types of debt that may be issued.</li>
                                    <li>The relationship of the debt to, and integration with, the issuer’s capital improvement program or
                                        budget, if applicable.</li>
                                    <li>Policy goals related to the issuer’s planning goals and objectives.</li>
                                    <li>
                                        <p>The internal control procedures that the issuer has implemented, or will implement, to ensure that
                                        the proceeds of the proposed debt issuance will be directed to the intended use.</p>
                                        <p>In the case of an issue of debt the proceeds of which will be used by a governmental entity other than
                                        the issuer, the issuer may rely upon a certification by that other governmental entity that it has adopted
                                        the policies described in (C), (D), and (E), and the references to the “issuer” in those sections shall be
                                        deemed to refer instead to the other governmental entity.</p>
                                        <ul>
                                            <li>“No” indicates that the issuer cannot certify the adoption of statutorily compliant local debt
                                            policies.</li>
                                            <li>“Yes” indicates certification that the issuer has adopted local debt policies in compliance with
                                            Gov. Code section 8855(i).</li>
                                            <li>“N/A” indicates that the issuer is not an issuer of local debt (e.g. state or state instrumentality)
                                            or the entity that will use the debt proceeds is a non-governmental entity.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" onClick={()=>setShowDebtPolicyHelpTextModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }</label>
                <br />
                <Form.Check
                        inline
                        label="No"
                        value="N"
                        name="debtPolicyFlag"
                        type="radio"
                        id="debtPolicyFlag-1"
                        checked={issuanceInfo.debtPolicyFlag === "N"}
                        onChange={(e)=>handleChange(e)}
                    />
                <Form.Check
                        inline
                        label="Yes"
                        value="Y"
                        name="debtPolicyFlag"
                        type="radio"
                        id="debtPolicyFlag-2"
                        checked={issuanceInfo.debtPolicyFlag === "Y"}
                        onChange={(e)=>handleChange(e)}
                    />
                <Form.Check
                        inline
                        label="N/A"
                        value="NA"
                        name="debtPolicyFlag"
                        type="radio"
                        id="debtPolicyFlag-3"
                        checked={issuanceInfo.debtPolicyFlag === "NA"}
                        onChange={(e)=>handleChange(e)}
                    />
                {formErrors['debtPolicyFlag'] && <p className="error">{formErrors['debtPolicyFlag']}</p>}
            </div>

            <div>
            <Row>
                    <Col>
                        <div>
                            <label>Environmental / Social Impact Designation:</label>
                            <br/>
                            <button type="button" className="custom-button" onClick={()=>setAddSocialImpactBond(true)}>Add</button>
                            {issueSocialImpactBonds.length !==0 && <><Table 
                            handleRemove={handleRemove}
                            handleSave={handleSave}
                            startEditing={startEditing}
                            editIdx={editIdx}
                            stopEditing={stopEditing}
                            handleChange={handleEnvironmentalSocialImpactChange}
                            data={issueSocialImpactBonds}
                            header={[
                            {
                                name: "Environmental / Social Impact Type",
                                prop: "envSocialImpactValue",
                                editable: false
                            },
                            {
                                name: "Amount",
                                prop: "envSocialImpactAmt",
                                fieldType: "currency",
                                editable: true
                            }
                            ]}
                            />
                            <Row>
                                <Col md={{ span: 5, offset: 7 }}>
                                    <FormGroup style={{display: 'inline-flex'}} controlId="formInlineName">
                                        <label>Total: </label>
                                        <Form.Control type="text" value={'$'+total} readOnly/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            </>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
            
            <div className="mb-3">
                <label htmlFor="marksRoosAuthorityInd">Marks-Roos Authority/TRAN Pool Authority:
                <TooltipComponent
                title="Is this an issue of debt by an Authority under the Marks-Roos Bond Pooling Act? [Gov. Code section 6584 -
                    6599.3] [CCR Title 4 §6000(a)]."
                id="mlr-reports"
                placement="right">
                    <AiFillQuestionCircle className="help-icon"/>
                </TooltipComponent>
                </label>
                <Form.Check
                    inline
                    label="No"
                    value="N"
                    name="marksRoosAuthorityInd"
                    type="radio"
                    id="marksRoosAuthorityInd-2"
                    data-validations="required"
                    onChange={(e)=>handleChange(e)}
                    checked={issuanceInfo.marksRoosAuthorityInd === 'N'}
                />
                <Form.Check
                    inline
                    label="Yes"
                    value="Y"
                    name="marksRoosAuthorityInd"
                    type="radio"
                    id="marksRoosAuthorityInd-1"
                    data-validations="required"
                    checked={issuanceInfo.marksRoosAuthorityInd === 'Y'}
                    onChange={(e)=>handleChange(e)}
                />
                 
                {formErrors.marksRoosAuthorityInd && <p className="error">{formErrors.marksRoosAuthorityInd}</p>}
            </div>

            <div className="mb-3">
                <label htmlFor="marksRoosLocalObligInd">Marks-Roos Local Obligor/TRAN Participant:
                <TooltipComponent
                title="Is this an issue of debt by a Local Obligor under the Marks-Roos Bond Pooling Act? [Gov. Code Section 6584 -
                    6599.3] [CCR Title 4 §6000(w)]."
                id="mlr-reports"
                placement="right">
                    <AiFillQuestionCircle className="help-icon"/>
                </TooltipComponent>
                </label>
                <Form.Check
                    inline
                    label="No"
                    value="N"
                    name="marksRoosLocalObligInd"
                    type="radio"
                    id="marksRoosLocalObligInd-2"
                    data-validations="required"
                    onChange={(e)=>handleChange(e)}
                    checked={issuanceInfo.marksRoosLocalObligInd === 'N'}
                />
                <Form.Check
                    inline
                    label="Yes"
                    value="Y"
                    name="marksRoosLocalObligInd"
                    type="radio"
                    id="marksRoosLocalObligInd-1"
                    data-validations="required"
                    onChange={(e)=>handleChange(e)}
                    checked={issuanceInfo.marksRoosLocalObligInd === 'Y'}
                />
                
                {formErrors.marksRoosLocalObligInd && <p className="error">{formErrors.marksRoosLocalObligInd}</p>}
            </div>

            {showCdiacSection &&  <Row>
            <label>Please Select CDIAC# of the related Authorities:</label>
                <Col md={6} >
                    <table>
                        <thead>
                            <tr>
                                <td>CDIAC #</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                        {mkrLobcdiacNbrs && mkrLobcdiacNbrs.map((item, i) => {
                            return <tr key={i}>
                                <td>
                                    <Typeahead
                                            placeholder="Choose CDIAC Number"
                                            id="cdiacNbr"
                                            labelKey="cdiacNbr"
                                            onChange={e=>handleCdiacNbrChange(e, i)}
                                            options={cdiacNumbersList}
                                            isLoading={isLoading}
                                            selected={cdiacNumbersList.filter(option => 
                                                option.cdiacNbr === item.authCdiacNbr)}
                                            disabled = { item.mkrAuthLobId && true}
                                    />
                                </td>
                                <td>                                
                                    <button type="button" className="dk-blue-color link-button"><FaPlusCircle onClick={()=>addCdiacNumber()}/></button>{" "}
                                    <button type="button" className="dk-blue-color link-button"><FaMinusCircle onClick={()=>removeCdiacNumber(i)}/></button>
                                </td>
                            </tr>
                        }) }
                        </tbody>
                    </table>
                    
                </Col>  
            </Row>
            }
            
            <div className="mb-3">
            <Row>
                <Col>
                    <div>
                        <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Maturity Structure:</label>
                        <Form.Check
                                inline
                                label="Serial"
                                id="serial"
                                name="maturityStructureCode"
                                value="S"
                                type="radio"
                                onChange={(e)=>handleChange(e)}
                                checked={issuanceInfo.maturityStructureCode === 'S'}
                                data-validations="required"
                            />
                        <Form.Check
                                inline
                                label="Term"
                                id="term"
                                name="maturityStructureCode"
                                value="T"
                                type="radio"
                                onChange={(e)=>handleChange(e)}
                                checked={issuanceInfo.maturityStructureCode === 'T'}
                                data-validations="required"
                                />
                        <Form.Check
                                inline
                                label="Serial and Term Bonds"
                                id="serialandTermbonds"
                                name="maturityStructureCode"
                                value="B"
                                type="radio"
                                onChange={(e)=>handleChange(e)}
                                checked={issuanceInfo.maturityStructureCode === 'B'}
                                data-validations="required"
                            />
                        {formErrors['maturityStructureCode'] && <p className="error">{formErrors['maturityStructureCode']}</p>}
                    </div>
                </Col>
                </Row>
            </div>
            
            <div className="btn-div">
            <button className="custom-button fright" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            
        </Form> 
        {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)} issuerDetails={issuerDetails}/>}
        {addSocialImpactBond && <AddSocialImpactBond onModalClose={()=>handleModalClose()} issueId={issueId}/>}
    </Card>
    )
}

  
export default Issuance;