import {Form, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import FinancialPurposeMaintenanceService from "../../../services/FinancialPurposeMaintenanceService";
import React from "react";
import { useForm } from "react-hook-form";
import {useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify';
import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import CommonService from "../../../services/CommonService";
import TooltipComponent from '../../../common/Tooltip';

const EditFinancePurposeForm = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, financialPurpose, groupValue} = props;
    const {register, handleSubmit,  formState: { errors }} = useForm({
        defaultValues: {
            financePurposeCode: financialPurpose.financePurposeCode,
            financePurposeName: financialPurpose.financePurposeName,
            activeInd: financialPurpose.activeInd === 'Y' ? true : false,
            financePurpGrpCodeId: financialPurpose.financePurpGrpCodeId
          }

    });
    const [errorMessage, setErrorMessage] = useState();

    const onSubmit = (data) => {

        var obj = {
            msgId: null,
            sysId: null,
            opr: "putFinancingPurposeRef",
            hdrStruct: null,
            queryParam: null,
            reqData: { "code": data.financePurposeCode, "financingPurposeRef":{
                "financePurposeCode": data.financePurposeCode,
                "financePurpGrpCodeId": data.financePurpGrpCodeId,
                "financePurposeName": data.financePurposeName,
                "createUser": CommonService.getUserName(user),
                "lastUpdateUser": CommonService.getUserName(user),
                "activeInd": data.activeInd === true ? 'Y' : 'N'
            }
            },
          };
      
          FinancialPurposeMaintenanceService.updateFinancingPurpose(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("Financial Purpose updated successfully!");
                    onModalClose();
                }
                
            });
      }
    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Edit Finance Purpose</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">

                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
                <div className="control">
                    <label><span className="required">* </span>Finance Purpose Group: </label>
                    <input value={groupValue} disabled/>
                </div>
                <div className="control">
                    <label><span className="required">* </span>Code: </label>
                    <input maxLength="4" disabled  {...register('financePurposeCode', { required: true })}/>
                    {errors.financePurposeCode && <p style={{ color: 'red' }}>Code is required</p>}
                </div>
                <div className="control">
                    <label><span className="required">* </span>Name: </label>
                    <input maxLength="100" {...register('financePurposeName', { required: true })}/>
                    {errors.financePurposeName && <p style={{ color: 'red' }}>Name is required</p>}
                </div>
                <div style={{display: 'flex'}}>
                    <Form.Check type="checkbox" label="Active?" {...register('activeInd')}/>
                    <TooltipComponent title="Only active values are displayed in the User Interface" id="edit-county"
                    placement="right">
                        <AiFillQuestionCircle className="help-icon mt-2" />
                    </TooltipComponent>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                <button className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Update</button>
            </Modal.Footer>
        </Modal>
        </form>
    )
}

export default EditFinancePurposeForm
