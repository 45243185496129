import { useState, useEffect } from 'react'
import IssuerFilerService from '../../../services/IssuerFilerService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import FilerRegistrationForm from '../../../pages/FilerRegistrationForm'
import swal from 'sweetalert'
import { toast } from 'react-toastify'
import CommonService from '../../../services/CommonService'
import { AlertType } from '../../../utils'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const FilerRequestsContainer = (props) => {
  const [selectedIssuerInfo, setSelectedIssuerInfo] = useState([]);
  const { searchReq } = props;
  const [requests, setRequests] = useState([])
  const [openIssuerRequest, setOpenIssuerRequest] = useState(false);
  const [action, setAction] = useState(null);
  const viewFormatter = (row, rowIndex) => {
    return (
      <div style={{ textAlign: "center", lineHeight: "normal" }}>
               <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row, 'edit')}/>{" "}
               {row.requestStatus === 'S' && 
                <FontAwesomeIcon icon={faTrash} className="custom-icon" onClick={(e)=>handleDeleteRequest(e, row, rowIndex)}/>
               }
      </div>
    );
  }

  const onRowClick = (e, row, action) => {
    setSelectedIssuerInfo(row);
    setOpenIssuerRequest(true);
    setAction(action);
  }

  const handleDeleteRequest = (e, row, index) => {
    swal("Are you sure you want to delete this request?", {
        buttons: ["No", "Yes"],
    }).then((value) => {
       if(value){
            var obj = {
              msgId: "null",
              sysId: null,
              opr: "deleteFilerRequest",
              hdrStruct: null,
              queryParam: null,
              reqData: { 
                 'filerRequest' : row     
              },
            };
          
            IssuerFilerService.deleteFilerRequest(obj)
            .then((res) => res)
            .then((result) => {
                let requestsArr = [...requests];
                requestsArr = requestsArr.filter(function( obj ) {
                  return obj.requestId !== row.requestId;
                });
                setRequests(requestsArr);
                toast.success("Filer Request Deleted Successfully");

            })
       }
    });
  }

  const fetchIssuerFilerRequests = async () => {
    CommonService.showHideSpinner(true);
    var obj = {
      msgId: null,
      sysId: null,
      opr: "searchIssuerFiler",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        "searchIssuerFiler" : searchReq
      },
    };

    await IssuerFilerService.searchIssuerFilerRequests(obj)
      .then((res) => res)
      .then((result) => {
        if(result.data.statusCode === 401 || result.data.statusCode === 500) {
          CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
        } else {
          setRequests(result.data.responses[0]);
        };
        CommonService.showHideSpinner(false);
      });
  }

  useEffect(() => {
    fetchIssuerFilerRequests();
    // eslint-disable-next-line
  },[]);

  const columns = [{
    dataField: 'requestId',
    text: 'Request Id',
    sort: true,
  }, {
    dataField: 'filerName',
    text: 'Filer Name',
    sort: true
  }, {
    dataField: 'filerAddressLine1',
    text: 'Address',
    sort: true,
  }, {
    dataField: 'filerCityName',
    text: 'City',
    sort: true,
  }, {
    dataField: 'filerStateCode',
    text: 'State',
    sort: true,
  }, 
  {
    dataField: "filerEmailId",
    text: "Email",
    sort: true,
  },
  {
    dataField: "requestStatusFull",
    text: "Status",
  },
  {
    dataField: "",
    text: "",
    formatter: viewFormatter
  }
  ];

  const handleOnModalClose = () => {
    setOpenIssuerRequest(false);
    fetchIssuerFilerRequests();
  }

  return (
    <>
      <div className="form">
        <DataGrid
            title="Search Result: "
            dataSource={requests}
            sortField='requestId'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
        
        { openIssuerRequest && <FilerRegistrationForm filerRequestInfo = {selectedIssuerInfo} onModalClose={handleOnModalClose} action={action} /> }
      </div>
    </>
  )
}

export default FilerRequestsContainer
