import {useState,  useContext} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal} from 'react-bootstrap'
import CurrencyInput from '../formElements/CurrencyInput'
import { IssuanceDetailContext } from '../../UserContext'
import CommonService from '../../services/CommonService'
import { runValidations , validateFormFields} from '../../utils'
import {UserContext} from '../../UserContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify'

const IssuerDetails = (props) => {
    return (
        <Modal show={true} onHide={props.onModalClose}>
            <Modal.Header closeButton>
                <h1>Issuer Details</h1>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <label>Address Line1</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line2</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line3</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line4</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>City</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>State</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>ZipCode</label>
                        <Form.Control type="text" readOnly/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const Issuance = (props) => {
    const { markRoosAuthorityDetails } = props;
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    const [stateValues, setStateValues] = useState({
        totalIssuanceCostAmt : markRoosAuthorityDetails !== undefined ? markRoosAuthorityDetails.totalIssuanceCostAmt : null,
        reserveMinBalInd : markRoosAuthorityDetails.reserveMinBalInd,
        reserveMinBalAmt: markRoosAuthorityDetails.reserveMinBalAmt,
    })
    const [formErrors, setFormErrors] = useState({});
    const loginUser = useContext(UserContext);
    
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    
    const handleChange = (e) => {
        const {name, value} = e.target;
        
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#issuanceForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            updateMarkRoosAuthorityIssuanceCost();
            props.openWidget('fundBalance');
       }
    }

    const updateMarkRoosAuthorityIssuanceCost = async() => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMarkRoosAuthorityIssuanceCost",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": markRoosAuthorityDetails.markRoosAuthorityId, 
                "env" : "I",
                "markRoosAuthority":{
                    "totalIssuanceCostAmt" : stateValues.totalIssuanceCostAmt,
                    "reserveMinBalInd" : stateValues.reserveMinBalInd,
                    "reserveMinBalAmt" : stateValues.reserveMinBalAmt,
                    "lastUpdateUser":  CommonService.getUserName(loginUser),
                    "emailId": loginUser,
                    "contactId": markRoosAuthorityDetails.contactId
                }
            }
        };
    
       await FinalSaleReportService.updateMarkRoosAuthorityIssuanceCost(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message)
            }
            else{
                props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'mkrAuthorityData'); 
                toast.success("Issuance updated Successfully!!");
            }

        });
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    return (
        <Card>
            <form className="form" id="issuanceForm">
            <Row>
            <Col>
                <label>Authority Issuer:</label>
                <Form.Control type="text" value={issuanceDetail.issuerName} disabled/>
            </Col>
            </Row>
            <Row>
            <Col>
                <label>Issue Name:</label>
                <Form.Control type="text" value={issuanceDetail.issueName} disabled/>
            </Col>
            </Row>
            <Row>
                <Col md={6} style={{display: 'flex'}}>
                    <label>Senior Issue:</label>
                    <Form.Check inline type="radio"  checked={issuanceDetail.seniorInd === "Y"}  label="Yes" disabled />
                    <Form.Check inline type="radio"  checked={issuanceDetail.seniorInd === "N"}  label="No" disabled />
                </Col> 
                <Col md={6} style={{display: 'flex'}}>
                    <label>Subordinate Issue:</label>
                    <Form.Check inline type="radio"  checked={issuanceDetail.subordinateInd === "Y"}  label="Yes" disabled />
                    <Form.Check inline type="radio"  checked={issuanceDetail.subordinateInd === "N"}  label="No" disabled />

                </Col> 
            </Row>
            <Row>
            <Col>
                <label>Project Name:</label>
                <Form.Control type="text" value={issuanceDetail.projectName} disabled/>
            </Col>
            </Row>
            
            <Row>
                <Col>
                    <label>Actual Sale Date:</label>
                    <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.actualSaleDate)} disabled/>
                </Col>
                <Col>
                    <label>Settlement Date:</label>
                    <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.settlementDate)} disabled/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Original Principal Amount:</label>
                    <Form.Control type="text" value={CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)} disabled/>
                </Col>
                <Col>
                    <label>Date of Filing:</label>
                    <Form.Control type="text" value={CommonService.dateFormatter(markRoosAuthorityDetails.filingDate)} disabled/>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <div className="control">
                <CurrencyInput 
                placeholder="0.00" 
                label="Total Issuance Costs" 
                name="totalIssuanceCostAmt" 
                type="text" 
                value={stateValues.totalIssuanceCostAmt ? stateValues.totalIssuanceCostAmt  : ""}
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                error={formErrors.totalIssuanceCostAmt} 
                validations={[issuanceDetail.isFirstIssuanceCost ? "" : "required", "max:999999999999.99"]}
                isrequired={issuanceDetail.isFirstIssuanceCost ? false : true }
                disabled={issuanceDetail.isFirstIssuanceCost}
                helpText="In the initial report, the total cost of issuance for the bond issue (including underwriter's discount, bond counsel and financial advisor fees, printing, insurance and ratings) were paid from the bond proceeds. These costs are only to be reported on the first filing date, and this item left blank in subsequent reports."
                />

                </div>
                </Col>
                <Col md={6}>
                <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Total Cost of Issuance from Report of Final Sale" 
                        name="totalIssuanceAmt" 
                        type="text" 
                        defaultValue={issuanceDetail.totalIssuanceAmt ? issuanceDetail.totalIssuanceAmt  : ""}
                        validations={["max:999999999999.99"]}
                        disabled
                        />
                        
                        </div>


                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <div>
                    <label><span className="required">*</span>Was a Reserve Fund established with proceeds of this debt issue?</label>
                    <div style={{display: 'flex'}}>
                        <Form.Check inline type="radio" name="reserveMinBalInd" id="reserveMinBalInd_Y" 
                        checked={stateValues.reserveMinBalInd === "Y"} label="Yes" value="Y" 
                        data-validations={"required"}
                        isrequired={true}
                        onChange={(e)=>handleChange(e)}/>
                        <Form.Check inline type="radio" name="reserveMinBalInd" id="reserveMinBalInd_N" 
                        checked={stateValues.reserveMinBalInd === "N" } label="No" value="N" 
                        data-validations={"required"}
                        isrequired={true}
                        onChange={(e)=>handleChange(e)}/>
                        {formErrors['reserveMinBalInd'] && <p className="error">{formErrors['reserveMinBalInd']}</p>}
                    </div>
 
                </div>
                </Col>
            </Row>
            <Row>
                < Col md={6}>
                    <div className="control">
                        <CurrencyInput 
                        placeholder="0.00" 
                        label="Reserve Fund Minimum Balance Amount" 
                        name="reserveMinBalAmt" 
                        type="text" 
                        value={stateValues.reserveMinBalAmt}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        validations={[stateValues.reserveMinBalInd === "Y" && "required", "max:999999999999.99"]}
                        isrequired = {stateValues.reserveMinBalInd === "Y"}
                        error={formErrors.reserveMinBalAmt}
                        />
                    </div>
                </Col>
            </Row>
            
            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
            </div>
            </form>
            {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)}/>}

        </Card>
    )
}

export default Issuance
