import { Form } from 'react-bootstrap'
import {useState, useEffect} from 'react'
import Card  from '../../ui/Card'
import IssuerIssuerAssociations from './issuerIssuerAssociations'
import IssuerFilerAssociations from './issuerFilerAssociations'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Spinner from 'react-bootstrap/Spinner'
import IssuerFilerService from '../../../services/IssuerFilerService'

const PendingAssociationRequests = (props) => {
    const [showIssuerIssuerAssocs, setShowIssuerIssuerAssocs] = useState(true);
    const [showIssuerFilerAssocs, setShowIssuerFilerAssocs] = useState(false);
    const [associations, setAssociations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({
      'issuerFiler': 'Issuer-Issuer',
    });
    useEffect(() => {
      getAssociations(searchParams.issuerFiler)
      // eslint-disable-next-line
    }, []);

    const getAssociations= async (type) => {
      setIsLoading(true);
      var obj = {
        msgId: null,
        sysId: null,
        opr: type === "Issuer-Issuer" ? "getPendingIssuerIssuerAssocs" : "getPendingIssuerFilerAssocs",
        hdrStruct: null,
        queryParam: null,
        reqData: {
        },
      };
  
      await IssuerFilerService.searchIssuerFilerRequests(obj)
        .then((res) => res)
        .then((result) => {
          Object.keys(result.data.responses).forEach((key) => {
            setAssociations(result.data.responses[key])
          });
          setIsLoading(false);
          if(type === "Issuer-Issuer"){
             setShowIssuerIssuerAssocs(true) 
             setShowIssuerFilerAssocs(false)
          } else {
            setShowIssuerIssuerAssocs(false) 
            setShowIssuerFilerAssocs(true)
          }
          
        });
    }
    const handleChange = (e) => {
      setSearchParams(prevState=>({
        ...searchParams, 
          "issuerFiler":  e.target.value
        })
      )
      getAssociations(e.target.value);
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title" >
                        <h1 className="menu-title">Pending Association Requests</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                    <form>
                    <div>
                      <Form.Check
                              inline
                              label="Issuer-Issuer Associations"
                              value="Issuer-Issuer"
                              name="issuerFiler"
                              id="issuerFiler_0"
                              type="radio"
                              checked={searchParams.issuerFiler === 'Issuer-Issuer'}
                              onChange={(e)=>handleChange(e)}
                          />
                      <Form.Check
                              inline
                              label="Issuer-Filer Associations"
                              value="Issuer-Filer"
                              name="issuerFiler"
                              id="issuerFiler_1"
                              type="radio"
                              checked={searchParams.issuerFiler === 'Issuer-Filer'}
                              onChange={(e)=>handleChange(e)}
                          />
                      </div>
                      </form>
                  </div>
                  <div>{isLoading && <Spinner animation="border" variant="primary" />}</div>
                </div>
              </div>                 

          </div>
          
          {showIssuerIssuerAssocs && <IssuerIssuerAssociations associations={associations} fetchAssocs={()=>getAssociations("Issuer-Issuer")}/>}         
          {showIssuerFilerAssocs && <IssuerFilerAssociations associations={associations} fetchAssocs={()=>getAssociations("Issuer-Filer")}/>}         
           
        </Card>
        </form>
    )
}

export default PendingAssociationRequests
