import { useState, useEffect } from 'react';
import Card from '../../ui/Card';
import {Form, Spinner} from 'react-bootstrap';
import AddPurposeOfFinanceModal from './addPurposeOfFinanceModal';
import Table from '../../Table';
import IssueFinancePurposeService from '../../../services/IssueFinancePurposeService';
import FinancialPurposeMaintenanceService from "../../../services/FinancialPurposeMaintenanceService";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
const PurposeOfFinancing = (props) => {
    const { issueId } = props;    
    const [showAddPurposeOfFinanceModal, setShowAddPurposeOfFinanceModal] = useState(false);
    const [purposes, setPurposes] = useState([]);
    const [editIdx, setEditIndex] = useState(-1);
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [financingPurposeGroups, setFinancingPurposeGroups] = useState([]);

    useEffect(()=> {
        calculateTotal(purposes);
    }, [purposes]);

    useEffect(() => {
        const fetchFinancePurposeGroups = async () => {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: { cdiacCode: "FNPG", active: "Y" },
            };
        
            await FinancialPurposeMaintenanceService.getAllFinancingPurposeGroups(obj)
            .then((res) => res)
            .then((result) => {               
                setFinancingPurposeGroups(result.data.responses[0]);
                setIsLoading(false);
                getIssueFinancingPurposesById(issueId);
            });
        }
       fetchFinancePurposeGroups();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[issueId]);

    const getIssueFinancingPurposesById = async (issueId) => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueFinancePurposesByProposeDebtId",
            hdrStruct: null,
            queryParam: null,
            reqData: {id : issueId },
          };
      
          await IssueFinancePurposeService.getFinancePurposesByIssueId(obj)
            .then((res) => res)
            .then((result) => {  
                setPurposes( result.data.responses[0]);
                props.updateProposeDebtDependencies(result.data.responses[0], 'issueFinancingPurposes');
               setIsLoading(false);
            });
    }

    const updateFinancePurpose = (purposeData)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueFinancePurpose",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": purposeData.financePurposeId,
                "issueFinancePurpose": purposeData
            }
        };

        IssueFinancePurposeService.updateFinancePurpose(obj)
        .then((res) => res)
        .then((result) => {
            getIssueFinancingPurposesById(issueId);
        });
    }

    const deleteFinancePurpose = (issueFinancePurposeId)  => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "deleteIssueFinancePurpose",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": {
            "id" : issueFinancePurposeId,
          }
      };

      IssueFinancePurposeService.deleteFinancePurpose(obj)
      .then((res) => res)
      .then((result) => {
          alert(
            'Finance Purpose Deleted Successfully'
          )
          getIssueFinancingPurposesById(issueId);
      });
    }

    const handleOnSave = (i, rowData) => {
        updateFinancePurpose(rowData);
        setEditIndex(-1);
    }

    const startEditing = i => {
        setEditIndex(i);
    };
    
    const stopEditing = () => {
        setEditIndex(-1);
    };

    const handleRemove = (i, rowData) => {
        const financePurposeId = rowData.financePurposeId;
        deleteFinancePurpose(financePurposeId);
    }

    const handleChange = (e, name, i) => {
        const { value } = e.target;
        const purposesnew =[...purposes];
        purposesnew[i][name] = value;

        setPurposes(purposesnew);
    }

    const handleModalClose = () => {
        setShowAddPurposeOfFinanceModal(false);
        getIssueFinancingPurposesById(issueId);
    }

    const calculateTotal = (purposes) => {
        const total = purposes.reduce((total, item) => total + parseFloat(item.financePurposePct !== "" ? item.financePurposePct : 0), 0);
        setTotalPercentage(total.toFixed(2));
    }

    const validateForm = (e) => {
        if(purposes.length !== 0) {
            if(totalPercentage !== "100.00") {
                swal("The total value of the Financing Purpose percentages must be equal to 100.00%");
            } else {
                toast.success("Financing Purposes Saved successfully!");
                props.sectionValidated('purposeOfFinancing', true);
                props.openWidget('financingParticipants');
            }
        } else {
            props.sectionValidated('purposeOfFinancing', false);
            alert("Please select at least one Purpose of Financing.");
        }
        
        //history.push("/reports/report-proposed-sale/upload-documents");
    }

    return (
        <Card>
            <Form className="form" id="purposeOfFinancingForm">
                <button type="button" className="custom-button" onClick={() => setShowAddPurposeOfFinanceModal(true)}>Add Purpose</button>
                {isLoading && <Spinner />}
                {Object.entries(purposes).length !== 0 && <>
                    <Table 
                    handleRemove={handleRemove}
                    startEditing={startEditing}
                    editIdx={editIdx}
                    stopEditing={stopEditing}
                    handleChange={handleChange}
                    handleSave={handleOnSave}
                    data={purposes}
                    header={[
                      {
                        name: "Finance Purpose Name",
                        prop: "financePurposeName",
                        editable: false
                      },
                      {
                        name: "Percentage",
                        prop: "financePurposePct",
                        fieldType: "input",
                        editable: true
                      }
                    ]}
                    />
                <div style={{overflow: 'auto'}}><div style={{float: 'right', display: 'flex'}}><label>Total: </label><input value={totalPercentage} disabled/></div></div>
                </>
                }            
            {showAddPurposeOfFinanceModal && <AddPurposeOfFinanceModal onModalClose={handleModalClose} issueId={issueId} financingPurposeGroups={financingPurposeGroups}/>}
            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </Form>
        </Card>
    )
}

export default PurposeOfFinancing
