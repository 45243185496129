import {useState, useContext} from 'react'
import {Row, Col} from 'react-bootstrap'
import Card from '../ui/Card'
import ContextModal from './contextModal'
import { useNavigate, useLocation } from 'react-router-dom'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UserContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { toast } from 'react-toastify'

const MKRAuthorityIssue = (props) => {
    const [openContextModal, setOpenContextModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const user = useContext(UserContext);
    let markRoosAuthority = location.state.reportData;   

    if(!location.state.reportData){
        markRoosAuthority = location.state.markRoosAuthority
    }

    const cdiacNo = props.match.params.cdiac;
    const handleSubmit = () => {

        if(markRoosAuthority.mkrAuthId === null || 
            CommonService.getFullYear(stateValues.fiscalYrEndDate) !== CommonService.getFullYear(markRoosAuthority.fiscalYrEndDate) 
            ){
            var obj ={
                "msgId": null,
                "sysId": null,
                "opr": "createMarkRoosAuthority",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {"cdiacNbr": cdiacNo, "markRoosAuthority": {
                    "fiscalYrEndDate" : stateValues.fiscalYrEndDate,
                    "finalSaleId": markRoosAuthority.finalSaleId,
                     "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user
                }
    
                }
              } ;
          
            FinalSaleReportService.createMarkRoosAuthority(obj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    toast.success("The Marks Roos Authority was created  successfully.");
                    markRoosAuthority.mkrAuthId = result.data.responses[0].markRoosAuthorityId;
                    setOpenContextModal(true);
                  }
            });
        }
        else{

            var anotherobj ={
                "msgId": null,
                "sysId": null,
                "opr": "validateMarkRoosAuthority",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {"cdiacNbr": cdiacNo, "markRoosAuthority": {
                    "fiscalYrEndDate" : stateValues.fiscalYrEndDate
                }
    
                }
              } ;
          
            FinalSaleReportService.validateMarkRoosAuthority(anotherobj)
            .then((res) => res)
            .then(async (result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    var obj ={
                        "msgId": null,
                        "sysId": null,
                        "opr": "createMarkRoosAuthority",
                        "hdrStruct": null,
                        "queryParam": null,
                        "reqData": {"cdiacNbr": cdiacNo, "markRoosAuthority": {
                            "fiscalYrEndDate" : stateValues.fiscalYrEndDate,
                            "finalSaleId": markRoosAuthority.finalSaleId,
                             "createUser": CommonService.getUserName(user),
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
            
                        }
                      } ;
                  
                    FinalSaleReportService.createMarkRoosAuthority(obj)
                    .then((res) => res)
                    .then(async (result) => {
                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        }
                        else{
                            toast.success("The Marks Roos Authority was created  successfully.");
                            markRoosAuthority.mkrAuthId = result.data.responses[0].markRoosAuthorityId;
                            setOpenContextModal(true);
                          }
                    });
                  }
            });


        }
        
    }

    const [stateValues, setStateValues] = useState({
        fiscalYrEndDate : markRoosAuthority.fiscalYrEndDate
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
        const date = `6/30/ + ${value}`;

        setStateValues(prevState => ({
            ...stateValues, [name]: CommonService.dateFormatter(date)
        })
        )


    }

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state:{dashboardSelection: dashboardSelectionObj}});
    }

    return (
        <Card>
            <div>
                <div className="panel panel-default">
                    <div className="panel-heading" role="tab"> 
                        <div className="container">
                            <div className="panel-title">
                                <h1 className="main-title">Marks Roos Yearly Fiscal Status Report For Authority Issue</h1>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body" style={{padding: '10px 0'}}>
                        <div className="container">
                            <div>
                            <Row>
                                <Col>
                                    <div>
                                        <label> CDIAC #: </label>
                                        <input type="text" defaultValue={cdiacNo} disabled/>
                                    </div>
                                    <div>
                                    <label><span className="required">* </span>Balances Reported as of June 30, <input type="number" onChange={(e)=>handleChange(e)} maxLength="4" name="fiscalYrEndDate" defaultValue={CommonService.getFullYear(stateValues.fiscalYrEndDate)}/></label>
                                    </div>
                                </Col>
                            </Row>
                            </div>

                            <div style={{overflow: 'auto'}}>
                                <button style={{float: 'right'}}type="button" onClick={(e)=>handleSubmit(e)}>Submit</button>
                                <button 
                                type="button"
                                className="button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard
                                </button>
                            </div>
                        </div>
                    </div>

                

                </div>
            </div>
            {openContextModal && <ContextModal onModalClose={()=>setOpenContextModal(false)} cdiacNo={cdiacNo} finalSaleId = {markRoosAuthority.finalSaleId}  markRoosAuthority={markRoosAuthority} />
            }
            
        </Card>
    )
}

export default MKRAuthorityIssue
