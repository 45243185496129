import {useState, useEffect, useContext, Fragment} from 'react'
import Card from '../ui/Card'
import {Row, Col, Modal} from 'react-bootstrap'
import CommonService from "../../services/CommonService"
import FinalSaleReportService from "../../services/FinalSaleReportService"
import { UserContext } from "../../UserContext"
import { toast } from 'react-toastify'
import CurrencyInput from '../formElements/CurrencyInput'
import { FormInput } from '../formElements/FormInput'
import IssueCdiacNumberService from '../../services/IssueCdiacNumberService'
import {AiFillQuestionCircle} from 'react-icons/ai'
import ReadOnlyRow from '../formElements/ReadOnlyRow'
import EditableRow from '../formElements/EditableRow'
import TooltipComponent from '../../common/Tooltip'

const PrincipalOutStanding = (props) => {
    const { adtrDependenciesData, updateAdtrDependencies } = props;
    const [issueRefundAssocs, setIssueRefundAssocs] = useState(adtrDependenciesData.issueRefundedAssocs);
    const [adtrInfo, setAdtrInfo] = useState(adtrDependenciesData.adtrInfo);
    const [totalPriAccInterest, setTotalPriAccInterest] = useState(0);
    const [principalOutstandingAmt, setPrincipalOutstandingAmt] = useState(0);
    const [showRefundIssueHelpText, setShowRefundIssueHelpText ] = useState(false) 
    const [editInd, setEditInd] = useState(null)
    const [editData, setEditData] = useState({})
    const [fiscalYrStartDate, setFiscalYrStartDate] = useState(null)
    const [refundingAmtTotal, setRefundingAmtTotal] = useState(0)
    //const [beginBalAmtDisable, setBeginBalAmtDisable ] = useState(true);  
    const beginBalAmtDisable = true;  

    const user = useContext(UserContext);
    
    useEffect(() => {
        calculateTotals();
        // eslint-disable-next-line
    }, [adtrInfo]);
    useEffect(()=>{
        const fiscalYear = CommonService.getFullYear(adtrInfo.fiscalYrEndDate);
        let today = new Date();
        today.setMonth(6);
        today.setDate(1);
        today.setFullYear(fiscalYear - 1);
        const fiscalYearStartDate = today;
        issueRefundAssocs && issueRefundAssocs.map(item => {
            if(!item.redeemDate || new Date(item.redeemDate) > fiscalYearStartDate){
                item.isEditable = true;
            } else {
                item.isEditable = false;
            }
            return item;
        });
        const refundingAmtTotal = issueRefundAssocs.reduce((total, item) => total + (item.redeemAmt && item.redeemAmt !== "" ? item.redeemAmt : 0), 0);
        setRefundingAmtTotal(refundingAmtTotal);
        setIssueRefundAssocs(issueRefundAssocs);
        setFiscalYrStartDate(fiscalYearStartDate);
        // eslint-disable-next-line
    },[]);
    
    const calculateTotals = () => {
        let adtrarray = {...adtrInfo};
        const rptPeriodBeginBalAmt = ( typeof adtrarray.rptPeriodBeginBalAmt === 'string' ) 
            ? adtrarray['rptPeriodBeginBalAmt'] !== "" ? parseFloat(adtrarray['rptPeriodBeginBalAmt'].replace(/,/g, '')) : 0.00
            : adtrarray['rptPeriodBeginBalAmt'] !== "" ? adtrarray['rptPeriodBeginBalAmt'] : 0;    
    
        const accretedInterestAmt = ( typeof adtrarray.accretedInterestAmt === 'string' ) 
            ? adtrarray['accretedInterestAmt'] !== "" ? parseFloat(adtrarray['accretedInterestAmt'].replace(/,/g, '')) : 0.00
            : adtrarray['accretedInterestAmt'] !== "" ? adtrarray['accretedInterestAmt'] : 0;    
        
        const principalOtherPaidAmt = ( typeof adtrarray.principalOtherPaidAmt === 'string' ) 
            ? adtrarray['principalOtherPaidAmt'] !== "" ? parseFloat(adtrarray['principalOtherPaidAmt'].replace(/,/g, '')) : 0.00
            : adtrarray['principalOtherPaidAmt'] !== "" ? adtrarray['principalOtherPaidAmt'] : 0;    
    
        const principalRedeemAmt = ( typeof adtrarray.principalRedeemAmt === 'string' ) 
            ? adtrarray['principalRedeemAmt'] !== "" ? parseFloat(adtrarray['principalRedeemAmt'].replace(/,/g, '')) : 0.00
            : adtrarray['principalRedeemAmt'] !== "" ? adtrarray['principalRedeemAmt'] : 0;    

        let total = (rptPeriodBeginBalAmt*10 + accretedInterestAmt*10)/10;
        setTotalPriAccInterest(total);
        let unIssuedDebt = (total - (principalOtherPaidAmt + principalRedeemAmt));
        setPrincipalOutstandingAmt(unIssuedDebt);

    }

    // const showAlertMsg = () => {
    //     swal({
    //         text: "Altering the principal amount may cause this and future ADTR reports to be inconsistent with Issuance documents. Are you sure?",
    //         icon: "warning",
    //         buttons: ["No", "Yes"],
    //     })
    //     .then((yesContinue) => {
    //         if (yesContinue) {
    //             setBeginBalAmtDisable(false);
    //         } else {
    //         swal.close();
    //         }
    //     });
    // }
    const handleChange = (e) => {
        const {name, value} = e.target;
        setAdtrInfo(prevState =>({
            ...adtrInfo, [name] : value
        }));
    }

    const handleSave = (e) => {
        e.preventDefault()
        updateIssueRefundAssoc(editData);
    }

    const handleRefundDateChange = (e, name) => {
        let newEditData = {...editData};
        newEditData[name] = e;
        setEditData(newEditData);
    }

    const updateIssueRefundAssoc = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueRefundAssoc",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : editData.issueRefundId,
                "issueRefundAssoc" : {...editData, 'lastUpdateUser' : user}
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            }
            getIssueRefundingAssocs()
            setEditInd(null)
        });
    }

    const getIssueRefundingAssocs = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getByAdtrLinkedCdiacNbr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId" : adtrInfo.finalSaleId,
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            } else {
                setIssueRefundAssocs(result.data.responses[0])
                updateAdtrDependencies(result.data.responses[0], 'issueRefundedAssocs')
            }
        });
    }

    const updateAdtrDetails = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putAdtr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "adtrId" : adtrInfo.adtrId,
                "adtr" : {...adtrInfo, 'lastUpdateUser' : user}
            }
        };
    
        await FinalSaleReportService.updateAdtr(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            } else{
                updateAdtrDependencies(result.data.responses[0], 'adtrInfo');
                toast.success("Principal Outstanding updated successfully!");
            }
            
        });
    }

    const validateForm = async() => {
        await updateAdtrDetails();
        props.openWidget('useOfProceeds');
    }
    const handleCancelClick = () => {
        setEditInd(null);
    }
    const handleEditClick = (event, i, item) => {
        event.preventDefault();
        setEditInd(i);
        setEditData(item);
    }
    return (
        <Card>
            <div className="form">
           
            
            <Row>
                <Col className="control">
                  <div style={{display: 'inline'}}><CurrencyInput 
                    placeholder="0.00" 
                    label="Principal Balance Upon Sale or at Beginning of the Reporting Period" 
                    name="rptPeriodBeginBalAmt" 
                    type="text" 
                    onBlur={(e)=>calculateTotals(e)}
                    onChange={(e)=>handleChange(e)}
                    value={adtrInfo.rptPeriodBeginBalAmt}
                    validations={["required", "max:999999999999.99"]}
                    disabled={beginBalAmtDisable}
                    />
                    {/* <FaPencilAlt className="date-picker-icon" onClick={() => showAlertMsg()} /> */}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6} className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Accreted Interest – During Reporting Period" 
                    name="accretedInterestAmt" 
                    type="text" 
                    onBlur={(e)=>calculateTotals(e)}
                    onChange={(e)=>handleChange(e)}
                    value={adtrInfo.accretedInterestAmt}
                    validations={["required", "max:999999999999.99"]}
                    //disabled={isAdtrFirstYear ? false : true}
                    />
                </Col>

                <Col md={6}>
                    <div className="control">
                    <label>Total Principal and Accreted Interest: </label>
                    <input 
                    placeholder="0.00" 
                    value={CommonService.currencyFormatter(totalPriAccInterest)}
                    disabled
                    />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="control">
                <CurrencyInput 
                    placeholder="0.00" 
                    label="Principal Paid with Proceeds from Other Debt Issues - During the Reporting Period" 
                    name="principalOtherPaidAmt" 
                    type="text" 
                    onBlur={(e)=>calculateTotals(e)}
                    onChange={(e)=>handleChange(e)}
                    value={adtrInfo.principalOtherPaidAmt}
                    validations={["required", "max:999999999999.99"]}
                    //disabled={isAdtrFirstYear ? false : true}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="control">
                    <CurrencyInput 
                    placeholder="0.00" 
                    label="Principal Payments - During the Reporting Period (not reported as payments above)" 
                    name="principalRedeemAmt" 
                    type="text" 
                    onBlur={(e)=>calculateTotals(e)}
                    onChange={(e)=>handleChange(e)}
                    value={adtrInfo.principalRedeemAmt}
                    validations={["required", "max:999999999999.99"]}
                    //disabled={isAdtrFirstYear ? false : true}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="control">
                <FormInput 
                    placeholder="0.00" 
                    label="Principal Outstanding – End of Reporting Period" 
                    type="text" 
                    value={CommonService.currencyFormatter(principalOutstandingAmt)}
                    disabled
                    readOnly
                    />
                </Col>
            </Row>
            <Row>
                <Col className="control">
                    <FormInput 
                    label="Date this Issuance was Fully Redeemed as Reported on a Mello or Marks-Roos YFSR" 
                    type="text" 
                    value={CommonService.dateFormatter(adtrDependenciesData.issuanceDetails.retireDate)}
                    disabled
                    readOnly
                    />
                </Col>
            </Row>
            <Row>
            <p><b>Refunding/Refinancing Issues </b>
                <AiFillQuestionCircle onClick={()=>setShowRefundIssueHelpText(true)} className="help-icon heading-help-icon"/>
                {showRefundIssueHelpText && 
                <Modal show={true} backdrop="static" onHide={()=>setShowRefundIssueHelpText(false)}>
                    <Modal.Body>
                        <div>
                        The issue(s) listed in the table below were reported to CDIAC as using all or part of their proceeds to refund, 
                        refinance, or pay-down all or part of the principal of the issue subject to this report. This data was reported 
                        to CDIAC previously on the RFS(s) for the listed issues. Please note that the information in the table may not be a complete record of the applicable Refunding/Refinancing information, 
                        it is provided as a reference for completion of “Principal Paid with Proceeds from Other Debt Issues - During the Reporting Period.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" onClick={()=>setShowRefundIssueHelpText(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }
            </p>

            <div className="app-container">
                <form>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                        <th>CDIAC #
                            <TooltipComponent
                                title="Read-only. CDIAC number of the issue reported to be the source of funds used all or in part to refund, refinance, or pay-down the debt issue subject to this report."
                                id="cdiac"
                                placement="right">
                                    <AiFillQuestionCircle className="help-icon" />
                            </TooltipComponent>
                        </th>
                        <th>Refunding/Refinancing Amount
                            <TooltipComponent
                                title="Read-only. The amount of proceeds from the listed issue (source of funds) used to refund, refinance, or pay-down the debt issue subject to this report."
                                id="refunding-amt"
                                placement="right">
                                    <AiFillQuestionCircle className="help-icon" />
                            </TooltipComponent>
                        </th>
                        <th>Redemption/Repayment Date
                            <TooltipComponent
                                title="The date that debt holders or lenders of the issue subject to this report were repaid the amount listed. Prepopulated with the date previously reported on the RFS for the refunding debt issue (source of funds). Edit as necessary."
                                id="redemption-date"
                                placement="right">
                                    <AiFillQuestionCircle className="help-icon" />
                            </TooltipComponent>
                        </th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {(issueRefundAssocs && issueRefundAssocs.length > 0) ? issueRefundAssocs.map((item, i)=>(
                        <Fragment>
                            {editInd === i ? (
                                <EditableRow 
                                data={editData}
                                handleChange={handleRefundDateChange}
                                handleCancelClick={()=>handleCancelClick()}
                                handleUpdate={(e)=>handleSave(e, editData )}
                                columns={[
                                    {
                                    prop: "cdiacNbr",
                                    fieldType: "text",
                                    editable: false
                                    },
                                    {
                                    prop: "redeemAmt",
                                    fieldType: "currency",
                                    editable: false
                                    },
                                    {
                                    prop: "redeemDate",
                                    fieldType: "date",
                                    editable: true,
                                    maxDate: new Date(adtrInfo.fiscalYrEndDate),
                                    minDate: new Date(fiscalYrStartDate)
                                    }
                                ]}
                                />
                            ) : (
                                <ReadOnlyRow 
                                key={i}
                                data={item} 
                                handleEditClick={(e)=>handleEditClick(e, i, item) } 
                                isActionsRequired={true}
                                columns={[
                                    {
                                    prop: "cdiacNbr",
                                    fieldType: "text"
                                    },
                                    {
                                    prop: "redeemAmt",
                                    fieldType: "currency"
                                    },
                                    {
                                    prop: "redeemDate",
                                    fieldType: "date"
                                    }
                                ]}
                                />
                            )}
                        </Fragment>
                        )):
                        <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display</td></tr>
                    }
                    <tr><td><b>Total:</b></td><td>{CommonService.currencyFormatter(refundingAmtTotal)}</td><td></td></tr>
                    </tbody>
                </table>
                </form>
            </div>
            </Row>
            
            
            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Save & Next</button>
            </div>
            </div>
        </Card>
    )
}

export default PrincipalOutStanding
