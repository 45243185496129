import CommonService from '../../services/CommonService';
const PrintPdfComponent = (props) => {
      const {finalSaleDetails, finalSaleDependencies} = props; 

      return (
       <>
        <html>
         <head>
         <meta charset="UTF-8"></meta>
         <style>{"\
          div.repeat-header {\
            padding-top: 0.1in !important;\
            display: block !important;\
            text-align: center;\
            position: running(repeat-header) !important;\
          }\
          .report-header {\
              text-align: center;\
              padding: 5px;\
              width: 100%;\
              height: 40px;\
          }\
          .report-heading-left {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              width: 20%;\
          }\
          .report-heading-center {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: center;\
              width: 60%;\
          }\
          .report-heading-right {\
              font-size: 12px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
              width: 20%;\
          }\
          .report-heading-title {\
              font-size: 18px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: left;\
              min-width: 65%;\
          }\
          .report-heading-date {\
              font-size: 10px;\
              font-weight: 800;\
              color: darkblue;\
              text-align: right;\
          }\
          div.footer {\
            position: running(footer) !important;\
          }\
          #current-page-placeholder::before {\
            content: counter(page);\
          }\
          #total-pages-placeholder::before {\
            content: counter(pages);\
          }\
          @page {\
              margin-top: 90px;\
              @top-center {\
                  content: element(repeat-header) !important\
              }\
          }\
          @page {\
              @bottom-right {\
                  content: element(footer) !important;\
                  font-size: 10px;\
              }\
          }\
          div.repeat-header table {\
              page-break-inside: avoid;\
          }\
          div.repeat-header tr {\
              page-break-inside: avoid;\
              page-break-after: auto\
          }\
          div.repeat-header thead {\
              display: table-header-group\
          }\
          div.repeat-header tfoot {\
              display: table-footer-group\
          }\
          div.break-page {\
              page-break-after: always;\
          }\
          .table a {\
              color: black;\
              text-decoration: underline;\
          }\
          .table .tableheader th, .table tbody th {\
              color: #fff;\
              background-color: darkblue;\
              border-color: #454d55;\
              vertical-align: middle;\
              font-size: 1.0em;\
          }\
          .v-top {\
              vertical-align: top !important;\
          }\
          tr {\
              page-break-inside: avoid\
          }\
          .invoice-box{\
            max-width: 800px;\
            margin: auto;\
            padding: 20px;\
            border: 1px solid #eee;\
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);\
            font-size: 13px;\
            line-height: 18px;\
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;\
            color: #555;\
          }\
          .invoice-box table {\
            width: 100%;\
            line-height: inherit;\
            text-align: left;\
          }\
          .invoice-box table.table-border {\
            border: 1px solid #eee;\
          }\
          .invoice-box table td {\
            padding: 5px;\
            vertical-align: top;\
          }\
        .border-top {\
              border-top: 2px solid #eee;\
          }\
          .invoice-box table tr.top table td.title {\
              font-size: 13px;\
              line-height: 22px;\
              color: #333;\
          }\
          .invoice-box table tr.information table td {\
              padding-bottom: 10px;\
          }\
          .invoice-box table tr.heading td {\
              border-bottom: 1px solid #ddd;\
              font-weight: bold;\
          }\
          .invoice-box table tr.sub-heading td {\
            font-weight: bold;\
          }\
          .invoice-box table tr.item td {\
            border-bottom: 1px solid #eee;\
          }\
          .invoice-box table tr.item.last td {\
              border-bottom: none;\
          }\
          .invoice-box table tr.total {\
              border-top: 2px solid #eee;\
              font-weight: bold;\
          }\
          .invoice-box table tr.section-title td {\
            font-weight: bold;\
            font-size: 18px;\
            line-height: 18px;\
            color: #000;\
          }\
          @media only screen and (max-width: 600px) {\
          .invoice-box table tr.top table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          .invoice-box table tr.information table td {\
              width: 100%;\
              display: block;\
              text-align: center;\
          }\
          }\
        "}</style>
         </head>
          <body className="pdf-box">
            <div className="invoice-box">
            <table cellPadding="0" cellSpacing="0">
              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Issuance</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td colSpan="8">Issuer Name:</td><td colSpan="4">{finalSaleDetails.issuerName}</td></tr>
                            <tr><td colSpan="8">Issue Name:</td><td colSpan="4">{finalSaleDetails.issueName}</td></tr>
                            <tr><td colSpan="8">Project Name:</td><td colSpan="4">{finalSaleDetails.projectName}</td></tr>
                            <tr><td colSpan="8">Principal:</td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.principalSaleAmt)}</td></tr>
                            <tr><td colSpan="8">Original Issue Premium:</td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.premiumAmt)}</td></tr>
                            <tr><td colSpan="8">Original Premium Discount:</td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.premiumDiscountAmt)}</td></tr>
                            <tr><td colSpan="8">Is any portion of the principal to refund, redeem, paydown, or refinance outstanding debt?</td><td colSpan="4">{finalSaleDetails.refndRedeemPaydnRefinInd !== null ? (finalSaleDetails.refndRedeemPaydnRefinInd === 'N' ? 'No' :'Yes') : ''}</td></tr>
                            {(finalSaleDetails.refndRedeemPaydnRefinInd !== null && finalSaleDetails.refndRedeemPaydnRefinInd === 'Y') && 
                              <tr><td colSpan="8">Refunding Amount:</td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.refndRedeemPaydnRefinAmt)}</td></tr>
                            }
                            {(finalSaleDetails.refndRedeemPaydnRefinInd !== null && finalSaleDetails.refndRedeemPaydnRefinInd === 'Y') && 
                              <tr><td colSpan="12"><table>
                                <tr><td colSpan="12">CDIAC# of the debt to be refunded, redeemed, paid down, or refinanced: </td></tr>
                              <tr className="heading">
                                <td align="left" colSpan="6">CDIAC #</td>
                                <td align="left" colSpan="6">Amount</td>
                              </tr>
                            {finalSaleDependencies.issueRefundAssocs.length > 0 ? finalSaleDependencies.issueRefundAssocs.map((refundAssoc, i) => {
                              return (<tr key={i}>
                                <td colSpan="6">{refundAssoc.linkedCdiacNbr}</td><td colSpan="6">{CommonService.currencyFormatter(refundAssoc.redeemAmt)}</td>
                              </tr>
                              )}) :
                              <tr><td>No data available to disaplay.</td></tr>
                            }
                            </table></td></tr>
                            }
                            <tr><td colSpan="8">Issuer certifies that it has complied with Gov. Code section 8855(i) with respect to local debt policies:</td><td colSpan="4">{finalSaleDetails.debtPolicyFlag !== null ? (finalSaleDetails.debtPolicyFlag === 'N' ? 'No' : (finalSaleDetails.debtPolicyFlag === 'Y' ? 'Yes' : 'Not Applicable') ) : ''}</td></tr>
                            
                            <tr className="heading">
                              <td align="left" colSpan="8">Environmental / Social Impact Type</td>
                              <td align="left" colSpan="4">Amount</td>
                            </tr>
                            
                            {finalSaleDependencies.issueSocEnvImpacts.length > 0 ? finalSaleDependencies.issueSocEnvImpacts.map((impact, i) => {
                              return (<tr key={i}>
                                <td colSpan="8">{impact.envSocImpactDisplayName}</td><td colSpan="4">{CommonService.currencyFormatter(impact.envSocialImpactAmt)}</td>
                              </tr>
                              )})
                              : 
                            <tr><td>No Data available to display.</td></tr>
                            }
                            
                            <tr><td colSpan="8">Marks-Roos Authority/TRAN Pool Authority:</td><td colSpan="4">{finalSaleDetails.marksRoosAuthorityInd !== null ? (finalSaleDetails.marksRoosAuthorityInd === 'N' ? 'No' :'Yes') : ''}</td></tr>

                            <tr><td colSpan="8">Marks-Roos Local Obligor/TRAN Participant:</td><td colSpan="4">{finalSaleDetails.marksRoosLocalObligInd !== null ? (finalSaleDetails.marksRoosLocalObligInd === 'N' ? 'No' :'Yes') : ''}</td></tr>
                            <tr><td colSpan="8">Maturity Structure:</td><td colSpan="4">{finalSaleDetails.maturityStructureDesc}</td></tr>
                            {(finalSaleDetails.marksRoosLocalObligInd !== null && finalSaleDetails.marksRoosLocalObligInd === 'Y') && 
                                <tr><td colSpan="12"><table>
                                  <tr><td colSpan="12">CDIAC# of the related Authorities: </td></tr>
                                <tr className="heading">
                                  <td align="left" colSpan="6">CDIAC #</td>
                                </tr>
                              {finalSaleDependencies.issueMkrAuthAssocs.length > 0 ? finalSaleDependencies.issueMkrAuthAssocs.map((mkrAuthAssoc, i) => {
                                return (<tr key={i}>
                                  <td colSpan="6">{mkrAuthAssoc.authCdiacNbr}</td>
                                </tr>
                                )}) :
                                <tr><td>No data available to disaplay.</td></tr>
                              }
                              </table></td></tr>
                            }
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* Dates */}
              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Dates</td></tr>
                    <tr>
                      <td>
                        <table>
                          <tr><td colSpan="8">Proposed Sale Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.proposedSaleDate)}</td></tr>
                          <tr><td colSpan="8">Actual Sale Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.actualSaleDate)}</td></tr>
                          <tr><td colSpan="8">Settlement Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.settlementDate)}</td></tr>
                          <tr><td colSpan="8">Dated Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.datedDate)}</td></tr>
                          <tr><td colSpan="8">Final Maturity Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.finalMaturityDate)}</td></tr>
                          <tr><td colSpan="8">First Optional Call Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.firstOptionalCallDate)}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* Issue Authorization */}
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Issuance Authorization</td></tr>
                    {finalSaleDependencies.issueAuthorizations.length>0 ? finalSaleDependencies.issueAuthorizations.map((auth, i) => {
                      return (<tr key={i}><td><table>
                      <tr><b>Authorization ({i+1}): </b></tr>
                                                   
                      <tr><td>Authorization Name:</td><td>{auth.authorizationName}</td></tr>
                      <tr><td>Original Authorized Amount:</td><td>{CommonService.currencyFormatter(auth.originalAuthorizedAmt)}</td></tr>
                      <tr><td>Authorization Date (MM/DD/YYYY):</td><td>{CommonService.dateFormatter(auth.authorizationDate)}</td></tr>
                      <tr><td>Amount Authorization Replenished (By this Issue):</td><td>{CommonService.currencyFormatter(auth.authorityReplenishAmt)}</td></tr>
                      <tr><td>Amount Authorization Reduced (By this Issue):</td><td>{CommonService.currencyFormatter(auth.authorityReducedAmt)}</td></tr>
                      <tr><td>Net Change (By this Issue):</td><td>{CommonService.currencyFormatter(auth.authorityReplenishAmt - auth.authorityReducedAmt)}</td></tr>
                      </table></td></tr>
                      )}):
                      <tr><td>No data available to display.</td></tr>
                    }
                  </table>
                </td>
              </tr>
              {/* Statutory Authority */}
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Statutory Authority</td></tr>

                    <tr><td colSpan="3">Will a Validation Action be Pursued?</td><td>{finalSaleDetails.validationActionFlag !== null ? (finalSaleDetails.validationActionFlag === 'N' ? 'No' : 'Yes'): ''}</td></tr>
                  </table>
                  <table>
                    <tr><td colSpan="3"><b>Statutory Authorities</b></td></tr>
                    {finalSaleDependencies.issueStatutes.length > 0 ? finalSaleDependencies.issueStatutes.map((statute, i) => {
                      return (<tr key={i}><td colSpan="3"><table>
                      <tr><b>Statute ({i+1}): </b></tr>
                                                   
                                <tr><td>Statute:</td><td>{statute.displayStatuteName}</td></tr>
                                {statute.statuteId === 'OTH' &&
                                <>
                                <tr><td>Statute Code:</td><td>{statute.otherStatuteCode}</td></tr>
                                <tr><td>Statute Section:</td><td>{statute.otherStatuteText}</td></tr>
                                <tr><td>Statute Description:</td><td>{statute.otherStatuteBriefDesc}</td></tr>
                                </>}
                              
                      </table></td></tr>
                      )}) :
                      <tr><td>No data available to display.</td></tr>
                    }
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Credit</td></tr>
                    <tr>
                      <td>
                        <table>
                          <tr><td colSpan="8">Credit Enhancement/Guaranty:</td><td colSpan="4">{finalSaleDetails.guaranteeFlagDesc}</td></tr>
                          <tr><td colSpan="8">Enhancement Expiration Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.enhancementExpDate)}</td></tr>
                          <tr><td colSpan="8">Credit Rating:</td><td colSpan="4">{finalSaleDetails.creditNotRatedInd !== null ? (finalSaleDetails.creditNotRatedInd === "Y" ? "Not Rated" : "Rated") : ""}</td></tr>
                          <tr><td colSpan="8">Standard & Poor:</td><td colSpan="4">{finalSaleDetails.stdsPoorRate}</td></tr>
                          <tr><td colSpan="8">Fitch:</td><td colSpan="4">{finalSaleDetails.fitchRate}</td></tr>
                          <tr><td colSpan="8">Moody's:</td><td colSpan="4">{finalSaleDetails.moodyRate}</td></tr>
                          <tr><td colSpan="8">Other:</td><td colSpan="4">{finalSaleDetails.otherRate}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* Financing participants/Costs */}
              <tr className="information">
                <td colSpan="12">
                  <table>
                    <tr className="section-title"><td>Financing Participants/Costs</td></tr>
                    <tr>
                      <td>
                        <table>
                          <tr><td><b>Underwriter</b></td></tr>
                          <tr><td colSpan="8">Management Fee:</td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.mgmtFeeAmt)}</td></tr>
                          <tr><td colSpan="8">Total Takedown Amount:</td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.totalTakedownAmt)}</td></tr>
                          <tr><td colSpan="8">Underwriter Expense:</td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.uwExpenseFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>['UW', 'LUW', 'OUW'].includes(el.roleCode )) &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['UW', 'LUW', 'OUW'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Bond Counsel:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.bondCounselFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>el.roleCode === "BC") &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts
                                .filter((el)=> el.roleCode === "BC")
                                .map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Borrower Counsel:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.borrowerCounselFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>['BRC', 'CBRW'].includes(el.roleCode)) &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['BRC', 'CBRW'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Co-Bond Counsel:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.cobondCounselFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>el.roleCode === "CB") &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['CB'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Credit Enhancement:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.creditEnhancementFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>el.roleCode === "GR") &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['GR'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Disclosure Counsel:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.disclosureCounselFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>el.roleCode === "DC") &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['DC'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Financial/Municipal Advisor:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.financialAdvisorFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>['FA', 'CFA', 'MA'].includes(el.roleCode )) &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['FA', 'CFA', 'MA'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Lender:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.lenderFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>el.roleCode === "LE") &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['LE'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Placement Agent:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.placementAgencyFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>el.roleCode === "PA") &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['PA'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Purchaser:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.purchaserFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>['PU', 'CPU'].includes(el.roleCode )) &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['PU', 'CPU'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Credit Rating Agency:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.ratingAgencyFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>el.roleCode === "CRA") &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['CRA'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Trustee/Paying Agent:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.trusteeFeeAmt)}</td></tr>
                          {finalSaleDependencies.issueContacts && finalSaleDependencies.issueContacts.some(el=>el.roleCode === "TR") &&
                          <tr>
                            <td colSpan="12">
                              <table className="table-border">
                                <tr className="heading">
                                  <td align="left">Organization Name</td>
                                  <td align="left">Contact First Name</td>
                                  <td align="left">Contact Last Name</td>
                                  <td align="left">Fee Amount</td>
                                </tr>
                                {finalSaleDependencies.issueContacts.filter((el)=> ['TR'].includes(el.roleCode)).map((el, i)=>{
                                  return (<tr><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td><td>{CommonService.currencyFormatter(el.feeAmt)}</td></tr>)
                                })}
                              </table>
                            </td>
                          </tr> 
                          }
                          <tr><td colSpan="8"><b>Other Expenses Amount:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.otherExpenseFeeAmt)}</td></tr>
                          <tr><td colSpan="8"><b>Total Additional Fees and Expenses:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.totalAdditionalFees)}</td></tr>
                          <tr><td colSpan="8"><b>Total Costs of Issuance:</b></td><td colSpan="4">{CommonService.currencyFormatter(finalSaleDetails.totalAdditionalFees+finalSaleDetails.totalUnderwriterFees)}</td></tr>
                        </table>
                        <table>
                          <tr className="sub-heading"><td colSpan="8">Other Contacts:</td></tr>
                          <tr>
                            <td>
                              <table>
                                {finalSaleDependencies.issueContacts && 
                                <tr>
                                  <td colSpan="12">
                                    <table className="table-border">
                                      <tr className="heading">
                                        <td align="left">Role</td>
                                        <td align="left">Organization</td>
                                        <td align="left">Contact First Name</td>
                                        <td align="left">Contact Last Name</td>
                                      </tr>
                                      {finalSaleDependencies.issueContacts.some(el=>!(['BC', 'BRC', 'CB', 'DC', 'LE', 'PA', 'PU', 'FA', 'TR', 'GR', 'MA', 'UW', 'LUW', 'OUW', 'CRA'].includes(el.roleCode ))) ? 
                                      finalSaleDependencies.issueContacts.filter((el)=> !['BC', 'BRC', 'CB', 'DC', 'LE', 'PA', 'PU', 'FA', 'TR', 'GR', 'MA', 'UW', 'LUW', 'OUW', 'CRA'].includes(el.roleCode) ).map((el, i)=>{
                                        return (<tr><td>{el.roleCodeDescription}</td><td>{el.organizationName}</td><td>{el.contactFirstName}</td><td>{el.contactLastName}</td></tr>)
                                      }) :
                                      <tr><td>No data available to display.</td></tr>
                                      }
                                    </table>
                                  </td>
                                </tr> 
                              }
                                
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              
              <tr className="information">
                <td colSpan="8">
                  <table>
                    <tr className="section-title"><td>Tax Status/Interest</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="total currency">Under State Law:</td><td className="total currency">{finalSaleDetails.stateTaxFlag != null ? finalSaleDetails.stateTaxFlag === "Y" ? "Taxable" : (finalSaleDetails.stateTaxFlag === "N" ? "Tax-Exempt" : "Taxable, Tax-Exempt") : ''}</td></tr>
                            <tr><td className="total currency">State Taxable Principal Amount:</td><td className="total currency">{CommonService.currencyFormatter(finalSaleDetails.stateTaxPrincipalAmt)}</td></tr>
                            <tr><td className="total currency">State Tax-Exempt Principal Amount:</td><td className="total currency">{CommonService.currencyFormatter(finalSaleDetails.stateTaxExemptAmt)}</td></tr>
                            <tr><td className="total currency">Under Federal Law:</td><td className="total currency">{finalSaleDetails.federalLawDisplayText}</td></tr>
                            <tr><td className="total currency">Federal Taxable Principal Amount:</td><td className="total currency">{CommonService.currencyFormatter(finalSaleDetails.federalTaxPrincipalAmt)}</td></tr>
                            <tr><td className="total currency">Federal Tax-exempt (No AMT) Principal Amount:</td><td className="total currency">{CommonService.currencyFormatter(finalSaleDetails.federalTaxExemptAmt)}</td></tr>
                            <tr><td className="total currency">Federal Tax-exempt (AMT) Principal Amount:</td><td className="total currency">{CommonService.currencyFormatter(finalSaleDetails.altMinTaxAmt)}</td></tr>
                            {finalSaleDetails.nicInterestTypeInd === "Y" ? <tr><td className="total currency">Net Interest Cost (NIC):</td><td className="total currency">{finalSaleDetails.nicInterestCostRate}</td></tr> : ""}
                            {finalSaleDetails.ticInterestTypeInd === "Y" ? <tr><td className="total currency">True Interest Cost (TIC):</td><td className="total currency">{finalSaleDetails.ticInterestCostRate}</td></tr> : ""}
                            {finalSaleDetails.varInterestTypeInd === "Y" ? <tr><td className="total currency">Variable:</td><td className="total currency">{finalSaleDetails.varInterestCostRate}</td></tr> : ""}
                            {finalSaleDetails.otherInterestTypeInd === "Y" ? <tr><td className="total currency">Other:</td><td className="total currency">{finalSaleDetails.otherInterestCostRate}</td></tr> : ""}
                            <tr><td className="total currency">Capital Appreciation Debt:</td><td className="total currency">{finalSaleDetails.capitalAppreciationInd != null ? (finalSaleDetails.capitalAppreciationInd === "Y" ? "Yes" : "No") : ""}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr className="information">
                <td colSpan="8">
                  <table>
                    <tr className="section-title"><td>Type of Debt Instrument</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="total">{finalSaleDetails.debtInstrumentName}</td></tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* Source of Repayment */}
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Source of Repayment</td></tr>
                    <tr><td><b>Source of Repayment Type:</b></td></tr>
                    {finalSaleDependencies.issueRepaymentSources.length > 0 ?
                    finalSaleDependencies.issueRepaymentSources.map((source, i) => {
                      return (
                        <tr><td>{source.displayName}{source.repymtSrcCodeValueCode === "OTHS" && (" ("+source.otherText+")")}</td></tr>
                        )
                    }):
                    <tr><td>No data available to display.</td></tr>
                    }

                    <tr><td>Is debt repayable in non-US currency?</td><td>{finalSaleDetails.debtRepayNonUsCurrFlag ? (finalSaleDetails.debtRepayNonUsCurrFlag === 'N' ? 'No' : (finalSaleDetails.debtRepayNonUsCurrFlag === 'Y' ? 'Yes' : 'Unknown')) : ''}</td></tr>                      
                    <tr><td>Senior Structure:</td><td>{finalSaleDetails.seniorInd !== null ? (finalSaleDetails.seniorInd === 'Y' ? 'Yes' : 'No') : ''}</td></tr>                      
                    <tr><td>Subordinate Structure:</td><td>{finalSaleDetails.subordinateInd !== null ? (finalSaleDetails.subordinateInd === 'N' ? 'No' :'Yes') : ''}</td></tr>                      
                   
                  </table>
                </td>
              </tr>

              {/* Purpose of Financing */}
              <tr className="information">
                <td>
                  <table>
                    <tr className="section-title"><td>Purpose of Financing</td></tr>

                    <tr className="heading">
                      <td align="left">Purpose Name</td>
                      <td align="left">Percentage</td>
                    </tr>
                    {finalSaleDependencies.issueFinancingPurposes.length > 0 ? finalSaleDependencies.issueFinancingPurposes.map((purpose, i) => {
                      return (<tr key={i}>
                        <td>{purpose.financePurposeName}</td><td>{purpose.financePurposePct}</td>
                      </tr>
                      )})
                      :
                      <tr><td>No data available to display.</td></tr>
                    }
                  </table>
                </td>
              </tr>


              {/* Type of Sale */}
              <tr className="information">
                <td colSpan="8">
                  <table>
                    <tr className="section-title"><td>Type of Sale</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="total currency">Type:</td><td className="total currency">{finalSaleDetails.saleTypeCode && (finalSaleDetails.saleTypeCode === "N" ? "Negotiated" : "Competitive")}</td></tr>
                            <tr><td className="total currency">Is financing a private placement?</td><td className="total currency">{finalSaleDetails.pvtPlacementFinanceInd && (finalSaleDetails.pvtPlacementFinanceInd === "N" ? "No" : "Yes")}</td></tr>

                            {finalSaleDetails.saleTypeCode && finalSaleDetails.saleTypeCode === "N" &&
                              <tr className="sub-heading"><td><b>Reason(s) for Negotiated Sale:</b></td><td className="total currency"></td></tr>
                            }
                            {finalSaleDependencies.issuePrivateSaleReasons.length > 0 ? finalSaleDependencies.issuePrivateSaleReasons.map((source, i) => {
                                return (<tr><td>{source.displayName}<br/>{source.reasonOtherText && source.reasonOtherText}</td></tr>)
                              }):
                              <tr><td>No data available to display.</td></tr>
                            }
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              {/* Document Submittal */}
              <tr className="information">
                <td colSpan="8">
                  <table>
                    <tr className="section-title"><td>Document Submittal</td></tr>
                    <tr>
                      <td>
                        <table>
                            <tr><td className="total currency">Official Statement/Offering Memorandum:</td><td className="total currency">{finalSaleDetails.officialStmtRcvdFlag !== null ? (finalSaleDetails.officialStmtRcvdFlag === "E" ? "Enclosed" : "None Prepared") : ""}</td></tr>
                            <tr><td className="total currency">Maturity Schedule:</td><td className="total currency">{finalSaleDetails.maturityScheduleCode !== null ? (finalSaleDetails.maturityScheduleCode === "A" ? "Attached" : "Included in Official Statement") : ""}</td></tr>
                        </table>
                      </td>
                    </tr>
                    <tr className="heading">
                      <td align="left">Document Type</td>
                      <td align="left">Document Name</td>
                      <td align="left">File Upload Date</td>
                    </tr>
                    {finalSaleDependencies.issueDocuments.length > 0 ? finalSaleDependencies.issueDocuments.map((document, i) => {
                      return (<tr key={i}>
                        <td>{document.documentDesc}</td><td>{document.fileName}</td><td>{CommonService.dateFormatter(document.createDatetime)}</td>
                      </tr>
                      )}) :
                      <tr><td>No data available to disaplay.</td></tr>
                    }
                  </table>
                </td>
              </tr>

              {/* <tr className="information">
                <td>
                  <table>
                    <tbody>
                    <tr className="section-title"><td>Comments</td></tr>
                    <tr><td colSpan="2">
                    <table className="data-table">
                      <tr className="heading">
                        <td align="left">Comment Type</td>
                        <td align="left">Comment</td>
                        <td align="left">Create User</td>
                        <td align="left">Create Date</td>
                      </tr>
                      {issueComments && issueComments.map((comment, i) => {
                        return (<tr key={i}>
                          <td>{comment.cdiacCodeValueDesc}</td>
                          <td>{comment.comment}</td>
                          <td>{comment.createUser}</td>
                          <td>{CommonService.dateFormatter(comment.createDatetime)}</td>
                        </tr>
                        )})
                      }
                    </table>
                    </td></tr>   
                    </tbody>  
                  </table>
                </td>
              </tr> */}

            </table>
            <table>
              {finalSaleDetails.issueStatusFlag !== 'D' &&
              <tr className="information">
                <td colSpan="8">Submission Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.finalSaleReceiveDate)}</td>
              </tr>
              }
              {finalSaleDetails.issueStatusFlag === 'R' &&
              <tr className="information">
                <td colSpan="8">Review Date:</td><td colSpan="4">{CommonService.dateFormatter(finalSaleDetails.lastUpdateDatetime)}</td>
              </tr>
              }
            </table>
           </div>
          </body>
        </html>
       </>
      );
  }

  export default PrintPdfComponent;