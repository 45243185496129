import { Form } from 'react-bootstrap'
import {useState} from 'react'
import Card  from '../../ui/Card'
import IssuerFilerService from '../../../services/IssuerFilerService'
import StatusHistory from './statusHistory'
import { AlertType, validateFormFields } from '../../../utils'
import UploadedDocuments from './uploadedDocuments'
import CommonService from '../../../services/CommonService'
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from 'react'

const StatusHistoryUploadDocuments = (props) => {
    const navigate = useNavigate();
    const [showUploadedDocuments, setShowUploadedDocuments] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [showStatusHistory, setShowStatusHistory] = useState(false);
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useState({
      "cdiacNo" : "",
      "historyType" : "",
    });

    let params = useParams();
    useEffect(()=>{
      setSearchParams(prevState => ({
      ...searchParams, 
        cdiacNo:  params.cdiacNbr
      }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[params.cdiacNbr]);
    

    const fetchData = async () => {
      CommonService.showHideSpinner(true);
      var obj = {
        msgId: null,
        sysId: null,
        opr: searchParams.historyType === "Status-History" ? "getQuickRetrievalStatusHistory" : "getQuickRetrievalUploadedDocuments",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "cdiacNo" : searchParams.cdiacNo
        },
      };
  
      await IssuerFilerService.searchIssuerFilerRequests(obj)
        .then((res) => res)
        .then((result) => {
          if (result.data.errors && result.data.errors.length > 0){
              CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
              setData([])
          } else {
            Object.keys(result.data.responses).forEach((key) => {
              setData(result.data.responses[key])
            });
            
            if(searchParams.historyType === "Status-History"){
              setShowUploadedDocuments(false) 
              setShowStatusHistory(true)
            } else {
              setShowStatusHistory(false) 
              setShowUploadedDocuments(true)
            }
          }  
          CommonService.showHideSpinner(false);
      });
      
    }
    const handleChange = (e, key) => {
      const {name, value} = e.target
      setSearchParams(prevState=>({
        ...searchParams, 
          [name]:  value
        })
      )
    }

    const backToDashboard = () => {
      const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
      navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
  }

    const handleSubmit = (e) => {
      e.preventDefault()
      const form = e.target.closest('form#quick-retrieval');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({})
            fetchData()
        }
    }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title" >
                        <h1 className="menu-title">Quick Retrievals</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                    <form id="quick-retrieval">
                      <div className="control">
                        <label><span className="required">*</span>CDIAC #:</label>
                        <input type="text" name="cdiacNo" value={searchParams.cdiacNo} data-validations="required" onChange={(e)=>handleChange(e, 'cdiacNo')} placeholder="XXXX-XXXX"/>
                        {formErrors.cdiacNo && <p className="error">This field is required.</p>}
                      </div>
                      <div>
                      <Form.Check
                              inline
                              label="Reporting History"
                              value="Status-History"
                              name="historyType"
                              id="statusHistory"
                              type="radio"
                              checked={searchParams.historyType === 'Status-History'}
                              onChange={(e)=>handleChange(e)}
                              data-validations="required"
                          />
                      <Form.Check
                              inline
                              label="Uploaded Documents"
                              value="Uploaded-Documents"
                              name="historyType"
                              id="uploadedDocuments"
                              type="radio"
                              checked={searchParams.historyType === 'Uploaded-Documents'}
                              onChange={(e)=>handleChange(e)}
                              data-validations="required"
                          />
                      </div>
                      {formErrors.historyType && <p className="error">Please select one option.</p>}

                      <div className="pt-4">
                        <div className="d-flex justify-content-between">
                          <button className="custom-button btn-secondary"  type="button" onClick={()=>backToDashboard()}>Close</button>
                          <button className="custom-button" type="Submit" onClick={(e)=>handleSubmit(e)}>Search</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>                 
             
          </div>
          
          {showUploadedDocuments && <UploadedDocuments uploadedDocuments={data} />}         
          {showStatusHistory && <StatusHistory statusHistory={data} />}         
           
        </Card>
        </form>
    )
}

export default StatusHistoryUploadDocuments
