import {Modal, Col, Row, Form} from 'react-bootstrap'
import AuthorizationMaintenanceService from "../../../services/AuthorizationMaintenanceService";
import React from "react";
import {useState} from 'react';
import Alert from 'react-bootstrap/Alert';
import { toast } from 'react-toastify';
import CommonService from "../../../services/CommonService";
import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from 'react-icons/fa';
import CurrencyInput from '../../formElements/CurrencyInput';
import { validateFormFields } from '../../../utils';

const EditAuthorization = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, authInfo} = props;
    const [formErrors, setFormErrors] = useState({});
    const origins = [
        {value:"E", label:"Elections"},
        {value:"I", label:"Issues"}
    ]
    const [errorMessage, setErrorMessage] = useState();
    const [authorizationInfo, setAuthorizationInfo] = useState({
            authorizationName: authInfo.authorizationName,
            electionDate: CommonService.dateFormatter(authInfo.electionDate),
            issuerId: authInfo.issuerId,
            activeInd: authInfo.activeInd === 'Y' ? true : false,
            authorityOriginCode: authInfo.authorityOriginCode,            
            authorizationAmt: CommonService.currencyFormatter(authInfo.authorizationAmt),
            issuerName: authInfo.issuerName,
            authorizationId: authInfo.authorizationId
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target.closest('form#edit-authorization');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "putAuthorizationRef",
                hdrStruct: null,
                queryParam: null,
                reqData: { "authorizationId": authorizationInfo.authorizationId, 
                "authorizationRef":{
                    "authorizationId": authorizationInfo.authorizationId,
                    "authorizationName": authorizationInfo.authorizationName,
                    "issuerId": authorizationInfo.issuerId,
                    "electionDate": authorizationInfo.electionDate,
                    "authorityOriginCode": origins.filter(option => option.label === authorizationInfo.authorityOriginCode)[0].value,
                    "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user),
                    "authorizationAmt": parseFloat(authorizationInfo.authorizationAmt.replace(/\$|,/g, '')) ,
                    "issuerName" : authorizationInfo.issuerName
                }
                },
            };
        
            AuthorizationMaintenanceService.updateAuthorizationRef(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        setErrorMessage(result.data.errors[0].message)
                    }
                    else{
                        toast.success("Authorization updated successfully!");
                        onModalClose();

                    }
                    
                });
        }
    }

    return (
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Edit Authorization</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
            <form id="edit-authorization">
                <Row>
                    <Col md={6} className="control">
                    <label>Issuer Name:</label>
                    <input 
                        type="text"
                        defaultValue={authorizationInfo.issuerName}
                        disabled
                    />
                    </Col>
                    <Col md={6} className="control">
                        <label><span className="required">*</span>Authorization Name:</label>
                        <input
                            type="text" 
                            name="authorizationName"
                            value={authorizationInfo.authorizationName}
                            onChange={(e)=>setAuthorizationInfo({...authorizationInfo, 'authorizationName' : e.target.value })}
                            data-validations="required"
                        />
                        {formErrors.authorizationName && <p className="error">Authorization Name is required</p>}
                    </Col>
                </Row>
                      
                        <Row>
                        <Col md={6}>
                            <label><span className="required">*</span>Voter Measure:</label>
                            <Form.Check
                                inline
                                label="No"
                                name="voterMeasureInd"
                                value="N"
                                type="radio"
                                id="voterMeasure_0"
                                checked={authorizationInfo.authorityOriginCode === 'Issues'}
                                onChange={(e)=>setAuthorizationInfo({...authorizationInfo, 'authorityOriginCode' : 'Issues' })}
                            />
                            <Form.Check
                                inline
                                label="Yes"
                                name="voterMeasureInd"
                                value="Y"
                                type="radio"
                                id="voterMeasure_1"
                                checked={authorizationInfo.authorityOriginCode === 'Elections'}
                                onChange={(e)=>setAuthorizationInfo({...authorizationInfo, 'authorityOriginCode' : 'Elections' })}
                            />
                            {formErrors.originType && <p className="error">{formErrors.originType}</p>}
                        </Col>
                        <Col md={6} className="control">
                            <label><span className="required">*</span>Authorization Date (By Election or Governing Body):</label>
                            <span>
                            <DatePicker 
                            selected={authorizationInfo.electionDate ? new Date(authorizationInfo.electionDate) : null}
                            name="electionDate"
                            dateFormat="M/d/yyyy"
                            maxDate= {new Date(9999, 12, 31)}
                            onChange={(date) => setAuthorizationInfo({...authorizationInfo, "electionDate": date})} 
                            />
                            <FaCalendarAlt className="date-picker-icon"/>
                            </span>
                            {formErrors.electionDate && <p className="error">Authorization Date is required</p>}
                            <input type="hidden" value={authorizationInfo.electionDate} name="electionDate" data-validations={['required']}/>
                        </Col>
                        <Col md={6} className="control">
                            <CurrencyInput 
                                placeholder="0.00" 
                                label="Original Authorization Amount" 
                                name="authorizationAmt" 
                                type="text" 
                                onChange={(e)=>setAuthorizationInfo({...authorizationInfo, 'authorizationAmt' : e.target.value })}
                                value={authorizationInfo.authorizationAmt}
                                validations={["required", "max:999999999999.99"]}
                                isrequired="true"
                            />
                            {formErrors.authorizationAmt && <p className="error">Authorization Amount is required</p>}
                        </Col>
                    </Row> 
                    <div className="btn-div">
                    <div className="fright">
                        <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                        <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                        </div>
                    </div>
                </form>                
            </Modal.Body>
        </Modal>
    )
}

export default EditAuthorization
