import { useState, useEffect } from "react";
import InvoiceService from "../../services/InvoiceService";
import CommonService from "../../services/CommonService";
import BlobService from "../../services/BlobService";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import DataGrid from "../GenericComponents/DataGrid";
import { Column } from "primereact/column";

const Filenet = (props) => {
  const { searchReq } = props;
  const [invoice, setInvoice] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function sendFile(thefile, therow) {
    let fileNetUpdateId = therow.filenetId;

    let formData = new FormData();
    formData.append("file", thefile);
    formData.append("docTypeCodeId", therow.docTypeCodeId.toString());
    formData.append("createUser", therow.createUser);
    formData.append(
      "finalSaleId",
      therow.finalSaleId === null ? "" : therow.finalSaleId.toString()
    );
    formData.append("mimeType", "pdf");
    formData.append("proposedDebtId", "");
    formData.append(
      "melloRoosId",
      therow.mlrId === null ? "" : therow.mlrId.toString()
    );
    formData.append("fileName", thefile.name);
    BlobService.uploadFile(formData).then((res) => {
      if (res.data.errors && res.data.errors.length > 0) {
        toast.error(res.data.errors[0].message);
      } else {
        updateFileNetFlag(fileNetUpdateId);
      }
    }); // Handle the response from backend here
  }

  const onMultiple = (e) => {
    let files = Array.from(e.target.files);
    files.map((aFile) => getFileNetRecord(aFile));
  };

  function updateFileNetFlag(aId) {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "updateFilenet",
      hdrStruct: null,
      queryParam: null,
      reqData: { filenetId: aId },
    };

    InvoiceService.updateFilenet(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          toast.success("Document Loaded, and FileNet Flag was updated");
        });
        setIsLoading(false);
      });
  }
  function getFileNetRecord(aFile) {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getOneFilenetDoc",
      hdrStruct: null,
      queryParam: null,
      reqData: { sFileName: aFile.name.substring(0, aFile.name.length - 4) },
    };

    InvoiceService.getOneFilenetDoc(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setInvoice(result.data.responses[key]);
          sendFile(aFile, result.data.responses[key][0]);
        });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getFilenetDocs",
      hdrStruct: null,
      queryParam: null,
      reqData: {},
    };

    InvoiceService.getFilenetDocs(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setInvoice(result.data.responses[key]);
        });
        setIsLoading(false);
      });
  }, [props, searchReq]);

  const columns = [
    {
      dataField: "filenetId",
      text: "Filenet ID",
      sort: true,
    },
    {
      dataField: "cdiacNbr",
      text: "CDIAC #",
      sort: true,
    },
    {
      dataField: "docFileName",
      text: "Doc File Name",
      sort: true,
    },
    {
      dataField: "docType",
      text: "Doc Type",
      sort: true,
    },
    {
      dataField: "createDatetime",
      text: "Create Date",
      sort: true,
      formatter: (row) => CommonService.dateFormatter(row['createDatetime']),
    },
    {
      dataField: "createUser",
      text: "Created User",
      sort: true,
    },

    {
      dataField: "finalSaleId",
      text: "Final Sale ID",
      sort: true,
    },
    {
      dataField: "docTypeCode",
      text: "Doc Type Code",
      sort: true,
    },
    {
      dataField: "docTypeCodeId",
      text: "Doc Code #",
      sort: true,
    },
  ];

  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" />}
      <div className="form">
        <h1>Document Upload List: </h1>
        <DataGrid
        dataSource={invoice}
        sortField='filenetId'
        emptyDataMessage='No data available to display'
        >
        {columns.map((column, i) => {
            return (
            <Column 
            key={i}
            field={column.dataField}
            header={column.text}
            sortable={column.sort} 
            body={column.formatter}
            hidden={column.hidden}
            />)
            }) 
        } 
        </DataGrid>
        <input
          className="form-control"
          type="file"
          name="file1"
          accept="application/pdf"
          onChange={(e) => onMultiple(e)}
          multiple
        />
      </div>
    </>
  );
};

export default Filenet;
