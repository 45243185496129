import React, {useEffect, useState} from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import Card from '../ui/Card'
const FilingContact = (props) => {
    const validateForm = () => {
        props.openWidget('comments');
    }
    const [contact, setContactDetails] = useState(false);
    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getContactById",
          hdrStruct: null,
          queryParam: null,
          reqData:{"id" : props.drawDetails.contactId}
        };
    
        FinalSaleReportService.getContactDetails(obj)
          .then((result) => {
                setContactDetails({...result.data.responses[0],
                'contactFirstName': result.data.responses[0] ? result.data.responses[0].contactFirstName : "",
                'contactLastName': result.data.responses[0] ? result.data.responses[0].contactLastName : ""
                });
          });
    }, [props]);

    return (
        <Card>
            <div className="form">
            <div>
                <label>Filing Contact Name</label>
                <Form.Control type="text" value={contact.contactFirstName + ' ' + contact.contactLastName} disabled/>
            </div>
            <Row>
            <Col>
                <label>Agency/Issuer Name</label>
                <Form.Control type="text" value={contact.issuerName} disabled/>
            </Col>
            </Row>
            <Row>
            <Col md={6}>
                <label>Address Line 1</label>
                <Form.Control type="text" value={contact.contactAddressLine1} disabled/>
            </Col>
            <Col md={6}>
                <label>Address Line 2</label>
                <Form.Control type={contact.contactAddressLine2} disabled/>
            </Col>
            <Col md={6}>
                <label>Address Line 3</label>
                <Form.Control type={contact.contactAddressLine3} disabled/>
            </Col>
            <Col md={6}>
                <label>Address Line 4</label>
                <Form.Control type={contact.contactAddressLine4} disabled/>
            </Col>
            </Row>
            <Row>
            <Col>
                <label>City</label>
                <Form.Control type="text" value={contact.contactCityName} disabled/>
            </Col>
            <Col>
                <label>State</label>
                <Form.Control type="text" value={contact.contactStateCode} disabled/>
            </Col>
            <Col>
                <label>Zip Code</label>
                <Form.Control type="text" value={contact.contactZipCode} disabled/>
            </Col>
            </Row>
            <Row>
                <Col>
                    <label>Area Code</label>
                    <Form.Control type="text" value={contact.contactPhoneAreaCode} disabled/>
                </Col>
                <Col>
                    <label>Telephone</label>
                    <Form.Control type="text" value={contact.contactPhoneNumber} disabled/>
                </Col>
                <Col>
                    <label>Ext</label>
                    <Form.Control type="text" valuw={contact.contactPhoneExtn} disabled/>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <label>Fax Area Code</label>
                    <Form.Control type="text" value={contact.contactFaxAreaCode} disabled/>
                </Col>
                <Col md={4}>
                    <label>Fax Number</label>
                    <Form.Control type="text" value={contact.contactFaxNumber} disabled/>
                </Col>
            </Row>
            <Row>
                <Col>
                <label>E-mail Address</label>
                    <Form.Control type="text" value={contact.contactEmailId} disabled/>
                </Col>
            </Row>

            <div className="btn-div">
                {/* <button className="custom-button" type="button">Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Next</button>
                </div>
            </div>
        </Card>
    )
}

export default FilingContact
