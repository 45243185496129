import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import Card from "../../ui/Card";
import DebtDetails from "./debtDetails";
import DebtInstrumentMaintenanceService from "../../../services/DebtInstrumentMaintenanceService";
import Spinner from 'react-bootstrap/Spinner'

const DebtInstrumentMaintenance = () => {
  const [selectedDebtGroup, setSelectedDebtGroup] = useState(null);
  const [selectedDebtGroupVal, setSelectedDebtGroupVal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [debtGroups, setDebtGroups] = useState([]);

  useEffect(() => {
    var obj = {
      msgId: "null",
      sysId: null,
      opr: "GetLookups",
      hdrStruct: null,
      queryParam: null,
      reqData: { cdiacCode: "DEBTG", active: "Y" },
    };

    DebtInstrumentMaintenanceService.getAllDebtGroups(obj)
      .then((res) => res)
      .then((result) => {
        setDebtGroups(result.data.responses);
        setIsLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    let debtType = e.target.value;
    setSelectedDebtGroup(debtType);
    setSelectedDebtGroupVal(e.target[e.target.selectedIndex].text);
  };

  return (
    <Card>
      <div className="panel panel-default">
        <div className="panel-heading">
              <div className="container">
                  <div id="packet_id" className="panel-title">
                      <h1 className="menu-title">Debt Instrument Maintenance</h1>
                  </div>
              </div>
          </div>
          <div className="panel-body">
            <div className="container">
              {isLoading && <Spinner animation="border" variant="primary" />}

              <div className="form">
                <label>Debt Group: </label>

                {Object.keys(debtGroups).map((key) => {
                  return (
                    <div key={key}>
                      <Form.Select
                        as="select"
                        onChange={(e) => handleChange(e)}
                        value={selectedDebtGroup || ''}
                      >
                        <option>Select</option>
                        {debtGroups[key].map((dataItem) => {
                          return (
                            <option
                              key={dataItem.cdiacCodeValueCode}
                              value={dataItem.cdiacCodeValueId}
                            >
                              {dataItem.cdiacCodeValueDesc}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  );
                })}
              </div>
            </div>
            </div>
        {selectedDebtGroup && <DebtDetails selectedDebtGroup={selectedDebtGroup} selectedDebtGroupVal={selectedDebtGroupVal}/>}
      </div>
    </Card>
  );
};

export default DebtInstrumentMaintenance;
