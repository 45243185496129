import { useState } from 'react'
import { Modal, Table, Dropdown } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import AdtrService from '../../../services/AdtrService'
import CommonService from '../../../services/CommonService'
import { toast } from 'react-toastify'
import BaseService from '../../../services/BaseService'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const SearchResultContainer = (props) => {
  const { data, onRefresh } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(null);
  const [viewError, setViewError] = useState(false);
  const handleViewDocument = (e, adtrBatch) => {
    setShowSpinner(true);
    let obj = {
        "msgId": null,
        "sysId": null,
        "opr": "getPdfDocumentFromBlob",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": { 
            "adtrBatchId" : adtrBatch.adtrBatchId,
            "type" : "adtr_batch"
        }
    };
    BaseService.processRequest(obj)
      .then((res) => {
        var base64EncodedPDF = res.data.responses[0];
        var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
        function base64ToArrayBuffer(base64) {
            let binaryString = window.atob(base64);
            let binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        }
        setShowSpinner(false);
        var blob = new Blob([arrrayBuffer], {type: "text/csv"});
         const a = document.createElement('a')
         a.download = adtrBatch.uploadFileName;
         a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove();
    });
  }
  const fileNameFormatter = (row) => { 
    return ( 
        <div style={{lineHeight: "normal" }}>
            <button
                type="button"
                className="link-button dk-blue-color"
                onClick={(e)=>handleViewDocument(e, row)}
                >{row.uploadFileName}
            </button>
        </div> 
    ); 
 }

  const processAdtrBatch = (adtrBatch) => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "processAdtrBatchFile",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        "adtrBatchId" : adtrBatch.adtrBatchId,
        "adtrFileType" : adtrBatch.adtrFileType
      }
    };

    AdtrService.processRequest(obj)
      .then((result) => {
          if (result.data.errors && result.data.errors.length > 0) {
            toast.error(result.data.errors[0].message);
          }
          else {
              toast.success("Adtr Batch File Processed!");
              onRefresh();
          }
      });
  }

  const getAdtrBatchErrors = (adtrBatchId) => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "getAdtrBatchError",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        "adtrBatchId" : adtrBatchId
      }
    };

    AdtrService.processRequest(obj)
      .then((result) => {
          if (result.data.errors && result.data.errors.length > 0) {
            toast.error(result.data.errors[0].message);
          }
          else {
            setError(result.data.responses[0]);
            setViewError(true);
          }
      });
  }

  const deleteAdtrBatch = (adtrBatchId) => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "updateAdtrBatchDeleteInd",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        "adtrBatchId" : adtrBatchId,
      }
    };

    AdtrService.processRequest(obj)
      .then((result) => {
          if (result.data.errors && result.data.errors.length > 0) {
            toast.error(result.data.errors[0].message);
          }
          else {
              toast.success("Deleted Successfully!");
              onRefresh();
          }
      });
  }

  const handleErrorModalClose = () => {
    setViewError(false);
    setError(null);
  }

  const onRowClick = (e, action, row) => {
    e.preventDefault();
    if(action === "process-file") {
      processAdtrBatch(row);
    } else if(action === "view-errors") {
      getAdtrBatchErrors(row.adtrBatchId);
    } else if(action === "delete") {
      deleteAdtrBatch(row.adtrBatchId);
    }
  }
 const viewEditFormatter = (row) => {
  return (
    <div style={{
      textAlign: "center",
      lineHeight: "normal"
    }}>
       <div className="dropdown">
            <Dropdown>
            <Dropdown.Toggle 
            variant="secondary btn-sm" 
            id="dropdown-basic">
                Action
            </Dropdown.Toggle>
            <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
            {row.processStatusFlag === "N" && row.uploadErrorInd === "N" &&
                <Dropdown.Item href="#" onClick={(e)=>onRowClick(e, 'process-file', row )}>Submit for Processing</Dropdown.Item> }
            {(row.processStatusFlag === "E") && 
                <Dropdown.Item href="#" onClick={(e)=>onRowClick(e, 'view-errors', row )}>View Errors</Dropdown.Item> }
                <Dropdown.Item href="#" onClick={(e)=>onRowClick(e, 'delete', row )}>Delete</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
        </div>
    </div>
  );
  }
  const columns = [{
      dataField: 'adtrBatchId',
      text: 'Batch #',
      sort: true,
    },{
      dataField: 'uploadFileName',
      text: 'File Name',
      formatter: fileNameFormatter,
      sort: true,
    },{
      dataField: 'adtrFileType',
      text: 'ADTR File Type',
      sort: true,
    }, 
    {
      dataField: 'uploadDatetime',
      text: 'Upload Date',
      sort: true,
      formatter: (row) => CommonService.dateFormatter(row['uploadDatetime'])
    },
    {
      dataField: 'processStatusFlagDesc',
      text: 'Process Status',
      sort: true
    },{
      dataField: 'processDatetime',
      text: 'Process Date',
      sort: true,
      formatter: (row) => CommonService.dateFormatter(row['processDatetime'])
    },{
      dataField: 'createUser',
      text: 'Uploaded By',
    },{
      dataField: '',
      text: 'Action',
      formatter: viewEditFormatter,
    }
  ];

  return (
    <>
      <div className="form">
        <h2>Search Result: </h2>
        <DataGrid
          dataSource={data}
          searchToolTip='Use the search feature to quickly retrieve the system code values'
          sortField='uploadFileName'
          >
          {columns.map((column, i) => {
              return (
              <Column 
              key={i}
              field={column.dataField}
              header={column.text}
              sortable={column.sort} 
              body={column.formatter}
              />)
            }) 
          } 
        </DataGrid>
        {viewError && <ErrorModal errors={error} onModalClose={handleErrorModalClose}/>}
        {showSpinner && 
        <Modal show={true} className="modal bd-example-modal-lg" data-backdrop="static">
            <div className="modal-dialog modal-sm">
                    <Spinner animation="border"/>
            </div>
        </Modal>
        }
      </div>
    </>
  )
}

export default SearchResultContainer

const ErrorModal = (props) => {
  const {errors, onModalClose} = props;
  return (
    <Modal show={true} backdrop="static" onHide={onModalClose} size='lg'>
      <Modal.Header closeButton><h3>Error</h3></Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <td>CDIAC #</td>
              <td>Fiscal Year End Date</td>
              <td>Error Desc</td>
            </tr>
          </thead>
          <tbody>
            {errors && errors.map((error, i) => {
              return <tr key={i}>
                <td>{error.cdiacNbr}</td>
                <td>{CommonService.dateFormatter(error.fiscalYrEndDate)}</td>
                <td>{error.errorDesc}</td>
              </tr>
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <button className="custom-button-secondary btn-secondary"onClick={()=>onModalClose()}>Close</button>
      </Modal.Footer>
    </Modal>
  )
}