import React, { useState, useContext } from 'react'
import Card from '../ui/Card'
import { Form, Row, Col } from "react-bootstrap"
import { FormInput } from '../formElements/FormInput'
import { validateFormFields } from '../../utils/index'
import {AiFillQuestionCircle} from 'react-icons/ai'
import { FaCalendarAlt} from 'react-icons/fa'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { UserContext } from '../../UserContext'
import CommonService from '../../services/CommonService'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { toast } from 'react-toastify'
import TooltipComponent from '../../common/Tooltip'

const Dates = (props) => {
    const {finalSaleDetails, updateFinalSaleDetails} = props;
    const loginUser = useContext(UserContext);
    const [finalSaleInfo, setFinalSaleInfo] = useState(finalSaleDetails ? 
        {...finalSaleDetails, 
            'actualSaleDate': finalSaleDetails.actualSaleDate ? new Date(finalSaleDetails.actualSaleDate) : null, 
            'settlementDate': finalSaleDetails.settlementDate ? new Date(finalSaleDetails.settlementDate) : null, 
            'datedDate': finalSaleDetails.datedDate ? new Date(finalSaleDetails.datedDate) : null ,
            'finalMaturityDate': finalSaleDetails.finalMaturityDate ? new Date(finalSaleDetails.finalMaturityDate) : null,
            'firstOptionalCallDate': finalSaleDetails.firstOptionalCallDate ? new Date(finalSaleDetails.firstOptionalCallDate) : null 
        } : 
        {});

    const [formErrors, setFormErrors] = useState({})
    

    const handleDate = (e, key) => {
        setFinalSaleInfo(prevState=>({
            ...prevState, 
            [key]: e
        })
        )
    }

    const saveFinalSaleInfo = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleInfo.finalSaleId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };
    
        FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Dates Updated Successfully!");
                updateFinalSaleDetails(result.data.responses[0]);
                props.sectionValidated('dates', true);
                props.openWidget('issuanceAuthorization');
            }
        });
        
    }
    
    const validateForm = (e) => {
        let form = e.target.closest('form#datesForm');

        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('dates', false);
        } else {
            setFormErrors({});
            saveFinalSaleInfo();
        }
    }

    return (
        <Card>
        <Form className="form" id="datesForm">
            <Row>
            <Col md={6}>
            <FormInput label="Proposed Sale Date" 
                name="proposedSaleDate" 
                value={CommonService.dateFormatter(finalSaleInfo.proposedSaleDate)}
                isrequired="true"
                disabled={true}
                helpText="Proposed Date of Sale"/>
            </Col>
            <Col md={6}>
                <div className="control">
                    <label><span className="required">*</span>Actual Sale Date: 
                        <TooltipComponent
                        title="Actual Sale Date"
                        id="actual-sale-date"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.actualSaleDate ? finalSaleInfo.actualSaleDate : null}
                        dateFormat="M/d/yyyy"
                        name="actualSaleDate"
                        maxDate= {new Date(9999, 12, 31)}
                        onChange={(e) => handleDate(e, "actualSaleDate")} 
                        error={formErrors && formErrors['actualSaleDate']}                                
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        <input type="hidden" data-validations={["required"]} value={finalSaleInfo.actualSaleDate} name="actualSaleDate" />
                        {formErrors.actualSaleDate && <p className="error">{formErrors.actualSaleDate}</p>}
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
                    <label><span className="required">*</span>Settlement Date: 
                        <TooltipComponent
                        title="Settlement Date"
                        id="settlement-date"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.settlementDate ? finalSaleInfo.settlementDate : null}
                        dateFormat="M/d/yyyy"
                        name="settlementDate"
                        maxDate= {new Date(9999, 12, 31)}
                        onChange={(e) => handleDate(e, "settlementDate")} 
                        error={formErrors && formErrors['settlementDate']} 
                        data-validations={["required"]}                               
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        {formErrors.settlementDate && <p className="error">{formErrors.settlementDate}</p>}
                        <input type="hidden" data-validations={["required"]} value={finalSaleInfo.settlementDate} name="settlementDate" />
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
                    <label><span className="required">*</span>Dated Date: 
                        <TooltipComponent
                        title="Dated Date"
                        id="dated-date"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.datedDate ? finalSaleInfo.datedDate : null}
                        dateFormat="M/d/yyyy"
                        name="datedDate"
                        maxDate= {new Date(9999, 12, 31)}
                        onChange={(e) => handleDate(e, "datedDate")} 
                        error={formErrors && formErrors['datedDate']} 
                        data-validations={["required"]}                               
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        <input type="hidden" data-validations={["required"]} value={finalSaleInfo.datedDate} name="datedDate" />
                        {formErrors.datedDate && <p className="error">{formErrors.datedDate}</p>}

                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
                    <label><span className="required">*</span>Final Maturity Date: </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={finalSaleInfo && finalSaleInfo.finalMaturityDate ? finalSaleInfo.finalMaturityDate : null}
                        dateFormat="M/d/yyyy"
                        name="finalMaturityDate"
                        maxDate= {new Date(9999, 12, 31)}
                        onChange={(e) => handleDate(e, "finalMaturityDate")} 
                        error={formErrors && formErrors['finalMaturityDate']} 
                        data-validations="required"                              
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        <input type="hidden" data-validations={["required"]} value={finalSaleInfo.finalMaturityDate} name="finalMaturityDate" />
                        {formErrors.finalMaturityDate && <p className="error">{formErrors.finalMaturityDate}</p>}
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
                <label><span className="required">*</span>First Optional Call Date: </label>
                <span>
                    <DatePicker 
                    selected={finalSaleInfo && finalSaleInfo.firstOptionalCallDate ? finalSaleInfo.firstOptionalCallDate : null}
                    dateFormat="M/d/yyyy"
                    name="firstOptionalCallDate"
                    maxDate= {new Date(9999, 12, 31)}
                    onChange={(e) => handleDate(e, "firstOptionalCallDate")} 
                    error={formErrors && formErrors['firstOptionalCallDate']} 
                    data-validations="required"                              
                    />
                    <FaCalendarAlt className="date-picker-icon"/>
                </span>
                <input type="hidden" data-validations={["required"]} value={finalSaleInfo.firstOptionalCallDate} name="firstOptionalCallDate" />
                {formErrors['firstOptionalCallDate'] && <p className="error">{formErrors['firstOptionalCallDate']}</p>}
                </div>
            </Col>
            </Row>

            <div className="btn-div">
            {/* <button className="custom-button" type="button" onClick={() => props.openWidget('issuance')}>Back</button> */}
            <button className="custom-button fright" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            
        </Form> 
        </Card>
    )
}

  
export default Dates;