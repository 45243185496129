import React, {useState, useEffect, useContext} from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import Card from '../../../components/ui/Card'
import AddFinancingParticipant from './addFinancingParticipant'
import { validateFormFields, runValidations } from '../../../utils/index'
import CurrencyInput from '../../formElements/CurrencyInput'
//import BootstrapTable from 'react-bootstrap-table-next'
import {AiFillQuestionCircle} from 'react-icons/ai'
import FinalSaleReportService from '../../../services/FinalSaleReportService'
import { toast } from 'react-toastify'
import CodeMaintenanceService from '../../../services/CodeMaintenanceService'
import CommonService from '../../../services/CommonService'
import { UserContext } from '../../../UserContext'
import IssueContactService from '../../../services/IssueContactService'
import Table from '../../Table'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import swal from 'sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import TooltipComponent from '../../../common/Tooltip'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const FinancingParticipantsTable = ({finalSaleId, role, financialParticipantRoles, updateIssuanceFees, getFinancialParticipantsByIssueId}) => {
    const loginUser = useContext(UserContext)
    const [financingContacts, setFinancingContacts] = useState([])
    const [editIdx, setEditIndex] = useState(-1)
    const [openFinancingContactModal, setOpenFinancingContactModal] = useState(false)
    const roleCodeId = financialParticipantRoles.filter(el => el.cdiacCodeValueCode === role.roleCode)[0].cdiacCodeValueId
    const getIssueContactAssocsByRole = () => {
        let roleCodes = role.roleCode;
        switch(role.roleCode){
            case 'FA':
                roleCodes = "FA,CFA,MA"
                break
            case 'UW':
                roleCodes = "UW,LUW,OUW,CUW"
                break
            case 'PU':
                roleCodes = "PU,CPU"
                break
            case 'BRC':
                roleCodes = "BRC,CBRW"
                break
            default:
                break;
        }
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getIssueContactAssocsByRole",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issueId": finalSaleId,
                "roleCode" : roleCodes
            },
          };
      
        IssueContactService.getIssueContactsByIssueId(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message)
                }
                else{
                    setFinancingContacts(result.data.responses[0])
                    const ContactFeesTotal = result.data.responses[0].reduce((total, item) => total + (item.feeAmt && item.feeAmt !== "" ? item.feeAmt : 0), 0)
                    updateIssuanceFees(role.feetype, ContactFeesTotal)
                }
            });
    }
    useState(()=>{
        getIssueContactAssocsByRole()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const deleteIssueContact = async(issueContactId) => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "deleteIssueContactAssocById",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueContactId": issueContactId,
            }
        };

        await IssueContactService.deleteIssueContact(obj)
        .then((res) => res)
        .then((result) => {
            getIssueContactAssocsByRole()
        });
    }

    const updateIssueContact = async (contactData)  => {
        const issueContactAssoc = financingContacts.filter(el => el.issueContactId === contactData.issueContactId)[0];
        issueContactAssoc.lastUpdateUser = CommonService.getUserName(loginUser);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueContactAssoc",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : issueContactAssoc.issueContactId,
                "issueContactAssoc": issueContactAssoc
            }
        };

        await IssueContactService.updateIssueContact(obj)
        .then((res) => res)
        .then((result) => {
            getIssueContactAssocsByRole()
        });
    }

    const startEditing = (i, rowData) => {
        setEditIndex(i);
    }
    const stopEditing = (i) => {
        setEditIndex(-1);
    }
    const handleSave = (i, contactData) => {
        updateIssueContact(contactData);
        setEditIndex(-1);
    }
    const handleRemove = (i, contactData) => {
        const issueContactId = contactData.issueContactId;
        deleteIssueContact(issueContactId);
        const financingContactsArr = [...financingContacts];
        const index = financingContactsArr.findIndex( el => el.issueContactId === contactData.issueContactId );
        financingContactsArr.splice(index,1);
    }
    const handleIssueContactChange = (e, name, i,contactData) => {
        const { value } = e.target;
        const financingContactsArr = [...financingContacts];
        const index = financingContactsArr.findIndex( el => el.issueContactId === contactData.issueContactId );
        financingContactsArr[index][name] = value !== '' ? parseFloat(value.replaceAll(',', '')) : 0;
        setFinancingContacts(financingContactsArr);
    }
    const addFinancingContact = () => {
        setOpenFinancingContactModal(true)
    }
    const handleModalClose = () => {
        setOpenFinancingContactModal(false)
        getIssueContactAssocsByRole()
        getFinancialParticipantsByIssueId()
    }

    return (
        <>
            <Table
                handleRemove={handleRemove}
                startEditing={startEditing}
                editIdx={editIdx}
                stopEditing={stopEditing}
                handleSave={handleSave}
                handleChange={handleIssueContactChange}
                data={financingContacts}
                header={[
                    {
                    name: "Organization",
                    prop: "organizationName",
                    editable: false
                    },
                    {
                        name: "Role",
                        prop: "roleCodeDescription",
                        editable: false,
                        hidden: role.roleCode === "UW" ? false : true 
                    },
                    {
                    name: "Contact First Name",
                    prop: "contactFirstName",
                    editable: false
                    },
                    {
                    name: "Contact Last Name",
                    prop: "contactLastName",
                    editable: false
                    },
                    {
                    name: "Issuance Fee",
                    prop: "feeAmt",
                    fieldType: "currency",
                    editable: true,
                    hidden: role.roleCode !== "UW" ? false : true
                    }
                ]}
            />
            <button type="button" className="custom-button" onClick={()=>addFinancingContact()}>Add {role.role}</button>
            {openFinancingContactModal && <AddFinancingParticipant onModalClose={handleModalClose} issueId={finalSaleId} roles={financialParticipantRoles} role={{...role, roleCodeId: roleCodeId}}/>}
        </>
    )
}
const RetiredIssues = (props) => {
    const {finalSaleDetails, updateFinalSaleDependencies} = props;
    const [finalSaleInfo, setFinalSaleInfo] = useState(props.finalSaleDetails ? props.finalSaleDetails : {});
    const loginUser = useContext(UserContext);
    const [formErrors, setFormErrors] = useState({});
    const [openFinancingContactModal, setOpenFinancingContactModal] = useState(false);
    const [showMelloRoosMainContact, setShowMelloRoosMainContact] = useState(finalSaleDetails.melloRoosReportableFlag ? true : false);
   // const [financialParticipantRoles, setFinancialParticipantRoles] = useState([]);
    const [allFinancialParticipantRoles, setAllFinancialParticipantRoles] = useState([]);
    const [otherFinancialParticipantRoles, setOtherFinancialParticipantRoles] = useState([]);
    const [financingContacts, setFinancingContacts] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [staticContactsExpandedRows, setStaticContactsExpandedRows] = useState(null);
    const [otherContactsExpandedRows, setOtherContactsExpandedRows] = useState(null);
    const [underwriterFees, setUnderwriterFees] = useState({
        'mgmtFeeAmt': finalSaleInfo.mgmtFeeAmt,
        'uwExpenseFeeAmt': finalSaleInfo.uwExpenseFeeAmt,
        'totalTakedownAmt': finalSaleInfo.totalTakedownAmt
    })
    const [issuanceFees, setIssuanceFees] = useState({
        'purchaserFeeAmt': finalSaleInfo.purchaserFeeAmt,
        'lenderFeeAmt': finalSaleInfo.lenderFeeAmt,
        'bondCounselFeeAmt': finalSaleInfo.bondCounselFeeAmt,
        'borrowerCounselFeeAmt':finalSaleInfo.borrowerCounselFeeAmt,
        'cobondCounselFeeAmt': finalSaleInfo.cobondCounselFeeAmt,
        'disclosureCounselFeeAmt': finalSaleInfo.disclosureCounselFeeAmt,
        'financialAdvisorFeeAmt':finalSaleInfo.financialAdvisorFeeAmt,
        'ratingAgencyFeeAmt': finalSaleInfo.ratingAgencyFeeAmt,
        'creditEnhancementFeeAmt': finalSaleInfo.creditEnhancementFeeAmt,
        'trusteeFeeAmt': finalSaleInfo.trusteeFeeAmt,
        'placementAgencyFeeAmt': finalSaleInfo.placementAgencyFeeAmt,
        'otherExpenseFeeAmt': finalSaleInfo.otherExpenseFeeAmt,
    })

    const updateIssuanceFees = (key, value) => {
        setFinalSaleInfo(prevState=>({
            ...finalSaleInfo, [key]: value
        }));
    }

    useEffect(()=>{
        setFinalSaleInfo(finalSaleDetails);
    },[finalSaleDetails]);

    useEffect(() => {
        async function checkIfMelloRoosReportable() {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "IsFinalSaleMelloRoosReportable",
            hdrStruct: null,
            queryParam: null,
            reqData: {"finalSaleId" : finalSaleDetails.finalSaleId},
            };
    
            ReportOfProposedDebtService.checkIfMelloRoosReportable(obj)
            .then((res) => res)
            .then((result) => {
                const isreportable = result.data.responses[0];
                if(isreportable) {
                    swal("This debt issue may be reportable pursuant to 53359.5 of the Mello-Roos Community Facilities Act of 1982.")
                    .then(()=>{
                        setShowMelloRoosMainContact(true);
                        // setFinalSaleInfo(prevState=> ({
                        //     ...prevState, 'melloRoosReportableFlag': 'Y'
                        // }))
                    })
                }
            }); 
        }

        (!finalSaleDetails.melloRoosReportableFlag || finalSaleDetails.melloRoosReportableFlag === "N") && checkIfMelloRoosReportable();
    // eslint-disable-next-line
    }, [finalSaleDetails.melloRoosReportableFlag]);

    const getFinancialParticipantsByIssueId = React.useCallback( () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getIssueContactAssocs",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "issueId": finalSaleInfo.finalSaleId,
                "reportType" : "RFS"
            },
          };
      
        IssueContactService.getIssueContactsByIssueId(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message)
                }
                else{
                    //should not show FC in financial participants tab
                    let filteredList = result.data.responses[0].filter(item => (!['BC', 'BRC', 'CB', 'DC', 'LE', 'PA', 'PU', 'FA', 'TR', 'GR', 'FC', 'MA', 'UW', 'OUW', 'LUW', 'CRA'].includes(item.roleCode)));
                    setFinancingContacts(filteredList);
                    updateFinalSaleDependencies(result.data.responses[0], 'issueContacts');
                }
            });
            // eslint-disable-next-line
    },[]);

    useEffect(()=>{
        async function getAllCodes() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "FINP", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
               // const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                setAllFinancialParticipantRoles(result.data.responses[0]);
                //setFinancialParticipantRoles(filteredList);
            })
        }
        async function getOtherFinancingParticipantsRoles() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetOtherFinancingParticipantsRoles",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "FINP", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                setOtherFinancialParticipantRoles(filteredList);
            })
        }
        getAllCodes();
        getOtherFinancingParticipantsRoles();
        getFinancialParticipantsByIssueId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

    const [totalUnderWriterDiscount, setTotalUnderWriterDiscount] = useState(0.00);
    const [totalIssuanceFee, setTotalIssuanceFee] = useState(0.00);

    useEffect(()=>{
        calculateIssuanceFeesTotal();
        // eslint-disable-next-line
    },[issuanceFees]);

    useEffect(()=>{
        finalSaleInfo &&  calculateUnderWriterDiscount();
       // eslint-disable-next-line  
    },[underwriterFees]);

    const calculateUnderWriterDiscount = () => {
        let total = 0;
        Object.entries(underwriterFees).forEach(([k, v])=> {
            total = total + (v !== "" ? v : 0);
        })
        setTotalUnderWriterDiscount(total);
    }

    const calculateIssuanceFeesTotal = () => {
        let total = 0;
        Object.entries(issuanceFees).forEach(([k, v])=> {
            total = total + (v !== "" ? v : 0);
        })
        setTotalIssuanceFee(total);
    }

    const handleChange = e => {
        const {name, value } = e.target;
        
        setFinalSaleInfo(prevState=>({
            ...finalSaleInfo, [name]: value
        }));
       
        if(['mgmtFeeAmt', 'uwExpenseFeeAmt', 'totalTakedownAmt'].includes(name)) {
            setUnderwriterFees(prevState=>({
                ...underwriterFees, [name]: Number(value.replace(/,/g,''))
            }))
        } else {
            if(name !== "melloRoosReportableFlag") {
                setIssuanceFees(prevState=>({
                    ...issuanceFees, [name]: Number(value.replace(/,/g,''))
                }));
            }
        }
    }

    const addFinancingContact = () => {
        setOpenFinancingContactModal(true);
    }

    const handleModalClose = () => {
        setOpenFinancingContactModal(false);
        getFinancialParticipantsByIssueId();
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }

    const checkGuarantor = async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateGuarantor",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueId": finalSaleInfo.finalSaleId,
            }
        };
    
        await FinalSaleReportService.validateGuarantor(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            } else {
                saveFees();
            }
        });
    }

    const validateFinancingParticipantsFees = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "validateFinancingParticipantsFees",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueId": finalSaleInfo.finalSaleId,
            }
        };

        await FinalSaleReportService.validateFinancingParticipantsFees(obj)
        .then((res) => res)
        .then((result) => {
            const InvoiceeContactExists = financingContacts.some(el=>el.roleCode === "IN");
            if( !InvoiceeContactExists) {
                swal("An Invoice Contact must be identified regardless of whether an issuance fee exception applies. If an exception applies, an invoice will not be sent. The issuance fee is authorized pursuant to Government Code section 8856.")
                return
            } 

            if(result.data.errors[0]) {
                toast.error(result.data.errors[0]);
            } else if(result.data.responses[0].length>0) {
                let alertMessage = "";
                (result.data.responses[0]).forEach((el, i) => {
                alertMessage +=  "- " + el.message + "\n";
                });
                alert(alertMessage); 
            } else {
                toast.success("Financing Participants/Costs updated successfully!");
                props.sectionValidated('financingParticipants', true);
                props.openWidget('interestDebtExempt');  
            }
        });
    }

    const validateForm = (e) => {
        let form = e.target.closest('form#financingParticipantsForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('financingParticipants', false);
        } else {
            setFormErrors({});
            checkGuarantor();
        }
    }

    const saveFees = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": finalSaleInfo.finalSaleId,
                "finalSale": {...finalSaleInfo,
                    'lastUpdateUser': CommonService.getUserName(loginUser)}
            }
        };

        await FinalSaleReportService.updateFinalSale(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                props.updateFinalSaleDetails(result.data.responses[0]);
                validateFinancingParticipantsFees();
            }
        });
    }

    const deleteIssueContact = async(issueContactId) => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "deleteIssueContactAssocById",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueContactId": issueContactId,
            }
        };

        await IssueContactService.deleteIssueContact(obj)
        .then((res) => res)
        .then((result) => {
        });
    }

    // #####################################

    const [financingContactsNewArr, setFinancingContactsNewArr] = useState([]);
    const addFinancingContacts = (financingContacts) => {
        const financingContactsNew = [];
        const startId = financingContactsNew.length;
        for (let i = 0; i < financingContacts.length; i++) {
            const ind = financingContactsNew.findIndex(el => el.contactRoleDesc === financingContacts[i].roleCodeDescription);
            if (ind === -1) {
                const id = startId + i;
                financingContactsNew.push({
                    id: id,
                contactRoleDesc: financingContacts[i].roleCodeDescription,
                feeAmt: financingContacts[i].feeAmt,
                expand: [ {
                    issueContactId: financingContacts[i].issueContactId,
                    contact: financingContacts[i].organizationName,
                    contactFirstName: financingContacts[i].contactFirstName,
                    contactLastName: financingContacts[i].contactLastName,
                    feeAmt: CommonService.currencyFormatter(financingContacts[i].feeAmt),
                    //notEditable: ["MC","ADTR","IN"].includes(financingContacts[i].roleCode) ? true : false,
                }]
                });
            } else {
                financingContactsNew[ind].feeAmt = (financingContactsNew[ind].feeAmt ? financingContactsNew[ind].feeAmt : 0) + (financingContacts[i].feeAmt ?financingContacts[i].feeAmt : 0);
                financingContactsNew[ind].expand.push({
                    issueContactId: financingContacts[i].issueContactId,
                    contact: financingContacts[i].organizationName,
                    contactFirstName: financingContacts[i].contactFirstName,
                    contactLastName: financingContacts[i].contactLastName,
                    feeAmt: CommonService.currencyFormatter(financingContacts[i].feeAmt),
                    //notEditable: ["MC","ADTR","IN"].includes(financingContacts[i].roleCode) ? true : false,
                })
            }
        }

        setFinancingContactsNewArr(financingContactsNew);
      }

    useEffect(()=>{
    financingContacts && addFinancingContacts(financingContacts);
    },[financingContacts])
    
    const viewFormatter = (row, rowIndex) => { 
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
               <FontAwesomeIcon icon={faTrash} className="custom-icon" onClick={(e)=>handleRemove(rowIndex, row)}/> 
            </div> 
        ); 
    }
    const otherContactColumns = [{
        dataField: 'contactRoleDesc',
        text: 'Contact Role'
    }];
    const othInnerContactsColumns = [{
        dataField: 'contact',
        text: 'Organization'
    },
    {
        dataField: 'contactFirstName',
        text: 'Contact First Name'
    },
    {
        dataField: 'contactLastName',
        text: 'Contact Last Name'
    },
    {
        dataField: '',
        text: '',
        formatter: viewFormatter
    }];

    const columns = [{
        dataField: 'role',
        text: 'Contact Role'
      },{
        dataField: 'feeAmt',
        text: 'Issuance Total Fee',
        formatter: (row) => CommonService.currencyFormatter(row['feeAmt'])
    }];
    const underWriterColumns = [{
        dataField: 'role',
        text: 'Contact Role'
      }];

    const staticContacts = [{
        id: 0,
        role: 'Bond Counsel',
        roleCode: 'BC',
        feeAmt: finalSaleInfo.bondCounselFeeAmt,
        feetype: 'bondCounselFeeAmt'
    },
    {
        id: 1,
        role: 'Borrower Counsel/Loan',
        roleCode: 'BRC',
        feeAmt: finalSaleInfo.borrowerCounselFeeAmt,
        feetype: 'borrowerCounselFeeAmt'
    },
    {
        id: 2,
        role: 'Co-Bond Counsel',
        roleCode: 'CB',
        feeAmt: finalSaleInfo.cobondCounselFeeAmt,
        feetype: 'cobondCounselFeeAmt'
    },
    {
        id: 3,
        role: 'Disclosure Counsel',
        roleCode: 'DC',
        feeAmt: finalSaleInfo.disclosureCounselFeeAmt,
        feetype: 'disclosureCounselFeeAmt'
    },
    {
        id: 4,
        role: 'Lender',
        roleCode: 'LE',
        feeAmt: finalSaleInfo.lenderFeeAmt,
        feetype: 'lenderFeeAmt'
    },
    {
        id: 5,
        role: 'Placement Agent',
        roleCode: 'PA',
        feeAmt: finalSaleInfo.placementAgencyFeeAmt,
        feetype: 'placementAgencyFeeAmt'
    },
    {
        id: 6,
        role: 'Purchaser',
        roleCode: 'PU',
        feeAmt: finalSaleInfo.purchaserFeeAmt,
        feetype: 'purchaserFeeAmt'
    },
    {
        id: 7,
        role: 'Credit Rating Agency',
        roleCode: 'CRA',
        feeAmt: finalSaleInfo.ratingAgencyFeeAmt,
        feetype: 'ratingAgencyFeeAmt'
    },
    {
        id: 8,
        role: 'Trustee/Paying Agent',
        roleCode: 'TR',
        feeAmt: finalSaleInfo.trusteeFeeAmt,
        feetype: 'trusteeFeeAmt'
    },
    {
        id: 9,
        role: 'Financial Advisor',
        roleCode: 'FA',
        feeAmt: finalSaleInfo.financialAdvisorFeeAmt,
        feetype: 'financialAdvisorFeeAmt'
    },
    {
        id: 10,
        role: 'Guarantor / Credit Enhancement Provider',
        roleCode: 'GR',
        feeAmt: finalSaleInfo.creditEnhancementFeeAmt,
        feetype: 'creditEnhancementFeeAmt'
    }]

    const underWriterInfo = [{
        id: 0,
        role: 'Underwriter',
        roleCode: 'UW'
    },
    ]

    // const isExpandableRow = (row) => {
    //     return true;
    // }
    
    const handleRemove = (i, contactData) => {
        const issueContactId = contactData.issueContactId;
        deleteIssueContact(issueContactId);
        const financingContactsArr = [...financingContacts];
        const index = financingContactsArr.findIndex( el => el.issueContactId === contactData.issueContactId );
        financingContactsArr.splice(index,1);
        setFinancingContacts(financingContactsArr);
    }

    const expandRow = (row) => {
        return (
            <FinancingParticipantsTable finalSaleId={finalSaleInfo.finalSaleId} role={row} financialParticipantRoles={allFinancialParticipantRoles} updateIssuanceFees={updateIssuanceFees} getFinancialParticipantsByIssueId={getFinancialParticipantsByIssueId}/>
         )
    };

    const expandOtherContactRow = (row) => {
        return (
            <DataGrid
                dataSource={row.expand}
                showPaginator={false}
                showQuickSearch={false}
            >
                {othInnerContactsColumns.map((column, i) => {
                    return (
                    <Column 
                    key={i}
                    field={column.dataField}
                    header={column.text}
                    sortable={column.sort} 
                    body={column.formatter}
                    hidden={column.hidden}
                    />)
                    }) 
                } 
            </DataGrid>
        )
    };

    return (
        <Card>
        <Form className="form" id="financingParticipantsForm">
            <h3>Costs of Issuance</h3>
            <div>
                <h4>Underwriter Fees and Expenses</h4>
                <Row>
                    <Col md={6}>
                        <div className="control">
                        <CurrencyInput placeholder="0.00" 
                        label="Management Fee" 
                        name="mgmtFeeAmt" 
                        type="text" 
                        value={finalSaleInfo.mgmtFeeAmt}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.mgmtFeeAmt} 
                        validations={["max:999999999999.99"]}
                        helpText="Enter the amount paid for managing the affairs of an underwriting syndicate."
                        isrequired="true"
                        />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                        <CurrencyInput placeholder="0.00" 
                        label="Total Takedown Amount" 
                        name="totalTakedownAmt" 
                        type="text" 
                        value={finalSaleInfo.totalTakedownAmt}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.totalTakedownAmt} 
                        validations={["max:999999999999.99"]}
                        helpText="Enter the amount paid as commission to the underwriter or members of the syndicate for the issue."
                        isrequired="true"
                        />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="control">
                        <CurrencyInput placeholder="0.00" 
                        label="Underwriter Expense" 
                        name="uwExpenseFeeAmt" 
                        type="text" 
                        value={finalSaleInfo.uwExpenseFeeAmt}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.uwExpenseFeeAmt} 
                        validations={["max:999999999999.99"]}
                        helpText="Enter the amount paid for to cover the costs of the underwriting activities, include any expenses for
                        Underwriters and Lead Underwriter."
                        isrequired="true"
                        />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <label>Underwriter Spread or Discount: </label>
                            <Form.Control type="text" name="underwriterDiscount" value={CommonService.currencyFormatter(totalUnderWriterDiscount)} disabled/>
                        </div>
                    </Col>
                </Row>
                <DataGrid
                dataSource={underWriterInfo}
                showPaginator={false}
                showQuickSearch={false}
                rowExpansionTemplate={expandRow}
                expandedRows={expandedRows}
                onRowToggle={(e)=>{setExpandedRows(e.data)}}
                >
                    <Column expander={true} style={{ width: '3rem'}}/>
                    {underWriterColumns.map((column, i) => {
                        return (
                        <Column 
                        key={i}
                        field={column.dataField}
                        header={column.text}
                        sortable={column.sort} 
                        body={column.formatter}
                        hidden={column.hidden}
                        />)
                        }) 
                    } 
                </DataGrid>
            </div>
            {showMelloRoosMainContact && <>
            <div>
                <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Mello-Roos Reportable ?
                    <TooltipComponent
                        title="Based on the type of issuer, statutory authorization, debt type or revenue source selected, this report of debt issuance is subject to review by CDIAC for determining whether this issue is reportable under GOV 53359.5 of the Mello-Roos Community Facilities Act of 1982."
                        id="refunding-amt"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon heading-help-icon" />
                    </TooltipComponent>
                </label>
                    <br />
                    <Form.Check
                            inline
                            label="Yes"
                            id="melloRoos-yes"
                            value="Y"
                            name="melloRoosReportableFlag"
                            type="radio"
                            checked={finalSaleInfo.melloRoosReportableFlag === 'Y'}
                            onChange={(e)=>handleChange(e)}
                        />
                    <Form.Check
                            inline
                            label="No"
                            id="melloRoos-no"
                            value="N"
                            name="melloRoosReportableFlag"
                            type="radio"
                            checked={finalSaleInfo.melloRoosReportableFlag === 'N'}
                            onChange={(e)=>handleChange(e)}
                        />
                    {formErrors['melloRoosReportableFlag'] && <p className="error">{formErrors['melloRoosReportableFlag']}</p>}
            </div> 
            <br/>               
            </>
            }

            <div>
                <h4>Additional Fees and Expenses</h4>
                <div className="mb-20">
                    <h5 className="mb-10">Contacts</h5>
                    <DataGrid
                    dataSource={staticContacts}
                    showPaginator={false}
                    showQuickSearch={false}
                    rowExpansionTemplate={expandRow}
                    expandedRows={staticContactsExpandedRows}
                    onRowToggle={(e)=>{setStaticContactsExpandedRows(e.data)}}
                    >
                        <Column expander={true} style={{ width: '3rem'}}/>
                        {columns.map((column, i) => {
                            return (
                            <Column 
                            key={i}
                            field={column.dataField}
                            header={column.text}
                            sortable={column.sort} 
                            body={column.formatter}
                            hidden={column.hidden}
                            />)
                            }) 
                        } 
                    </DataGrid>
                </div> 
                <Row>
                    <Col md={6}>
                        <div className="control">
                        <CurrencyInput placeholder="0.00" 
                        label="Other Expenses Amount" 
                        name="otherExpenseFeeAmt" 
                        type="text" 
                        value={finalSaleInfo.otherExpenseFeeAmt}
                        onChange={(e)=>handleChange(e)} 
                        onBlur={e=>validateField(e)}
                        error={formErrors.otherExpenseFeeAmt} 
                        validations={["required","max:999999999999.99"]}
                        helpText="Enter amount for any other expenses of the issue."
                        isrequired="true"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 6 }}>
                        <div style={{display: 'inline-flex'}}>
                            <label>Total Additional Fees and Expenses: </label>
                            <input type="text" value={CommonService.currencyFormatter(totalIssuanceFee)} disabled/>
                        </div>
                    </Col>
                </Row>  
                <Row>
                    <Col md={{ span: 5, offset: 7 }}>
                        <div style={{display: 'inline-flex'}} >
                            <label>Total Costs of Issuance: </label>
                            <input type="text" value={CommonService.currencyFormatter(totalIssuanceFee+totalUnderWriterDiscount)} disabled/>
                        </div>
                    </Col>
                </Row>  
            </div>

            <div className="mb-20">
                <h5 className="mb-10">Invoice Contact, Issuer Contacts and Other Contacts</h5>
                <DataGrid
                dataSource={financingContactsNewArr}
                showPaginator={false}
                showQuickSearch={false}
                rowExpansionTemplate={expandOtherContactRow}
                expandedRows={otherContactsExpandedRows}
                onRowToggle={(e)=>{setOtherContactsExpandedRows(e.data)}}
                showHeader={false}
                >
                    <Column expander={true} style={{ width: '3rem'}}/>
                    {otherContactColumns.map((column, i) => {
                        return (
                        <Column 
                        key={i}
                        field={column.dataField}
                        header={column.text}
                        sortable={column.sort} 
                        body={column.formatter}
                        hidden={column.hidden}
                        />)
                        }) 
                    } 
                </DataGrid>
            </div> 
            <button type="button" className="custom-button" onClick={()=>addFinancingContact()}>Add Other Invoice Contact, Issuer Contacts and Other Contacts</button>
            {openFinancingContactModal && <AddFinancingParticipant onModalClose={handleModalClose} issueId={finalSaleDetails.finalSaleId} roles={otherFinancialParticipantRoles} role={{roleCode: 'OTH'}}/>}
            
            <div className="btn-div">
            <button className="custom-button fright" type="button" onClick={(e)=>validateForm(e)}>Save & Next</button>
            </div>
        </Form> 
        </Card>
    )
}

export default RetiredIssues
