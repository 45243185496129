import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import BaseService from "../../../services/BaseService";
import { FormInput } from "../../formElements/FormInput";
import CommonService from "../../../services/CommonService";
import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import { AlertType, validateFormFields } from "../../../utils";

const DebtWatchConfiguration = () => {
    const user = useContext(UserContext);
    const [formErrors, setFormErrors] = useState({});
    const [configuration, setConfiguration] = useState("");
    useEffect( () => {
      CommonService.showHideSpinner(true);
      const fetchData = async() => {
          var obj = {
            msgId: null,
            sysId: null,
            opr: "getDebtWatchConfiguration",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
          };
      
          await BaseService.processRequest(obj)
            .then((res) => res)
            .then((result) => {
              if (result.data.errors && result.data.errors.length > 0){
                CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
              }
              else{
                setConfiguration(result.data.responses[0]);
              }
              CommonService.showHideSpinner(false);
            });
          }
      fetchData();
    },[])

    const handleDateChange = (e, key) => {
      setConfiguration(prevState=>({
          ...prevState, 
          [key]: e.target.value
      })
      )
    }
    const handleSubmit = (e) => {
      e.preventDefault();
        const form = e.target.closest('form#debtwatch-configuration');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({});
            var obj = {
              msgId: null,
              sysId: null,
              opr: "updateDebtWatchConfiguration",
              hdrStruct: null,
              queryParam: null,
              reqData: {
                "debtWatchConfiguration" : {...configuration, lastUpdateUser: CommonService.getUserName(user)}
              },
            };
        
            BaseService.processRequest(obj)
              .then((res) => res)
              .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                  CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
                  }
                  else{
                    CommonService.displayServerMessage("DebtWatch Publication Configuration Updated Successfully!", AlertType.Success);
                  }
              });
      }
    }
    return (
        <form onSubmit={(e) => e.preventDefault()} id="debtwatch-configuration">
          <Card>
            <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title" >
                        <h1 className="menu-title">DebtWatch Publication Configuration</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                    <div className="d-flex justify-content-between">
                      
                    <div className="control">
                    <FormInput label="ADTR Publication Year" 
                            name="adtrPublicationYear" 
                            type="text" 
                            maxLength={4}
                            isrequired={true}
                            validations={["required", "calendarYear"]}
                            onChange={(e) => handleDateChange(e, 'adtrPublicationYear')} 
                            error={formErrors.adtrPublicationYear} 
                            value={configuration.adtrPublicationYear ? configuration.adtrPublicationYear : "" }
                            />
                        </div>
                        <div className="control">
                            <FormInput label="Marks-Roos Publication Year" 
                            name="mkrPublicationYear" 
                            type="text" 
                            maxLength={4}
                            isrequired={true}
                            validations={["required", "calendarYear"]}
                            onChange={(e) => handleDateChange(e, 'mkrPublicationYear')} 
                            error={formErrors.mkrPublicationYear} 
                            value={configuration.mkrPublicationYear ? configuration.mkrPublicationYear : "" }
                            />
                        </div>
                        <div className="control">
                            <FormInput label="Mello-Roos Publication Year" 
                            name="mlrPublicationYear" 
                            type="text" 
                            maxLength={4}
                            isrequired={true}
                            validations={["required", "calendarYear"]}
                            onChange={(e) => handleDateChange(e, 'mlrPublicationYear')} 
                            error={formErrors.mlrPublicationYear} 
                            value={configuration.mlrPublicationYear ? configuration.mlrPublicationYear : "" }
                            />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div style={{overflow: 'auto'}}>
                <button className="custom-button fright" onClick={(e)=>handleSubmit(e)}>Update</button>
                </div>
              </div>
            </div>
            
          </Card>
          
        </form>
    )
}

export default DebtWatchConfiguration;