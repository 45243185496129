import React, { useEffect, useState } from 'react'
import {Modal, Spinner, Table} from 'react-bootstrap'
import CommonService from '../../services/CommonService';
import ReportsService from '../../services/ReportsService'
const EditReasonHistory = (props) => {
    const {cdiacCodeValueCode, issueId, onModalClose} = props;
    const [editReasonHistory, setEditReasonHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true);
        const reasonObj = {};
        reasonObj.CdiacCodeValueCode = cdiacCodeValueCode;
        if(cdiacCodeValueCode === "DRWER") {
            reasonObj.drawId = issueId;
        }else if(cdiacCodeValueCode === "RFSER"){
            reasonObj.finalSaleId = issueId;
        }else if(cdiacCodeValueCode === "MKLER"){
            reasonObj.MkrLobId = issueId;
        }else if(cdiacCodeValueCode === "MLRER"){
            reasonObj.MelloRoosId = issueId;
        }else if(cdiacCodeValueCode === "MKAER"){
            reasonObj.MkrAuthId = issueId;
        }else if(cdiacCodeValueCode === "ADTER"){
            reasonObj.AdtrId = issueId;
        }
        async function fetchEditReasonHistory() {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getEditReasonHistory",
                hdrStruct: null,
                queryParam: null,
                reqData:{
                    "editReason" : reasonObj
                }
            };
          
            await ReportsService.getEditReasonHistory(obj)
            .then((result) => {
                if(result.data.responses[0]) {
                    let reasonsArr = [];
                    result.data.responses[0].forEach((item, i) => {
                        item.displayComment = item.comment;
                        if( item.comment.length > 32 ) {
                            item.displayComment = item.comment.substr(0, 32) + "...";
                            item.expanded = false;
                        }
                        
                        reasonsArr.push(item);
                    })
                    setEditReasonHistory(reasonsArr);
                }
                setIsLoading(false);
            });
        }
        fetchEditReasonHistory();
      }, [issueId, cdiacCodeValueCode]);
    
    const toggleText = (e, i) => {
        const history = [...editReasonHistory];
        history[i].expanded = !history[i].expanded;
        if(history[i].expanded) {
            history[i].displayComment = history[i].comment;
        } else {
            history[i].displayComment =  history[i].comment.substr(0, 32) + "...";
        }
        setEditReasonHistory(history);
    }
    return (
        <Modal show={true} backdrop="static" onHide={onModalClose} size='lg'>
            <Modal.Header closeButton><h3>View - Edit Reason History</h3></Modal.Header>
            <Modal.Body>
                {isLoading ? <Spinner animation="border" /> :
                <Table>
                    <thead>
                        <tr>
                            <th>Reason for Edit</th>
                            <th>Comment</th>
                            <th>Create User</th>
                            <th>Create Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {editReasonHistory.map((reason,i)=>{
                            return (
                                <tr key={i}>
                                <td>{reason.cdiacCodeValueDesc}</td>
                                <td>{reason.displayComment}&nbsp; 
                                {reason.hasOwnProperty('expanded') ? 
                                    (reason.expanded ? 
                                    <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show less
                                    </button>
                                    : <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show more
                                    </button>
                                    ) : ""
                                }
                                </td>
                                <td>{reason.createUser}</td>
                                <td>{CommonService.dateFormatter(reason.createDatetime)}</td>
                                </tr>
                            )
                        })}
                       
                    </tbody>
                </Table>
                }
            </Modal.Body>
            <Modal.Footer>
            <button className="custom-button-secondary  btn-secondary" onClick={()=>onModalClose()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditReasonHistory