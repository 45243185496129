import { useEffect, useState } from 'react'
import { Form, Modal, Row, Col } from 'react-bootstrap'
import Card from '../ui/Card'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { AlertType, validateFormFields } from '../../utils/index'
import FinalSaleReportService from "../../services/FinalSaleReportService"
import CommonService from "../../services/CommonService"
import { UserContext } from "../../UserContext"
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { IssuanceDetailContext } from '../../UserContext'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { FaCalendarAlt, FaRegCalendarAlt} from 'react-icons/fa'
import IssueCdiacNumberService from '../../services/IssueCdiacNumberService'
import { Typeahead } from 'react-bootstrap-typeahead'

const RetiredIssues = (props) => {
    const { markRoosLocalOblig, markRoosLocalObligDetails } = props;
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [showMaturedFields, setShowMaturedFields] = useState(false);
    const [showRedeemedFields, setShowRedeemedFields] = useState(false);
    const [showOtherFields, setShowOtherFields] = useState(false);
    const [showNewCdiacField, setShowNewCdiacField] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const user = useContext(UserContext);
    const issuanceDetail = useContext(IssuanceDetailContext);
    const [cdiacNumbersList, setCdiacNumbersList] = useState([]);
    const [cdiacNbrsLoading, setCdiacNbrsLoading] = useState(true);
console.log("Issuance Detail", issuanceDetail);
console.log("markRoosLocalObligDetails", markRoosLocalObligDetails);
    const RetiredIssuesOptions = [
        { value: 'N', label: 'Not Retired' },
        { value: 'M', label: 'Matured' },
        { value: 'R', label: 'Redeemed Entirely' },
        { value: 'O', label: 'Other' },
    ]

    const [stateValues, setStateValues] = useState({
        retireDate: issuanceDetail.retireDate ? new Date(issuanceDetail.retireDate) : null,
        retireFlag: issuanceDetail.retireFlag,
        issueName: issuanceDetail.issueName,
        redeemCdiacNumber: issuanceDetail.redeemCdiacNumber,
        stateRefundingBondTitle: issuanceDetail.stateRefundingBondTitle,
        finalMaturityDate: issuanceDetail.retireDate ? new Date(issuanceDetail.retireDate) : null,
        correctCdiacNbrInd: markRoosLocalObligDetails.correctCdiacNbrInd,
        correctedRedeemCdiacNbr: markRoosLocalObligDetails.correctedRedeemCdiacNbr,
        otherRetiredText: issuanceDetail.otherRetireText,
        otherDate: issuanceDetail.retireDate ? new Date(issuanceDetail.retireDate) : null 
    })

    useEffect(() => {    
        async function getCdiacNumbers() {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getCdiacNumbers",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {}
            };
           
            IssueCdiacNumberService.getCdiacNumbers(obj)
              .then((res) => res)
              .then((result) => {
                setCdiacNumbersList(result.data.responses[0]);
                setCdiacNbrsLoading(false);
              });
        }
        getCdiacNumbers();
    }, []);

    useEffect(()=>{
        handleRetirementSelection(stateValues.retireFlag);
    },[stateValues.retireFlag]);

    const handleRetirementSelection = (retireFlag) => {
        switch (retireFlag) {
            case "M":
                setShowMaturedFields(true);
                setShowRedeemedFields(false);
                setShowOtherFields(false);
                break;
            case "R":
                setShowMaturedFields(false);
                setShowRedeemedFields(true);
                setShowOtherFields(false);
                break;
            case "O":
                setShowRedeemedFields(false);
                setShowMaturedFields(false);
                setShowOtherFields(true);
                break;
            default:
                setShowMaturedFields(false);
                setShowRedeemedFields(false);
                setShowOtherFields(false);
                break;
        }
    }

    const handleChange = (e) => {
        switch (e.target.value) {
            case "M":
                setShowMaturedFields(true);
                setShowRedeemedFields(false);
                setShowOtherFields(false);
                break;
            case "R":
                setShowMaturedFields(false);
                setShowRedeemedFields(true);
                setShowOtherFields(false);
                break;
            case "O":
                setShowRedeemedFields(false);
                setShowMaturedFields(false);
                setShowOtherFields(true);
                break;
            default:
                setShowMaturedFields(false);
                setShowRedeemedFields(false);
                setShowOtherFields(false);
                break;
        }

        const { name, value } = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const handleCdiacNbrChange = (e, key) => {
        const value = e.length ? e[0].cdiacNbr : "";

        setStateValues(prevState => ({
            ...stateValues, [key]: value
        })
        )
    }

    const handleChangeDate = (e, key) => {
        setStateValues(prevState => ({
            ...stateValues, [key]: e
        })
        )
    }
    const handleOtherTextChange = (e) => {
        const { name, value } = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const checkIfcdiacCorrect = (e) => {
        let newStateValues = {...stateValues}
        const {name, value} = e.target
        newStateValues[name] = value
        if (e.target.checked && e.target.value === "N") {
            setShowNewCdiacField(true)
        } else {
            setShowNewCdiacField(false)
            newStateValues['correctedRedeemCdiacNbr'] = null
        }
        
        setStateValues(newStateValues) 
    }

    const validateForm = async (e) => {
        let form = e.target.closest('form#retiredIssues');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            if(stateValues.retireFlag !== 'N' && markRoosLocalObligDetails.principalOutstdgAmt > 0) {
                CommonService.displayServerMessage("In order for this issue to be marked as retired, the Principal Amount of Bonds Outstanding in the Fund Balance section must be $0.", AlertType.Error);
                return;
            }
            if (stateValues.retireFlag === 'R') {
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "updateMKRRoosLocalObligNbrInd",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: {
                        "id": markRoosLocalOblig.mkrLobId,
                        "env": "I",
                        "marksRoosLocalObligor": {
                            "correctCdiacNbrInd": stateValues.correctCdiacNbrInd,
                            "correctedRedeemCdiacNbr": stateValues.correctedRedeemCdiacNbr,
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
                    },
                };

                await FinalSaleReportService.updateMKRRoosLocalObligRetire(obj)
                    .then((result) => {
                        if (result.data.errors && result.data.errors.length > 0) {
                            toast.error(result.data.errors[0].message);
                        }
                        else {
                            props.updateMkrLocalObligorDetails(result.data.responses[0]);
                        }
                    });
            }

            if (stateValues.retireFlag === 'M' && stateValues.finalMaturityDate !== undefined) {

                var fullYear = CommonService.getFullYear(markRoosLocalOblig.fiscalYrEndDate);
                var previousYear = fullYear - 1;

                var compareDate = CommonService.dateFormatter(stateValues.finalMaturityDate);
                var startDate = CommonService.dateFormatter('7/1/' + previousYear);
                var endDate = CommonService.dateFormatter('6/30/' + fullYear);

                if(compareDate.valueOf() < startDate.valueOf() || compareDate.valueOf() > endDate.valueOf() ) {
                    stateValues.retireDate = compareDate;
                }
                else {
                    toast.error('Invalid Date. The date entered must fall within the filing year July 1st - June 30th');
                    return;
                }

            }

            if (stateValues.retireFlag === 'R' && stateValues.retireDate !== undefined) {

                var fullYearR = CommonService.getFullYear(markRoosLocalOblig.fiscalYrEndDate);
                var previousYearR = fullYearR - 1;

                var compareDateR = CommonService.dateFormatter(stateValues.retireDate);
                var startDateR = CommonService.dateFormatter('7/1/' + previousYearR);
                var endDateR = CommonService.dateFormatter('6/30/' + fullYearR);

                if(compareDateR.valueOf() < startDateR.valueOf() || compareDateR.valueOf() > endDateR.valueOf() ) {
                    toast.error('Invalid Date. The date entered must fall within the filing year July 1st - June 30th');
                    return;
                }

            }

            if (stateValues.retireFlag === 'O' && stateValues.otherDate !== undefined) {

                var fullYearO = CommonService.getFullYear(markRoosLocalOblig.fiscalYrEndDate);
                var previousYearO = fullYearO - 1;
                console.log(previousYearO);
                var compareDateO = CommonService.dateFormatter(stateValues.otherDate);
                var startDateO = CommonService.dateFormatter('7/1/' + previousYearO);
                var endDateO = CommonService.dateFormatter('6/30/' + fullYearO);

                if(compareDateO.valueOf() < startDateO.valueOf() || compareDateO.valueOf() > endDateO.valueOf() ) {
                    stateValues.retireDate = compareDateO;
                }
                else {
                    toast.error('Invalid Date. The date entered must fall within the filing year July 1st - June 30th');
                    return;
                }

            }

            if (stateValues.retireFlag === 'O' && stateValues.otherDate !== undefined && stateValues.otherRetiredText !== '') {
                var objanother = {
                    msgId: null,
                    sysId: null,
                    opr: "updateMKRRoosLocalObligOther",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: {
                        "env": "I",
                        "marksRoosLocalObligor": {
                            "retireFlag": stateValues.retireFlag,
                            "otherRetiredText": stateValues.otherRetiredText,
                            "retireDate": stateValues.otherDate,
                            "finalSaleId": issuanceDetail.finalSaleId,
                            "lastUpdateUser": CommonService.getUserName(user),
                            "emailId": user
                        }
                    },
                };

                await FinalSaleReportService.updateMKRRoosLocalObligOther(objanother)
                    .then((result) => {
                        if (result.data.errors && result.data.errors.length > 0) {
                            toast.error(result.data.errors[0].message);
                            return;
                        }
                        else {
                            props.updateIssuanceDetails(result.data.responses[0]);
                        }

                    });

            }

            let updateobj = {
                msgId: null,
                sysId: null,
                opr: "updateMKRRoosLocalObligRetire",
                hdrStruct: null,
                queryParam: null,
                reqData: {
                    "env": "I",
                    "marksRoosLocalObligor": {
                        "retireFlag": stateValues.retireFlag,
                        "retireDate": stateValues.retireFlag !== 'N' ? stateValues.retireDate : null,
                        "finalSaleId": issuanceDetail.finalSaleId,
                        "lastUpdateUser": CommonService.getUserName(user),
                        "emailId": user
                    }
                },
            };

            await FinalSaleReportService.updateMKRRoosLocalObligRetire(updateobj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0) {
                        toast.error(result.data.errors[0].message);
                    }
                    else {
                        props.updateIssuanceDetails(result.data.responses[0]);
                        toast.success("Retired Issue updated successfully!");
                    }

                });

            props.openWidget('filingContact')
        }
    }
    return (
        <Card>
            <form className="form" id="retiredIssues">
                <h3>Retired Issues  <AiFillQuestionCircle onClick={() => setShowInstructionsModal(true)} className="help-icon heading-help-icon" />

                    {showInstructionsModal &&
                        <Modal show={true} backdrop="static" onHide={() => setShowInstructionsModal(false)}>
                            {/* <Modal.Header closeButton><h3>Issuance Authorization</h3></Modal.Header> */}
                            <Modal.Body>
                                <div>
                                    <span>{props.instructions}</span>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" onClick={() => setShowInstructionsModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }</h3>

                <Row>
                    <Col md={6}>
                        <label><span className="required">*</span>Indicate Reason for Retirement</label>
                        <Form.Select name="retireFlag"
                            defaultValue={issuanceDetail.retireFlag}
                            data-validations="required"
                            onChange={(e) => handleChange(e)}>
                            <option value="">Select</option>
                            {RetiredIssuesOptions.map((item, i) => {
                                return <option key={i} value={item.value}>{item.label}</option>
                            })
                            }
                        </Form.Select>
                        {formErrors['retireFlag'] && <p className="error">{formErrors['retireFlag']}</p>}
                    </Col>
                </Row>

                {showMaturedFields &&
                    <Row>
                        <Col md={6}>
                            <div className="control">
                                <label><span className="required">*</span>Final Maturity Date:</label>
                                <div>
                                    <span>
                                    <DatePicker 
                                    selected={stateValues && stateValues.finalMaturityDate ? stateValues.finalMaturityDate : null}
                                    dateFormat="M/d/yyyy"
                                    name="finalMaturityDate" 
                                    maxDate= {new Date(9999, 12, 31)}
                                    onChange={(e) => handleChangeDate(e, "finalMaturityDate")} 
                                    error={formErrors && formErrors['finalMaturityDate']}                                
                                    />
                                    <FaCalendarAlt className="date-picker-icon"/>
                                    </span>
                                    <input type="hidden" data-validations={["required"]} value={stateValues.finalMaturityDate} name="finalMaturityDate" />
                                    {formErrors['finalMaturityDate'] && <p className="error">{formErrors['finalMaturityDate']}</p>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                }

                {showRedeemedFields && <>
                    <Row>
                        <Col>
                            <label>CDIAC #</label>
                            <Form.Control type="text" value={issuanceDetail.redeemCdiacNumber} readOnly />
                        </Col>
                        <Col>
                            <label>Issue Name</label>
                            <Form.Control type="text" value={issuanceDetail.stateRefundingBondTitle} disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <label><span className="required">*</span>Is the CDIAC mentioned correct?</label>
                            <Form.Check inline type="radio" name="correctCdiacNbrInd" id="Y" label="Yes" value="Y" checked={stateValues.correctCdiacNbrInd === "Y"} onChange={(e) => checkIfcdiacCorrect(e)} data-validations="required" />
                            <Form.Check inline type="radio" name="correctCdiacNbrInd" id="N" label="No" value="N" checked={stateValues.correctCdiacNbrInd === "N"} onChange={(e) => checkIfcdiacCorrect(e)} data-validations="required" />
                            {formErrors['correctCdiacNbrInd'] && <p className="error">{formErrors['correctCdiacNbrInd']}</p>}
                        </Col>

                        {(showNewCdiacField || stateValues.correctCdiacNbrInd === "N") && <Col>
                            <label><span className="required">*</span>Corrected CDIAC #</label>
                            <Typeahead
                                    placeholder="Choose CDIAC Number"
                                    id="cdiacNbr"
                                    labelKey="cdiacNbr"
                                    onChange={(e)=>handleCdiacNbrChange(e, 'correctedRedeemCdiacNbr')}
                                    options={cdiacNumbersList}
                                    selected={
                                        cdiacNumbersList.filter(option => 
                                            option.cdiacNbr === stateValues.correctedRedeemCdiacNbr)
                                    }
                                    inputProps={{ 'data-validations': (stateValues.correctCdiacNbrInd === "N" ? 'required' : ''), name: 'correctedCdiacNbr' }}
                                    isLoading={cdiacNbrsLoading}
                            />
                            {formErrors['correctedCdiacNbr'] && <span className="error">{formErrors['correctedCdiacNbr']}</span>}
                        </Col>}
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="control">
                                <label><span className="required">*</span>Redemption Date</label>
                                <div>
                                    <span>
                                    <DatePicker 
                                    selected={stateValues && stateValues.retireDate ? stateValues.retireDate : null}
                                    dateFormat="M/d/yyyy"
                                    name="retireDate"
                                    maxDate= {new Date(9999, 12, 31)}
                                    onChange={(e) => handleChangeDate(e, "retireDate")} 
                                    error={formErrors && formErrors['retireDate']} 
                                    />
                                    <FaRegCalendarAlt className="date-picker-icon"/>
                                    </span>
                                    <input type="hidden" data-validations={["required"]} value={CommonService.dateFormatter(stateValues.retireDate)} name="retireDate" />
                                    {formErrors['retireDate'] && <p className="error">{formErrors['retireDate']}</p>}
                                </div>
                            </div>
                        </Col>
                    </Row></>
                }

                {showOtherFields && <Row>
                    <Col md={6}>
                        <label><span className="required">*</span>Other</label>
                        <Form.Control type="text" name="otherRetiredText" data-validations="required" value={stateValues.otherRetiredText} onChange={(e) => handleOtherTextChange(e)} />
                        {formErrors['otherRetiredText'] && <p className="error">{formErrors['otherRetiredText']}</p>}
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <label><span className="required">*</span>Date</label>
                            <div>
                                <span>
                                <DatePicker 
                                selected={stateValues && stateValues.otherDate ? stateValues.otherDate : null}
                                dateFormat="M/d/yyyy"
                                name="otherDate"
                                maxDate= {new Date(9999, 12, 31)}
                                onChange={(e) => handleChangeDate(e, "otherDate")} 
                                error={formErrors && formErrors['otherDate']}                                
                                />
                                <FaCalendarAlt className="date-picker-icon"/>
                                </span>
                                <input type="hidden" data-validations={["required"]} value={stateValues.otherDate} name="otherDate" />
                                {formErrors['otherDate'] && <p className="error">{formErrors['otherDate']}</p>}
                            </div>
                        </div>

                        {/* {formErrors['retirementReason'] && <p className="error">{formErrors['retirementReason']}</p>} */}
                    </Col></Row>
                }

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('foreclosure')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={e => validateForm(e)}>Save & Next</button>
                </div>
            </form>
        </Card>
    )
}

export default RetiredIssues
