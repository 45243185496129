import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import Card from '../ui/Card'

const FilingContact = (props) => {
    const { contact} = props;
    const validateForm = () => {
        props.openWidget('comments');
    }

    return (
        <Card>
            <div className="form">
                <h3>Filing Contact</h3>
                <Row>
                    <Col>
                        <label>Filing Contact Name</label>
                        <Form.Control type="text" value={contact.contactFirstName + ' ' + contact.contactLastName} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Firm/Agency</label>
                        <Form.Control type="text" value={contact.issuerName} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <label>Address Line 1</label>
                        <Form.Control type="text" value={contact.contactAddressLine1} disabled />
                    </Col>
                    <Col md={6}>
                        <label>Address Line 2</label>
                        <Form.Control type="text" value={contact.contactAddressLine2} disabled />
                    </Col>
                    <Col md={6}>
                        <label>Address Line 3</label>
                        <Form.Control type="text" value={contact.contactAddressLine3} disabled />
                    </Col>
                    <Col md={6}>
                        <label>Address Line 4</label>
                        <Form.Control type="text" value={contact.contactAddressLine4} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>City</label>
                        <Form.Control type="text" value={contact.contactCityName} disabled />
                    </Col>
                    <Col>
                        <label>State</label>
                        <Form.Control type="text" value={contact.contactStateCode} disabled />
                    </Col>
                    <Col>
                        <label>Zip Code</label>
                        <Form.Control type="text" value={contact.contactZipCode} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Area Code</label>
                        <Form.Control type="text" value={contact.contactPhoneAreaCode} disabled />
                    </Col>
                    <Col>
                        <label>Telephone</label>
                        <Form.Control type="text" value={contact.contactPhoneNumber} disabled />
                    </Col>
                    <Col>
                        <label>Ext</label>
                        <Form.Control type="text" value={contact.contactPhoneExtn} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <label>Fax Area Code</label>
                        <Form.Control type="text" value={contact.contactFaxAreaCode} disabled />
                    </Col>
                    <Col md={4}>
                        <label>Fax Number</label>
                        <Form.Control type="text" value={contact.contactFaxNumber} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>E-mail Address</label>
                        <Form.Control type="text" value={contact.contactEmailId} disabled />
                    </Col>
                </Row>

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('retiredIssues')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={() => validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default FilingContact
