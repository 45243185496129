import { useEffect, useState } from 'react'
import Card from '../../ui/Card'
import {Form, Spinner} from 'react-bootstrap'
import AddPurposeOfFinanceModal from './addPurposeOfFinanceModal'
import Table from '../../Table'
import IssueFinancePurposeService from '../../../services/IssueFinancePurposeService'
import swal from 'sweetalert'
import { toast } from 'react-toastify'

const PurposeOfFinancing = (props) => {
    const {finalSaleDetails} = props;
    const [showAddPurposeOfFinanceModal, setShowAddPurposeOfFinanceModal] = useState(false);
    const initialIssuePurposes= JSON.parse(sessionStorage.getItem('purposes')) ? JSON.parse(sessionStorage.getItem('purposes')) : [];
    const [purposes, setPurposes] = useState(initialIssuePurposes)
    const [editIdx, setEditIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPercentage, setTotalPercentage] = useState(0);


    useEffect(()=> {
        calculateTotal(purposes);
    }, [purposes]);

    useEffect(() => {
        getIssueFinancingPurposesById()
        // eslint-disable-next-line
    },[finalSaleDetails.finalSaleId]);

    const calculateTotal = (purposes) => {
        const total = purposes.reduce((total, item) => total + parseFloat(item.financePurposePct !== "" ? item.financePurposePct : 0), 0);
        setTotalPercentage(total.toFixed(2));
    }

    const getIssueFinancingPurposesById = async () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssueFinancePurposesByFinalSaleId",
            hdrStruct: null,
            queryParam: null,
            reqData: {id : finalSaleDetails.finalSaleId },
          };
      
          await IssueFinancePurposeService.getFinancePurposesByIssueId(obj)
            .then((res) => res)
            .then((result) => {    
                setPurposes(result.data.responses[0]);
                props.updateFinalSaleDependencies(result.data.responses[0], 'issueFinancingPurposes');
                setIsLoading(false);
            });
    }
    const updateFinancePurpose = (purposeData)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueFinancePurpose",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": purposeData.financePurposeId,
                "issueFinancePurpose": purposeData
            }
        };

        IssueFinancePurposeService.updateFinancePurpose(obj)
        .then((res) => res)
        .then((result) => {
            getIssueFinancingPurposesById();
        });
    }

    const deleteFinancePurpose = (issueFinancePurposeId)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "deleteIssueFinancePurpose",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
              "id" : issueFinancePurposeId,
            }
        };
  
        IssueFinancePurposeService.deleteFinancePurpose(obj)
        .then((res) => res)
        .then((result) => {
            toast.success(
              'Finance Purpose Deleted Successfully'
            );
            getIssueFinancingPurposesById();
        });
    }

    const startEditing = i => {
        setEditIndex(i);
    };
    
    const stopEditing = () => {
        setEditIndex(-1);
    };
    const handleOnSave = (i, rowData) => {
        updateFinancePurpose(rowData);
        setEditIndex(-1);
    }
    const handleRemove = (i, rowData) => {
        const financePurposeId = rowData.financePurposeId;
        deleteFinancePurpose(financePurposeId);
    }
    const handleChange = (e, name, i) => {
        const { value } = e.target;
        const purposesnew =[...purposes];
        if( name === 'bondTypeDesc') {
            purposesnew[i]['bondType'] = value;
            const displayVal = e.target[e.target.selectedIndex].text;
            purposesnew[i]['bondTypeDesc'] = displayVal;
        } else {
            purposesnew[i][name] = value;
        }

        setPurposes(purposesnew);
        sessionStorage.setItem('purposes',JSON.stringify(purposesnew) )
    }

    const handleModalClose = () => {
        setShowAddPurposeOfFinanceModal(false);
        getIssueFinancingPurposesById();
    }

    const validateForm = (e) => {
        if(purposes.length !== 0) {
            if(totalPercentage !== "100.00") {
                swal("The total value of the Financing Purpose percentages must be equal to 100.00%");
            } else {
                toast.success("Purpose of Financing updated successfully!");
                props.sectionValidated('purposeOfFinancing', true);
                props.openWidget('typeOfSale');
            }
        } else {
            props.sectionValidated('purposeOfFinancing', false);
            alert("Please select at least one Purpose of Financing.");
        }
    }

    return (
        <Card>
            <Form className="form" id="purposeOfFinancingForm">
                <button type="button" className="custom-button" onClick={() => setShowAddPurposeOfFinanceModal(true)}>Add Purpose</button>
                {isLoading && <Spinner animation="border" variant="primary" />}
                {Object.entries(purposes).length !== 0 && <>
                    <Table 
                    handleRemove={handleRemove}
                    startEditing={startEditing}
                    editIdx={editIdx}
                    stopEditing={stopEditing}
                    handleChange={handleChange}
                    handleSave={handleOnSave}
                    data={purposes}
                    header={[
                      {
                        name: "Purpose of Financing",
                        prop: "financePurposeName",
                        fieldType: "input",
                        editable: false
                      },
                      {
                        name: "Percentage",
                        prop: "financePurposePct",
                        fieldType: "input",
                        editable: true
                      }
                    ]}
                    />
                
                <div style={{overflow: 'auto'}}><div style={{float: 'right', display: 'flex'}}><label>Total: </label><input value={totalPercentage} disabled/></div></div>
                </>
                }  
            
          
            {showAddPurposeOfFinanceModal && <AddPurposeOfFinanceModal onModalClose={handleModalClose} finalSaleId={finalSaleDetails.finalSaleId}/>}
            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="fright custom-button" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </Form>
        </Card>
    )
}

export default PurposeOfFinancing
