import {useState, useEffect} from 'react';
import Card from '../../ui/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditElectionForm from './editElectionForm';
import AddElectionForm from './addElectionForm';
import CommonService from "../../../services/CommonService";
import BaseService from '../../../services/BaseService';
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const StateElection = () => {
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editElectionInfo, setEditElectionInfo] = useState([])
    const [openAddModal, setOpenAddElectionModal] = useState(false);
    const [electionData, setElectionData] = useState([]);

    const viewFormatter = (row) => { 
        return ( 
              <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
              <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/>
         </div> 
        ); 
    }

    const getStateElections = () => {
      CommonService.showHideSpinner(true);
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getStateElections",
        hdrStruct: null,
        queryParam: null,
        reqData: {},
      };

      BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
          Object.keys(result.data.responses).forEach((key) => {
            setElectionData(result.data.responses[key]);
          });
          CommonService.showHideSpinner(false);
        });
    }

    useEffect(() => {
      getStateElections();
    }, []);

    const onRowClick = (e, row) => {
        setOpenEditModal(true);
        setEditElectionInfo(row);
    }

    const handleAddModalClose = () => {
      setOpenAddElectionModal(false);
      getStateElections();
    }
    const handleModalClose = () => {
      setOpenEditModal(false);
      getStateElections();
    }
    return (
        <Card>
          <div>
            <div className="panel panel-default" id="stepspanel" style={{marginBottom: '0px'}}>

              <div className="panel-heading">
                  <div className="container">
                      <div id="packet_id" className="panel-title">
                          <h1 className="menu-title">State Election Data</h1>
                      </div>
                  </div>
              </div>

              <div className="panel-body">
                <div className="form">
                  <DataGrid
                  dataSource={electionData}
                  sortField='electionYear'
                  addNew={true}
                  addNewBtnText="Add New State Election"
                  onAddNew={()=>setOpenAddElectionModal(true)}
                  >
                    <Column field='issuerName' header='Issuer Name' sortable/>
                    <Column field='electionType' header='Election Type' sortable/>
                    <Column field='electionYear' header='Election Year' sortable/>
                    <Column field='electionDate' header='Election Date' sortable body={(row)=>CommonService.dateFormatter(row['electionDate'])} />
                    <Column field='createDatetime' header='Date Created' sortable body={(row)=>CommonService.dateFormatter(row['createDatetime'])} />
                    <Column field='createUser' header='Created By' sortable />
                    <Column field='' header='' body={viewFormatter}/>
                  </DataGrid>
                </div>
              </div>

            </div>
          </div>
            
          {openAddModal && <AddElectionForm onModalClose={()=>handleAddModalClose()}/>}
          {openEditModal && <EditElectionForm onModalClose={()=>handleModalClose()} electionInfo={editElectionInfo} />}
        </Card>
    )
}

export default StateElection
