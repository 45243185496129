import {Form, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CodeMaintenanceService from "../../services/CodeMaintenanceService";
import React from "react";
import { useForm } from "react-hook-form";
import {useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify';
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import CommonService from "../../services/CommonService";
import TooltipComponent from '../../common/Tooltip';

const AddCodeForm = (props) => {
    const {onModalClose} = props;
    const {register, handleSubmit, formState: { errors }} = useForm();
    const [errorMessage, setErrorMessage] = useState();
    const user = useContext(UserContext);

    const onSubmit = (data) => {
        
        var obj = {
            msgId: null,
            sysId: null,
            opr: "postCdiacCodeValueRef",
            hdrStruct: null,
            queryParam: null,
            reqData: { "cdiacCodeValueRef":{
                "cdiacCodeValueCode": data.cdiacCodeValueCode,
                "cdiacCodeValueDesc": data.cdiacCodeValueDesc,
                "cdiacCode": props.codeInfo,
                "cdiacCodeValueShortDesc": data.cdiacCodeValueShortDesc,
                "createUser": CommonService.getUserName(user),
                "lastUpdateUser": CommonService.getUserName(user),
                "activeInd": data.activeInd === true ? 'Y' : 'N'
            }
            },
          };

     
          CodeMaintenanceService.addCode(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("Code added successfully!");
                    onModalClose();
                }
                
            });
      }
    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Add New Code</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
                <div className="control">
                    <label><span className="required">* </span>Code: </label>
                    <input maxLength="6" {...register('cdiacCodeValueCode', { required: true })}/>
                    {errors.cdiacCodeValueCode && <p style={{ color: 'red' }}>Code is required</p>}
                </div>
                <div className="control">
                    <label><span className="required">* </span>Description: </label>
                    <textarea maxLength="500" {...register('cdiacCodeValueDesc', { required: true })}/>
                    {errors.cdiacCodeValueDesc && <p style={{ color: 'red' }}>Description is required</p>}
                </div>
                <div className="control">
                    <label>Brief Description: </label>
                    <textarea maxLength="100" {...register('cdiacCodeValueShortDesc')}/>
                </div>
                <div style={{display: 'flex'}}>
                    <Form.Check type="checkbox" label="Active?" {...register('activeInd')} defaultChecked/>
                    <TooltipComponent
                        title="Only active values are displayed in the User Interface"
                        id="refunding-amt"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                    </TooltipComponent>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                <button className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Save</button>
            </Modal.Footer>
        </Modal>
        </form>
    )
}

export default AddCodeForm

