import {useState, useEffect, useCallback, useContext} from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import Card from '../../../components/ui/Card'
import { validateFormFields } from '../../../utils/index'
import CurrencyInput from '../../formElements/CurrencyInput'
import Select from 'react-select'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import { Typeahead } from 'react-bootstrap-typeahead'
import IssueCdiacNumberService from '../../../services/IssueCdiacNumberService'
import { toast } from 'react-toastify'
import { UserContext } from '../../../UserContext'

const federalTaxOptions = [
    { value: "T", label : "Taxable"},
    { value: "E", label : "Tax Exempt, No AMT"},
    { value: "A", label : "Tax Exempt, with AMT"}
]
const InterestDeptExempt = (props) => {
    const loginUser = useContext(UserContext);
    const {issueId, proposedDebtDetails} = props;
    const [showStateTaxAmount, setShowStateTaxAmount] = useState(false);
    const [showStateTaxExemptAmount, setShowStateTaxExemptAmount] = useState(false);
    const [showStateTaxTotalAmount, setShowStateTaxTotalAmount] = useState(false);
    const [stateTaxTotal, setStateTaxTotal] = useState("0.00");
    const [showFederalTaxAmount, setShowFederalTaxAmount] = useState(false);
    const [showFederalTaxExemptAmount, setShowFederalTaxExemptAmount] = useState(false);
    const [showAltMinTaxAmount, setShowAltMinTaxAmount] = useState(false);
    const [showFederalTaxTotalAmount, setShowFederalTaxTotalAmount] = useState(false);
    const [federalTaxTotal, setFederalTaxTotal] = useState("0.00");
    const [formErrors,setFormErrors] = useState({});
    const [proposedDebtInfo, setProposedDebtInfo] = useState(proposedDebtDetails);
    const [defaultFederalTaxSelection, setDefaultFederalTaxSelection] = useState([{}]);
    const [cdiacNumbersList, setCdiacNumbersList] = useState([]);

    const [issueSeriesCdiacNbrs, setIssueSeriesCdiacNbrs] = useState([
        {seriesCdiacNbr: ''}
    ]);

    const getCdiacNumbers = useCallback (() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getCdiacNumbers",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {}
        };
       
        IssueCdiacNumberService.getCdiacNumbers(obj)
          .then((res) => res)
          .then((result) => {
            setCdiacNumbersList(result.data.responses[0]);
          });
    },[]);

    useEffect(()=>{
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueSeriesByIssueId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "proposeDebtId": issueId
            }
        };
       
        ReportOfProposedDebtService.getIssueSeriesByIssueId(obj)
          .then((res) => res)
          .then((result) => {
              if(result.data.responses[0].length > 0) {
                setIssueSeriesCdiacNbrs(result.data.responses[0]);
              } else {
                setIssueSeriesCdiacNbrs([
                    {seriesCdiacNbr: ''}
                ]);
              }
          });
        
        getCdiacNumbers();
    }, [getCdiacNumbers, issueId])
    useEffect(() => {
        proposedDebtInfo.federalTaxFlag && calculateFederalTaxTotal(proposedDebtInfo);
       
        const federalTaxSelectionArr = proposedDebtInfo.federalTaxFlag ? proposedDebtInfo.federalTaxFlag.split("") : [];

        (federalTaxSelectionArr.length > 1) ? setShowFederalTaxTotalAmount(true) : setShowFederalTaxTotalAmount(false);
        
        let defaultFederalTaxSelectionArr = [];
        federalTaxSelectionArr.forEach(val => {
            if( val === 'T') {
                setShowFederalTaxAmount(true);
            }
            if( val === 'E') {
                setShowFederalTaxExemptAmount(true);
            }
            if( val === 'A') {
                setShowAltMinTaxAmount(true);
            }
            defaultFederalTaxSelectionArr.push(federalTaxOptions.find(item=> item.value === val))
        });

        setDefaultFederalTaxSelection(defaultFederalTaxSelectionArr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ proposedDebtInfo.federalTaxFlag]);

    useEffect(()=> {
        proposedDebtInfo.stateTaxFlag && calculateStateTaxTotal(proposedDebtInfo);

        switch (proposedDebtInfo.stateTaxFlag) {
            case "Y" :
                setShowStateTaxAmount(true);
                setShowStateTaxExemptAmount(false);
                setShowStateTaxTotalAmount(false);
                setProposedDebtInfo(prevState=>({
                    ...prevState, 'stateTaxExemptAmt': ''
                }))
                break;
            case "N" :
                setShowStateTaxAmount(false);
                setShowStateTaxExemptAmount(true);
                setShowStateTaxTotalAmount(false);
                setProposedDebtInfo(prevState=>({
                    ...prevState, 'stateTaxPrincipalAmt': ''
                }))
                break;
            case "B":
                setShowStateTaxAmount(true);
                setShowStateTaxExemptAmount(true);
                setShowStateTaxTotalAmount(true);
                break;
            default :
                setShowStateTaxAmount(false);
                setShowStateTaxExemptAmount(false);
                setShowStateTaxTotalAmount(false);
                break;

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[proposedDebtInfo.stateTaxFlag]);

    useEffect(()=> {
        calculateStateTaxTotal(proposedDebtInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[proposedDebtInfo.stateTaxPrincipalAmt, proposedDebtInfo.stateTaxExemptAmt]);

    useEffect(()=> {
        calculateFederalTaxTotal(proposedDebtInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[proposedDebtInfo.federalTaxPrincipalAmt, proposedDebtInfo.federalTaxExemptAmt, proposedDebtInfo.altMinTaxAmt]);

    const saveInterestTaxExempt = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putProposeDebt",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issueId,
                "proposeDebt": {...proposedDebtInfo}
            }
        };
    
        ReportOfProposedDebtService.updateProposedDebt(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                toast.success("Tax Status/Interest updated successfully");
                props.updateProposedDebtDetails(result.data.responses[0]);
                props.sectionValidated('interestDebtExempt', true);
                props.openWidget('typeOfDebt');
            }
        });
    }
    const validateForm = (e) => {
        let form = e.target.closest('form#add-form');

        let formErrors = validateFormFields(form.elements);

        let proposedDebtInfoArr = {...proposedDebtInfo};
        if( !proposedDebtInfoArr['federalTaxFlag'] || proposedDebtInfoArr['federalTaxFlag'] === "" ) {
            formErrors['federalTaxFlag'] = "This field is required."
        }
        if(stateTaxTotal !== proposedDebtInfoArr.principalSaleAmt) {
            formErrors['stateTaxTotal'] = "State tax total amount should be equal to the principal amount.";
        }
        if(federalTaxTotal !== proposedDebtInfoArr.principalSaleAmt) {
            formErrors['federalTaxTotal'] = "Federal tax total amount should be equal to the principal amount.";
        }
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            saveInterestTaxExempt();
        }
    }

    const handleUnderStateLawChange = (e) => {
        const {name,value} = e.target;
        setProposedDebtInfo(prevState=>({
            ...prevState, [name]: value
        }))
    }
    const handleUnderFederalLawChange = (e) => {
        setShowFederalTaxAmount(false);
        setShowFederalTaxExemptAmount(false);
        setShowAltMinTaxAmount(false);

        let selectionArr = [];
        e.map(x=>{
           return selectionArr.push(x.value);
        });
        
        var one = ['T', 'E', 'A'];
        var resultArr = one.filter(function(val){
            return !selectionArr.find(function(obj){
                return val===obj;
            });
        });

        let fedExemptAmt = proposedDebtInfo.federalTaxExemptAmt;
        let fedPrincipalAmt = proposedDebtInfo.federalTaxPrincipalAmt;
        let altMinAmt = proposedDebtInfo.altMinTaxAmt;
        resultArr.forEach(k => {
            if(k === 'E') {
                fedExemptAmt = "";
            } else if(k === 'T') {
                fedPrincipalAmt = "";
            } else if(k === 'A'){
                altMinAmt = "";
            }
        })


        setProposedDebtInfo(prevState=>({
            ...prevState, 
            federalTaxFlag: selectionArr.join(''),
            federalTaxExemptAmt: fedExemptAmt,
            federalTaxPrincipalAmt: fedPrincipalAmt,
            altMinTaxAmt: altMinAmt
        }))
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        const taxableInfo = {...proposedDebtInfo};
        taxableInfo[name] = value;
        setProposedDebtInfo(taxableInfo);
    }

    const calculateStateTaxTotal = (proposedDebtInfo) => {
       const stateTaxPriAmt = ( proposedDebtInfo['stateTaxPrincipalAmt'] && proposedDebtInfo['stateTaxPrincipalAmt'] !== "" ) ?
           typeof proposedDebtInfo['stateTaxPrincipalAmt'] === 'string' ? proposedDebtInfo['stateTaxPrincipalAmt'].replace(/,/g,'') : proposedDebtInfo['stateTaxPrincipalAmt']
        : 0;

        const stateTaxExptAmt = ( proposedDebtInfo['stateTaxExemptAmt'] && proposedDebtInfo['stateTaxExemptAmt'] !== "" ) ?
           typeof proposedDebtInfo['stateTaxExemptAmt'] === 'string' ? proposedDebtInfo['stateTaxExemptAmt'].replace(/,/g,'') : proposedDebtInfo['stateTaxExemptAmt']
        : 0;

        const stateTaxtotal = parseFloat(stateTaxPriAmt) + Number(stateTaxExptAmt);
        setStateTaxTotal(stateTaxtotal);
    }
    const calculateFederalTaxTotal = (proposedDebtInfo) => {
        const federalTaxPriAmt = ( proposedDebtInfo['federalTaxPrincipalAmt'] && proposedDebtInfo['federalTaxPrincipalAmt'] !== "" ) ?
        typeof proposedDebtInfo['federalTaxPrincipalAmt'] === 'string' ? proposedDebtInfo['federalTaxPrincipalAmt'].replace(/,/g,'') : proposedDebtInfo['federalTaxPrincipalAmt']
        : 0;

        const federalTaxExptAmt = ( proposedDebtInfo['federalTaxExemptAmt'] && proposedDebtInfo['federalTaxExemptAmt'] !== "" ) ?
        typeof proposedDebtInfo['federalTaxExemptAmt'] === 'string' ? proposedDebtInfo['federalTaxExemptAmt'].replace(/,/g,'') : proposedDebtInfo['federalTaxExemptAmt']
        : 0;

        const federalAltMinAmt = ( proposedDebtInfo['altMinTaxAmt'] && proposedDebtInfo['altMinTaxAmt'] !== "" ) ?
        typeof proposedDebtInfo['altMinTaxAmt'] === 'string' ? proposedDebtInfo['altMinTaxAmt'].replace(/,/g,'') : proposedDebtInfo['altMinTaxAmt']
        : 0;

        const federalTaxtotal = parseFloat(federalTaxPriAmt) + Number(federalTaxExptAmt) + Number(federalAltMinAmt);
        setFederalTaxTotal(federalTaxtotal);
    }

    const handleCdiacNbrChange = (e, i) => {
        const selectedValue = e.length ? e[0].cdiacNbr : "";
        let cidacNumArr = [...issueSeriesCdiacNbrs];
        cidacNumArr[i].seriesCdiacNbr = selectedValue;
        setIssueSeriesCdiacNbrs(cidacNumArr);
    }

    const saveIssueSeriesCdiac = async (i) => {
        let cidacNumArr = [...issueSeriesCdiacNbrs];
        const cdiacNbrObj = cidacNumArr[i];
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "postIssueSeries",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueSeries" : {
                    'seriesCdiacNbr': cdiacNbrObj.seriesCdiacNbr,
                    'cdiacNbr' : proposedDebtDetails.cdiacNbr === "Unassigned" ? "" : proposedDebtDetails.cdiacNbr,
                    'proposeDebtId': issueId,
                    'createUser': loginUser
                }
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast(result.data.errors[0].message)
            }
            else{
                const issueSeriesId = result.data.responses[0];
                cdiacNbrObj['issueSeriesId'] = issueSeriesId;
                setIssueSeriesCdiacNbrs(cidacNumArr);
                toast.success("CDIAC # saved successfully!");
            }
        });
    }

    const deleteIssueSeriesCdiac = async (i) => {
        let cidacNumArr = [...issueSeriesCdiacNbrs];
        const IssueSeriesId =  cidacNumArr[i].issueSeriesId;
        if ( IssueSeriesId ) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "deleteIssueSeries",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": IssueSeriesId
                }
            };
        
            await IssueCdiacNumberService.deleteIssueSeries(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast(result.data.errors[0].message)
                }
                else{
                    cidacNumArr.splice(i,1);
                    setIssueSeriesCdiacNbrs(cidacNumArr);
                    toast.success("CDIAC # deleted successfully!");
                }
            });
        } else {
            cidacNumArr.splice(i,1);
            setIssueSeriesCdiacNbrs(cidacNumArr);
        }
    }

    const addCdiacNumber = () => {
        setIssueSeriesCdiacNbrs(prevState=>([
            ...issueSeriesCdiacNbrs, {seriesCdiacNbr: ''}
        ]))
    }

    return (
        <Card>
        <Form className="form" id="add-form">
            <h3>Is The Interest on any portion of the Debt Taxable?</h3>
           
            <Row>
            <Col md={4}><label><span className="required">*</span>Under State Law:</label></Col>
            <Col md={4}>
                <Form.Select name="stateTaxFlag" onChange={e=>handleUnderStateLawChange(e)} data-validations="required" defaultValue={proposedDebtInfo['stateTaxFlag']}>
                <option value="">Select all that apply</option>
                <option value="Y">Taxable</option>
                <option value="N">Tax-Exempt</option>
                <option value="B">Both</option>
                </Form.Select>
                {formErrors['stateTaxFlag'] && <p className="error">{formErrors['stateTaxFlag']}</p>}
            </Col>
            {formErrors['stateTaxTotal'] && <Col><p className="error">{formErrors['stateTaxTotal']}</p></Col>}
            </Row>

            <div id="stateTax">
                <Row>
                    {showStateTaxAmount && <Col md={6}>
                    <div className="control">
                        <span className="required">*</span>
                        <CurrencyInput
                        label="State Tax Principal Amount" 
                        name="stateTaxPrincipalAmt" 
                        value={proposedDebtInfo.stateTaxPrincipalAmt}
                        onChange={(e)=>handleChange(e)}
                        validations={["required"]}
                        style={formErrors['stateTaxPrincipalAmt'] && {border: 'solid 1px red'}}
                        error={formErrors && formErrors['stateTaxPrincipalAmt']}/>
                    </div></Col>
                    }
                    {showStateTaxExemptAmount && <Col md={6}>
                        <div className="control">
                            <span className="required">*</span>
                            <CurrencyInput
                            label="State Tax Exempt Amount" 
                            name="stateTaxExemptAmt" 
                            value={proposedDebtInfo.stateTaxExemptAmt}
                            onChange={(e)=>handleChange(e)}
                            validations={["required"]}
                            style={formErrors['stateTaxExemptAmt'] && {border: 'solid 1px red'}}
                            error={formErrors && formErrors['stateTaxExemptAmt']}/>
                        </div></Col>
                    }
                </Row>
                <Row>
                    {showStateTaxTotalAmount && <Col md={6}>
                        <div className="control">
                            <span className="required">*</span>
                            <CurrencyInput
                            label="State Tax Total Amount" 
                            id="stateTotal"
                            name="stateTaxTotalAmount" 
                            readOnly
                            value={stateTaxTotal}
                        />
                        </div></Col>
                    }
                </Row>
            </div>
            
            <Row>
            <Col md={4}><label><span className="required">*</span>Under Federal Law (Multiple-Select):</label></Col>
            <Col md={4}>
                <Select 
                value={defaultFederalTaxSelection}
                placeholder="Select all that apply"
                options={federalTaxOptions}
                isMulti
                onChange={e=>handleUnderFederalLawChange(e)}
                />
                {formErrors['federalTaxFlag'] && <p className="error">{formErrors['federalTaxFlag']}</p>}
            </Col>
            {formErrors['federalTaxTotal'] && <Col><p className="error">{formErrors['federalTaxTotal']}</p></Col>}

            </Row>

            <div id="federalTax">
                <Row>{showFederalTaxAmount && <Col md={6}>
                    <div className="control">
                        <span className="required">*</span>
                        <CurrencyInput
                        label="Federal Tax Principal Amount" 
                        name="federalTaxPrincipalAmt" 
                        value={proposedDebtInfo.federalTaxPrincipalAmt}
                        onChange={(e)=>handleChange(e)}
                        validations={["required"]}
                        style={formErrors['federalTaxPrincipalAmt'] && {border: 'solid 1px red'}}
                        error={formErrors && formErrors['federalTaxPrincipalAmt']}/>
                    </div></Col>
                }
                {showFederalTaxExemptAmount && <Col md={6}>
                    <div className="control">
                        <span className="required">*</span>
                        <CurrencyInput
                        label="Federal Tax Exempt Amount (No AMT)" 
                        name="federalTaxExemptAmt" 
                        value={proposedDebtInfo.federalTaxExemptAmt}
                        onChange={(e)=>handleChange(e)}
                        validations={["required"]}
                        style={formErrors['federalTaxExemptAmt'] && {border: 'solid 1px red'}}
                        error={formErrors && formErrors['federalTaxExemptAmt']}/>
                    </div></Col>
                }
                {showAltMinTaxAmount && <Col md={6}>
                    <div className="control">
                        <span className="required">*</span>
                        <CurrencyInput
                        label="Alternate Minimum Tax Amount" 
                        name="altMinTaxAmt" 
                        value={proposedDebtInfo.altMinTaxAmt}
                        onChange={(e)=>handleChange(e)}
                        validations={["required"]}
                        style={formErrors['altMinTaxAmt'] && {border: 'solid 1px red'}}
                        error={formErrors && formErrors['altMinTaxAmt']}/>
                    </div></Col>
                }
                </Row>
            </div>

            <Row>
            {showFederalTaxTotalAmount && <Col md={6}>
                <div className="control">
                    <span className="required">*</span>
                    <CurrencyInput
                    label="Federal Tax Total Amount" 
                    name="federalTaxTotalAmount" 
                    readOnly
                    value={federalTaxTotal}
                    />
                </div></Col>
            }
            </Row>

            <Row>
               <label>To link "Split" issues together, please select CDIAC # based on tax status:</label>
                <Col md={12} >
                    <table>
                        <thead>
                            <tr>
                                <td>CDIAC #</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                        {issueSeriesCdiacNbrs && issueSeriesCdiacNbrs.map((item, i) => {
                            return <tr key={i}>
                                <td className="col-md-6" style={{paddingLeft: '0'}}>
                                    <Typeahead
                                            placeholder="Choose CDIAC Number"
                                            id="cdiacNbr"
                                            labelKey="cdiacNbr"
                                            onChange={e=>handleCdiacNbrChange(e, i)}
                                            options={cdiacNumbersList}
                                            selected={cdiacNumbersList.filter(option => 
                                                option.cdiacNbr === item.seriesCdiacNbr)}
                                    />
                                </td>
                                <td className="tblCol col-md-6"> 
                                   {!item.issueSeriesId && <button type="button" className="custom-button" disabled = { !item.seriesCdiacNbr && true} onClick={()=>saveIssueSeriesCdiac(i)}>Save</button>}{" "}                          
                                    <button type="button" className="custom-button" onClick={()=>deleteIssueSeriesCdiac(i)}>Delete</button>                               
                                </td>
                            </tr>
                        }) }
                        </tbody>
                    </table>
                    
                </Col>  
            </Row>
            <br />
            <button type="button" className="custom-button" onClick={()=>addCdiacNumber()}>Add CDIAC #</button>

            <div className="btn-div">
            {/* <button className="custom-button">Back</button> */}
            <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
            </div>
        </Form> 
        </Card>
    )
}

export default InterestDeptExempt
