import { Modal, Row, Col, Form} from 'react-bootstrap'
import FeeScheduleMaintenanceService from '../../../services/FeeScheduleMaintenanceService'
import React, {useState, useContext} from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify'
import { UserContext } from '../../../UserContext'
import CommonService from '../../../services/CommonService'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CurrencyInput from '../../formElements/CurrencyInput'
import { FormInput } from '../../formElements/FormInput'
import { validateFormFields } from '../../../utils/index'

const AddFeeForm = (props) => {
    const user = useContext(UserContext);
    const {onModalClose} = props;
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [stateValues, setStateValues] = useState({
        effectiveStartDate: null,
        effectiveEndDate: null,
        principalMinAmt: '',
        principalMaxAmt: '',
        maxFeeAmt: '',
        maxFeeDesc: '',
        basisPointDesc: '',
        basisPointRate: ''
    })

    const handleDateChange = (e, key) => {
        setStateValues(prevState=>({
            ...prevState, 
            [key]: e
        })
        )
    }
    
    const handleChange = (e) => {
        const {name, value} = e.target;
        setStateValues(prevState =>({
            ...prevState, [name] : value
        }));
    }
    const handleSubmit = (e) => {
        let form = e.target.closest('form#addFeeForm');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "postFeeScheduleRef",
                hdrStruct: null,
                queryParam: null,
                reqData: { "feeScheduleRef":{
                    "effectiveStartDate": stateValues.effectiveStartDate,
                    "effectiveEndDate": stateValues.effectiveEndDate,
                    "principalMinAmt": typeof(stateValues.principalMinAmt) === 'string' ? parseFloat(stateValues.principalMinAmt.replace(/\$|,/g, '')) : stateValues.principalMinAmt,
                    "principalMaxAmt": typeof(stateValues.principalMaxAmt) === 'string' ? parseFloat(stateValues.principalMaxAmt.replace(/\$|,/g, '')) : stateValues.principalMaxAmt,
                    "maxFeeAmt": typeof(stateValues.maxFeeAmt) === 'string' ? parseFloat(stateValues.maxFeeAmt.replace(/\$|,/g, '')) : stateValues.maxFeeAmt,
                    "maxFeeDesc": stateValues.maxFeeDesc,
                    "basisPointDesc": stateValues.basisPointDesc,
                    "basisPointRate": stateValues.basisPointRate,
                    "createUser": CommonService.getUserName(user),
                    "lastUpdateUser": CommonService.getUserName(user),
                }
                }
            };
      
            FeeScheduleMaintenanceService.addFeeSchedule(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("Fee Schedule added successfully!");
                    onModalClose();
                }
            });
        }
    }

    return (
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>New Fee Schedule</h3></Modal.Header>
            <Modal.Body>
            <form id="addFeeForm">
                {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
                </Alert>}
                <Row>
                    <Col md={6}>
                        <div className="control">
                            <label><span className="required">* </span>Effective Start Date: </label>
                            <div >
                                <DatePicker 
                                selected={stateValues.effectiveStartDate ? new Date(stateValues.effectiveStartDate) : null}
                                dateFormat="M/d/yyyy"
                                maxDate= {new Date(9999, 12, 31)}
                                name="effectiveStartDate"
                                onChange={(e) => handleDateChange(e, 'effectiveStartDate')} 
                                error={formErrors && formErrors['effectiveStartDate']} 
                                showIcon   
                                className='form-control'
                                />
                                {formErrors.effectiveStartDate && <p className="error">Effective Start Date is required</p>}
                                <input type="hidden" data-validations={["required"]}  value={stateValues.effectiveStartDate} name="effectiveStartDate" />
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <label><span className="required">* </span>Effective End Date: </label>
                            <div>
                                <DatePicker 
                                selected={stateValues.effectiveEndDate ? new Date(stateValues.effectiveEndDate) : null}
                                dateFormat="M/d/yyyy"
                                maxDate= {new Date(9999, 12, 31)}
                                name="effectiveEndDate"
                                onChange={(e) => handleDateChange(e, 'effectiveEndDate')} 
                                error={formErrors && formErrors['effectiveEndDate']}  
                                showIcon                              
                                />
                                {formErrors.effectiveEndDate && <p className="error">Effective End Date is required</p>}
                                <input type="hidden" data-validations={["required"]}  value={stateValues.effectiveEndDate} name="effectiveEndDate" />
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                        <CurrencyInput placeholder="0.00" 
                            label="Minimum Principal Amount" 
                            name="principalMinAmt" 
                            type="text" 
                            value={stateValues.principalMinAmt ? stateValues.principalMinAmt : ""}
                            onChange={(e)=>handleChange(e)} 
                            error={formErrors.principalMinAmt} 
                            validations={["required", "max:999999999999.99"]}
                            isrequired="true"
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                        <CurrencyInput placeholder="0.00" 
                            label="Maximum Principal Amount" 
                            name="principalMaxAmt" 
                            type="text" 
                            value={stateValues.principalMaxAmt ? stateValues.principalMaxAmt : ""}
                            onChange={(e)=>handleChange(e)} 
                            error={formErrors.principalMaxAmt} 
                            validations={["required", "max:999999999999.99"]}
                            isrequired="true"
                            />
                        </div>
                    </Col>
                </Row>
                <div>
                    <h5>Fees and Invoice Description</h5>
                    <Row>
                    <Col>
                        <label>Basis Points Rate</label>
                        <Form.Control type="text" name="basisPointRate" value={stateValues.basisPointRate} 
                        onChange={(e)=>handleChange(e)} 
                        maxLength="15"/>
                    </Col>
                    
                    <Col>
                        <label>Description</label>
                        <div className="control">
                            <Form.Control type="text" name="basisPointDesc" value={stateValues.basisPointDesc} 
                            onChange={(e)=>handleChange(e)}/>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="control">
                            <CurrencyInput placeholder="0.00" 
                                label="Maximum Principal Fee" 
                                name="maxFeeAmt" 
                                type="text" 
                                value={stateValues.maxFeeAmt ? stateValues.maxFeeAmt : ""}
                                onChange={(e)=>handleChange(e)} 
                                error={formErrors.maxFeeAmt} 
                                validations={["max:999999999999.99"]}
                                />
                            </div>
                        </Col>
                    
                        <Col md={6} className="control">
                            <FormInput label="Description" 
                            name="maxFeeDesc" 
                            type="text" 
                            onChange={(e)=>handleChange(e)} 
                            error={formErrors.maxFeeDesc} 
                            value={stateValues.maxFeeDesc ? stateValues.maxFeeDesc : "" }
                            />

                        </Col>
                    </Row>
                </div>
                <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                    <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
            </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddFeeForm
