import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import BaseService from "../../../services/BaseService";
const RemittanceAdviceTemplate = (props) => {
    const [remittanceAdviceTemplate, setRemittanceAdviceTemplate] = useState("");
    useEffect(() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getRemittanceAdviceTemplate",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
          };
      
          BaseService.processRequest(obj)
            .then((res) => res)
            .then((result) => {
              if (result.data.errors && result.data.errors.length > 0){
                toast(result.data.errors[0].message);
                }
                else{
                  setRemittanceAdviceTemplate(result.data.responses[0]);
                }
            });
    },[])

    const handleSubmit = () => {
      var obj = {
        msgId: null,
        sysId: null,
        opr: "updateRemittanceAdviceTemplate",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          "remittanceAdviceTemplate" : remittanceAdviceTemplate
         },
      };
  
      BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
          if (result.data.errors && result.data.errors.length > 0){
            toast(result.data.errors[0].message);
            }
            else{
              toast.success("Remittance Advice Template Updated Successfully!");
            }
        });
    }
    return (
        <form onSubmit={(e) => e.preventDefault()}>
          <Card>
            <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title" >
                        <h1 className="menu-title">Remittance Advice Template</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                    <p style={{color: 'red'}}>
                    *Please do not remove the &lt;BR&gt; tags as they indicate a new line.
                    </p>
                    <div className="row">
                    <textarea style={{alignItems: "center"}} 
                    rows="15"
                    value = {(remittanceAdviceTemplate)} 
                    onChange={(e)=>setRemittanceAdviceTemplate(e.target.value)}/>
                  </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div style={{overflow: 'auto'}}>
                <button className="custom-button fright" onClick={()=>handleSubmit()}>Update</button>
                </div>
              </div>
            </div>
            
          </Card>
          
        </form>
    )
}

export default RemittanceAdviceTemplate;