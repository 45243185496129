import {Form, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import StatuteMaintenanceService from "../../../services/StatuteMaintenanceService";
import React from "react";
import { useForm } from "react-hook-form";
import {useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify';
import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import CommonService from "../../../services/CommonService";
import TooltipComponent from '../../../common/Tooltip';

const EditCodeForm = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, codeInfo} = props;
    const {register, handleSubmit,  formState: { errors }} = useForm({
        defaultValues: {
            statuteId: codeInfo.statuteId,
            statuteCode: codeInfo.statuteCode,
            activeInd: codeInfo.activeInd === 'Y' ? true : false,
            statuteSection: codeInfo.statuteSection,
            statuteDesc: codeInfo.statuteDesc
          }

    });
    const [errorMessage, setErrorMessage] = useState();

    const onSubmit = (data) => {

        var obj = {
            msgId: null,
            sysId: null,
            opr: "putStatuteRef",
            hdrStruct: null,
            queryParam: null,
            reqData: { "statuteId": data.statuteId, "statuteRef":{
                "statuteId": data.statuteId,
                "statuteCode": data.statuteCode,
                "statuteSection": data.statuteSection,
                "statuteDesc": data.statuteDesc,
                "createUser": CommonService.getUserName(user),
                "lastUpdateUser": CommonService.getUserName(user),
                "activeInd": data.activeInd === true ? 'Y' : 'N'
            }
            },
          };
      
          StatuteMaintenanceService.updateStatute(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("Statute updated successfully!");
                    onModalClose();
                }
                
            });
      }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Edit Statute</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">

                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
                <div className="control">
                    <label><span className="required">* </span>Statute ID: </label>
                    <input maxLength="6" {...register('statuteId', { required: true })} disabled/>
                </div>
                <div className="control">
                    <label><span className="required">* </span>Code: </label>
                    <input maxLength="25" {...register('statuteCode', { required: true })}  disabled/>
                </div>
                <div className="control">
                    <label><span className="required">* </span>Section: </label>
                    <input maxLength="25" {...register('statuteSection', { required: true })} />
                    {errors.statuteSection && <p style={{ color: 'red' }}>Section is required</p>}
                </div>
                <div className="control">
                    <label><span className="required">* </span>Description: </label>
                    <textarea maxLength="100" {...register('statuteDesc', { required: true })}/>
                    {errors.statuteDesc && <p style={{ color: 'red' }}>Statute Desc is required</p>}
                </div>
                <div style={{display: 'flex'}}>
                    <Form.Check inline type="checkbox" label="Active?" {...register('activeInd')}/>
                    <TooltipComponent
                    title="Only active values are displayed in the User Interface"
                    id="isActive"
                    placement="right">
                        <AiFillQuestionCircle className="help-icon mt-2" />
                    </TooltipComponent>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                <button className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Update</button>
            </Modal.Footer>
        </Modal>
         </form>
    )
}

export default EditCodeForm
