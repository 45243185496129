import {useState,useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Form, Row, Col} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import DashboardSearchModal from './dashboardSearchModal'
import Select from 'react-select'
import Card from '../ui/Card'
import Reports from '../reports'
import TooltipComponent from '../../common/Tooltip'
const Dashboard = (props) => {
    const location = useLocation()
    const previousSelection = location.state?.dashboardSelection;
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [issuanceType, setIssuanceType] = useState('issuance');
    const [reportType, setReportType] = useState(previousSelection ? previousSelection.reportType : null);
    const [reportStatus, setReportStatus] = useState(previousSelection ? previousSelection.reportStatus : null);
    const [showReports, setShowReports] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState(null);
    const navigate = useNavigate();
        
    const handleNewReportLinkClick = (e, path) => {
      e.preventDefault();
      navigate(path);
    }

    const ReportTypes = [
      {value: 'PD', label: 'Report of Proposed Debt', type: 'issuance'},
      {value: 'FS', label: 'Report of Final Sale', type: 'issuance'},
      {value: 'DRAW', label: 'Draw on Reserve/Default/Replenishment', type: 'yearlyStatus'},
      {value: 'ADTR', label: 'Annual Debt Transparency Report',type: 'yearlyStatus'},
      {value: 'MLR', label: 'Mello Roos',type: 'yearlyStatus'},
      {value: 'MKRA', label: 'Marks Roos (Authority)',type: 'yearlyStatus'},
      {value: 'MKRL', label: 'Marks Roos (Local Obligor)', type: 'yearlyStatus'},
    ]

    
    const handleReportTypeChange = (e) => {
      setShowReports(false);
      setReportType(e.value)
    }
    const handleReportStatusChange = (e) => {
      setReportStatus(e.target.value);
      setShowReports(false);
    }
    const handleIssuanceTypeChange = (e) => {
      setIssuanceType(e.target.value);
      setReportType(null);
    }
    const handleShowReports = () => {
      const dashboardSelection = {
        'issuanceType' : issuanceType,
        'reportType': reportType,
        'reportStatus' : reportStatus
        };
      sessionStorage.setItem('dashboardSelection', JSON.stringify(dashboardSelection));
      if(reportStatus === 'new-pending') {
        setShowReports(true);
      } else {
        setShowSearchModal(true);
      }
    }

    useEffect(()=>{
      if(previousSelection) {
        handleShowReports();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleSearchCriteria = (searchContext) => {
      setSearchCriteria(searchContext);
      setShowSearchModal(false);
      setShowReports(true);
    }

    return (
          <Card>
              <div className="panel panel-default">
              <div className="panel-heading">
                    <div className="container">
                        <div id="packet_id" className="panel-title">
                            <h1 className="menu-title">Dashboard</h1>
                        </div>
                    </div>
                </div>
                
                <div id="dashboard">
                  <Card>
                    <div className="form">
                      <form>
                      <Row>
                        <Col md={12}>
                        <Form.Check inline type="radio" name="issuanceType" value="issuance" id="issuance" label="Issuance" onChange={(e)=>handleIssuanceTypeChange(e)} checked={issuanceType === 'issuance'}/>
                        <TooltipComponent 
                        title="This includes Report of Proposed Debt & Report of Final Sale" id="issuance-tooltip"
                        placement="right">
                          <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                        </Col>
                        <Col md={12}>
                        <Form.Check inline type="radio" name="issuanceType" value="yearlyStatus" id="yearlyStatus" label="Ongoing/Yearly Status Submissions" onChange={(e)=>handleIssuanceTypeChange(e)} checked={issuanceType === 'yearlyStatus'}/>
                        <TooltipComponent 
                        title="This includes Reason for Private Sale, ADTR, Mello Roos, Marks Roos (Authority), Marks Roos (Local)" id="ongoing-submission-tooltip"
                        placement="right">
                          <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                        </Col>
                      </Row>
                      <Row>
                        <label><span className="required" >*</span>Select Type of Report: 
                        <TooltipComponent 
                        title="Select Type of Report" 
                        id="report-type"
                        placement="right">
                          <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                        </label>
                        <Select 
                          options={ReportTypes.filter(
                            option => option.type === issuanceType
                          )} 
                          onChange={(e)=>handleReportTypeChange(e)}
                          value={ReportTypes.filter(
                            option => option.value === reportType
                          )}/>
                      </Row>
                      <Row>
                        <Col md={6}>
                        <Form.Check inline type="radio" name="reportStatus" value="new-pending" id="new-pending" label="New and Pending Reports" onChange={(e)=>handleReportStatusChange(e)} checked={reportStatus === 'new-pending'}/>
                        </Col>
                        <Col md={6}>
                        <Form.Check inline type="radio" name="reportStatus" value="view-previous" id="view-previous" label="View Previous Reports" onChange={(e)=>handleReportStatusChange(e)} checked={reportStatus === 'view-previous'}/>
                        </Col>
                      </Row>
                      <div style={{overflow: 'auto', margin: '10px'}}>
                      <button className="fright custom-button" type="button" onClick={()=>handleShowReports()}>GO</button>
                      </div>
                      </form>
                      {showReports && <Reports reportType={reportType} reportStatus={reportStatus} searchCriteria={searchCriteria} newReportLink={handleNewReportLinkClick}/>}
                    </div>
                  </Card>
                </div>
            </div>

          {showSearchModal && <DashboardSearchModal onModalClose={()=>setShowSearchModal(false)} handleSearch={handleSearchCriteria}/>}          
          </Card>
       
        
    )
}

export default Dashboard
