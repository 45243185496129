import { useContext, useState } from 'react'
import CommonService from '../../services/CommonService';
import FinalSaleReportService from '../../services/FinalSaleReportService';
import Card from '../ui/Card'
import { toast } from 'react-toastify';
import { UserContext } from '../../UserContext';
import { Alert } from 'react-bootstrap';
const Comments = (props) => {
    const {drawDetails} = props;

    const user = useContext(UserContext);
    const [count, setCount] = useState(drawDetails ? (drawDetails.issuerComment ? drawDetails.issuerComment.length : 0) : 0);
    const [cdiacCommentCount, setCdiacCommentCount] = useState(drawDetails ? (drawDetails.cdiacComment ? drawDetails.cdiacComment.length : 0) : 0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [cdiacBgColor, setCdiacBgcolor] = useState('yellowgreen');
    const [commentValue, setCommentValue] = useState(drawDetails ? drawDetails.issuerComment : "");
    const [cdiacCommentValue, setCdiacCommentValue] = useState(drawDetails ? drawDetails.cdiacComment : "");
    const [errorMessage, setErrorMessage] = useState();

    const handleCommentValue = (e) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setCommentValue(e.target.value);
    };
    const handleCdiacCommentValue = (e) => {
        setCdiacCommentCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setCdiacBgcolor(color);
        setCdiacCommentValue(e.target.value);
    };

    const validateForm = (e) => {
        async function updateComments() {
            let obj = {
                msgId: null,
                sysId: null,
                opr: "updateDrawIssuerCdiacComment",
                hdrStruct: null,
                queryParam: null,
                reqData: {  "id": props.drawId, 
                    "env" : "I",
                    "draw":{
                        "issuerComment" : commentValue,
                        "cdiacComment" : cdiacCommentValue,
                        "lastUpdateUser": CommonService.getUserName(user),
                        "emailId": user
                    }
                },
            };
            
            await FinalSaleReportService.updateDrawIssuerComment(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    props.updateDraw(result.data.responses[0]);
                    toast.success("Comment updated successfully!");
                }
                
            });
        }
        
        updateComments();
        props.openWidget('review');
    }
    return (
        <Card>
            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
            <div className="form">
                <h3>Issuer Comments</h3>
                <div className="comments-box">
                    <textarea name="comment" value={commentValue} 
                    id="comments-input" onChange={handleCommentValue}
                     className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                     <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                    <div>
                    
                    <label>CDIAC Comments: </label>
                    <textarea name="comment" value={cdiacCommentValue} 
                    id="comments-input" onChange={handleCdiacCommentValue}
                     className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                     <span style={{position: 'relative', left: '45%' , backgroundColor: cdiacBgColor }}>{cdiacCommentCount} out of 4000 max</span>
                    </div>
                    

                   
                </div>

                <div className="btn-div">
                {/* <button className="custom-button" type="button">Back</button> */}
                <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>
            </div>  
        </Card>
    )
}

export default Comments
