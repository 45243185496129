import { useContext, useState } from 'react'
import {Dropdown} from 'react-bootstrap'
import IssuerFilerService from '../../../services/IssuerFilerService'
import { toast } from 'react-toastify'
import CommonService from '../../../services/CommonService'
import { UserContext } from '../../../UserContext'
import EditIssuer from '../issuerMaintenance/editIssuer'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const IssuerFilerAssociations = (props) => {
  //const { SearchBar, ClearSearchButton } = Search;
  const { associations, fetchAssocs } = props;
  const [openIssuer, setOpenIssuer] = useState(false);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const user = useContext(UserContext);

  const handleModalClose = () => {
    setOpenIssuer(false);
    setSelectedIssuer(null);
  }
  const handleIssuerNameClick = (issuerInfo) => {
    setSelectedIssuer(issuerInfo);
    setOpenIssuer(true);
  }
  const viewEditFormatter = (row) => {
    return (
      <div style={{ textAlign: "center", lineHeight: "normal" }}>
          <div className="dropdown">
              <Dropdown>
              <Dropdown.Toggle 
              variant="secondary btn-sm" 
              id="dropdown-basic">
                  Action
              </Dropdown.Toggle>

              <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                  <Dropdown.Item href="#" onClick={(e)=>handleActionClick(e, 'approve', row )}>Approve</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e)=>handleActionClick(e, 'delete', row )}>Delete</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={(e)=>handleActionClick(e, 'notify-issuer', row )}>Notify Issuer</Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
          </div>
      </div> 
    );
  }
  const issuerNameFormatter = (row) => {
    return (
      <div style={{ textAlign: "center", lineHeight: "normal" }}>
        <button
          type="button"
          className="link-button dk-blue-color me-4"
          onClick={() => handleIssuerNameClick(row)}
        >
          {row['issuerName']}
        </button>
      </div> 
    );
  }

  const handleActionClick = (e, action, row) => {
    if( action === 'approve' ) {
      let obj = {
        msgId: null,
        sysId: null,
        opr: "putRequestIssuer",
        hdrStruct: null,
        queryParam: null,
        reqData: { 
          "id" : row.issuerOrgAssocId,
          "verifiedInd" : "Y",
          "user" : user
         },
        };
  
      IssuerFilerService.updateIssuerFilerRequest(obj)
        .then((res) => res)
        .then((result) => {
          toast.success("Issuer Filer Association updated successfully.")
          fetchAssocs();
        });
    } else if( action === 'delete') {
      let obj = {
        msgId: null,
        sysId: null,
        opr: "deleteIssuerOrgAssoc",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
              "issuerOrgAssocId" : row.issuerOrgAssocId
            },
          };
  
      IssuerFilerService.updateIssuerFilerRequest(obj)
        .then((res) => res)
        .then((result) => {
          toast.success("Issuer Filer Association deleted successfully.")
          fetchAssocs();
        });
    } else if (action === 'notify-issuer') {
      let obj = {
        msgId: null,
        sysId: null,
        opr: "notifyIssuerOrgAssoc",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
              "issuerOrgAssoc" : { ...row }
            },
          };
        
          IssuerFilerService.notifyIssuerOrgAssoc(obj)
        .then((res) => res)
        .then((result) => {
          toast.success("Email Sent Successfully.")
          fetchAssocs();
        });
    }
  }  


  const columns = [{
    dataField: 'issuerName',
    text: 'Issuer Name',
    sort: true,
    formatter: issuerNameFormatter
  }, {
    dataField: 'orgName',
    text: 'Filer Name',
    sort: true,
  },{
    dataField: 'verifiedInd',
    text: 'Status',
    sort: true,
  },{
    dataField: 'createUser',
    text: 'Requestor',
    sort: true,
  },{
    dataField: 'createDatetime',
    text: 'Create Date',
    sort: true,
    formatter: (row) => CommonService.dateFormatter(row['createDatetime']),
  },{
    dataField: "",
    text: "Action",
    formatter: viewEditFormatter,
  }
  ];

  return (
    <>
      <div className="form">
        <DataGrid
            title={'Pending Issuer Filer Association Requests: '}
            dataSource={associations}
            sortField='requestId'
          >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                />)
              }) 
            } 
        </DataGrid>
      </div>
      {openIssuer && <EditIssuer onModalClose={() => handleModalClose()} issuerInfo={selectedIssuer} />}
    </>
  )
}

export default IssuerFilerAssociations
