import { useState, useEffect } from 'react'
import IssuerFilerService from '../../../services/IssuerFilerService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import IssuerRegistrationForm from '../../../pages/IssuerRegistrationForm'
import CommonService from '../../../services/CommonService'
import { AlertType } from '../../../utils'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const IssuerRequestsContainer = (props) => {
  const [selectedIssuerInfo, setSelectedIssuerInfo] = useState([]);
  const { searchReq } = props;
  const [authorizationRefs, setAuthorizationRef] = useState([])
  const [openIssuerRequest, setOpenIssuerRequest] = useState(false);
  const [action, setAction] = useState(null);
  const viewFormatter = (row) => {
    return (
      <div style={{ textAlign: "center", lineHeight: "normal" }}>
               <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row, 'edit')}/>
      </div>
    );
  }

  const onRowClick = (e, row, action) => {
    setSelectedIssuerInfo(row);
    setOpenIssuerRequest(true);
    setAction(action);
  }

  
  useEffect(() => {
      fetchIssuerFilerRequests();
      // eslint-disable-next-line
  },[]);

  const fetchIssuerFilerRequests = async () => {
    CommonService.showHideSpinner(true);
    var obj = {
      msgId: null,
      sysId: null,
      opr: "searchIssuerFiler",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        "searchIssuerFiler" : searchReq
      },
    };

    await IssuerFilerService.searchIssuerFilerRequests(obj)
      .then((res) => res)
      .then((result) => {
        if(result.data.statusCode === 401 || result.data.statusCode === 500) {
          CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error);
        } else {
          setAuthorizationRef(result.data.responses[0]);
        };
        CommonService.showHideSpinner(false);
      });
  }

  const columns = [{
    dataField: 'requestId',
    text: 'Request Id',
    sort: true,
  }, {
    dataField: 'issuerName',
    text: 'Org Name',
    sort: true
  }, {
    dataField: 'issuerAddressLine1',
    text: 'Address',
    sort: true,
  }, {
    dataField: 'issuerCityName',
    text: 'City',
    sort: true,
  }, {
    dataField: 'issuerStateCode',
    text: 'State',
    sort: true
  }, 
  {
    dataField: "issuerEmailId",
    text: "Email",
    sort: true,
  },
  {
    dataField: "requestStatusFull",
    text: "Status",
  },
  {
    dataField: "",
    text: "",
    formatter: viewFormatter
  }
  ];

  const handleOnModalClose = () => {
    setOpenIssuerRequest(false);
    fetchIssuerFilerRequests();
  }

  return (
    <>
      <div className="form">
        <DataGrid
            title={'Search Result:'}
            dataSource={authorizationRefs}
            sortField='requestId'
          >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                />)
              }) 
            } 
        </DataGrid>

        { openIssuerRequest && <IssuerRegistrationForm issuerRequestInfo = {selectedIssuerInfo} onModalClose={handleOnModalClose} action={action} /> }
      </div>
    </>
  )
}

export default IssuerRequestsContainer
