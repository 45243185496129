import { configManager } from "./configManager";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {method: "GET",headers: headers};
    return fetch(`${configManager.graphMeEndpoint}`, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function hasGroupAccess(accessToken) {
    let userHasAccess = false;
    let data = [];
    try{
        data = await fetch(`${configManager.graphMeEndpoint}/memberOf`, {
            method: "GET",
            headers: {'Authorization': `Bearer ${accessToken}`} 
        })
        .then(response => response.json())
    } catch (error) {
        console.log('An error occured during the API request: ', error);
    }
    userHasAccess = checkGroupMembership(data);
    return userHasAccess;
}

const checkGroupMembership = (response) => {
    if(response) {
        const groups = response.value || [];
        return groups.some(group => group.id === configManager.groupId);
    }
    return false
}