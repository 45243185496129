import React, { useEffect, useState, useContext, useCallback } from 'react'
import Card from '../../ui/Card'
import { Form, Row, Col, Modal, Spinner, FormGroup, Alert } from "react-bootstrap"
import { FormInput } from '../../formElements/FormInput'
import CurrencyInput from '../../formElements/CurrencyInput'
import { runValidations, validateFormFields } from '../../../utils'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {FaPlusCircle, FaMinusCircle, FaCalendarAlt} from 'react-icons/fa'
import swal from 'sweetalert'
import { Typeahead } from 'react-bootstrap-typeahead'
import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import CommonService from '../../../services/CommonService'
import IssueCdiacNumberService from '../../../services/IssueCdiacNumberService'
import IssuerService from '../../../services/IssuerService'
import {UserContext, IssuerContext } from "../../../UserContext";
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import IssueSocialImpactService from '../../../services/IssueSocialImpactService'
import Table from '../../Table'
import AddSocialImpactBond from './addSocialImpactBond'
import TooltipComponent from '../../../common/Tooltip'

const socialImpactBonds = [
    { code: "G", value: "Green"},
    { code: "S", value : "Social Impact"},
    { code: "U", value : "Sustainability"},
    { code: "N", value : "None"}
]
const IssuerDetails = (props) => {
    const { issuerDetails } = props;
return (
    <Modal show={true} onHide={props.onModalClose}>
        <Modal.Header closeButton>
            <h1>Issuer Details</h1>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col md={6}>
                    <label>Address Line1</label>
                    <Form.Control type="text" value={issuerDetails.contactAddressLine1} readOnly/>
                </Col>
                <Col md={6}>
                    <label>Address Line2</label>
                    <Form.Control type="text" value={issuerDetails.contactAddressLine2} readOnly/>
                </Col>
                <Col md={6}>
                    <label>Address Line3</label>
                    <Form.Control type="text" value={issuerDetails.contactAddressLine3} readOnly/>
                </Col>
                <Col md={6}>
                    <label>Address Line4</label>
                    <Form.Control type="text" value={issuerDetails.contactAddressLine4} readOnly/>
                </Col>
                <Col md={6}>
                    <label>City</label>
                    <Form.Control type="text" value={issuerDetails.contactCityName} readOnly/>
                </Col>
                <Col md={6}>
                    <label>State</label>
                    <Form.Control type="text" value={issuerDetails.contactStateCode} readOnly/>
                </Col>
                <Col md={6}>
                    <label>ZipCode</label>
                    <Form.Control type="text" value={issuerDetails.contactZipCode} readOnly/>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
        </Modal.Footer>
    </Modal>
)
}
const Issuer = (props) => {
    const issuerId = useContext(IssuerContext);
    const loginUser = useContext(UserContext);
    const { issueId, proposedDebtDetails, proposeDebtDependencies } = props;
    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    const [showCdiacSection, setShowCdiacSection] = useState(false);
    const [issuerInfo, setIssuerInfo] = useState(proposedDebtDetails);//, 'proposedSaleDate': proposedDebtDetails.proposedSaleDate ? new Date(proposedDebtDetails.proposedSaleDate) : null });
    const [formErrors, setFormErrors] = useState({});
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [formSubmittingLoader, setFormSubmittingLoader] = useState(false);
    const [cdiacNumbersList, setCdiacNumbersList] = useState([]);
    const [issuerDetails, setIssuerDetails] = useState(null);
    const [issueSocialImpactBonds, setIssueSocialImpactBonds] = useState([]);
    const [addSocialImpactBond, setAddSocialImpactBond] = useState(false);
    const [editIdx, setEditIndex] = useState(-1);
    const [total, setTotal] = useState(0.00);
    const [showDebtForRefundingTextField, setShowDebtForRefundingTextField] = useState(proposedDebtDetails && proposedDebtDetails.refndRedeemPaydnRefinInd === 'Y' ? true : false);
    const [issueRefundCdiacNumbers, setIssueRefundCdiacNumbers] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [showDebtPolicyHelpTextModal, setShowDebtPolicyHelpTextModal] = useState(false);
    const [mkrLobcdiacNbrs, setMkrLobCdiacNbrs] = useState(proposeDebtDependencies && proposeDebtDependencies.issueMkrAuthAssocs.length > 0 ? proposeDebtDependencies.issueMkrAuthAssocs : [{authCdiacNbr: ''}]);
    const [isLoading, setIsLoading] = useState(false);
    debugger
    useEffect(()=>{
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssuerDetailsByIssuerId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": issuerId 
            }
        };
       
        IssuerService.getIssuerContactByIssuerId(obj)
          .then((res) => res)
          .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
              if(result.data.responses.length > 0) {
                setIssuerDetails(result.data.responses[0]);
              }
            }
        });
    }, [issuerId])
    useEffect(()=>{
        if(issuerInfo.marksRoosLocalObligInd === 'Y') {
            setShowCdiacSection(true);
            getCdiacNumbers();
        }
    }, [issuerInfo.marksRoosLocalObligInd, issueId])
    const getMkrAuthAssocs = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getMkrAuthLobAssocsByIssueId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "proposeDebtId": issueId
            }
        };
       
        ReportOfProposedDebtService.getMkrAuthLobAssocsByIssueId(obj)
          .then((res) => res)
          .then((result) => {
              if(result.data.responses[0].length > 0) {
                setMkrLobCdiacNbrs(result.data.responses[0]);
                props.updateProposeDebtDependencies(result.data.responses[0], 'issueMkrAuthAssocs')
              } else {
                setMkrLobCdiacNbrs([
                    {authCdiacNbr: ''}
                ]);
              }
          });
    }
    const getIssueSocialImpactBonds = useCallback(async() => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueSocEnvImpactsByProposeDebtId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { "id" : issueId }
        };
       await IssueSocialImpactService.getIssueSocEnvImpactsByIssueId(obj)
          .then((res) => res)
          .then((result) => {
              let envSocialImpactsArr = [];
              result.data.responses[0].forEach((item, i) => {
                let socialImpact = socialImpactBonds.find(ref => ref.code === item.envSocialImpactCode);
                let envSocialImpact = {...item, 
                  'envSocialImpactValue' : socialImpact.value
                }
                envSocialImpactsArr.push(envSocialImpact);
              }); 
            setIssueSocialImpactBonds(envSocialImpactsArr);
            props.updateProposeDebtDependencies(result.data.responses[0], 'issueSocEnvImpacts');
            //setIsLoading(false);
          });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[issueId]);

    useEffect(()=> {
        showDebtForRefundingTextField && getIssueRefundAssocCdiac();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showDebtForRefundingTextField])

    useEffect(()=>{
        getIssueSocialImpactBonds();
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        calculateSocialImpactAmountTotal(issueSocialImpactBonds);
    }, [issueSocialImpactBonds]);

    const calculateSocialImpactAmountTotal = (issueSocialImpactBonds) => {
        const total = issueSocialImpactBonds.reduce((total, item) => total + parseFloat(item.envSocialImpactAmt !== "" ? 
        ( typeof item.envSocialImpactAmt === "string" ? item.envSocialImpactAmt.replace(/,/g,''): item.envSocialImpactAmt)
         : 0), 0);
        setTotal(total.toLocaleString());
    }

    const updateSocialEnvImpact = (socEnvImpactData)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueSocEnvImpact",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id": socEnvImpactData.envSocialImpactId,
                "issueSocialEnvironmentalImpact": socEnvImpactData
            }
        };

        IssueSocialImpactService.updateSocialEnvImpact(obj)
        .then((res) => res)
        .then((result) => {
            getIssueSocialImpactBonds()
        });
    }

    const deleteSocialEnvImpact = (socEnvImpactId)  => {
      let obj = {
          "msgId": null,
          "sysId": null,
          "opr": "deleteIssueSocEnvImpact",
          "hdrStruct": null,
          "queryParam": null,
          "reqData": {
            "id" : socEnvImpactId,
          }
      };

      IssueSocialImpactService.deleteSocialEnvImpact(obj)
      .then((res) => res)
      .then((result) => {
          alert(
            'Deleted Successfully'
          )
          getIssueSocialImpactBonds();
      });
    }

    const handleSave = (i, rowData) => {
        updateSocialEnvImpact(rowData);
        setEditIndex(-1);
    }
    const handleRemove = (i, rowData) => {
        const socEnvImpactId = rowData.envSocialImpactId;
        deleteSocialEnvImpact(socEnvImpactId);
        setIssueSocialImpactBonds(
            issueSocialImpactBonds.filter((row, j) => j !== i)
        );
      
    }
    const handleEnvironmentalSocialImpactChange = (e, name, i) => {
        const { value } = e.target;
        const issueSocialImpactBondsnew =[...issueSocialImpactBonds];        
        issueSocialImpactBondsnew[i][name] = value;
        //setIssueSocialImpactBonds(issueSocialImpactBondsnew);
    }

    const handleModalClose = () => {
        setAddSocialImpactBond(false);
        getIssueSocialImpactBonds();
    }

    const startEditing = i => {
        setEditIndex(i);
    };
    
    const stopEditing = () => {
        setEditIndex(-1);
    };

    const handleCdiacAssocChange = (e, i) => {
        const {name, value} = e.target;
        let cidacNumArr = [...issueRefundCdiacNumbers];
        cidacNumArr[i][name] = value;
        setIssueRefundCdiacNumbers(cidacNumArr);
    }

    const addIssueRefundCdiacNumber = () => {
        setIssueRefundCdiacNumbers(prevState=>([
            ...issueRefundCdiacNumbers, {cdiacNum: '', amount: ''}
        ]))
    }

    const validateCdiacNumber = async (i, action) => {
        let cidacNumArr = [...issueRefundCdiacNumbers];
        const cdiacNbr = cidacNumArr[i].cdiacNum;
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "IsIssuerAssocValidCdiacNumber",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "cdiacNumber" : cdiacNbr,
                "issuerId": issuerId
            }
        };
    
      await IssueCdiacNumberService.validateIssueRefundCdiac(obj)
        .then((res) => res)
        .then((result) => {
            const response = result.data.responses[0];
            if(!response.isValidCdiac) {
                toast.error("This CDIAC Number does not exist or has been cancelled.");
            } else {
                if(!response.IsIssuerAssocValidCdiacNumber) {
                    swal({
                        text: "The CDIAC number you have entered is associated with a different Issuer.Do you wish to continue?",
                        icon: "warning",
                        buttons: ["No", "Yes"],
                    })
                    .then((yesContinue) => {
                        if (yesContinue) {
                            if(action === "create"){
                                saveIssueRefundCdiac(i);
                            } else if(action === "update"){
                                updateIssueRefundCdiac(i);
                            }else{}
                        } else {
                        swal.close();
                        }
                    });
                } else {
                    if(action === "create"){
                        saveIssueRefundCdiac(i);
                    } else if(action === "update"){
                        updateIssueRefundCdiac(i);
                    } else{}
                }
            }
        });
    }
    const getIssueRefundAssocCdiac = async (i) => {
        let cidacNumArr = [];

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueRefundAssocsByIssueId",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "proposeDebtId": issueId,
            }
        };
    
      await ReportOfProposedDebtService.getIssueRefundAssocsByIssueId(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                setErrorMessage(result.data.errors[0].message)
            }
            else{
                result.data.responses[0].forEach((item,i) => {
                    const cdiacNbrObj = {};
                    cdiacNbrObj.cdiacNum = item.linkedCdiacNbr;
                    cdiacNbrObj.issueRefundId = item.issueRefundId;
                    cdiacNbrObj.amount = item.redeemAmt;
                    cidacNumArr.push(cdiacNbrObj);
                })
                setIssueRefundCdiacNumbers(cidacNumArr);
                props.updateProposeDebtDependencies(result.data.responses[0], 'issueRefundAssocs');
            }
        });
    }
    const saveIssueRefundCdiac = async (i) => {
        let cidacNumArr = [...issueRefundCdiacNumbers];
        const cdiacNbrObj = cidacNumArr[i];

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "postIssueRefundAssoc",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueRefundAssoc" : {
                    'linkedCdiacNbr': cdiacNbrObj.cdiacNum,
                    'cdiacNbr' : proposedDebtDetails.cdiacNbr === "Unassigned" ? "" : proposedDebtDetails.cdiacNbr,
                    'redeemAmt': cdiacNbrObj.amount ,
                    'proposeDebtId': issueId,
                    'createUser': loginUser
                }
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                setErrorMessage(result.data.errors[0].message)
            }
            else{
                getIssueRefundAssocCdiac();
                // const issueRefundId = result.data.responses[0];
                // cdiacNbrObj['issueRefundId'] = issueRefundId;
                // setIssueRefundCdiacNumbers(cidacNumArr);
                toast.success("CDIAC # saved successfully!");
            }
        });
    }

    const updateIssueRefundCdiac = async (i) => {
        let cidacNumArr = [...issueRefundCdiacNumbers];
        const issueRefundId = cidacNumArr[i].issueRefundId;
        if( issueRefundId ) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "updateIssueRefundAssoc",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": issueRefundId,
                    "issueRefundAssoc" : {
                        "linkedCdiacNbr": cidacNumArr[i].cdiacNum,
                        "redeemAmt": cidacNumArr[i].amount,
                        "lastUpdateUser" : loginUser
                    }
                }
            };
        
            await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    setIssueRefundCdiacNumbers(cidacNumArr);
                    props.updateProposeDebtDependencies(cidacNumArr, 'issueRefundAssocs');
                    toast.success("Cdiac Number and Amount Updated successfully!");
                }
            });
        } 
    }
    const deleteIssueRefundCdiac = async (i) => {
        let cidacNumArr = [...issueRefundCdiacNumbers];
        const issueRefundId = cidacNumArr[i].issueRefundId;
        if( issueRefundId ) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "deleteIssueRefundAssoc",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": issueRefundId
                }
            };
        
            await IssueCdiacNumberService.deleteIssueRefundAssoc(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    cidacNumArr.splice(i,1);
                    setIssueRefundCdiacNumbers(cidacNumArr);
                    props.updateProposeDebtDependencies(cidacNumArr, 'issueRefundAssocs');
                    toast.success("Cdiac Number deleted successfully!");
                }
            });
        } else {
            cidacNumArr.splice(i,1);
            setIssueRefundCdiacNumbers(cidacNumArr);
        }
    }

    const handleChange = (e) => {  
        const {value, name} = e.target;
        setIssuerInfo({
            ...issuerInfo,
            [name]: value
        });

        if( name === "marksRoosLocalObligInd") {
            if(value === 'Y') {
                setShowCdiacSection(true);
                getCdiacNumbers();
            } else {
                setShowCdiacSection(false);
            }
        }
        if( name === "refndRedeemPaydnRefinInd"){
            if( value === 'Y' && e.target.checked ) {
                setShowDebtForRefundingTextField(true)
            } else {
                setShowDebtForRefundingTextField(false)
            }
       }
    }

    const handleProposeDate = (e) => {
        setIssuerInfo(prevState=>({
            ...prevState, 
            'proposedSaleDate': e
        })
        )
    }

    const handleCdiacNbrChange = (e, i) => {
        const selectedValue = e.length ? e[0].cdiacNbr : "";
        
        let cidacNumArr = [...mkrLobcdiacNbrs];
        cidacNumArr[i].authCdiacNbr = selectedValue;
        setMkrLobCdiacNbrs(cidacNumArr);
    }

    const getCdiacNumbers = () => {
        setIsLoading(true);
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getCdiacNumbers",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {}
        };
       
        IssueCdiacNumberService.getCdiacNumbers(obj)
          .then((res) => res)
          .then((result) => {
            setCdiacNumbersList(result.data.responses[0]);
            setIsLoading(false);
          });
    }

    const addCdiacNumber = () => {
        setMkrLobCdiacNbrs(prevState=>([
            ...mkrLobcdiacNbrs, {authCdiacNbr: ''}
        ]))
    }
    const removeCdiacNumber = (i) => {
        let cidacNumArr = [...mkrLobcdiacNbrs];

        const mkrAuthLobId =  cidacNumArr[i].mkrAuthLobId;
        if ( mkrAuthLobId ) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "deleteMkrAuthLobAssoc",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": mkrAuthLobId
                }
            };
        
            IssueCdiacNumberService.deleteMkrAuthLobAssoc(obj)
            .then((res) => res)
            .then((result) => {
                getMkrAuthAssocs();
            });
        } else {
            if(cidacNumArr.length === 1) {
                cidacNumArr[0].authCdiacNbr = ""
            } else {
                cidacNumArr.splice(i,1);
            }
            setMkrLobCdiacNbrs(cidacNumArr);
        }        
    }

    const validateField = (e) => {
        if ( e.target.dataset['validations'] )
            runValidations(e) 
    }
    const checkforDuplicateIssuance = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "isDuplicateIssuance",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "proposeDebt": {
                "issuerId": issuerId,
                "issueName": issuerInfo.issueName,
                "principalSaleAmt": issuerInfo.principalSaleAmt,
            }}
        };
       
       const isdp = ReportOfProposedDebtService.checkforDuplicateIssuance(obj)
          .then((res) => res)
          .then((result) => {
              setFormSubmittingLoader(false);
            return result.data.responses[0];
          });
          return isdp;

    }

    const saveIssuance = () => {
        let envTotal = ( typeof total === "string" ? Number(total.replace(/,/g,'')) : total);
        let principalSaleAmount = ( typeof issuerInfo.principalSaleAmt === "string" ? Number(issuerInfo.principalSaleAmt.replace(/,/g,'')) : issuerInfo.principalSaleAmt);
        if(envTotal > principalSaleAmount) {
            swal("The sum of all the environmental and social impacts cannot exceed the principal amount.");
        } else {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "putProposeDebt",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id": issueId,
                    "proposeDebt": {...issuerInfo,
                        'lastUpdateUser': CommonService.getUserName(loginUser)}
                }
            };
        
            ReportOfProposedDebtService.updateProposedDebt(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.responses[0]) {
                    toast.success("Issuance updated successfully");
                    props.updateProposedDebtDetails(result.data.responses[0]);
                    props.sectionValidated('issuance', true);
                    props.openWidget('issuanceAuthorization');
                }
            });
        }
        
    }

    const saveMkrLocCdiacNbrs = async (cdiacNumsArr) => {
        for (const item of cdiacNumsArr) {
            if(!item.mkrAuthLobId) {
                let obj = {
                    "msgId": null,
                    "sysId": null,
                    "opr": "postMkrAuthLobAssoc",
                    "hdrStruct": null,
                    "queryParam": null,
                    "reqData": {
                        "mkrAuthLobAssoc": {
                            "authCdiacNbr": item.authCdiacNbr,
                            "lobCdiacNbr" : proposedDebtDetails.cdiacNbr === "Unassigned" ? "" : proposedDebtDetails.cdiacNbr,
                            "proposeDebtId": issueId,
                            "createUser": loginUser
                        }
                    }
                };
            
                await IssueCdiacNumberService.saveMkrLobAssoc(obj)
                .then((res) => res)
                .then((result) => {
                    if(result.data.errors.length > 0) {
                        toast.error(result.data.errors[0].message);
                    } else {
                        props.updateProposeDebtDependencies(result.data.responses[0], "issueMkrAuthAssocs");
                    }
                });
            }
        }
        
    }

    const checkMarksRoosInd = () => {
        if(issuerInfo.marksRoosAuthorityInd === 'Y' || issuerInfo.marksRoosLocalObligInd === 'Y'){
            swal("This debt issue may be reportable pursuant to GOV 6599.1 of the Marks-Roos Local Bond Pooling Act if the proceeds of the debt are used to acquire local obligations or transferred to a local obligor under the terms of a debt contract. (CDIAC Regulations, Section 6051).",{
                buttons: [false, true],
            }).then(async (value) => {
                if(mkrLobcdiacNbrs[0].authCdiacNbr !== "") {
                    let cdiacNumsArr = [...mkrLobcdiacNbrs];                   
                    await saveMkrLocCdiacNbrs(cdiacNumsArr);                    
                }
                await saveIssuance();
            })
        } else {
            saveIssuance();
        }
       
    }

    const validateForm = async (e) => {
        let form = e.target.closest('form#issuerForm');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
            props.sectionValidated('issuance', false);
        } else {
            setFormErrors({});           
            let isDuplicate = false;
            if( !proposedDebtDetails.issueName && !proposedDebtDetails.principalSaleAmt) {
                setFormSubmittingLoader(true);
                isDuplicate = await checkforDuplicateIssuance();
            }
            if(isDuplicate) {
                swal({
                    text: "A duplicate issuance has been found for the same Issuer, Issue Name, and the Principal Sale Amount. Do you want to continue with this Report?",
                    icon: "warning",
                    buttons: ["No", "Yes"],
                  })
                  .then((yesContinue) => {
                    if (yesContinue) {
                        checkMarksRoosInd();
                    } else {
                      swal.close();
                    }
                  });
            } else {
                checkMarksRoosInd();
            }
        }
    }
debugger
    return (
        <Card>
        <Form className="form" id="issuerForm">
            {errorMessage && <Alert variant="danger">
                <p>
                    {errorMessage}
                </p>
              </Alert>
            }
              
            <div className="control">
                <label>Issuer Name {"  "}
                <TooltipComponent
                title="Click me for more info."
                    id="statute-maintenance"
                placement="right">
                    <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>
                </TooltipComponent>
                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    <Modal.Body>
                        <div>
                            <span>Pre-populated from dashboard selection. Must be the proper name of the state or local issuing entity
(special districts may be abbreviated, for example community facilities district names abbreviated with “CFD”).
 If issuer name displayed is not accurate, return to your dashboard to select the correct issuing
entity or add a new issuer name.</span>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="custom-button-secondary btn-secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }
                </label>
                <input 
                name="issuerName" 
                type="text" 
                value={issuerDetails ? issuerDetails.issuerName : ""}
                disabled={true}
               />
            </div>
            
            <FormInput label="Issue Name" 
                name="issueName" 
                type="text" 
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                error={formErrors.issueName} 
                value={issuerInfo && issuerInfo.issueName ? issuerInfo.issueName : "" }
                validations={["required", "maxLength:200"]}
                isrequired="true"
                helpText="Title of debt issue as it will appear on the financing documents including all series names, if applicable."/>

            <FormInput label="Project Name" 
                name="projectName" 
                type="text" 
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                error={formErrors.projectName} 
                value={issuerInfo && issuerInfo.projectName ? issuerInfo.projectName : ""}
                validations={["maxLength:200"]}
                helpText="Optional: Name of the project(s) for which the debt proceeds will be used. Include the specific
                assessment district(s), housing/construction project, hospital/health facility, redevelopment area, if
                known. Series names may be included in this field if not included as part of the Issue Name."/>
            <Row>
            <Col md={6}>
            <div className="control">
                    <label><span className="required">*</span>Proposed Sale Date:
                        <TooltipComponent
                            title="Date this debt is scheduled to be sold. Sale Date is defined as the date of acceptance of the debt
                            contract between the issuer and the creditor."
                            id="actual-sale-date"
                            placement="right">
                                <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent> 
                    </label>
                    <div>
                        <span>
                        <DatePicker 
                        selected={issuerInfo && issuerInfo.proposedSaleDate ? issuerInfo.proposedSaleDate : null}
                        dateFormat="M/d/yyyy"
                        maxDate= {new Date(9999, 12, 31)}
                        name="proposedSaleDate"
                        onChange={(e) => handleProposeDate(e)} 
                        error={formErrors && formErrors['proposedSaleDate']}                                
                        />
                        <FaCalendarAlt className="date-picker-icon"/>
                        </span>
                        {formErrors.proposedSaleDate && <p className="error">{formErrors.proposedSaleDate}</p>}
                        <input type="hidden" data-validations={["required"]}  value={issuerInfo.proposedSaleDate} name="proposedSaleDate" />
                    </div>
                        
                </div>
            </Col>
            <Col md={6}>
                <div className="control">
            <CurrencyInput placeholder="0.00" 
                label="Principal to be sold" 
                name="principalSaleAmt" 
                type="text" 
                value={issuerInfo && issuerInfo.principalSaleAmt ? issuerInfo.principalSaleAmt : ""}
                onChange={(e)=>handleChange(e)} 
                onBlur={e=>validateField(e)}
                error={formErrors.principalSaleAmt} 
                validations={["required", "max:999999999999.99"]}
                isrequired="true"
                helpText="Total principal amount of debt proposed to be sold. The dollar amount may be updated after the sale
                when the Report of Final Sale is submitted."/>
                </div>
                </Col>
            </Row>
            <br />
            <div className="mb-3">
                <label><span className="required">*</span>Is any portion of the principal to refund, redeem, paydown, or refinance outstanding debt?
                    <TooltipComponent
                        title="Select “Yes” if any amount of the proposed principal will be used to redeem securities, pay down
                        outstanding balances, or refund or refinance outstanding debt."
                         id="RedeemPaydnRefinInd"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                    </TooltipComponent>
                </label>
                <div>
                <Form.Check
                    style={{marginBottom: '1rem'}}
                    inline
                    label="No"
                    value="N"
                    name="refndRedeemPaydnRefinInd"
                    type="radio"
                    id="refndRedeemPaydnRefinInd_0"
                    checked={issuerInfo.refndRedeemPaydnRefinInd === 'N'}
                    onChange={(e)=>handleChange(e)}
                    data-validations="required"
                />
                <Form.Check
                    inline
                    label="Yes"
                    value="Y"
                    name="refndRedeemPaydnRefinInd"
                    type="radio"
                    id="refndRedeemPaydnRefinInd_1"
                    checked={issuerInfo.refndRedeemPaydnRefinInd === 'Y'}
                    onChange={(e)=>handleChange(e)}
                    data-validations="required"
                />
                {formErrors['refndRedeemPaydnRefinInd'] && <p className="error">{formErrors['refndRedeemPaydnRefinInd']}</p>}
                {showDebtForRefundingTextField && 
                    <>
                    <Row>
                        <Col md={3} >
                        <div className="control">
                            <label>Refunding Amount:
                            <TooltipComponent
                                title="Amount of proposed principal to be used to refund, redeem, pay down or refinance outstanding debt."
                                id="refundAmt"
                                placement="right">
                                    <AiFillQuestionCircle className="help-icon" />
                            </TooltipComponent>
                            </label>
                            <CurrencyInput placeholder="0.00" 
                            name="refndRedeemPaydnRefinAmt" 
                            value={issuerInfo.refndRedeemPaydnRefinAmt} 
                            onChange={(e)=>handleChange(e)}
                            label=""/>
                        </div>
                        </Col>  
                    </Row>
                    
                    <label>Enter CDIAC# of the debt to be refunded, redeemed, paid down, or refinanced:
                        <TooltipComponent
                            title="Enter prior CDIAC# of the outstanding debt that the proposed principal will be used to refund, redeem,
                            paydown, or refinance. Multiple CDIAC#s may be added."
                            id="refundAmt"
                            placement="right">
                                <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                    </label>
                    <br/>
                    <button type="button" className="custom-button" onClick={()=>addIssueRefundCdiacNumber()}>Add</button>
                    {issueRefundCdiacNumbers.length > 0 && <Row>
                        <Col md={9} >
                            <table>
                                <thead>
                                    <tr>
                                        <td>CDIAC #</td>
                                        <td>Amount</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                {issueRefundCdiacNumbers && issueRefundCdiacNumbers.map((item, i) => {
                                    return <tr key={i}>
                                        <td>
                                            <div className="control">
                                                <Form.Control type="text" name="cdiacNum" value={item.cdiacNum} 
                                                onChange={(e)=>handleCdiacAssocChange(e, i)}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="control">
                                                <CurrencyInput placeholder="0.00" type="text" name="amount" value={item.amount} onChange={(e)=>handleCdiacAssocChange(e, i)}/>
                                            </div>
                                        </td>
                                        <td> 
                                            {item.issueRefundId ?  
                                            <button type="button" className="custom-button button-text" onClick={()=>validateCdiacNumber(i, "update")}>Update</button>
                                            :
                                            <button type="button" className="custom-button button-text" onClick={()=>validateCdiacNumber(i, "create")}>Save</button>
                                            }{" "}                               
                                            <button type="button" className="custom-button button-text" onClick={()=>deleteIssueRefundCdiac(i)}>Delete</button>
                                        </td>
                                    </tr>
                                }) }
                                </tbody>
                            </table>
                            
                        </Col>  
                    </Row>
                    }
                    </>
                }
                </div>
            </div>
            <Row>
                <div>
                    <label style={{marginBottom: '0.5rem'}}><span className="required">*</span>Issuer certifies that it has complied with Gov. Code section 8855(i) with respect to local debt policies:{"  "}
                <TooltipComponent
                    title="Click me for more info."
                    id="refundAmt"
                    placement="right">
                        <AiFillQuestionCircle onClick={()=>setShowDebtPolicyHelpTextModal(true)} className="help-icon heading-help-icon"/>
                </TooltipComponent>
                {showDebtPolicyHelpTextModal && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowDebtPolicyHelpTextModal(false)}>
                        <Modal.Body>
                            <div>
                                <span>The issuer must provide certification that it has adopted local debt policies concerning the use of debt
and that this contemplated debt issuance is consistent with those local debt policies. A local debt policy
shall include all of the following:</span>
                                <ul style={{listStyleType: 'lower-alpha'}}>
                                    <li>The purposes for which the debt proceeds may be used.</li>
                                    <li>The types of debt that may be issued.</li>
                                    <li>The relationship of the debt to, and integration with, the issuer’s capital improvement program or
                                        budget, if applicable.</li>
                                    <li>Policy goals related to the issuer’s planning goals and objectives.</li>
                                    <li>
                                        <p>The internal control procedures that the issuer has implemented, or will implement, to ensure that
                                        the proceeds of the proposed debt issuance will be directed to the intended use.</p>
                                        <p>In the case of an issue of debt the proceeds of which will be used by a governmental entity other than
                                        the issuer, the issuer may rely upon a certification by that other governmental entity that it has adopted
                                        the policies described in (C), (D), and (E), and the references to the “issuer” in those sections shall be
                                        deemed to refer instead to the other governmental entity.</p>
                                        <ul>
                                            <li>“No” indicates that the issuer cannot certify the adoption of statutorily compliant local debt
                                            policies.</li>
                                            <li>“Yes” indicates certification that the issuer has adopted local debt policies in compliance with
                                            Gov. Code section 8855(i).</li>
                                            <li>“N/A” indicates that the issuer is not an issuer of local debt (e.g. state or state instrumentality)
                                            or the entity that will use the debt proceeds is a non-governmental entity.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="custom-button-secondary btn-secondary" onClick={()=>setShowDebtPolicyHelpTextModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }</label>
                    <br />
                    <Form.Check
                            inline
                            label="No"
                            name="debtPolicyFlag"
                            type="radio"
                            id="inline-radio-1"
                            value="N"
                            checked={issuerInfo.debtPolicyFlag === 'N'}
                            onChange={(e)=>handleChange(e)}
                            data-validations="required"
                        />
                    <Form.Check
                            inline
                            label="Yes"
                            name="debtPolicyFlag"
                            type="radio"
                            id="inline-radio-2"
                            value="Y"
                            checked={issuerInfo.debtPolicyFlag === 'Y'}
                            onChange={(e)=>handleChange(e)}
                            data-validations="required"
                        />
                    <Form.Check
                            inline
                            label="N/A"
                            name="debtPolicyFlag"
                            type="radio"
                            id="inline-radio-3"
                            value="NA"
                            checked={issuerInfo.debtPolicyFlag === 'NA'}
                            onChange={(e)=>handleChange(e)}
                            data-validations="required"
                        />
                    {formErrors['debtPolicyFlag'] && <p className="error">{formErrors['debtPolicyFlag']}</p>}
                </div>
            </Row>
            <Row>
                <Col>
                    <div>
                        <label>Environmental / Social Impact Designation:
                        <TooltipComponent
                        title="Select “ADD” to report the amount of this debt that will be marketed with an environmental or social
                        impact designation."
                         id="env-designation"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                        </TooltipComponent>
                        </label>
                        <br/>
                        <button type="button" className="custom-button" onClick={()=>setAddSocialImpactBond(true)}>Add</button>
                        {issueSocialImpactBonds.length !==0 && <><Table 
                        handleRemove={handleRemove}
                        handleSave={handleSave}
                        startEditing={startEditing}
                        editIdx={editIdx}
                        stopEditing={stopEditing}
                        handleChange={handleEnvironmentalSocialImpactChange}
                        data={issueSocialImpactBonds}
                        header={[
                        {
                            name: "Environmental / Social Impact Type",
                            prop: "envSocialImpactValue",
                            editable: false
                        },
                        {
                            name: "Amount",
                            prop: "envSocialImpactAmt",
                            fieldType: "currency",
                            editable: true
                        }
                        ]}
                        />
                        <Row>
                            <Col md={{ span: 5, offset: 7 }}>
                                <FormGroup style={{display: 'inline-flex'}} controlId="formInlineName">
                                    <label>Total: </label>
                                    <Form.Control type="text" value={'$'+total} readOnly/>
                                </FormGroup>
                            </Col>
                        </Row>
                        </>
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                <label htmlFor="marksRoosAuthorityInd"><span className="required">*</span>Marks-Roos Authority/TRAN Pool Authority:
                    <TooltipComponent
                        title="Is this an issue of debt by an Authority under the Marks-Roos Bond Pooling Act? [Gov. Code section
                            6584 - 6599.3] [CCR Title 4 §6000(a)]."
                         id="actual-sale-date"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                    </TooltipComponent>
                </label> 
                </Col>  
                <Col>             
                <Form.Check
                    inline
                    label="No"
                    value="N"
                    name="marksRoosAuthorityInd"
                    type="radio"
                    id="marksRoosAuthorityInd-2"
                    data-validations="required"
                    checked={issuerInfo.marksRoosAuthorityInd === 'N'}
                    onChange={(e)=>handleChange(e)}
                />
                <Form.Check
                    inline
                    label="Yes"
                    value="Y"
                    name="marksRoosAuthorityInd"
                    type="radio"
                    id="marksRoosAuthorityInd-1"
                    data-validations="required"
                    checked={issuerInfo.marksRoosAuthorityInd === 'Y'}
                    onChange={(e)=>handleChange(e)}
                />
                </Col>
                {formErrors.marksRoosAuthorityInd && <p className="error">{formErrors.marksRoosAuthorityInd}</p>}
            </Row>

            <Row>
                <Col md={5}>
                <label htmlFor="marksRoosLocalObligInd"><span className="required">*</span>Marks-Roos Local Obligor/TRAN Participant:
                    <TooltipComponent
                        title="Is this an issue of debt by a Local Obligor under the Marks-Roos Bond Pooling Act? [Gov. Code Section
                            6584 - 6599.3] [CCR Title 4 §6000(w)]"
                         id="actual-sale-date"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon  heading-help-icon" />
                    </TooltipComponent>
                </label>
                </Col>
                <Col>
                <Form.Check
                    inline
                    label="No"
                    value="N"
                    name="marksRoosLocalObligInd"
                    type="radio"
                    id="marksRoosLocalObligInd-2"
                    data-validations="required"
                    checked={issuerInfo.marksRoosLocalObligInd === 'N'}
                    onChange={(e)=>handleChange(e)}
                />
                <Form.Check
                    inline
                    label="Yes"
                    value="Y"
                    name="marksRoosLocalObligInd"
                    type="radio"
                    id="marksRoosLocalObligInd-1"
                    data-validations="required"
                    checked={issuerInfo.marksRoosLocalObligInd === 'Y'}
                    onChange={(e)=>handleChange(e)}
                />
                </Col>
                {formErrors.marksRoosLocalObligInd && <p className="error">{formErrors.marksRoosLocalObligInd}</p>}
            </Row>
            
           {showCdiacSection &&  <Row>
               <label>Please Select CDIAC# of the related Authority(s):</label>
                <Col md={12} >
                    <table>
                        <thead>
                            <tr>
                                <td>CDIAC #</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                        {mkrLobcdiacNbrs && mkrLobcdiacNbrs.map((item, i) => {
                            return <tr key={i}>
                                <td>
                                    <Typeahead
                                            placeholder="Choose CDIAC Number"
                                            id="cdiacNbr"
                                            labelKey="cdiacNbr"
                                            onChange={e=>handleCdiacNbrChange(e, i)}
                                            options={cdiacNumbersList}
                                            isLoading={isLoading}
                                            selected={cdiacNumbersList.filter(option => 
                                                option.cdiacNbr === item.authCdiacNbr)}
                                            disabled = { item.mkrAuthLobId && true}
                                    />
                                </td>
                                <td>                                
                                    <button type="button" className="dk-blue-color link-button"><FaPlusCircle onClick={()=>addCdiacNumber()}/></button>{" "}
                                    <button type="button" className="dk-blue-color link-button"><FaMinusCircle onClick={()=>removeCdiacNumber(i)}/></button>
                                </td>
                            </tr>
                        }) }
                        </tbody>
                    </table>
                    
                </Col>  
            </Row>
            }


            <div className="btn-div">
            
            {formSubmittingLoader ?  <button className="custom-button fright" type="button" disabled>
                <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                />
                Loading...
            </button> :
            <button className="custom-button fright" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>}
            </div>
            
        </Form> 
        {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)} issuerDetails={issuerDetails}/>}
        {addSocialImpactBond && <AddSocialImpactBond onModalClose={()=>handleModalClose()} issueId={issueId}/>}
        </Card>
    )
}
  
export default Issuer;