import CommonService from "./CommonService";


const getAllFinancingPurposeGroups = (obj) => {
    return CommonService.apicall(obj)

};

const getFinancingPurposeGroupDetails = (obj) => {
    return CommonService.apicall(obj)

};

const addFinancingPurpose = (obj) => {
    return CommonService.apicall(obj)

};

const updateFinancingPurpose = (obj) => {
    return CommonService.apicall(obj)
};

const getAllFinancingPurposeTypes = (obj) => {
    return CommonService.apicall(obj)
};


const FinancialPurposeMaintenanceService = {
    getAllFinancingPurposeGroups,
    getFinancingPurposeGroupDetails,
    addFinancingPurpose,
    updateFinancingPurpose,
    getAllFinancingPurposeTypes
};

export default FinancialPurposeMaintenanceService;