import { useState, useEffect, useContext } from 'react'
import {Dropdown} from 'react-bootstrap'
import ReportsService from '../../services/ReportsService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UserContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import swal from 'sweetalert'
import { AlertType } from '../../utils'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'
import { configManager } from '../../configManager'

const FinalSaleReports = (props) => {
    const { reportStatus, searchCriteria } = props;
    const [finalSaleReports, setFinalSaleReports] = useState([]);
    const loginUserId = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        getFinalSaleWorkLoad();
        // eslint-disable-next-line
    }, [searchCriteria]);

    const getFinalSaleWorkLoad = () => {
        CommonService.showHideSpinner(true);
        let obj;
        if(reportStatus === "view-previous") {
            obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getOldFinalSaleReportsByIssuerId",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "env" : "I",
                    "searchCriteria": searchCriteria
                }
            } 
        } else {
            obj ={
                "msgId": null,
                "sysId": null,
                "opr": "getFinalSaleReportsByIssuerId",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "env" : "I"
                }
            } 
        }
        ReportsService.getReports(obj)
        .then((res) => res)
        .then((result) => {
            CommonService.showHideSpinner(false);

            if(result.data.statusCode === 401 || result.data.statusCode === 500) {
                CommonService.displayServerMessage(result.data.errors[0].message, AlertType.Error)
            } else {
                setFinalSaleReports(result.data.responses[0]);            
            }
        });
    }
    const actions = {
        'D' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'S' : [
            {value:'E', label: 'Edit'},
            {value:'C', label: 'Cancel'},
            {value:'SH', label: 'View History'}
        ],
        'R' : [
            {value:'E', label: 'Edit'},
            {value:'SH', label: 'View History'},
            {value:'DW', label: 'View in DebtWatch'}
        ],
        'E' : [
            {value:'E', label: 'Edit'},
            {value:'SH', label: 'View History'}
        ]
    }
    const viewFormatter = (row) => { 
        return ( 
            <div style={{ textAlign: "center", lineHeight: "normal" }}>
                <div className="dropdown">
                    <Dropdown>
                    <Dropdown.Toggle 
                    variant="secondary btn-sm" 
                    id="dropdown-basic">
                        Action
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                        {actions[row['issueStatusFlag']].map((action, i)=> {
                            return <Dropdown.Item key={i} href="#" onClick={(e)=>handleActionClick(e, action.value, row )}>{action.label}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div> 
        ); 
    }

    const cancelFinalSaleReport = (adtrId) => {
        var obj ={
            "msgId": null,
            "sysId": null,
            "opr": "CancelFinalSale",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : adtrId,
                "user" : loginUserId,
                "env" : 'I'
            }
          } ;
      
        ReportsService.cancelReport(obj)
        .then((res) => res)
        .then(async (result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("The transaction has been cancelled and an acknowledgement email has been sent.");
                getFinalSaleWorkLoad();
            }
        });
    }

    const handleActionClick = (e, action, row) => {
        const finalSaleId = row.finalSaleId;
        if( action === 'C' ) {
            swal('By clicking "Yes" this action will permanently cancel this Report of Final Sale, its assigned CDIAC #, and the precedent Report of Proposed Debt Issuance. Are you sure you want to permanently cancel this report?', {
                buttons: ["No", "Yes"],
            }).then((value) => {
               if(value){
                cancelFinalSaleReport(finalSaleId);
               }
            });
        } else if( action === 'E') {            
            navigate(`/dashboard/report-final-sale/${finalSaleId}`);
        } else if( action === 'R') {
            alert("Clicked Review");
        } else if( action === 'SH') {
           navigate(`/admin/status-history-upload-documents/${row.cdiacNbr}`);
        } else if( action === 'DW') {
            window.open( `${configManager.debtWatchUrl}/issue-level-detail/${row.cdiacNbr}`);
        }
    }   

    const columns = [{
        dataField: 'cdiacNbr',
        text: 'CDIAC #',
        sort: true
      }, {
        dataField: 'issueStatus',
        text: 'Status',
        sort: true
      }, {
        dataField: 'issueName',
        text: 'Issue Name',
        sort: true
      }, {
        dataField: 'issuerName',
        text: 'Issuer Name',
        sort: true
      }, {
        dataField: 'projectName',
        text: 'Project Name',
        sort: true
      }, {
        dataField: 'actualSaleDate',
        text: 'Actual Sale Date',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['actualSaleDate'])
      },{
        dataField: 'principalSaleAmt',
        text: 'Principal Amount',
        sort: true,
        formatter: (row) => CommonService.currencyFormatter(row['principalSaleAmt'])
      },{
        dataField: 'retiredFlag',
        text: 'Ongoing Reportable',
        sort: true,
        hidden: reportStatus === "view-previous" ? false : true
      },{ 
        dataField: "",
        text: "Action", 
        formatter: viewFormatter,
    }
    
    ]
    return (
        <div>
            <DataGrid
            title='Final Sale Reports'
            dataSource={finalSaleReports}
            sortField='cdiacNbr'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
        </div>
    )
}

export default FinalSaleReports
