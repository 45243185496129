import {useState, useEffect} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal} from 'react-bootstrap'
import CommonService from "../../services/CommonService"
import FinalSaleReportService from "../../services/FinalSaleReportService"
import {AiFillQuestionCircle} from 'react-icons/ai'
import TooltipComponent from '../../common/Tooltip'
const IssuerDetails = (props) => {
    const [issuer, setIssuerDetails] = useState({});

    useEffect(() => {
        var obj = {
          msgId: null,
          sysId: null,
          opr: "getIssuerDetailsByIssuerId",
          hdrStruct: null,
          queryParam: null,
          reqData:{"id" : props.issuanceDetail.issuerId}
        };
    
        FinalSaleReportService.getIssuerDetails(obj)
          .then((result) => {
            setIssuerDetails(result.data.responses[0]);
          });
      }, [props]);

    return (
        <Modal show={true} onHide={props.onModalClose}>
            <Modal.Header closeButton>
                <h1>Issuer Details</h1>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <label>Address Line1</label>
                        <Form.Control type="text" value={issuer.contactAddressLine1} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line2</label>
                        <Form.Control type="text" value={issuer.contactAddressLine2} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line3</label>
                        <Form.Control type="text" value={issuer.contactAddressLine3} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>Address Line4</label>
                        <Form.Control type="text" value={issuer.contactAddressLine4}  readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>City</label>
                        <Form.Control type="text" value={issuer.contactCityName} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>State</label>
                        <Form.Control type="text" value={issuer.contactStateCode} readOnly/>
                    </Col>
                    <Col md={6}>
                        <label>ZipCode</label>
                        <Form.Control type="text" alue={issuer.contactZipCode}  readOnly/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button" onClick={props.onModalClose}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

const Issuance = (props) => {
    const {issuanceDetail}  = props;

    const [showIssuerDetails, setShowIssuerDetails] = useState(false);
    const validateForm = () => {
        props.openWidget('draw');
    }
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    
    return (
        <Card>
            <div className="form">
            <Row>
                <Col md={6}>
                <label>CDIAC Number
                <TooltipComponent
                title="Read-only. Prepopulated from data submitted on the RFS."
                id="dated-date"
                placement="right">
                    <AiFillQuestionCircle className="help-icon" />
                </TooltipComponent>
                </label>
                <Form.Control type="text" value={issuanceDetail.cdiacNumber} disabled/>
                </Col>
            </Row>
            <Row>
            <Col>
                {/* <div style={{float: 'right', marginTop: '10px'}}>
                    <button
                        type="button"
                        className="dk-blue-color link-button" 
                        onClick={()=>setShowIssuerDetails(true)}
                        >View Issuer details</button>
                </div> */}
                <label>Issuer</label>
                <Form.Control type="text" value={issuanceDetail.issuerName} disabled/>
            </Col>
            </Row>
            <Row>
            <Col>
                <label>Issue Name</label>
                <Form.Control type="text" value={issuanceDetail.issueName} disabled/>
            </Col>
            </Row>
            
            <Row>
                <Col>
                <label>Project Name</label>
                <Form.Control type="text" value={issuanceDetail.projectName} disabled/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Actual Sale Date</label>
                    <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.actualSaleDate)} disabled/>
                </Col>
                <Col>
                    <label>Settlement Date</label>
                    <Form.Control type="text" value={CommonService.dateFormatter(issuanceDetail.settlementDate)} disabled/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>Original Principal Amount</label>
                      <Form.Control type="text" value={CommonService.currencyFormatter(issuanceDetail.principalSaleAmt)} disabled/>
                </Col>
                <Col>
                    <label>Date of Filing</label>
                    <Form.Control type="text" value={today} disabled/>
                </Col>
            </Row>

            <div className="btn-div">
            <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Next</button>
            </div>
            </div>
            {showIssuerDetails && <IssuerDetails onModalClose={()=>setShowIssuerDetails(false)} issuanceDetail={issuanceDetail}/>}

        </Card>
    )
}

export default Issuance
