import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditCountyForm from './editCountyForm'
import AddCountyForm from './addCountyForm'
import CountyMaintenanceService from "../../../services/CountyMaintenanceService";
import Spinner from 'react-bootstrap/Spinner';
import CommonService from "../../../services/CommonService";
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const CountyDetails = (props) => {
    const { region } = props;
    const [openEditCodeModal, setOpenModal] = useState(false);
    const [openAddCodeModal, setOpenAddCodeModal] = useState(false);
    const [editCountyInfo, setEditCountyInfo] = useState([])
    const [counties, setCounty] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const viewFormatter = (row) => { 
        return ( 
              <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
           <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
         </div> 
        ); 
    }

    useEffect(() => {
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getCountyRefsByGroupId",
        hdrStruct: null,
        queryParam: null,
        reqData: { "id": props.selectedCounty },
      };
  
      CountyMaintenanceService.getCountyDetails(obj)
        .then((res) => res)
        .then((result) => {
          Object.keys(result.data.responses).forEach((key) => {
              setCounty(result.data.responses[key]);
          });
          setIsLoading(false);
        });
    }, [props, openAddCodeModal, openEditCodeModal]);

    const onRowClick = (e, row) => {
       // e.preventDefault();
        setOpenModal(true);
        setEditCountyInfo(row);
    }

    const columns = [{
        dataField: 'countyCode',
        text: 'County Code',
        sort: true,
        headerStyle: () => {
          return { width: '10%'};
        },
      }, {
        dataField: 'countyName',
        text: 'County Name',
        sort: true
      }, {
        dataField: 'createDatetime',
        text: 'Date Created',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['createDatetime'])
      },{
        dataField: 'lastUpdateDatetime',
        text: 'Last Updated',
        sort: true,
        formatter: (row) => CommonService.dateFormatter(row['lastUpdateDatetime'])
      }, {
        dataField: 'createUser',
        text: 'Created By',
        sort: true,
      },{
        dataField: 'lastUpdateUser',
        text: 'Last Updated By',
        sort: true,
      },{
        dataField: 'activeInd',
        text: 'Active',
        sort: true,
      },{ 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];
    
   
      
    return (
        <>
        {isLoading && <Spinner animation="border" variant="primary" />}
        <div className="form">
          <DataGrid
            title={region}
            dataSource={counties}
            sortField='countyCode'
            addNew={true}
            addNewBtnText="Add New County"
            onAddNew={()=>setOpenAddCodeModal(true)}
          >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                />)
              }) 
            } 
          </DataGrid>
        </div>
        {openEditCodeModal && <EditCountyForm onModalClose={()=>setOpenModal(false)} countyInfo={editCountyInfo} region={props.region}/>}
        {openAddCodeModal && <AddCountyForm onModalClose={()=>setOpenAddCodeModal(false)} region={props.region} selectedCounty ={props.selectedCounty} />}
        </>
    )
}

export default CountyDetails
