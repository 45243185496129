import {useState, useEffect, useContext} from 'react'
import CollapsibleWidget from './collapsibleWidgets'
import FinalSaleReportProgressBar from './finalSaleReportProgressBar'
import {VscExpandAll, VscCollapseAll} from 'react-icons/vsc'
import History from '../../common/History'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { Spinner } from 'react-bootstrap'
import { IssuerContext, UserContext } from '../../UserContext'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import EditReasonHistory from '../../common/EditReasonHistory'
import CommonService from '../../services/CommonService'
import HtmlPdfService from '../../services/HtmlPdfService'
import { renderToString } from 'react-dom/server'
import PrintPdfComponent from './printPdfComponent'
import { AiFillQuestionCircle } from 'react-icons/ai'
import TooltipComponent from '../../common/Tooltip'
import { configManager } from '../../configManager'
const FinalSaleReport = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const finalSaleId = params.finalSaleId;
    const loginUser = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [showHistory, setShowHistory] = useState(false);
    const [showEditReasonHistory, setShowEditReasonHistory] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const [finalSaleDetails, setFinalSaleDetails] = useState({});
    const [finalSaleDependencies, setFinalSaleDependencies] = useState({
        "issueAuthorizations" : [],
        "issueStatutes" : [], 
        "issueContacts" : [], 
        "issueFinancingPurposes" : [],
        "issueRepaymentSources" : [],
        "issueSocEnvImpacts" : [],
        "issuePrivateSaleReasons" : [],
        "issueRefundAssocs": [],
        "issueMkrAuthAssocs" : [],
        "issueDocuments": []
    });
    const [issueComments, setIssueComments] = useState([]);
    const [openSection, setOpenSection] = useState({
        issuance: true,
        dates: false,
        issuanceAuthorization: false,
        statutories: false,
        issuerContact: false,
        filingContact: false,
        credit: false,
        financingParticipants: false,
        typeOfDebt: false,
        interestDebtExempt: false,
        typeOfSale: false,
        sourceOfRepayment: false,
        purposeOfFinancing: false,
        uploadDoc: false,
        review: false
    });

    const [issuanceFees, setIssuanceFees] = useState({});
    const [underwriterFees, setUnderwriterFees] = useState({});

    const updateIssuanceFees = (finalSaleDetail) => {
        setIssuanceFees(prevState => ({
            ...issuanceFees,
            'purchaserFeeAmt': finalSaleDetail.purchaserFeeAmt,
            'lenderFeeAmt': finalSaleDetail.lenderFeeAmt,
            'bondCounselFeeAmt': finalSaleDetail.bondCounselFeeAmt,
            'borrowerCounselFeeAmt':finalSaleDetail.borrowerCounselFeeAmt,
            'cobondCounselFeeAmt': finalSaleDetail.cobondCounselFeeAmt,
            'disclosureCounselFeeAmt': finalSaleDetail.disclosureCounselFeeAmt,
            'financialAdvisorFeeAmt':finalSaleDetail.financialAdvisorFeeAmt,
            'ratingAgencyFeeAmt': finalSaleDetail.ratingAgencyFeeAmt,
            'creditEnhancementFeeAmt': finalSaleDetail.creditEnhancementFeeAmt,
            'trusteeFeeAmt': finalSaleDetail.trusteeFeeAmt,
            'placementAgencyFeeAmt': finalSaleDetail.placementAgencyFeeAmt,
            'otherExpenseFeeAmt': finalSaleDetail.otherExpenseFeeAmt,
        }))
    }

    const updateUnderwriterFees = (finalSaleDetail) => {
        setUnderwriterFees(prevState => ({
            ...underwriterFees,
            'mgmtFeeAmt': finalSaleDetail.mgmtFeeAmt,
            'uwExpenseFeeAmt': finalSaleDetail.uwExpenseFeeAmt,
            'totalTakedownAmt': finalSaleDetail.totalTakedownAmt
        }))
    }

    const getGuaranteeFlagDesc = (finalSaleDetail)  => {
        switch(finalSaleDetail.guaranteeFlag){
            case "I" :
              return "Insurance";
            case "L" :
              return "Letter of Credit";
            case "O" :
              return "Other Enhancement Type";
            case "T" :
              return "State Intercept Program";
            case "N" :
              return "None";
            default:
                return "";
            } 
    }

    const getMaturityStructureDesc = (finalSaleDetail)  => {
        switch(finalSaleDetail.maturityStructureCode){
            case "S" :
              return "Serial";
            case "T" :
              return "Term";
            case "B" :
              return "Serial and Term Bonds";
            default:
                return "";
        } 
    }
    const getFederalLawSelection = (finalSaleDetail) => {
          let federalTaxSelectionArr = finalSaleDetails.federalTaxFlag ? finalSaleDetails.federalTaxFlag.split("") : [];

          let federalLaw = [];
          federalTaxSelectionArr.forEach(val => {
            if( val === 'T') {
              federalLaw.push("Taxable");
            }
            if( val === 'E') {
              federalLaw.push("Tax Exempt, No AMT");
            }
            if( val === 'A') {
              federalLaw.push("Tax Exempt, with AMT");
            }
          });
          federalLaw = federalLaw.join(', ');
          return federalLaw;
    } 

    useEffect(()=>{
        let totalIssuanceFee = 0;
        Object.entries(issuanceFees).forEach(([k, v])=> {
          totalIssuanceFee = totalIssuanceFee + (v !== "" ? v : 0);
        })

        let totalUnderwriterFees = 0;
        Object.entries(underwriterFees).forEach(([k, v])=> {
            totalUnderwriterFees = totalUnderwriterFees + (v !== "" ? v : 0);
        })

        setFinalSaleDetails(prevState => ({
            ...finalSaleDetails,
            "totalAdditionalFees" : totalIssuanceFee,
            "totalUnderwriterFees" : totalUnderwriterFees
        }))
        // eslint-disable-next-line
    },[issuanceFees, underwriterFees])

    useEffect(()=>{
        async function fetchFinalSale() {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getFinalSale",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : finalSaleId  
                }
            };
            await FinalSaleReportService.getFinalSale(obj)
            .then((res) => res)
            .then((result) => {
                let guaranteeFlagDesc = getGuaranteeFlagDesc(result.data.responses[0]);
                let maturityStructureDesc = getMaturityStructureDesc(result.data.responses[0]);
                let federalLawDisplayText = getFederalLawSelection(result.data.responses[0]);
                result.data.responses[0].guaranteeFlagDesc = guaranteeFlagDesc;
                result.data.responses[0].maturityStructureDesc = maturityStructureDesc;
                result.data.responses[0].federalLawDisplayText = federalLawDisplayText;
                setFinalSaleDetails(result.data.responses[0]);
                fetchFinalSaleOtherDetails(result.data.responses[0]);
                updateIssuanceFees(result.data.responses[0]);
                updateUnderwriterFees(result.data.responses[0]);
            });
        }
        async function fetchFinalSaleOtherDetails(finalSaleData) {
            let obj = {
                "msgId": null,
                "sysId": null,
                "opr": "getFinalSaleOtherDetails",
                "hdrStruct": null,
                "queryParam": null,
                "reqData": {
                    "id" : finalSaleId ,
                    "cdiacNbr" : finalSaleData.cdiacNbr 
                }
            };
            await FinalSaleReportService.getFinalSale(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors[0]) {
                    toast(result.data.errors[0].message);
                } else {
                    setFinalSaleDependencies(result.data.responses[0]);
                }
            });
        }
        async function fetchIssueComments () {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getIssueComments",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "issueComment":{
                        "finalSaleId" : finalSaleId 
                    }
                },
                };
            
            await FinalSaleReportService.getIssueComments(obj)
            .then((res) => res)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0 ) {
                    toast.error(result.data.errors[0].message);
                }else {
                    let reasonsArr = [];
                    result.data.responses[0].forEach((item, i) => {
                        item.displayComment = item.comment;
                        if( item.comment.length > 32 ) {
                            item.displayComment = item.comment.substr(0, 32) + "...";
                            item.expanded = false;
                        }
                        
                        reasonsArr.push(item);
                    })
                    setIssueComments(reasonsArr);
                }
            });
        }
        fetchFinalSale();
        fetchIssueComments();
        setTimeout(() => {
            setIsLoading(false);
          }, 5000);
         // eslint-disable-next-line
    },[finalSaleId]);

    const toggleWidget = (sectionName) => {
      setOpenSection({...openSection, [sectionName]: !(openSection[sectionName])})
    }

    const toggleAllWidgets = () => {
        setExpandAll( !(expandAll) );
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: !(expandAll) })));
        setOpenSection(openSecObj);
    }
    const openWidget = (sectionName) => {
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: false })));
        openSecObj[sectionName] = !(openSecObj[sectionName]);
        setOpenSection(openSecObj);
    }
    const updateFinalSaleDetails = (finalSaleDetails) => {
        let guaranteeFlagDesc = getGuaranteeFlagDesc(finalSaleDetails);
        let maturityStructureDesc = getMaturityStructureDesc(finalSaleDetails);
        let federalLawDisplayText = getFederalLawSelection(finalSaleDetails);
        finalSaleDetails.guaranteeFlagDesc = guaranteeFlagDesc;
        finalSaleDetails.maturityStructureDesc = maturityStructureDesc;
        finalSaleDetails.federalLawDisplayText = federalLawDisplayText;
        setFinalSaleDetails(finalSaleDetails);
        updateIssuanceFees(finalSaleDetails);
        updateUnderwriterFees(finalSaleDetails);
    }
    const updateIssueComments = (issueComments) => {
        setIssueComments(issueComments);
    }
    const updateFinalSaleDependencies = (data, type) => {
        setFinalSaleDependencies(prevState => ({
            ...finalSaleDependencies, [type] : data
        }));
    }
    const handlePrint = () => {
        const html = renderToString(<PrintPdfComponent finalSaleDetails={finalSaleDetails} finalSaleDependencies={finalSaleDependencies} issueComments={issueComments}/>)

        var obj = {
            msgId: null,
            sysId: null,
            opr: "getPdf",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "html": html,
                "id" : finalSaleId,
                "cdiacNbr": finalSaleDetails.cdiacNbr,
                "createUser":  CommonService.getUserName(loginUser),   
                "status": 'D',
                "reportType" : "RFS" 
            },
          };

        HtmlPdfService.generatePdf(obj)
        .then((res) => { 
             var base64EncodedPDF = res.data;
             var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
             var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
             var url = window.URL.createObjectURL(blob);
             window.open(url);

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
    }
    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }
    return (
        
        <div>
            <div className="panel panel-default">
                <div className="panel-heading" role="tab"> 
                    <div className="container">
                        <div className="panel-title">
                            <div className="fright" style={{margin: '5px'}}>
                                {Object.keys(finalSaleDetails).length && finalSaleDetails.issueStatusFlag === 'R' && <button className="custom-button me-2" onClick={() => window.open(`${configManager.debtWatchUrl}/issue-level-detail/${finalSaleDetails.cdiacNbr}`)}>View in DebtWatch</button> }
                                {Object.keys(finalSaleDetails).length && finalSaleDetails.issueStatusFlag === 'D' && <button className="custom-button" onClick={handlePrint}>Print PDF (Draft)</button> }
                            </div>
                            <h1 className="main-title">Final Sale Report</h1>
                        </div>
                    </div>
                </div>
                <div className="panel-body" style={{padding: '10px 0'}}>
                    <div className="container">
                        <FinalSaleReportProgressBar openSection={openWidget}/> 
                        <div className="center-align">
                            <div style={{float: 'left'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard</button>
                            </div>
                            <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>toggleAllWidgets()}>{expandAll ? <VscCollapseAll /> : <VscExpandAll /> } {expandAll ? "Collapse All" : "Expand All" }</button>
                            <div style={{float: 'right'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>setShowHistory(true)}>Show History</button>
                                {(finalSaleDetails.issueStatusFlag === "E" || finalSaleDetails.issueStatusFlag === "R") &&
                                <><br />
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>setShowEditReasonHistory(true)}>Show Edit Reason History</button>
                                </>}
                                </div>
                        </div><br/>
                        <div>
                            <p>CDIAC Number:
                            <TooltipComponent
                            title="Prepopulated – No edits allowed."
                            id="dated-date"
                            placement="right">
                                <AiFillQuestionCircle className="help-icon" />
                            </TooltipComponent>
                                  ( {finalSaleDetails.cdiacNbr} )</p>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <Spinner animation="border" variant="primary"/> :
            <IssuerContext.Provider value={finalSaleDetails.issuerId && finalSaleDetails.issuerId}>
            <CollapsibleWidget openSectionObj={openSection} 
            toggleWidget={toggleWidget} 
            openSection={openWidget}
            finalSaleDetails={finalSaleDetails}
            updateFinalSaleDetails={updateFinalSaleDetails}
            finalSaleDependencies={finalSaleDependencies}
            updateFinalSaleDependencies={updateFinalSaleDependencies}
            issueComments={issueComments}
            updateIssueComments={updateIssueComments}/>
            <div className="panel panel-default">
                <div className="panel-heading"> 
                    <div className="container">
                        <div className="panel-body" style={{padding: '10px 0 0'}} >
                        <button className="custom-button" onClick={()=>backToDashboard()}>Back to Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
            </IssuerContext.Provider>

            }
            {showHistory && <History onModalClose={()=>setShowHistory(false)} issueId={finalSaleDetails.finalSaleId} reportTypeId="final_sale_id"/>}
            {showEditReasonHistory && <EditReasonHistory onModalClose={()=>setShowEditReasonHistory(false)} issueId={finalSaleDetails.finalSaleId} cdiacCodeValueCode="RFSER"/>}
        </div>
    )
}

export default FinalSaleReport
