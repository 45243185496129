import {useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import EditContact from './editContact'
import DataGrid from '../../GenericComponents/DataGrid';
import { Column } from 'primereact/column';

const SearchResultContainer = (props) => {
    const [openEditContactModal, setOpenModal] = useState(false);
    const [openAddCodeModal, setOpenAddCodeModal] = useState(false);
    const [editContact, setEditContact] = useState([])
    const viewFormatter = (row) => { 
        return ( 
              <div 
                  style={{ textAlign: "center",
                    lineHeight: "normal" }}>
           <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" style={{ fontSize: 'medium'}} onClick={(e)=>onRowClick(e, row)} />
         </div> 
        ); 
    }

    const onRowClick = (e, row) => {
       // e.preventDefault();
        setOpenModal(true);
        setEditContact(row);
    }

    const columns = [{
        dataField: 'fname',
        text: 'First Name',
        sort: true
      },{
        dataField: 'lname',
        text: 'Last Name',
        sort: true
      },{
        dataField: 'createdDate',
        text: 'Date Created',
        sort: true
      },{
        dataField: 'lastUpdatedDate',
        text: 'Last Updated',
        sort: true
      },{
        dataField: 'isActive',
        text: 'Active',
        sort: true
      },{ 
        dataField: "",
        text: "", 
        formatter: viewFormatter,}
    ];
    
        //"GAN":"Grant anticipation note",
   const financingPurpose = [{
            'fname': 'John',
            'lname': 'Doe',
            // 'email':'John.Doe@qualapps.com',
            'createdDate':'06/05/2021',
            'lastUpdatedDate':'08/15/2021',
            // 'createdBy':'John',
            // 'updatedBy':'John',
            'isActive': 'Yes'
        },
        {
            'fname': 'Darrell',
            'lname': 'Steinberg',
            // 'email':'Darrell.Steinberg@qualapps.com',
            'createdDate':'06/05/2021',
            'lastUpdatedDate':'08/15/2021',
            // 'createdBy':'Sam',
            // 'updatedBy':'Sam',
            'isActive': 'No'
        }
      ]
      
    return (
        <>
        <div className="form">
            <h1>Issuer Name</h1>
            <DataGrid
            dataSource={financingPurpose}
            sortField='cdiacNbr'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            addNew={true}
            addNewBtnText="Add New Contact"
            onAddNew={()=>setOpenAddCodeModal(true)}
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
        </div>
        {openEditContactModal && <EditContact onModalClose={()=>setOpenModal(false)} contact={editContact}/>}
        {openAddCodeModal && <EditContact onModalClose={()=>setOpenAddCodeModal(false)} formType="Add"/>}
        </>
    )
}

export default SearchResultContainer
