import { Row, Col } from 'react-bootstrap'
import {useState, useEffect} from 'react'
import Card  from '../../ui/Card'
import SearchResultContainer from './searchResultContainer'
import { useForm } from 'react-hook-form';
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Spinner from 'react-bootstrap/Spinner'
import IssuerService from '../../../services/IssuerService';
import {FaCalendarAlt} from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ADTRBatch = (props) => {
    const { handleSubmit} = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [adtrBatchUploads, setAdtrBatchUploads] = useState([]);
    const [showAdtrBatchUploads, setShowAdtrBatchUploads] = useState(false);
    const [stateValues, setStateValues] = useState({
      uploadStartDate: null,
      uploadEndDate: null
    });

    const handleDateChange = (e, key) => {
      setStateValues(prevState=>({
          ...prevState, 
          [key]: e
      })
      )
    }

    const onSubmit = (data) => {
      getADTRBatchUploads()
    }

    async function getADTRBatchUploads() {
      setIsLoading(true);
      let obj = {
        "msgId": null,
        "sysId": null,
        "opr": "getADTRBatchUploads",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": {
          "searchParams" : {
            "uploadStartDate": stateValues.uploadStartDate,
            "uploadEndDate": stateValues.uploadEndDate
          }
        }
      };
    
      await IssuerService.getIssuersByUserId(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses.length > 0 && result.data.responses !== null) {
              setAdtrBatchUploads(result.data.responses[0]);
            }
            setShowAdtrBatchUploads(true);
            setIsLoading(false);
      });
    }
         
    useEffect(()=>{
      getADTRBatchUploads();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <div className="panel panel-default">
              <div className="panel-heading">
                <div className="container">
                    <div id="packet_id" className="panel-title" >
                        <h1 className="menu-title">Process ADTR Batch</h1>
                    </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="container">
                  <div className="form">
                  {isLoading && <Spinner animation="border" variant="primary" />}
                  <Row>
                    <Col md={6}>
                        <div className="control">
                            <label>Upload Start Date: </label>
                            <div>
                                <span>
                                <DatePicker 
                                selected={stateValues.uploadStartDate ? new Date(stateValues.uploadStartDate) : null}
                                dateFormat="M/d/yyyy"
                                maxDate= {new Date(9999, 12, 31)}
                                name="uploadStartDate"
                                onChange={(e) => handleDateChange(e, 'uploadStartDate')} 
                                />
                                <FaCalendarAlt className="date-picker-icon"/>
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="control">
                            <label>Upload End Date: </label>
                            <div>
                                <span>
                                <DatePicker 
                                selected={stateValues.uploadEndDate ? new Date(stateValues.uploadEndDate) : null}
                                dateFormat="M/d/yyyy"
                                maxDate= {new Date(9999, 12, 31)}
                                name="uploadEndDate"
                                onChange={(e) => handleDateChange(e, 'uploadEndDate')} 
                                />
                                <FaCalendarAlt className="date-picker-icon"/>
                                </span>
                            </div>
                        </div>
                    </Col>
                  </Row>
                  
                  <div>
                    <button style={{float: 'right'}} className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Search</button>
                  </div>
                  </div>
                </div>
              </div>
          </div>
          {showAdtrBatchUploads && <SearchResultContainer data={adtrBatchUploads} onRefresh={getADTRBatchUploads}/>}         
           
        </Card>
        </form>
    )
}

export default ADTRBatch
