import {useState, useEffect, useContext, Fragment} from 'react'
import Card from '../ui/Card'
import {Row, Col, Form, Modal, Spinner} from 'react-bootstrap'
import CommonService from '../../services/CommonService'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { UserContext } from '../../UserContext'
import CurrencyInput from '../formElements/CurrencyInput'
import { FormInput } from '../formElements/FormInput'
import AdtrService from '../../services/AdtrService'
import ReadOnlyRow from '../formElements/ReadOnlyRow'
import EditableRow from '../formElements/EditableRow'
import { toast } from 'react-toastify'
import { validateFormFields } from '../../utils'
import {AiFillQuestionCircle} from 'react-icons/ai'
import IssueCdiacNumberService from '../../services/IssueCdiacNumberService'
import TooltipComponent from '../../common/Tooltip'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const ProceedsTable = (props) => {
    const {onModalClose, linkClicked, finalSaleId} = props;
    const [proceeds, setProceeds] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const functionName = (linkClicked === "MLRProceeds" ? "getMLRProceeds" : "getMKRProceeds");
    useEffect(() => {
        async function getProceeds () {
            var obj = {
            msgId: null,
            sysId: null,
            opr: functionName,
            hdrStruct: null,
            queryParam: null,
            reqData:{"id" : finalSaleId}
            };
        
            await AdtrService.getInfo(obj)
            .then((result) => {
                setProceeds(result.data.responses[0]);
                setIsLoading(false);
            });
        }

       getProceeds();
      }, [functionName, finalSaleId]);

    const proceedsColumns = [
        {
            dataField: 'mkrSource',
            text: 'Source',
            hidden: linkClicked === "MKRProceeds" ? false : true
        },
        {
            dataField: 'fiscalYrEndDate',
            text: 'Fiscal Year End Date',
            formatter: (row) => CommonService.dateFormatter(row['fiscalYrEndDate'])
        },{
            dataField: 'capitalizedIntFundAmt',
            text: 'Capitalized Interest Fund Amount',
            formatter: (row) => CommonService.currencyFormatter(row['capitalizedIntFundAmt'])
        },{
            dataField: 'constructionFundAmt',
            text: 'Construction Fund balance',
            formatter: (row) => CommonService.currencyFormatter(row['constructionFundAmt']),
            hidden: linkClicked === "MLRProceeds" ? false : true
        },{
            dataField: 'principalOutstdgAmt',
            text: 'Principal Outstanding Amount',
            formatter: (row) => CommonService.currencyFormatter(row['principalOutstdgAmt'])
        }
    ]
    return (
        <Modal show={true} onHide={onModalClose} backdrop="static" keyboard={false}  size="lg">
            <Modal.Header closeButton>
                <h3>{linkClicked==="MLRProceeds" ? 
                "Proceeds from Mello-Roos Last Submission" :
                "Proceeds from Marks-Roos Last Submission"}</h3></Modal.Header>
            <Modal.Body>
            {isLoading ? 
            <Spinner animation="border" variant="primary" /> :
            <DataGrid
            dataSource={proceeds}
            sortField='fiscalYrEndDate'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            showPaginator={false}
            showQuickSearch={false}
            >
            {proceedsColumns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
            }
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button custom-button-secondary" onClick={()=>onModalClose()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
const UseOfProceeds = (props) => {
    const { adtrDependenciesData, adtrId, updateAdtrDependencies} = props;
    const isAdtrFirstYear = adtrDependenciesData.isAdtrFirstYear;
    const adtrInfo = adtrDependenciesData.adtrInfo;
    const issuanceDetail = adtrDependenciesData.issuanceDetails;
    const [fiscalYrProceedsSummary, setFiscalYrProceedsSummary] = useState(adtrDependenciesData.fiscalYrProceedsSummary);
    const [proceedsSummary, setProceedsSummary] = useState(adtrDependenciesData.proceedsSummary);
    const [expenditureSummary, setExpenditureSummary] = useState(adtrDependenciesData.expenditureSummary);
    const [currentPeriodexpenditures, setCurrentPeriodExpenditures] = useState(adtrDependenciesData.currentPeriodexpenditures);
    const [proceedsFundCategories, setProceedsFundCategories] = useState(null);
    const [showOtherField, setShowOtherField] = useState(false);
    const [expenditureFundCategories, setExpenditureFundCategories] = useState(null);
    const [editProceedFundInd, setEditProceedFundInd] = useState(null);
    const [editProceedData, setEditProceedData] = useState({});
    const [editExpenditureInd, setEditExpenditureInd] = useState(null);
    const [editExpenditureData, setEditExpenditureData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [showMLRProceeds, setShowMLRProceeds] = useState(false);
    const [showMKRProceeds, setShowMKRProceeds] = useState(false);
    const [issueRefundedAssocs, setIssueRefundedAssocs] = useState(adtrDependenciesData.issueRefundingAssocs);
    const [refundedAmtTotal, setRefundedAmtTotal] = useState(0);
    const [showRefundIssueHelpText, setShowRefundIssueHelpText ] = useState(false) ;
    const [editInd, setEditInd] = useState(null);
    const [editData, setEditData] = useState({});
    const [fiscalYrStartDate, setFiscalYrStartDate] = useState(null);
    const user = useContext(UserContext);
    
    const[stateValues, setStateValues] = useState({
        'fundCategoryText' : '',
        'proceedAvlAmt' : '',
        'otherFundCategory' : '',
        'purposeDesc' : '',
        'currSpentAmt': '',
        'adtrProceedId': ''
    })

    async function getExpenditureFundCategories() {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getExpendituresFundCategories",
            hdrStruct: null,
            queryParam: null,
            reqData:{"adtrId" : adtrId}
            };
        await FinalSaleReportService.getAdtrInfo(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.log(result.data.errors[0].message);
                } else {
                    setExpenditureFundCategories(result.data.responses[0]);
                }
                
            });
    }

    useEffect(()=>{
        const fiscalYear = CommonService.getFullYear(adtrInfo.fiscalYrEndDate)
        let today = new Date()
        today.setMonth(6)
        today.setDate(1)
        today.setFullYear(fiscalYear - 1)
        const fiscalYearStartDate = today
        issueRefundedAssocs && issueRefundedAssocs.forEach(item => {
            if(!item.redeemDate || (new Date(item.redeemDate) > fiscalYearStartDate && new Date(item.redeemDate) < new Date(adtrInfo.fiscalYrEndDate) && adtrInfo.statusFlag !== 'R')){
                item.isRowEditable = true
            } else {
                item.isRowEditable = false
            }
        });
        const refundedAmtTotal = issueRefundedAssocs.reduce((total, item) => total + (item.redeemAmt && item.redeemAmt !== "" ? item.redeemAmt : 0), 0)
        setRefundedAmtTotal(refundedAmtTotal)
        setIssueRefundedAssocs(issueRefundedAssocs)
        setFiscalYrStartDate(fiscalYearStartDate)
        // eslint-disable-next-line
    },[]);

    useEffect(() => {
        async function getProceedsFundCategories() {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getProceedsFundCategories",
                hdrStruct: null,
                queryParam: null,
                reqData:{"adtrId" : adtrId}
                };
            await FinalSaleReportService.getAdtrInfo(obj)
                .then((result) => {
                    if(result.data.errors && result.data.errors.length > 0) {
                        console.log(result.data.errors[0].message);
                    } else {
                        setProceedsFundCategories(result.data.responses[0])
                    }
                    
                });
        }  
        
        getProceedsFundCategories();
        getExpenditureFundCategories();
        // eslint-disable-next-line
    }, [adtrId]);

    const getUseOfProceedsSummary = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getUseOfProceedsSummary",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "adtr" : {
                    "finalSaleId" : adtrInfo.finalSaleId,
                    "fiscalYrEndDate" : adtrInfo.fiscalYrEndDate
                }
            }
        };
        await AdtrService.getUseOfProceedsSummary(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.log(result.data.errors[0].message);
                } else {
                    setFiscalYrProceedsSummary(result.data.responses[0].fiscalYrProceedsSummary);
                    setProceedsSummary(result.data.responses[0].proceedsSummary);
                    setExpenditureSummary(result.data.responses[0].expenditureSummary);
                    setCurrentPeriodExpenditures(result.data.responses[0].currentPeriodexpenditures);
                    updateAdtrDependencies(result.data.responses[0], 'useOfProceeds')
                }
                
            });
    }
    const validateForm = async() => {
        if(isAdtrFirstYear){
            await validateTotalReportableProceedsAvl();
        }
        props.openWidget('filingContact');
    }
    const validateTotalReportableProceedsAvl = () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "validateTotalReportableProceedsAvl",
            hdrStruct: null,
            queryParam: null,
            reqData:{"adtrId" : adtrId}
            };

        AdtrService.validateTotalReportableProceedsAvl(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    toast.error(result.data.errors[0].message);
                }
            });
    }

    const handleChange = (e) => {
        const{name,value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name] : value
        }));
        if(name === "fundCategoryText"){
            if( value === "Other") {
                setShowOtherField(true);
            } else {
                setShowOtherField(false);
            }
        }
    }
    const handleAddFund = async() => {        
        var obj = {
            msgId: null,
            sysId: null,
            opr: "addAdtrProceed",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "adtrProceed" : {
                    "adtrId" : adtrId,
                    "proceedAvlAmt" : stateValues.proceedAvlAmt,
                    "fundCategoryText" : stateValues.fundCategoryText !== "Other" ? stateValues.fundCategoryText : stateValues.otherFundCategory,
                    "createUser" : CommonService.getUserName(user),
                    "lastUpdateUser" : CommonService.getUserName(user)
                }
            }
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    const newStateValues = {
                        'fundCategoryText' : '',
                        'proceedAvlAmt' : '',
                        'otherFundCategory' : ''
                    }
                    setStateValues(newStateValues);
                    getUseOfProceedsSummary();
                    getExpenditureFundCategories();
                }
                
            });
    }
    const handleAddExpenditure = async(e) => {  
        const form = e.target.closest('form#add-expenditure');
        let formErrors = validateFormFields(form.elements);
        
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({}); 
            var obj = {
                msgId: null,
                sysId: null,
                opr: "addAdtrProceedExpenditure",
                hdrStruct: null,
                queryParam: null,
                reqData:{
                    "adtrProceedExpenditure" : {
                        "adtrProceedId" : stateValues.adtrProceedId,
                        "spentAmt" : stateValues.currSpentAmt,
                        "purposeDesc" : stateValues.purposeDesc,
                        "createUser" : CommonService.getUserName(user),
                        "lastUpdateUser" : CommonService.getUserName(user)
                    }
                }
            };
            await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    const newStateValues = {
                        'adtrProceedId' : '',
                        'currSpentAmt' : '',
                        'purposeDesc' : ''
                    }
                    setStateValues(newStateValues);
                    getUseOfProceedsSummary();
                }
                
            });
        }
    }
    const handleProccedEditClick = (event, i, item) => {
        event.preventDefault();
        setEditProceedFundInd(i);
        setEditProceedData(item);
    }
    const handleProceedFundChange = (e, i) => {
        e.preventDefault();
        const {name, value} = e.target;
        let newEditProceedData = {...editProceedData};
        newEditProceedData[name] = value;
        setEditProceedData(newEditProceedData);
    }

    const handleProceedFundUpdate = async (event) => {
        event.preventDefault();

        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateAdtrProceed",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "adtrProceed" : {
                    "fundCategoryText" : editProceedData.fundCategory,
                    "adtrId" : adtrId,
                    "proceedAvlAmt" : editProceedData.proceedAvlAmt,
                    "LastUpdateUser" : CommonService.getUserName(user)
                }
            }
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.log(result.data.errors[0].message);
                } else {
                    getUseOfProceedsSummary();
                    setEditProceedFundInd(null);
                }
                
            });
    }
    const handleProceedCancelClick = () => {
        setEditProceedFundInd(null);
    }

    const handleExpenditureEditClick = (event, i, item) => {
        event.preventDefault();
        setEditExpenditureInd(i);
        setEditExpenditureData(item);
    }
    const handleExpenditureChange = (e, i) => {
        e.preventDefault();

        const {name, value} = e.target;
        let newEditExpenditureData = {...editExpenditureData};
        newEditExpenditureData[name] = value;
        setEditExpenditureData(newEditExpenditureData);
    }

    const handleExpenditureUpdate = async (event) => {
        event.preventDefault();

        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateAdtrProceedExpenditure",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "adtrProceedExpenditure" : {
                    "adtrProceedExpenditureId" : editExpenditureData.adtrProceedExpenseId,
                    "purposeDesc" : editExpenditureData.purposeDesc,
                    "spentAmt" : editExpenditureData.currSpentAmt,
                    "LastUpdateUser" : CommonService.getUserName(user)
                }
            }
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    getUseOfProceedsSummary();
                    setEditExpenditureInd(null);
                }
                
            });
    }
    const handleExpenditureCancelClick = () => {
        setEditExpenditureInd(null);
    }
    const handleProceedDelete = async (event, proceed) => {
        event.preventDefault();

        var obj = {
            msgId: null,
            sysId: null,
            opr: "deleteAdtrProceed",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                "fundCategoryText" : proceed.fundCategory,
                "adtrId" : adtrId,
            }
            
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    getUseOfProceedsSummary();
                }
                
            });
    }
    const handleExpenditureDelete = async (event, expenditure) => {
        event.preventDefault();

        var obj = {
            msgId: null,
            sysId: null,
            opr: "deleteAdtrProceedExpenditure",
            hdrStruct: null,
            queryParam: null,
            reqData:{
                    "adtrProceedExpenseId" : expenditure.adtrProceedExpenseId,
            }
        };
        await AdtrService.saveAdtrProceed(obj)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0) {
                    console.error(result.data.errors[0].message);
                } else {
                    getUseOfProceedsSummary();
                }
                
            });
    }
    const handleMLRProceedsClick = () => {
        setShowMLRProceeds(true);
    }
    const handleMKRProceedsClick = () => {
        setShowMKRProceeds(true);
    }
    const handleSave = (e) => {
        e.preventDefault()
        updateIssueRefundAssoc(editData);
    }

    const handleRefundDateChange = (e, name) => {
        let newEditData = {...editData};
        newEditData[name] = e;
        setEditData(newEditData);
    }

    const updateIssueRefundAssoc = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "putIssueRefundAssoc",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "id" : editData.issueRefundId,
                "issueRefundAssoc" : {...editData, 'lastUpdateUser' : user}
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            }
            getIssueRefundedAssocs()
            setEditInd(null)
        });
    }

    const getIssueRefundedAssocs = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueRefundAssocByCdiacNbr",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "cdiacNbr" :  adtrInfo.cdiacNbr
            }
        };
    
      await IssueCdiacNumberService.saveIssueRefundAssoc(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.log(result.data.errors[0].message)
            } else {
                setIssueRefundedAssocs(result.data.responses[0])
                updateAdtrDependencies(result.data.responses[0], 'issueRefundingAssocs')
            }
        });
    }

    const handleCancelClick = () => {
        setEditInd(null);
    }
    const handleEditClick = (event, i, item) => {
        event.preventDefault();
        setEditInd(i);
        setEditData(item);
    }
    return (
        <Card>
            <div className="form">
                <div>
                    <p><b>Reportable Proceeds</b></p>
                    <div className="container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Reporting Period End Date</th>
                                <th>Beginning Amount</th>
                                <th>Spent Amount</th>
                                <th>Remaining Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(fiscalYrProceedsSummary && fiscalYrProceedsSummary.length > 0) ? fiscalYrProceedsSummary.map((item, i)=>(
                                <Fragment>
                                    <ReadOnlyRow 
                                    key={i}
                                    data={item} 
                                    isActionsRequired={false}
                                    columns={[
                                        {
                                        prop: "fiscalYrEndDate",
                                        fieldType: "date"
                                        },
                                        {
                                        prop: "proceedAvailableAmt",
                                        fieldType: "currency",
                                        },
                                        {
                                        prop: "proceedSpentAmt",
                                        fieldType: "currency"
                                        },
                                        {
                                        prop: "",
                                        fieldType: "formatted",
                                        value: CommonService.currencyFormatter(item.proceedAvailableAmt - item.proceedSpentAmt)
                                        }
                                    ]}
                                    />
                                </Fragment>
                            )) :
                            <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            
                            </tbody>
                        </table>
                        </form>
                    </div>
                </div>
                <div>
                    <p><b>Proceeds Spent/Unspent (Fund Level)</b></p>
                    { isAdtrFirstYear && <>
                    <Row style={{margin: '0.5px'}}>
                        <Col md={6}>
                        <div>
                            <label>Fund Category: </label>
                            <Form.Select as="select" name="fundCategoryText" onChange={(e)=>handleChange(e)} value={stateValues.fundCategoryText}>
                                    <option>select</option>
                                    {proceedsFundCategories && proceedsFundCategories.map((item, i) =>{
                                        return (<option key={i} value={item.FundCategoryDesc} >{item.FundCategoryDesc}</option>);
                                    }
                                    ) }
                            </Form.Select>
                        </div>
                        </Col>
                        < Col md={6}>
                            <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Total Reportable Proceeds Available" 
                            name="proceedAvlAmt" 
                            type="text" 
                            value={stateValues.proceedAvlAmt}
                            onChange={(e)=>handleChange(e)}
                            validations={["required", "max:999999999999.99"]}
                            />
                            </div>
                        </Col>
                        {showOtherField && <Col md={6}>
                            <FormInput label="Other Fund Category Name" 
                            name="otherFundCategory" 
                            type="text"
                            value={stateValues.otherFundCategory} 
                            onChange={(e)=>handleChange(e)} 
                            />
                        </Col>}
                    </Row>
                    <div style={{margin: '0.5em'}}><button type="button" className="custom-button" onClick={()=>handleAddFund()}>Add Fund</button></div>
                    </>
                    }
                    {/* { isAdtrFirstYear ? ( */}
                    <div className="app-container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Fund Category</th>
                                <th>Total Reportable Proceeds Available</th>
                                <th>Proceeds Spent Current Reporting Period</th>
                                <th>Proceeds Spent Prior Reporting Period</th>
                                <th>Proceeds Unspent / Remaining</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {(proceedsSummary && proceedsSummary.length > 0) ? proceedsSummary.map((item, i)=>(
                                <Fragment>
                                    {editProceedFundInd === i ? (
                                        <EditableRow 
                                        data={editProceedData}
                                        handleChange={(e)=>handleProceedFundChange(e, i)}
                                        handleCancelClick={()=>handleProceedCancelClick()}
                                        handleUpdate={(e)=>handleProceedFundUpdate(e, editProceedData )}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text",
                                            editable: false
                                            },
                                            {
                                            prop: "proceedAvlAmt",
                                            fieldType: "currency",
                                            editable: true
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency",
                                            editable: false
                                            },
                                            {
                                            prop: "prevSpentAmt",
                                            fieldType: "currency",
                                            editable: false
                                            },
                                            {
                                            prop: "balance",
                                            fieldType: "currency",
                                            editable: false
                                            }
                                        ]}
                                        />
                                    ) : (
                                        <ReadOnlyRow 
                                        key={i}
                                        data={item} 
                                        handleEditClick={(e)=>handleProccedEditClick(e, i, item) } 
                                        handleDeleteClick={(e)=>handleProceedDelete(e, item)}
                                        isActionsRequired={true}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text"
                                            },
                                            {
                                            prop: "proceedAvlAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "prevSpentAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "balance",
                                            fieldType: "currency"
                                            }
                                        ]}
                                        />
                                    )}
                                    </Fragment>
                            )):
                            <tr><td colSpan="5" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr>
                                <td><b>Total:</b></td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.proceedAvlAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.prevSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.balance), 0))}</td><td></td>
                            </tr>
                            </tbody>
                        </table>
                        </form>
                    </div>
                    {/* // ) : (
                    // <form>
                    //     <table className="table table-bordered">
                    //         <thead>
                    //             <tr>
                    //             <th>Fund Category</th>
                    //             <th>Total Reportable Proceeds Available</th>
                    //             <th>Proceeds Spent Current Reporting Period</th>
                    //             <th>Proceeds Spent Prior Reporting Period</th>
                    //             <th>Proceeds Unspent / Remaining</th>
                    //             </tr>
                    //         </thead>
                    //         <tbody>
                    //         {(proceedsSummary && proceedsSummary.length > 0) ? proceedsSummary.map((item, i)=>(
                    //             <Fragment>
                    //                 {
                    //                     <ReadOnlyRow 
                    //                     key={i}
                    //                     data={item} 
                    //                     isActionsRequired={false}
                    //                     columns={[
                    //                         {
                    //                         prop: "fundCategory",
                    //                         fieldType: "text"
                    //                         },
                    //                         {
                    //                         prop: "proceedAvlAmt",
                    //                         fieldType: "currency",
                    //                         },
                    //                         {
                    //                         prop: "currSpentAmt",
                    //                         fieldType: "currency"
                    //                         },
                    //                         {
                    //                         prop: "prevSpentAmt",
                    //                         fieldType: "currency"
                    //                         },
                    //                         {
                    //                         prop: "balance",
                    //                         fieldType: "currency"
                    //                         }
                    //                     ]}
                    //                     />
                    //                 }
                    //             </Fragment>
                    //         )) :
                    //         <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display.</td></tr>
                    //         }
                    //         <tr>
                    //             <td><b>Total:</b></td>
                    //             <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.proceedAvlAmt), 0))}</td>
                    //             <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                    //             <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.prevSpentAmt), 0))}</td>
                    //             <td>{CommonService.currencyFormatter(proceedsSummary && proceedsSummary.reduce((a, v) => a + (v.balance), 0))}</td>
                    //         </tr>
                    //         </tbody>
                    //     </table>
                    //     </form>
                    // ) */}
                    {/* } */}
                </div>
                <hr />
                <div>
                    <p><b>Expenditure of Proceeds</b></p>
                    <form id="add-expenditure">
                    <Row style={{margin: '0.5px'}}>
                        <Col md={6}>
                        <div>
                            <label>Fund Category: </label>
                            <Form.Select as="select" name="adtrProceedId" onChange={(e)=>handleChange(e)} value={stateValues.adtrProceedId} data-validations="required">
                                    <option value="">select</option>
                                    {expenditureFundCategories && expenditureFundCategories.map((item, i) =>{
                                        return (<option key={i} value={item.AdtrProceedId} >{item.FundCategoryText}</option>);
                                    }
                                    ) }
                            </Form.Select>
                        </div>
                        {formErrors['adtrProceedId'] && <p className="error">Please Select Fund Category.</p>}

                        </Col>
                        <Col md={6}>
                            <FormInput label="Purpose" 
                            name="purposeDesc" 
                            type="text"
                            value={stateValues.purposeDesc} 
                            onChange={(e)=>handleChange(e)} 
                            validations={["required"]}
                            error={formErrors.purposeDesc}
                            />
                        </Col>
                        < Col md={6}>
                            <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Expenditure in the current reporting period" 
                            name="currSpentAmt" 
                            type="text" 
                            value={stateValues.currSpentAmt}
                            onChange={(e)=>handleChange(e)}
                            validations={["required", "max:999999999999.99"]}
                            data-validations="required"
                            error={formErrors.currSpentAmt}
                            />
                            </div>
                        </Col>
                        
                    </Row>
                    <div style={{margin: '0.5em'}}><button type="button" className="custom-button" onClick={(e)=>handleAddExpenditure(e)}>Add Expenditure</button></div>
                    </form>

                    <div>
                        <p><b>Expenditures of Current Reporting Period:</b></p>
                        <div className="container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Fund Category</th>
                                <th>Purpose</th>
                                <th>Expenditure In Current Reporting Period</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {(currentPeriodexpenditures && currentPeriodexpenditures.length > 0) ? currentPeriodexpenditures.map((item, i)=>(
                                <Fragment>
                                    {editExpenditureInd === i ? (
                                        <EditableRow 
                                        key={i}
                                        data={editExpenditureData}
                                        handleChange={(e)=>handleExpenditureChange(e, i)}
                                        handleCancelClick={()=>handleExpenditureCancelClick()}
                                        handleUpdate={(e)=>handleExpenditureUpdate(e, editProceedData )}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text",
                                            editable: false
                                            },
                                            {
                                            prop: "purposeDesc",
                                            fieldType: "text",
                                            editable: true
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency",
                                            editable: true
                                            }
                                        ]}
                                        />
                                    ) : (
                                        <ReadOnlyRow 
                                        key={i}
                                        data={item} 
                                        handleEditClick={(e)=>handleExpenditureEditClick(e, i, item) } 
                                        handleDeleteClick={handleExpenditureDelete}
                                        isActionsRequired={true}
                                        columns={[
                                            {
                                            prop: "fundCategory",
                                            fieldType: "text"
                                            },
                                            {
                                            prop: "purposeDesc",
                                            fieldType: "text",
                                            },
                                            {
                                            prop: "currSpentAmt",
                                            fieldType: "currency"
                                            }
                                        ]}
                                        />
                                    )}
                                </Fragment>
                            )) :
                            <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr>
                                <td><b>Total:</b></td>
                                <td></td>
                                <td>{CommonService.currencyFormatter(currentPeriodexpenditures && currentPeriodexpenditures.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        </form>
                        </div>
                    </div>
                    <div>
                        <p><b>Expenditure Summary:</b></p>
                        <div className="container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>Fund Category</th>
                                <th>Purpose</th>
                                <th>Expenditure In Current Reporting Period</th>
                                <th>Expenditure in Previous Reporting Periods</th>
                                <th>Total Expenditure All Periods</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(expenditureSummary && expenditureSummary.length > 0) ? expenditureSummary.map((item, i)=>(
                                <Fragment>
                                    <ReadOnlyRow 
                                    key={i}
                                    data={item} 
                                    isActionsRequired={false}
                                    columns={[
                                        {
                                        prop: "fundCategory",
                                        fieldType: "text"
                                        },
                                        {
                                        prop: "purposeDesc",
                                        fieldType: "text",
                                        },
                                        {
                                        prop: "currSpentAmt",
                                        fieldType: "currency"
                                        },
                                        {
                                        prop: "prevSpentAmt",
                                        fieldType: "currency"
                                        },
                                        {
                                        prop: "",
                                        fieldType: "formatted",
                                        value: CommonService.currencyFormatter(item.currSpentAmt + item.prevSpentAmt)
                                        }
                                    ]}
                                    />
                                </Fragment>
                            )) :
                            <tr><td colSpan="5" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr>
                                <td><b>Total:</b></td>
                                <td></td>
                                <td>{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.currSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.prevSpentAmt), 0))}</td>
                                <td>{CommonService.currencyFormatter(expenditureSummary && expenditureSummary.reduce((a, v) => a + (v.currSpentAmt+v.prevSpentAmt), 0))}</td>
                            </tr>
                            </tbody>
                        </table>
                        </form>
                        </div>
                    </div>
                </div>
                <div className="control">
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}}  onClick={()=>handleMLRProceedsClick()}>View Proceeds from Mello-Roos Last Submission</button><br />
                    <button type="button" className="link-button dk-blue-color" style={{textDecoration: 'none', fontWeight: '500'}} onClick={()=>handleMKRProceedsClick()}>View Proceeds from Marks-Roos Last Submission</button>
                </div>
                <div>
                    <Row>
                        < Col md={6}>
                        <div className="control">
                            <CurrencyInput 
                            placeholder="0.00" 
                            label="Total cost of issuance from Report of Final Sale" 
                            name="reserveMinBalAmt" 
                            type="text" 
                            value={issuanceDetail.totalIssuanceAmt}
                            validations={["required", "max:999999999999.99"]}
                            disabled readOnly
                            />
                        </div>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row>
                    <p><b>Refunded/Refinanced Issues </b>
                        <AiFillQuestionCircle onClick={()=>setShowRefundIssueHelpText(true)} className="help-icon heading-help-icon"/>
                        {showRefundIssueHelpText && 
                        <Modal show={true} backdrop="static" onHide={()=>setShowRefundIssueHelpText(false)}>
                            <Modal.Body>
                                <div>
                                The issue(s) listed in the table below were reported previously to CDIAC as refunded, refinanced, or 
                                paid-down all or in part by the proceeds of the issue subject to this report. This data is populated from 
                                data on the RFS(s) for the issue subject to this report. It is provided as a reference for completion of the 
                                Expenditures in the Current Reporting Period. A refunding expenditure should be reported if the redemption/repayment date occurs within the reporting period of this report.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" onClick={()=>setShowRefundIssueHelpText(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                        }
                    </p>

                    <div className="container">
                        <form>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                <th>CDIAC #
                                    <TooltipComponent 
                                    title="Read-only. CDIAC number of the debt issue that was refunded with the proceeds of the issue subject to this report." id="cdaic"
                                    placement="right">
                                        <AiFillQuestionCircle className="help-icon" />
                                    </TooltipComponent>
                                </th>
                                <th>Refunded/Refinanced Amount
                                    <TooltipComponent 
                                    title="Read-only. The amount of the debt issue that was refunded, refinanced, or paid down with proceeds of the issue subject to this report." id="refunded-amount"
                                    placement="right">
                                        <AiFillQuestionCircle className="help-icon" />
                                    </TooltipComponent>
                                </th>
                                <th>Redemption/Repayment Date
                                    <TooltipComponent 
                                    title="Date that debt holders or lenders for each issue of debt listed were repaid. Prepopulated with the date previously entered on the RFS (source of funds) for the issue subject to this report. Edit as necessary." id="redemption-date"
                                    placement="right">
                                        <AiFillQuestionCircle className="help-icon" />
                                    </TooltipComponent>
                                </th>
                                <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(issueRefundedAssocs && issueRefundedAssocs.length > 0) ? issueRefundedAssocs.map((item, i)=>(
                                <Fragment>
                                    {editInd === i ? (
                                        <EditableRow
                                        key={i} 
                                        data={editData}
                                        handleChange={handleRefundDateChange}
                                        handleCancelClick={()=>handleCancelClick()}
                                        handleUpdate={(e)=>handleSave(e, editData )}
                                        columns={[
                                            {
                                            prop: "linkedCdiacNbr",
                                            fieldType: "text",
                                            editable: false
                                            },
                                            {
                                            prop: "redeemAmt",
                                            fieldType: "currency",
                                            editable: false
                                            },
                                            {
                                            prop: "redeemDate",
                                            fieldType: "date",
                                            editable: true,
                                            maxDate: new Date(adtrInfo.fiscalYrEndDate),
                                            minDate: new Date(fiscalYrStartDate)
                                            }
                                        ]}
                                        />
                                    ) : (
                                        <ReadOnlyRow 
                                        key={i}
                                        data={item} 
                                        handleEditClick={item.isRowEditable ? (e)=>handleEditClick(e, i, item) : null}
                                        isActionsRequired={true} 
                                        columns={[
                                            {
                                            prop: "linkedCdiacNbr",
                                            fieldType: "text"
                                            },
                                            {
                                            prop: "redeemAmt",
                                            fieldType: "currency"
                                            },
                                            {
                                            prop: "redeemDate",
                                            fieldType: "date"
                                            }
                                        ]}
                                        />
                                    )}
                                </Fragment>
                                )):
                                <tr><td colSpan="4" style={{textAlign:'center'}}>No data available to display.</td></tr>
                            }
                            <tr><td><b>Total:</b></td><td>{CommonService.currencyFormatter(refundedAmtTotal)}</td><td></td></tr>
                            </tbody>
                        </table>
                        </form>
                    </div>
                    </Row>
                </div>
                
                <div className="btn-div">
                    <button className="fright custom-button" type="button" onClick={()=>validateForm()}>Save & Next</button>
                </div>
            </div>
            {showMLRProceeds && <ProceedsTable linkClicked="MLRProceeds" onModalClose={()=>setShowMLRProceeds(false)} finalSaleId={adtrInfo.finalSaleId}/>}
            {showMKRProceeds && <ProceedsTable linkClicked="MKRProceeds" onModalClose={()=>setShowMKRProceeds(false)} finalSaleId={adtrInfo.finalSaleId}/>}
        </Card>
    )
}

export default UseOfProceeds
