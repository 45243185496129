import CommonService from "./CommonService";

const getAuthorizationsByIssuerId = (obj) => {
    return CommonService.apicall(obj)

};

const getAuthorizationsByIssueId = (obj) => {
    return CommonService.apicall(obj)

};

const addAuthorization = (obj) => {
    return CommonService.apicall(obj)

};

const updateAuthorization = (obj) => {
    return CommonService.apicall(obj)

};

const deleteAuthorization = (obj) => {
    return CommonService.apicall(obj)

};
const getAuthorizationRef = (obj) => {
    return CommonService.apicall(obj)

};

const IssueAuthorizationService = {
    getAuthorizationsByIssuerId,
    getAuthorizationsByIssueId,
    addAuthorization,
    updateAuthorization,
    deleteAuthorization,
    getAuthorizationRef,
};


export default IssueAuthorizationService;