import { useState, useEffect , useContext} from 'react'
import { Row, Col, Modal, FormSelect} from 'react-bootstrap'
import {FormInput} from'../components/formElements/FormInput'
import CodeMaintenanceService from '../services/CodeMaintenanceService'
import MaskedInput from 'react-text-mask'
import { Typeahead } from 'react-bootstrap-typeahead'
import { validateFormFields } from '../utils/index'
import IssuerFilerService from '../services/IssuerFilerService'
import { toast } from 'react-toastify'
import CommonService from "../services/CommonService";
import { UserContext } from "../UserContext";
import swal from 'sweetalert'

const FilerRegistrationForm = (props) => {
    const {filerRequestInfo, onModalClose, action} = props;
    const user = useContext(UserContext);
    const [orgTypes, setOrgTypes] = useState([]);
    const [orgDivisions, setOrgDivisions] = useState([]);
    const [socrataGroups, setSocrataGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDivisionsLoading, setIsDivisionsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [filerIssuers, setFilerIssuers] = useState([]);
    const [issuers, setIssuers] = useState([]);
    const [filerRequest, setFilerRequest] = useState(filerRequestInfo);
    const disable = (action === 'approveDeny') ? true : false;
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const handleCommentValue = (e, key) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setFilerRequest(prevState => ({
            ...prevState, [key] : e.target.value
        }))
    };
    const [counties, setCounties] = useState([]);

    useEffect(()=>{
        async function fetchOrgTypes() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGT", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                const orgTypesListNew = [];
                filteredList.forEach((orgType, i)=>{
                    let obj = {};
                    obj = orgType;
                    obj.value = orgType.cdiacCodeValueId;
                    obj.label = orgType.cdiacCodeValueDesc;
                    orgTypesListNew.push(obj);
                })
                
                setOrgTypes(orgTypesListNew);
                setIsLoading(false);
            })
        }
        async function fetchOrgDivisions() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGD" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                setIsDivisionsLoading(false);
                setOrgDivisions(result.data.responses[0]);
            })
        }

        async function fetchSocrataGroups() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "SOCR", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                setSocrataGroups(result.data.responses[0]);
            })
        }

        fetchOrgTypes();
        fetchOrgDivisions();
        getCounties();
        getIssuers();
        fetchFilerIssuerAssocs();
        fetchSocrataGroups();
        // eslint-disable-next-line
    },[]);

    const getCounties = async () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getCounties",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
          };
      
        await CodeMaintenanceService.getAllCodes(obj)
        .then((res) => res)
        .then((result) => {
            setCounties(result.data.responses[0]);
        })
    }

    const fetchFilerIssuerAssocs = async () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getFilerIssuerAssociations",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "requestId" : filerRequestInfo.requestId
             },
          };
      
        await IssuerFilerService.getFilerIssuerAssociations(obj)
        .then((res) => res)
        .then((result) => {
            setFilerIssuers(result.data.responses[0]);
        })
    }
    const handleOrgChange = (e, key) => {
        const val = (key === "filerName") ? e.target.value : (e.length ? e[0].cdiacCodeValueId : "");
        
        setFilerRequest(prevState => ({
            ...prevState, [key] : val
        }))
    }

    const handleContactCountyChange = e => {
        const selectedVal =  e.length > 0 ? e[0].countyCode : ""

        setFilerRequest(prevState => ({
            ...prevState, 'filerCountyCode' : selectedVal
        }))
        
    }
    const handleContactChange = e => {
        const {name} = e.target;
        let value = e.target.value;
        if(name === 'filerCityName') {
            value = value.replace(/[^A-Za-z\s]/ig, '');
        }
        if(name === 'filerStateCode') {
            value = value.replace(/[^A-Za-z]/ig, '');
        }


        if(name === 'filerContactPhoneExtn') {
            value = value.replace(/[^\d]$/, "").substr(0, 10);
        }
        if(name === 'filerZipCode') {
            value = value.replace(/[^\d{5}-]$/, "").substr(0, 10);
        }

        setFilerRequest(prevState => ({
            ...prevState, [name] : value
        }))
    }

    const handleAddIssuer = () => {
        setFilerIssuers(prevState=>([
            ...filerIssuers, {"issuerId" : ''}
        ])
        )
    }
    const handleFilerIssuerAssocChange = (e, i) => {
        if(e.length > 0) {
        let filerIssuersArr = [...filerIssuers];
        filerIssuersArr[i].issuerId = e[0].issuerId;
        setFilerIssuers(filerIssuersArr);
     }
    }

    const handleRequestStatusChange = (e, key) => {
        const val = (key === "requestStatus") ? e.target.value : "S";

        setFilerRequest(prevState => ({
            ...prevState, [key] : val
        }))
    }

    const handleSocrataGroupChange = (e) => {
        const selectedValue =  e.length > 0 ? e[0].cdiacCodeValueId : ""
        setFilerRequest(prevState => ({
            ...prevState, 'filerSocrataGroupCodeId' : selectedValue
        }))
    
    }

    const handleFilerShortNameChange = e => {
        const {name, value} = e.target;
        setFilerRequest(prevState => ({
            ...prevState, [name] : value
        }))
    }

    const saveIssuer = async (i) => {
        const filerIssuersArr = [...filerIssuers]
       
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "postFilerIssuerAssociation",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "filerIssuerAssociation" : {
                    "issuerId": filerIssuersArr[i].issuerId,
                    "requestFilerOrgId" : filerRequest.requestId,
                    "verifiedInd" : "N", // needs to be updated
                    "createUser" : CommonService.getUserName(user),
                    "lastUpdateUser" : CommonService.getUserName(user)
                }
             },
          };
      
        await IssuerFilerService.createFilerIssuerAssoc(obj)
        .then((res) => res)
        .then((result) => {
            toast.success("Filer Issuer Association Created Successfully");
        })
    }
    const removeIssuer = async (i) => {

        swal("Are you sure you want to remove this association?", {
            buttons: ["No", "Yes"],
        }).then((value) => {
           if(value){
            const filerIssuersArr = [...filerIssuers]

            if(filerIssuersArr[i].issuerId !== ''){
                var obj = {
                    msgId: "null",
                    sysId: null,
                    opr: "deleteFilerIssuerAssociation",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: { 
                            "filerId" : filerRequest.requestId,
                            "issuerId": filerIssuersArr[i].issuerId
                     },
                  };
              
                 IssuerFilerService.deleteFilerIssuerAssoc(obj)
                .then((res) => res)
                .then((result) => {
        
                    if (i !== -1) {
                        filerIssuersArr.splice(i, 1);
                        setFilerIssuers(filerIssuersArr);
                      }
                    toast.success("Filer Issuer Association Removed Successfully");
                })
            }
           }
        });
        




    }
    const getIssuers = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssuers",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
          };
      
          await IssuerFilerService.getIssuers(obj)
            .then((res) => res)
            .then((result) => {
              Object.keys(result.data.responses).forEach((key) => {
                  setIssuers(result.data.responses[key]);
              });
              setIsLoading(false);
            });
    }
    const updateFilerRequest = () => {   

        if(filerRequest.filerCountyCode === ""){
            swal("Please enter a valid County option");
            return;
        }

        filerRequest.createUser = CommonService.getUserName(user);
        filerRequest.lastUpdateUser =  CommonService.getUserName(user);

        var obj = {
            msgId: null,
            sysId: null,
            opr: "putFilerRequest",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "requestId" : filerRequest.requestId,
                "filerRequest" : filerRequest,
                "env" : "I"
            },
          };
      
        IssuerFilerService.updateIssuerFilerRequest(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors[0]) {
                toast.error(result.data.errors[0].message);
            } else {
                toast.success("Filer Request Updated Successfully.");
                onModalClose();
            }
        });
    }
    const submitForm = (e) => {
        let form = e.target.closest('form#filerForm');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            updateFilerRequest();
        }
    }
    const approveDenyRequest = (approveDenyAction) => {
        alert("Clicked " + approveDenyAction + " Request");
    }



    return (
        <Modal show={true} onHide={onModalClose}>
        <Modal.Header closeButton>
            <h3>Request for New Filer</h3>
        </Modal.Header>

        <Modal.Body>
            <div className="panel panel-default">
                <div className="form">
                    <form id="filerForm">
                        <div className="organizationForm">
                            <Row>
                                <Col md={6} className="control">
                                <FormInput 
                                    type="text" 
                                    name="filerName" 
                                    label="Filer Organization Name"
                                    isrequired="true"
                                    validations={["required"]}
                                    value={filerRequest.filerName}
                                    onChange={(e)=>handleOrgChange(e, 'filerName')}
                                    disabled = {disable}
                                    maxLength="120"
                                />
                                {formErrors['filerName'] && <span className="error">{formErrors['filerName']}</span>}
                                </Col>
                                
                                <Col md={6}>
                                    <label><span className="required">*</span>Organization Type</label>
                                    <Typeahead 
                                        id="filerTypeCodeId"
                                        name="filerTypeCodeId"
                                        placeholder="Choose Org Type..."
                                        style={{marginBottom: '0.5rem'}}
                                        labelKey="cdiacCodeValueDesc"
                                        onChange={(e)=>handleOrgChange(e, 'filerTypeCodeId')}
                                        options={orgTypes}
                                        selected = {
                                            orgTypes.filter(option => 
                                               option.cdiacCodeValueId === filerRequest.filerTypeCodeId)
                                         }
                                        inputProps={{ 'data-validations': 'required', name: 'filerTypeCodeId' }}
                                        disabled = {disable}
                                        />
                                {formErrors['filerTypeCodeId'] && <span className="error">{formErrors['filerTypeCodeId']}</span>}
                                </Col>

                                <Col md={6} className="control">
                                    <label>Filer Short Name</label>
                                    <input type="text" name="filerShortName" value={filerRequest.filerShortName ? filerRequest.filerShortName : ""} 
                                    onChange={(e)=>handleFilerShortNameChange(e)}
                                    />
                                </Col>

                                <Col md={6}>
                                    <label>Socrata Groups</label>
                                    <Typeahead 
                                        id="filerSocrataGroupCodeId"
                                        name="filerSocrataGroupCodeId"
                                        placeholder="Choose Socrata Group..."
                                        labelKey="cdiacCodeValueDesc"
                                        selected = {
                                            socrataGroups.filter(option => 
                                               option.cdiacCodeValueId === filerRequest.filerSocrataGroupCodeId)
                                         }
                                        onChange={(e)=>handleSocrataGroupChange(e)}
                                        options={socrataGroups}
                                        inputProps={{ name: 'filerSocrataGroupCodeId' }}
                                        isLoading={isLoading}
                                        disabled = {disable}
                                        />
                                {formErrors['filerSocrataGroupCodeId'] && <span className="error">{formErrors['filerSocrataGroupCodeId']}</span>}
                                </Col>
                            
                                <Col md={6}>
                                    <label><span className="required">*</span>Division</label>
                                    <Typeahead 
                                        id="filerDivisionCodeId"
                                        name="filerDivisonCodeId"
                                        placeholder="Choose Org Division..."
                                        style={{marginBottom: '0.5rem'}}
                                        labelKey="cdiacCodeValueDesc"
                                        isLoading={isDivisionsLoading}
                                        onChange={(e)=>handleOrgChange(e, 'filerDivisonCodeId')}
                                        options={orgDivisions}
                                        selected = {
                                            orgDivisions.filter(option => 
                                               option.cdiacCodeValueId === filerRequest.filerDivisonCodeId)
                                         }
                                        inputProps={{ 'data-validations': 'required', name: 'filerDivisonCodeId' }}
                                        disabled = {disable}
                                        />
                                {formErrors['filerDivisonCodeId'] && <span className="error">{formErrors['filerDivisonCodeId']}</span>}
                                </Col>
                                                   
                                <Col md={6} className="control">
                                    <FormInput type="text" label="Address Line1" name="filerAddressLine1" isrequired="true"
                                     value={filerRequest.filerAddressLine1} 
                                     validations={["required"]} onChange={(e)=>handleContactChange(e)}
                                     disabled = {disable}/>
                                    {formErrors['filerAddressLine1'] && <span className="error">{formErrors['filerAddressLine1']}</span>}
                                </Col>
                                <Col md={6} className="control">
                                    <label>Address Line2:</label>
                                    <input type="text" name="filerAddressLine2" 
                                    value={filerRequest.filerAddressLine2} 
                                    onChange={(e)=>handleContactChange(e)} disabled = {disable}/>
                                </Col>
                                <Col md={6} className="control">
                                    <label>Address Line3:</label>
                                    <input type="text" name="filerAddressLine3" 
                                    onChange={(e)=>handleContactChange(e)} disabled = {disable}/>
                                </Col>
                                <Col md={6} className="control">
                                    <label>Address Line4:</label>
                                    <input type="text" name="filerAddressLine4"
                                    value={filerRequest.filerAddressLine4}  
                                    onChange={(e)=>handleContactChange(e)} disabled = {disable}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} className="control">
                                    <FormInput type="text" label="City" name="filerCityName" isrequired="true" 
                                    value={filerRequest.filerCityName} 
                                    maxLength="50"
                                    validations={["required"]} onChange={(e)=>handleContactChange(e)}
                                    disabled = {disable}/>
                                    {formErrors['filerCityName'] && <span className="error">{formErrors['filerCityName']}</span>}
                                </Col>
                                <Col md={3} className="control">
                                    <FormInput type="text" label="State" name="filerStateCode" isrequired="true"
                                    value={filerRequest.filerStateCode} 
                                    maxLength="2" 
                                    validations={["required", "minLength:2"]} onChange={(e)=>handleContactChange(e)}
                                    disabled = {disable}/>
                                    {formErrors['filerStateCode'] && <span className="error">{formErrors['filerStateCode']}</span>}
                                </Col>
                                <Col md={3} className="control">
                                    <FormInput type="text" label="Zip Code" name="filerZipCode" isrequired="true" 
                                    value={filerRequest.filerZipCode} 
                                    validations={["required","zipCode"]} onChange={(e)=>handleContactChange(e)}
                                    disabled = {disable}/>
                                    {formErrors['filerZipCode'] && <span className="error">{formErrors['filerZipCode']}</span>}
                                </Col>
                                <Col md={3} className="control">
                                    <label><span className="required">*</span>County</label>
                                    <Typeahead id="countyCode" 
                                        name="filerCountyCode"
                                        placeholder="Choose County..."
                                        labelKey="countyName"
                                        selected = {
                                            filerRequest.filerCountyCode !== "" ? (counties.filter(option => 
                                               option.countyCode === filerRequest.filerCountyCode)) : []
                                         }
                                        onChange={(e)=>handleContactCountyChange(e)}
                                        options={counties} isLoading={isLoading}
                                        inputProps={{ 'data-validations': 'required', name: 'filerCountyCode' }}
                                        disabled = {disable}/>
                                    {formErrors['filerCountyCode'] && <span className="error">{formErrors['filerCountyCode']}</span>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className="control">
                                    <label><span className="required">*</span>Area Code (XXX)</label>
                                    <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                        guide={true}
                                        name="filerContactPhoneAreaCode"
                                        value={filerRequest.filerContactPhoneAreaCode}
                                        data-validations={["required", "telNo:3"]}
                                        onChange={(e)=>handleContactChange(e)}
                                        onBlur={(e)=>handleContactChange(e)}
                                        disabled = {disable}
                                    />
                                    {formErrors['filerContactPhoneAreaCode'] && <span className="error">{formErrors['filerContactPhoneAreaCode']}</span>}
                                </Col>
                                <Col md={4} className="control">
                                    <label><span className="required">*</span>Phone Number (XXX-XXXX)</label>
                                    <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                        guide={true}
                                        name="filerContactPhoneNumber"
                                        data-validations={["required", "telNo:8"]}
                                        onChange={(e)=>handleContactChange(e)}
                                        value={filerRequest.filerContactPhoneNumber}
                                    />
                                    {formErrors['filerContactPhoneNumber'] && <span className="error">{formErrors['filerContactPhoneNumber']}</span>}
                                </Col>
                                <Col md={4} className="control">
                                    <label>Ext</label>
                                    <input type="text" name="filerContactPhoneExtn" 
                                    value={filerRequest.filerContactPhoneExtn}
                                    onChange={(e)=>handleContactChange(e)}
                                    disabled = {disable}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className="control">
                                    <label>Fax Area Code (XXX)</label>
                                    <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                        guide={true}
                                        name="filerFaxAreaCode"
                                        value={filerRequest.filerFaxAreaCode} 
                                        data-validations={["telNo:3"]}
                                        onChange={(e)=>handleContactChange(e)}
                                        onBlur={(e)=>handleContactChange(e)}
                                        disabled = {disable}
                                    />
                                    {formErrors['filerFaxAreaCode'] && <span className="error">{formErrors['filerFaxAreaCode']}</span>}
                                </Col>
                                <Col md={4} className="control">
                                    <label>Fax Number (XXX-XXXX)</label>
                                    <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                        guide={true}
                                        name="filerFaxNumber"
                                        data-validations={["telNo:8"]}
                                        value={filerRequest.filerFaxNumber}
                                        onChange={(e)=>handleContactChange(e)}
                                        disabled = {disable}
                                    />
                                    {formErrors['filerFaxNumber'] && <span className="error">{formErrors['filerFaxNumber']}</span>}
                                </Col>
                                <Col md={4} className="control">
                                    <FormInput type="email" name="filerEmailId" label="E-Mail" isrequired="true" 
                                    value={filerRequest.filerEmailId}
                                    validations={["required", "email"]} 
                                    onChange={(e)=>handleContactChange(e)}
                                    disabled = {disable}/>
                                    {formErrors['filerEmailId'] && <span className="error">{formErrors['filerEmailId']}</span>}
                                </Col>
                            </Row>  
                            <Row>
                                <Col md={4} className="control">
                                <FormInput type="text" label="Contact First Name" value={filerRequest.filerContactFirstName} name="filerContactFirstName" isrequired="true"  validations={["required"]} onChange={(e)=>handleContactChange(e)}/>
                                    {formErrors['filerContactFirstName'] && <span className="error">{formErrors['filerContactFirstName']}</span>}

                                </Col>
                                <Col md={4} className="control">
                                    <label>Contact Middle Name:</label>
                                    <input type="text" name="filerContactMiddleName" value={filerRequest.filerContactMiddleName} onChange={(e)=>handleContactChange(e)}/>
                                </Col>
                                <Col md={4} className="control">
                                <FormInput type="text" label="Contact Last Name" value={filerRequest.filerContactLastName} name="filerContactLastName" isrequired="true"  validations={["required"]} onChange={(e)=>handleContactChange(e)}/>
                                    {formErrors['filerContactLastName'] && <span className="error">{formErrors['filerContactLastName']}</span>}
                                </Col>
                            </Row>
                            <Row>
                        <Col md={6}>
                            <label>Request Status</label>
                            <FormSelect as="select" name="status" value={filerRequest.requestStatus} 
                            onChange={(e)=>handleRequestStatusChange(e, 'requestStatus')}>
                                <option value="S">Submitted</option>
                                <option value="A">Approved</option>
                                <option value="D">Denied</option>
                            </FormSelect>
                        </Col>

                        {filerRequest.requestStatus === 'D' &&
                        <div>
                            <label>Denial Comment</label>
                            <div className="comments-box">
                                <textarea name="denielComment" value={filerRequest.denyCancelComment}
                                id="comments-input" onChange={(e)=>handleCommentValue(e,'denyCancelComment')}
                                className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                                <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                            </div>
                        </div>
                        }

                        <div>
                            <label>Filer Comment</label>
                            <div className="comments-box">
                                <textarea name="denielComment" value={filerRequest.filerComment}
                                id="comments-input" onChange={(e)=>handleCommentValue(e,'filerComment')}
                                className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                                {/* <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span> */}
                            </div>
                        </div>

                    </Row>
                        </div>

                        <div>
                            <div>
                                <label>Please identify the Issuers for whom you would like to file the Issuances: </label>
                                <div>
                                { action === 'edit' && <button className="custom-button" type="button" onClick={()=>handleAddIssuer()}>Add Issuer</button> }
                                    {filerIssuers && filerIssuers.map((item, i) => {
                                        return (<Row key={i}>
                                            <Col md={6}>
                                                <Typeahead id="addIssuer" 
                                                placeholder="Choose Issuer..."
                                                labelKey="issuerName"
                                                options={issuers} isLoading={isLoading}
                                                selected = {
                                                    issuers.filter(option => 
                                                       option.issuerId === item.issuerId)
                                                 }
                                                onChange={(e)=>handleFilerIssuerAssocChange(e, i)}/>
                                            </Col>
                                            { action === 'edit'  && 
                                            <Col>
                                                <button className="custom-button" type="button" onClick={()=>saveIssuer(i)}>Save</button>{" "}
                                                <button className="custom-button" type="button" onClick={()=>removeIssuer(i)}>Remove</button>
                                            </Col>}
                                            
                                        </Row>)
                                    }) 
                                    }
                                </div>
                            </div>
                        </div>
                       
                        <div className="btn-div">
                        <div className="fright">
                            <button className=" custom-button-secondary" type="button" onClick={onModalClose}>Cancel</button> { " " }
                            { action === 'edit' ?
                            <button className=" custom-button" type="button" onClick={(e)=>submitForm(e)}>Update</button>
                            :
                            <>
                            <button className="custom-button-danger" type="button" onClick={()=>approveDenyRequest("deny")}>Deny</button>{ " " }
                            <button className="custom-button-success" type="button" onClick={()=>approveDenyRequest("approve")}>Approve</button>
                            </>
                            }

                        </div>
                        </div>
                        
                    </form>
                </div>
                </div>
            </Modal.Body>
        </Modal>
    
    )
}

export default FilerRegistrationForm