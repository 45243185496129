import Sidebar from './Sidebar'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

const Layout = (props) => {
    const {user} = props;
        return (
        <div>
            <div id="HeaderAlertMessage"/>
            <Header user={user}/>
            <Sidebar />
            <div className="container" style={{minHeight: '70vh'}}>
            <main>{props.children}</main>
            </div>
            <Footer />
        </div>
    )
}

export default Layout
