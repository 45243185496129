import {Form, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import ReportRefService from "../../../services/ReportRefService";
import React from "react";
import { useForm } from "react-hook-form";
import {useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify';
import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import CommonService from "../../../services/CommonService";
import TooltipComponent from '../../../common/Tooltip';

const AddReportForm = (props) => {
    const {onModalClose, selectedReportGroupId, groupName } = props;
    const {register, handleSubmit, formState: { errors }} = useForm();
    const [errorMessage, setErrorMessage] = useState();
    const user = useContext(UserContext);

    const onSubmit = (data) => {

        var obj = {
            msgId: null,
            sysId: null,
            opr: "postReportRef",
            hdrStruct: null,
            queryParam: null,
            reqData: { "reportRef":{
                "reportName": data.reportName,
                "reportDesc": data.reportDesc,
                "reportUrl": data.reportUrl,
                "reportGroupCodeId": selectedReportGroupId,
                "createUser": CommonService.getUserName(user),
                "lastUpdateUser": CommonService.getUserName(user),
                "activeInd": data.activeInd === true ? 'Y' : 'N'
            }
            },
          };
      
          ReportRefService.addReportRef(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("Report added successfully!");
                    onModalClose();
                }
                
            });
      }

    return (
        <form onSubmit={(e) => e.preventDefault()}>
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>New Report</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
                <div className="control">
                    <label><span className="required">* </span>Report Group: </label>
                    <input  value={groupName} disabled/>

                </div>
                <div className="control">
                    <label><span className="required">* </span>Name: </label>
                    <input maxLength="100" {...register('reportName', { required: true })}/>
                    {errors.reportName && <p style={{ color: 'red' }}>Name is required</p>}
                </div>
                <div className="control">
                    <label><span className="required">* </span>Description: </label>
                    <input maxLength="100" {...register('reportDesc', { required: true })}/>
                    {errors.reportDesc && <p style={{ color: 'red' }}>Desc is required</p>}
                </div>
                <div className="control">
                    <label><span className="required">* </span>Report URL: </label>
                    <input maxLength="100" {...register('reportUrl', { required: true })}/>
                    {errors.reportUrl && <p style={{ color: 'red' }}>Report URL is required</p>}
                </div>
                <div style={{display: 'flex'}}>
                    <Form.Check type="checkbox" label="Active?" {...register('activeInd')} defaultChecked/>
                    <TooltipComponent
                        title="Only active values are displayed in the User Interface"
                         id="isActive"
                        placement="right">
                            <AiFillQuestionCircle className="help-icon" />
                    </TooltipComponent>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                <button className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Save</button>
            </Modal.Footer>
        </Modal>
        </form>
    )
}

export default AddReportForm
