import { useState, useContext } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { validateFormFields } from '../../../utils';
import Select from 'react-select';
import IssueStatutesService from '../../../services/IssueStatuteService';
import { UserContext } from '../../../UserContext';
import CommonService from '../../../services/CommonService';
const AddStatutoryCodeForm = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, issueId, statuteRefs} = props;
    const [showTextField, setShowTextField] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const statutoriesList = statuteRefs;
    const [isLoading, setIsLoading] = useState(false);
    const [statutory, setStatutory] = useState({
        statutoryCode: '',
        statuteId: '',
        otherCode: '',
        otherSection: '',
        otherDesc: ''
    });
    const [statuteCodes, setStatuteCodes] = useState(null);
    const fetchStatuteCodes = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getStatuteCodes",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {}
        };

        IssueStatutesService.getStatuteCodes(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.responses[0]) {
                setStatuteCodes(result.data.responses[0]);
            }
            setIsLoading(false);
        });
    }
    const handleStatutoryCodeChange = (e) => {
        setStatutory(prevState => ({
                ...prevState,
                'statutoryCode': e.value,
                'statuteId': e.statuteId,
            }))
        if(e.value === "OTH") {
            setIsLoading(true);
            fetchStatuteCodes();
            setShowTextField(true)
        } else {
            setShowTextField(false)
        }

    }
    const handleOtherTextChange = (e) => {
        setStatutory(prevState=>({
            ...statutory,
            [e.target.name]: e.target.value
        }))
    }

    const saveStatute = (issueStatute) => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "postIssueStatute",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueStatute": {
                    "proposeDebtId": issueId,
                    "statuteCodeValueId": issueStatute.statutoryCode,
                    "statuteId" : issueStatute.statuteId,
                    "otherStatuteCode": issueStatute.otherCode,
                    "createUser": CommonService.getUserName(user),
                    "otherStatuteText": issueStatute.otherSection,
                    "otherStatuteBriefDesc": issueStatute.otherDesc
                }
            }
        };

        IssueStatutesService.addStatute(obj)
        .then((res) => res)
        .then((result) => {
        });
    }

    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            saveStatute(statutory);
            onModalClose();
        }
    }
    return (
        <Modal  show={true} onHide={onModalClose} size="lg">
            <Modal.Header closeButton><h3>Add Statutory Code</h3></Modal.Header>
            <Modal.Body>
                <form id="add-form">
                <Row>
                    <label>{<span className="required">*</span>}Statutory code under which this debt will be issued:</label>
                    <Col>
                        <Select 
                        styles={{
                            control: () => {
                              return {
                                display: 'flex',
                                border: `1px solid ${formErrors['statutoryCode'] ? "red" : "hsl(0,0%,80%)"}`
                              };
                            }
                          }}
                        options={statutoriesList} 
                        onChange={(e)=>handleStatutoryCodeChange(e)} 
                        />
                        <input
                        tabIndex={-1}
                        name="statutoryCode"
                        autoComplete="off"
                        style={{ opacity: 0, height: 0, position: 'absolute' }}
                        value={statutory.statutoryCode}
                        data-validations="required"
                        disabled
                        />
                    {formErrors['statutoryCode'] && <p className="error">{ formErrors['statutoryCode'] }</p>}
                    </Col>
                </Row>
                {showTextField && !isLoading && 
                <Row>
                    <Col>
                        <div className="control">
                            <label><span className="required">*</span>Statute Code:</label>
                            <Form.Select name="otherCode" 
                            data-validations="required" 
                            value={statutory.otherCode} 
                            onChange={(e)=>handleOtherTextChange(e)}>
                                <option value="">select</option>
                                {statuteCodes.map((item, i) => {
                                    return (
                                        <option key={`s${i}`} value={item.statuteCode}>{item.statuteCode}</option>
                                    )
                                })}
                            </Form.Select>
                            {formErrors['otherCode'] && <p className="error">{ formErrors['otherCode'] }</p>}
                        </div>
                    </Col>
                    <Col>
                        <div className="control">
                            <label><span className="required">*</span>Statute Section:</label>
                            <input 
                            value={statutory.otherSection}
                            placeholder="Other Statute Section"
                            id="otherSection"
                            data-validations = "required"
                            name = "otherSection" 
                            onChange={(e) => {
                                handleOtherTextChange(e)
                            }}
                            maxLength = '50' />
                            {formErrors['otherSection'] && <p className="error">{ formErrors['otherSection'] }</p>}
                        </div>
                    </Col>
                    <Col>
                        <div className="control">
                            <label><span className="required">*</span>Statute Description:</label>
                            <input 
                            value={statutory.otherDesc}
                            placeholder="Other Statute Brief Description"
                            id="otherDesc"
                            data-validations = "required"
                            name = "otherDesc" 
                            onChange={(e) => {
                                handleOtherTextChange(e)
                            }}
                            maxLength = '50' />
                            {formErrors['otherDesc'] && <p className="error">{ formErrors['otherDesc'] }</p>}
                        </div>
                    </Col>
                </Row>
                }
                <div className="btn-div">
                    <div className="fright">
                    <button type="button" className="custom-button-secondary  btn-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                    <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                </div>
                </form>

            </Modal.Body>
          
        </Modal>
    )
}

export default AddStatutoryCodeForm
