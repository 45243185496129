import { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../ui/Card'
import { FormInput } from '../formElements/FormInput'
import CommonService from "../../services/CommonService";
import { UserContext } from "../../UserContext"
import { useContext } from 'react'
import AdtrService from '../../services/AdtrService'

const RetiredIssues = (props) => {
    const {adtrDependenciesData} = props;
    const adtrInfo = adtrDependenciesData.adtrInfo;

    const principalOutstandingAmt = (adtrInfo.rptPeriodBeginBalAmt + adtrInfo.accretedInterestAmt) - (adtrInfo.principalOtherPaidAmt + adtrInfo.principalRedeemAmt);
    const [netProceedsBalance, setNetProceedsBalance] = useState(0);
    const [adtrReportable, setAdtrReportable] = useState('');
    const user = useContext(UserContext);
    
    useEffect(()=>{
        getNetProceedsBalance();
        // eslint-disable-next-line
    },[]);

    const getNetProceedsBalance = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getNetProceedsBalance",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId" : adtrInfo.finalSaleId,
                "fiscalYrEndDate" : adtrInfo.fiscalYrEndDate
            }
        };
    
       AdtrService.getNetProceedsBalance(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.error(result.data.errors[0].message)
            } else{
                setNetProceedsBalance(result.data.responses[0]);
                const adtrReptFlag = (result.data.responses[0] === 0 && principalOutstandingAmt === 0 ? 'N' : 'Y')
                setAdtrReportable(adtrReptFlag);
            }
        });
    }

    const updateAdtrReportableFlag = () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateAdtrReportableFlag",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "finalSaleId" : adtrInfo.finalSaleId,
                "lastUpdateUser" : CommonService.getUserName(user)
            }
        };
    
       AdtrService.updateAdtrReportableFlag(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                console.error(result.data.errors[0].message)
            }
        });
    }
  
    const validateForm = async(e) => {
        if(adtrReportable === 'N') {
            await updateAdtrReportableFlag();
        }
        props.openWidget('review')
        
    }
    return (
        <Card>
            <form className="form" id="retiredIssues">
                <Row>
                    <Col md={6} className="control">
                        <FormInput 
                        placeholder="0.00" 
                        label="Principal Outstanding – End of Reporting Period" 
                        value={CommonService.currencyFormatter(principalOutstandingAmt)}
                        disabled
                        readOnly
                        />
                    </Col>
                    <Col md={6} className="control">
                        <FormInput 
                        placeholder="0.00" 
                        label="Proceeds Unspent – End of Reporting Period" 
                        value={CommonService.currencyFormatter(netProceedsBalance)}
                        disabled
                        readOnly
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <label>ADTR Reportable Next Reporting Year: </label>
                        {adtrReportable === 'N' ? 'No' : 'Yes'}
                    </Col>
                </Row>
                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('comments')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={e => validateForm(e)}>Save & Next</button>
                </div>
            </form>
        </Card>
    )
}

export default RetiredIssues
