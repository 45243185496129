import { useState, useEffect } from 'react'
import EditIssuer from './editIssuer'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import IssuerMaintenanceService from '../../../services/IssuerMaintenanceService'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const SearchResultContainer = (props) => {
  const [editIssuer, setEditIssuer] = useState(false);
  const [selectedAuthInfo, setSelectedAuthInfo] = useState([]);
  const { searchReq } = props;
  const [authorizationRefs, setAuthorizationRef] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  const viewEditFormatter = (row) => {
    return (
      <div style={{
        textAlign: "center",
        lineHeight: "normal"
      }}>
        <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
      </div>
    );
  }
  const onRowClick = (e, row) => {
    // e.preventDefault();
    setSelectedAuthInfo(row);
    setEditIssuer(row);
  }

  const getIssuersBySearchReq = () => {
    var obj = {
      msgId: null,
      sysId: null,
      opr: "searchIssuers",
      hdrStruct: null,
      queryParam: null,
      reqData: {
        "searchCriteria": {
          "issuerId": searchReq.issuerId,
          "orgTypeCodeId": searchReq.orgTypeCodeId,
          "orgDivisionCodeId": searchReq.orgDivisionCodeId
        }
      },
    };

    IssuerMaintenanceService.searchIssuers(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setAuthorizationRef(result.data.responses[key]);
        });
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getIssuersBySearchReq();
    // eslint-disable-next-line
  }, [searchReq]);

  const columns = [{
    dataField: 'issuerName',
    text: 'Issuer Name',
    sort: true,
  }, {
    dataField: 'orgType',
    text: 'Organization Type',
    sort: true,
  }, {
    dataField: 'orgDivision',
    text: 'Organization Division',
    sort: true,
  }, {
    dataField: "",
    text: "Action",
    formatter: viewEditFormatter,
  }
  ];

  const handleModalClose = () => {
    setEditIssuer(false);
    getIssuersBySearchReq();
  }

  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" />}
      <div className="form">
        <DataGrid
            title="Search Result:"
            dataSource={authorizationRefs}
            sortField='issuerName'
            searchToolTip="Use the search feature to quickly retrieve the system code values"
            emptyDataMessage='No data available to display'
            >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
      </div>
      {editIssuer && <EditIssuer onModalClose={() => handleModalClose()} issuerInfo={selectedAuthInfo} />}
    </>
  )
}

export default SearchResultContainer
