import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../configManager";
import { Link } from 'react-router-dom'

function Header({ user }) {

    const { instance, accounts } = useMsal();
    const loginUsername = accounts[0] ? accounts[0].username : "";

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest)
        .then(response => {
        })
        .catch(e => {
            console.error(e);
        });
    }

    function handleLogout(instance) {
        instance.logout().catch(e => {
            console.error(e);
        });
    }
    return (
        <>
            <Navbar className="header" variant="dark" expand="lg" sticky="top">
                <Container>
                    <Navbar.Brand href="/dashboard"><img src="/assets/images/CDIAC_LOGO_UPDATED.png" alt="" className="navbar-logo" /><span className="appname">CDIAC DATA PORTAL</span></Navbar.Brand>
                    {!accounts[0] && <div>
                            <p>
                                <span> <button className="custom-button bg-teal" style={{textDecoration: 'none', color: 'white'}} onClick={() => handleLogin(instance)} >Log In</button>
                                </span>
                            </p>
                        </div>
                    }
                    {accounts[0] && <>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="rightside">
                            <Nav className="mr-right">
                                <span><p>{`Welcome ${loginUsername}`}</p></span>
                                <NavDropdown title="" id="basic-nav-dropdown" style={{ right: 0 }}>
                                <NavDropdown.Item as={Link} to="/" onClick={() => handleLogout(instance)} >Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse></>
                    }
                </Container>
            </Navbar>

        </>

    );
}

export default Header;