import {Form, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai'
import CountyMaintenanceService from "../../../services/CountyMaintenanceService";
import React from "react";
import { useForm } from "react-hook-form";
import {useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify';
import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import CommonService from "../../../services/CommonService";
import TooltipComponent from '../../../common/Tooltip';

const EditCountyForm = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, countyInfo, region} = props;
    const {register, handleSubmit,  formState: { errors }} = useForm({
        defaultValues: {
            countyName: countyInfo.countyName,
            countyCode: countyInfo.countyCode,
            activeInd: countyInfo.activeInd === 'Y' ? true : false,
            regionCodeId: countyInfo.regionCodeId
          }

    });
    const [errorMessage, setErrorMessage] = useState();

    const onSubmit = (data) => {

        var obj = {
            msgId: null,
            sysId: null,
            opr: "putCountyRef",
            hdrStruct: null,
            queryParam: null,
            reqData: { "code": data.countyCode, "countyRef":{
                "countyCode": data.countyCode,
                "regionCodeId": data.regionCodeId,
                "countyName": data.countyName,
                "createUser": CommonService.getUserName(user),
                "lastUpdateUser": CommonService.getUserName(user),
                "activeInd": data.activeInd === true ? 'Y' : 'N'
            }
            },
          };
      
          CountyMaintenanceService.updateCounty(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("County updated successfully!");
                    onModalClose();

                }
                
            });
      }

    return (
        
        <form onSubmit={(e) => e.preventDefault()}>
            
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Edit County</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
                <div className="control">
                    <label><span className="required">* </span>Region: </label>
                    <input disabled value={region}/>
                </div>
                <div className="control">
                    <label><span className="required">* </span>County Code: </label>
                    <input maxLength="6" disabled {...register('countyCode', { required: true })}/>
                </div>
                <div className="control">
                    <label><span className="required">* </span>County Name: </label>
                    <input maxLength="100" {...register('countyName', { required: true })}  />
                    {errors.countyName && <p style={{ color: 'red' }}>County Name is required</p>}
                </div>
                <div style={{display: 'flex'}}>
                    <Form.Check inline type="checkbox" label="Active?" {...register('activeInd')}/>
                    <TooltipComponent title="Only active values are displayed in the User Interface" id="edit-county"
                    placement="right">
                        <AiFillQuestionCircle className="help-icon mt-2" />
                    </TooltipComponent>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                <button className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Update</button>
            </Modal.Footer>
        </Modal>
        
        </form>
    )
}

export default EditCountyForm
