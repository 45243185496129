import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavbarTabsData } from './SidebarData';
import './Sidebar.css';
import { IconContext } from 'react-icons';

function Sidebar() {
  const {pathname} = useLocation()
  if(pathname === "/notAuthorized") {
    return null;
  }
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='sub-header'>
          <div className="container">
          <div className="dflex container-xl">
            <Link to='#' className='menu-bars'>
            </Link>
            <ul className="navbar-tabs flex-row">
            {NavbarTabsData.map((tab, index) => {
              return (
                <li key={index} className="nav-item" id={pathname.indexOf(tab.path) > -1 ? "activetab" : ""}>
                  <Link to={tab.path}>
                    <span>{tab.title}</span>
                  </Link>
                </li>
              );
              })
            }
            </ul>
          </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Sidebar;