import { useEffect } from 'react'
import Card from '../../ui/Card'
import {Form, Row, Col, Modal, Spinner} from 'react-bootstrap'
import { useState } from 'react'
import classes from './IssuanceAuthorization.module.css'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {FaPencilAlt} from 'react-icons/fa'
import {VscTrash} from 'react-icons/vsc'
import AddIssuanceAuthorization from './addIssuanceAuthorization'
import EditIssuanceAuthorization from './editIssuanceAuthorization'
import IssueAuthorizationService from '../../../services/IssueAuthorizationService'
import CommonService from '../../../services/CommonService'
import ViewIssuances from '../../admin/authorizationMaintenance/viewIssuances'
import AuthorizationSummary from '../../admin/authorizationMaintenance/authorizationSummary'
const IssuanceAuthorization = (props) => {
    const issueId = props.finalSaleDetails.finalSaleId;
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editAuthorizationInfo, setEditAuthorizationInfo] = useState(null);
    const [selectedAuthInfo, setSelectedAuthInfo] = useState(null);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [authorizations, setAuthorizations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openViewIssuances, setOpenViewIssuances] = useState(false);
    const [openAdtrSummary, setOpenAdtrSummary] = useState(false);

    useEffect(()=>{
        getIssueAuthorizations(issueId);
        // eslint-disable-next-line
    },[issueId]);

    const getIssueAuthorizations = async (issueId) => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getIssueAuthorizationsById",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { "id" : issueId, "reportType" : "RFS" }
        };
       await IssueAuthorizationService.getAuthorizationsByIssueId(obj)
          .then((res) => res)
          .then((result) => {
            setAuthorizations(result.data.responses[0]);
            props.updateFinalSaleDependencies(result.data.responses[0], 'issueAuthorizations');
            setIsLoading(false);
          });
    }
    const deleteIssueAuthorization = async (authorizationId) => {
        var obj = {
            "msgId": null,
            "sysId": null,
            "opr": "deleteIssueAuthorization",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { "id" : authorizationId }
        };
       await IssueAuthorizationService.deleteAuthorization(obj)
          .then((res) => res)
          .then((result) => {
            setIsLoading(true);
            getIssueAuthorizations(issueId);
          });
    }

    const addAuthorization = () => {
        setOpenAddModal(true);
        setEditAuthorizationInfo(null);
    }
    const handleModalClose = () => {
        setOpenAddModal(false);
        setTimeout(() => {
        getIssueAuthorizations(issueId);
    }, 1000);
    }
    const handleEditModalClose = () => {
        setIsLoading(true);
        setOpenEditModal(false);
        setTimeout(() => {
        getIssueAuthorizations(issueId);
        }, 1000);
    }
 
    const editAutorization=(i)=> {
        let authorizationsArr = [...authorizations];
        setEditAuthorizationInfo(authorizationsArr[i]);
        setOpenEditModal(true);
    }

    const deleteAuthorization = (i) => {
        deleteIssueAuthorization(i);
    }

    const validateForm = (e) => {
        if(authorizations.length !== 0) {
            props.sectionValidated('issuanceAuthorization', true);
            props.openWidget('statutories');
        } else {
            props.sectionValidated('issuanceAuthorization', false);
            alert("Please add at least one issuance authorization.");
        }           
    }    

    const handleViewIssuancesClick = (e, auth) => {
        e.stopPropagation();
        setSelectedAuthInfo(auth)
        setOpenViewIssuances(true);
    }
    const handleAuthSummaryClick = (e, auth) => {
        e.stopPropagation();
        setSelectedAuthInfo(auth)
        setOpenAdtrSummary(true);
    }
    
    return (
        <Card>
            <Form className="form" id="issuanceAuthorizationForm">
            <h3><span className="required">* </span>Issuance Authorization
                {' '}<AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    <Modal.Body>
                        <div>
                        Prepopulated from data submitted on the RPDI, update if necessary. Identify the authorization(s) under which this
proposed debt will be issued, either by vote of the electorate (ballot measure) or by act of the governing body
(resolution). Multiple authorizations may be entered for the same issuance.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }
            </h3>

           {isLoading ? <Spinner /> :
            authorizations.length !== 0 && authorizations.map((auth, i) => {
                return (
                    <Row key={i}>
                        <div>
                            <span style={{float: "right"}}>
                                <FaPencilAlt className="custom-icon" onClick={()=>editAutorization(i)}/>{"   "}
                                <VscTrash className="custom-icon" onClick={()=>deleteAuthorization(auth.issuerAuthorizationId)}/>
                            </span>
                            <h3 className={classes.authheading}>Issuance Authorization {i+1}</h3>
                        </div>
                        <div className='py-2'>
                            <button
                            type="button"
                            className="link-button dk-blue-color me-4"
                            onClick={(e) => handleViewIssuancesClick(e, auth)}
                            >
                                View Issuances
                            </button>
                            <button
                            type="button"
                            className="link-button dk-blue-color"
                            onClick={(e) => handleAuthSummaryClick(e, auth)}
                            >ADTR Summary
                            </button>
                        </div>

                        <Row>
                            <Col md={5}>Authorization Name : </Col>
                            <Col md={5}>{auth.authorizationName} </Col>
                        </Row>
                        <Row>
                            <Col md={5}>Original Authorized Amount : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.originalAuthorizedAmt)} </Col>
                        </Row>
                        <Row>
                            <Col md={5}>Authorization Date : </Col>
                            <Col md={5}>{CommonService.dateFormatter(auth.authorizationDate) }</Col>
                        </Row>
                        <Row>
                            <Col md={5}>Amount Authority Reduced (By this Issue) : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.authorityReducedAmt)} </Col>
                        </Row>
                        <Row>
                            <Col md={5}>Amount Authority Replenished (By this Issue) : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.authorityReplenishAmt)}</Col>
                        </Row>
                        <Row>
                            <Col md={5}>Net Change (By this Issue) : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.authorityReplenishAmt - auth.authorityReducedAmt)} </Col>
                        </Row>
                        <hr/>
                    </Row>

                )
            })}
            
            {openAddModal && <AddIssuanceAuthorization onModalClose={handleModalClose} finalSaleId={issueId}/> }
            {openEditModal && <EditIssuanceAuthorization onModalClose={handleEditModalClose} authInfo={editAuthorizationInfo} issueId={issueId}/> }
            <button type="button" className="custom-button" onClick={() => addAuthorization()}>Add Authorization</button>
            
            <div className="btn-div">
            {/* <button className="custom-button" type="button">Back</button> */}
            <button className="custom-button fright" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </Form>

            {openViewIssuances && <ViewIssuances onModalClose={() => setOpenViewIssuances(false)} authInfo={selectedAuthInfo} />}
            {openAdtrSummary && <AuthorizationSummary onModalClose={() => setOpenAdtrSummary(false)} authInfo={selectedAuthInfo} />}

        </Card>
    )
}

export default IssuanceAuthorization
