import {useState, useEffect, useContext} from 'react'
import CollapsibleWidgets from'./collapsibleWidgets'
import MelloRoosProgressBar from './melloRoosProgressBar'
import {VscExpandAll, VscCollapseAll} from 'react-icons/vsc'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { IssuanceDetailContext } from '../../UserContext'
import { Spinner } from 'react-bootstrap'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import { UserContext } from '../../UserContext'
import CommonService from '../../services/CommonService'
import { toast } from 'react-toastify'
import EditReasonHistory from '../../common/EditReasonHistory'
import ReviewComponent from './reviewComponent'
import { renderToString } from 'react-dom/server'
import HtmlPdfService from '../../services/HtmlPdfService'
import History from '../../common/History'
import Loader from '../../common/Loader'
import { configManager } from '../../configManager'

const MelloRoosYFS = (props) => {
    const userEmail = useContext(UserContext);
    const params = useParams();
    const location = useLocation();
    const cdiacNo = params.cdiac;
    let melloRoos = location.state.reportData;

    if(!location.state.reportData){
        melloRoos = location.state.melloRoos
    }
    const [expandAll, setExpandAll] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [melloRoosDetails, setMelloRoosDetails] =  useState([]);
    const [contact, setContactDetails] = useState([]);
    const [showEditReasonHistory, setShowEditReasonHistory] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [foreclosures,setForeclosures] =  useState([]);
    const [issueComments,setIssueComments] =  useState([]);
    const [issueDocuments,setIssueDocuments] =  useState([]);
    const [showSpinner,setShowSpinner] =  useState(false);

    const [issuanceDetail, setIssuanceDetails] = useState([]);
    const [openSection, setOpenSection] = useState({
        issuance: true,
        fundBalance: false,
        assessedValue: false,
        taxCollection: false,
        delinquentReporting: false,
        docUpload: false,
        foreclosure: false,
        retiredIssues: false,
        filingContact: false,
        comments: false,
        review: false
    });

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state:{dashboardSelection: dashboardSelectionObj}} );
    }

    const toggleAllWidgets = () => {
        setExpandAll( !(expandAll) );
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: !(expandAll) })));
        setOpenSection(openSecObj);
    }

    const toggleWidget = (sectionName) => {
        setOpenSection({...openSection, [sectionName]: !(openSection[sectionName])})
    }

    const openWidget = (sectionName) => {
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: false })));
        openSecObj[sectionName] = !(openSecObj[sectionName]);
        setOpenSection(openSecObj);
    }
    const handlePrint = () => {
        setShowSpinner(true);
        const html = renderToString(<ReviewComponent issuanceDetail={issuanceDetail} 
            melloRoos={melloRoos} 
            melloRoosDetails={melloRoosDetails} 
            contact={contact} 
            issueComments={issueComments} 
            issueDocuments={issueDocuments} 
            foreclosures={foreclosures} />)
        
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getPdf",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "html": html,
                "id" : melloRoos.melloRoosId,
                "cdiacNbr" :  cdiacNo,
                "status": 'D',
                "reportType" : "MLR",
                "createUser":  CommonService.getUserName(userEmail), 
            },
          };

        HtmlPdfService.generatePdf(obj)
        .then((res) => { 
             var base64EncodedPDF = res.data;
             var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
             var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
             var url = window.URL.createObjectURL(blob);
             setShowSpinner(false);
             window.open(url);

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
        
    }

    const getRetireFlagDesc = (finalSaleDetail)  => {
        switch(finalSaleDetail.mlrRetireFlag){
            case "R" :
              return "Redeemed Entirely";
            case "M" :
              return "Matured";
            case "N" :
              return "Not Retired";
            case "O" :
              return "Other";
            default:
                return "";
            } 
    }

    useEffect(() => {

        async function getIssuanceDetailsByFinalSaleId() {
            
            if(melloRoos.finalSaleId !== null){
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "getIssuanceDetailsByFinalSaleIdMelloRoos",
                    hdrStruct: null,
                    queryParam: null,
                    reqData:{"finalSaleId" : melloRoos.finalSaleId}
                  };
              
                  FinalSaleReportService.getIssuanceDetailsByFinalSaleId(obj)
                    .then((result) => {

                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        }
                        else{
                            let retireFlagDesc = getRetireFlagDesc(result.data.responses[0]);
                            result.data.responses[0].retireFlagDesc = retireFlagDesc;
                            setIssuanceDetails(result.data.responses[0]);
                        }
                      
                     });
            }
        }

        async function getMelloRoos() {

            if(melloRoos.melloRoosId !== null){
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "getMelloRoos",
                    hdrStruct: null,
                    queryParam: null,
                    reqData:{"id" : melloRoos.melloRoosId}
                  };
                  FinalSaleReportService.getMelloRoos(obj)
                    .then((result) => {                        
                        if (result.data.errors && result.data.errors.length > 0){
                            toast.error(result.data.errors[0].message);
                            return;
                        }
                        else{
                            setMelloRoosDetails(result.data.responses[0]);
                            getFilingContact(result.data.responses[0].contactId);
                        }

                    });
            }
        }

        async function getFilingContact(contactId) {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getContactById",
                hdrStruct: null,
                queryParam: null,
                reqData:{"id" : contactId}
                };
            
                FinalSaleReportService.getContactDetails(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        toast.error(result.data.errors[0].message);
                        return;
                    }
                    else{
                        setContactDetails(result.data.responses[0]);
                    }

                });
        }

        async function getForeclosures() {
            var obj = {
              msgId: null,
              sysId: null,
              opr: "getForeclosures",
              hdrStruct: null,
              queryParam: null,
              reqData:{"id" : melloRoos.melloRoosId}
            };
            await FinalSaleReportService.getForeclosures(obj)
              .then((result) => {
                  if (result.data.errors && result.data.errors.length > 0){
                      console.error(result.data.errors[0].message);
                      return;
                  }
                  else{
                    setForeclosures(result.data.responses[0]);
                  }
              });
        }
        getIssuanceDetailsByFinalSaleId();
        getMelloRoos();
        getForeclosures();

        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
        // eslint-disable-next-line
      }, []);

      const updateIssuanceDetails = (finalSaleInfo) => {
        let retireFlagDesc = getRetireFlagDesc(finalSaleInfo);
        finalSaleInfo.retireFlagDesc = retireFlagDesc;
        setIssuanceDetails(finalSaleInfo);
      }
      const updateMelloRoosDetails = (melloRoosInfo) => {
        setMelloRoosDetails(melloRoosInfo);
      }
      const updateIssueComments = (commentsInfo) => {
        setIssueComments(commentsInfo);
      }
      const updateIssueDocuments = (documentsInfo) => {
        setIssueDocuments(documentsInfo);
      }
      const updateForeClosures = (foreclosuresInfo) => {
        setForeclosures(foreclosuresInfo);
      }
    return (
        <div>
            <div className="panel panel-default" id="stepspanel" style={{marginBottom: '0px'}}>
        
                <div className="panel-heading">
                    <div className="container">
                        <div className="fright" style={{margin: '5px'}}>
                        {melloRoosDetails && melloRoosDetails.statusFlag === 'R' &&  <button className="custom-button me-2" onClick={() => window.open(`${configManager.debtWatchUrl}/issue-level-detail/${cdiacNo}`)}>View in DebtWatch</button> }
                        {melloRoosDetails && melloRoosDetails.statusFlag === 'D' && <button className="custom-button" onClick={()=>handlePrint()}>Print PDF (Draft)</button>}
                        </div>
                        <h1 className="main-title">Mello Roos Yearly Fiscal Status Report</h1>
                    </div>
                </div>
                <div className="panel-body">
                    <div className="container">
                        <MelloRoosProgressBar openSection={openWidget} />
                        <div className="center-align">
                            <div style={{float: 'left'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>backToDashboard()}>Back to Dashboard</button>
                            </div>
                            <button
                                type="button"
                                style={{textDecoration: 'none', fontWeight: '500'}}
                                className="dk-blue-color link-button" onClick={()=>toggleAllWidgets()}>{expandAll ? <VscCollapseAll /> : <VscExpandAll /> } {expandAll ? "Collapse All" : "Expand All" }</button>
                            <div style={{float: 'right'}}>
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>setShowHistory(true)}>Show History</button>
                                {(melloRoos.issueStatusFlag === "E" || melloRoos.issueStatusFlag === "R") &&
                                <><br />
                                <button
                                type="button"
                                className="dk-blue-color link-button" 
                                onClick={()=>setShowEditReasonHistory(true)}>Show Edit Reason History</button>
                                </>}
                            </div>
                        </div>
                        <br/>
                        <div style={{color: 'green'}}>
                            <em>CDIAC # {cdiacNo}</em>
                            <br/>
                            <em>Balances Reported as of {CommonService.dateFormatterMonthDayYear(melloRoos.fiscalYrEndDate)}</em>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <Spinner animation="border" variant="primary"/> :
                <>
                    <IssuanceDetailContext.Provider value={issuanceDetail}>
                        <CollapsibleWidgets 
                        openSectionObj={openSection} 
                        toggleWidget={toggleWidget} 
                        openSection={openWidget} 
                        melloRoos={melloRoos} 
                        melloRoosDetails={melloRoosDetails} 
                        contact={contact} 
                        foreclosureDetails={foreclosures}
                        issueComments={issueComments}
                        issueDocuments={issueDocuments}
                        updateIssuanceDetails={updateIssuanceDetails} 
                        updateMelloRoosDetails={updateMelloRoosDetails}
                        updateIssueComments={updateIssueComments}
                        updateForeClosures={updateForeClosures}
                        updateIssueDocuments={updateIssueDocuments}
                        />
                    </IssuanceDetailContext.Provider>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <div className="container">
                                <div className="panel-body" style={{ padding: '10px 0 0' }}>
                                    <button className="custom-button" onClick={() => backToDashboard()}>Back to Dashboard</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {showSpinner && <Loader />} 
            {showHistory && <History onModalClose={()=>setShowHistory(false)} issueId={melloRoos.melloRoosId} reportTypeId="mello_roos_id"/>}
           {showEditReasonHistory && <EditReasonHistory onModalClose={()=>setShowEditReasonHistory(false)} issueId={melloRoos.melloRoosId} cdiacCodeValueCode="MLRER"/>}
        </div>
    )
}

export default MelloRoosYFS
