import React, { useState, useContext, useEffect } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import InvoiceService from "../../../services/InvoiceService";
import Alert from "react-bootstrap/Alert";
import CommonService from "../../../services/CommonService";
import { toast } from "react-toastify";
import { UserContext } from "../../../UserContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyInput from "../../formElements/CurrencyInput";
import { validateFormFields } from "../../../utils/index";
import BaseService from "../../../services/BaseService";


const EditInvoice = (props) => {
  const user = useContext(UserContext);
  const { onModalClose, invoiceInfo } = props;
  const [formErrors, setFormErrors] = useState({});
  const [stateValues, setStateValues] = useState(invoiceInfo);
  const invoiceId = invoiceInfo.invoiceId;

  const [errorMessage, setErrorMessage] = useState();

  const handleDateChange = (e, key) => {
    setStateValues((prevState) => ({
      ...prevState,
      [key]: e,
    }));
  };
  useEffect(() => {
    async function fetchInvoice(){
      var obj = {
        msgId: null,
        sysId: null,
        opr: "getInvoicePaymentDueAmt",
        hdrStruct: null,
        queryParam: null,
        reqData: { id: invoiceId },
      };
      BaseService.processRequest(obj)
        .then((res) => res)
        .then((result) => {
          if(result.data.responses && result.data.responses[0]) {
            setStateValues((prevState) => ({
              ...prevState,
              "paymentDueAmt": result.data.responses[0],
            }));
          }
        });
    }
    fetchInvoice(invoiceId);
  },[invoiceId]);
  // useEffect(() => {
  //   async function fetchInvoiceCommentTypes() {
  //     var obj = {
  //       msgId: "null",
  //       sysId: null,
  //       opr: "GetLookups",
  //       hdrStruct: null,
  //       queryParam: null,
  //       reqData: { cdiacCode: "INVC", active: "Y" },
  //     };

  //     await CodeMaintenanceService.getAllCodes(obj)
  //       .then((res) => res)
  //       .then((result) => {
  //         const filteredList = result.data.responses[0].filter(
  //           (el) => el.activeInd === "Y"
  //         );
  //         const invTypesListNew = [];
  //         filteredList.forEach((invType, i) => {
  //           let obj = {};
  //           obj = invType;
  //           obj.value = invType.cdiacCodeValueId;
  //           obj.label = invType.cdiacCodeValueDesc;
  //           invTypesListNew.push(obj);
  //         });

  //         setInvTypes(invTypesListNew);
  //         setIsLoading(false);
  //       });
  //   }
  //   fetchInvoiceCommentTypes();
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStateValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    let form = e.target.closest("form#editInvoiceForm");
    let formErrors = validateFormFields(form.elements);

    if (Object.keys(formErrors).length > 0) {
      setFormErrors(formErrors);
    } else {
      setFormErrors({});
      var obj = {
        msgId: null,
        sysId: null,
        opr: "updateInvoice",
        hdrStruct: null,
        queryParam: null,
        reqData: {
          invoiceId: stateValues.invoiceId,
          invoiceRef: {
            invoiceId: stateValues.invoiceId,
            finalSaleId: stateValues.finalSaleId,
            invoiceDate: stateValues.invoiceDate,
            invoiceDueAmt:
              typeof stateValues.invoiceDueAmt === "string"
                ? parseFloat(stateValues.invoiceDueAmt.replace(/\$|,/g, ""))
                : stateValues.invoiceDueAmt,
            invoiceStatusFlag: stateValues.invoiceStatusFlag,
            secondNoticeDate: stateValues.secondNoticeDate,
            thirdNoticeDate: stateValues.thirdNoticeDate,
            finalNoticeDate: stateValues.finalNoticeDate,
            rptCollectionNbr: stateValues.rptCollectionNbr,
            rptCollectionDate: stateValues.rptCollectionDate,
            emailId: stateValues.emailId,
            lastUpdateUser: CommonService.getUserName(user),
          },
        },
      };

      InvoiceService.updateInvoice(obj).then((result) => {
        if (result.data.errors && result.data.errors.length > 0) {
          setErrorMessage(result.data.errors[0].message);
        } else {
          toast.success("Invoice Updated Successfully!");
          onModalClose();
        }
      });
    }
  };

  return (
    <Modal show={true} backdrop="static" onHide={onModalClose}>
      <Modal.Header closeButton>
        <h3>Invoice Detail:</h3>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert variant="danger">
            <p>{errorMessage}</p>
          </Alert>
        )}
        <form id="editInvoiceForm">
          <Row>
            <Col md={6}>
              <label>Invoice #:</label>
              <Form.Control
                type="text"
                name="invoiceId"
                value={stateValues.invoiceId}
                onChange={(e) => handleChange(e)}
                maxLength="16"
                disabled
              />
            </Col>
            <Col md={6}>
              <label>CDIAC #:</label>
              <Form.Control
                type="text"
                name="cdiacNbr"
                value={stateValues.cdiacNbr}
                onChange={(e) => handleChange(e)}
                disabled
              />
            </Col>
            <Col md={6}>
              <label>Issuer Name:</label>
              <Form.Control
                type="text"
                name="issuerName"
                value={stateValues.issuerName}
                disabled
              />
            </Col>
            <Col md={6}>
            <div className="control">
              <CurrencyInput
                label="Payment Amount Due"
                name="paymentDueAmt"
                value={stateValues.paymentDueAmt}
                disabled
              />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <CurrencyInput
                  placeholder="0.00"
                  label="Invoiced Amount Due"
                  name="invoiceDueAmt"
                  type="text"
                  value={
                    stateValues.invoiceDueAmt ? stateValues.invoiceDueAmt : ""
                  }
                  onChange={(e) => handleChange(e)}
                  error={formErrors.invoiceDueAmt}
                  validations={["required", "max:999999999999.99"]}
                  isrequired="true"
                />
              </div>
            </Col>
          
            <Col md={6}>
              <div className="control">
                <label>
                  <span className="required">* </span>Date Invoiced:{" "}
                </label>
                <div>
                    <DatePicker
                      selected={
                        stateValues.invoiceDate
                          ? new Date(stateValues.invoiceDate)
                          : null
                      }
                      dateFormat="M/d/yyyy"
                      maxDate={new Date(9999, 12, 31)}
                      name="invoiceDate"
                      onChange={(e) => handleDateChange(e, "invoiceDate")}
                      error={formErrors && formErrors["invoiceDate"]}
                      required={true}
                      showIcon
                    />
                  {formErrors.invoiceDate && (
                    <p className="error">Invoice Date is required</p>
                  )}
                  <input
                    type="hidden"
                    data-validations={["required"]}
                    value={stateValues.invoiceDate}
                    name="invoiceDate"
                  />
                </div>
              </div>
            </Col>
            
          </Row>
          <Row>
            <Col md={6}>
              <div className="control">
                <label>2nd Notice Date:</label>
                <div>
                  <span>
                    <DatePicker
                      selected={
                        stateValues.secondNoticeDate
                          ? new Date(stateValues.secondNoticeDate)
                          : null
                      }
                      dateFormat="M/d/yyyy"
                      maxDate={new Date(9999, 12, 31)}
                      name="secondNoticeDate"
                      onChange={(e) => handleDateChange(e, "secondNoticeDate")}
                      error={formErrors && formErrors["secondNoticeDate"]}
                      showIcon
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="control">
                <label>3rd Notice Date:</label>
                <div>
                  <span>
                    <DatePicker
                      selected={
                        stateValues.thirdNoticeDate
                          ? new Date(stateValues.thirdNoticeDate)
                          : null
                      }
                      dateFormat="M/d/yyyy"
                      maxDate={new Date(9999, 12, 31)}
                      name="thirdNoticeDate"
                      onChange={(e) => handleDateChange(e, "thirdNoticeDate")}
                      error={formErrors && formErrors["thirdNoticeDate"]}
                      showIcon
                    />
                  </span>
                </div>
              </div>
            </Col>
            {/*<Col md={6}>
              <div className="control">
                <label>Final Notice Date:</label>
                <div>
                  <span>
                    <DatePicker
                      selected={
                        stateValues.finalNoticeDate
                          ? new Date(stateValues.finalNoticeDate)
                          : null
                      }
                      dateFormat="M/d/yyyy"
                      maxDate={new Date(9999, 12, 31)}
                      name="finalNoticeDate"
                      onChange={(e) => handleDateChange(e, "finalNoticeDate")}
                      error={formErrors && formErrors["finalNoticeDate"]}
                    />
                    <FaCalendarAlt className="date-picker-icon" />
                  </span>
                </div>
              </div>
            </Col>
             <Col md={6}>
              <div className="control">
                <label>RPT Collection Date:</label>
                <div>
                  <span>
                    <DatePicker
                      selected={
                        stateValues.rptCollectionDate
                          ? new Date(stateValues.rptCollectionDate)
                          : null
                      }
                      dateFormat="M/d/yyyy"
                      maxDate={new Date(9999, 12, 31)}
                      name="rptCollectionDate"
                      onChange={(e) => handleDateChange(e, "rptCollectionDate")}
                      error={formErrors && formErrors["rptCollectionDate"]}
                    />
                    <FaCalendarAlt className="date-picker-icon" />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label>RPT Collection Number:</label>
              <Form.Control
                type="text"
                name="rptCollectionNbr"
                value={stateValues.rptCollectionNbr}
                onChange={(e)=>handleChange(e)}
              />
            </Col> 
            <Col md={6}>
              <div className="control">
                  <label>Status: </label>
                  <Form.Select name="invoiceStatusFlag" defaultValue={stateValues.invoiceStatusFlag} onChange={(e)=>handleChange(e)}>
                    <option value="OP">Open</option>
                    <option value="PE">Pending</option>
                    <option value="PD">Paid</option>
                  </Form.Select>
              </div>
            </Col>*/}
          </Row>

          <Row>
{/*
          <label>
            Status
          </label>
          <Typeahead
                id="invoiceStatusFlag"
                name="invoiceStatusFlag"
                placeholder="Choose Status Type..."
                labelKey="cdiacCodeValueDesc"
                // onChange={(e) => handleInvChange(e, "issuerTypeCodeId")}
                options={invTypes}
                inputProps={{
                  "data-validations": "required",
                  name: "invoiceStatusFlag",
                }}
                isLoading={isLoading}
              />
*/}
{/*}              
              {formErrors["invoiceCommentTypeCodeId"] && (
                <span className="error">
                  {formErrors["invoiceCommentTypeCodeId"]}
                </span>  
              */}
{/*
            <Col md={6}>
              <label>Status</label>
              <Form.Control
                type="text"
                name="invoiceStatusFlag"
                value={stateValues.invoiceStatusFlag}
                disabled
              />
            </Col>
                    */}
          </Row>

          <div className="btn-div">
            <div className="fright">
              <button
                type="button"
                className="custom-button-secondary  btn-secondary"
                onClick={onModalClose}
              >
                Cancel
              </button>{" "}
              <button
                className="custom-button"
                type="button"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditInvoice;
