import { useState, useEffect, useContext } from 'react'
import { Row, Col, Modal, FormSelect} from 'react-bootstrap'
import {FormInput} from'../components/formElements/FormInput'
import CodeMaintenanceService from '../services/CodeMaintenanceService'
import MaskedInput from 'react-text-mask'
import { Typeahead } from 'react-bootstrap-typeahead'
import { validateFormFields } from '../utils/index'
import IssuerFilerService from '../services/IssuerFilerService'
import { toast } from 'react-toastify'
import CommonService from "../services/CommonService";
import { UserContext } from "../UserContext";
import swal from 'sweetalert'

const IssuerRegistrationForm = (props) => {
    
    const {issuerRequestInfo, onModalClose, action} = props;
    const user = useContext(UserContext);
    const [orgTypes, setOrgTypes] = useState([]);
    const [orgDivisions, setOrgDivisions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [issuerRequest, setIssuerRequest] = useState(issuerRequestInfo);
    const disable = (action === 'approveDeny') ? true : false;
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [socrataGroups, setSocrataGroups] = useState([]);
    const [counties, setCounties] = useState([]);
    const [issuerAssocs, setIssuerAssocs] = useState([]);
    const [issuers, setIssuers] = useState([]);
    const handleCommentValue = (e, key) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setIssuerRequest(prevState => ({
            ...prevState, [key] : e.target.value
        }))
    };
    useEffect(()=>{
        async function fetchOrgTypes() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGT", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                const orgTypesListNew = [];
                filteredList.forEach((orgType, i)=>{
                    let obj = {};
                    obj = orgType;
                    obj.value = orgType.cdiacCodeValueId;
                    obj.label = orgType.cdiacCodeValueDesc;
                    orgTypesListNew.push(obj);
                })
                
                setOrgTypes(orgTypesListNew);
                setIsLoading(false);
            })
        }

        async function fetchOrgDivisions() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGD" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                setOrgDivisions(result.data.responses[0]);
            })
        }

        async function fetchSocrataGroups() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "SOCR", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                setSocrataGroups(result.data.responses[0]);
            })
        }

        fetchOrgTypes();
        fetchOrgDivisions();
        fetchSocrataGroups();
        getCounties();
        getIssuerAssocs();
        getIssuers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getCounties = async () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getCounties",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
          };
      
        await CodeMaintenanceService.getAllCodes(obj)
        .then((res) => res)
        .then((result) => {
            setCounties(result.data.responses[0]);
        })
    }

    const handleContactCountyChange = e => {
        const selectedVal =  e.length > 0 ? e[0].countyCode : []

        setIssuerRequest(prevState => ({
            ...prevState, 'issuerCountyCode' : selectedVal
        }))
        
    }

    const handleOrgChange = (e, key) => {
        const val = (key === "issuerName") ? e.target.value : (e.length ? e[0].cdiacCodeValueId : "");

        setIssuerRequest(prevState => ({
            ...prevState, [key] : val
        }))
    }
    const handleIssuerRequestContactChange = e => {
        const {name} = e.target;
        let value = e.target.value;
        if(name === 'issuerCityName') {
            value = value.replace(/[^A-Za-z\s]/ig, '');
        }
        if(name === 'issuerStateCode') {
            value = value.replace(/[^A-Za-z]/ig, '');
        }
        if(name === 'issuerContactPhoneExtn') {
            value = value.replace(/[^\d]$/, "").substr(0, 10);
        }
        if(name === 'issuerZipCode') {
            value = value.replace(/[^\d{5}-]$/, "").substr(0, 10);
        }

        setIssuerRequest(prevState => ({
            ...prevState, [name] : value
        }))
    }

    const handleRequestStatusChange = (e, key) => {
        const val = (key === "requestStatus") ? e.target.value : "S";

        setIssuerRequest(prevState => ({
            ...prevState, [key] : val
        }))
    }

    const handleSocrataGroupChange = (e) => {
        const selectedValue =  e.length > 0 ? e[0].cdiacCodeValueId : ""
        setIssuerRequest(prevState => ({
            ...prevState, 'issuerSocrataGroupCodeId' : selectedValue
        }))
    }

    const handleIssuerShortNameChange = e => {
        const {name, value} = e.target;
        setIssuerRequest(prevState => ({
            ...prevState, [name] : value
        }))
    }
   
    const updateIssuerRequest = () => {

        issuerRequest.createUser = CommonService.getUserName(user);
        issuerRequest.lastUpdateUser =  CommonService.getUserName(user);

        var obj = {
            msgId: null,
            sysId: null,
            opr: "putIssuerRequest",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "requestId" : issuerRequest.requestId,
                "issuerRequest" : issuerRequest,
                "env" : "I"
            },
          };
      
        IssuerFilerService.updateIssuerFilerRequest(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors[0]) {
                toast.error(result.data.errors[0].message);
            } else {
                toast.success("Request Updated.");
                onModalClose();
            }
        });
    }
    const getIssuerAssocs = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssuerReqIssuerAssocs",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                requestId: issuerRequest.requestId 
             },
          };
      
        await IssuerFilerService.getIssuers(obj)
            .then((res) => res)
            .then((result) => {
              Object.keys(result.data.responses).forEach((key) => {
                setIssuerAssocs(result.data.responses[key]);
              });
            });
    }
    const getIssuers = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getIssuers",
            hdrStruct: null,
            queryParam: null,
            reqData: { },
          };
      
          await IssuerFilerService.getIssuers(obj)
            .then((res) => res)
            .then((result) => {
              Object.keys(result.data.responses).forEach((key) => {
                  setIssuers(result.data.responses[key]);
              });
              setIsLoading(false);
            });
    }
    const handleAddIssuer = () => {
        setIssuerAssocs(prevState=>([
            ...issuerAssocs, {"issuerId" : ''}
        ])
        )
    }
    const handleFilerIssuerAssocChange = (e, i) => {
        let issuerAssocsArr = [...issuerAssocs];
        issuerAssocsArr[i].issuerId = e.length > 0 ? e[0].issuerId : [];
        setIssuerAssocs(issuerAssocsArr);
    }
    const saveIssuer = async (i) => {
        const issuerAssocsArr = [...issuerAssocs]

        var obj = {
            msgId: "null",
            sysId: null,
            opr: "postIssuerReqIssuerAssoc",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "issuerReqIssuerAssoc" : {
                    "issuerId": issuerAssocsArr[i].issuerId,
                    "requestOrgId" : issuerRequest.requestId
                }
            },
          };
      
        await IssuerFilerService.createFilerIssuerAssoc(obj)
        .then((res) => res)
        .then((result) => {
            getIssuerAssocs();
            toast.success("Issuer Issuer Association Created Successfully");
        })
    }
    const removeIssuer = async (i) => {

        swal("Are you sure you want to remove this association?", {
            buttons: ["No", "Yes"],
        }).then((value) => {
           if(value){
            const issuerAssocsArr = [...issuerAssocs]

            if(issuerAssocsArr[i].issuerId !== ''){
                var obj = {
                    msgId: "null",
                    sysId: null,
                    opr: "deleteIssuerReqIssuerAssoc",
                    hdrStruct: null,
                    queryParam: null,
                    reqData: { 
                        "issuerReqIssuerId": issuerAssocsArr[i].issuerRequestIssuerId
                     },
                  };
              
                 IssuerFilerService.deleteFilerIssuerAssoc(obj)
                .then((res) => res)
                .then((result) => {
        
                    if (i !== -1) {
                        issuerAssocsArr.splice(i, 1);
                        setIssuerAssocs(issuerAssocsArr);
                      }
                    toast.success("Issuer Issuer Association Removed Successfully");
                })
            }
           }
        });
    }
    const submitForm = (e) => {
        let form = e.target.closest('form#filerForm');
        let formErrors = validateFormFields(form.elements);

        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
            updateIssuerRequest();
        }
    }
    return (
        <Modal show={true} onHide={onModalClose}>
            <Modal.Header closeButton>
                <h3>Request for New Issuer Organization</h3>
            </Modal.Header>

            <Modal.Body>
            <div className="panel panel-default">
                <div className="form">
                    <form id="filerForm">
                        <div className="organizationForm">
                            <Row>
                                <Col md={6} className="control">
                                <FormInput 
                                    type="text" 
                                    name="issuerName" 
                                    label="Issuer Organization Name"
                                    isrequired="true"
                                    validations={["required"]}
                                    value={issuerRequest.issuerName}
                                    onChange={(e)=>handleOrgChange(e, 'issuerName')}
                                    disabled = {disable}
                                    maxLength="120"
                                />
                                {formErrors['issuerName'] && <span className="error">{formErrors['issuerName']}</span>}
                                </Col>
                                
                                <Col md={6}>
                                    <label><span className="required">*</span>Organization Type</label>
                                    <Typeahead 
                                        id="issuerTypeCodeId"
                                        name="issuerTypeCodeId"
                                        placeholder="Choose Org Type..."
                                        labelKey="cdiacCodeValueDesc"
                                        selected = {
                                            orgTypes.filter(option => 
                                               option.cdiacCodeValueId === issuerRequest.issuerTypeCodeId)
                                         }
                                        onChange={(e)=>handleOrgChange(e, 'issuerTypeCodeId')}
                                        options={orgTypes}
                                        inputProps={{ 'data-validations': 'required', name: 'issuerTypeCodeId' }}
                                        isLoading={isLoading}
                                        disabled = {disable}
                                        />
                                {formErrors['issuerTypeCodeId'] && <span className="error">{formErrors['issuerTypeCodeId']}</span>}
                                </Col>

                                <Col md={6} className="control">
                                    <label>Issuer Short Name</label>
                                    <input type="text" name="issuerShortName" value={issuerRequest.issuerShortName} 
                                    onChange={(e)=>handleIssuerShortNameChange(e)}
                                    />
                                </Col>

                                <Col md={6}>
                                    <label>Socrata Groups</label>
                                    <Typeahead 
                                        id="issuerSocrataGroupCodeId"
                                        name="issuerSocrataGroupCodeId"
                                        placeholder="Choose Socrata Group..."
                                        labelKey="cdiacCodeValueDesc"
                                        selected = {
                                            socrataGroups.filter(option => 
                                               option.cdiacCodeValueId === issuerRequest.issuerSocrataGroupCodeId)
                                         }
                                        onChange={(e)=>handleSocrataGroupChange(e)}
                                        options={socrataGroups}
                                        inputProps={{ name: 'issuerSocrataGroupCodeId' }}
                                        isLoading={isLoading}
                                        disabled = {disable}
                                        />
                                {formErrors['issuerSocrataGroupCodeId'] && <span className="error">{formErrors['issuerSocrataGroupCodeId']}</span>}
                                </Col>
                            
                                <Col md={6}>
                                    <label><span className="required">*</span>Division</label>
                                    <Typeahead 
                                        id="issuerDivisonCodeId"
                                        name="issuerDivisonCodeId"
                                        placeholder="Choose Org Division..."
                                        labelKey="cdiacCodeValueDesc"
                                        selected = {
                                            orgDivisions.filter(option => 
                                               option.cdiacCodeValueId === issuerRequest.issuerDivisonCodeId)
                                         }
                                        onChange={(e)=>handleOrgChange(e, 'issuerDivisonCodeId')}
                                        options={orgDivisions}
                                        disabled = {disable}
                                        inputProps={{ 'data-validations': 'required', name: 'issuerDivisonCodeId' }}
                                        />
                                {formErrors['issuerDivisonCodeId'] && <span className="error">{formErrors['issuerDivisonCodeId']}</span>}
                                </Col>
                                                   
                                <Col md={6} className="control">
                                    <FormInput type="text" label="Address Line1" name="issuerAddressLine1" isrequired="true"  
                                    validations={["required"]} value={issuerRequest.issuerAddressLine1} 
                                    onChange={(e)=>handleIssuerRequestContactChange(e)}
                                    disabled = {disable}/>
                                    {formErrors['issuerAddressLine1'] && <span className="error">{formErrors['issuerAddressLine1']}</span>}
                                </Col>
                                <Col md={6} className="control">
                                    <label>Address Line2:</label>
                                    <input type="text" name="issuerAddressLine2" value={issuerRequest.issuerAddressLine2} 
                                    onChange={(e)=>handleIssuerRequestContactChange(e)}
                                    disabled = {disable}/>
                                </Col>
                                <Col md={6} className="control">
                                    <label>Address Line3:</label>
                                    <input type="text" name="issuerAddressLine3" value={issuerRequest.issuerAddressLine3} 
                                    onChange={(e)=>handleIssuerRequestContactChange(e)}
                                    disabled = {disable}/>
                                </Col>
                                <Col md={6} className="control">
                                    <label>Address Line4:</label>
                                    <input type="text" name="issuerAddressLine4" value={issuerRequest.issuerAddressLine4} 
                                    onChange={(e)=>handleIssuerRequestContactChange(e)}
                                    disabled = {disable}/>
                                </Col>

                                <Col md={6}>
                                    <label><span className="required">*</span>Agency Type</label>
                                    <Typeahead 
                                        id="issuerAgencyTypeCodeId"
                                        name="issuerAgencyTypeCodeId"
                                        placeholder="Choose Agency Type..."
                                        labelKey="cdiacCodeValueDesc"
                                        selected = {
                                            orgTypes.filter(option => 
                                               option.cdiacCodeValueId === issuerRequest.issuerAgencyTypeCodeId)
                                         }
                                        onChange={(e)=>handleOrgChange(e, 'issuerAgencyTypeCodeId')}
                                        options={orgTypes}
                                        inputProps={{ 'data-validations': 'required', name: 'issuerAgencyTypeCodeId' }}
                                        isLoading={isLoading}
                                        disabled = {disable}
                                        />
                                {formErrors['issuerAgencyTypeCodeId'] && <span className="error">{formErrors['issuerAgencyTypeCodeId']}</span>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} className="control">
                                    <FormInput type="text" label="City" name="issuerCityName" isrequired="true" 
                                    validations={["required"]} value={issuerRequest.issuerCityName} 
                                    maxLength="50"
                                    onChange={(e)=>handleIssuerRequestContactChange(e)}
                                    disabled = {disable}/>
                                    {formErrors['issuerCityName'] && <span className="error">{formErrors['issuerCityName']}</span>}
                                </Col>
                                <Col md={3} className="control">
                                    <FormInput type="text" label="State" name="issuerStateCode" isrequired="true"  
                                    validations={["required", "minLength:2"]} maxLength="2" 
                                    value={issuerRequest.issuerStateCode} onChange={(e)=>handleIssuerRequestContactChange(e)}
                                    disabled = {disable}/>
                                    {formErrors['issuerStateCode'] && <span className="error">{formErrors['issuerStateCode']}</span>}
                                </Col>
                                <Col md={3} className="control">
                                    <FormInput type="text" label="Zip Code" name="issuerZipCode" isrequired="true"  
                                    validations={["required","zipCode"]} 
                                    value={issuerRequest.issuerZipCode}
                                    onChange={(e)=>handleIssuerRequestContactChange(e)}
                                    disabled = {disable}/>
                                    {formErrors['issuerZipCode'] && <span className="error">{formErrors['issuerZipCode']}</span>}
                                </Col>
                                <Col md={3} className="control">
                                    <label><span className="required">*</span>County</label>
                                    <Typeahead id="countyCode" 
                                        name="issuerCountyCode"
                                        placeholder="Choose County..."
                                        labelKey="countyName"
                                        selected = {
                                            counties.filter(option => 
                                               option.countyCode === issuerRequest.issuerCountyCode)
                                         }
                                        onChange={(e)=>handleContactCountyChange(e)}
                                        options={counties} isLoading={isLoading}
                                        inputProps={{ 'data-validations': 'required', name: 'issuerCountyCode' }}
                                        disabled = {disable}/>
                                    {formErrors['issuerCountyCode'] && <span className="error">{formErrors['issuerCountyCode']}</span>}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className="control">
                                    <label><span className="required">*</span>Area Code (XXX)</label>
                                    <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                        guide={true}
                                        name="issuerContactPhoneAreaCode"
                                        data-validations={["required", "telNo:3"]}
                                        value={issuerRequest.issuerContactPhoneAreaCode}
                                        onChange={(e)=>handleIssuerRequestContactChange(e)}
                                        onBlur={(e)=>handleIssuerRequestContactChange(e)}
                                    />
                                    {formErrors['issuerContactPhoneAreaCode'] && <span className="error">{formErrors['issuerContactPhoneAreaCode']}</span>}
                                </Col>
                                <Col md={4} className="control">
                                    <label><span className="required">*</span>Phone Number (XXX-XXXX)</label>
                                    <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                        guide={true}
                                        name="issuerContactPhoneNumber"
                                        value={issuerRequest.issuerContactPhoneNumber}
                                        data-validations={["required", "telNo:8"]}
                                        onChange={(e)=>handleIssuerRequestContactChange(e)}
                                        disabled = {disable}
                                    />
                                    {formErrors['issuerContactPhoneNumber'] && <span className="error">{formErrors['issuerContactPhoneNumber']}</span>}
                                </Col>
                                <Col md={4} className="control">
                                    <label>Ext</label>
                                    <input type="text" name="issuerContactPhoneExtn" value={issuerRequest.issuerContactPhoneExtn} 
                                    onChange={(e)=>handleIssuerRequestContactChange(e)}
                                    disabled = {disable}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className="control">
                                    <label>Fax Area Code (XXX)</label>
                                    <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                        guide={true}
                                        name="issuerFaxAreaCode"
                                        value={issuerRequest.issuerFaxAreaCode} 
                                        data-validations={["telNo:3"]}
                                        onChange={(e)=>handleIssuerRequestContactChange(e)}
                                        onBlur={(e)=>handleIssuerRequestContactChange(e)}
                                        disabled = {disable}
                                    />
                                    {formErrors['issuerFaxAreaCode'] && <span className="error">{formErrors['issuerFaxAreaCode']}</span>}
                                </Col>
                                <Col md={4} className="control">
                                    <label>Fax Number (XXX-XXXX)</label>
                                    <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                        guide={true}
                                        name="issuerFaxNumber"
                                        data-validations={["telNo:8"]}
                                        value={issuerRequest.issuerFaxNumber}
                                        onChange={(e)=>handleIssuerRequestContactChange(e)}
                                        disabled = {disable}
                                    />
                                    {formErrors['issuerFaxNumber'] && <span className="error">{formErrors['issuerFaxNumber']}</span>}
                                </Col>
                                <Col md={4} className="control">
                                    <FormInput type="email" name="issuerEmailId" label="E-Mail" isrequired="true"  
                                     value={issuerRequest.issuerEmailId}
                                     validations={["required", "email"]}
                                     onChange={(e)=>handleIssuerRequestContactChange(e)} 
                                     disabled = {disable}/>
                                    {formErrors['issuerEmailId'] && <span className="error">{formErrors['issuerEmailId']}</span>}
                                </Col>
                                <Row>
                                <Col md={4} className="control">
                                <FormInput type="text" label="Contact First Name" value={issuerRequest.issuerContactFirstName} name="issuerContactFirstName" isrequired="true"  validations={["required"]} onChange={(e)=>handleIssuerRequestContactChange(e)}/>
                                    {formErrors['issuerContactFirstName'] && <span className="error">{formErrors['issuerContactFirstName']}</span>}

                                </Col>
                                <Col md={4} className="control">
                                    <label>Contact Middle Name:</label>
                                    <input type="text" name="issuerContactMiddleName" value={issuerRequest.issuerContactMiddleName} onChange={(e)=>handleIssuerRequestContactChange(e)}/>
                                </Col>
                                <Col md={4} className="control">
                                <FormInput type="text" label="Contact Last Name" value={issuerRequest.issuerContactLastName} name="issuerContactLastName" isrequired="true"  validations={["required"]} onChange={(e)=>handleIssuerRequestContactChange(e)}/>
                                    {formErrors['issuerContactLastName'] && <span className="error">{formErrors['issuerContactLastName']}</span>}
                                </Col>
                            </Row>
                            </Row> 
                            <Row>
                        <Col md={6} className="mb-10">
                            <label>Request Status</label>
                            <FormSelect as="select" name="status" value={issuerRequest.requestStatus} 
                            onChange={(e)=>handleRequestStatusChange(e, 'requestStatus')}>
                                <option value="S">Submitted</option>
                                <option value="A">Approved</option>
                                <option value="D">Denied</option>
                            </FormSelect>
                        </Col>
                        {issuerRequest.requestStatus === 'D' &&
                        <div>
                            <label>Denial Comment</label>
                            <div className="comments-box">
                                <textarea name="denielComment" value={issuerRequest.issuerDenyCancelComment}
                                id="comments-input" onChange={(e)=>handleCommentValue(e,'issuerDenyCancelComment')}
                                className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                                <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                            </div>
                        </div>
                        }

                        <div>
                            <label>Issuer Comment</label>
                            <div className="comments-box">
                                <textarea name="denielComment" value={issuerRequest.issuerComment}
                                id="comments-input" onChange={(e)=>handleCommentValue(e,'issuerComment')}
                                className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                                {/* <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span> */}
                            </div>
                        </div>

                    
                    </Row>
                        </div>

                        <div>
                            <div>
                                <label>Please identify the Issuers for whom you would like to file the Issuances: </label>
                                <div>
                                { action === 'edit' && <button className="custom-button" type="button" onClick={()=>handleAddIssuer()}>Add Issuer</button> }
                                    {issuerAssocs && issuerAssocs.map((item, i) => {
                                        return (<Row key={i}>
                                            <Col md={6}>
                                                <Typeahead id="addIssuer" 
                                                placeholder="Choose Issuer..."
                                                labelKey="issuerName"
                                                options={issuers} isLoading={isLoading}
                                                selected = {
                                                    issuers.filter(option => 
                                                       option.issuerId === item.issuerId)
                                                 }
                                                onChange={(e)=>handleFilerIssuerAssocChange(e, i)}/>
                                            </Col>
                                            { action === 'edit'  && 
                                            <Col>
                                                {!item.issuerRequestIssuerId && <button className="custom-button" type="button" onClick={()=>saveIssuer(i)} disabled={item.issuerId === ""}>Save</button>}{" "}
                                                <button className="custom-button" type="button" onClick={()=>removeIssuer(i)}>Remove</button>
                                            </Col>}
                                            
                                        </Row>)
                                    }) 
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="btn-div">
                        <div className="fright">
                            <button className=" custom-button-secondary btn-secondary" type="button" onClick={onModalClose}>Cancel</button> { " " }
                            { action === 'edit' ?
                            <button className=" custom-button" type="button" onClick={(e)=>submitForm(e)}>Update</button>
                            :
                            <>
                            <button className="custom-button-danger" type="button" onClick={(e)=>submitForm(e)}>Deny</button>{ " " }
                            <button className="custom-button-success" type="button" onClick={(e)=>submitForm(e)}>Approve</button>
                            </>
                            }

                        </div>
                        </div>
                    </form>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

export default IssuerRegistrationForm