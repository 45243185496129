import { useState, useEffect } from 'react'
import Card from '../ui/Card'
import { Modal } from 'react-bootstrap'
import { AiFillQuestionCircle } from 'react-icons/ai'
import FinalSaleReportService from "../../services/FinalSaleReportService";
import CommonService from "../../services/CommonService";
import { UserContext } from "../../UserContext"
import { useContext } from 'react'
import { toast } from 'react-toastify';
import CountyMaintenanceService from "../../services/CountyMaintenanceService";
import { Row, Col, Table } from 'react-bootstrap'
import Select from 'react-select'

const Comments = (props) => {
    const {melloRoosDetails} = props;
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [stateValues, setStateValues] = useState({
        issuerComment : melloRoosDetails.issuerComment
    });
    const [isLoading, setIsLoading] = useState(true);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);

    const user = useContext(UserContext);
    const loginUserId = useContext(UserContext);
    const [comment, setComment] = useState("");
    const [issueComments, setIssueComments] = useState([]);
    const [commentTypes, setCommentTypes] = useState([]);
    const [commentTypeCodeId, setCommentTypeCodeId] = useState(null);

    const toggleText = (e, i) => {
        const history = [...issueComments];
        history[i].expanded = !history[i].expanded;
        if(history[i].expanded) {
            history[i].displayComment = history[i].comment;
        } else {
            history[i].displayComment =  history[i].comment.substr(0, 32) + "...";
        }
        setIssueComments(history);
    }

    async function fetchIssueComments () {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getMelloRoosComments",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "issueComment":{
                    "melloRoosId" : melloRoosDetails.melloRoosId 
                }
            },
            };
        
        FinalSaleReportService.getIssueComments(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0 ) {
                toast.error(result.data.errors[0].message);
            }else {
                let commentsArr = [];
                result.data.responses[0].forEach((item, i) => {
                    item.displayComment = item.comment;
                    if( item.comment.length > 32 ) {
                        item.displayComment = item.comment.substr(0, 32) + "...";
                        item.expanded = false;
                    }
                    
                    commentsArr.push(item);
                })
                setIssueComments(commentsArr);
                props.updateIssueComments(commentsArr);
            }
        });
    }
    const handleCommentValue = (e) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setComment(e.target.value);
    };
    const saveComment = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "saveComment",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueComment" : {
                    "melloRoosId" : melloRoosDetails.melloRoosId ,
                    "commentTypeCodeId": commentTypeCodeId,
                    "comment": comment,
                    "createUser": CommonService.getUserName(loginUserId),
                    "contactId": melloRoosDetails.contactId
                }
            }
        };
    
       await FinalSaleReportService.saveComment(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message);
            }
            else{
                fetchIssueComments();
                setCommentTypeCodeId(null);
                setComment("");
            }
        });
    }
    const handleChange = (e) => {
        const {name, value} = e.target;

        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    useEffect(() => {        
        async function fetchCommentTypes () {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: { cdiacCode: "MLCMT", active: "Y" },
            };
        
            CountyMaintenanceService.getAllCounties(obj)
            .then((res) => res)
            .then((result) => {
                let codesArr = [];
                result.data.responses[0].forEach((item, i) => {
                    let Obj = {};
                    Obj['value'] = item.cdiacCodeValueId;
                    Obj['label'] = item.cdiacCodeValueDesc;
                    codesArr.push(Obj);
                });
                setCommentTypes(codesArr);
                setIsLoading(false);
            });
        }
        
        fetchCommentTypes();
        fetchIssueComments();
        // eslint-disable-next-line
    }, []);

    const validateForm = (e) => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "updateMelloRoosComment",
            hdrStruct: null,
            queryParam: null,
            reqData: {  "id":melloRoosDetails.melloRoosId,
                "env" : "I",
                "melloRoos":{
                    "issuerComment" : stateValues.issuerComment,
                    "lastUpdateUser": CommonService.getUserName(user),
                    "emailId": user,
                    "contactId": melloRoosDetails.contactId
                }
            },
          };
      
          FinalSaleReportService.updateMelloRoosComment(obj)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                }
                else{
                    props.updateMelloRoosDetails(result.data.responses[0]);
                    toast.success("Comment updated successfully!");
                }
                
            });
        props.openWidget('review');
    }
    return (
        <Card>
            <div className="form">
                <h3>Comments <AiFillQuestionCircle onClick={() => setShowInstructionsModal(true)} className="help-icon heading-help-icon" />

                    {showInstructionsModal &&
                        <Modal show={true} backdrop="static" onHide={() => setShowInstructionsModal(false)}>
                            <Modal.Body>
                                <div>
                                    Provide any additional information you may have to CDIAC.
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button variant="secondary" onClick={() => setShowInstructionsModal(false)}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    }</h3>
                <div className="comments-box">

                    <textarea name="issuerComment" value={stateValues.issuerComment}
                        id="issuerComment" onChange={(e)=>handleChange(e)}
                        className="form-control" rows="5" maxLength="4000" />
                    <span style={{ position: 'relative', left: '45%', backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                </div>
                <div className="comments-box">
                <div>
                <h3>CDIAC Comments: </h3>
                {issueComments.length > 0 &&
                <Table>
                <thead>
                    <tr>
                        <th>Comment Type</th>
                        <th>Comment</th>
                        <th>Create User</th>
                        <th>Create Date</th>
                    </tr>
                </thead>
                <tbody>
                    {issueComments.map((comment,i)=>{
                            return (
                                <tr key={i}>
                                <td>{comment.cdiacCodeValueDesc}</td>
                                <td>{comment.displayComment}&nbsp; 
                                {comment.hasOwnProperty('expanded') ? 
                                    (comment.expanded ? 
                                    <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show less
                                    </button>
                                    : <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show more
                                    </button>
                                    ) : ""
                                }
                                </td>
                                <td>{comment.createUser}</td>
                                <td>{CommonService.dateFormatter(comment.createDatetime)}</td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
            }
                <Row>
                    <Col md={6}>
                    <label>Comment Type</label>
                    <Select 
                    options={commentTypes} 
                    isLoading={isLoading}
                    onChange={(e)=>setCommentTypeCodeId(e.value)}
                    />
                    </Col>
                    
                </Row>
                <textarea name="comment" value={comment} 
                id="issuerComment" onChange={(e)=>handleCommentValue(e)}
                    className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                    <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                    <span style={{position: 'relative', float: 'right', marginTop: '5px' }}><button type="button" disabled={(commentTypeCodeId !== null && comment !== "") ? false : true}onClick={()=>saveComment()}>Submit</button></span>
                </div>
                {/* <Row>
                    <Col md={6}>
                    <label>Comment Type</label>
                    <Select 
                    options={commentTypes} 
                    isLoading={isLoading}
                    onChange={(e)=>setCommentTypeCodeId(e.value)}
                    />
                    </Col>
                    
                </Row> */}
                    {/* <textarea name="issuerComment" value={stateValues.issuerComment}
                        id="issuerComment" onChange={(e) => handleChange(e)}
                        className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..." />
                    <span style={{ position: 'relative', left: '45%', backgroundColor: bgcolor }}>{count} out of 4000 max</span> */}


                </div>
                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('filingContact')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={() => validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default Comments
