import CommonService from "./CommonService";

const searchIssuers = (obj) => {
    return CommonService.apicall(obj)
};
const getIssuerContacts = (obj) => {
    return CommonService.apicall(obj)
};
const updateContact = (obj) => {
    return CommonService.apicall(obj)
};
const createContact = (obj) => {
    return CommonService.apicall(obj)
};
const saveIssuerCounty = (obj) => {
    return CommonService.apicall(obj)
};
const deleteIssuerCounty = (obj) => {
    return CommonService.apicall(obj)
};
const updateIssuer = (obj) => {
    return CommonService.apicall(obj)
};
const createB2CUserProfile = (obj) => {
    return CommonService.apicall(obj)
};


const IssuerMaintenanceService = {
    searchIssuers,
    getIssuerContacts,
    updateContact,
    createContact,
    saveIssuerCounty,
    deleteIssuerCounty,
    updateIssuer,
    createB2CUserProfile
};

export default IssuerMaintenanceService;
