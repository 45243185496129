import CommonService from "./CommonService";

const getInvoiceById = (obj) => {
  return CommonService.apicall(obj);
};
const getLast100Invoices = (obj) => {
  return CommonService.apicall(obj);
};

const getInvoicePayment = (obj) => {
  return CommonService.apicall(obj);
};
const updateInvoice = (obj) => {
  return CommonService.apicall(obj);
};
const updateInvoiceComment = (obj) => {
  return CommonService.apicall(obj);
};

const updateInvoicePayment = (obj) => {
  return CommonService.apicall(obj);
};
const createInvoice = (obj) => {
  return CommonService.apicall(obj);
};
const createInvoiceComment = (obj) => {
  return CommonService.apicall(obj);
};
const createInvoicePayment = (obj) => {
  return CommonService.apicall(obj);
};

const getValidationMessages = (obj) => {
  return CommonService.apicall(obj);
};

const deleteInvoice = (obj) => {
  return CommonService.apicall(obj);
};
const getInvoice = (obj) => {
  return CommonService.apicall(obj);
};
const getInvoiceIssuerList = (obj) => {
  return CommonService.apicall(obj);
};
const deleteInvoiceComment = (obj) => {
  return CommonService.apicall(obj);
};

const deleteInvoicePayment = (obj) => {
  return CommonService.apicall(obj);
};

const saveComment = (obj) => {
  return CommonService.apicall(obj);
};
const getInvoiceComments = (obj) => {
  return CommonService.apicall(obj);
};

const getInvoiceeList = (obj) => {
  return CommonService.apicall(obj);
};
const searchInvoice = (obj) => {
  return CommonService.apicall(obj);
};
const getFilenetDocs = (obj) => {
  return CommonService.apicall(obj);
};
const getOneFilenetDoc = (obj) => {
  return CommonService.apicall(obj);
};
const updateFilenet = (obj) => {
  return CommonService.apicall(obj);
};
const generateInvoicePDF = (obj) => {
  return CommonService.apicall(obj);
};

const InvoiceService = {
  getInvoiceById,
  getLast100Invoices,
  getInvoiceComments,
  getInvoicePayment,
  updateInvoice,
  updateInvoiceComment,
  updateInvoicePayment,
  createInvoice,
  createInvoiceComment,
  createInvoicePayment,
  deleteInvoice,
  deleteInvoiceComment,
  deleteInvoicePayment,
  getInvoice,
  saveComment,
  getInvoiceIssuerList,
  getInvoiceeList,
  getValidationMessages,
  searchInvoice,
  getFilenetDocs,
  getOneFilenetDoc,
  updateFilenet,
  generateInvoicePDF,
};
export default InvoiceService;