import {useState, useEffect, useContext} from 'react';
import Card from '../ui/Card';
import {Form, Modal, Row, Col} from 'react-bootstrap';
import {AiFillQuestionCircle} from 'react-icons/ai';
import {validateFormFields} from '../../utils';
import CurrencyInput from '../formElements/CurrencyInput';
import Table from '../Table';
import CodeMaintenanceService from '../../services/CodeMaintenanceService';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import FinalSaleReportService from '../../services/FinalSaleReportService';
import {UserContext} from "../../UserContext";
import CommonService from '../../services/CommonService';
import swal from 'sweetalert';

const AddServiceFee = (props) => {
    const {onModalClose, markRoosAuthorityDetails } = props;
    const [formErrors,setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [services, setService] = useState([]);
    const loginUser = useContext(UserContext);

    useEffect(()=>{
        async function fetchServiceTypes() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGT", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    toast.error(result.data.errors[0].message);
                    return;
                }
                else{
                    setService(result.data.responses[0]);
                    setIsLoading(false);
                }

            })
        }
        fetchServiceTypes();

    },[])

    const handleChange = (e) => {

        const {name, value} = e.target;
        
        if(name === 'serviceTypeCodeId'){
            const filteredItems = services.filter(x => x.cdiacCodeValueId === parseInt(value));

            setStateValues(prevState => ({
                ...stateValues, [name]: value,
                cdiacCodeValueCode : filteredItems[0].cdiacCodeValueCode
            })
            )
        }
        else{
            setStateValues(prevState => ({
                ...stateValues, [name]: value
            })
            )
        }
        


    }

    const [stateValues, setStateValues] = useState({
        feeAmt : '',
        serviceTypeCodeId: '',
        otherFeeText : '',
        cdiacCodeValueCode: ''
    })
    const handleSave = (form) => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "createMarkRoosServiceFee",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "markRoosAuthorityServiceFee":{      
                "serviceTypeCodeId" : parseInt(stateValues.serviceTypeCodeId),
                "markRoosAuthorityId"  : markRoosAuthorityDetails.markRoosAuthorityId,    
                "otherFeeText" : stateValues.otherFeeText,
                "feeAmt" : stateValues.feeAmt,
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser,
                "createUser":  CommonService.getUserName(loginUser)
            }
            }
        };
    
       FinalSaleReportService.createMarkRoosServiceFee(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                //props.updateDraw(result.data.responses[0]);
                toast("Professional Fees was added Successfully!!");
                onModalClose();

            }

        });

    }
    const handleSubmit = (e) => {
        const form = e.target.closest('form#add-form');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {
            setFormErrors({});
           handleSave(form);
        }
    }
    return (
        <Modal  show={true} onHide={onModalClose}>
            <Modal.Header closeButton><h3>Add Service</h3></Modal.Header>
            <Modal.Body>
            {isLoading && <Spinner animation="border" variant="primary" />}
                <form id="add-form">
                    <Row>
                        <Col md={6}>
                            <div className="control">
                                <label><span className="required">*</span>Type of Service:</label>
                                <Form.Select name="serviceTypeCodeId"
                                            data-validations="required"
                                            onChange={(e) => handleChange(e)}>
                                            <option value="">Select</option>
                                            {services.map((item, i) => {
                                                return <option key={i} value={item.cdiacCodeValueId}>{item.cdiacCodeValueDesc}</option>
                                            })
                                            }
                                    </Form.Select>
                        {formErrors['serviceTypeCodeId'] && <p className="error">{formErrors['serviceTypeCodeId']}</p>}
                            </div>
                        </Col>
                        
                        <Col md={6}>
                            <div className="control">
                                <CurrencyInput 
                                placeholder="0.00" 
                                label="Amount" 
                                name="feeAmt" 
                                type="text" 
                                onChange={(e)=>handleChange(e)} 
                                validations={ ["required", "max:999999999999.99"]}
                                error={formErrors && formErrors['feeAmt']} 
                                isrequired="true"
                               />
                            </div>
                        </Col>

                    </Row>
                    {stateValues.cdiacCodeValueCode && stateValues.cdiacCodeValueCode === "OTH" &&
            <Row>
            < Col md={6}>
            <label><span className="required" >*</span>Please specify if "Other" was checked above: </label>
                    <Form.Control type="text" name="otherFeeText" data-validations="required" onChange={e=>handleChange(e)} />
                </Col>
            </Row>
        }
         
                    <div className="btn-div">
                        <div className="fright">
                            <button type="button" className="custom-button custom-button-secondary" onClick={()=>onModalClose()}>Cancel</button>{" "}
                            <button type="button" className="custom-button" onClick={(e)=>handleSubmit(e)}>Save</button>
                    </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
const ProfessionalFees = (props) => {
    const {markRoosAuthorityDetails} = props;
    const [openAddModal, setOpenAddModal] = useState(false);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [totalAmount, setTotalAmount] = useState("0.00");
    const [editIdx, setEditIndex] = useState(-1);
    const [professionalFees, setProfessionalFees] = useState([]);
    const loginUser = useContext(UserContext);

    useEffect(() => {
        getProfessionalFees();
        // eslint-disable-next-line
    }, [openAddModal]);
    //}, [serviceFees]);

    // const calculateServiceFeeTotal = (serviceFees) => {
    //     const total = serviceFees.reduce((total, item) => total + parseFloat(item.serviceFee !== "" ? item.serviceFee.replace(/,/g,'') : 0), 0);
    //     setTotalAmount(total.toLocaleString());
    // }

    const addServiceFee = () => {
        setOpenAddModal(true);
    }
    const handleChange = (e, name, i) => {
       
        const { value } = e.target;
        const professionalFeesnew =[...professionalFees];
        professionalFeesnew[i][name] = value;

        setProfessionalFees(professionalFeesnew);

    }
    const handleRemove = (i,rowData) => {
        swal("Are you sure you want to remove this Professional fees?", {
            buttons: ["No", "Yes"],
        }).then((value) => {
           if(value){
            const markRoosAuthorityServiceId = rowData.markRoosAuthorityServiceId;
            removeMarkRoosServiceFee(markRoosAuthorityServiceId);
            

           }
        });
    }

    const removeMarkRoosServiceFee = (markRoosAuthorityServiceId)  => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "deleteMarkRoosServiceFee",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
              "id" : markRoosAuthorityServiceId,
            }
        };
  
        FinalSaleReportService.removeMarkRoosServiceFee(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                toast.success("Mark Roos Professional Fee removed successfully");
                getProfessionalFees();
            }
        });
    }


    const getProfessionalFees = async () => {
        var obj = {
            msgId: "null",
            sysId: null,
            opr: "getMarkRoosAuthorityServiceFee",
            hdrStruct: null,
            queryParam: null,
            reqData: { id: markRoosAuthorityDetails.markRoosAuthorityId },
        };
    
        await FinalSaleReportService.getMarkRoosAuthorityServiceFee(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                
                setTotalAmount(CommonService.currencyFormatter(result.data.responses[0].map(item => item.feeAmt).reduce((prev, curr) => prev + curr, 0)));
                setProfessionalFees(result.data.responses[0]);
                props.updateMarkRoosAuthorityDependencies(result.data.responses[0], 'professionalFees');
            }

        })
    }

    const startEditing = i => {
        setEditIndex(i);
    };
    
    const stopEditing = () => {
        setEditIndex(-1);
    };


    const handleModalClose = () => {
        setOpenAddModal(false);
    }
    const validateForm = () => {
        props.openWidget('localObligor');
    }

    const handleOnSave = (i, rowData) => {
        updateMarkRoosAuthorityServiceFee(rowData);
        getProfessionalFees();
        setEditIndex(-1);
    }

    const updateMarkRoosAuthorityServiceFee = (rowData)  => {

        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "updateMarkRoosAuthorityServiceFee",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {  "id": rowData.markRoosAuthorityServiceId, "markRoosAuthorityServiceFee":{
                "feeAmt" : rowData.feeAmt,             
                "lastUpdateUser":  CommonService.getUserName(loginUser),
                "emailId": loginUser
            },
            "env": "I"
            }
        };

        FinalSaleReportService.updateMarkRoosAuthorityServiceFee(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message);
                return;
            }
            else{
                toast.success("Mark Roos Professional Fee updated successfully");
                getProfessionalFees();
            }
        });
    }

    return (
        <Card>
            <div className="form">
                <h3>Professional Services Fees <AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                {showInstructionsModal && 
                    <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                        <Modal.Body>
                            <div>
                            Report annual fees paid for ongoing professional services in connection with the bond issue. This may include financial advisory fees, consulting fees and administration fees. Investment contract fees are covered in a separate item below. The Total Fees for Professional Services is a calculated field which shows the total of all the fee.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                        </Modal.Footer>
                    </Modal>
                    }
                </h3>
                <button type="button" className="custom-button" onClick={() => addServiceFee()}>Add Service Fee</button>

                <div>
                {professionalFees && professionalFees.length !==0 && <>
                    <label>Fees Paid for Professional Services (Annual Totals)</label>
                    <Table 
                            handleRemove={handleRemove}
                            startEditing={startEditing}
                            editIdx={editIdx}
                            stopEditing={stopEditing}
                            handleChange={handleChange}
                            handleSave={handleOnSave}
                            data={professionalFees}
                            header={[
                            {
                                name: "Type Of Service",
                                prop: "serviceTypeCodeDesc",
                                editable: false
                            },
                            {
                                name: "Other Text",
                                prop: "otherFeeText",
                                editable: false
                            },
                            {
                                name: "Service Fee",
                                prop: "feeAmt",
                                fieldType: "currency",
                                type:"amount",
                                editable: true
                            }
                            ]}
                            />
                            <Row>
                                <Col md={{ span: 8, offset: 7}}>
                                    <Form.Group style={{display: 'inline-flex'}} controlId="formInlineName">
                                        <label>Total: </label>
                                        <Form.Control type="text" value={totalAmount} readOnly/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            </>
                    }
                </div>

                {openAddModal && <AddServiceFee onModalClose={handleModalClose}  markRoosAuthorityDetails ={markRoosAuthorityDetails} /> }


                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('fundBalance')} >Back</button> */}
                    <button className="fright custom-button" type="button" onClick={e=>validateForm(e)}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default ProfessionalFees
