import CommonService from "./CommonService";


const getCdiacNumbers = (obj) => {
    return CommonService.apicall(obj)

};

const saveMkrLobAssoc = (obj) => {
    return CommonService.apicall(obj)

};
const deleteMkrAuthLobAssoc = (obj) => {
    return CommonService.apicall(obj);
}

const validateIssueRefundCdiac = (obj) => {
    return CommonService.apicall(obj)
}

const saveIssueRefundAssoc = (obj) => {
    return CommonService.apicall(obj)

};
const deleteIssueRefundAssoc = (obj) => {
    return CommonService.apicall(obj);
}
const deleteIssueSeries = (obj) => {
    return CommonService.apicall(obj);
}
const IssueCdiacNumberService = {
    getCdiacNumbers,
    saveMkrLobAssoc,
    deleteMkrAuthLobAssoc,
    validateIssueRefundCdiac,
    saveIssueRefundAssoc,
    deleteIssueRefundAssoc,
    deleteIssueSeries
};


export default IssueCdiacNumberService;
