import { useEffect } from 'react'
import Card from '../../ui/Card'
import {Form, Row, Col, Modal, Spinner} from 'react-bootstrap'
import { useState } from 'react'
import classes from './IssuanceAuthorization.module.css'
import {AiFillQuestionCircle} from 'react-icons/ai'
import {FaPencilAlt} from 'react-icons/fa'
import EditIssuanceAuthorization from './editIssuanceAuthorization'
import IssueAuthorizationService from '../../../services/IssueAuthorizationService'
import CommonService from '../../../services/CommonService'
import ViewIssuances from '../../admin/authorizationMaintenance/viewIssuances'
import AuthorizationSummary from '../../admin/authorizationMaintenance/authorizationSummary'
const IssuanceAuthorization = (props) => {
    const {issueId, isAdtrFirstYear, updateAdtrDependencies} = props;
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editAuthorizationInfo, setEditAuthorizationInfo] = useState(null);
    const [selectedAuthInfo, setSelectedAuthInfo] = useState(null);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [authorizations, setAuthorizations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openViewIssuances, setOpenViewIssuances] = useState(false);
    const [openAdtrSummary, setOpenAdtrSummary] = useState(false);

    useEffect(()=>{
        getIssueAuthorizations(issueId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[issueId]);

    const getIssueAuthorizations = async (issueId) => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "getAdtrAuthorizations",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": { "adtrId" : issueId }
        };
       await IssueAuthorizationService.getAuthorizationsByIssueId(obj)
          .then((res) => res)
          .then((result) => {
            setAuthorizations(result.data.responses[0]);
            updateAdtrDependencies(result.data.responses[0], 'issueAuthorizations');
            setIsLoading(false);
          });
    }

    const handleEditModalClose = () => {
        setIsLoading(true);
        setOpenEditModal(false);
        setTimeout(() => {
        getIssueAuthorizations(issueId);
        }, 1000);
    }
 
    const editAutorization=(i)=> {
        let authorizationsArr = [...authorizations];
        setEditAuthorizationInfo(authorizationsArr[i]);
        setOpenEditModal(true);
    }

    const validateForm = (e) => {
        if(authorizations.length !== 0) {
            props.openWidget('principalOutstanding');
        } else {
            alert("Please add at least one issuance authorization.");
        }           
    }    
    const handleViewIssuancesClick = (e, auth) => {
        e.stopPropagation();
        setSelectedAuthInfo(auth)
        setOpenViewIssuances(true);
    }
    const handleAuthSummaryClick = (e, auth) => {
        e.stopPropagation();
        setSelectedAuthInfo(auth)
        setOpenAdtrSummary(true);
    }

    return (
        <Card>
            <Form className="form" id="issuanceAuthorizationForm">
            <h3>Issuance Authorization
                {' '}<AiFillQuestionCircle onClick={()=>setShowInstructionsModal(true)} className="help-icon heading-help-icon"/>

                {showInstructionsModal && 
                <Modal show={true} backdrop="static" onHide={()=>setShowInstructionsModal(false)}>
                    <Modal.Body>
                        <div>
                        The Issuance Authorization data is calculated and populated from data submitted on the RFS of any debt issue using this authorization, and prior year ADTRs for this and other issues using this Authorization. Amounts Authorized at the Beginning and During the Reporting Period, and Authorization Lapsed are required fields. Select the Edit Pencil to enter these amounts. Amount Authorized at the Beginning of the Reporting Period is only required on the 1st ADTR.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="secondary" className="custom-button custom-button-secondary" onClick={()=>setShowInstructionsModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                }
            </h3>

           {isLoading ? <Spinner /> :
            authorizations.length !== 0 && authorizations.map((auth, i) => {
                return (
                    <Row key={i}>
                        <div>
                            <span style={{float: "right"}}>
                                <FaPencilAlt className="custom-icon" onClick={()=>editAutorization(i)}/>{"   "}
                            </span>
                            <h3 className={classes.authheading}>Issuance Authorization {i+1}</h3>
                        </div>
                        <div className='py-2'>
                            <button
                            type="button"
                            className="link-button dk-blue-color me-4"
                            onClick={(e) => handleViewIssuancesClick(e, auth)}
                            >
                                View Issuances
                            </button>
                            <button
                            type="button"
                            className="link-button dk-blue-color"
                            onClick={(e) => handleAuthSummaryClick(e, auth)}
                            >ADTR Summary
                            </button>
                        </div>
                        <Row>
                            <Col md={7}>Authorization Name : </Col>
                            <Col md={5}>{auth.authorizationName} </Col>
                        </Row>
                        <Row>
                            <Col md={7}>Original Authorized Amount : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.originalAuthorizedAmt)} </Col>
                        </Row>
                        <Row>
                            <Col md={7}>Authorization Date : </Col>
                            <Col md={5}>{CommonService.dateFormatter(auth.authorizationDate)} </Col>
                        </Row>
                        <Row>
                            <Col md={7}><span className="required">*</span>Amount Authorized - Beginning of the Reporting Period : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.beginAmt)} </Col>
                        </Row>
                        <Row>
                            <Col md={7}><span className="required">*</span>Amount Authorized - During the Reporting Period : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.duringAmt)} </Col>
                        </Row>
                        <Row>
                            <Col md={7}>Total Debt Authorized : </Col>
                            <Col md={5}>{CommonService.currencyFormatter((auth.beginAmt + auth.duringAmt))} </Col>
                        </Row>
                        <Row>
                            <Col md={7}>Debt Issued During the Reporting Period : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.authorityReducedAmt)} </Col>
                        </Row>
                        <Row>
                            <Col md={7}>Replenishment Reported During the Reporting Period : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.authorityReplenishAmt)}</Col>
                        </Row>
                        <Row>
                            <Col md={7}>Total Debt Authorized but Unissued : </Col>
                            <Col md={5}>{CommonService.currencyFormatter((auth.beginAmt + auth.duringAmt + auth.authorityReplenishAmt)- auth.authorityReducedAmt)}</Col>
                        </Row>
                        
                        <Row>
                            <Col md={7}><span className="required">*</span>Authorization Lapsed : </Col>
                            <Col md={5}>{CommonService.currencyFormatter(auth.lapsedAmt) }</Col>
                        </Row>
                        <Row>
                            <Col md={7}>Total Authorization Remaining - End of Reporting Period : </Col>
                            <Col md={5}>{CommonService.currencyFormatter((auth.beginAmt + auth.duringAmt + auth.authorityReplenishAmt) - auth.lapsedAmt - auth.authorityReducedAmt)}</Col>
                        </Row>
                        
                        <hr/>
                    </Row>

                )
            })}
            
            {openEditModal && <EditIssuanceAuthorization onModalClose={handleEditModalClose} authInfo={editAuthorizationInfo} issueId={issueId} isAdtrFirstYear={isAdtrFirstYear}/> }
            
            <div className="btn-div">
            {/* <button className="custom-button" type="button">Back</button> */}
            <button className="custom-button fright" type="button" onMouseDown={(e)=>validateForm(e)}>Save & Next</button>
            </div>
            </Form>

            {openViewIssuances && <ViewIssuances onModalClose={() => setOpenViewIssuances(false)} authInfo={selectedAuthInfo} />}
            {openAdtrSummary && <AuthorizationSummary onModalClose={() => setOpenAdtrSummary(false)} authInfo={selectedAuthInfo} />}

        </Card>
    )
}

export default IssuanceAuthorization
