import {useState, useEffect, useContext} from 'react'
import CollapsibleWidgets from'./collapsibleWidgets'
import AdtrProgressBar from './adtrProgressBar';
import {VscExpandAll, VscCollapseAll} from 'react-icons/vsc'
import { IssuanceDetailContext, IssuerContext, UserContext } from '../../UserContext'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import History from '../../common/History'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import CommonService from '../../services/CommonService'
import ReviewComponent from './reviewComponent'
import HtmlPdfService from '../../services/HtmlPdfService'
import { renderToString } from 'react-dom/server'
import EditReasonHistory from '../../common/EditReasonHistory'
import { configManager } from '../../configManager';

const AdtrYFS = (props) => {
    const userEmail = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [issuanceDetail, setIssuanceDetails] = useState(null);
    const [contact, setContactDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [adtrDependenciesData, setAdtrDependenciesData] = useState(null);
    const [showEditReasonHistory, setShowEditReasonHistory] = useState(false);
    const cdiacNo = params.cdiac; 
    const rowInfo = location.state.appState;
   
    const [adtrDetails, setAdtrDetails] =  useState(null);
    const [expandAll, setExpandAll] = useState(false);
    const [openSection, setOpenSection] = useState({
        issuance: true,
        issuanceAuthorization: false,
        principalOutstanding: false,
        useOfProceeds: false,
        retiredIssues: false,
        filingContact: false,
        comments: false,
        review: false
    });
    const [showHistory, setShowHistory] = useState(false);
    const toggleAllWidgets = () => {
        setExpandAll( !(expandAll) );
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: !(expandAll) })));
        setOpenSection(openSecObj);
    }

    const toggleWidget = (sectionName) => {
        setOpenSection({...openSection, [sectionName]: !(openSection[sectionName])})
    }

    const openWidget = (sectionName) => {
        let openSecObj = Object.assign(...Object.keys(openSection).map(k => ({ [k]: false })));
        openSecObj[sectionName] = !(openSecObj[sectionName]);
        setOpenSection(openSecObj);
    }
    
    const handlePrint = () => {
        const html = renderToString(<ReviewComponent adtrDependenciesData={adtrDependenciesData}  contact={contact} />)
        
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getPdf",
            hdrStruct: null,
            queryParam: null,
            reqData: {
                "html": html,
                "id" : rowInfo.adtrId,
                "cdiacNbr" :  cdiacNo,
                "status": 'D',
                "reportType" : "ADTR",
                "createUser":  CommonService.getUserName(userEmail), 
            },
          };

        HtmlPdfService.generatePdf(obj)
        .then((res) => { 
             var base64EncodedPDF = res.data;
             var arrrayBuffer = CommonService.base64ToArrayBuffer(base64EncodedPDF); 
             var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
             var url = window.URL.createObjectURL(blob);
             window.open(url);

        }) // Handle the response from backend here
          .catch((err) => { }); // Catch errors if any
        
    }

    const backToDashboard = () => {
        const dashboardSelectionObj = JSON.parse(sessionStorage.getItem('dashboardSelection'));
        navigate("/dashboard", {state: {dashboardSelection: dashboardSelectionObj}});
    }


    
      useEffect(() => {
        async function getAllAdtrDependencies() {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getAllAdtrDependencies",
                hdrStruct: null,
                queryParam: null,
                reqData:{
                    "adtr" : {
                        "adtrId" : rowInfo.adtrId,
                        "finalSaleId" : rowInfo.finalSaleId,
                    },
                    "cdiacNo" :  cdiacNo
                    }
                };
            await FinalSaleReportService.getAdtrInfo(obj)
                .then((result) => {
                    if(result.data.errors && result.data.errors.length > 0) {
                        console.log(result.data.errors[0].message);
                    } else {
                        const responseData = result.data.responses[0];
                        setAdtrDependenciesData(responseData);
                        setIssuanceDetails(responseData.issuanceDetails);
                        setAdtrDetails(responseData.adtrInfo);
                        responseData.adtrInfo.contactId && getFilingContact(responseData.adtrInfo.contactId);
                    }
                    
                });
        }  

        async function getFilingContact(contactId) {
                var obj = {
                    msgId: null,
                    sysId: null,
                    opr: "getContactById",
                    hdrStruct: null,
                    queryParam: null,
                    reqData:{"id" : contactId}
                  };
                await FinalSaleReportService.getContactDetails(obj)
                    .then((result) => {
                        setContactDetails(result.data.responses[0]);
                    });
        }

        getAllAdtrDependencies();
        
        setIsLoading(false);
        // eslint-disable-next-line
      }, []);
    
    const updateIssuanceDetails = (finalSaleDetails) => {
        setIssuanceDetails(finalSaleDetails);
        updateAdtrDetails(finalSaleDetails, 'issuanceDetails');
    }
    const updateAdtrDetails = (data, type) => {
        if(type === 'useOfProceeds'){
            const newArr = {...adtrDependenciesData};
            newArr.fiscalYrProceedsSummary = data.fiscalYrProceedsSummary;
            newArr.proceedsSummary = data.proceedsSummary;
            newArr.expenditureSummary = data.expenditureSummary;
            newArr.currentPeriodexpenditures = data.currentPeriodexpenditures;
            newArr.netProceedsBalance = data.netProceedsBalance;
            setAdtrDependenciesData(newArr);
        } else {
            setAdtrDependenciesData(prevState => ({
                ...adtrDependenciesData, [type] : data
            }));
        }
    }

    return (
        <div>
            <div>
                <div className="panel panel-default" id="stepspanel" style={{marginBottom: '0px'}}>
    
                    <div className="panel-heading">
                        <div className="container">
                        <div id="packet_id" className="panel-title">                           
                            <div className="fright" style={{margin: '5px'}}>
                            {adtrDetails && adtrDetails.statusFlag === 'R' && <button className="custom-button me-2" onClick={() => window.open(`${configManager.debtWatchUrl}/issue-level-detail/${cdiacNo}`)}>View in DebtWatch</button>}
                            {adtrDetails && adtrDetails.statusFlag === 'D' && <button className="custom-button" onClick={handlePrint}>Print PDF (Draft)</button>}
                            </div>
                                <h1 className="menu-title">Annual Debt Transparency Report</h1>                                
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="container">
                            <AdtrProgressBar openSection={openWidget} />
                            <div className="center-align">
                                <div style={{float: 'left'}}>
                                    <button
                                    type="button"
                                    className="dk-blue-color link-button" 
                                    onClick={()=>backToDashboard()}>Back to Dashboard</button>
                                </div>
                                <button
                                type="button"
                                style={{textDecoration: 'none', fontWeight: '500'}}
                                className="dk-blue-color link-button"
                                onClick={()=>toggleAllWidgets()}>{expandAll ? <VscCollapseAll /> : <VscExpandAll /> } {expandAll ? "Collapse All" : "Expand All" }</button>
                                
                                <div style={{float: 'right'}}>
                                    <button
                                    type="button"
                                    className="dk-blue-color link-button" 
                                    onClick={()=>setShowHistory(true)}>Show History</button>
                                    {(adtrDetails && (adtrDetails.statusFlag === "E" || adtrDetails.statusFlag === "R")) &&
                                    <><br />
                                    <button
                                    type="button"
                                    className="dk-blue-color link-button" 
                                    onClick={()=>setShowEditReasonHistory(true)}>Show Edit Reason History</button>
                                    </>}
                                </div>
                            </div>
                            <br/>
                            <div style={{color: 'green'}}>
                            <em>CDIAC # {cdiacNo}</em>
                            <br/>
                            <em>Balances Reported as of {CommonService.dateFormatterMonthDayYear(rowInfo.fiscalYrEndDate)}</em>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>
            <br/>
            {showHistory && <History onModalClose={()=>setShowHistory(false)} issueId={rowInfo.adtrId} reportTypeId="adtr_id"/>}
            {isLoading ? <Spinner animation="border" variant="primary"/> :
            (issuanceDetail &&
            <IssuerContext.Provider value={issuanceDetail.issuerId && issuanceDetail.issuerId}>
            <IssuanceDetailContext.Provider value={issuanceDetail}>
            <CollapsibleWidgets 
                openSectionObj={openSection} 
                toggleWidget={toggleWidget} 
                openSection={openWidget} 
                adtrDetails={adtrDetails} 
                contact={contact} 
                adtrDependenciesData={adtrDependenciesData} 
                updateIssuanceDetails={updateIssuanceDetails}
                updateAdtrDependencies={updateAdtrDetails}/>
            <div className="panel panel-default">
                <div className="panel-heading"> 
                    <div className="container">
                        <div className="panel-body" style={{padding: '10px 0 0'}} >
                        <button className="custom-button" onClick={()=>backToDashboard()}>Back to Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
            </IssuanceDetailContext.Provider>
            </IssuerContext.Provider>
            )
            }
            {showEditReasonHistory && <EditReasonHistory onModalClose={()=>setShowEditReasonHistory(false)} issueId={adtrDetails.adtrId} cdiacCodeValueCode="ADTER"/>}
        </div>
    )
}

export default AdtrYFS
