import ReportOfProposedDebtService from '../../../services/ReportOfProposedDebtService'
import CommonService from '../../../services/CommonService'
import DataGrid from '../../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const StatusHistory = (props) => {
  const { statusHistory } = props;

  const handleViewDocument = (e, statusHistId) => {
    CommonService.showHideSpinner(true);
    let obj = {
        "msgId": null,
        "sysId": null,
        "opr": "getPdfDocumentFromBlob",
        "hdrStruct": null,
        "queryParam": null,
        "reqData": { 
            "statusHistId" : statusHistId,
        }
    };
    ReportOfProposedDebtService.getHistory(obj)
      .then((res) => {
        var base64EncodedPDF = res.data.responses[0];
        var arrrayBuffer = base64ToArrayBuffer(base64EncodedPDF); 
        function base64ToArrayBuffer(base64) {
            let binaryString = window.atob(base64);
            let binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        }
        CommonService.showHideSpinner(false);
        var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
        var url = window.URL.createObjectURL(blob);
        window.open(url);
    });
  }
  const fileNameFormatter = (row) => { 
    return ( 
        <div style={{lineHeight: "normal" }}>
            <button
                type="button"
                className="link-button dk-blue-color"
                onClick={(e)=>handleViewDocument(e, row.statusHistId)}>PDF
            </button>
        </div> 
    ); 
  }

  return (
    <>
      <div className="form">
        <h2>Reporting History: </h2>

        <DataGrid
        dataSource={statusHistory}
        sortField='createDateTime'
        searchToolTip="Use the search feature to quickly retrieve the system code values"
        emptyDataMessage='No data available to display'
        addNew={false}
        >
          <Column field='source' header='Report' sortable/>
          <Column field='fiscalYrEndDate' header='FY End Date' sortable body={(row)=>CommonService.dateFormatter(row['fiscalYrEndDate'])} />
          <Column field='fileName' header='File' sortable body={fileNameFormatter}/>
          <Column field='status' header='Status' sortable />
          <Column field='createDateTime' header='Create Date' sortable body={(row)=>CommonService.dateFormatter(row['createDateTime'])} />
          <Column field='createUser' header='Created User' sortable />
        </DataGrid>
      </div>
      
    </>
  )
}

export default StatusHistory
