import { useState, useEffect } from "react";
import EditInvoice from "./editInvoice";
import InvoiceService from "../../../services/InvoiceService";
import CommonService from "../../../services/CommonService";
import {Dropdown} from "react-bootstrap";
import DataGrid from "../../GenericComponents/DataGrid";
import { Column } from "primereact/column";

const SearchResultContainer = (props) => {
  const [editInvoice, setEditInvoice] = useState(false);
  const [selectedInvoiceInfo, setSelectedInvoiceInfo] = useState([]);
  const { searchReq } = props;
  const [invoice, setInvoice] = useState([]);

  const viewStatusFormatter = (row) => {
    let statusDesc = "";
    if(row.invoiceStatusFlag === "OP") {
      statusDesc = "Open";
    } else if(row.invoiceStatusFlag === "PE"){
      statusDesc = "Pending";
    }
    else if(row.invoiceStatusFlag === "PD"){
      statusDesc = "Paid";
    }
    else if(row.invoiceStatusFlag === "CA"){
      statusDesc = "Cancelled";
    }
    return statusDesc;
  };

  const handleActionClick = (e, action, row) => {
    e.preventDefault();

    if(action === "edit-invoice"){
      setSelectedInvoiceInfo(row);
      setEditInvoice(true);
    }
  }
  const viewActionFormatter = (row) => { 
    return ( 
        <div style={{ textAlign: "center", lineHeight: "normal" }}>
            <div className="dropdown">
                <Dropdown>
                <Dropdown.Toggle 
                variant="secondary btn-sm" 
                id="dropdown-basic">
                    Action
                </Dropdown.Toggle>
              
                <Dropdown.Menu style={{backgroundColor:'#73a47'}} >
                  <Dropdown.Item href="#" onClick={(e)=>handleActionClick(e, 'edit-invoice', row )}>Edit Invoice</Dropdown.Item>
                  <Dropdown.Item href={`/invoice-payments/${row.invoiceId}/${row.invoiceStatusFlag}`} >Payments</Dropdown.Item>
                  <Dropdown.Item href={`/invoice/invoiceForm/comment/${row.invoiceId}`} >Invoice Comments</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
            </div>
        </div> 
    ); 
  }

  async function searchInvoice () {
    CommonService.showHideSpinner(true);
    var obj = {
      msgId: null,
      sysId: null,
      opr: searchReq.opr,
      hdrStruct: null,
      queryParam: null,
      reqData: {
        searchInvoiceInfo: {
          invoiceId: searchReq.invoiceId,
          issuerName: searchReq.issuerName,
          invoiceStatus: searchReq.invoiceStatus,
          cdiacNbr: searchReq.cdiacNbr,
        },
      },
    };

    await InvoiceService.searchInvoice(obj)
      .then((res) => res)
      .then((result) => {
        Object.keys(result.data.responses).forEach((key) => {
          setInvoice(result.data.responses[key]);
        });
        CommonService.showHideSpinner(false);
      });
  }
  useEffect(() => {
    searchInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchReq]);

  const columns = [
    {
      dataField: "invoiceId",
      text: "Invoice #",
      sort: true,
    },
    {
      dataField: "issuerName",
      text: "Issuer Name",
      sort: true,
    },
    {
      dataField: "cdiacNbr",
      text: "CDIAC #",
      sort: true,
    },
    {
      dataField: "invoiceDate",
      text: "Invoice Date",
      sort: true,
      formatter: (row) => CommonService.dateFormatter(row['invoiceDate']),
    },
    {
      dataField: "invoiceDueAmt",
      text: "Invoice Amount",
      sort: true,
      formatter: (row) => CommonService.currencyFormatter(row['invoiceDueAmt']),
    },
    {
      dataField: "",
      text: "Invoice Status",
      sort: true,
      formatter: viewStatusFormatter
    },
    {
      dataField: "",
      text: "",
      formatter: viewActionFormatter,
    }
  ];

  const handleInvoiceModalClose = () => {
    setEditInvoice(false);
    searchInvoice();
  }
  return (
    <>
      <div className="form">
        <DataGrid
          title="Search Result:"
          dataSource={invoice}
          sortField='invoiceId'
          searchToolTip="Use the search feature to quickly retrieve the system code values"
          emptyDataMessage='No data available to display'
          >
            {columns.map((column, i) => {
                return (
                <Column 
                key={i}
                field={column.dataField}
                header={column.text}
                sortable={column.sort} 
                body={column.formatter}
                hidden={column.hidden}
                />)
                }) 
            } 
            </DataGrid>
      </div>

      {editInvoice && (
        <EditInvoice
          onModalClose={() => handleInvoiceModalClose()}
          invoiceInfo={selectedInvoiceInfo}
        />
      )}
    </>
  );
};

export default SearchResultContainer;
