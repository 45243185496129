import {Form, Modal} from 'react-bootstrap'
import {AiFillQuestionCircle} from 'react-icons/ai';
import CountyMaintenanceService from "../../../services/CountyMaintenanceService";
import React from "react";
import { useForm } from "react-hook-form";
import {useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify';
import { useContext } from "react";
import { UserContext } from "../../../UserContext";
import CommonService from "../../../services/CommonService";
import TooltipComponent from '../../../common/Tooltip';

const AddCountyForm = (props) => {
    const {onModalClose, region} = props;
    const {register, handleSubmit, formState: { errors }} = useForm();
    const [errorMessage, setErrorMessage] = useState();
    const user = useContext(UserContext);
    
    const onSubmit = (data) => {

        var obj = {
            msgId: null,
            sysId: null,
            opr: "postCountyRef",
            hdrStruct: null,
            queryParam: null,
            reqData: { "countyRef":{
                "countyCode": data.countyCode,
                "regionCodeId": props.selectedCounty,
                "countyName": data.countyName,
                "createUser": CommonService.getUserName(user),
                "lastUpdateUser": CommonService.getUserName(user),
                "activeInd": data.activeInd === true ? 'Y' : 'N'
            }
            },
          };
      
          CountyMaintenanceService.addCounty(obj)
            .then((res) => res)
            .then((result) => {
                if (result.data.errors && result.data.errors.length > 0){
                    setErrorMessage(result.data.errors[0].message)
                }
                else{
                    toast.success("County added successfully!");
                    onModalClose();
                }
                
            });
      }

    return (
        
        <form onSubmit={(e) => e.preventDefault()}>

            <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>New County</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
            
                <div className="control">
                    <label><span className="required">* </span>Region: </label>
                    <input value={region} disabled/>
                </div>
                <div className="control">
                    <label><span className="required">* </span>County Code: </label>
                    <input name='countyCode' maxLength="6" {...register('countyCode', { required: true })} />
                    {errors.countyCode && <p style={{ color: 'red' }}>County Code is required</p>}
                </div>
                <div className="control">
                    <label><span className="required">* </span>County Name: </label>
                    <input maxLength="100" {...register('countyName', { required: true })}/>
                    {errors.countyName && <p style={{ color: 'red' }}>County Name is required</p>}
                </div>
                <div style={{display: 'flex'}}>
                    <Form.Check inline type="checkbox" label="Active?" {...register('activeInd')} defaultChecked />
                    <TooltipComponent title="Only active values are displayed in the User Interface" id="adtr"
                    placement="right">
                        <AiFillQuestionCircle className="help-icon mt-2" />
                    </TooltipComponent>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>
                <button className="custom-button" type="Submit" onClick={handleSubmit(onSubmit)}>Save</button>
            </Modal.Footer>
        </Modal>
        </form>
    )
}

export default AddCountyForm
