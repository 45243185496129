import CommonService from "./CommonService";


const getAllFeeSchedules = (obj) => {
    return CommonService.apicall(obj)

};

const addFeeSchedule = (obj) => {
    return CommonService.apicall(obj)

};

const updateFeeSchedule = (obj) => {
    return CommonService.apicall(obj)

};

const FeeScheduleMaintenanceService = {
    getAllFeeSchedules,
    addFeeSchedule,
    updateFeeSchedule
};


export default FeeScheduleMaintenanceService;
