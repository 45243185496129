import { useState, useEffect } from 'react'
import Card from '../ui/Card'
import FinalSaleReportService from '../../services/FinalSaleReportService'
import CommonService from '../../services/CommonService'
import { UserContext } from '../../UserContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import CountyMaintenanceService from '../../services/CountyMaintenanceService'
import { Row, Col, Table } from 'react-bootstrap'
import Select from 'react-select'

const Comments = (props) => {
    const { markRoosLocalObligDetails, updateIssueComments } = props;
    const [count, setCount] = useState(0);
    const [cdiacCommentCount, setCdiacCommentCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [cdiacBgColor, setCdiacBgcolor] = useState('yellowgreen');
    const [stateValues, setStateValues] = useState({
        issuerComment: markRoosLocalObligDetails.issuerComment
    });
    const [isLoading, setIsLoading] = useState(true);
    const [issueComments, setIssueComments] = useState([]);
    const [commentTypes, setCommentTypes] = useState([]);
    const [commentTypeCodeId, setCommentTypeCodeId] = useState(null);
    const [comment, setComment] = useState("");
    const loginUserId = useContext(UserContext);
    

    const user = useContext(UserContext);
    async function fetchIssueComments () {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getMarkRoosLocalObligIssueComments",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "issueComment":{
                    "mkrLobId" : markRoosLocalObligDetails.marksRoosLocalObligorId 
                }
            },
            };
        
        FinalSaleReportService.getIssueComments(obj)
        .then((res) => res)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0 ) {
                toast.error(result.data.errors[0].message);
            }else {
                let reasonsArr = [];
                result.data.responses[0].forEach((item, i) => {
                    item.displayComment = item.comment;
                    if( item.comment.length > 32 ) {
                        item.displayComment = item.comment.substr(0, 32) + "...";
                        item.expanded = false;
                    }
                    
                    reasonsArr.push(item);
                })
                setIssueComments(reasonsArr);
                updateIssueComments(result.data.responses[0]);
            }
        });
    }

    useEffect(() => {        
        async function fetchCommentTypes () {
            var obj = {
            msgId: null,
            sysId: null,
            opr: "GetLookups",
            hdrStruct: null,
            queryParam: null,
            reqData: { cdiacCode: "MRCMT", active: "Y" },
            };
        
            CountyMaintenanceService.getAllCounties(obj)
            .then((res) => res)
            .then((result) => {
                let codesArr = [];
                result.data.responses[0].forEach((item, i) => {
                    let Obj = {};
                    Obj['value'] = item.cdiacCodeValueId;
                    Obj['label'] = item.cdiacCodeValueDesc;
                    codesArr.push(Obj);
                });
                setCommentTypes(codesArr);
                setIsLoading(false);
            });
        }
        
        fetchCommentTypes();
        fetchIssueComments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setStateValues(prevState => ({
            ...stateValues, [name]: value
        })
        )
    }

    const validateForm = (e) => {
        if(markRoosLocalObligDetails.marksRoosLocalObligorId !== undefined){

            var obj = {
                msgId: null,
                sysId: null,
                opr: "updateMKRRoosLocalObligComment",
                hdrStruct: null,
                queryParam: null,
                reqData: {
                    "id": markRoosLocalObligDetails.marksRoosLocalObligorId,
                    "env": "I",
                    "marksRoosLocalObligor": {
                        "issuerComment": stateValues.issuerComment,
                        "lastUpdateUser": CommonService.getUserName(user),
                        "emailId": user,
                        "contactId": markRoosLocalObligDetails.contactId
                    }
                },
            };
    
            FinalSaleReportService.updateDrawIssuerComment(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0) {
                        toast.error(result.data.errors[0].message);
                    }
                    else {
                        props.updateMkrLocalObligorDetails(result.data.responses[0]);
                        toast.success("Comment updated successfully!");
                    }
    
                });
            props.openWidget('review');
        }
        
    }

    const toggleText = (e, i) => {
        const history = [...issueComments];
        history[i].expanded = !history[i].expanded;
        if(history[i].expanded) {
            history[i].displayComment = history[i].comment;
        } else {
            history[i].displayComment =  history[i].comment.substr(0, 32) + "...";
        }
        setIssueComments(history);
    }

    const handleCommentValue = (e) => {
        setCdiacCommentCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setCdiacBgcolor(color);
        setComment(e.target.value);
    };
    const saveComment = async () => {
        let obj = {
            "msgId": null,
            "sysId": null,
            "opr": "saveComment",
            "hdrStruct": null,
            "queryParam": null,
            "reqData": {
                "issueComment" : {
                    "mkrLobId" : markRoosLocalObligDetails.marksRoosLocalObligorId ,
                    "commentTypeCodeId": commentTypeCodeId,
                    "comment": comment,
                    "createUser": CommonService.getUserName(loginUserId)
                }
            }
        };
    
       await FinalSaleReportService.saveComment(obj)
        .then((res) => res)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                alert(result.data.errors[0].message);
            }
            else{
                fetchIssueComments();
                setCommentTypeCodeId(null);
                setComment("");
                setCdiacCommentCount(0);
            }
        });
    }
    return (
        <Card>
            <div className="form">
                <h3>Issuer Comments: </h3>
                <div className="comments-box">
                    <textarea name="issuerComment" value={stateValues.issuerComment}
                        id="issuerComment" onChange={(e)=>handleChange(e)}
                        className="form-control" rows="5" maxLength="4000" />
                    <span style={{ position: 'relative', left: '45%', backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                </div>
                <div className="comments-box">
                <div>
                <h3>CDIAC Comments: </h3>
                {issueComments.length > 0 &&
                <Table>
                <thead>
                    <tr>
                        <th>Comment Type</th>
                        <th>Comment</th>
                        <th>Create User</th>
                        <th>Create Date</th>
                    </tr>
                </thead>
                <tbody>
                    {issueComments.map((comment,i)=>{
                            return (
                                <tr key={i}>
                                <td>{comment.cdiacCodeValueDesc}</td>
                                <td>{comment.displayComment}&nbsp; 
                                {comment.hasOwnProperty('expanded') ? 
                                    (comment.expanded ? 
                                    <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show less
                                    </button>
                                    : <button
                                    type="button"
                                    className="link-button dk-blue-color"
                                    onClick={(e) => toggleText(e, i)}>show more
                                    </button>
                                    ) : ""
                                }
                                </td>
                                <td>{comment.createUser}</td>
                                <td>{CommonService.dateFormatter(comment.createDatetime)}</td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
            }
                <Row>
                    <Col md={6}>
                    <label>Comment Type</label>
                    <Select 
                    options={commentTypes} 
                    isLoading={isLoading}
                    onChange={(e)=>setCommentTypeCodeId(e.value)}
                    />
                    </Col>
                    
                </Row>
                <textarea name="comment" value={comment} 
                id="issuerComment" onChange={(e)=>handleCommentValue(e)}
                    className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                    <span style={{position: 'relative', left: '45%' , backgroundColor: cdiacBgColor }}>{cdiacCommentCount} out of 4000 max</span>
                    <span style={{position: 'relative', float: 'right', marginTop: '5px' }}><button type="button" disabled={(commentTypeCodeId !== null && comment !== "") ? false : true}onClick={()=>saveComment()}>Submit</button></span>
                </div>
                </div>

                <div className="btn-div">
                    {/* <button className="custom-button" type="button" onClick={() => props.openWidget('filingContact')}>Back</button> */}
                    <button className="fright custom-button" type="button" onClick={() => validateForm()}>Save & Next</button>
                </div>
            </div>
        </Card>
    )
}

export default Comments
