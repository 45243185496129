import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import Card from "../ui/Card";
import SearchResultContainer from "./searchResultContainer";
import { useForm } from "react-hook-form";
import "react-bootstrap-typeahead/css/Typeahead.css";
import swal from "sweetalert";

const AzureDocs = (props) => {
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchReq, setSearchReq] = useState([]);
  const { handleSubmit } = useForm();
  const [searchParams, setSearchParams] = useState({
    beginCDIAC: "2019-0001",
    endCDIAC: "2019-0025",
  });

  const handleChange = (e, key) => {
    if (key === "beginCDIAC") {
      searchParams.beginCDIAC = e.target.value;
    } else if (key === "endCDIAC") {
      searchParams.endCDIAC = e.target.value;
    } else {
      if (e.length) {
        setSearchParams((prevState) => ({
          ...searchParams,
          [key]: e[0][key],
        }));
      }
    }
  };

  const onSubmit = (data) => {
    if (
      searchParams.beginCDIAC === "" &&
      searchParams.endCDIAC === ""
    ) {
      var name = "Please select at least one search or filter criteria !";
      var content = document.createElement("div");
      content.innerHTML = "<strong>" + name + "</strong>";
      swal(content);
      return;
    }
    var obj = {
      "beginCDIAC": searchParams.beginCDIAC,
      "endCDIAC": searchParams.endCDIAC
    };
    setSearchReq(obj);
    setShowSearchResult(true);
    
  };

  return (
   <form onSubmit={(e) => e.preventDefault()}>
      <Card>
        <div className="form">
          <h1>Azure Docs</h1>
          <Row>
            <Col>
              <div className="control">
                <label>Begin CDIAC Number</label>
                <input
                  type="text"
                  placeholder="Begin CDIAC Number"
                  id="beginCDIAC"
                  labelKey="beginCDIAC"
                  onChange={(e) => handleChange(e, "beginCDIAC")}
                />
              </div>
            </Col>
            <Col>
              <div className="control">
                <label>End CDIAC Number</label>
                <input
                  type="text"
                  placeholder="End CDIAC Number"
                  id="endCDIAC"
                  onChange={(e) => handleChange(e, "endCDIAC")}
                  labelKey="endCDIAC"
                />
              </div>
            </Col>
          </Row>
          <div style={{ overflow: "auto" }}>
            <button
              style={{ float: "right" }}
              type="Submit"
              onClick={handleSubmit(onSubmit)}
            >
              Search
            </button>
          </div>
          <div></div>
        </div>
        {showSearchResult && <SearchResultContainer searchReq={searchReq} />}
      </Card>
    </form>
  );
};

export default AzureDocs;
